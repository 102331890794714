import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import { Body } from "src/reusable_view_elements/Typography";

interface LoadingCircleProps {
  text?: string;
}

const LoadingCircle = (props: LoadingCircleProps) => {
  const { t, ready } = useTranslation(nsCommonFormsBtns);
  const label = props.text || (ready ? t("loading.label", { ns: nsCommonFormsBtns }) : "Loading");

  return (
    <Box textAlign="center" margin="0px auto" padding="100px 0px">
      <CircularProgress color="secondary" title={label} />
      {label && <Body>{label}</Body>}
    </Box>
  );
};

export default LoadingCircle;
