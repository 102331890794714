import { UserType, useUserSession } from "src/context/UserSessionContext";
import ChildcareNavbar from "src/reusable_view_elements/navbars/ChildcareNavbar";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import NavBar from "src/reusable_view_elements/navbars/NavBar";

interface Props {
  showLanguage?: boolean; // Only affects ChildcareNavbar and NavBar
  hideMenu?: boolean; // Only affects NavBar
  hideLogin?: boolean; // Only affects NavBar
}

/**
 * Purpose: We have a couple pages whose routes are accessible by both public and logged-in users. This pattern of
 * checking whether there is a user session and what kind of user they are is repeated for each of those pages.
 */
const PublicPrivateNavBar = (props: Props) => {
  const { userSession, isUserType, hasMedicaidAccess } = useUserSession();

  if (userSession) {
    if (hasMedicaidAccess()) {
      return <MedicaidNavbar />;
    }

    if (isUserType(UserType.ChildcareProvider)) {
      return <ChildcareNavbar showLanguage={props.showLanguage} />;
    }
  }

  return <NavBar {...props} />;
};

export default PublicPrivateNavBar;
