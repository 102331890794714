import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { ReactNode, useState } from "react";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";

type UserDeactivationDialogProps = {
  userFirstName: string;
  onSubmit: (accepted: boolean) => void;
  children: (setModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => ReactNode;
};

export const UserDeactivationDialog: React.FC<UserDeactivationDialogProps> = ({
  userFirstName,
  onSubmit = () => {},
  children,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setModalOpen(false);
          }
        }}
        maxWidth="xs"
        PaperProps={{
          style: { padding: "8px 0px 16px 0px" },
        }}
      >
        <DialogTitle>
          <BodyTitle>{`Do you really want to deactivate ${userFirstName}'s account?`}</BodyTitle>
        </DialogTitle>
        <DialogContent>
          <Body>If you confirm, you cannot undo this action.</Body>

          <Grid style={{ textAlign: "right" }}>
            <Button
              variant="text"
              type="button"
              onClick={() => {
                setModalOpen(false);
                onSubmit(true);
              }}
            >
              Yes, deactivate
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setModalOpen(false);
                onSubmit(false);
              }}
            >
              No
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      {children(setModalOpen)}
    </>
  );
};
