import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Grid, Link as MuiLink, SvgIconProps, useMediaQuery } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";

interface PortalCardTitleProps {
  viewMorePath: string;
  hideViewMore?: boolean;
  icon: React.ComponentType<SvgIconProps>;
  buttonText?: string;
  ariaLabel?: string;
  portalButtonText?: string;
}

const PortalCardTitle = (props: PropsWithChildren<PortalCardTitleProps>) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { icon: Icon } = props;
  const { t, ready } = useTranslation(nsCommonFormsBtns);

  return (
    <Grid container>
      <Grid item>
        <Icon style={{ width: 32, height: 32, marginRight: 12 }} />
      </Grid>

      <Grid item xs>
        {props.children}
      </Grid>
      {!props.hideViewMore && (
        <Grid item>
          {desktopSize && props.buttonText && (
            <Body>
              <LinkStyledAsButton
                variant="contained"
                component={Link}
                to={props.viewMorePath}
                aria-label={props.ariaLabel}
              >
                {props.buttonText}
              </LinkStyledAsButton>
            </Body>
          )}
          {!props.buttonText && (
            <Grid container>
              {desktopSize && ready && (
                <InternalLink to={props.viewMorePath} aria-label={props.ariaLabel}>
                  <Grid container item justifyContent="center" alignItems="center">
                    <Body pl="8px">{t("button.view", { ns: nsCommonFormsBtns })}</Body>
                    <ChevronRightRoundedIcon style={{ width: 32, height: 32 }} />
                  </Grid>
                </InternalLink>
              )}
              {!desktopSize && (
                <InternalLink to={props.viewMorePath} aria-label={props.ariaLabel}>
                  <Grid container item justifyContent="center" alignItems="center">
                    <ChevronRightRoundedIcon style={{ width: 48, height: 48 }} />
                  </Grid>
                </InternalLink>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {props.portalButtonText && (
        <Grid item>
          {!desktopSize ? (
            <Body>
              <MuiLink component={Link} to={props.viewMorePath} aria-label={props.ariaLabel}>
                <ChevronRightRoundedIcon style={{ width: 48, height: 48 }} />
              </MuiLink>
            </Body>
          ) : (
            <InternalLink to={props.viewMorePath} aria-label={props.ariaLabel}>
              <Grid container item justifyContent="center" alignItems="center">
                <Body>{props.portalButtonText}</Body>
                <ChevronRightRoundedIcon style={{ width: 48, height: 48 }} />
              </Grid>
            </InternalLink>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PortalCardTitle;
