import { Box, Card, CardContent, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { nsMedicaidJobPost, nsMedicaidSearch, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { InternalLink } from "src/reusable_view_elements/Link";
import {
  CalendarIcon,
  ChatTranslateIcon,
  TimeClockCircleAlternateIcon,
  PinLocation1Icon,
  FireworksPeopleWatchIcon,
} from "src/assets/icons/StreamlineIcons";
import IconList from "src/reusable_view_elements/IconList";
import IconListItemForCard from "src/reusable_view_elements/IconListItemForCard";
import TogglingBookmark from "src/reusable_view_elements/TogglingBookmark";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface CardProps {
  first_name?: string;
  pronouns?: string;
  gender?: string;
  city: string;
  state: string;
  description?: string;
  previewPageCard?: boolean;
  postedBy: string;
  roleOrAgency: string;
  hours: number;
  schedule?: string;
  languages: Array<string>;
  careNeeds: Array<string>;
  distance: number; // Expects distance in miles
  daysUp?: number;
  buttonLink: string;
  hideBookmark?: boolean;
  bookmarked?: boolean;
  onBookmark?: () => void;
  buttonSection: ReactElement;
  flexibleHours: boolean;
  flexibleSchedule: boolean;
  jobNumber: number;
}

const JobCard: React.FC<CardProps> = ({
  first_name,
  pronouns,
  gender,
  city,
  state,
  description,
  previewPageCard = false,
  postedBy,
  roleOrAgency,
  hours,
  schedule,
  distance,
  languages,
  careNeeds,
  daysUp,
  buttonLink,
  hideBookmark = false,
  bookmarked = false,
  onBookmark = () => {},
  buttonSection,
  flexibleHours,
  flexibleSchedule,
  jobNumber,
}) => {
  const { t, ready } = useTranslation([nsMedicaidJobPost, nsMedicaidSearch, nsMedicaidTagsEnums]);

  const location: string = `${city}, ${state}`;
  const milesAway: string = t("count_miles_away.label", { ns: nsMedicaidSearch, count: distance });
  const locationAndMiles: string = `${location} (${milesAway})`;

  if (!ready) {
    return (
      <Card style={{ display: "grid", width: "100%", boxShadow: "none", borderRadius: 0 }}>
        <CardContent>
          <Skeleton height={30} style={{ marginBottom: 24 }} />
          <Skeleton height={50} />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ display: "grid", width: "100%", boxShadow: "none", borderRadius: 0 }} data-testid={jobNumber}>
        <CardContent>
          <Grid container>
            <Grid item xs>
              {first_name && (
                <BodyTitle style={{ marginBottom: 4, overflowWrap: "anywhere" }}>
                  <InternalLink to={buttonLink}>
                    {t("work_with_name.label", { ns: nsMedicaidJobPost, name: first_name })}
                  </InternalLink>
                </BodyTitle>
              )}
              {/*Hide pronouns section when job card is on preview page*/}
              {pronouns && !previewPageCard && <Body style={{ marginTop: 12 }}>{pronouns}</Body>}
              {description &&
                (previewPageCard ? (
                  <BodyTitle paragraph>{description}</BodyTitle>
                ) : (
                  <Body style={{ marginTop: 8, marginBottom: 12 }}>{description}</Body>
                ))}
            </Grid>
            <Grid item>
              {!hideBookmark && (
                <TogglingBookmark
                  data-testid={`bookmark-icon-${jobNumber}`}
                  isSelected={bookmarked}
                  onClick={onBookmark}
                />
              )}
            </Grid>
          </Grid>
          <IconList>
            {/*Hide gender list item when card is on preview page*/}
            {gender && !previewPageCard && (
              <IconListItemForCard
                icon={FireworksPeopleWatchIcon}
                titleAccess={t("summary.consumer_gender.label", { ns: nsMedicaidJobPost })}
                secondary={<Body>{gender}</Body>}
              />
            )}
            <IconListItemForCard
              icon={PinLocation1Icon}
              titleAccess={t("summary.location.label", { ns: nsMedicaidJobPost })}
              secondary={<Body>{locationAndMiles}</Body>}
            />
            <IconListItemForCard
              icon={TimeClockCircleAlternateIcon}
              titleAccess={t("summary.monthly_hours.label", { ns: nsMedicaidJobPost })}
              secondary={
                <Body>
                  {t("count_hours_per_month.label", { ns: nsMedicaidJobPost, count: hours })}{" "}
                  {flexibleHours && <>({t("flexible.label", { ns: nsMedicaidSearch })})</>}
                </Body>
              }
            />
            {schedule && (
              <IconListItemForCard
                icon={CalendarIcon}
                titleAccess={t("summary.schedule.label", { ns: nsMedicaidJobPost })}
                secondary={
                  <Body style={{ overflowWrap: "anywhere" }}>
                    {schedule} {flexibleSchedule && <>({t("flexible.label", { ns: nsMedicaidSearch })})</>}
                  </Body>
                }
              />
            )}
            <IconListItemForCard
              icon={ChatTranslateIcon}
              titleAccess={t("summary.languages.label", { ns: nsMedicaidJobPost })}
              secondary={<Body>{languages.join(", ")}</Body>}
            />
          </IconList>
          <BodyEmphasis style={{ marginTop: "8px" }}>{t("care_needs.label", { ns: nsMedicaidJobPost })}:</BodyEmphasis>
          <Body paragraph>{careNeeds.join(" • ")}</Body>

          <Body style={{ textAlign: "right", color: CivColors.coreGray }}>
            {daysUp !== undefined &&
              t("posted_count_days_ago_by.label", { ns: nsMedicaidJobPost, count: daysUp, name: postedBy })}{" "}
            | {roleOrAgency}
          </Body>
        </CardContent>

        <Box style={{ alignSelf: "self-end" }} p={2}>
          {buttonSection}
        </Box>
      </Card>
    </>
  );
};

export default JobCard;
