import {
  AxiosMedicaidConsumerControllerClient,
  AxiosMedicaidControllerClient,
  DeleteJobPostDTO,
  JobPostAndRepostDTO,
} from "src/generated/api_types";

const client = new AxiosMedicaidConsumerControllerClient();
const generalClient = new AxiosMedicaidControllerClient();

export async function postMedicaidJob(jobPost: JobPostAndRepostDTO) {
  return client.postJob(jobPost);
}

export async function updateJobPostInfo(id: string, jobPostDTO: JobPostAndRepostDTO) {
  return client.updateJobPost(id, jobPostDTO);
}

export async function getMyJobPosts() {
  return client.getJobPostList();
}

export async function getPageMyJobPosts(pageNumber: number) {
  return client.getMyJobPostPage({ pageNumber });
}

export async function getAllMessagedProvidersForDropdown() {
  return client.getAllMessagedProviders();
}

export async function deleteJobPost(id: string, deleteJobPostDTO: DeleteJobPostDTO) {
  return client.deleteJobPost(id, deleteJobPostDTO);
}

export async function getDeletedJobPostListPage(pageNumber: number) {
  return client.getDeletedJobPostsPage({ pageNumber });
}

export async function renewJobPost(id: string) {
  return client.renewJobPost(id);
}

export async function getUserNewMessageSettings() {
  return generalClient.getUserNewMessageSettings();
}
