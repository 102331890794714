import { StepConnector as MStepConnector, stepConnectorClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import CivColors from "src/themes/civilization/CivColors";

interface StyledConnectorProps {
  top?: number;
}

const StyledConnector = styled(MStepConnector)<StyledConnectorProps>(({ top }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: top || 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    "& .MuiStepConnector-line": {
      backgroundColor: CivColors.coreOrange,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    "& .MuiStepConnector-line": {
      backgroundColor: CivColors.coreOrange,
    },
  },
  "& .MuiStepConnector-line": {
    height: 3,
    border: 0,
    backgroundColor: CivColors.mediumGray,
    borderRadius: 1,
  },
}));

export default StyledConnector;
