import { AxiosPPHCCarinaAdminDashboardConsumersControllerClient } from "src/generated/api_types";

const carinaAdminDashboardConsumerClient = new AxiosPPHCCarinaAdminDashboardConsumersControllerClient();

export async function getAllConsumers() {
  return carinaAdminDashboardConsumerClient.listAllApplications();
}

export function archiveClient(id: string) {
  return carinaAdminDashboardConsumerClient.postClientArchive(id);
}

export async function getConsumer(id: string) {
  return carinaAdminDashboardConsumerClient.getConsumerApplicationDetails(id);
}
