import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { nsChildcareProviderAcctPortal } from "src/i18n/Namespaces";
import { JobSearchProfileIcon } from "src/assets/icons/StreamlineIcons";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { ChildcareProviderProfileControllerClient } from "src/pages/childcare/ControllerClients";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import ChildcareNavbar from "src/reusable_view_elements/navbars/ChildcareNavbar";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import Section from "src/reusable_view_elements/Section";
import { BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const AccountPortal = () => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation(nsChildcareProviderAcctPortal);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const { showSnackbar } = useNotification();

  useEffect(() => {
    setLoading(true);
    ChildcareProviderProfileControllerClient.getChildcareProviderPortal()
      .then((res) => {
        setName(res.data.firstName);
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Carina | Childcare Account Portal</title>
      </Helmet>

      <ChildcareNavbar showLanguage />
      <main id="main-content">
        {!ready && (
          <Section bgcolor={CivColors.lightGray} centerContent minHeight={750}>
            <LoadingCircle />
          </Section>
        )}

        {ready && (
          <>
            <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
              <Constraint columns={8}>
                <SectionTitle align="center" style={{ overflowWrap: "anywhere" }}>
                  {t("greeting", { ns: nsChildcareProviderAcctPortal })} {name}
                </SectionTitle>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray} minimizeTopGutter minHeight={600}>
              <Constraint columns={8}>
                {isUserType(UserType.ChildcareProvider) && !loading && (
                  <PortalCard>
                    <PortalCardTitle icon={JobSearchProfileIcon} viewMorePath={CHILD_CARE_ROUTES.editProfile}>
                      <BodyTitle>{t("edit profile card", { ns: nsChildcareProviderAcctPortal })}</BodyTitle>
                    </PortalCardTitle>
                  </PortalCard>
                )}
              </Constraint>
            </Section>
          </>
        )}
      </main>

      <Footer />
    </>
  );
};

export default AccountPortal;
