import { Grid } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { MultipleActionsCheck1Icon } from "src/assets/icons/StreamlineIcons";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { SUPPORT_ADMIN_PROGRAM_KEY } from "src/pages/supportAdminAccount/dashboard/components/SupportAdminDashboardContainer";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import PageContainerDashboard from "src/reusable_view_elements/PageContainerDashboard";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import Section from "src/reusable_view_elements/Section";
import { BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

export function getDashboardPortalTitle(userType: string | undefined): string {
  switch (userType) {
    case UserType.UserSupportAgent:
      return "Carina User Support Agent Portal";
    case UserType.UserSupportManager:
      return "Carina User Support Manager Portal";
    default:
      return "Carina User Support Portal";
  }
}

const SupportPortalMain = () => {
  const { userSession, isUserType, hasMedicaidAccess } = useUserSession();

  useEffect(() => {
    sessionStorage.removeItem(SUPPORT_ADMIN_PROGRAM_KEY);
  }, []);

  return (
    <>
      <Helmet>
        <title>Carina | Support Manager Portal</title>
      </Helmet>

      <PageContainerDashboard title={getDashboardPortalTitle(userSession?.role)}>
        <Section
          style={{ position: "absolute", left: 0, right: 0, height: "100%" }}
          bgcolor={CivColors.lightGray}
          minimizeTopGutter
        >
          <Constraint columns={20}>
            <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
              <Constraint columns={16}>
                <Grid container spacing={10}>
                  <Grid item xs={12} md={6}>
                    <SectionTitle align="left">Tools</SectionTitle>
                    {hasMedicaidAccess() && (
                      <PortalCard data-testid="medicaid-tools-card">
                        <PortalCardTitle
                          icon={MultipleActionsCheck1Icon}
                          portalButtonText="Manage"
                          ariaLabel="Medicaid Management Tools"
                          hideViewMore
                          viewMorePath={SUPPORT_ADMIN_DASHBOARD_ROUTES.active_user}
                        >
                          <BodyTitle>Medicaid</BodyTitle>
                        </PortalCardTitle>
                      </PortalCard>
                    )}
                    {isUserType(UserType.UserSupportManager) && (
                      <PortalCard data-testid="private-pay-tools-card">
                        <PortalCardTitle
                          icon={MultipleActionsCheck1Icon}
                          portalButtonText="Manage"
                          ariaLabel="Private Pay Management Tools"
                          hideViewMore
                          viewMorePath={SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list}
                        >
                          <BodyTitle>Private Pay</BodyTitle>
                        </PortalCardTitle>
                      </PortalCard>
                    )}
                    {isUserType(UserType.UserSupportManager) && (
                      <PortalCard data-testid="childcare-tools-card">
                        <PortalCardTitle
                          icon={MultipleActionsCheck1Icon}
                          portalButtonText="Manage"
                          ariaLabel="Child Care Management Tools"
                          hideViewMore
                          viewMorePath={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_active_user}
                        >
                          <BodyTitle>Childcare</BodyTitle>
                        </PortalCardTitle>
                      </PortalCard>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SectionTitle align="left">End User View</SectionTitle>
                    {hasMedicaidAccess() && (
                      <PortalCard>
                        <PortalCardTitle
                          icon={MultipleActionsCheck1Icon}
                          portalButtonText="View"
                          ariaLabel="View Medicaid End User View"
                          hideViewMore
                          viewMorePath={MEDICAID_ACCOUNT_ROUTES.homepage}
                        >
                          <BodyTitle>Medicaid</BodyTitle>
                        </PortalCardTitle>
                      </PortalCard>
                    )}
                  </Grid>
                </Grid>
              </Constraint>
            </Section>
          </Constraint>
        </Section>
      </PageContainerDashboard>
    </>
  );
};

export default SupportPortalMain;
