import { Box, Grid } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsMedicaidJobSurveyConfirmation } from "src/i18n/Namespaces";
import { Circle1FilledIcon, Circle2FilledIcon, Circle3FilledIcon } from "src/assets/icons/CustomIcons";
import { Consumer_Blog_Posts, Provider_Blog_Posts } from "src/pages/blog/BlogPosts";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import SimpleThreadSurveyConfirmation from "src/pages/inbox/matchingSurveyComponents/SimpleThreadSurveyConfirmation";
import { IconList, IconListItem } from "src/pages/inbox/matchingSurveyComponents/ThreadSurveyMatchedConfirmation";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import { Medicaid_Proxy_Provider_Routes } from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface ThreadSurveyNotMatchedConfirmationProps {
  threadId: string;
}

const ThreadSurveyNotMatchedConfirmation = (props: ThreadSurveyNotMatchedConfirmationProps) => {
  const { userSession, isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobSurveyConfirmation, nsCommonFormsBtns]);

  if (isUserType(UserType.MedicaidCaseManager) || isUserType(UserType.MedicaidReferralCoordinator)) {
    return <SimpleThreadSurveyConfirmation threadId={props.threadId} />;
  }

  if (!ready) {
    return (
      <>
        <Section minimizeBottomGutter minHeight="100vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  return (
    <>
      <Section textAlign="center">
        <Constraint columns={8}>
          <SectionTitle>{t("received_your_response.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
          <Body paragraph>{t("received_your_response.description", { ns: nsMedicaidJobSurveyConfirmation })}</Body>
          <Grid container>
            <Grid item xs={12}>
              <LinkStyledAsButton
                variant="outlined"
                component={Link}
                to={generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: props.threadId })}
              >
                {t("button.return_to_thread", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
          </Grid>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.paleBlue} textAlign="center">
        <Constraint columns={8}>
          <SectionTitle sx={{ marginBottom: "48px" }}>
            {t("next_steps.label", { ns: nsMedicaidJobSurveyConfirmation })}
          </SectionTitle>
          <IconList>
            <IconListItem
              icon={Circle1FilledIcon}
              primary={
                <BodyTitle>
                  {userSession?.role === "Consumer" &&
                    t("post_if_not_already.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) &&
                    t("update_profile_if_you_havent.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Box style={{ margin: "20px 0px" }}>
                  <Body style={{ marginBottom: 26 }}>
                    {userSession?.role === "Consumer" &&
                      t("post_if_not_already.description", { ns: nsMedicaidJobSurveyConfirmation })}
                    {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) &&
                      t("update_profile_if_you_havent.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  </Body>
                  {userSession?.role === "Consumer" && (
                    <InternalLink to={getMedicaidRouteByUserType("jobCreate", isUserType)}>
                      <Body>{`${t("button.post_a_job_now", { ns: nsCommonFormsBtns })}.`}</Body>
                    </InternalLink>
                  )}
                  {isUserType(UserType.Provider) && (
                    <InternalLink to={Medicaid_Provider_Dashboard_Routes.editMyProfile}>
                      <Body>{`${t("button.update_your_profile_now", { ns: nsCommonFormsBtns })}.`}</Body>
                    </InternalLink>
                  )}
                  {isUserType(UserType.ProxyProvider) && (
                    <InternalLink to={Medicaid_Proxy_Provider_Routes.editMyProfile}>
                      <Body>{`${t("button.update_your_profile_now", { ns: nsCommonFormsBtns })}.`}</Body>
                    </InternalLink>
                  )}
                </Box>
              }
            />
            <IconListItem
              icon={Circle2FilledIcon}
              primary={
                <BodyTitle>
                  {userSession?.role === "Consumer" &&
                    t("continue_searching_providers.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) &&
                    t("continue_searching_for_jobs.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Box style={{ margin: "20px 0px" }}>
                  <Body style={{ marginBottom: 26 }}>
                    {userSession?.role === "Consumer" &&
                      t("continue_searching_providers.description", { ns: nsMedicaidJobSurveyConfirmation })}
                    {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) &&
                      t("continue_searching_for_jobs.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  </Body>
                  {userSession?.role === "Consumer" && (
                    <InternalLink to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
                      <Body>{`${t("button.find_providers_now", { ns: nsCommonFormsBtns })}.`}</Body>
                    </InternalLink>
                  )}
                  {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) && (
                    <InternalLink to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
                      <Body>{`${t("button.find_jobs_now", { ns: nsCommonFormsBtns })}.`}</Body>
                    </InternalLink>
                  )}
                </Box>
              }
            />
            <IconListItem
              icon={Circle3FilledIcon}
              primary={
                <BodyTitle>
                  {userSession?.role === "Consumer" &&
                    t("checkout_tips_for_finding_provider.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) &&
                    t("check_tips_for_good_impression.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Body style={{ margin: "20px 0px" }}>
                  {userSession?.role === "Consumer" && (
                    <Trans
                      t={t}
                      i18nKey={Consumer_Blog_Posts["tips-for-choosing-a-provider"].translateKey}
                      ns={Consumer_Blog_Posts["tips-for-choosing-a-provider"].namespace}
                      components={{
                        stcp: (
                          <InternalLink to={Consumer_Blog_Posts["tips-for-choosing-a-provider"].url} target="_blank" />
                        ),
                      }}
                    />
                  )}
                  {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) && (
                    <Trans
                      t={t}
                      i18nKey={Provider_Blog_Posts["first-impression"].translateKey}
                      ns={Provider_Blog_Posts["first-impression"].namespace}
                      components={{
                        mgfipc: <InternalLink to={Provider_Blog_Posts["first-impression"].url} target="_blank" />,
                      }}
                    />
                  )}
                </Body>
              }
            />
          </IconList>
        </Constraint>
      </Section>
    </>
  );
};

export default ThreadSurveyNotMatchedConfirmation;
