import { Grid, useMediaQuery } from "@mui/material";
import { ReactElement } from "react";
import { NavBarLogo } from "src/reusable_view_elements/navbars/NavBar";
import theme from "src/themes/civilization/CivTheme";

type GridNavBarProps = {
  center: ReactElement;
  right: ReactElement;
};

/**
 * Provides a grid that spaces out child components. Includes Carina Logo.
 * This is nearly a duplicate of BasicNavBar. Consider consolidating these two components
 */
const NavBarGrid = (props: GridNavBarProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const desktopStyle = { padding: 24 } as React.CSSProperties;
  const mobileStyle = { padding: "24px 16px" } as React.CSSProperties;
  const style = desktopSize ? desktopStyle : mobileStyle;

  return (
    <Grid style={style} container justifyContent="space-between" alignItems="center">
      <Grid item xs display="flex">
        <NavBarLogo />
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        {props.center}
      </Grid>
      <Grid item xs style={{ textAlign: "right" }}>
        {props.right}
      </Grid>
    </Grid>
  );
};

export default NavBarGrid;
