import { AxiosPPHCFunderDashboardConsumersControllerClient } from "src/generated/api_types";

const funderDashboardConsumerClient = new AxiosPPHCFunderDashboardConsumersControllerClient();

export async function getAllFunderConsumers(funderId: string) {
  return funderDashboardConsumerClient.listAllApplicationsByFunder({
    funderId,
  });
}

export async function getConsumer(id: string) {
  return funderDashboardConsumerClient.getConsumerApplicationDetails(id);
}

export function archiveClient(id: string) {
  return funderDashboardConsumerClient.postClientArchive(id);
}
