import { Container, ContainerProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";
import CivTheme from "src/themes/civilization/CivTheme";

const StyledContainer = styled(Container)({
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 24,
  paddingBottom: 50,
  "& > .MuiContainer-maxWidthXl": {
    [CivTheme.breakpoints.down("xl")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 32,
      paddingBottom: 50,
      maxWidth: 814,
    },
    [CivTheme.breakpoints.up("lg")]: {
      paddingLeft: 209,
      paddingRight: 209,
      paddingTop: 32,
      paddingBottom: 50,
      maxWidth: 2000,
    },
  },
});

export enum DashboardType {
  PRIVATE_PAY,
  MEDICAID,
  CHILDCARE,
}

interface PageContainerDashboardProps extends ContainerProps {
  type?: DashboardType;
}

const PageContainerDashboard: React.FC<PageContainerDashboardProps> = ({ type, children, title, ...props }) => {
  return (
    <main id="main-content">
      {type === DashboardType.MEDICAID ? <MedicaidNavbar /> : <DashboardNavbar title={title} />}
      <StyledContainer maxWidth={props.maxWidth} {...props}>
        {children}
      </StyledContainer>
    </main>
  );
};

PageContainerDashboard.defaultProps = {
  type: DashboardType.PRIVATE_PAY,
};

export default PageContainerDashboard;
