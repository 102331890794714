import { Box, BoxProps } from "@mui/material";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";

const Hero = ({ bgcolor, ...rest }: BoxProps) => {
  return (
    <Box
      paddingTop={CivTheme.spacing(10)}
      paddingBottom={CivTheme.spacing(10)}
      paddingLeft={CivTheme.spacing(2)}
      paddingRight={CivTheme.spacing(2)}
      style={typeof bgcolor == "string" ? { background: bgcolor } : { background: CivColors.gradient1 }}
      {...rest}
    />
  );
};

export default Hero;
