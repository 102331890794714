import { ButtonProps as MButtonProps } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { AxiosUsersImpersonationControllerClient } from "src/generated/api_types";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { Button } from "src/reusable_view_elements/Button";

const impersonateClient = new AxiosUsersImpersonationControllerClient();

interface StartImpersonationButtonProps extends Omit<MButtonProps, "type"> {
  userId: string;
  userDisplayName: string;
  label?: string;
  returnRoute?: string;
  impersonationRoute?: string;
}

// Reusable function so that it can be used in components that are not a Button
export const impersonateUser = async (
  userId: string,
  reloadAuthState: (shouldRedirect?: boolean) => void,
  loadingUserSession: boolean,
  history: any, // History<unknown> does not have a generic type
  returnRoute?: string,
  impersonationRoute?: string,
) => {
  try {
    setImpersonationReturnRoute(returnRoute);
    if (impersonationRoute) setImpersonationRoute(impersonationRoute);
    await impersonateClient.postImpersonate(userId);
    reloadAuthState();
    if (!loadingUserSession) {
      history.push(DASHBOARD_ROUTES.consumer_root);
    }
  } catch (e) {
    // window.alert("Error: Could not start impersonation");
  }
};

/*** Helper functions to set an optional route for returning to after impersonation ***/
const IMPERSONATION_RETURN_URL_KEY = "impersonation-return-route";

export const getImpersonationReturnRoute = (): string | null => {
  return localStorage.getItem(IMPERSONATION_RETURN_URL_KEY);
};

export const removeImpersonationReturnRoute = () => {
  const route = getImpersonationReturnRoute();
  if (route) localStorage.removeItem(IMPERSONATION_RETURN_URL_KEY);
};

const setImpersonationReturnRoute = (route?: string) => {
  if (route) localStorage.setItem(IMPERSONATION_RETURN_URL_KEY, route);
};

/**************** Helper functions to set an optional route to land on  **************/
const IMPERSONATION_URL_KEY = "impersonation-route";

export const getImpersonationRoute = (): string | null => {
  return localStorage.getItem(IMPERSONATION_URL_KEY);
};

export const removeImpersonationRoute = () => {
  const route = getImpersonationRoute();
  if (route) localStorage.removeItem(IMPERSONATION_URL_KEY);
};

const setImpersonationRoute = (route?: string) => {
  if (route) localStorage.setItem(IMPERSONATION_URL_KEY, route);
};

/*************************************** **********************************************/

export const StartImpersonationButton: React.FC<StartImpersonationButtonProps> = ({
  userId,
  userDisplayName,
  label,
  returnRoute,
  impersonationRoute,
  ...buttonProps
}) => {
  const { reloadAuthState, loading: loadingUserSession } = useUserSession();
  const history = useHistory();
  const { variant } = buttonProps;

  return (
    <Button
      variant={variant || "outlined"}
      type="button"
      onClick={async () => {
        setImpersonationReturnRoute(returnRoute);
        await impersonateUser(userId, reloadAuthState, loadingUserSession, history, impersonationRoute);
      }}
      {...buttonProps}
    >
      {label || `Impersonate ${userDisplayName}`}
    </Button>
  );
};
