import { format } from "date-fns";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  ConsumerApplication_DTO_Response_Listing,
  ConsumerApplicationGender,
  ContactMethod,
} from "src/generated/api_types";
import {
  getAgencyNotes,
  getAgencyNotesText,
  getConsumerNotes,
  getConsumerNotesText,
  getDeclinedNotes,
} from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import {
  getCareRecipientOrContactFirstName,
  getCareRecipientOrContactLastName,
} from "src/pages/agencyAdmin/dashboard/consumers/GetConsumers";
import { useCarinaAdminConsumersDatatable } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/CarinaAdminConsumerDashboard";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { getCounty } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumersList";
import { statusColumn } from "src/pages/offerings/pphc/datatable_columns/consumer_applications/StatusColumn";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { ExportMenuOption } from "src/reusable_view_elements/datatable/Datatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import { getConsumerAppDeclinedReasonLabel } from "src/utilities/ConsumerApplicationUtilities";
import { applyPhoneFormat, hasOnlyDigits, removeNonDigits } from "src/utilities/DashboardUtilities";

function getContactMethod(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.contact.preferredMethod) {
    switch (data.contact.preferredMethod) {
      case ContactMethod.EMAIL:
        return data.contact.email || "";
      case ContactMethod.PHONE:
        return applyPhoneFormat(data.contact.phone);
      case ContactMethod.SMS:
        return applyPhoneFormat(data.contact.phone);
      default:
        return "";
    }
  } else {
    return applyPhoneFormat(data.contact.phone) || data.contact.email;
  }
}

function getPetsResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (
    data.homeEnvironment.DOGS === true ||
    data.homeEnvironment.CATS === true ||
    data.homeEnvironment.BIRDS === true ||
    data.homeEnvironment.OTHER_PETS === true
  ) {
    return "Yes";
  }

  if (
    data.homeEnvironment.DOGS === false &&
    data.homeEnvironment.CATS === false &&
    data.homeEnvironment.BIRDS === false &&
    data.homeEnvironment.OTHER_PETS === false
  ) {
    return "No";
  }

  return "";
}

function getSmokingResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.homeEnvironment.SMOKING_HOME === true) {
    return "Yes";
  }

  if (data.homeEnvironment.SMOKING_HOME === false) {
    return "No";
  }

  return "";
}

export function getUpdatedDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return data.updatedAt ? new Date(data.updatedAt) : new Date();
}

export function getCreatedDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return new Date(data.createdAt);
}

export function getDobDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return data.careRecipient.dateOfBirth ? new Date(data.careRecipient.dateOfBirth) : new Date(1800, 0, 1);
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

export function removePhoneFormat(formattedPhone?: string): string {
  if (formattedPhone) {
    return formattedPhone.replace(/\D/g, "");
  }
  return "";
}

export function applyMMddyyyyFormat(rawDate: number): string {
  if (rawDate) {
    return format(rawDate, "MM/dd/yyyy");
  }
  return "";
}

function getFirstName(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.firstName ? data.careRecipient.firstName : `${data.contact.firstName} (representative)`;
}

function getGender(data: ConsumerApplication_DTO_Response_Listing): string {
  switch (data.careRecipient.gender) {
    case ConsumerApplicationGender.NOT_DISCLOSED:
      return "Not Disclosed";
    case ConsumerApplicationGender.DIFFERENT_IDENTITY:
      return "Different Identity";
    case ConsumerApplicationGender.FEMALE:
      return "Female";
    case ConsumerApplicationGender.MALE:
      return "Male";
    default:
      return "Not Disclosed";
  }
}

function getZipCode(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.zipCode : "";
}

function getCity(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.city : "";
}

function getLanguages(data: ConsumerApplication_DTO_Response_Listing): string {
  const languagesArray = data.languages
    .map((language) => _.startCase(_.lowerCase(`${language.toString().replace(/_/g, " ")}`)))
    .sort();
  return languagesArray.toString().replace(/,/g, ", ");
}

function getLatestEditorNameText(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.latestEditor ? `${data.latestEditor.firstName} ${data.latestEditor.lastName}` : "N/A";
}

export const CarinaAdminConsumersList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useCarinaAdminConsumersDatatable();
  const { isUserType } = useUserSession();

  /*********************************************************
   ******************* Column Definitions ******************
   *********************************************************/

  const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<ConsumerApplication_DTO_Response_Listing> } = {
    readable_id: {
      title: "ID",
      field: "readableId",
      render: (data) => data.readableId,
      customFilterAndSearch: (term, data) => {
        return data.readableId?.indexOf(term) !== -1;
      },
      customSort: (data1, data2) => {
        if (data1.readableId == null && data2.readableId == null) return 0;
        if (data1.readableId == null) return 1;
        if (data2.readableId == null) return -1;
        return data1.readableId.localeCompare(data2.readableId);
      },
      hidden: true,
    },
    county: {
      title: "County",
      field: "county",
      customExport: (data) => getCounty(data),
      render: (data) => getCounty(data),
      customFilterAndSearch: (term, data) => {
        return getCounty(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getCounty(data1).toLowerCase().localeCompare(getCounty(data2).toLowerCase());
      },
      hidden: true,
    },
    funder: {
      title: "Funder",
      field: "agency.funder.segment",
    },
    agency: {
      title: "Agency",
      field: "agency.name",
    },
    first_name: {
      title: "First Name",
      field: "firstName",
      customExport: getFirstName,
      render: (data) => {
        return (
          <InternalLink
            to={
              data.id! &&
              generatePath(
                isUserType(UserType.CarinaAdmin)
                  ? CARINA_ADMIN_DASHBOARD_ROUTES.consumer_details
                  : SUPPORT_ADMIN_DASHBOARD_ROUTES.consumer_details,
                {
                  id: data.id,
                },
              )
            }
          >
            <Body>{getFirstName(data)}</Body>
          </InternalLink>
        );
      },
      customFilterAndSearch: (term, data) => {
        return getCareRecipientOrContactFirstName(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        const name1 = getCareRecipientOrContactFirstName(data1);
        const name2 = getCareRecipientOrContactFirstName(data2);
        return name1.toLowerCase().localeCompare(name2.toLowerCase());
      },
    },
    last_name: {
      title: "Last Name",
      field: "lastName",
      customExport: (data) => getCareRecipientOrContactLastName(data),
      render: (data) => {
        return <Body>{getCareRecipientOrContactLastName(data)}</Body>;
      },
      customFilterAndSearch: (term, data) => {
        return getCareRecipientOrContactLastName(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        const name1 = getCareRecipientOrContactLastName(data1);
        const name2 = getCareRecipientOrContactLastName(data2);
        return name1.toLowerCase().localeCompare(name2.toLowerCase());
      },
    },
    dob: {
      title: "DOB",
      field: "dob",
      customExport: (data) => (getDobDate(data).getFullYear() === 1800 ? "" : formatDate(getDobDate(data))),
      render: (data) => (getDobDate(data).getFullYear() === 1800 ? "" : formatDate(getDobDate(data))),
      customFilterAndSearch: (term, data) => formatDate(getDobDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getDobDate(data1).getTime() - getDobDate(data2).getTime(),
      hidden: true,
    },
    gender: {
      title: "Gender",
      field: "gender",
      customExport: (data) => getGender(data),
      render: (data) => getGender(data),
      customFilterAndSearch: (term, data) => {
        return getGender(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getGender(data1).toLowerCase().localeCompare(getGender(data2).toLowerCase());
      },
      hidden: true,
    },
    languages: {
      title: "Languages",
      field: "languages",
      customExport: (data) => getLanguages(data),
      render: (data) => getLanguages(data),
      customFilterAndSearch: (term, data) => {
        return getLanguages(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getLanguages(data1).toLowerCase().localeCompare(getLanguages(data2).toLowerCase());
      },
      hidden: true,
    },
    contact_method: {
      title: "Contact Method",
      field: "contactMethod",
      customExport: getContactMethod,
      render: getContactMethod,
      customFilterAndSearch: (term, data) => {
        const cmStr = getContactMethod(data);
        if (term.match(/^\d+$/)) {
          return cmStr.replace(/\D|\s/g, "").indexOf(term) !== -1;
        }
        return cmStr.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) =>
        getContactMethod(data1).toLowerCase().localeCompare(getContactMethod(data2).toLowerCase()),
    },
    email: {
      title: "Email",
      field: "email",
      customExport: (data) => data.contact.email,
      render: (data) => data.contact.email,
      customFilterAndSearch: (term, data) =>
        data.contact.email.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1,
      customSort: (data1, data2) => data1.contact.email.toLowerCase().localeCompare(data2.contact.email.toLowerCase()),
      hidden: true,
    },
    phone: {
      title: "Phone",
      field: "phone",
      customExport: (data) => applyPhoneFormat(data.contact.phone),
      render: (data) => applyPhoneFormat(data.contact.phone),
      customFilterAndSearch: (term, data) => {
        if (hasOnlyDigits(term)) {
          return removeNonDigits(data.contact.phone).indexOf(term) !== -1;
        }
        return data.contact.phone.indexOf(term) !== -1;
      },
      customSort: (data1, data2) => {
        return data1.contact.phone.localeCompare(data2.contact.phone.toLowerCase());
      },
      hidden: true,
    },
    hours: {
      title: "Req'd Hrs/Wk",
      field: "hours",
    },
    consumerNotes: {
      title: "Care Needs Notes",
      field: "consumerNotes",
      customExport: getConsumerNotesText,
      render: getConsumerNotes,
      hiddenByColumnsButton: false,
      customFilterAndSearch: (term, data) => data.consumerNotes.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    agencyNotes: {
      title: "Agency Notes",
      field: "agencyNotes",
      customExport: getAgencyNotesText,
      render: getAgencyNotes,
      hiddenByColumnsButton: false,
      customFilterAndSearch: (term, data) => data.agencyNotes.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    address: {
      title: "Address",
      field: "careRecipient.address",
      customExport: (data) => data.careRecipient.address || "",
      hidden: true,
    },
    city: {
      title: "City",
      field: "city",
      customExport: (data) => getCity(data),
      render: (data) => getCity(data),
      customFilterAndSearch: (term, data) => {
        return getCity(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getCity(data1).toLowerCase().localeCompare(getCity(data2).toLowerCase());
      },
    },
    zip_code: {
      title: "ZIP Code",
      field: "zipCode",
      customExport: (data) => getZipCode(data),
      render: (data) => getZipCode(data),
      customFilterAndSearch: (term, data) => {
        return getZipCode(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getZipCode(data1).localeCompare(getZipCode(data2));
      },
      hidden: true,
    },
    declinedReason: {
      title: "Reason for Decline",
      field: "declinedReason",
      customExport: (data) => getConsumerAppDeclinedReasonLabel(data.declinedReason),
      render: (data) => getConsumerAppDeclinedReasonLabel(data.declinedReason),
      hidden: true,
      customFilterAndSearch: (term, data) => data.declinedReason?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    declinedNotes: {
      title: "Declined Status Notes",
      field: "declinedNotes",
      render: getDeclinedNotes,
      hidden: true,
      customFilterAndSearch: (term, data) => data.declinedNotes?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    pets: {
      title: "Pets",
      customExport: getPetsResponse,
      render: getPetsResponse,
      hidden: true,
      customFilterAndSearch: (term, data) => getPetsResponse(data).toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    smoking: {
      title: "Smoking",
      customExport: getSmokingResponse,
      render: getSmokingResponse,
      hidden: true,
      customFilterAndSearch: (term, data) => getSmokingResponse(data).toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    status: statusColumn(),
    updated: {
      title: "Last Updated",
      field: "updatedAt",
      exportTransformer: (data) => formatDate(getUpdatedDate(data)),
      render: (data) => formatDate(getUpdatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getUpdatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getUpdatedDate(data1).getTime() - getUpdatedDate(data2).getTime(),
      defaultSort: "desc",
    },
    created: {
      title: "Create Date",
      field: "createdAt",
      exportTransformer: (data) => formatDate(getCreatedDate(data)),
      render: (data) => formatDate(getCreatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getCreatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getCreatedDate(data1).getTime() - getCreatedDate(data2).getTime(),
      defaultSort: "desc",
      hidden: true,
    },
    latest_editor: {
      title: "Latest Editor",
      field: "latestEditor",
      customExport: getLatestEditorNameText,
      render: (data) => <Body>{getLatestEditorNameText(data)}</Body>,
      customFilterAndSearch: (term, data) =>
        (data.latestEditor?.firstName ? `${data.latestEditor?.firstName} ${data.latestEditor?.lastName}` : "N/A")
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
    },
  };

  /*********************************************************
   ******************* Page Definition *********************
   *********************************************************/

  return (
    <>
      <Helmet>
        <title>Carina | Internal Tools | Clients</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="consumers-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Clients"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching client applications..." : "No client records to display",
          },
        }}
        options={{
          exportMenu: [ExportMenuOption.csv({ filename: "Clients" })],
          exportAllData: true,
        }}
        highlightRowFn={(rowData: ConsumerApplication_DTO_Response_Listing) => {
          return !!rowData.highlightReasons && rowData.highlightReasons.length > 0;
        }}
      />
    </>
  );
};
