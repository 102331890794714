import { Button as MuiButton, Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

/**
 * This file intends to simplify our usage of Links. Whether your link is external or internal, I think it's
 * reasonable that both types should share prop names. For example,
 * - the Material UI Link uses the prop "href", meanwhile
 * - the React Router DOM Link uses the prop "to"
 *
 * Both link types extend MUI's Link, which provides a single style to be used by both.
 * Depending on your use case, it's entirely fine to use MUI's Link directly. These are simply abstractions.
 *
 * See additional explanation below about LinkStyledAsButton.
 */

interface ExternalLinkProps extends MuiLinkProps {
  to: string;
}

// Picking "to" on RouterLinkProps allows us to pass state to the route
// Example: https://stackoverflow.com/questions/65712641/react-router-how-to-pass-previous-location-pathname-to-current
interface InternalLinkProps extends Omit<MuiLinkProps, "to">, Pick<RouterLinkProps, "to"> {}

export const ExternalLink = ({ to, ...props }: ExternalLinkProps) => (
  <MuiLink href={to} {...props}>
    {props.children}
  </MuiLink>
);

export const InternalLink = ({ to, ...muiLinkProps }: InternalLinkProps) => (
  <MuiLink component={RouterLink} to={to} {...muiLinkProps}>
    {muiLinkProps.children}
  </MuiLink>
);

/**
 * There are many places across the app where we want the style of a button, but the underlying element and behavior
 * of a link. If a Mui Button is used with a href prop or a component={link} prop, it changes the underlying element
 * to a link (but still has the classnames of a button, which allows us to access the button styles). The type
 * declarations of our existing custom Button prevent us from being able to use the component/to properties, so this
 * simple export of a MuiButton allows us to be semantic about the places where we want the style of a button but the
 * element and behavior of a link.
 */

export const LinkStyledAsButton = ({ ...props }) => (
  <MuiButton variant={props.variant || "contained"} {...props}>
    {props.children}
  </MuiButton>
);
