import { Box, BoxProps } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CivColors from "src/themes/civilization/CivColors";

const ErrorBox = (props: BoxProps) => {
  return (
    <>
      <Box
        sx={{
          background: CivColors.dustyRose,
          borderRadius: 4,
          padding: "16px",
          textAlign: "left",
          display: "flex",
        }}
        {...props}
      >
        <Box marginRight={2}>
          <ErrorRoundedIcon htmlColor={CivColors.deepRose} />
        </Box>
        <Box>{props.children}</Box>
      </Box>
    </>
  );
};

export default ErrorBox;
