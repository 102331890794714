import { Box, Dialog, DialogContent, DialogTitle, Grid, Link as MuiLink } from "@mui/material";
import { Field, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PixelEventPush } from "src/analytics/fpixel";
import { nsChildcareMessageProviderForm, nsCommonCTAbtns, nsCommonForms, nsCommonFormsBtns } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useChildCareContext } from "src/pages/childcare/ChildCareContext";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { ChildcareMessagingControllerClient } from "src/pages/childcare/ControllerClients";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import {
  Checkbox,
  CheckboxControlLabel,
  CheckboxErrorMessage,
} from "src/reusable_view_elements/form_fields/CheckboxField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivTheme from "src/themes/civilization/CivTheme";
import { isRequiredField, phoneRegExp } from "src/utilities/GeneralUtilities";
import { boolean, object, string } from "yup";

interface MessageDialogProps {
  open: boolean;
  onClose: () => void;
  providerId: string; // for Carina API
  providerBusinessName: string; // for dialog
  providerFirstName: string; // for dialog
}

export interface MessageFormValues {
  userFirstName: string;
  userEmail: string;
  userMobilePhone: string;
  message: string;
  tos: boolean;
}

const MessageDialog = (props: MessageDialogProps) => {
  const { t, ready } = useTranslation([nsChildcareMessageProviderForm, nsCommonCTAbtns, nsCommonForms]);
  const MAX_MESSAGE_LENGTH = 500;
  const location = useLocation();
  const [showConfirmation, setConfirmation] = useState<boolean>(false);
  const searchListLink = `${CHILD_CARE_ROUTES.searchList}${location.search}#${props.providerId}`;
  const { geoDataId, searchInput } = useChildCareContext();

  const initialValues: MessageFormValues = {
    userFirstName: "",
    userEmail: "",
    userMobilePhone: "",
    message: "",
    tos: false,
  };

  const validationSchema = object().shape({
    message: string()
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(MAX_MESSAGE_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    userFirstName: string().required(t("first name error", { ns: nsCommonForms })),
    userEmail: string()
      .email(t("email invalid error", { ns: nsCommonForms }))
      .required(t("email required error", { ns: nsCommonForms })),
    userMobilePhone: string()
      .required(t("mobile phone error", { ns: nsCommonForms }))
      .matches(phoneRegExp, { message: t("mobile phone error", { ns: nsCommonForms }) }),
    tos: boolean().oneOf([true], t("tos agree error", { ns: nsCommonForms })),
  });

  const handleSubmit = async (formValues: MessageFormValues, actions: FormikHelpers<MessageFormValues>) => {
    try {
      await ChildcareMessagingControllerClient.sendMessageToChildcareProvider({
        parentEmail: formValues.userEmail,
        parentFirstName: formValues.userFirstName,
        parentMessage: formValues.message,
        parentPhone: formValues.userMobilePhone,
        parentZipCode: searchInput || "",
        geoDataId: geoDataId || "",
        providerId: props.providerId,
      });

      PixelEventPush.CONTACT();

      setConfirmation(true);
    } catch (e) {
      // TODO: Localize error message: https://github.com/CarinaWeb/CarinaCore/issues/995
      actions.setStatus("Uh oh, there's been an error. Please try again later or contact us.");
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby="family-message-dialog"
      aria-describedby="family-message-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      {showConfirmation ? (
        <>
          <DialogTitle id="family-message-dialog" style={{ overflowWrap: "anywhere" }}>
            <b>{t("success modal header", { ns: nsChildcareMessageProviderForm })}</b>
          </DialogTitle>
          <DialogContent>
            <Body>{t("success modal body", { ns: nsChildcareMessageProviderForm })}</Body>
            <Box paddingY={2} textAlign="right" style={{ display: "flex", justifyContent: "flex-end" }}>
              <InternalLink
                to={searchListLink}
                style={{ textDecoration: "none", marginRight: 40, alignSelf: "center" }}
              >
                <BodyEmphasis>{t("return to results", { ns: nsChildcareMessageProviderForm })}</BodyEmphasis>
              </InternalLink>
              <Button onClick={() => props.onClose()} type="button" variant="contained">
                {t("okay", { ns: nsCommonCTAbtns })}
              </Button>
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="family-message-dialog">
            <b>
              {t("form header", { ns: nsChildcareMessageProviderForm })} {props.providerBusinessName}
            </b>
            <Body>{t("form header body", { ns: nsChildcareMessageProviderForm })}</Body>
          </DialogTitle>
          <DialogContent>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ isSubmitting, status }) => (
                <Form localizationReady={ready}>
                  <Body gutterBottom style={{ overflowWrap: "anywhere" }}>
                    <b>{t("to", { ns: nsChildcareMessageProviderForm })}:</b> {props.providerFirstName}
                  </Body>

                  <FormTextField
                    name="message"
                    label={t("message", { ns: nsCommonCTAbtns })}
                    placeholder={t("message placeholder", { ns: nsChildcareMessageProviderForm })}
                    helperText={""}
                    maximumlength={MAX_MESSAGE_LENGTH}
                    required={isRequiredField("message", validationSchema)}
                    multiline
                    minRows={4}
                  />

                  <Body gutterBottom mt={CivTheme.spacing(4)}>
                    <b>{t("my contact info", { ns: nsChildcareMessageProviderForm })}</b>
                  </Body>

                  <FormTextField
                    name="userFirstName"
                    label={t("first name", { ns: nsCommonForms })}
                    required={isRequiredField("userFirstName", validationSchema)}
                  />
                  <FormTextField
                    name="userEmail"
                    label={t("email", { ns: nsCommonForms })}
                    required={isRequiredField("userEmail", validationSchema)}
                  />
                  <FormTextField
                    name="userMobilePhone"
                    label={t("mobile phone", { ns: nsCommonForms })}
                    required={isRequiredField("userMobilePhone", validationSchema)}
                  />
                  {!initialValues.tos && (
                    <>
                      <Field
                        as={CheckboxControlLabel}
                        name="tos"
                        type="checkbox"
                        control={<Checkbox />}
                        label={
                          <Body>
                            {t("tos1", { ns: nsChildcareMessageProviderForm })}{" "}
                            <MuiLink href={ROUTES.termsOfService} target="_blank">
                              {t("tos2", { ns: nsChildcareMessageProviderForm })}
                            </MuiLink>{" "}
                            {t("tos3", { ns: nsChildcareMessageProviderForm })}
                          </Body>
                        }
                        style={{ marginTop: 16 }}
                      />
                      <CheckboxErrorMessage name="tos" BodyProps={{ style: { marginBottom: 16 } }} />
                    </>
                  )}

                  {status && <Body color="error">{status}</Body>}

                  <Grid container justifyContent="flex-end" style={{ paddingTop: 16, paddingBottom: 16 }}>
                    <Grid item>
                      <Button fullWidth onClick={() => props.onClose()} type="button" variant="text">
                        {t("Cancel", { ns: nsCommonCTAbtns })}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button fullWidth type="submit" variant="contained" disabled={isSubmitting}>
                        {isSubmitting ? "Sending..." : t("Send", { ns: nsCommonCTAbtns })}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MessageDialog;
