import { TFunction } from "i18next";
import { UserType } from "src/context/UserSessionContext";
import { LocizeLanguage, RoleType } from "src/generated/api_types";
import { LanguageLabel, LangKeyByUserLangPref } from "src/i18n/Languages";
import { nsCommonToasts, nsMedicaidBEContent, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { LocalizedTag } from "src/i18n/TagHelpers";

// Returns Locize key match for referral type WA & OR
export function getKeyForReferralType(referralType: string): string {
  const ReferralKeys: { [key: string]: string } = {
    seiu755union: "seiu755union",
    cdwa: "cdwa",
    individualprovider: "individual_provider",
    homecareclient: "homecare_client",
    afriendorfamily: "a_friend_or_family",
    carinaemails: "carina_emails",
    casemanager: "case_manager",
    carewell: "carewell",
    ohcc: "ohcc",
    inhomeprovider: "in_home_provider",
    risecarewell: "risecarewell",
    seiulocal503: "SEIU Local 503 (Union)",
    empty: "field.referral.label",
    seiu775benefitsgroup: "seiu775benefitsgroup",
    other: "other",
  };
  return ReferralKeys[referralType.toLowerCase()] || referralType;
}

// Returns Locize key for the role_display_name string defined on RoleConfig.
// If not found, default to the role_display_name that was given
export function getKeyForRoleDisplayName(roleDisplayName: string): string {
  const RoleKeys: { [key: string]: string } = {
    consumer: "consumer",
    provider: "provider",
    "user support agent": "user_support_agent",
    "referral coordinator": "referral_coordinator",
    "medicaid referral coordinator": "referral_coordinator",
    "case manager": "case_manager",
    "home care consumer": "home_care_consumer",
    "childcare provider": "childcare_provider",
    medicaidreferralcoordinator: "medicaid_referral_coordinator",
  };

  return RoleKeys[roleDisplayName.toLowerCase()] || roleDisplayName;
}

// Returns Locize key and namespace for a given UserType
export function getLocalizedUserType(userType: UserType): LocalizedTag {
  switch (userType) {
    case UserType.Consumer:
      return { key: "consumer", namespace: nsMedicaidTagsEnums };
    case UserType.Provider:
      return { key: "provider", namespace: nsMedicaidTagsEnums };
    case UserType.ProxyProvider:
      return { key: "proxy_provider", namespace: nsMedicaidTagsEnums };
    case UserType.ChildcareProvider:
      return { key: "childcare_provider", namespace: nsMedicaidTagsEnums };
    case UserType.MedicaidReferralCoordinator:
      return { key: "medicaid_referral_coordinator", namespace: nsMedicaidTagsEnums };
    case UserType.MedicaidCaseManager:
      return { key: "case_manager", namespace: nsMedicaidTagsEnums };
    case UserType.CaseWorker:
      return { key: "case_worker", namespace: nsMedicaidTagsEnums };
    case UserType.AgencyAdmin:
      return { key: "agency_admin", namespace: nsMedicaidTagsEnums };
    case UserType.FunderAdmin:
      return { key: "funder_admin", namespace: nsMedicaidTagsEnums };
    case UserType.CarinaAdmin:
      return { key: "carina_admin", namespace: nsMedicaidTagsEnums };
    case UserType.UserSupportAgent:
      return { key: "user_support_agent", namespace: nsMedicaidTagsEnums };
    case UserType.UserSupportManager:
      return { key: "user_support_manager", namespace: nsMedicaidTagsEnums };
    default:
      return { key: "User", namespace: nsMedicaidTagsEnums };
  }
}

export function getLocalizedRoleType(roleType: RoleType): LocalizedTag {
  switch (roleType) {
    case RoleType.CONSUMER:
      return { key: "consumer", namespace: nsMedicaidTagsEnums };
    case RoleType.PROVIDER:
      return { key: "provider", namespace: nsMedicaidTagsEnums };
    case RoleType.PROXY_PROVIDER:
      return { key: "proxy_provider", namespace: nsMedicaidTagsEnums };
    case RoleType.CHILDCARE_PROVIDER:
      return { key: "childcare_provider", namespace: nsMedicaidTagsEnums };
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
      return { key: "medicaid_referral_coordinator", namespace: nsMedicaidTagsEnums };
    case RoleType.MEDICAID_CASE_MANAGER:
      return { key: "case_manager", namespace: nsMedicaidTagsEnums };
    case RoleType.CASE_WORKER:
      return { key: "case_worker", namespace: nsMedicaidTagsEnums };
    case RoleType.USER_SUPPORT_AGENT:
      return { key: "user_support_agent", namespace: nsMedicaidTagsEnums };
    case RoleType.USER_SUPPORT_MANAGER:
      return { key: "user_support_manager", namespace: nsMedicaidTagsEnums };
    default:
      return { key: "User", namespace: nsMedicaidTagsEnums };
  }
}

//get Localized key for registration stepper labels
export type LocizeKey = LocalizedTag;

//components or functions using the next function should include the 'nsCommonToasts' ns
export function getGenericError(t: TFunction): string {
  return t("error.generic", "Something went wrong. Please try again later.", { ns: nsCommonToasts });
}

export function getLocalizedRegStep(displayName: string, t: TFunction): string {
  return t(`reg_step.${displayName}`, displayName, { ns: nsMedicaidBEContent });
}

export function getLocalizedSettingsResponse(respString: string, t: TFunction): string {
  return t(`set_user_settings_resp.${respString}`, getGenericError(t), {
    ns: nsMedicaidBEContent,
  });
}

export function getLocalizedEmailChangeResponse(respString: string, type: "success" | "error", t: TFunction): string {
  const key = `email_change_resp.${respString}`;
  if (type === "success") {
    return t(key, "Email has been successfully changed", { ns: nsMedicaidBEContent });
  }
  return t(key, getGenericError(t), { ns: nsMedicaidBEContent });
}

export function getLocalizedLangPrefChangeResponse(respUserLang: LocizeLanguage, t: TFunction): string {
  const langKey = LangKeyByUserLangPref[respUserLang];
  const langLabelVar = LanguageLabel[langKey];
  return t("success.we_updated_your_preferred_language", "We updated your language preference", {
    ns: nsCommonToasts,
    lng: langKey,
    newLangPref: langLabelVar,
  });
}

export function getLocalizedVerificationResponse(respString: string, type: "success" | "error", t: TFunction): string {
  const key = `verification_resp.${respString}`;
  if (type === "success") {
    return t(key, "Verification succeeded", { ns: nsMedicaidBEContent });
  }
  return t(key, getGenericError(t), { ns: nsMedicaidBEContent });
}
