import { Box, Snackbar as MSnackbar } from "@mui/material";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import civTheme from "src/themes/civilization/CivTheme";
import Alert from "./alert/Alert";
import { Body, BodyEmphasis } from "./Typography";

export const Snackbar = (props: { isEnabled?: boolean; onCloseCallback?: Function }) => {
  const { alertType, title, message, isOpen, autoHideTime, closeSnackbar, useMainSnackbar } = useNotification();
  const history = useHistory();
  const currentLocation = useRef(history.location.pathname);
  const alertRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!isOpen) return;
    currentLocation.current = history.location.pathname;
    setTimeout(() => {
      alertRef.current?.focus();
    }, 500);
    setTimeout(() => {
      alertRef.current?.blur(); // Removing focus so that autoHide works. Leaving 0.5s for focus to be set.
    }, 1000);
  }, [isOpen]);

  const handleClose = () => {
    closeSnackbar();
    props.onCloseCallback && props.onCloseCallback();
  };

  return (
    <MSnackbar
      open={isOpen && props.isEnabled}
      autoHideDuration={autoHideTime}
      // Default resumeHideDuration is autoHideDuration / 2 and since we don't want that, we need to override it here.
      // Also need to subtract 1s to account for the focus/blur action in the useEffect.
      resumeHideDuration={autoHideTime ? autoHideTime - 1000 : undefined}
      onClose={(_, reason) => {
        if (reason === "timeout") {
          handleClose();
        } else if (reason === "escapeKeyDown") {
          handleClose();
        } else if (reason === "clickaway" && useMainSnackbar) {
          const hasNavigated = history.location.pathname !== currentLocation.current;
          currentLocation.current = history.location.pathname;
          if (hasNavigated) closeSnackbar();
        }
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        left: 0, // left/top/right spreads snackbar to top edges of device on mobile
        top: 0,
        right: 0,
        [civTheme.breakpoints.up("sm")]: {
          width: "395px",
        },
        outline: "none",
        textAlign: "left",
      }}
      ref={alertRef}
      tabIndex={-1}
    >
      <Box sx={{ width: "100%" }}>
        <Alert variant="filled" severity={alertType} onClose={handleClose} elevation={6}>
          {title && <BodyEmphasis mb="12px">{title}</BodyEmphasis>}
          <Body>{message}</Body>
        </Alert>
      </Box>
    </MSnackbar>
  );
};
