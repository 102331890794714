import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "src/reusable_view_elements/Footer";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";

export enum PageType {
  MEDICAID,
}

/**
 * Convenience wrapper for pages. Tries to be agnostic of any styling regarding content.
 */
const PageWrapper = (
  props: PropsWithChildren<{
    title: string;
    type: PageType;
  }>,
) => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>

      {props.type === PageType.MEDICAID && <MedicaidNavbar />}

      <main id="main-content">{props.children}</main>
      <Footer />
    </>
  );
};

export default PageWrapper;
