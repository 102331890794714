import { EditNotificationsRounded } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { JobSearchProfileIcon, TaskChecklistCheckIcon } from "src/assets/icons/StreamlineIcons";
import { OnboardingStepType, RoleType } from "src/generated/api_types";
import { nsMedicaidOnboardingMain } from "src/i18n/Namespaces";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";

type CardContentType = {
  title: string;
  icon: React.ComponentType<SvgIconProps>;
  subtitle: string;
  linkUrl: string;
};

type CardStepType = {
  [s in keyof typeof OnboardingStepType]?: CardContentType;
};

type CardsContentRoleLevel = {
  [r in keyof typeof RoleType]?: CardStepType;
};

interface CardsContentMapType {
  [f: string]: CardsContentRoleLevel;
}

export const getCardContent = (state: string, roleType: RoleType, stepType: keyof typeof OnboardingStepType) => {
  const { t } = useTranslation([nsMedicaidOnboardingMain]);

  const PROVIDER_GUIDELINES_1: CardContentType = {
    title: t("agree_to_our_guidelines.label"),
    icon: TaskChecklistCheckIcon,
    subtitle: `1 ${t("min_read_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const PROVIDER_PROFILE_1: CardContentType = {
    title: t("create_your_profile.label"),
    icon: JobSearchProfileIcon,
    subtitle: `5 ${t("min_completion_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.medicaidProfile,
  };

  const CHILDCARE_PROVIDER_PROFILE_1: CardContentType = {
    title: t("create_your_profile.label"),
    icon: JobSearchProfileIcon,
    subtitle: `5 ${t("min_completion_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.childcareProfile,
  };

  const PROVIDER_SETTINGS_1: CardContentType = {
    title: t("set_up_notifications.label"),
    icon: EditNotificationsRounded,
    subtitle: `2 ${t("min_completion_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.medicaidSettings,
  };

  const CONSUMER_GUIDELINES_1: CardContentType = {
    title: t("agree_to_our_guidelines.label"),
    icon: TaskChecklistCheckIcon,
    subtitle: `1 ${t("min_read_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const CONSUMER_SETTINGS_1: CardContentType = {
    title: t("set_up_notifications.label"),
    icon: EditNotificationsRounded,
    subtitle: `2 ${t("min_completion_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.medicaidSettings,
  };

  const CASE_WORKER_GUIDELINES_1: CardContentType = {
    title: t("agree_to_our_guidelines.label"),
    icon: TaskChecklistCheckIcon,
    subtitle: `1 ${t("min_read_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const REFERRAL_COORDINATOR_GUIDELINES_1: CardContentType = {
    title: t("agree_to_our_guidelines.label"),
    icon: TaskChecklistCheckIcon,
    subtitle: `1 ${t("min_read_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const MEDICAID_CASE_MANAGER_GUIDELINES_1: CardContentType = {
    title: t("agree_to_our_guidelines.label"),
    icon: TaskChecklistCheckIcon,
    subtitle: `1 ${t("min_read_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const USER_SUPPORT_AGENT_GUIDELINES_1: CardContentType = {
    title: t("agree_to_our_guidelines.label"),
    icon: TaskChecklistCheckIcon,
    subtitle: `1 ${t("min_read_time.label")}`,
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const GUIDELINES_CARD_CONTENT_MODEL: CardContentType = {
    title: "Model card for Guidelines",
    icon: TaskChecklistCheckIcon,
    subtitle: "1 min read time",
    linkUrl: ONBOARDING_ROUTES.communityGuidelines,
  };

  const cardsContentMap: CardsContentMapType = {
    WA: {
      PROVIDER: {
        GUIDELINES: PROVIDER_GUIDELINES_1,
        PROFILE: PROVIDER_PROFILE_1,
        SETTINGS: PROVIDER_SETTINGS_1,
      },
      CONSUMER: {
        GUIDELINES: CONSUMER_GUIDELINES_1,
        SETTINGS: CONSUMER_SETTINGS_1,
      },
      SUPPORT: {
        GUIDELINES: GUIDELINES_CARD_CONTENT_MODEL,
      },
      CASE_WORKER: {
        GUIDELINES: CASE_WORKER_GUIDELINES_1,
      },
      MEDICAID_REFERRAL_COORDINATOR: {
        GUIDELINES: REFERRAL_COORDINATOR_GUIDELINES_1,
      },
      MEDICAID_CASE_MANAGER: {
        GUIDELINES: MEDICAID_CASE_MANAGER_GUIDELINES_1,
      },
      USER_SUPPORT_AGENT: {
        GUIDELINES: USER_SUPPORT_AGENT_GUIDELINES_1,
      },
      CHILDCARE_PROVIDER: {
        PROFILE: CHILDCARE_PROVIDER_PROFILE_1,
      },
    },
    OR: {
      PROVIDER: {
        GUIDELINES: PROVIDER_GUIDELINES_1,
        PROFILE: PROVIDER_PROFILE_1,
        SETTINGS: PROVIDER_SETTINGS_1,
      },
      CONSUMER: {
        GUIDELINES: CONSUMER_GUIDELINES_1,
        SETTINGS: CONSUMER_SETTINGS_1,
      },
      SUPPORT: {
        GUIDELINES: GUIDELINES_CARD_CONTENT_MODEL,
      },
      CASE_WORKER: {
        GUIDELINES: CASE_WORKER_GUIDELINES_1,
      },
      MEDICAID_REFERRAL_COORDINATOR: {
        GUIDELINES: REFERRAL_COORDINATOR_GUIDELINES_1,
      },
      MEDICAID_CASE_MANAGER: {
        GUIDELINES: MEDICAID_CASE_MANAGER_GUIDELINES_1,
      },
      USER_SUPPORT_AGENT: {
        GUIDELINES: USER_SUPPORT_AGENT_GUIDELINES_1,
      },
    },
    CA: {
      CHILDCARE_PROVIDER: {
        PROFILE: CHILDCARE_PROVIDER_PROFILE_1,
      },
    },
    IL: {
      CHILDCARE_PROVIDER: {
        PROFILE: CHILDCARE_PROVIDER_PROFILE_1,
      },
    },
    CT: {
      CHILDCARE_PROVIDER: {
        PROFILE: CHILDCARE_PROVIDER_PROFILE_1,
      },
    },
  };
  return cardsContentMap[state][roleType]![stepType];
};
