import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { nsCommonFormsBtns, nsCommonLogin, nsCommonToasts } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { AuthPageContainer } from "src/pages/auth/AuthPageContainer";
import { requestPasswordReset } from "src/pages/auth/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import BlockedUserErrorBox from "src/reusable_view_elements/BlockedUserErrorBox";
import Constraint from "src/reusable_view_elements/Constraint";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Button } from "src/reusable_view_elements/Button";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

interface ForgotPasswordProps {
  onSubmit: typeof requestPasswordReset;
}

interface ForgotPasswordFields {
  email: string;
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onSubmit }) => {
  const { showSnackbar } = useNotification();
  const { t, ready } = useTranslation([nsCommonLogin, nsCommonFormsBtns, nsCommonToasts]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState<string>("");

  const initialValues: ForgotPasswordFields = {
    email: "",
  };

  const validationSchema = object({
    email: string()
      .label(t("field.email.label.email", { ns: nsCommonFormsBtns }))
      .required(t("field.email.error.valid", { ns: nsCommonFormsBtns }))
      .email(t("field.email.error.valid", { ns: nsCommonFormsBtns })),
  });

  function handleSubmit(values: ForgotPasswordFields) {
    setIsSubmitting(true);
    setIsBlocked(false);
    setEmail(values.email);
    onSubmit(values)
      .then(() => {
        setShowConfirmation(true);
      })
      .catch((e: AxiosError) => {
        if (e.response?.data.indexOf("blocked") !== -1) {
          setIsBlocked(true);
        } else {
          showSnackbar(
            t(
              "error.submitting_password_reset_request",
              "Error submitting password reset request. Please try again later.",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        }
        setIsSubmitting(false);
      });
  }

  return (
    <AuthPageContainer>
      <Helmet>
        <title>Carina | Forgot Password</title>
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: "68vh",
        }}
      >
        <Grid
          item
          sm={8}
          style={{
            textAlign: "center",
          }}
        >
          {ready && showConfirmation && (
            <Constraint columns={8}>
              <SectionTitle>{t("email_sent.label", { ns: nsCommonLogin })}</SectionTitle>
              <Body>{t("email_sent.if_email_exists.description_v2", { ns: nsCommonLogin, email: email })}</Body>
              <Box p={2} />
              <Body>{t("email_sent.check_your_email.description_v2", { ns: nsCommonLogin })}</Body>
              <Box p={2} />
              <LinkStyledAsButton variant="outlined" component={Link} to={ROUTES.login}>
                {t("button.return_to_login", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Constraint>
          )}
          {ready && !showConfirmation && (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              <Form data-testid="login-form" localizationReady={ready}>
                <Constraint columns={8}>
                  <SectionTitle>{t("forgot_password.label", { ns: nsCommonLogin })}</SectionTitle>
                  <Body paragraph>{t("forgot_password.enter_email.description", { ns: nsCommonLogin })}</Body>
                  <Box maxWidth={400} textAlign="left" margin="0px auto">
                    <FormTextField
                      name="email"
                      label={t("field.email.label.email", { ns: nsCommonFormsBtns })}
                      required={isRequiredField("email", validationSchema)}
                    />
                    <FormAlert schema={validationSchema} reset={isBlocked} sx={{ mt: 3 }} />
                  </Box>
                  {isBlocked && <BlockedUserErrorBox sx={{ mt: 3 }} />}
                  <Button variant="contained" type="submit" disabled={isSubmitting} sx={{ mt: 2 }}>
                    {isSubmitting
                      ? t("button.sending", { ns: nsCommonFormsBtns })
                      : t("button.send_link_to_email", { ns: nsCommonFormsBtns })}
                  </Button>
                </Constraint>
              </Form>
            </Formik>
          )}
        </Grid>
      </Grid>
    </AuthPageContainer>
  );
};
