import { Redirect, Route, Switch } from "react-router-dom";
import { AgencyInviteDashboard } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyInviteDashboard";
import { getAgencyInvites } from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { CarinaAdminConsumerDashboard } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/CarinaAdminConsumerDashboard";
import { getAllConsumers } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/Queries";
import { CarinaAdminFunderDashboard } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/CarinaAdminFunderDashboard";
import { CarinaAdminProviderDashboard } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/CarinaAdminProviderDashboard";
import { getAllFunderAdmins } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/Queries";
import { getAllProviders } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/Queries";
import { GeocodingPage } from "src/pages/carinaAdmin/dashboard/GeocodingPage";

const CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX = "/homecare/admin";
export const CARINA_ADMIN_DASHBOARD_ROUTES = {
  root: CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX,
  agency_root: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency`,
  agency_list: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/list`,
  agency_invite: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/invite`,
  agency_details: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/details/:id`,
  agency_invite_details: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/invite/details/:id`,
  consumer_list: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer/list`,
  consumer_details: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer/list/:id`,
  funder_list: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/funder`,
  funder_details: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/funder/:id`,
  geocoding_page: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/geocoding`,
  provider_list: `${CARINA_ADMIN_DASHBOARD_ROUTE_PREFIX}/provider/list`,
};

const CarinaAdminDashboardRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={CARINA_ADMIN_DASHBOARD_ROUTES.agency_root}>
        <AgencyInviteDashboard getAgencyInvites={getAgencyInvites} />
      </Route>
      <Route path={CARINA_ADMIN_DASHBOARD_ROUTES.consumer_list}>
        <CarinaAdminConsumerDashboard getAllConsumers={getAllConsumers} />
      </Route>
      <Route path={CARINA_ADMIN_DASHBOARD_ROUTES.funder_list}>
        <CarinaAdminFunderDashboard getAllFunderAdmins={getAllFunderAdmins} />
      </Route>
      <Route exact path={CARINA_ADMIN_DASHBOARD_ROUTES.geocoding_page}>
        <GeocodingPage />
      </Route>
      <Route path={CARINA_ADMIN_DASHBOARD_ROUTES.provider_list}>
        <CarinaAdminProviderDashboard getAllProviders={getAllProviders} />
      </Route>
      <Redirect to={CARINA_ADMIN_DASHBOARD_ROUTES.agency_list} />
    </Switch>
  );
};

export default CarinaAdminDashboardRouter;
