import { useTranslation } from "react-i18next";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { nsMedicaidAccountPortal } from "src/i18n/Namespaces";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import { Medicaid_Case_Worker_Dashboard_Routes } from "src/pages/medicaidAccount/caseWorkerAccount/CaseWorkerRouter";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import { getRoutePrefix } from "src/pages/medicaidAccount/SharedHelperFunctions";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { BodyTitle } from "src/reusable_view_elements/Typography";

const CarinaTutorialsPortalCard = () => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidAccountPortal]);

  function getViewMorePath(): string {
    if (isUserType(UserType.Provider)) {
      return Medicaid_Provider_Dashboard_Routes.tutorial;
    }
    if (isUserType(UserType.MedicaidReferralCoordinator)) {
      return Medicaid_Coordinator_Dashboard_Routes.tutorial;
    }
    if (isUserType(UserType.CaseWorker)) {
      return Medicaid_Case_Worker_Dashboard_Routes.tutorial;
    }
    return getRoutePrefix(isUserType).tutorial;
  }

  if (!ready) {
    return <LoadingPortalCardSkeleton titleOnly />;
  }

  return (
    <PortalCard>
      <PortalCardTitle icon={SchoolRoundedIcon} viewMorePath={getViewMorePath()}>
        <BodyTitle>{t("carina_tutorials.label", { ns: nsMedicaidAccountPortal })}</BodyTitle>
      </PortalCardTitle>
    </PortalCard>
  );
};

export default CarinaTutorialsPortalCard;
