import { useTranslation } from "react-i18next";
import { ThreadFilterEnum } from "src/generated/api_types";
import { nsMedicaidInbox } from "src/i18n/Namespaces";
import { EmptyInbox } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListContainer from "src/pages/inbox/inboxComponents/ThreadListContainer";

const AllThreadsTabView = () => {
  const { t, ready } = useTranslation(nsMedicaidInbox);

  return (
    <ThreadListContainer
      threadFilter={ThreadFilterEnum.ALL}
      loadingText={ready ? t("loading.all_conversations.label", { ns: nsMedicaidInbox }) : ""}
    >
      <EmptyInbox title={t("you_have_zero_messages.label", { ns: nsMedicaidInbox })} />
    </ThreadListContainer>
  );
};

export default AllThreadsTabView;
