import { Box, Grid, Hidden, Link as MuiLink } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AxiosMessagingControllerClient, JobPostTagCategoryEnum, Permission } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidJobPost,
  nsMedicaidSearch,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { JobListOrigin, useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import AddressSearch, { FiltersType } from "src/pages/medicaidAccount/searchComponents/AddressSearch";
import AdvancedJobFilters from "src/pages/medicaidAccount/searchComponents/AdvancedJobFilters";
import FirstMessageDialog, { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import { bookmarkJob, unBookmarkJob } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { getDashboardPortalTitle } from "src/pages/supportAdminAccount/portal/SupportPortalMain";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import JobCardTranslated from "src/reusable_view_elements/JobCardTranslated";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MessageFollowUpButton from "src/reusable_view_elements/MessageFollowUpButton";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import Section from "src/reusable_view_elements/Section";
import { Snackbar } from "src/reusable_view_elements/Snackbar";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { scrollToId } from "src/utilities/ScrollToId";

const FindJobs = () => {
  const { showSnackbar, useMainSnackbar, closeSnackbar } = useNotification();
  const {
    isLoadingJobs,
    jobsList,
    setJobsList,
    setJobPostListOrigin,
    setJobIdList,
    setClearJobFilters,
    totalJobCount,
    setJobsPage,
    loadingNextPage,
    sendMsgDialogProps,
    setSendMsgDialogProps,
    setNextJobPageWasCalled,
    visitedJobIndex,
    setVisitedJobIndex,
    setIsJobFilterDirty,
    setIsInitialProvidersSearch,
  } = useMedicaidContext();
  const { userSession, isUserType, hasPermission } = useUserSession();
  const { setTotalUnreadCount } = useInboxContext();
  const { t, ready: translationReady } = useTranslation([
    nsMedicaidJobPost,
    nsMedicaidSearch,
    nsCommonFormsBtns,
    nsCommonToasts,
  ]);

  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [msgBtnWasClicked, setMsgBtnWasClicked] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string>();

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/

  const enableMessageSnackbar = () => {
    closeSnackbar();
    setMsgBtnWasClicked(true);
  };

  useEffect(() => {
    if (sendMsgDialogProps) {
      setMsgModalOpen(true);
    }
  }, [sendMsgDialogProps]);

  /**********************************************************************************************************
   ******* Next block drives infinite scrolling *************************************************************
   ****** more info: https://medium.com/suyeonme/react-how-to-implement-an-infinite-scroll-749003e9896a *****
   *********************************************************************************************************/
  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setNextJobPageWasCalled(true);
      setJobsPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "5px",
      threshold: 0.2,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver, loadingNextPage]);
  /************************************************* *********************************************************/
  /************************************************* *********************************************************
   The next two functions update the jobsList object without having to
   make a call to the BE. This prevents rerendering the list, which would break the focus sequence of
   any open 'first message success snackbar' or move the user back to the top when using infinite scrolling
   ************************************************* *********************************************************/
  const updateJobAfterFirstMessage = (threadId?: string) => {
    if (!threadId || !jobsList) return;
    const updatedJobList = jobsList?.map((job) => {
      return job.entity.id === selectedJobId ? { ...job, entity: { ...job.entity, threadIds: [threadId] } } : job;
    });
    setJobsList(updatedJobList);
  };
  /************************************************* *********************************************************/

  useEffect(() => {
    setJobIdList(jobsList.map((jobPost) => jobPost.entity.id));
    setJobPostListOrigin(JobListOrigin.FIND_JOBS);
    if (jobsList.length > 0 && visitedJobIndex !== -1) scrollToId(visitedJobIndex.toString());
  }, []);

  const dashboardNavBarTriggerFn = (programId: string) => {
    setIsJobFilterDirty(true);
    setIsInitialProvidersSearch(true);
    new AxiosMessagingControllerClient()
      .getUnreadThreadCount({ selectedFunderId: programId })
      .then((resp) => {
        setTotalUnreadCount(resp.data);
      })
      .catch(() =>
        showSnackbar(
          t(
            "error.fetching_unread_messages_count",
            "Error fetching unread messages count. Please try again or refresh browser.",
            { ns: nsCommonToasts },
          ),
          "error",
        ),
      );
  };

  const handleBookmarkClick = async (jobId: string, isBookmarked?: boolean) => {
    if (isBookmarked === undefined) {
      return;
    }
    try {
      if (isBookmarked) {
        await unBookmarkJob(jobId);
      } else {
        await bookmarkJob(jobId);
      }
      const updatedJobList = jobsList?.map((job) => {
        return job.entity.id === jobId ? { ...job, entity: { ...job.entity, bookmarked: !isBookmarked } } : job;
      });
      setJobsList(updatedJobList);
    } catch (e) {
      showSnackbar(
        t("error.processing_bookmark", "Error processing bookmark request. Please try again later.", {
          ns: nsCommonToasts,
        }),
        "error",
      );
    }
  };

  const getTitle = (): string | ReactElement => {
    if (!translationReady) return <Skeleton height={60} />;
    if (isLoadingJobs) return t("searching.label", { ns: nsMedicaidSearch });
    return t("count_jobs_near_you.label", { ns: nsMedicaidJobPost, count: totalJobCount });
  };

  return (
    <>
      <Helmet>
        <title>Carina | Medicaid Job List</title>
      </Helmet>

      {isUserType(UserType.UserSupportManager) && (
        <DashboardNavbar
          title={getDashboardPortalTitle(UserType.UserSupportManager)}
          withProgramDropdown
          searchTriggerFn={dashboardNavBarTriggerFn}
        />
      )}

      <MedicaidNavbar />

      <main id="main-content">
        <Box bgcolor={CivColors.lightGray} pt={3} pb={1}>
          <Constraint columns={8}>
            <SectionTitle align="center">{getTitle()}</SectionTitle>
          </Constraint>
        </Box>

        <Section bgcolor={CivColors.lightGray} paddingBottom={0} paddingTop={0}>
          <Constraint columns={12}>
            <AddressSearch DrawerContents={<AdvancedJobFilters />} filtersType={FiltersType.JOBS} />
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
          <Constraint columns={12}>
            <Grid container spacing={2}>
              {translationReady && (
                <Hidden smDown>
                  <Grid item style={{ width: "300px" }}>
                    <BodyTitle paragraph>{t("refine_your_search.label", { ns: nsMedicaidSearch })}</BodyTitle>
                    <AdvancedJobFilters />
                  </Grid>
                </Hidden>
              )}

              <Grid item sm container spacing={2}>
                {isLoadingJobs && (
                  <LoadingCircle
                    text={translationReady ? t("loading_your_search_results.label", { ns: nsMedicaidSearch }) : ""}
                  />
                )}

                {!isLoadingJobs && jobsList.length === 0 && translationReady && (
                  <Box textAlign="center" margin="0px auto" padding="100px 0px">
                    <Body paragraph>{t("currently_no_results.description", { ns: nsMedicaidSearch })}</Body>
                    <Trans
                      t={t}
                      i18nKey="you_can_clear_filters.description"
                      ns={nsMedicaidSearch}
                      components={{
                        clearfltr: (
                          <MuiLink component="button" variant="body1" onClick={() => setClearJobFilters(true)} />
                        ),
                      }}
                      values={{
                        clearfltr_key: t("clear_search_filters.label", { ns: nsMedicaidSearch }),
                      }}
                      parent={Body}
                    />
                  </Box>
                )}

                {!isLoadingJobs && jobsList.length > 0 && (
                  <>
                    {sendMsgDialogProps && (
                      <FirstMessageDialog
                        {...sendMsgDialogProps}
                        isDialogOpen={isMsgModalOpen}
                        onCloseDialog={(threadId) => {
                          updateJobAfterFirstMessage(threadId);
                          setMsgModalOpen(false);
                          setSendMsgDialogProps(undefined);
                        }}
                      />
                    )}
                    <Grid item style={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {jobsList.map((jobPost, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ display: "flex" }}
                              key={jobPost.entity.id}
                              id={index.toString()}
                              onClick={() => setVisitedJobIndex(index)}
                            >
                              <JobCardTranslated
                                jobPost={jobPost}
                                first_name={jobPost.entity.firstName || jobPost.entity.postingUserInfo.firstName}
                                schedule={jobPost.entity.scheduleNotes}
                                buttonLink={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: jobPost.entity.id })}
                                daysUp={jobPost.entity.daysPosted}
                                hideBookmark={userSession?.id === jobPost.entity.postingUserInfo.id}
                                bookmarked={jobPost.entity.bookmarked}
                                onBookmark={() => handleBookmarkClick(jobPost.entity.id, jobPost.entity.bookmarked)}
                                buttonSection={
                                  <Grid container spacing={2} direction="column" alignItems="center">
                                    <Grid item>
                                      <LinkStyledAsButton
                                        variant="contained"
                                        component={Link}
                                        to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: jobPost.entity.id })}
                                      >
                                        {t("button.view_job", { ns: nsCommonFormsBtns })}
                                      </LinkStyledAsButton>
                                    </Grid>

                                    {hasPermission(Permission.MESSAGING_ACCESS) &&
                                      userSession &&
                                      jobPost.entity.postingUserInfo.id !== userSession.id &&
                                      jobPost.entity.threadIds && (
                                        <Grid item>
                                          <MessageFollowUpButton
                                            variant="outlined"
                                            onMessageClick={() => {
                                              setSelectedJobId(jobPost.entity.id);
                                              enableMessageSnackbar();
                                              setSendMsgDialogProps({
                                                recipientId: jobPost.entity.postingUserInfo.id,
                                                recipientFirstName: jobPost.entity.postingUserInfo.firstName,
                                                recipientRole: jobPost.entity.postingUserInfo.role as UserType,
                                                contextJobPostId: jobPost.entity.id,
                                                context: FirstMessageContext.JOB_POST,
                                                pronouns:
                                                  jobPost.entity.postingUserInfo.role === UserType.Consumer
                                                    ? jobPost.entity.tags
                                                        .filter(
                                                          (tagObj) =>
                                                            tagObj.category === JobPostTagCategoryEnum.PRONOUN,
                                                        )
                                                        .sort((a, b) => a.order - b.order)
                                                        .map((tagObj) =>
                                                          t(tagObj.tag.toLowerCase(), { ns: nsMedicaidTagsEnums }),
                                                        )
                                                    : undefined,
                                              });
                                            }}
                                            threadIds={jobPost.entity.threadIds}
                                            otherUserId={jobPost.entity.postingUserInfo.id}
                                          />
                                          <Snackbar
                                            isEnabled={
                                              msgBtnWasClicked && index === visitedJobIndex && !useMainSnackbar
                                            }
                                            onCloseCallback={() => setMsgBtnWasClicked(false)}
                                          />
                                        </Grid>
                                      )}
                                  </Grid>
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </>
                )}

                {loadingNextPage && (
                  <Box textAlign="center" margin="0px auto" padding="100px 0px">
                    <Body>{translationReady ? t("loading.label", { ns: nsMedicaidSearch }) : "Loading..."}</Body>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Constraint>
        </Section>
      </main>

      {/* this div is used as a reference to trigger request for next page of results */}
      <div>
        {!loadingNextPage && <div ref={loader} />}
        <Footer />
      </div>
    </>
  );
};
export default FindJobs;
