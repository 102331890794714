import { Container, Divider, Tab, Tabs } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import PageContainerDashboard from "src/reusable_view_elements/PageContainerDashboard";

export enum DashboardTabs {
  CONSUMERS,
  PROVIDERS,
}

export interface DashboardContainerProps {
  tab: DashboardTabs;
}

export const DashboardContainer: React.FC<PropsWithChildren<DashboardContainerProps>> = ({ tab, children }) => {
  return (
    <PageContainerDashboard title="Agency Dashboard" maxWidth="xl">
      <Tabs value={tab}>
        <Tab component={Link} label="Clients" to={DASHBOARD_ROUTES.consumer_list} />
        <Tab component={Link} label="Providers" to={DASHBOARD_ROUTES.provider_list} />
      </Tabs>
      <Divider />
      <Container maxWidth={false}>{children!}</Container>
    </PageContainerDashboard>
  );
};
