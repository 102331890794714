import { Route, Switch } from "react-router-dom";
import { AgencyProviderListing_DTO_Response_ListView } from "src/generated/api_types";
import {
  FunderAdminDashboardContainer,
  FunderAdminDashboardTabs,
} from "src/pages/funderAdmin/dashboard/funder_admin_dashboard_components/FunderAdminDashboardContainer";
import { getAllFunderProviders as getProvidersType } from "src/pages/funderAdmin/dashboard/funder_admin_providers/Queries";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { FunderAdminProvidersList } from "./FunderAdminProvidersList";

export const {
  useClientsideDatatable: useFunderAdminProvidersDatatable,
  ClientsideDatatableContextProvider: FunderAdminProvidersDatatableContextProvider,
} = GenerateClientsideDatatable<AgencyProviderListing_DTO_Response_ListView, typeof getProvidersType>();

interface FunderAdminProviderDashboardContainerProps {
  getAllFunderProviders: typeof getProvidersType;
}

export const FunderAdminProviderDashboard: React.FC<FunderAdminProviderDashboardContainerProps> = ({
  getAllFunderProviders,
}) => {
  return (
    <FunderAdminDashboardContainer tab={FunderAdminDashboardTabs.PROVIDERS}>
      <FunderAdminProvidersDatatableContextProvider getData={getAllFunderProviders}>
        <Switch>
          <Route exact path={FUNDER_ADMIN_DASHBOARD_ROUTES.provider_list}>
            <FunderAdminProvidersList />
          </Route>
        </Switch>
      </FunderAdminProvidersDatatableContextProvider>
    </FunderAdminDashboardContainer>
  );
};
