import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { ChildcareProviderProfileResponseDetailWithDistanceDTO } from "src/generated/api_types";
import { nsChildcareProviderProfileView } from "src/i18n/Namespaces";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useChildCareContext } from "src/pages/childcare/ChildCareContext";
import { ChildcareControllerClient } from "src/pages/childcare/ControllerClients";
import MessageDialog from "src/pages/childcare/searchPages/components/MessageDialog";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { getDistanceInMiles, toTitleCase } from "src/utilities/GeneralUtilities";
import Map from "src/reusable_view_elements/Map";
import { scrollToId } from "src/utilities/ScrollToId";
import { CHILD_CARE_ROUTES } from "../ChildCareRouter";
import ChildCareProviderCard from "./components/ChildCareProviderCard";

const SearchListDetails = () => {
  const { t, ready } = useTranslation([nsChildcareProviderProfileView]);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [showMessageModal, setMessageModal] = useState(false);
  const searchListLink = `${CHILD_CARE_ROUTES.searchList}${location.search}`;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ name: string; message: string }>();
  const [providerData, setProviderData] = useState<ChildcareProviderProfileResponseDetailWithDistanceDTO>();
  const { searchInput } = useChildCareContext();

  useEffect(() => {
    setLoading(true);
    ChildcareControllerClient.getChildcareProviderProfileWithDistance(id, {
      distanceReferenceAddress: searchInput,
    })
      .then((res) => {
        setProviderData(res.data);
      })
      .catch((err) => {
        setError({ name: "Something went wrong", message: JSON.stringify(err) });
      })
      .finally(() => {
        setLoading(false);
        scrollToId(location.hash); // Mainly for skipping to the map if "Show Map" was clicked
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Carina | Child Care Provider Details</title>
      </Helmet>

      <NavBar showLanguage />
      <main id="main-content">
        <Section bgcolor={CivColors.lightGray} paddingBottom={3}>
          <Constraint columns={12}>
            <InternalLink to={searchListLink} underline="none">
              <Box display="flex">
                <ChevronLeftRoundedIcon />
                {ready && (
                  <BodyEmphasis>
                    {toTitleCase(t("return to results", { ns: nsChildcareProviderProfileView }))}
                  </BodyEmphasis>
                )}
              </Box>
            </InternalLink>
          </Constraint>
        </Section>

        {loading && (
          <Section bgcolor={CivColors.lightGray} centerContent minHeight={600}>
            <LoadingCircle />
          </Section>
        )}

        {!loading && error && (
          <Section bgcolor={CivColors.lightGray} centerContent minHeight={600} textAlign="center">
            <Constraint columns={8}>
              <SectionTitle>{error.name}</SectionTitle>
              <Body paragraph>{error.message}</Body>
              {ready && (
                <LinkStyledAsButton variant="contained" href={searchListLink}>
                  {toTitleCase(t("return to results", { ns: nsChildcareProviderProfileView }))}
                </LinkStyledAsButton>
              )}
            </Constraint>
          </Section>
        )}

        {!loading && !error && providerData && (
          <>
            <Section bgcolor={CivColors.lightGray} paddingTop={0}>
              <Constraint columns={12}>
                <ChildCareProviderCard
                  expertises={providerData.tagGroups.SPECIALIZED_SKILL || []}
                  hasOpenings={providerData.searchStatus === "SEEKING"}
                  milesAway={getDistanceInMiles(providerData.distance) || null}
                  location={
                    (providerData.address.city &&
                      providerData.address.state &&
                      `${providerData.address.city}, ${providerData.address.state}`) ||
                    providerData.address.generalizedLocation
                  }
                  businessName={
                    providerData.businessName || `${providerData?.firstName} ${providerData?.lastInitial[0]}.`
                  }
                  schedule={providerData.tagGroups.SCHEDULE || []}
                  description={providerData.about}
                  languages={providerData.tagGroups.LANGUAGE || []}
                  personName={`${providerData.firstName} ${providerData.lastInitial[0]}.`}
                  onButtonClick={() => setMessageModal(true)}
                  funderKey={providerData.funderKey}
                />

                <Box p={2} id="map" />

                <Map
                  containerStyle={{ width: "100%", height: 600 }}
                  longitude={providerData.longitude}
                  latitude={providerData.latitude}
                />
              </Constraint>
            </Section>

            <MessageDialog
              providerFirstName={providerData.firstName}
              providerId={providerData.providerId}
              providerBusinessName={
                providerData.businessName || `${providerData?.firstName} ${providerData?.lastInitial[0]}.`
              }
              open={showMessageModal}
              onClose={() => setMessageModal(false)}
            />
          </>
        )}
      </main>

      <Footer />
    </>
  );
};

export default SearchListDetails;
