import { Box, Dialog, Drawer, FormControl, Grid, Hidden, RadioGroup, SelectChangeEvent } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProviderProfileSort } from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidSearch } from "src/i18n/Namespaces";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import Select from "src/reusable_view_elements/form_fields/Select";
import { AddressField } from "src/reusable_view_elements/form_fields/address_autocomplete/AddressField";
import { SelectOption } from "src/reusable_view_elements/form_fields";
import ControlLabel from "src/reusable_view_elements/form_fields/ControlLabel";
import Radio from "src/reusable_view_elements/form_fields/Radio";
import { Button } from "src/reusable_view_elements/Button";

export enum FiltersType {
  JOBS,
  PROVIDERS,
}

interface AddressSearchProps {
  DrawerContents: ReactElement;
  filtersType: FiltersType;
}

const AddressSearch = (props: AddressSearchProps) => {
  const [isDrawerOpen, toggleDrawer] = useState<boolean>(false);
  const [isSortModalOpen, toggleSortModal] = useState<boolean>(false);
  const { jobSearchDTO, setIsJobFilterDirty, setJobsPage, providerSearchDTO, setIsProvFilterDirty, setProvidersPage } =
    useMedicaidContext();
  const { t, ready: translationReady } = useTranslation([nsMedicaidSearch, nsCommonFormsBtns]);

  const handleDropDownSelect = (e: SelectChangeEvent<any>) => handleValuesChange(e.target.name, e.target.value);

  const handleValuesChange = (fieldName: string, fieldValue: string | number | null) => {
    if (!fieldValue) return;
    // @ts-ignore
    if (props.filtersType === FiltersType.JOBS) {
      // @ts-ignore
      jobSearchDTO[fieldName] = fieldValue;
      setIsJobFilterDirty(true);
      setJobsPage(0);
    } else {
      // @ts-ignore
      providerSearchDTO[fieldName] = fieldValue;
      setIsProvFilterDirty(true);
      setProvidersPage(0);
    }
  };

  if (!translationReady) {
    return <Skeleton variant="rectangular" height={40} />;
  }

  const getTravelTimeOptions = (): SelectOption<number>[] => {
    return [
      {
        label: t("count_minutes.label", { ns: nsMedicaidSearch, count: 15 }),
        value: 15,
      },
      {
        label: t("count_minutes.label", { ns: nsMedicaidSearch, count: 30 }),
        value: 30,
      },
      {
        label: t("count_minutes.label", { ns: nsMedicaidSearch, count: 45 }),
        value: 45,
      },
      {
        label: t("count_minutes.label", { ns: nsMedicaidSearch, count: 60 }),
        value: 60,
      },
    ];
  };

  const getSortByOptions = (): SelectOption<string>[] => {
    return [
      {
        label: t("distance.label", { ns: nsMedicaidSearch }),
        value: "DISTANCE",
      },
      {
        label:
          props.filtersType === FiltersType.JOBS
            ? t("post_date.label", { ns: nsMedicaidSearch })
            : t("join_date.label", { ns: nsMedicaidSearch }),
        value: props.filtersType === FiltersType.JOBS ? "POST_DATE" : ProviderProfileSort.JOIN_DATE,
      },
    ];
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm>
          <AddressField
            name="address"
            label={t("address.label", { ns: nsMedicaidSearch })}
            value={props.filtersType === FiltersType.JOBS ? jobSearchDTO.address : providerSearchDTO.address}
            onChange={(selectedValue) => handleValuesChange("address", selectedValue)}
          />
        </Grid>

        {/* Travel time is handled in Accordion.tsx and AdvancedProviderFilters.tsx/AdvancedJob Filters.tsx on mobile */}
        <Hidden smDown>
          <Grid item>
            <Select
              sx={{ width: 200 }}
              label={t("max_travel_time.label", { ns: nsMedicaidSearch })}
              name="distanceMinutes"
              value={
                props.filtersType === FiltersType.JOBS
                  ? jobSearchDTO.distanceMinutes
                  : providerSearchDTO.distanceMinutes
              }
              onChange={handleDropDownSelect}
              selectOptions={getTravelTimeOptions()}
            />
          </Grid>
          <Grid item>
            <Select
              sx={{ width: 200 }}
              label={t("sort_by.label", { ns: nsMedicaidSearch })}
              name="sort"
              value={props.filtersType === FiltersType.JOBS ? jobSearchDTO.sort : providerSearchDTO.sort}
              onChange={handleDropDownSelect}
              selectOptions={getSortByOptions()}
            />
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Drawer open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
            <Box padding="16px" maxWidth="300px">
              {props.DrawerContents}
            </Box>
          </Drawer>
          <Dialog open={isSortModalOpen} fullWidth onClose={() => toggleSortModal(false)}>
            <Box padding={2}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  aria-label="sort options"
                  name="sort"
                  value={props.filtersType === FiltersType.JOBS ? jobSearchDTO.sort : providerSearchDTO.sort}
                  onChange={handleDropDownSelect}
                >
                  <ControlLabel
                    value="DISTANCE"
                    control={<Radio />}
                    label={t("distance.label", { ns: nsMedicaidSearch })}
                    style={{ height: "50px" }}
                  />
                  <ControlLabel
                    value={props.filtersType === FiltersType.JOBS ? "POST_DATE" : "JOIN_DATE"}
                    control={<Radio />}
                    label={
                      props.filtersType === FiltersType.JOBS
                        ? t("post_date.label", { ns: nsMedicaidSearch })
                        : t("join_date.label", { ns: nsMedicaidSearch })
                    }
                    style={{ height: "50px" }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Dialog>
          <Grid item container justifyContent="space-between">
            <Button variant="outlined" type="button" onClick={() => toggleDrawer(true)}>
              {t("button.filter", { ns: nsCommonFormsBtns })}
            </Button>
            <Button variant="outlined" type="button" onClick={() => toggleSortModal(true)}>
              {t("button.sort", { ns: nsCommonFormsBtns })}
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default AddressSearch;
