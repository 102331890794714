import { DialogContent, DialogContentProps, useMediaQuery } from "@mui/material";
import theme from "src/themes/civilization/CivTheme";

// Inherits the DialogContent MUI component, adds desktop and mobile-specific styling
// Pairs with DialogHeader
const DialogBody = ({ children, style, ...rest }: DialogContentProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  return (
    <DialogContent style={desktopSize ? { paddingTop: 0, ...style } : { paddingTop: 24, ...style }} {...rest}>
      {children}
    </DialogContent>
  );
};

export default DialogBody;
