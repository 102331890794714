import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { JobPostAndRepostDTO, JobPostListDTO, JobPostTagEnum } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobPost } from "src/i18n/Namespaces";
import CreateEditRepostJobForm, {
  JobFormType,
  JobPostFormFields,
} from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJobForm";
import { postMedicaidJob, updateJobPostInfo } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { getJobPostInfo } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PageWrapper, { PageType } from "src/reusable_view_elements/PageWrapper";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface Props {
  formType: JobFormType;
}

const CreateEditRepostJob = ({ formType }: Props) => {
  const { showSnackbar, useMainSnackbar, closeSnackbar } = useNotification();
  const { id } = useParams<{ id: string | undefined }>();
  const { verificationId } = useParams<{ verificationId: string }>();
  const { userSession, isUserType } = useUserSession();
  const [job, setJob] = useState<JobPostListDTO>();
  const [loadingJobDetails, setLoadingJobDetails] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const { t, ready } = useTranslation([nsCommonToasts, nsMedicaidJobPost, nsCommonFormsBtns]);
  const history = useHistory();

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/

  // Retrieve job info by id if it exists; Otherwise, we know we are creating a new job
  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setLoadingJobDetails(true);
          const res = await getJobPostInfo(id);
          setJob(res.data);
        } catch {
          showSnackbar(
            t("error.loading_your_job", "Sorry, there was a problem loading your job. Please try again later.", {
              ns: nsCommonToasts,
            }),
            "error",
          );
        } finally {
          setLoadingJobDetails(false);
        }
      })();
    }
  }, []);

  const handleSubmit = async (values: JobPostFormFields) => {
    const isJPTE = (item: JobPostTagEnum | undefined): item is JobPostTagEnum => {
      return !!item;
    };
    const tagsArray: JobPostTagEnum[] = [
      ...values.consumerGender,
      ...values.pronouns,
      ...values.providerGender,
      values.typeOfCare,
      values.ageRange,
      ...values.typeOfSupport,
      ...values.languages,
      ...values.skillsAndExperience,
      ...values.movement,
      ...values.transportation,
      ...values.environmentGeneral,
      ...values.workEnvironment,
      ...values.pets,
    ].filter(isJPTE);
    const jobPostOrRepostDTO: JobPostAndRepostDTO = {
      jobPostDTO: {
        tags: tagsArray,
        hours: values.hours || 0,
        hoursFlexible: values.hoursFlexible,
        scheduleNotes: values.scheduleNotes,
        scheduleFlexible: values.scheduleFlexible,
        furtherIntroduction: values.furtherIntroduction,
        address: values.address,
        userNotes: values.userNotes,
        firstName: values.consumerName,
      },
      verificationId,
    };

    try {
      setSaving(true);
      if (formType === JobFormType.JOB_CREATE) {
        await postMedicaidJob(jobPostOrRepostDTO);
        showSnackbar(
          { messageTitle: t("success.job_created_title"), messageBody: t("success.job_created_description") },
          "success",
          true,
        );
      }
      if (formType === JobFormType.JOB_EDIT && id) {
        await updateJobPostInfo(id, jobPostOrRepostDTO);
        showSnackbar(t("success.job_updated_description"), "success");
      }
      if (formType === JobFormType.JOB_REPOST && id) {
        await postMedicaidJob({
          ...jobPostOrRepostDTO,
          jobPostDTO: {
            ...jobPostOrRepostDTO.jobPostDTO,
            reposted: true,
          },
        });
        showSnackbar((t("success.job_reposted_title"), t("success.job_reposted_description")), "success", true);
      }
      history.push(getMedicaidRouteByUserType("myJobList", isUserType));
    } catch {
      showSnackbar(
        t("error.posting_your_job", "Sorry, there was an issue posting your job. Please try again later.", {
          ns: nsCommonToasts,
        }),
        "error",
      );
    } finally {
      setSaving(false);
    }
  };

  const getPageTitle = (): string => {
    switch (formType) {
      case JobFormType.JOB_REPOST:
        return ready ? t("browser_tab_title.repost_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Job Repost";
      case JobFormType.JOB_EDIT:
        return ready ? t("browser_tab_title.edit_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Job Edit";
      case JobFormType.JOB_CREATE:
        return ready ? t("browser_tab_title.post_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Post a Job";
      default:
        return ready ? t("browser_tab_title.post_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Post a Job";
    }
  };

  const getMainTitle = (): string => {
    switch (formType) {
      case JobFormType.JOB_REPOST:
        return t("repost_job.label", { ns: nsMedicaidJobPost });
      case JobFormType.JOB_EDIT:
        return t("edit_job.label", { ns: nsMedicaidJobPost });
      case JobFormType.JOB_CREATE:
        return t("post_a_job.label", { ns: nsMedicaidJobPost });
      default:
        return t("post_a_job.label", { ns: nsMedicaidJobPost });
    }
  };

  if (!ready) {
    return (
      <PageWrapper title={getPageTitle()} type={PageType.MEDICAID}>
        <Section bgcolor={CivColors.lightGray} minHeight="100vh">
          <Constraint columns={8} textAlign="center">
            <LoadingCircle />
          </Constraint>
        </Section>
      </PageWrapper>
    );
  }

  if (loadingJobDetails) {
    return (
      <PageWrapper title={getPageTitle()} type={PageType.MEDICAID}>
        <Section bgcolor={CivColors.lightGray} textAlign="center">
          <Constraint columns={6}>
            <SectionTitle gutterBottom>{getMainTitle()}</SectionTitle>
          </Constraint>
        </Section>
        <Section bgcolor={CivColors.lightGray} textAlign="center">
          <LoadingCircle />
        </Section>
      </PageWrapper>
    );
  }

  // Handle case where job id is invalid, or user is not the job post owner
  if ((id && !job) || (job && userSession?.id !== job.entity.postingUserInfo.id)) {
    return (
      <PageWrapper title={getPageTitle()} type={PageType.MEDICAID}>
        <Box bgcolor={CivColors.lightGray} textAlign="center" pt="10vh" pb="30vh">
          <Constraint columns={8}>
            <SectionTitle gutterBottom>{t("sorry_cannot_find.label", { ns: nsMedicaidJobPost })}</SectionTitle>
            <Body paragraph>{t("sorry_cannot_find.description", { ns: nsMedicaidJobPost })}</Body>
            <LinkStyledAsButton
              variant="contained"
              component={Link}
              to={getMedicaidRouteByUserType("myJobList", isUserType)}
            >
              {t("button.return_to_job_list", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
          </Constraint>
        </Box>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title={getPageTitle()} type={PageType.MEDICAID}>
      <Section bgcolor={CivColors.lightGray} textAlign="center" minimizeBottomGutter>
        <Constraint columns={6}>
          <SectionTitle gutterBottom>{getMainTitle()}</SectionTitle>
          <Body paragraph>{t("post_a_job.description.let_providers_know", { ns: nsMedicaidJobPost })}</Body>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
        <Constraint columns={8}>
          <CreateEditRepostJobForm
            handleSubmit={handleSubmit}
            formType={formType}
            jobPreFill={job?.entity}
            submitting={isSaving}
          />
        </Constraint>
      </Section>
    </PageWrapper>
  );
};

export default CreateEditRepostJob;
