import { Box, Grid, useMediaQuery } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsMedicaidJobSurveyConfirmation } from "src/i18n/Namespaces";
import { Circle1FilledIcon, Circle2FilledIcon } from "src/assets/icons/CustomIcons";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { FeatureList, FeatureListItem } from "src/reusable_view_elements/FeatureList";
import { ExternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const FilledViaCarina = () => {
  const { isUserType, isFunderState } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobSurveyConfirmation, nsCommonFormsBtns]);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  if (!ready) {
    return (
      <>
        <Section minimizeBottomGutter minHeight="100vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  return (
    <>
      <Section textAlign="center">
        <Constraint columns={8}>
          <SectionTitle>{t("job_post_deleted.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
          <Body paragraph>
            {t("job_post_deleted.description.your_answers_will", { ns: nsMedicaidJobSurveyConfirmation })}
          </Body>
          <Body paragraph>
            {t("job_post_deleted.description.you_can_repost", { ns: nsMedicaidJobSurveyConfirmation })}
          </Body>
          <Grid container>
            <Grid item xs={12}>
              <LinkStyledAsButton
                variant="outlined"
                component={Link}
                to={getMedicaidRouteByUserType("myJobList", isUserType)}
                sx={desktopSize ? { marginRight: "24px" } : { marginBottom: "24px" }}
              >
                {t("button.return_my_jobs", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
              <LinkStyledAsButton
                variant="outlined"
                component={Link}
                to={getMedicaidRouteByUserType("myDeletedJobs", isUserType)}
              >
                {t("button.view_deleted_jobs", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
          </Grid>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.paleBlue} textAlign="center">
        <Constraint columns={8}>
          <SectionTitle gutterBottom>{t("next_steps.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
          <FeatureList>
            <FeatureListItem
              icon={Circle1FilledIcon}
              primary={
                <BodyTitle sx={{ marginBottom: "16px" }}>
                  {isFunderState(FunderState.Washington) &&
                    t("check_with_cdwa.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {isFunderState(FunderState.Oregon) &&
                    t("check_with_case_manager.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Body>
                  {isFunderState(FunderState.Washington) &&
                    t("check_with_cdwa.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  {isFunderState(FunderState.Oregon) &&
                    t("check_with_case_manager.description", { ns: nsMedicaidJobSurveyConfirmation })}
                </Body>
              }
            />
            <Box marginBottom={6} />
            <FeatureListItem
              icon={Circle2FilledIcon}
              primary={
                <BodyTitle sx={{ marginBottom: "16px" }}>
                  {isFunderState(FunderState.Washington) &&
                    t("review_consumer_guide.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {isFunderState(FunderState.Oregon) &&
                    t("reach_out_to_erc.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Box>
                  {isFunderState(FunderState.Washington) && (
                    <Body>
                      <Trans
                        t={t}
                        i18nKey="review_consumer_guide.description.more_cdwa_details"
                        ns={nsMedicaidJobSurveyConfirmation}
                        components={{
                          cdwacr: (
                            <ExternalLink to="https://www.consumerdirectwa.com/client-resources/" target="_blank" />
                          ),
                        }}
                      />
                    </Body>
                  )}
                  {isFunderState(FunderState.Oregon) && (
                    <>
                      <Body paragraph>
                        {t("reach_out_to_erc.description.erc_is_free", { ns: nsMedicaidJobSurveyConfirmation })}
                      </Body>
                      <ExternalLink
                        to="https://www.oregon.gov/odhs/home-care-consumers/pages/employer-resource-connection.aspx"
                        target="_blank"
                      >
                        <Body>
                          {t("reach_out_to_erc.description.read_about_erc", { ns: nsMedicaidJobSurveyConfirmation })}
                        </Body>
                      </ExternalLink>
                    </>
                  )}
                </Box>
              }
            />
          </FeatureList>
        </Constraint>
      </Section>
    </>
  );
};

export default FilledViaCarina;
