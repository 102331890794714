import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY || "";

interface Props {
  containerStyle?: React.CSSProperties;
  zoom?: number;
  longitude: number;
  latitude: number;
  interactive?: boolean;
}

const defaultProps = {
  containerStyle: { width: "100%", height: "100%" },
  zoom: 14,
  interactive: false,
};

const ReactMap = (props: Props) => {
  return (
    <Map
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={{
        longitude: props.longitude,
        latitude: props.latitude,
        zoom: props.zoom,
      }}
      style={props.containerStyle}
      mapStyle="mapbox://styles/mapbox/light-v9"
      interactive={props.interactive}
    >
      <Source
        id="my-data"
        type="geojson"
        data={{
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [props.longitude, props.latitude],
          },
          properties: {},
        }}
      >
        <Layer
          id="point"
          type="circle"
          source="my-data"
          paint={{
            "circle-radius": 240,
            "circle-color": "#E54E52",
            "circle-opacity": 0.2,
          }}
        />
      </Source>
    </Map>
  );
};

ReactMap.defaultProps = defaultProps;
export default ReactMap;
