import { Redirect, Route, Switch } from "react-router-dom";
import ProviderProfileEdit from "src/pages/medicaidAccount/providerAccount/EditProviderProfile";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import ProviderProfileDetails from "src/pages/medicaidAccount/ProviderProfileDetails";
import ProviderTutorial from "src/pages/tutorials/ProviderTutorial";
import ThreadMatchingSurvey from "src/pages/inbox/ThreadMatchingSurvey";

const MEDICAID_ACCOUNT_PROVIDER_PREFIX = "/homecare/medicaid/provider";
export const Medicaid_Provider_Dashboard_Routes = {
  root: `${MEDICAID_ACCOUNT_PROVIDER_PREFIX}`,
  tutorial: `${MEDICAID_ACCOUNT_PROVIDER_PREFIX}/tutorial`,
  matchSurvey: `${MEDICAID_ACCOUNT_PROVIDER_PREFIX}/match-survey/thread/:id`,
  viewMyProfile: `${MEDICAID_ACCOUNT_PROVIDER_PREFIX}/profile`,
  editMyProfile: `${MEDICAID_ACCOUNT_PROVIDER_PREFIX}/profile/edit`,
};

const ProviderRouter = () => {
  return (
    <Switch>
      <Route exact path={Medicaid_Provider_Dashboard_Routes.tutorial}>
        <ProviderTutorial />
      </Route>
      <Route exact path={Medicaid_Provider_Dashboard_Routes.matchSurvey}>
        <ThreadMatchingSurvey />
      </Route>
      <Route exact path={Medicaid_Provider_Dashboard_Routes.viewMyProfile}>
        <ProviderProfileDetails />
      </Route>
      <Route exact path={Medicaid_Provider_Dashboard_Routes.editMyProfile}>
        <ProviderProfileEdit />
      </Route>
      <Redirect to={MEDICAID_ACCOUNT_ROUTES.homepage} />
    </Switch>
  );
};

export default ProviderRouter;
