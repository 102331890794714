import { Box, FormControlLabelProps, ListItemText, MenuItem, Select, SelectProps } from "@mui/material";
import { useState } from "react";
import Chip from "src/reusable_view_elements/Chip";
import { SelectOption } from "src/reusable_view_elements/form_fields";
import { Checkbox, CheckboxControlLabel } from "src/reusable_view_elements/form_fields/CheckboxField";
import { StyledSelectControl } from "src/reusable_view_elements/form_fields/Select";
import { StyledInputLabel } from "src/reusable_view_elements/form_fields/TextField";
import CivColors from "src/themes/civilization/CivColors";

interface SimpleChipsMultiSelectProps<T extends string> {
  options: SelectOption<T>[];
  selected: T[];
  selectionHandlerFn: (selected: T[]) => void;
}

const SimpleChipsMultiSelect = <T extends string>(props: SimpleChipsMultiSelectProps<T> & SelectProps) => {
  const { options, selected, selectionHandlerFn } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDeleteChip = (value: T) => {
    const result = selected.filter((item: string) => item !== value);
    selectionHandlerFn(result);
  };

  return (
    <div>
      <StyledInputLabel id={`simple-chips-multi-select-${props.name}`} htmlFor={props.name}></StyledInputLabel>
      <StyledSelectControl fullWidth variant="outlined">
        <Select
          labelId={`simple-chips-multi-select-${props.name}`}
          id={`simple-chips-multi-select-${props.name}`}
          multiple
          fullWidth
          displayEmpty
          value={selected}
          onChange={(event) => {
            selectionHandlerFn(event.target.value as T[]);
            if (props.onChange) props.onChange(event, null);
          }}
          onBlur={props.onBlur}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          disabled={props.disabled}
          renderValue={(selection: any) => (
            <>
              {selection.length > 0 && (
                <Box style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
                  {selection.map((value: T) => (
                    <Chip
                      key={value}
                      disabled={props.disabled}
                      label={options.find((option) => option.value === value)?.label || value}
                      onDelete={() => handleDeleteChip(value)}
                    />
                  ))}
                </Box>
              )}
            </>
          )}
          inputProps={{
            ...props.inputProps,
            id: props.name ? props.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase()) : "",
          }}
          SelectDisplayProps={{
            // @ts-ignore
            "data-testid": `button-${props.name}`,
            style: {
              background: props.disabled ? "inherit" : CivColors.white,
            },
          }}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            PaperProps: {
              sx: {
                "& .Mui-selected": {
                  backgroundColor: `${CivColors.white}!important`,
                },
                "& .Mui-selected:focus": {
                  backgroundColor: "rgba(236, 99, 62, 0.08)!important",
                },
                "& .Mui-focusVisible": {
                  backgroundColor: "rgba(45, 48, 69, 0.12)!important",
                },
              },
            },
          }}
        >
          {options
            .sort((a, b) => a.value.localeCompare(b.value))
            .map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selected.includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
        </Select>
      </StyledSelectControl>
    </div>
  );
};

type ICheckboxProps = {
  name: string;
  group: string;
  checkFn: (group: string, tag: string) => boolean;
} & Omit<FormControlLabelProps, "name" | "control">;

// Checkbox for tags that should Included in the jobs/providers results
export const ICheckbox = ({ label, name, group, checkFn, ...otherProps }: ICheckboxProps) => {
  return (
    <CheckboxControlLabel
      control={<Checkbox name={name} checked={checkFn(group, name)} />}
      label={label}
      {...otherProps}
    />
  );
};

type XCheckboxProps = {
  name: string;
  checkFn: (tag: string) => boolean;
} & Omit<FormControlLabelProps, "name" | "control">;

// Checkbox for tags that should eXcluded from the jobs/providers results
export const XCheckbox = ({ label, name, checkFn, ...otherProps }: XCheckboxProps) => {
  return (
    <CheckboxControlLabel control={<Checkbox name={name} checked={checkFn(name)} />} label={label} {...otherProps} />
  );
};

export default SimpleChipsMultiSelect;
