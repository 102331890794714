import { MapiResponse } from "@mapbox/mapbox-sdk/lib/classes/mapi-response";
import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import { Autocomplete } from "@mui/material";
import { FieldProps } from "formik";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import { addressFieldSearchOptions } from "src/reusable_view_elements/form_fields/address_autocomplete/AddressFieldUtilities";
import TextField, { TextFieldProps } from "src/reusable_view_elements/form_fields/TextField";

export type AddressFieldProps = {
  name: string;
  /** @optional. Indicates what to do when a new address is selected from the dropdown */
  onChange?: (newValue: string | null) => void;
  /** @optional. This prop enables the component to be used as child of a Formik's field component */
  formikFieldProps?: FieldProps;
  /** @optional. If true, the component will only allow zip code input and show zip code options, not full address */
  zipCodeOnly?: boolean;
} & Omit<TextFieldProps, "onChange">;

/**
 'AddressField' can be used as a standalone field. If the calling component needs to do something with the value selected
 from the dropdown menu, it can pass a function to the onChange prop (see 'AddressSearch' component
 for an example).

 If the AddressField needs to be inside a Formik form, use the 'FormAddressField' component instead, which wraps
 the AddressField component in a Formik Field component
 */

export const AddressField = ({ formikFieldProps, zipCodeOnly, ...otherProps }: AddressFieldProps) => {
  const { t, ready } = useTranslation([nsCommonFormsBtns]);
  const [options, setOptions] = useState<string[]>([]);
  const [fieldValue, setFieldValue] = useState("");

  const field = formikFieldProps?.field;
  const meta = formikFieldProps?.meta;
  const form = formikFieldProps?.form;

  const searchOptions = addressFieldSearchOptions(zipCodeOnly);
  const getSuggestions = searchOptions.getSuggestions;
  const formatOption = searchOptions.formatOption;

  useEffect(() => {
    if (!fieldValue && otherProps.value) setFieldValue(otherProps.value as string);
  }, [otherProps.value]);

  const dbGetSuggestions = debounce(getSuggestions, 400);

  const handleSelection = (event: React.ChangeEvent<{}>, newValue: string | null, reason: AutocompleteChangeReason) => {
    if (reason !== "selectOption" || !newValue || !options.includes(newValue)) return;
    form?.setFieldValue(otherProps.name, newValue) || setFieldValue(newValue);
    if (otherProps.onChange) otherProps.onChange(newValue);
  };

  const handleAutocomplete = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue.length > 0) {
      dbGetSuggestions(newValue, (response: MapiResponse) => {
        setOptions(response.body.features.map((feature: any) => formatOption(feature.place_name)) || []);
      });
    } else {
      setOptions([]);
    }
  };

  // If localization is not ready yet just display a field with the label "Loading..." on it
  if (!ready)
    return (
      <TextField
        variant="outlined"
        label="Loading..."
        id={otherProps.id}
        name={otherProps.name}
        fullWidth={otherProps.fullWidth}
        disabled
      />
    );

  return (
    <Autocomplete
      id={otherProps.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase())}
      fullWidth={otherProps.fullWidth}
      options={options}
      // disable built-in Autocomplete filtering to function better with keystroke fetching options
      // https://mui.com/material-ui/react-autocomplete/
      filterOptions={(_options) => _options}
      value={field?.value || fieldValue} //'field?.value' is used when the component is inside a Formik's field
      onBlur={(ev) => {
        //Next line keeps consistency between options and value in the field when blurring without selecting
        handleAutocomplete(ev, field?.value || fieldValue);
        field?.onBlur(ev);
      }}
      onChange={handleSelection}
      disabled={otherProps.disabled}
      renderInput={({ inputProps, ...restParams }) => (
        <TextField
          autoComplete="off"
          name={otherProps.name}
          label={t(`${otherProps.label}`, { ns: nsCommonFormsBtns })}
          helperText={meta && meta.touched && meta.error ? meta.error : otherProps.helperText}
          error={Boolean(meta && meta.touched && meta.error)}
          onChange={(ev) => handleAutocomplete(ev, ev.target.value)}
          inputProps={{ ...inputProps, "data-testid": otherProps.name }}
          required={otherProps.required}
          {...restParams}
        />
      )}
    />
  );
};
