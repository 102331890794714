import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { JobResponsesDTO } from "src/generated/api_types";
import { nsUniversalDatatables } from "src/i18n/Namespaces";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import Constraint from "src/reusable_view_elements/Constraint";
import { ServersideColumn, ServersideDatatable } from "src/reusable_view_elements/datatable/ServersideDatatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body } from "src/reusable_view_elements/Typography";
import { applyMMddyyyyFormat } from "src/utilities/DashboardUtilities";

const JobResponsesTabView = () => {
  const { t, ready } = useTranslation([nsUniversalDatatables]);

  interface JobResponsesColumn extends ServersideColumn<JobResponsesDTO> {}

  const JOB_RESPONSES_COLUMN_DEFINITIONS: { [k: string]: JobResponsesColumn } = {
    jobNumber: {
      title: t("job_num.label", { ns: nsUniversalDatatables }),
      field: "jobNumber",
      sortingField: "jobNumber",
      filteringField: "jobNumber",
      type: "string",
      render: (data) => {
        return (
          <InternalLink to={generatePath(MEDICAID_INBOX_ROUTES.jobResponseThreads, { jobPostId: data.id })}>
            <Body>{data.jobNumber}</Body>
          </InternalLink>
        );
      },
    },
    clientName: {
      title: t("client.label", { ns: nsUniversalDatatables }),
      field: "clientName",
      sortingField: "clientName",
      filteringField: "clientName",
      type: "string",
      render: (data) => <Body>{data.clientName}</Body>,
    },
    hours: {
      title: t("hours_per_month.label", { ns: nsUniversalDatatables }),
      field: "hours",
      sortingField: "hours",
      filteringField: "hours",
      type: "string",
    },
    numThreads: {
      title: t("num_threads.label", { ns: nsUniversalDatatables }),
      field: "numThreads",
      sortingField: "numThreads",
      filteringField: "numThreads",
      type: "string",
    },
    unreadMessageCount: {
      title: t("num_unread_messages.label", { ns: nsUniversalDatatables }),
      field: "unreadMessageCount",
      sortingField: "unreadMessageCount",
      filteringField: "unreadMessageCount",
      type: "string",
    },
    resolvedMethod: {
      title: t("resolved_method.label", { ns: nsUniversalDatatables }),
      field: "resolvedMethod",
      sortingField: "resolvedMethod",
      filteringField: "resolvedMethod",
      type: "string",
    },
    daysOpen: {
      title: t("days_open.label", { ns: nsUniversalDatatables }),
      field: "daysOpen",
      sortingField: "daysOpen",
      filteringField: "daysOpen",
      type: "string",
    },
    lastMessageDate: {
      title: t("last_message.label", { ns: nsUniversalDatatables }),
      field: "lastMessageDate",
      sortingField: "lastMessageDate",
      filteringField: "lastMessageDate",
      type: "string",
      render: (data) => <Body>{applyMMddyyyyFormat(data.lastMessageDate)}</Body>,
    },
    createdAt: {
      title: t("job_created.label", { ns: nsUniversalDatatables }),
      field: "createdAt",
      sortingField: "createdAt",
      filteringField: "createdAt",
      type: "string",
      render: (data) => <Body>{applyMMddyyyyFormat(data.createdAt)}</Body>,
    },
  };

  if (!ready) {
    return <LoadingCircle />;
  }

  return (
    <Constraint columns={14}>
      <ServersideDatatable<JobResponsesDTO, JobResponsesColumn>
        columnDefinitions={JOB_RESPONSES_COLUMN_DEFINITIONS}
        persistKeyPrefix="mrc-job-responses"
        dataFetchFn={(pageInfo) => messagingClient.getJobResponses(pageInfo)}
        runQueryInEffect
        options={{
          search: false,
        }}
        localization={{
          body: {
            filterRow: {
              filterTooltip: t("filter.label", { ns: nsUniversalDatatables }),
            },
          },
          pagination: {
            labelDisplayedRows: `{from}-{to} ${t("of.label", { ns: nsUniversalDatatables })} {count}`,
            // labelRowsSelect: t("rows.label", { ns: nsUniversalDatatables }), // TODO: Unsure which one to map to
            firstAriaLabel: t("first_page.label", { ns: nsUniversalDatatables }),
            firstTooltip: t("first_page.label", { ns: nsUniversalDatatables }),
            previousAriaLabel: t("previous_page.label", { ns: nsUniversalDatatables }),
            previousTooltip: t("previous_page.label", { ns: nsUniversalDatatables }),
            nextAriaLabel: t("next_page.label", { ns: nsUniversalDatatables }),
            nextTooltip: t("next_page.label", { ns: nsUniversalDatatables }),
            lastAriaLabel: t("last_page.label", { ns: nsUniversalDatatables }),
            lastTooltip: t("last_page.label", { ns: nsUniversalDatatables }),
          },
        }}
      />
    </Constraint>
  );
};

export default JobResponsesTabView;
