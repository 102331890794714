import Skeleton from "@mui/material/Skeleton";
import PortalCard from "src/reusable_view_elements/PortalCard";

interface LoadingPortalCardSkeletonProps {
  titleOnly?: boolean;
}

const LoadingPortalCardSkeleton = ({ titleOnly = false }: LoadingPortalCardSkeletonProps) => {
  return (
    <PortalCard>
      <Skeleton variant="rectangular" height={30} width="40%" style={{ marginBottom: 16 }} />
      {!titleOnly && <Skeleton variant="rectangular" height={100} />}
    </PortalCard>
  );
};

export default LoadingPortalCardSkeleton;
