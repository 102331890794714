import { Box, Grid, PaginationItem as MUIPaginationItem, PaginationRenderItemParams } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostListDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobPost } from "src/i18n/Namespaces";
import { useConsumerAccountContext } from "src/pages/medicaidAccount/consumerAccount/ConsumerAccountContext";
import { getDeletedJobPostListPage } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { JobListOrigin, useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { getRoutePrefix } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import JobCardTranslated from "src/reusable_view_elements/JobCardTranslated";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PageWrapper, { PageType } from "src/reusable_view_elements/PageWrapper";
import Pagination from "src/reusable_view_elements/Pagination";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { applyMMddyyyyFormat } from "src/utilities/DashboardUtilities";

const MyDeletedJobList = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    setMyDeletedJobIdList,
    deletedDisplayedPageNumber,
    setDeletedDisplayedPageNumber,
    deletedTotalPages,
    setDeletedTotalPages,
  } = useConsumerAccountContext();
  const { setJobPostListOrigin } = useMedicaidContext();
  const [myDeletedJobs, setMyDeletedJobs] = useState<JobPostListDTO[]>();
  const { showSnackbar } = useNotification();
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobPost, nsCommonFormsBtns, nsCommonToasts]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getDeletedJobPostListPage(deletedDisplayedPageNumber - 1);
        setMyDeletedJobs(res.data.content);
        setDeletedTotalPages(res.data.totalPages);
        setMyDeletedJobIdList(res.data.content.map((job) => job.entity.id));
        setJobPostListOrigin(JobListOrigin.DELETED);
      } catch {
        showSnackbar(
          t(
            "error.retrieving_your_jobs_list",
            "Sorry, there was an issue retrieving your list of jobs. Please try again later.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [deletedDisplayedPageNumber]);

  if (isLoading || !ready) {
    return (
      <PageWrapper title="Carina | My Deleted Jobs" type={PageType.MEDICAID}>
        <Section bgcolor={CivColors.lightGray} textAlign="center" minimizeBottomGutter>
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </PageWrapper>
    );
  }

  if (!myDeletedJobs || myDeletedJobs.length === 0) {
    return (
      <PageWrapper title="Carina | My Deleted Jobs" type={PageType.MEDICAID}>
        <Section bgcolor={CivColors.lightGray} textAlign="center" minimizeBottomGutter>
          <Constraint columns={6}>
            <SectionTitle gutterBottom>{t("my_deleted_job_posts.label", { ns: nsMedicaidJobPost })}</SectionTitle>
            <Body paragraph>{t("my_deleted_job_posts.description.jobs_past_365_days", { ns: nsMedicaidJobPost })}</Body>
            <Body>{t("my_deleted_job_posts.description.you_dont_have", { ns: nsMedicaidJobPost })}</Body>
          </Constraint>
        </Section>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title="Carina | My Deleted Jobs" type={PageType.MEDICAID}>
      <Section bgcolor={CivColors.lightGray} textAlign="center" minimizeBottomGutter>
        <Constraint columns={6}>
          <SectionTitle>{t("my_deleted_job_posts.label", { ns: nsMedicaidJobPost })}</SectionTitle>
          <Body paragraph>{t("my_deleted_job_posts.description.jobs_past_365_days", { ns: nsMedicaidJobPost })}</Body>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
        <Constraint columns={8}>
          {deletedTotalPages > 1 && (
            <Box display="flex" justifyContent="center" paddingY={6}>
              <Pagination
                page={deletedDisplayedPageNumber}
                count={deletedTotalPages}
                renderItem={(item: PaginationRenderItemParams) => {
                  return <MUIPaginationItem {...item} onClick={() => setDeletedDisplayedPageNumber(item.page || 1)} />;
                }}
              />
            </Box>
          )}
          <Grid container spacing={2} alignItems="stretch">
            {myDeletedJobs.map((jobPost) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", marginBottom: "24px" }}
                  key={jobPost.entity.id}
                  direction="column"
                  container
                >
                  <Grid item direction="column" wrap="nowrap" style={{ width: "100%" }} container>
                    {(isUserType(UserType.MedicaidReferralCoordinator) || isUserType(UserType.MedicaidCaseManager)) && (
                      <Grid item style={{ margin: "12px 14px", wordWrap: "break-word" }}>
                        <BodyEmphasis>{t("notes.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>
                        <Body>{jobPost.entity.userNotes || "-"}</Body>
                      </Grid>
                    )}
                    <Grid style={{ margin: "12px 14px" }}>
                      <BodyEmphasis>{t("post_date.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>
                      <Body>{applyMMddyyyyFormat(jobPost.entity.createdAt)}</Body>
                    </Grid>
                    <Grid style={{ margin: "12px 14px" }}>
                      <BodyEmphasis>{t("deletion_date.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>
                      <Body>{applyMMddyyyyFormat(jobPost.entity.resolvedDate) || "-"}</Body>
                    </Grid>
                    <Grid item style={{ margin: "12px 14px", wordWrap: "break-word" }}>
                      <BodyEmphasis>{t("reason_for_deletion.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>
                      <Body>{jobPost.entity.resolvedNotes || "-"}</Body>
                    </Grid>
                  </Grid>
                  <Grid item style={{ display: "flex", marginBottom: "24px", flexGrow: 1 }}>
                    <JobCardTranslated
                      jobPost={jobPost}
                      first_name={jobPost.entity.firstName || jobPost.entity.postingUserInfo.firstName}
                      schedule={jobPost.entity.scheduleNotes}
                      buttonLink={generatePath(getRoutePrefix(isUserType).myDeletedJobDetails, {
                        id: jobPost.entity.id,
                      })}
                      daysUp={jobPost.entity.daysPosted}
                      hideBookmark
                      buttonSection={
                        <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
                          <LinkStyledAsButton
                            variant="contained"
                            sx={{
                              margin: !isUserType(UserType.Consumer) ? "10px auto" : "20px auto",
                              alignSelf: "flex-end",
                            }}
                            component={Link}
                            to={generatePath(getRoutePrefix(isUserType).myDeletedJobDetails, { id: jobPost.entity.id })}
                          >
                            {t("button.view_job", { ns: nsCommonFormsBtns })}
                          </LinkStyledAsButton>
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Constraint>
      </Section>
    </PageWrapper>
  );
};
export default MyDeletedJobList;
