import { Redirect } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { Permission, User_AccountStatus } from "src/generated/api_types";
import { changeLanguage, LangKeyByUserLangPref } from "src/i18n/Languages";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import PageContainer from "src/reusable_view_elements/PageContainer";
import {
  getImpersonationReturnRoute,
  getImpersonationRoute,
  removeImpersonationReturnRoute,
  removeImpersonationRoute,
} from "src/reusable_view_elements/StartImpersonationButton";
import { SectionTitle } from "src/reusable_view_elements/Typography";

const oops = (
  <PageContainer>
    <SectionTitle>Oops, looks like there&apos;s been a problem!</SectionTitle>
  </PageContainer>
);

export const LoginResult: React.FC = () => {
  const { isUserType, userSession, isImpersonated, hasPermission } = useUserSession();

  const postLoginPath = sessionStorage.getItem("post-login-path");
  if (postLoginPath) {
    sessionStorage.removeItem("post-login-path");
    return <Redirect to={postLoginPath} />;
  }

  const impersonationReturnRoute = getImpersonationReturnRoute();

  if (impersonationReturnRoute && !isImpersonated()) {
    // user types that can impersonate
    if (
      isUserType(UserType.CarinaAdmin) ||
      isUserType(UserType.FunderAdmin) ||
      isUserType(UserType.UserSupportManager) ||
      isUserType(UserType.UserSupportAgent) ||
      isUserType(UserType.MedicaidReferralCoordinator)
    ) {
      const tempReturnRoute = impersonationReturnRoute;
      removeImpersonationReturnRoute();
      removeImpersonationRoute();
      return <Redirect to={tempReturnRoute} />;
    }
  }

  const impersonationRoute = getImpersonationRoute();
  if (impersonationRoute && isImpersonated()) {
    return <Redirect to={impersonationRoute} />;
  }

  if (isUserType(UserType.AgencyAdmin)) return <Redirect to={DASHBOARD_ROUTES.root} />;
  if (isUserType(UserType.CarinaAdmin)) return <Redirect to={CARINA_ADMIN_DASHBOARD_ROUTES.root} />;
  if (isUserType(UserType.FunderAdmin)) return <Redirect to={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_list} />;
  if (isUserType(UserType.UserSupportManager)) return <Redirect to={SUPPORT_ADMIN_DASHBOARD_ROUTES.portal} />;
  if (isUserType(UserType.UserSupportAgent)) {
    switch (userSession?.accountStatus) {
      case User_AccountStatus.NEW:
        return <Redirect to={ONBOARDING_ROUTES.root} />;
      case User_AccountStatus.ACTIVE:
        return <Redirect to={SUPPORT_ADMIN_DASHBOARD_ROUTES.portal} />;
      default:
        return oops;
    }
  }
  if (
    isUserType(UserType.Consumer) ||
    isUserType(UserType.MedicaidReferralCoordinator) ||
    isUserType(UserType.MedicaidCaseManager) ||
    isUserType(UserType.CaseWorker) ||
    isUserType(UserType.ProxyProvider) ||
    isUserType(UserType.Provider)
  ) {
    if (userSession?.languagePreference && hasPermission(Permission.SETTINGS_ACCESS)) {
      changeLanguage(LangKeyByUserLangPref[userSession.languagePreference], () => {});
    }

    switch (userSession?.accountStatus) {
      case User_AccountStatus.NEW:
        return <Redirect to={ONBOARDING_ROUTES.root} />;
      case User_AccountStatus.ACTIVE:
        return <Redirect to={MEDICAID_ACCOUNT_ROUTES.homepage} />;
      default:
        return oops;
    }
  }

  if (isUserType(UserType.ChildcareProvider)) {
    switch (userSession?.accountStatus) {
      case User_AccountStatus.NEW:
        return <Redirect to={ONBOARDING_ROUTES.root} />;
      case User_AccountStatus.ACTIVE:
        return <Redirect to={CHILD_CARE_ROUTES.homepage} />;
      default:
        return oops;
    }
  }

  return oops;
};
