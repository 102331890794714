import { IconButton, Menu, MenuItem, Box } from "@mui/material";
import { useState } from "react";
import { useNotification } from "src/context/NotificationContext";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { MedicaidInviteListDTO } from "src/generated/api_types";

const InviteListActionMenuButton = (props: { data: MedicaidInviteListDTO }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { showSnackbar } = useNotification();

  const generateInviteLink = (token: string) => {
    return `${window.location.origin}/register/homecare/medicaid/invite/${token}`;
  };
  const copyInviteLink = async (token: string) => {
    await navigator.clipboard.writeText(generateInviteLink(token));
    showSnackbar("Invite Link Copied!", "success");
  };
  const handleInvite = () => {
    copyInviteLink(props.data.token).then(() => {
      handleCloseMenu();
    });
  };

  return (
    <>
      <IconButton aria-controls="user-action-menu" aria-haspopup="true" onClick={handleIconClick} size="large">
        <AddRoundedIcon />
      </IconButton>
      <Menu id="user-action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={handleInvite}>
          <Box>Copy Invite Link</Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default InviteListActionMenuButton;
