import { Box, Grid, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Formik } from "formik";
import { useNotification } from "src/context/NotificationContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { usersAdministrationController } from "src/pages/supportAdminAccount/ControllerClients";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyEmphasis, PanelTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

interface ChangeUserEmailModalProps {
  currentEmail: string;
  showModal: boolean;
  handleModalClose: () => void;
  updateDisplayedEmail: (newEmail: string) => void;
}

const ChangeUserEmailModal = ({
  currentEmail,
  showModal,
  handleModalClose,
  updateDisplayedEmail,
}: ChangeUserEmailModalProps) => {
  const { showSnackbar } = useNotification();

  const CHANGE_NOTES_MAX_LENGTH = 500;

  const formValidationSchema = object({
    email: string()
      .required("Please enter a valid email address")
      .email("Please enter a valid email address")
      .notOneOf([currentEmail], "This is your current email"),
    changeNotes: string()
      .required("Please fill out the text box")
      .max(CHANGE_NOTES_MAX_LENGTH, "Exceeded maximum space for this field"),
  });

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Modal
        open={showModal}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick") {
            handleModalClose();
          }
        }}
        aria-labelledby="Change-user-email-modal"
        aria-describedby="Change User Email Modal"
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "#fff",
            border: 0,
            borderRadius: 2,
            color: "#000",
            top: "50%",
            left: 0,
            right: 0,
            padding: "26px 24px",
            transform: "translateY(-50%)",
            maxWidth: "584px",
            margin: "auto",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Grid container item justifyContent="space-between">
            <Grid item xs={10}>
              <PanelTitle id="change_user-email-modal-title" paragraph>
                Change Email
              </PanelTitle>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <IconButton onClick={handleModalClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box mt={6} mb={6}>
            <Body>Current email:</Body>
            <BodyEmphasis paragraph>{currentEmail}</BodyEmphasis>
          </Box>
          <Body>Enter new email address:</Body>

          <Formik
            initialValues={{ email: "", changeNotes: "" }}
            validationSchema={formValidationSchema}
            onSubmit={(values, helpers) => {
              usersAdministrationController
                .requestEmailChange({
                  currentEmail,
                  newEmail: values.email,
                  changeNotes: values.changeNotes,
                })
                .then((res) => {
                  helpers.setSubmitting(false);
                  showSnackbar(res.data, "success");
                  updateDisplayedEmail(values.email);
                  handleModalClose();
                })
                .catch((error) => {
                  helpers.setSubmitting(false);
                  const errorStatus = error.response.status;
                  if ((errorStatus === 409 || errorStatus === 400) && typeof error.response.data === "string") {
                    helpers.setFieldError("email", error.response.data);
                  } else {
                    helpers.setFieldError("email", "Something went wrong. Please try again later");
                  }
                });
            }}
          >
            {({ isSubmitting, dirty }) => (
              <Form localizationReady={true}>
                <Box mt={3} mb={6}>
                  <FormTextField
                    name="email"
                    label="Enter new email"
                    disabled={isSubmitting}
                    required={isRequiredField("email", formValidationSchema)}
                  />
                </Box>
                <Body>Enter the reason for the change in email address.</Body>
                <Box mt={3} mb={6}>
                  <FormTextField
                    name="changeNotes"
                    label="Why does this user need their email changed?"
                    type="text"
                    helperText={""}
                    required={isRequiredField("changeNotes", formValidationSchema)}
                    multiline
                    minRows={4}
                    maximumlength={CHANGE_NOTES_MAX_LENGTH}
                  />
                </Box>
                <Grid container justifyContent="flex-end">
                  <Button variant="text" type="button" onClick={handleModalClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={isSubmitting || !dirty}>
                    {isSubmitting ? "Saving Changes..." : "Save Changes"}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChangeUserEmailModal;
