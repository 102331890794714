import Skeleton from "@mui/material/Skeleton";
import PortalCard from "src/reusable_view_elements/PortalCard";

const LoadingFiltersSkeleton = () => {
  const spacing = 24;
  return (
    <PortalCard>
      <Skeleton variant="rectangular" height={50} style={{ marginBottom: spacing }} />
      <Skeleton variant="rectangular" height={300} style={{ marginBottom: spacing }} />
      <Skeleton variant="rectangular" height={30} style={{ marginBottom: spacing }} />
      <Skeleton variant="rectangular" height={30} />
    </PortalCard>
  );
};

export default LoadingFiltersSkeleton;
