import { Grid } from "@mui/material";
import { FormikContextType, isFunction, useFormikContext } from "formik";
import { Funder_DTO_Response_IdOnly } from "src/generated/api_types";
import DashSection from "src/reusable_view_elements/DashSection";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

export const validationSchema = {
  firstName: string().required("Error: Please enter a first name"),
  lastName: string().required("Error: Please enter a last name"),
  email: string()
    .required("Error: Please enter a valid email address")
    .email("Error: Please enter a valid email address"),
};
const formattedSchema = object(validationSchema);

export interface FunderAdminFormFields {
  firstName: string;
  lastName: string;
  email: string;
  funder: Funder_DTO_Response_IdOnly;
}

export type FunderAdminFormProps<Fields extends FunderAdminFormFields = FunderAdminFormFields> = {
  editable?: boolean | (keyof FunderAdminFormFields)[];
  children: (formikContext: FormikContextType<Fields>) => React.ReactNode;
};

export function FunderAdminForm<Fields extends FunderAdminFormFields = FunderAdminFormFields>({
  editable,
  children,
}: FunderAdminFormProps<Fields>) {
  if (!isFunction(children)) {
    throw new Error("FunderAdminForm child must be a function");
  }

  const formikContext = useFormikContext<Fields>();

  function isEditable(fieldName: keyof FunderAdminFormFields) {
    if (Array.isArray(editable)) return editable.includes(fieldName);
    return editable === true;
  }

  return (
    <Form name="funder-admin-invite" localizationReady={true}>
      <DashSection label="Funder Admin Information" container topBorder>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="firstName"
            label="First Name"
            disabled={!isEditable("firstName")}
            required={isRequiredField("firstName", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="lastName"
            label="Last Name"
            disabled={!isEditable("lastName")}
            required={isRequiredField("lastName", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="email"
            label="Email Address"
            disabled={!isEditable("email")}
            type="email"
            required={isRequiredField("email", formattedSchema)}
          />
        </Grid>
      </DashSection>

      {/* TODO: Needs design. TBD on what info is needed: https://github.com/CarinaWeb/CarinaCore/issues/994 */}
      {/*
        <DashSection label="Funder Information" container topBorder>
          <Grid item xs={12} md={8}>
            <FormTextField name="funder" label="Funder" disabled={!isEditable("funder")} type="funder" />
          </Grid>
        </DashSection>
      */}
      {children(formikContext)}
    </Form>
  );
}
FunderAdminForm.defaultProps = {
  editable: true,
};
