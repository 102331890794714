import { RoleType } from "src/generated/api_types";
import { object, string, date } from "yup";

export const medicaidValidationSchema = object().shape({
  firstName: string().required("Error: Please enter a first name"),
  lastName: string().required("Error: Please enter a last name"),
  email: string()
    .required("Error: Please enter a valid email address")
    .email("Error: Please enter a valid email address"),
  regPathId: string().required("Error: Please select a role"),
  role: string().required("Error: Please select a role"),
  providerVerification: string().when("role", {
    is: (role) => role === RoleType.PROVIDER,
    then: string().required("Please choose a verification method."),
  }),
  pLastFour: string().when("providerVerification", {
    is: (method) => method === "providerInfo",
    then: string()
      .required("Please enter Last Four #'s Of SSN")
      .matches(/^[0-9]{4}$/, { message: "Please provide 4 valid digits" }),
  }),
  pDOB: date().when("providerVerification", {
    is: (method) => method === "providerInfo",
    then: date()
      .required("Please enter Date Of Birth")
      .typeError("Please Check Date Format (MM/DD/YYYY)")
      .max(new Date(), "Date should not be in the future")
      .min("01/01/1900", "Date is too far into the past. Please verify the entered date"),
    otherwise: date()
      .nullable()
      .transform((value) => (!isNaN(new Date(value).getDate()) ? value : null)),
  }),
  providerID: string().when("providerVerification", {
    is: (method) => method === "provider_id",
    then: string().required("Please enter a provider number"),
  }),
  medicaidVerification: string().when("role", {
    is: (role) => role === RoleType.CONSUMER,
    then: string().required("Please choose a verification method."),
  }),
  medicaidUniqueID: string().when("medicaidVerification", {
    is: (method) => method === "medicaid_id",
    then: string().required("Please enter your Medicaid Unique ID"),
  }),
  lastFour: string().when("medicaidVerification", {
    is: (method) => method === "personalInfo",
    then: string()
      .required("Please enter Last Four #'s Of SSN")
      .matches(/^[0-9]{4}$/, { message: "Please provide 4 valid digits" }),
  }),
  dob: date().when("medicaidVerification", {
    is: (method) => method === "personalInfo",
    then: date()
      .required("Please enter Date Of Birth")
      .typeError("Please Check Date Format (MM/DD/YYYY)")
      .max(new Date(), "Date should not be in the future")
      .min("01/01/1900", "Date is too far into the past. Please verify the entered date"),
    otherwise: date()
      .nullable()
      .transform((value) => (!isNaN(new Date(value).getDate()) ? value : null)),
  }),
  organizationID: string().when("role", {
    is: (role) => role === RoleType.MEDICAID_REFERRAL_COORDINATOR,
    then: string().required("Please select an organization"),
  }),
  verifiersFirstName: string().when("role", {
    is: (role) => ![RoleType.PROVIDER, RoleType.CONSUMER].includes(role),
    then: string().required("Please enter Verifier's First Name"),
  }),
  verifiersLastName: string().when("role", {
    is: (role) => ![RoleType.PROVIDER, RoleType.CONSUMER].includes(role),
    then: string().required("Please enter Verifier's Last Name"),
  }),
  verifiersEmail: string().when("role", {
    is: (role) => ![RoleType.PROVIDER, RoleType.CONSUMER].includes(role),
    then: string().required("Please enter Verifier's Email").email("Please enter Verifier's Email"),
  }),
  reason: string().required("Please enter an invite reason."),
});

export interface MedicaidInviteFieldValues {
  firstName: string;
  lastName: string;
  email: string;
  regPathId: string;
  role: RoleType;
  medicaidVerification: string;
  providerVerification: string;
  pLastFour?: string;
  pDOB?: string;
  providerID?: string;
  medicaidUniqueID?: string;
  lastFour?: string;
  dob?: string;
  organizationID?: string;
  verifiersFirstName?: string;
  verifiersLastName?: string;
  verifiersEmail: string;
  reason: string;
}

export const medicaidInitialValues: MedicaidInviteFieldValues = {
  firstName: "",
  lastName: "",
  email: "",
  regPathId: "",
  role: RoleType.CONSUMER,
  medicaidVerification: "",
  providerVerification: "",
  pLastFour: "",
  pDOB: "",
  providerID: "",
  medicaidUniqueID: "",
  lastFour: "",
  dob: "",
  organizationID: "",
  verifiersFirstName: "",
  verifiersLastName: "",
  verifiersEmail: "",
  reason: "",
};
