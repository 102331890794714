import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  AxiosSupportControllerClient,
  SupportMessageDTO,
  SupportMessageProgram,
  SupportMessageRole,
  SupportMessageState,
} from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidTagsEnums, nsUnivContactUS } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { FormSelectField, FormTextField } from "src/reusable_view_elements/form_fields";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import PublicPrivateNavBar from "src/reusable_view_elements/navbars/PublicPrivateNavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { MenuItemType } from "src/utilities/GeneralTypes";
import { isRequiredField, phoneRegExp, zipRegExp } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";
import { useHistory } from "react-router-dom";

const SupportControllerClient = new AxiosSupportControllerClient();

const ContactUs = () => {
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const [formKey, setFormKey] = useState(0);
  const { userSession, isUserType } = useUserSession();
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const location: { state?: { from?: string } } = useLocation();
  const { t, ready } = useTranslation([nsUnivContactUS, nsCommonFormsBtns, nsMedicaidTagsEnums]);
  const [helperToggle, setHelperToggle] = useState(false);

  function getSupportMessageRole(): SupportMessageRole | undefined {
    // if (isUserType(UserType.Provider)) {
    //   return SupportMessageRole.PROVIDER;
    // }
    // if (isUserType(UserType.Consumer)) {
    //   return SupportMessageRole.CONSUMER;
    // }
    return undefined;
  }

  function getSupportMessageProgram(): SupportMessageProgram | undefined {
    // if (isUserType(UserType.Provider) || isUserType(UserType.Consumer)) {
    //   return SupportMessageProgram.MEDICAID_HOME_CARE;
    // }
    if (isUserType(UserType.AgencyAdmin)) {
      return SupportMessageProgram.PRIVATE_PAY_HOME_CARE;
    }
    return undefined;
  }

  const MESSAGE_MAX_LENGTH = 1000;

  const validationSchema = object({
    firstName: string()
      .label(t("field.first_name.label", "First name", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns })),
    lastName: string().label(t("field.last_name.label", "Last name", { ns: nsCommonFormsBtns })),
    email: string()
      .label(t("field.email.label.email", "Email", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .email(t("field.email.error.valid", "Please enter a valid email address", { ns: nsCommonFormsBtns })),
    phone: string()
      .label(t("field.phone_number.label", "Phone number", { ns: nsCommonFormsBtns }))
      .matches(phoneRegExp, {
        message: t("field.phone_number.error", "Please enter a valid phone number", { ns: nsCommonFormsBtns }),
      }),
    state: string()
      .label(t("field.state.label", "State", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns })),
    zipCode: string()
      .label(t("field.zip_code.label", "ZIP Code", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .matches(zipRegExp, {
        message: t("field.zip_code.error_valid", "Please enter a valid ZIP code", { ns: nsCommonFormsBtns }),
      }),
    program: string()
      .label(t("field.program_on_carina.label", "Role", { ns: nsCommonFormsBtns }))
      .label(t("field.program_on_carina.label", "Program on Carina", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns })),
    role: string()
      .label(t("field.role.label", "Role", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns })),
    message: string()
      .label(t("field.message.label", "Message", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(MESSAGE_MAX_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
  });
  const initialValues: SupportMessageDTO = {
    email: userSession?.email || "",
    firstName: userSession?.firstName || "",
    lastName: userSession?.lastName || "",
    message: "",
    phone: "",
    // TODO: Can undefined work without formik complaining about initialValues?:
    //  https://github.com/CarinaWeb/CarinaCore/issues/996
    // @ts-ignore
    program: getSupportMessageProgram(),
    // @ts-ignore
    role: getSupportMessageRole(),
    // @ts-ignore
    state: undefined,
    zipCode: "",
  };
  const ProgramOptions: Array<MenuItemType> = [
    {
      value: SupportMessageProgram.MEDICAID_HOME_CARE,
      label: t("medicaid_home_care", "Home Care (Medicaid or state funding)", { ns: nsMedicaidTagsEnums }),
    },
    {
      value: SupportMessageProgram.PRIVATE_PAY_HOME_CARE,
      label: t("private_pay_home_care", "Home Care (Out of pocket or without insurance)", { ns: nsMedicaidTagsEnums }),
    },
    { value: SupportMessageProgram.CHILD_CARE, label: t("child_care", "Child Care", { ns: nsMedicaidTagsEnums }) },
  ];
  const RoleOptions: Array<MenuItemType> = [
    { value: SupportMessageRole.CONSUMER, label: t("consumer", "Consumer", { ns: nsMedicaidTagsEnums }) },
    { value: SupportMessageRole.PROVIDER, label: t("provider", "Provider", { ns: nsMedicaidTagsEnums }) },
    { value: SupportMessageRole.CASE_MANAGER, label: t("case_manager", "Case Manager", { ns: nsMedicaidTagsEnums }) },
    {
      value: SupportMessageRole.FAMILY,
      label: t("parent_guardian", "Parent or Guardian", { ns: nsMedicaidTagsEnums }),
    },
    { value: SupportMessageRole.OTHER, label: t("other", "Other", { ns: nsMedicaidTagsEnums }) },
  ];
  const StateOptions: Array<MenuItemType> = [
    { value: SupportMessageState.CALIFORNIA, label: "California" },
    { value: SupportMessageState.CONNECTICUT, label: "Connecticut" },
    { value: SupportMessageState.ILLINOIS, label: "Illinois" },
    { value: SupportMessageState.NEW_YORK, label: "New York" },
    { value: SupportMessageState.WASHINGTON, label: "Washington" },
    { value: SupportMessageState.OREGON, label: "Oregon" },
    { value: SupportMessageState.OTHER, label: t("other", "Other", { ns: nsMedicaidTagsEnums }) },
  ];

  async function handleSubmit(formValues: SupportMessageDTO) {
    try {
      setSendingMessage(true);
      await SupportControllerClient.messageSupport({
        email: formValues.email,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        message: formValues.message,
        phone: formValues.phone,
        program: formValues.program,
        role: formValues.role,
        state: formValues.state,
        zipCode: formValues.zipCode,
        referrer: location.state?.from,
      });
      showSnackbar(
        t("success.thank_you_reaching_out", "Your message has been sent", { ns: nsCommonToasts }),
        "success",
      );
      history.push(ROUTES.contactUs);
      setFormKey((prev) => prev + 1);
    } catch (e) {
      showSnackbar(t("error.message_sent", "Unable to send message", { ns: nsCommonToasts }), "error");
    } finally {
      setSendingMessage(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Carina | Contact Us</title>
      </Helmet>

      <PublicPrivateNavBar showLanguage />

      <main id="main-content">
        <Section>
          <Constraint columns={8}>
            <SectionTitle paragraph>{t("contact_us.label", "Contact us", { ns: nsUnivContactUS })}</SectionTitle>
            <Body paragraph>
              {t(
                "contact_us.description.fill_out",
                "Please fill out the form below. We will get back to you within a few business days.",
                { ns: nsUnivContactUS },
              )}
            </Body>
            <Body paragraph>
              {t("contact_us.description.you_can_call", "You can also call us at 1-855-796-0605.", {
                ns: nsUnivContactUS,
              })}
            </Body>
            <Body paragraph>
              {t("business_hours.label", "Business Hours:", { ns: nsUnivContactUS })}
              <br />
              {t("business_hours.days_range.description", "Monday - Friday,", { ns: nsUnivContactUS })}
              <br />
              {t("business_hours.time_range.description", "8:00 AM - 4:30 PM PT", { ns: nsUnivContactUS })}
            </Body>
            <Body paragraph>
              {t("location.label", "Location:", { ns: nsUnivContactUS })}
              <br />
              215 Columbia Street, Suite 300
              <br />
              Seattle, WA 98104
            </Body>
            <Formik
              key={formKey}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form localizationReady={ready}>
                  <Grid container spacing={3} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name="firstName"
                        label={t("field.first_name.label", "First name", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("firstName", validationSchema)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name="lastName"
                        label={t("field.last_name.label", "Last name", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("lastName", validationSchema)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name="email"
                        label={t("field.email.label.email", "Email", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("email", validationSchema)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FastMaskTextField
                        name="phone"
                        maskType="phone"
                        label={t("field.phone_number.label", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("phone", validationSchema)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormSelectField
                        label={t("field.state.label", "State", { ns: nsCommonFormsBtns })}
                        name="state"
                        required={isRequiredField("state", validationSchema)}
                        selectOptions={StateOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name="zipCode"
                        label={t("field.zip_code.label", "ZIP Code", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("zipCode", validationSchema)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormSelectField
                        selectOptions={ProgramOptions}
                        name="program"
                        label={t("field.program_on_carina.label", "Program on Carina", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("program", validationSchema)}
                        onChange={() => {
                          setFieldValue("role", initialValues.role);
                          setHelperToggle(!helperToggle);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormSelectField
                        selectOptions={RoleOptions.filter((role) =>
                          values.program === SupportMessageProgram.CHILD_CARE
                            ? role.value === SupportMessageRole.FAMILY ||
                              role.value === SupportMessageRole.PROVIDER ||
                              role.value === SupportMessageRole.OTHER
                            : role.value === SupportMessageRole.CONSUMER ||
                              role.value === SupportMessageRole.PROVIDER ||
                              role.value === SupportMessageRole.CASE_MANAGER ||
                              role.value === SupportMessageRole.OTHER,
                        )}
                        name="role"
                        label={t("field.role.label", "Role", { ns: nsCommonFormsBtns })}
                        required={isRequiredField("role", validationSchema)}
                        controlledRerender={helperToggle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormTextField
                        label={t("field.message.label", "Message", { ns: nsCommonFormsBtns })}
                        name="message"
                        placeholder={t(
                          "field.message.placeholder_contact_us",
                          "Share your questions, concerns or anything that you need assistance with in as much" +
                            "detail as you can.",
                          { ns: nsCommonFormsBtns },
                        )}
                        helperText={""}
                        required={isRequiredField("message", validationSchema)}
                        maximumlength={MESSAGE_MAX_LENGTH}
                        multiline
                        minRows={8}
                      />
                    </Grid>
                  </Grid>
                  <FormAlert schema={validationSchema} sx={{ mt: 3 }} />
                  <Box textAlign="right" mt={3}>
                    <Button type="submit" variant="contained" disabled={sendingMessage}>
                      {sendingMessage
                        ? t("button.submitting", "Submitting...", { ns: nsCommonFormsBtns })
                        : t("button.submit", "Submit", { ns: nsCommonFormsBtns })}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Constraint>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default ContactUs;
