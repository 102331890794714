import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import Alert, { AlertProps } from "src/reusable_view_elements/alert/Alert";
import { Body } from "src/reusable_view_elements/Typography";

interface FormAlertProps extends AlertProps {
  schema: any;
  reset?: any;
}

const FormAlert = ({ schema, reset, ...restProps }: FormAlertProps) => {
  const { t } = useTranslation(nsCommonFormsBtns);
  const { errors: currentErrors, isSubmitting } = useFormikContext();
  const [staticErrors, setStaticErrors] = useState({});
  const count = Object.keys(staticErrors).length;

  useEffect(() => {
    if (!isSubmitting) {
      setStaticErrors(currentErrors);
    }
  }, [isSubmitting]);

  useEffect(() => {
    setStaticErrors({});
  }, [reset]);

  function getLabel(fieldName: string): string {
    return schema.fields[fieldName]._label;
  }

  if (Object.keys(staticErrors).length === 0) {
    return <></>;
  }

  return (
    <Alert severity="error" title={t("alert.count_fields_have_errors.label", { count })} {...restProps}>
      <ul style={{ listStylePosition: "inside", paddingLeft: 0, marginBottom: 0 }}>
        {Object.keys(staticErrors).map((error) => (
          <li key={"alert-" + error}>
            <Body sx={{ display: "inline" }}>{getLabel(error)}</Body>
          </li>
        ))}
      </ul>
    </Alert>
  );
};

export default FormAlert;
