import { CssBaseline } from "@mui/material";
import { ThemeProvider as MThemeProvider } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import CivTheme from "src/themes/civilization/CivTheme";

function Theme(props: PropsWithChildren<{}>) {
  return (
    <MThemeProvider theme={CivTheme}>
      <CssBaseline />
      {props.children}
    </MThemeProvider>
  );
}

export default Theme;
