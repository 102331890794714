import { Box, Grid, Link as MuiLink, TypographyProps } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  DeleteJobPostDTO,
  ResolvedMethodEnum,
  SurveyJobInfoDTO,
  SurveyProviderDTO,
  TestimonialSourceEnum,
} from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobSurvey, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { Medicaid_Consumer_Dashboard_Routes } from "src/pages/medicaidAccount/consumerAccount/ConsumerRouter";
import FilledViaCarina from "src/pages/medicaidAccount/consumerAccount/jobDeletionConfirmation/FilledViaCarina";
import FilledViaOtherMeans from "src/pages/medicaidAccount/consumerAccount/jobDeletionConfirmation/FilledViaOtherMeans";
import Unfilled from "src/pages/medicaidAccount/consumerAccount/jobDeletionConfirmation/Unfilled";
import { deleteJobPost, getAllMessagedProvidersForDropdown } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { getSurveryJobPostInfo } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import {
  FormRadioGroupField,
  FormSelectField,
  FormTextField,
  FormMultiSelectChipsField,
  SelectOption,
} from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

function getProviderNameOptions(data: SurveyProviderDTO[] | undefined): SelectOption<string>[] {
  if (Array.isArray(data)) {
    return [
      ...data
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map((provider) => {
          return {
            label: `${provider.firstName} ${provider.lastName}`,
            value: `${provider.id}`,
          };
        }),
    ];
  }
  return [{ label: "Unassigned", value: "unassigned" }];
}

export interface JobDeleteSurveyData {
  reasonForDeletion?: ResolvedMethodEnum;
  selectedProviders: string[];
  furtherInformation: string;
  testimonialConsent: boolean;
  testimonialSource?: TestimonialSourceEnum;
  testimonialName?: string;
}

const DeleteJobSurvey = () => {
  const { id: jobId } = useParams<{ id: string }>();
  const { showSnackbar } = useNotification();
  const [isLoadingJobPost, setLoadingJobPost] = useState(false);
  const [jobPost, setJobPost] = useState<SurveyJobInfoDTO>();
  const [fetchingProviders, setFetchingProviders] = useState<boolean>(false);
  const [providerList, setProviderList] = useState<SurveyProviderDTO[]>([]);
  const [showConfirmationByCarina, setShowConfirmationByCarina] = useState<boolean>(false);
  const [showConfirmationByOther, setShowConfirmationByOther] = useState<boolean>(false);
  const [showConfirmationUnfilled, setShowConfirmationUnfilled] = useState<boolean>(false);
  const [deletingJob, setDeletingJob] = useState<boolean>(false);
  const { isUserType, userSession } = useUserSession();
  const { t, ready } = useTranslation([nsCommonToasts, nsMedicaidJobSurvey, nsCommonFormsBtns, nsMedicaidTagsEnums]);

  const FURTHER_INFO_MAX = 500;

  useEffect(() => {
    (async () => {
      try {
        setLoadingJobPost(true);
        const resJobPost = await getSurveryJobPostInfo(jobId);
        setJobPost(resJobPost.data);

        setFetchingProviders(true);
        const resProviders = await getAllMessagedProvidersForDropdown();
        setProviderList(resProviders.data);
      } catch (e) {
        showSnackbar(
          t("error.generic", "Something went wrong. Please try again later.", { ns: nsCommonToasts }),
          "error",
        );
      } finally {
        setLoadingJobPost(false);
        setFetchingProviders(false);
      }
    })();
  }, [jobId]);

  const PageWrapper: React.FC = (props) => (
    <>
      <Helmet>
        <title>
          {ready
            ? t("browser_tab_title.job_delete_survey", { ns: nsMedicaidJobSurvey })
            : "Carina | Medicaid Job Delete Survey"}
        </title>
      </Helmet>
      <MedicaidNavbar />
      {props.children}
      <Footer />
    </>
  );

  const FURTHER_INFO_MAX_LENGTH = 500;
  const validationSchema = object({
    reasonForDeletion: string()
      .label(t("why_are_you_deleting.label", { ns: nsMedicaidJobSurvey }))
      .required(t("field.generic.error.select_one_these_options", { ns: nsCommonFormsBtns })),
    selectedProviders: string()
      .label(
        t("who_did_you_choose.label", {
          ns: nsMedicaidJobSurvey,
        }),
      )
      .when("reasonForDeletion", {
        is: (val) => val === ResolvedMethodEnum.FILLED_CARINA,
        then: string().required(t("field.survey_providers.error", { ns: nsCommonFormsBtns })),
      }),
    furtherInformation: string()
      .label(t("field.survey_comments.placeholder", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(FURTHER_INFO_MAX_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    testimonialSource: string()
      .label(t("field.i_am.label", { ns: nsCommonFormsBtns }))
      .when("testimonialConsent", {
        is: (val) => val === true && isUserType(UserType.Consumer),
        then: string().required(t("field.i_am.error", { ns: nsCommonFormsBtns })),
      }),
    testimonialName: string()
      .label(t("field.first_name.label", { ns: nsCommonFormsBtns }))
      .when("testimonialSource", {
        is: (val) =>
          isUserType(UserType.Consumer) &&
          (val === TestimonialSourceEnum.FRIEND_FAMILY || val === TestimonialSourceEnum.OTHER_REPRESENTATIVE),
        then: string().required(t("field.first_name.error.name_for_testimonial", { ns: nsCommonFormsBtns })),
      }),
  });

  const initialValues: JobDeleteSurveyData = {
    reasonForDeletion: undefined,
    selectedProviders: [],
    furtherInformation: "",
    testimonialConsent: false,
    testimonialSource: undefined,
    testimonialName: "",
  };

  function getTestimonialSource(values: JobDeleteSurveyData) {
    if (values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA && values.testimonialConsent) {
      if (isUserType(UserType.Consumer)) {
        return values.testimonialSource;
      }
      return TestimonialSourceEnum.SELF_REPORT;
    }
    return undefined;
  }

  function getTestimonialName(values: JobDeleteSurveyData) {
    if (values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA && values.testimonialConsent) {
      if (
        isUserType(UserType.Consumer) &&
        (values.testimonialSource === TestimonialSourceEnum.FRIEND_FAMILY ||
          values.testimonialSource === TestimonialSourceEnum.OTHER_REPRESENTATIVE)
      ) {
        return values.testimonialName;
      }
      return userSession?.firstName;
    }
    return undefined;
  }

  function showSourceDropdown(values: JobDeleteSurveyData) {
    return (
      values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA &&
      values.testimonialConsent &&
      isUserType(UserType.Consumer)
    );
  }

  function showFirstNameField(values: JobDeleteSurveyData) {
    return (
      values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA &&
      values.testimonialConsent &&
      isUserType(UserType.Consumer) &&
      (values.testimonialSource === TestimonialSourceEnum.FRIEND_FAMILY ||
        values.testimonialSource === TestimonialSourceEnum.OTHER_REPRESENTATIVE)
    );
  }

  const handleSubmit = async (values: JobDeleteSurveyData) => {
    if (values.reasonForDeletion) {
      const jobDeletePostData: DeleteJobPostDTO = {
        resolvedMethod: values.reasonForDeletion,
        resolvedNotes: values.furtherInformation,
        resolvedProviders:
          values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA ? values.selectedProviders : [],
        testimonialConsent:
          values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA ? values.testimonialConsent : false,
        testimonialSource: getTestimonialSource(values),
        testimonialName: getTestimonialName(values),
      };

      try {
        setDeletingJob(true);
        await deleteJobPost(jobId, jobDeletePostData);
        if (values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA) {
          setShowConfirmationByCarina(true);
        }
        if (values.reasonForDeletion === ResolvedMethodEnum.FILLED_OTHER) {
          setShowConfirmationByOther(true);
        }
        if (values.reasonForDeletion === ResolvedMethodEnum.NOT_FILLED) {
          setShowConfirmationUnfilled(true);
        }
      } catch {
        await showSnackbar(
          t("issue deleting the job post", "There was an issue deleting the job post. Please try again.", {
            ns: nsCommonToasts,
          }),
          "error",
        );
      } finally {
        setDeletingJob(false);
      }
    }
  };

  if (isLoadingJobPost || fetchingProviders || !ready) {
    return (
      <PageWrapper>
        <Section bgcolor={CivColors.lightGray} minHeight="80vh">
          <Constraint columns={8}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </PageWrapper>
    );
  }

  if (!jobPost) {
    return (
      <PageWrapper>
        <Section bgcolor={CivColors.lightGray} minHeight="80vh">
          <Constraint columns={8} textAlign="center">
            <SectionTitle>{t("job_post_not_found.label", { ns: nsMedicaidJobSurvey })}</SectionTitle>
            <LinkStyledAsButton
              variant="contained"
              component={Link}
              to={getMedicaidRouteByUserType("myJobList", isUserType)}
            >
              {t("button.go_to_my_jobs", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
          </Constraint>
        </Section>
      </PageWrapper>
    );
  }

  if (showConfirmationByCarina) {
    return (
      <PageWrapper>
        <FilledViaCarina />
      </PageWrapper>
    );
  }
  if (showConfirmationByOther) {
    return (
      <PageWrapper>
        <FilledViaOtherMeans />
      </PageWrapper>
    );
  }

  if (showConfirmationUnfilled) {
    return (
      <PageWrapper>
        <Unfilled />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Section bgcolor={CivColors.lightGray} minHeight="80vh">
        <Constraint columns={8}>
          <SectionTitle align="center">
            {t("delete_job_post.label", { ns: nsMedicaidJobSurvey, job_number: jobPost?.jobNumber })}
          </SectionTitle>
          <Body align="center" paragraph>
            {t("delete_job_post.description", { ns: nsMedicaidJobSurvey })}
          </Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values }) => {
              return (
                <Form localizationReady={ready}>
                  <Box marginBottom={4}>
                    <FormRadioGroupField
                      name="reasonForDeletion"
                      label={`${t("why_are_you_deleting.label", { ns: nsMedicaidJobSurvey })} *`}
                      labelAlwaysOnTop
                      options={[
                        {
                          key: ResolvedMethodEnum.FILLED_CARINA,
                          optionLabel: t("filled_carina", { ns: nsMedicaidTagsEnums }),
                        },
                        {
                          key: ResolvedMethodEnum.FILLED_OTHER,
                          optionLabel: t("filled_other", { ns: nsMedicaidTagsEnums }),
                        },
                        {
                          key: ResolvedMethodEnum.NOT_FILLED,
                          optionLabel: t("not_filled", { ns: nsMedicaidTagsEnums }),
                        },
                      ]}
                    />
                  </Box>

                  {values.reasonForDeletion === "FILLED_CARINA" && (
                    <Box marginBottom={4}>
                      <FormMultiSelectChipsField
                        disableDefaultSort
                        name="selectedProviders"
                        selectOptions={getProviderNameOptions(providerList)}
                        label={`${t("who_did_you_choose.label", {
                          ns: nsMedicaidJobSurvey,
                        })} *`}
                      />
                    </Box>
                  )}

                  <BodyEmphasis>{t("tell_us_what_happened.label", { ns: nsMedicaidJobSurvey })}</BodyEmphasis>
                  <Grid item xs={12}>
                    <FormTextField
                      name="furtherInformation"
                      label={t("field.survey_comments.placeholder", { ns: nsCommonFormsBtns })}
                      multiline
                      minRows={5}
                      helperText={""}
                      required={isRequiredField("furtherInformation", validationSchema)}
                      maximumlength={FURTHER_INFO_MAX_LENGTH}
                    />
                  </Grid>

                  {values.reasonForDeletion === ResolvedMethodEnum.FILLED_CARINA && (
                    <Box marginBottom={4} marginTop={4}>
                      <Trans
                        t={t}
                        i18nKey="do_you_consent_question.label"
                        ns={nsMedicaidJobSurvey}
                        components={{
                          cwsc: <MuiLink href={MEDICAID_ACCOUNT_ROUTES.testimonialConsent} target="_blank" />,
                        }}
                        parent={(props: TypographyProps) => <BodyEmphasis {...props} sx={{ marginBottom: "22px" }} />}
                      />
                      <CheckboxField
                        name="testimonialConsent"
                        id="testimonialConsent"
                        label={<Body>{t("yes_i_consent.label", { ns: nsMedicaidJobSurvey })}</Body>}
                      />
                    </Box>
                  )}

                  {showSourceDropdown(values) && (
                    <Grid item xs={12}>
                      <FormSelectField
                        name="testimonialSource"
                        label={`${t("field.i_am.label", { ns: nsCommonFormsBtns })} *`}
                        selectOptions={[
                          {
                            label: t("self_report", { ns: nsMedicaidTagsEnums }),
                            value: TestimonialSourceEnum.SELF_REPORT,
                          },
                          {
                            label: t("friend_family", { ns: nsMedicaidTagsEnums }),
                            value: TestimonialSourceEnum.FRIEND_FAMILY,
                          },
                          {
                            label: t("other_representative", { ns: nsMedicaidTagsEnums }),
                            value: TestimonialSourceEnum.OTHER_REPRESENTATIVE,
                          },
                        ]}
                      />
                    </Grid>
                  )}

                  {showFirstNameField(values) && (
                    <Grid item xs={12}>
                      <FormTextField
                        name="testimonialName"
                        label={t("field.first_name.label", { ns: nsCommonFormsBtns })}
                        required
                      />
                    </Grid>
                  )}

                  <FormAlert schema={validationSchema} sx={{ marginTop: "24px" }} />

                  <Box textAlign="center" mt={CivTheme.spacing(4)}>
                    <Grid container style={{ padding: 24, display: "flex", justifyContent: "center" }}>
                      <Grid item xs={12} sm={4} style={{ paddingBottom: 12 }}>
                        <LinkStyledAsButton
                          variant="outlined"
                          component={Link}
                          to={
                            isUserType(UserType.Consumer)
                              ? generatePath(Medicaid_Consumer_Dashboard_Routes.myDeletedJobDetails, {
                                  id: jobId,
                                })
                              : generatePath(Medicaid_Coordinator_Dashboard_Routes.myDeletedJobDetails, {
                                  id: jobId,
                                })
                          }
                          disabled={deletingJob}
                        >
                          {t("button.cancel", { ns: nsCommonFormsBtns })}
                        </LinkStyledAsButton>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button variant="contained" type="submit" disabled={deletingJob}>
                          {deletingJob
                            ? t("button.deleting", { ns: nsCommonFormsBtns })
                            : t("button.delete", { ns: nsCommonFormsBtns })}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>{" "}
        </Constraint>
      </Section>
    </PageWrapper>
  );
};

export default DeleteJobSurvey;
