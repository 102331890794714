import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { nsCommonTooltips } from "src/i18n/Namespaces";
import Popover from "src/reusable_view_elements/Popover";
import { Body } from "../Typography";

const NurseDelegationPopover = (props: PropsWithChildren<{}>) => {
  const { t } = useTranslation(nsCommonTooltips);

  const nurseDelegationPopoverDescription = (
    <Body gutterBottom>{t("popover.nurse_deleg.description", { ns: nsCommonTooltips })}</Body>
  );

  return (
    <Popover
      contentTitle={t("popover.nurse_deleg.label", { ns: nsCommonTooltips })}
      content={nurseDelegationPopoverDescription}
      contentId="nurse-delegation-popover"
    >
      {props.children}
    </Popover>
  );
};

export default NurseDelegationPopover;
