import { Container, Divider, Tab, Tabs } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { nsCoordinatorAndSupportTools } from "src/i18n/Namespaces";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PageContainerDashboard, { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import Section from "src/reusable_view_elements/Section";
import CivColors from "src/themes/civilization/CivColors";

export enum MedicaidCoordDashboardTabs {
  COORDINATORS,
  CONSUMER_JOB_POSTS,
}

export interface MedicaidCoordDashboardContainerProps {
  tab: MedicaidCoordDashboardTabs;
}

export const MedicaidCoordDashboardContainer: React.FC<PropsWithChildren<MedicaidCoordDashboardContainerProps>> = ({
  tab,
  children,
}) => {
  const { t, ready } = useTranslation([nsCoordinatorAndSupportTools]);

  if (!ready) {
    return (
      <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
        <Constraint columns={6}>
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  return (
    <PageContainerDashboard type={DashboardType.MEDICAID} maxWidth="xl" style={{ padding: 0 }}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        style={{ backgroundColor: CivColors.lightGray, paddingTop: "26px", paddingLeft: "26px" }}
      >
        <Tab
          component={Link}
          label={t("coordinators.label")}
          style={{ textTransform: "none", textAlign: "left" }}
          to={Medicaid_Coordinator_Dashboard_Routes.team}
        />
        <Tab
          component={Link}
          label={t("consumer_job_posts.label")}
          style={{ textTransform: "none", textAlign: "left" }}
          to={Medicaid_Coordinator_Dashboard_Routes.jobs_list}
        />
      </Tabs>
      <Divider />
      <Container maxWidth={false} style={{ marginBottom: 80 }}>
        {children!}
      </Container>
      <Footer />
    </PageContainerDashboard>
  );
};
