import { Button, ButtonProps, Grid } from "@mui/material";
import colors from "src/themes/civilization/CivColors";

interface BottomActionBarButtonProps {
  buttonText: string;
  variant: "outlined" | "contained" | "text";
  buttonProps: Partial<ButtonProps>;
}

interface DashBottomBarProps {
  bottomActionButtons: BottomActionBarButtonProps[];
}

/**
 * A component for the bar fixed to the bottom of the page. Contains Cancel and Save buttons
 * by default, but can be provided props (MUI Button Props) to override or add to the defaults.
 * Made specifically for dashboard detail pages.
 */
const BottomActionBar = ({ bottomActionButtons }: DashBottomBarProps) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={3}
        sx={{
          height: 84,
          position: "fixed",
          zIndex: 10,
          bottom: 0,
          right: 0,
          backgroundColor: colors.white,
          borderTop: "1px solid #989898",
        }}
      >
        {bottomActionButtons.map((bottomActionButton) => (
          <Grid item key={bottomActionButton.buttonText}>
            <Button variant={bottomActionButton.variant} color="primary" {...bottomActionButton.buttonProps}>
              {bottomActionButton.buttonText}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default BottomActionBar;
