import { Box, CircularProgress, Grid, GridProps, IconButton, keyframes, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { Permission } from "src/generated/api_types";
import { nsCommonBanners, nsCommonFormsBtns } from "src/i18n/Namespaces";
import { AlertAlarmBellRingIcon } from "src/assets/icons/StreamlineIcons";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    display: none;
    z-index: 1;
  }
  99% {
    transform: translateY(0px);
    display: block;
    z-index: 1;
  }
  100% {
    transform: translateY(0px);
    display: block;
    z-index: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0px);
    display: block;
    z-index: 1;
  }
  99% {
    transform: translateY(-100%);
    display: block;
    z-index: 1;
  }
  100% {
    transform: translateY(-100%);
    display: none;
    z-index: 1;
  }
`;

const StyledBanner = styled("div")<{
  view?: ViewBannerControlEnum;
  safariHide: boolean;
  bannerEnabled: boolean;
  hasPermission: boolean;
}>`
  z-index: 1;
  position: relative;

  ${({ view, bannerEnabled, hasPermission }) =>
    bannerEnabled &&
    hasPermission &&
    view === ViewBannerControlEnum.VISIBLE_GENERIC &&
    `
    animation: ${slideIn} 0.4s ease-in-out;
    animation-delay: 0.2s;
    animation-fill-mode: both;
  `}

  ${({ view, bannerEnabled, hasPermission }) =>
    bannerEnabled &&
    hasPermission &&
    view === ViewBannerControlEnum.HIDDEN &&
    `
    animation: ${slideOut} 0.3s ease-in-out;
    animation-delay: 0s;
    animation-fill-mode: both;
  `}

  ${({ view, safariHide, bannerEnabled, hasPermission }) =>
    (view === undefined || !bannerEnabled || !hasPermission || safariHide) &&
    `
    display: none;
  `}

  ${({ view, safariHide, bannerEnabled, hasPermission }) =>
    view !== undefined &&
    !safariHide &&
    bannerEnabled &&
    hasPermission &&
    `
    display: flex;
  `}
`;

const useStyles = () => {
  return useMemo(
    () => ({
      bannerDefault: "bannerDefault", // This will not be used but included for completeness
    }),
    [],
  );
};

export default useStyles;

interface BannerProps {
  view?: ViewBannerControlEnum;
  setView: React.Dispatch<React.SetStateAction<ViewBannerControlEnum | undefined>>;
  useSite: BannerUseSiteEnum;
  bannerEnabled: boolean;
}

export enum BannerUseSiteEnum {
  JOB_POST_CONFIRMATION = "job_post_confirmation",
  INBOX = "inbox",
}

export enum ViewBannerControlEnum {
  HIDDEN,
  VISIBLE_GENERIC,
  VISIBLE_SUCCESS,
  VISIBLE_ERROR,
}

export const SETTINGS_BANNER_ENABLED_KEY = "settings_banner_enabled";

export const SettingsBanner: React.FC<BannerProps & GridProps> = ({ view, setView, useSite, bannerEnabled }) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const biggerThanMobileSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsCommonBanners, nsCommonFormsBtns]);
  const { hasPermission } = useUserSession();
  const [safariHide, setSafariHide] = useState(false);

  useEffect(() => {
    if (bannerEnabled && view === ViewBannerControlEnum.VISIBLE_GENERIC) setSafariHide(false);
  }, [view, bannerEnabled]);

  const handleRemindMeLater = () => {
    setView(ViewBannerControlEnum.HIDDEN);
    //The next delay has to be the same set in animatedWrapperOut.animation. This is for Safari browser, in which
    // the space where the dynamic banner goes remains empty and does not close after the banner is hidden.
    setTimeout(() => setSafariHide(true), 300);
  };

  const handleClose = () => {
    setTimeout(() => {
      setSafariHide(true);
      if (useSite === BannerUseSiteEnum.INBOX) localStorage.setItem(SETTINGS_BANNER_ENABLED_KEY, "false");
    }, 300);
    setView(ViewBannerControlEnum.HIDDEN);
  };

  const classes = useStyles();

  return (
    <StyledBanner
      view={view}
      safariHide={safariHide}
      bannerEnabled={bannerEnabled}
      hasPermission={hasPermission(Permission.SETTINGS_ACCESS)}
      className={classes.bannerDefault}
    >
      <Grid
        container
        style={{ backgroundColor: CivColors.paleBlue, padding: "24px 0px 24px 0px" }}
        id="settings-banner"
      >
        <>
          <Grid item xs={2} sm={1} style={{ textAlign: "right", paddingRight: desktopSize ? 24 : 12 }}>
            <AlertAlarmBellRingIcon />
          </Grid>
          <Grid item container xs={8} sm={10} style={{ flexDirection: "column" }}>
            {!ready ? (
              <>
                <Box textAlign="center" margin="0px auto">
                  <CircularProgress color="secondary" />
                </Box>
              </>
            ) : (
              <>
                <Grid item>
                  <BodyEmphasis style={{ paddingBottom: 12 }}>
                    {t("get_notified.label", { ns: nsCommonBanners })}
                  </BodyEmphasis>
                  <Body paragraph>{t("get_notified.description", { ns: nsCommonBanners })}</Body>
                </Grid>
                <Box maxWidth={760} style={{ marginTop: 12 }}>
                  <Grid
                    item
                    container
                    spacing={4}
                    style={{ textAlign: "center", justifyContent: !biggerThanMobileSize ? "center" : "left" }}
                  >
                    <Grid item>
                      <LinkStyledAsButton
                        variant="outlined"
                        component={Link}
                        to={MEDICAID_ACCOUNT_ROUTES.accountSettings}
                        sx={{ padding: "10px 40px" }}
                      >
                        {t("button.go_to_settings", { ns: nsCommonFormsBtns })}
                      </LinkStyledAsButton>
                    </Grid>
                    <Grid item>
                      <Button variant="text" type="button" onClick={handleRemindMeLater} sx={{ padding: "10px 0px" }}>
                        {t("button.remind_me_later", { ns: nsCommonFormsBtns })}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Grid>
          <Grid xs={2} sm={1} item style={{ textAlign: "center" }}>
            <IconButton onClick={handleClose} size="large">
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </>
      </Grid>
    </StyledBanner>
  );
};
