import {
  AxiosPPHCAgencyDashboardProvidersControllerClient,
  PPHCAgencyDashboardProvidersController_ListingFormData,
} from "src/generated/api_types";

const pphcAgencyDashboardClient = new AxiosPPHCAgencyDashboardProvidersControllerClient();

export async function getAllAgencyProviderListings() {
  return pphcAgencyDashboardClient.listAllAgencyProviders();
}

export async function createOrUpdateProviderListing(formData: PPHCAgencyDashboardProvidersController_ListingFormData) {
  return pphcAgencyDashboardClient.postProviderListingCreateOrUpdate(formData);
}

export async function deleteProviderListing(id: string) {
  return pphcAgencyDashboardClient.deleteProviderListing({ existingProviderListingId: id });
}
