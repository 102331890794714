import { Box, Grid } from "@mui/material";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { AgencyProviderListing_DTO_Request_Create, ConsumerApplicationLanguage } from "src/generated/api_types";
import { nsCommonForms } from "src/i18n/Namespaces";
import { createOrUpdateProviderListing } from "src/pages/agencyAdmin/dashboard/providers/AgencyAdmin_Provider_Requests";
import { useProvidersDatatable } from "src/pages/agencyAdmin/dashboard/providers/ProviderDashboard";
import { FormAddressField, FormMultiSelectChipsField, FormTextField } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import Form from "src/reusable_view_elements/form_fields/Form";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import { Body } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { number, object, string } from "yup";

export interface AddOrEditProviderRowFormFields {
  zip: string;
  numProviders: number;
  hasMaleProviders: boolean;
  languages: ConsumerApplicationLanguage[];
  notes: string;
  existingRowId?: string;
}

export const DefaultFormValues: AddOrEditProviderRowFormFields = {
  zip: "",
  numProviders: 0,
  hasMaleProviders: false,
  languages: [ConsumerApplicationLanguage.ENGLISH],
  notes: "",
  existingRowId: "",
};

type AddOrEditProviderFormProps = {
  actionToClose: () => void;
  existingZipCodes: Set<string>;
  initialValues: AddOrEditProviderRowFormFields;
};

const AddOrEditProviderForm: React.FC<AddOrEditProviderFormProps> = ({
  actionToClose,
  existingZipCodes,
  initialValues,
}) => {
  const { addOrUpdateData } = useProvidersDatatable();
  const { ready } = useTranslation(nsCommonForms);
  const { showSnackbar } = useNotification();
  const formikRef = useRef<FormikProps<AddOrEditProviderRowFormFields>>(null);

  const validateNoDupZip = (val: string | null | undefined) => {
    // Invalid zipcode
    if (!val || val.length < 5) {
      return false;
    }
    // If zipcode exists and it isn't what it was originally, its a dup zip
    if (existingZipCodes.has(val.trim()) && val.trim() !== initialValues.zip) {
      return false;
    }
    return true;
  };

  const zipRegEx = /^[0-9]{5}(?:-[0-9]{4})?$/;
  const schema = object().shape({
    zip: string()
      .required("You must enter a valid zip code.")
      .matches(zipRegEx, {
        message: "You must enter a valid zip code.",
      })
      .test(
        "duplicate zip",
        "This zip code is already on the dashboard. You must use a zip code that is not already on the dashboard.",
        (value) => validateNoDupZip(value),
      ),
    languages: string().required("You must select at least one language."),
    numProviders: number()
      .required("You must enter a number that is 0 or greater.")
      .min(0, "You must enter a number that is 0 or greater."),
  });

  if (!ready) {
    return <LoadingCircle />;
  }

  const handleSubmit = async (
    values: AddOrEditProviderRowFormFields,
    helpers: FormikHelpers<AddOrEditProviderRowFormFields>,
  ) => {
    const formData: AgencyProviderListing_DTO_Request_Create = {
      zipCode: values.zip,
      numProviders: values.numProviders,
      notes: values.notes,
      languages: values.languages,
      hasMaleProviders: values.hasMaleProviders,
    };

    try {
      const { data: resData } = await createOrUpdateProviderListing({
        formData,
        existingProviderListingID: initialValues.existingRowId,
      });
      helpers.setSubmitting(false);

      if (resData.mutatedProvider != null) {
        const newId = resData.mutatedProvider.id;
        const newEntry = resData.mutatedProvider;
        addOrUpdateData(newId, () => {
          return {
            ...newEntry,
            updatedAt: newEntry.updatedAt,
          };
        });
        actionToClose();
      } else if (resData.error != null) {
        showSnackbar(`${resData.error} Please try again.`, "error");
        helpers.setSubmitting(false);
      }
    } catch (e) {
      showSnackbar("Error saving provider row. Please try again or refresh browser.", "error");
      helpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnBlur
    >
      {({ errors, isSubmitting }) => (
        <Form localizationReady={ready}>
          <Grid container spacing={0} direction="column" alignItems="center">
            <Box maxWidth="500px">
              <FormAddressField
                zipCodeOnly
                name="zip"
                label="Input zip code"
                required={isRequiredField("zip", schema)}
              />
              <FormTextField
                name="numProviders"
                label="Input # of available providers from zip code"
                type="number"
                required={isRequiredField("numProviders", schema)}
              />
              <CheckboxField
                name="hasMaleProviders"
                style={{ padding: "1em 0" }}
                label={<Body>Male providers are available in this zip code</Body>}
              />
              <FormMultiSelectChipsField
                name="languages"
                label="Add languages spoken by available providers *"
                id="languages"
                error={!!errors.languages}
                closeOnSelect
                selectOptions={[
                  { value: ConsumerApplicationLanguage.ENGLISH, label: "English" },
                  { value: ConsumerApplicationLanguage.SPANISH, label: "Spanish" },
                  { value: ConsumerApplicationLanguage.VIETNAMESE, label: "Vietnamese" },
                  { value: ConsumerApplicationLanguage.RUSSIAN, label: "Russian" },
                  { value: ConsumerApplicationLanguage.CHINESE_CANTONESE, label: "Chinese-Cantonese" },
                  { value: ConsumerApplicationLanguage.CHINESE_MANDARIN, label: "Chinese-Mandarin" },
                  { value: ConsumerApplicationLanguage.TAGALOG, label: "Tagalog" },
                  { value: ConsumerApplicationLanguage.KOREAN, label: "Korean" },
                  {
                    value: ConsumerApplicationLanguage.AMERICAN_SIGN_LANGUAGE,
                    label: "American Sign Language",
                  },
                  { value: ConsumerApplicationLanguage.AMHARIC, label: "Amharic" },
                  { value: ConsumerApplicationLanguage.ARABIC, label: "Arabic" },
                  { value: ConsumerApplicationLanguage.SOMALI, label: "Somali" },
                  { value: ConsumerApplicationLanguage.UKRAINIAN, label: "Ukrainian" },
                  { value: ConsumerApplicationLanguage.BENGALI, label: "Bengali" },
                  { value: ConsumerApplicationLanguage.HAITIAN_CREOLE, label: "Haitian Creole" },
                  { value: ConsumerApplicationLanguage.ITALIAN, label: "Italian" },
                  { value: ConsumerApplicationLanguage.POLISH, label: "Polish" },
                  { value: ConsumerApplicationLanguage.YIDDISH, label: "Yiddish" },
                  { value: ConsumerApplicationLanguage.HEBREW, label: "Hebrew" },
                  { value: ConsumerApplicationLanguage.EDO_BINI, label: "Edo/Bini" },
                  { value: ConsumerApplicationLanguage.URDU, label: "Urdu" },
                  { value: ConsumerApplicationLanguage.HINDI, label: "Hindi" },
                  { value: ConsumerApplicationLanguage.FRENCH, label: "French" },
                  { value: ConsumerApplicationLanguage.TWI, label: "Twi" },
                ]}
              />
              <FormTextField
                name="notes"
                label="Add notes"
                multiline
                minRows={4}
                helperText={""}
                required={isRequiredField("notes", schema)}
                maximumlength={500}
              />
            </Box>
          </Grid>

          <Grid style={{ textAlign: "right", padding: "1em 0" }}>
            <Button variant="text" type="button" onClick={actionToClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddOrEditProviderForm;
