import { Container, Divider, Tab, Tabs } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import PageContainerDashboard from "src/reusable_view_elements/PageContainerDashboard";

export enum CarinaAdminMgmtTabs {
  FUNDERS,
  VERIFICATIONS,
  URL_SHORTENER,
  MERLIN,
}

export interface CarinaAdminMgmtContainerProps {
  tab: CarinaAdminMgmtTabs;
}

export const CarinaAdminMgmtContainer: React.FC<PropsWithChildren<CarinaAdminMgmtContainerProps>> = ({
  tab,
  children,
}) => {
  return (
    <PageContainerDashboard title="Carina Admin Management" maxWidth="xl">
      <Tabs value={tab}>
        <Tab component={Link} label="Funders" to={CARINA_ADMIN_MGMT_ROUTES.funder_mgmt} />
        <Tab component={Link} label="Verifications" to={CARINA_ADMIN_MGMT_ROUTES.verification_mgmt} />
        <Tab component={Link} label="URL Shortener" to={CARINA_ADMIN_MGMT_ROUTES.url_shortener} />
        <Tab component={Link} label="Merlin" to={CARINA_ADMIN_MGMT_ROUTES.merlin} />
      </Tabs>
      <Divider />
      <Container maxWidth={false}>{children!}</Container>
    </PageContainerDashboard>
  );
};
