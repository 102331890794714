import { Box, BoxProps } from "@mui/material";

/**
 * To vertically and horizontally center content inside the Section container, toggle ON the `centerContent` prop.
 * `centerContent` is a shortcut for writing: display="flex" justifyContent="center" alignItems="center"
 * Remember to define the height or minHeight in order to see any vertical centering.
 */
export interface SectionProps extends BoxProps {
  minimizeTopGutter?: boolean;
  minimizeBottomGutter?: boolean;
  minimal?: boolean;
  dashboardMinimal?: boolean;
  centerContent?: boolean;
  sidePadding?: boolean;
}

function Section({
  // TODO: https://github.com/CarinaWeb/CarinaCore/issues/1016
  //  we should explain what the use case is for each of these props;
  //  in short, they were intended to match the standard padding defined by the design system
  minimal = false,
  minimizeTopGutter = false,
  minimizeBottomGutter = false,
  dashboardMinimal = false,
  centerContent = false,
  sidePadding = false,
  ...restBoxProps
}: SectionProps) {
  const centerContentProps = centerContent ? { display: "flex", justifyContent: "center", alignItems: "center" } : {};
  const restProps = { ...centerContentProps, ...restBoxProps };

  if (minimal) {
    return <Box {...restProps} />;
  }

  if (sidePadding) {
    return <Box paddingLeft={2} paddingRight={2} {...restProps} />;
  }

  if (minimizeTopGutter) {
    return <Box paddingTop={3} paddingBottom={10} paddingLeft={2} paddingRight={2} {...restProps} />;
  }

  if (minimizeBottomGutter) {
    return <Box paddingTop={8} paddingBottom={1} paddingLeft={2} paddingRight={2} {...restProps} />;
  }
  if (dashboardMinimal) {
    return <Box paddingTop={2} paddingBottom={2} paddingLeft={2} paddingRight={30} {...restProps} />;
  }

  return <Box paddingTop={8} paddingBottom={8} paddingLeft={2} paddingRight={2} {...restProps} />;
}

export default Section;
