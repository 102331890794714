import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { nsCovidChildcareFAQ } from "src/i18n/Namespaces";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { StyledBreak as Break, anchorStyle } from "src/pages/Privacy & TOS/PrivacyPolicy";

const COVID19Info = () => {
  const { t, ready } = useTranslation(nsCovidChildcareFAQ);
  const AND = t("FAQ Answer 3B");

  const Link = ({ href, children }: PropsWithChildren<{ href: string }>) => (
    <a href={href} style={anchorStyle}>
      {children}
    </a>
  );

  const CA_1 = <Link href="https://www.dir.ca.gov/dosh/coronavirus/Health-Care-General-Industry.html">California</Link>;
  const CA_2 = <Link href="https://covid19.ca.gov/stay-home-except-for-essential-needs/#school">California</Link>;
  const CA_3 = <Link href="https://www.cdss.ca.gov/inforesources/community-care-licensing">California</Link>;
  const CT_1 = <Link href="https://portal.ct.gov/DCF/COVID-19/COVID-19">Connecticut</Link>;
  const CT_2 = (
    <Link href="https://portal.ct.gov/Coronavirus/Information-For/Childcare-Provider-Resources">Connecticut</Link>
  );
  const WA = <Link href="https://coronavirus.wa.gov/business-workers">Washington State</Link>;
  const IL = (
    <Link href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/guidance-for-childcare.html">
      Illinois
    </Link>
  );
  const CDC = (
    <Link href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/guidance-for-childcare.html">
      {t("FAQ Answer 1C")}
    </Link>
  );

  return (
    <>
      <Helmet>
        <title>Carina | COVID-19 FAQ for Child Care</title>
      </Helmet>

      <NavBar showLanguage />
      <main id="main-content">
        <Section bgcolor={CivColors.lightGray}>
          {ready ? (
            <Constraint columns={10}>
              <SectionTitle align="center" style={{ marginBottom: 60 }}>
                {t("FAQ Header")}
              </SectionTitle>

              <BodyTitle paragraph>{t("FAQ Question 1")}</BodyTitle>
              <Body paragraph>{t("FAQ Answer 1A")}</Body>
              <Body paragraph>
                {t("FAQ Answer 1B")} {CDC}, {t("FAQ Answer 1D")}
              </Body>
              <Body>
                {t("FAQ Answer 1E")} {CA_1}, {CT_1}, {IL}, {AND} {WA}.
              </Body>

              <Break />

              <BodyTitle paragraph>{t("FAQ Question 2")}</BodyTitle>
              <Body paragraph>{t("FAQ Answer 2A")}</Body>
              <Body paragraph>{t("FAQ Answer 2B")}</Body>
              <ul>
                {[CA_2, WA, CT_2, IL].map((point, i) => (
                  <Body key={i}>
                    <li>{point}</li>
                  </Body>
                ))}
              </ul>

              <Break />

              <BodyTitle paragraph>{t("FAQ Question 3")}</BodyTitle>
              <Body>
                {t("FAQ Answer 3A")} {CA_2}, {WA}, {CT_2}, {AND} {IL}.
              </Body>

              <Break />

              <BodyTitle paragraph>{t("FAQ Question 4")}</BodyTitle>
              <Body paragraph>{t("FAQ Answer 4A")}</Body>
              <Body paragraph>{t("FAQ Answer 4B")}</Body>
              <Body>
                {t("FAQ Answer 4C")} {CA_2}, {WA}, {CT_2}, {AND} {IL}.
              </Body>

              <Break />

              <BodyTitle paragraph>{t("FAQ Question 5")}</BodyTitle>
              <Body paragraph>{t("FAQ Answer 5A")}</Body>
              <ul>
                {[CA_3, WA, CT_2, IL].map((point, i) => (
                  <Body key={i}>
                    <li>{point}</li>
                  </Body>
                ))}
              </ul>
            </Constraint>
          ) : (
            <LoadingCircle />
          )}
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default COVID19Info;
