import {
  AxiosCarinaAdminUtilityControllerClient,
  AxiosGroupInviteControllerClient,
  GenerateInviteRequestDTO,
} from "src/generated/api_types";

const client = new AxiosCarinaAdminUtilityControllerClient();
const inviteClient = new AxiosGroupInviteControllerClient();

export function decryptVerification(id: string) {
  return client.decryptVerification(id);
}

export function urlShortener(url: string) {
  return client.shortenLink({ url });
}

export function getGroupInviteRegPaths(funderId: string) {
  return inviteClient.getGroupInviteRegPaths(funderId);
}

export function getActiveCaseManagers(funderId: string) {
  return inviteClient.getActiveCaseManagers(funderId);
}

export function generateInvite(dto: GenerateInviteRequestDTO) {
  return inviteClient.generateInvite(dto);
}

export function reGenerateGroupInvite() {
  return inviteClient.reGenerateGroupInvite();
}

export function getLastGroupInviteSentByMe() {
  return inviteClient.getLastGroupInviteSentByMe();
}
