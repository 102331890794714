import { GenericAxiosResponse } from "axios";
import {
  AxiosFunderSelectionControllerClient,
  AxiosMessagingControllerClient,
  AxiosUsersAdministrationControllerClient,
  AxiosUsersImpersonationControllerClient,
  ChildcareInviteListDTO,
  Page,
  PageInfo,
} from "src/generated/api_types";

export const funderSelectionController = new AxiosFunderSelectionControllerClient();
export const usersAdministrationController = new AxiosUsersAdministrationControllerClient();
export const usersImpersonationController = new AxiosUsersImpersonationControllerClient();
export const messagingController = new AxiosMessagingControllerClient();

export async function getAllUserReportsList(pageInfo: PageInfo, program: string) {
  return usersAdministrationController.searchUserReports(pageInfo, { funderId: program });
}

export async function getReportDetails(id: string) {
  return usersAdministrationController.getUserReportDetails(id);
}

export async function getChildcareInvitedUsers(
  program: string,
  pageInfo: PageInfo,
): Promise<GenericAxiosResponse<Page<ChildcareInviteListDTO>>> {
  return usersAdministrationController.searchChildcareUserInvites(program, pageInfo);
}
