import { Step as MStep, StepLabel as MStepLabel } from "@mui/material";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import StyledConnector from "src/reusable_view_elements/steppers/stepper_elements/StyledConnector";
import StyledStepIcon from "src/reusable_view_elements/steppers/stepper_elements/StyledStepIcon";
import StyledStepper from "src/reusable_view_elements/steppers/stepper_elements/StyledStepper";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";

export function getStepperCaseManagerCopy() {
  const { isFunderState } = useUserSession();
  if (isFunderState(FunderState.Washington)) return "CDWA";
  if (isFunderState(FunderState.Oregon)) return "Case Manager";
  return "Case Manager";
}

export interface BasicStepperProps {
  activeStep: number; // 0-based
  steps: Array<string>;
  mobileStyle?: boolean;
}

/**
 * A basic stepper that includes mobile styling
 */
const BasicStepper = (props: BasicStepperProps) => {
  if (props.mobileStyle) {
    return (
      <>
        <Body align="center" gutterBottom>
          Step {props.activeStep + 1} of {props.steps.length}
        </Body>
        <BodyEmphasis align="center">{props.steps[props.activeStep]}</BodyEmphasis>
      </>
    );
  }

  return (
    <StyledStepper
      activeStep={props.activeStep}
      alternativeLabel // Place label below the step icon
      connector={<StyledConnector />}
    >
      {props.steps.map((label, index) => (
        <MStep key={index}>
          <MStepLabel StepIconComponent={StyledStepIcon}>{label}</MStepLabel>
        </MStep>
      ))}
    </StyledStepper>
  );
};

export default BasicStepper;
