import { Grid, IconButton } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useNotification } from "src/context/NotificationContext";
import {
  MedicaidInvitesController_MedicaidInviteDTO,
  MedicaidInvitesController_MedicaidInviteRegPathOptionDTO,
  MedicaidInvitesController_OrganizationOptionDTO,
  RoleType,
} from "src/generated/api_types";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { UsersInviteFormProps } from "src/pages/supportAdminAccount/forms/ChildcareUsersInviteForm";

import {
  medicaidInitialValues,
  MedicaidInviteFieldValues,
  medicaidValidationSchema,
} from "src/pages/supportAdminAccount/forms/MedicaidUsersInviteValidation";
import {
  createMedicaidInvite,
  getFunderOrganizations,
  getMedicaidInviteRegPaths,
} from "src/pages/supportAdminAccount/forms/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormRadioGroupField } from "src/reusable_view_elements/form_fields/FormRadioGroupField";
import { FormSelectField } from "src/reusable_view_elements/form_fields/FormSelectField";
import { Button } from "src/reusable_view_elements/Button";
import { DescriptionEmphasis, PanelTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";

interface DynamicFormInputProps {
  roleType: RoleType | null;
}

const DynamicFormInput = ({ roleType }: DynamicFormInputProps) => {
  const { program } = useSupportAdminContext();
  const { values, setFieldValue } = useFormikContext<MedicaidInviteFieldValues>();
  const [orgOptions, setOrgOptions] = useState<MedicaidInvitesController_OrganizationOptionDTO[]>([]);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(false);

  const resetVerificationInputs = () => {
    const { firstName, lastName, email, regPathId, role, ...dynamicValues } = medicaidInitialValues;
    Object.keys(dynamicValues).forEach((key) => {
      setFieldValue(`${key}`, "");
    });
  };

  useEffect(() => {
    resetVerificationInputs();
    if (roleType === RoleType.MEDICAID_REFERRAL_COORDINATOR) {
      setIsLoadingOrgs(true);
      getFunderOrganizations(program)
        .then((res) => {
          setOrgOptions(res.data);
        })
        .finally(() => setIsLoadingOrgs(false));
    }
  }, [roleType]);

  if (isLoadingOrgs) return <>Loading organizations...</>;

  switch (roleType) {
    case RoleType.PROVIDER:
      return (
        <>
          <FormRadioGroupField<string>
            name="providerVerification"
            labelAlwaysOnTop
            options={[
              { key: "provider_id", optionLabel: "Provider Unique ID" },
              { key: "providerInfo", optionLabel: "SSN* Last 4 & DOB" },
            ]}
          />
          {values.providerVerification === "providerInfo" && (
            <>
              <Grid item xs={6}>
                <FormTextField
                  name="pLastFour"
                  label="Last 4 of SSN"
                  type="password"
                  inputProps={{
                    minLength: 4,
                    maxLength: 4,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FastMaskTextField label="DOB (MM/DD/YYYY)" name="pDOB" maskType="date" required />
              </Grid>
            </>
          )}
          {values.providerVerification === "provider_id" && (
            <>
              <Grid item xs={12}>
                <FormTextField
                  name="providerID"
                  type="text"
                  placeholder="Unique ID (Apple Health, IPOne, etc.)"
                  label="Unique ID (Apple Health, IPOne, etc.)"
                  required
                />
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <FormSelectField name="credentialInfo" label="Credential Description*" selectOptions={[]} />*/}
              {/*</Grid>*/}
            </>
          )}
        </>
      );
    case RoleType.CONSUMER:
      return (
        <>
          <FormRadioGroupField<string>
            name="medicaidVerification"
            labelAlwaysOnTop
            options={[
              { key: "medicaid_id", optionLabel: "Medicaid Unique ID" },
              { key: "personalInfo", optionLabel: "SSN* Last 4 & DOB" },
            ]}
          />
          {values.medicaidVerification === "medicaid_id" && (
            <Grid item xs={12}>
              <FormTextField name="medicaidUniqueID" label="Medicaid ID*" type="text" />
            </Grid>
          )}
          {values.medicaidVerification === "personalInfo" && (
            <>
              <Grid item xs={6}>
                <FormTextField
                  name="lastFour"
                  label="Last 4 of SSN"
                  type="password"
                  inputProps={{
                    minLength: 4,
                    maxLength: 4,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FastMaskTextField label="DOB (MM/DD/YYYY)" name="dob" maskType="date" required />
              </Grid>
            </>
          )}
        </>
      );
    case RoleType.MEDICAID_REFERRAL_COORDINATOR:
      return (
        <>
          <Grid item xs={12}>
            <FormSelectField
              name="organizationID"
              label="Organization*"
              selectOptions={orgOptions.map((dto) => ({ label: dto.name, value: dto.id }))}
            />
          </Grid>
          <Grid item xs={12} style={{ fontSize: "1.0rem" }}>
            <DescriptionEmphasis>Verifier&#39;s Information</DescriptionEmphasis>
          </Grid>
          <Grid item xs={6}>
            <FormTextField name="verifiersFirstName" label="Verifier's First Name" type="text" required />
          </Grid>
          <Grid item xs={6}>
            <FormTextField name="verifiersLastName" label="Verifier's Last Name" type="text" required />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name="verifiersEmail" label="Verifier's Email" type="email" required />
          </Grid>
        </>
      );
    default:
      return (
        <>
          <Grid item xs={12} style={{ fontSize: "1.0rem" }}>
            <DescriptionEmphasis>Verifier&#39;s Information</DescriptionEmphasis>
          </Grid>
          <Grid item xs={6}>
            <FormTextField name="verifiersFirstName" label="Verifier's First Name" type="text" required />
          </Grid>
          <Grid item xs={6}>
            <FormTextField name="verifiersLastName" label="Verifier's Last Name" type="text" required />
          </Grid>
          <Grid item xs={12}>
            <FormTextField name="verifiersEmail" label="Verifier's Email" type="email" required />
          </Grid>
        </>
      );
  }
};

const MedicaidUsersInviteForm = ({ closeCallback }: UsersInviteFormProps) => {
  const { program } = useSupportAdminContext();
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const [roleOptions, setRoleOptions] = useState<MedicaidInvitesController_MedicaidInviteRegPathOptionDTO[]>([]);
  const [roleId, setRoleId] = useState<string | null>(null);
  const { showSnackbar } = useNotification();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingRoles(true);
    getMedicaidInviteRegPaths(program)
      .then((res) => {
        setRoleOptions(res.data);
      })
      .finally(() => {
        setIsLoadingRoles(false);
      });
  }, [program]);

  if (isLoadingRoles) {
    return <>Loading invite registration paths...</>;
  }

  function getRoleType() {
    return roleOptions.find((dto) => dto.id === roleId)!!.roleConfig.roleType;
  }

  const handleSubmit = async (values: MedicaidInviteFieldValues) => {
    const dto: MedicaidInvitesController_MedicaidInviteDTO = {
      registrationPathId: values.regPathId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      reason: values.reason,
      role: values.role,
      verificationInputsJson: "",
    };
    const roleType = getRoleType();
    switch (roleType) {
      case RoleType.PROVIDER:
        dto.verificationInputsJson = JSON.stringify({
          providerID: values.providerID,
          pLast4: values.pLastFour,
          pDOB: values.pDOB,
        });
        break;
      case RoleType.CONSUMER:
        dto.verificationInputsJson = JSON.stringify({
          uniqueId: values.medicaidUniqueID,
          last4: values.lastFour,
          dob: values.dob,
        });
        break;
      case RoleType.MEDICAID_REFERRAL_COORDINATOR:
        dto.verificationInputsJson = JSON.stringify({
          organizationId: values.organizationID,
          verifiersFirstName: values.verifiersFirstName,
          verifiersLastName: values.verifiersLastName,
          verifiersEmail: values.verifiersEmail,
        });
        break;
      default:
        dto.verificationInputsJson = JSON.stringify({
          verifiersFirstName: values.verifiersFirstName,
          verifiersLastName: values.verifiersLastName,
          verifiersEmail: values.verifiersEmail,
        });
        break;
    }
    setSubmitting(true);
    createMedicaidInvite(dto)
      .then((res) => {
        if (res.data.error) {
          setSubmitting(false);
          showSnackbar(res.data.error.message, "error");
        } else if (res.data.result) {
          setSubmitting(false);
          showSnackbar(`Successfully created invite ${res.data.result}`, "success");
          closeCallback();
        }
      })
      .catch(() => {
        setSubmitting(false);
        showSnackbar("Error creating invite", "error");
      });
  };

  return (
    <>
      <Formik initialValues={medicaidInitialValues} validationSchema={medicaidValidationSchema} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form localizationReady={true}>
            <Grid container spacing={2}>
              <Grid container item justifyContent="space-between">
                <Grid item xs={10}>
                  <PanelTitle>Invite User</PanelTitle>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <IconButton onClick={closeCallback}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="firstName"
                  label="First Name"
                  type="text"
                  required={isRequiredField("firstName", medicaidValidationSchema)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="lastName"
                  label="Last Name"
                  type="text"
                  required={isRequiredField("lastName", medicaidValidationSchema)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="email"
                  label="Email"
                  type="email"
                  required={isRequiredField("email", medicaidValidationSchema)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelectField
                  name="regPathId"
                  label="Role*"
                  selectOptions={roleOptions.map((dto) => ({
                    label:
                      dto.roleConfig.roleType === RoleType.MEDICAID_CASE_MANAGER
                        ? "Case Manager II"
                        : dto.roleConfig.roleDisplayName,
                    value: dto.id,
                  }))}
                  onChange={(e: any) => {
                    setRoleId(e.target.value);
                    setFieldValue("role", roleOptions.find((dto) => dto.id === e.target.value)!!.roleConfig.roleType);
                  }}
                />
              </Grid>
              {roleId && (
                <>
                  <FormTextField type="hidden" style={{ opacity: 0 }} name="role" />
                  <DynamicFormInput roleType={getRoleType()} />
                  <Grid item xs={12}>
                    <FormTextField
                      name="reason"
                      label="Reason for Inviting"
                      type="text"
                      required={isRequiredField("reason", medicaidValidationSchema)}
                    />
                  </Grid>
                </>
              )}
              <Grid container item justifyContent="flex-end">
                <Button variant="text" type="button" onClick={closeCallback}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={!roleId || isSubmitting}>
                  Send Invite
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MedicaidUsersInviteForm;
