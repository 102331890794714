import axios from "axios";

export class HealthCheckService {
  TIMEOUT: number = 15000;
  HEALTHCHECK_URL: string = "/api/health";

  async healthCheck(): Promise<boolean> {
    return axios
      .get(window.location.origin + this.HEALTHCHECK_URL, {
        timeout: this.TIMEOUT,
      })
      .then((response) => {
        return response.status === 200;
      });
  }
}
