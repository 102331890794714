import { PropsWithChildren, useState } from "react";
import { createContainer } from "unstated-next";

interface InitialConfig {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function privatePayContext(initialState?: InitialConfig) {
  const [zipCode, setZipState] = useState<string>(sessionStorage.getItem("private-pay-search-zip") || "");
  const setZipCode = (__zip: string) => {
    sessionStorage.setItem("private-pay-search-zip", __zip);
    setZipState(__zip);
  };

  const [relationship, setRelationshipState] = useState<string>(
    sessionStorage.getItem("private-pay-search-relationship") || "OTHER",
  );
  const setRelationship = (__relationship: string) => {
    sessionStorage.setItem("private-pay-search-relationship", __relationship);
    setRelationshipState(__relationship);
  };

  const [agencyName, setAgencyName] = useState<string>();
  const [agencyId, setAgencyId] = useState<string>();
  const [funderSegment, setFunderSegment] = useState<string>();

  const [zipCodeForWaitlist, setZipCodeForWaitlistState] = useState<string>(
    sessionStorage.getItem("private-pay-search-waitlist-zip") || "",
  );
  const setZipCodeForWaitlist = (__zip: string) => {
    sessionStorage.setItem("private-pay-search-waitlist-zip", __zip);
    setZipCodeForWaitlistState(__zip);
  };

  const [agencyDescription, setAgencyDescription] = useState<string>();

  return {
    zipCode,
    setZipCode,
    zipCodeForWaitlist,
    setZipCodeForWaitlist,
    relationship,
    setRelationship,
    agencyName,
    setAgencyName,
    agencyId,
    setAgencyId,
    funderSegment,
    setFunderSegment,
    agencyDescription,
    setAgencyDescription,
  };
}

const PrivatePayContext = createContainer(privatePayContext);

export const PrivatePayContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<{
  initialState?: InitialConfig;
}>) => <PrivatePayContext.Provider initialState={initialState}>{children}</PrivatePayContext.Provider>;

export const usePrivatePayContext = () => PrivatePayContext.useContainer();
