import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";

interface DashReturnLinkProps {
  to: string;
  children: ReactNode;
}

/**
 * Simply a return link that is pre-pended by an arrow icon. This component is specially
 * made for the dashboard details page.
 */
const DashReturnLink = (props: DashReturnLinkProps) => {
  return (
    <Box paddingTop={4}>
      <InternalLink to={props.to} underline="none">
        <Grid container>
          <ChevronLeftRoundedIcon />
          <Body>{props.children}</Body>
        </Grid>
      </InternalLink>
    </Box>
  );
};

export default DashReturnLink;
