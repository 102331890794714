import { ReactElement } from "react";
import { useUserSession } from "src/context/UserSessionContext";
import { ProviderListDTO, ProviderNotificationDTO } from "src/generated/api_types";
import AssistiveRoleProviderCard from "src/reusable_view_elements/provider_card/AssistiveRoleProviderCard";
import RegularProviderCard from "src/reusable_view_elements/provider_card/RegularProviderCard";

export interface ProviderCardProps {
  previewPageCard?: boolean;
  profile: ProviderListDTO | ProviderNotificationDTO;
  buttonSection: ReactElement;
  hideBookmark?: boolean;
  onBookmark?: () => void;
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  previewPageCard,
  profile,
  hideBookmark,
  buttonSection,
  onBookmark,
}) => {
  const { isAssistiveRoleView } = useUserSession();

  if (isAssistiveRoleView()) {
    return (
      <AssistiveRoleProviderCard
        previewPageCard={previewPageCard}
        profile={profile}
        hideBookmark={hideBookmark}
        buttonSection={buttonSection}
        onBookmark={onBookmark}
      />
    );
  }
  return (
    <RegularProviderCard
      previewPageCard={previewPageCard}
      profile={profile}
      hideBookmark={hideBookmark}
      buttonSection={buttonSection}
      onBookmark={onBookmark}
    />
  );
};

export default ProviderCard;
