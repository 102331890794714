import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { User_AccountStatus } from "src/generated/api_types";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import ViewJobs from "src/pages/tutorials/caseWorkerPages/ViewJobs";
import ViewProviders from "src/pages/tutorials/caseWorkerPages/ViewProviders";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import { ExternalLink } from "src/reusable_view_elements/Link";
import { BasicNavBar } from "src/reusable_view_elements/navbars/NavBar";
import { Button } from "src/reusable_view_elements/Button";
import Segment from "src/reusable_view_elements/Segment";
import BasicStepper from "src/reusable_view_elements/steppers/BasicStepper";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

export interface TutorialProps {
  handleOnboardingSubmit?: () => Promise<void>;
}

const CaseWorkerTutorial = ({ handleOnboardingSubmit }: TutorialProps) => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const Components = [<ViewProviders key={0} />, <ViewJobs key={1} />];
  const StepNames = ["View Provider Profiles", "View Job Posts"];
  const isFirstStep = step === 0;
  const isFinalStep = step === StepNames.length - 1;
  const caseWorkerDesktopTutorial =
    "https://carina-core-static.s3.us-west-2.amazonaws.com/PDF-CaseWorker-Tutorial-Desktop.pdf";
  const caseWorkerMobileTutorial =
    "https://carina-core-static.s3.us-west-2.amazonaws.com/PDF-CaseWorker-Tutorial-Mobile.pdf";
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  const { userSession } = useUserSession();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  function handleBackButton() {
    if (!isFirstStep) {
      setStep(step - 1);
    } else {
      history.push(
        userSession?.accountStatus === User_AccountStatus.ACTIVE
          ? MEDICAID_ACCOUNT_ROUTES.homepage
          : ONBOARDING_ROUTES.root,
      );
    }
  }

  function handleNextButton() {
    if (!isFinalStep) {
      setStep(step + 1);
    } else if (isFinalStep && userSession?.accountStatus === User_AccountStatus.NEW && handleOnboardingSubmit) {
      // if the user status is NEW and we have handleSubmit prop, we know user is in onboarding stage
      handleOnboardingSubmit().then(() => {});
    } else {
      history.push(MEDICAID_ACCOUNT_ROUTES.homepage);
    }
  }

  return (
    <>
      <BasicNavBar />

      <main id="main-content">
        <Segment bgcolor={CivColors.lightGray} columns={12} minimal>
          <DashReturnLink
            to={
              userSession?.accountStatus === User_AccountStatus.ACTIVE
                ? MEDICAID_ACCOUNT_ROUTES.homepage
                : ONBOARDING_ROUTES.root
            }
          >
            Back
          </DashReturnLink>
        </Segment>

        <Segment bgcolor={CivColors.lightGray} columns={8} minimizeBottomGutter>
          <BasicStepper activeStep={step} steps={StepNames} mobileStyle={onMobile} />
        </Segment>

        {Components[step]}

        <Box bgcolor={CivColors.lightGray} textAlign="center" paddingY="25px" paddingX={2}>
          <Constraint columns={9}>
            <Grid container justifyContent="flex-end" spacing={2}>
              {onMobile && (
                <>
                  <Grid item>
                    <Button variant="outlined" type="button" onClick={handleBackButton}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item style={{ flex: 2 }}>
                    <Button variant="contained" type="button" onClick={handleNextButton} sx={{ width: "100%" }}>
                      {isFinalStep ? "Done" : "Next"}
                    </Button>
                  </Grid>
                  {isFinalStep && (
                    <Grid item style={{ flex: 1 }}>
                      <ExternalLink to={caseWorkerMobileTutorial} style={{ textDecoration: "none" }}>
                        <Button variant="outlined" type="button">
                          Download Tutorial as PDF
                        </Button>
                      </ExternalLink>
                    </Grid>
                  )}
                </>
              )}
              {onDesktop && (
                <>
                  <Grid item>
                    <Button variant="outlined" type="button" onClick={handleBackButton}>
                      Back
                    </Button>
                  </Grid>
                  {isFinalStep && (
                    <Grid item>
                      <ExternalLink to={caseWorkerDesktopTutorial} style={{ textDecoration: "none" }}>
                        <Button variant="outlined" type="button">
                          Download Tutorial as PDF
                        </Button>
                      </ExternalLink>
                    </Grid>
                  )}
                  <Grid item>
                    <Button variant="contained" type="button" onClick={handleNextButton}>
                      {isFinalStep ? "Done" : "Next"}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Constraint>
        </Box>
      </main>
    </>
  );
};

export default CaseWorkerTutorial;
