import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { ROUTES } from "src/MainRouter";
import { expandShortlink } from "src/pages/auth/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import PublicPrivateNavBar from "src/reusable_view_elements/navbars/PublicPrivateNavBar";
import Section from "src/reusable_view_elements/Section";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

export const ShortlinkHandler = () => {
  const [isFetching, setIsFetching] = useState(false);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  const { base62id } = useParams<{ base62id: string }>();

  useEffect(() => {
    setIsFetching(true);
    expandShortlink(base62id)
      .then((res) => {
        if (res.status === 200 && !!res.data) {
          window.location.href = res.data;
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Carina | Redirecting</title>
      </Helmet>

      <PublicPrivateNavBar />

      <Section bgcolor={CivColors.lightGray} style={desktopSize ? { paddingTop: "200px", paddingBottom: "200px" } : {}}>
        <Constraint columns={6} textAlign="center">
          <Body paragraph>{isFetching ? "Please wait. Expanding short link..." : "Bad short link"}</Body>
          {!isFetching && (
            <LinkStyledAsButton variant="contained" href={ROUTES.root}>
              Return Home
            </LinkStyledAsButton>
          )}
        </Constraint>
      </Section>

      <Footer englishOnly />
    </>
  );
};
