import {
  AxiosMedicaidProviderControllerClient,
  AxiosPublicMedicaidControllerClient,
  MedicaidProviderProfile_DTO_Request_ProfileDetails,
} from "src/generated/api_types";

const client = new AxiosMedicaidProviderControllerClient();
const publicMedicaidClient = new AxiosPublicMedicaidControllerClient();

export async function updateProfilePhoto(fileInBase64String: string) {
  return client.updateProfilePhoto({ fileInBase64String });
}

export function updateProviderProfile(id: string, dto: MedicaidProviderProfile_DTO_Request_ProfileDetails) {
  return client.updateProviderProfile(id, dto);
}

export function getJobsPreviewDetails(id: string) {
  return publicMedicaidClient.getNewJobsNearProvider(id);
}
