import { styled } from "@mui/material/styles";
import { Link as MuiLink } from "@mui/material";
import i18next from "i18next";
import { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import { nsCommonTooltips } from "src/i18n/Namespaces";
import { getGenderEquityBlogPost } from "src/pages/blog/BlogPosts";
import Popover from "src/reusable_view_elements/Popover";
import { Body } from "../Typography";

const StyledBody = styled(Body)({
  marginBottom: 22,
});

const GenderPopover = (props: PropsWithChildren<{}>) => {
  const { t } = useTranslation(nsCommonTooltips);

  const genderPopoverDescription = (
    <>
      <StyledBody>{t("popover.gender.description_refers_to", { ns: nsCommonTooltips })}</StyledBody>
      <StyledBody>{t("popover.gender.description_you_can_update", { ns: nsCommonTooltips })}</StyledBody>
      <Trans
        t={t}
        i18nKey="popover.gender.description_learn_more"
        ns={nsCommonTooltips}
        components={{ bloglink: <MuiLink href={getGenderEquityBlogPost(i18next.language)} target="_blank" /> }}
        parent={Body}
      />
    </>
  );

  return (
    <Popover
      contentTitle={t("popover.gender.label", { ns: nsCommonTooltips })}
      content={genderPopoverDescription}
      contentId="gender-popover"
    >
      {props.children}
    </Popover>
  );
};

export default GenderPopover;
