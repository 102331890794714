import { useUserSession } from "src/context/UserSessionContext";

interface AuthenticatedComponentProps {
  component: React.FC;
}

export const AuthenticatedComponent = ({ component: Component }: AuthenticatedComponentProps) => {
  const { userSession, loading } = useUserSession();

  if (loading || !userSession) {
    return <></>;
  }

  return <Component />;
};
