import { Box, Link as MuiLink } from "@mui/material";
import { Formik } from "formik";
import i18n from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import {
  LocizeLanguage,
  RegistrationController_PasswordCreationDTO,
  RegistrationStepEnum,
  RoleType,
} from "src/generated/api_types";
import { LangPrefByKey } from "src/i18n/Languages";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidRegPassword } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { RegistrationPageContainer } from "src/pages/registration/components/RegistrationPageContainer";
import { createPassword } from "src/pages/registration/password_creation/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Constraint from "src/reusable_view_elements/Constraint";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormPasswordField } from "src/reusable_view_elements/form_fields/FormPasswordField";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { boolean, object, ref, string } from "yup";

interface PasswordFormFields {
  loginEmail: string;
  password: string;
  confirmedPassword: string;
  terms?: boolean;
}

export interface PasswordUrlParams {
  funder: string;
  role: string;
}

const Password = () => {
  const { userInfo, isInvitedUser } = useRegistrationContext();
  const { showSnackbar } = useNotification();
  const { reloadAuthState } = useUserSession();
  const { role } = useParams<PasswordUrlParams>();
  const roleParam = (role || "").toUpperCase();
  const { t, ready } = useTranslation([nsMedicaidRegPassword, nsCommonFormsBtns, nsCommonToasts]);

  const schema = object().shape({
    password: string()
      .label(t("field.password.label", { ns: nsCommonFormsBtns }))
      .required(t("field.password.error_required", { ns: nsCommonFormsBtns }))
      .min(8, t("field.password.error_min", { ns: nsCommonFormsBtns })),
    confirmedPassword: string()
      .label(t("field.reenter_password.label", { ns: nsCommonFormsBtns }))
      .oneOf([ref("password"), undefined], t("field.reenter_password.error_must_match", { ns: nsCommonFormsBtns }))
      .required(t("field.reenter_password.error_required", { ns: nsCommonFormsBtns })),
    terms: isInvitedUser
      ? boolean()
          .label(t("field.tos_checkbox.carina_tos.label", { ns: nsCommonFormsBtns }))
          .oneOf([true], t("field.tos_checkbox_error", { ns: nsCommonFormsBtns }))
      : boolean().notRequired(),
  });

  const initialValues: PasswordFormFields = {
    loginEmail: userInfo.email,
    password: "",
    confirmedPassword: "",
    terms: false,
  };

  const getLangPrefFromSiteLang = (): LocizeLanguage => {
    const { language } = i18n;
    return LangPrefByKey[language] as LocizeLanguage;
  };

  const handleSubmit = async (values: PasswordFormFields) => {
    const relevantUserInfo: RegistrationController_PasswordCreationDTO = {
      email: values.loginEmail,
      password: values.confirmedPassword,
      langPref: getLangPrefFromSiteLang(),
    };

    try {
      await createPassword(relevantUserInfo);
      reloadAuthState();
    } catch (err: any) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  };

  if (!ready) {
    return (
      <Section bgcolor={CivColors.lightGray}>
        <LoadingCircle />
      </Section>
    );
  }

  return (
    <RegistrationPageContainer currentStep={RegistrationStepEnum.PASSWORD_CREATION}>
      <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
        <Constraint columns={6}>
          <SectionTitle align="center">
            {roleParam === RoleType.CASE_WORKER
              ? t("password.label_case_worker", { ns: nsMedicaidRegPassword, name: userInfo.firstName })
              : t("password.label", { ns: nsMedicaidRegPassword })}
          </SectionTitle>
          <Body paragraph align="center">
            {roleParam === RoleType.CASE_WORKER
              ? t("password.description.create_a_pwd_case_worker", { ns: nsMedicaidRegPassword })
              : t("password.description.create_a_pwd", { ns: nsMedicaidRegPassword })}
          </Body>
          <Body paragraph align="center">
            {t("password.description.use_8_char", { ns: nsMedicaidRegPassword })}
          </Body>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.lightGray} sx={{ padding: 2 }}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form localizationReady={ready}>
              <Constraint columns={4} sx={{ paddingTop: CivTheme.spacing(4) }}>
                <Body sx={{ marginBottom: "24px" }}>
                  {t("field.marked_with_asterisk.label", { ns: nsCommonFormsBtns })}
                </Body>
                <FormTextField
                  InputProps={{ readOnly: true }}
                  name="loginEmail"
                  label={t("login_email.label", { ns: nsMedicaidRegPassword })}
                  sx={{ marginBottom: "24px" }}
                />
                <FormPasswordField
                  name="password"
                  label={t("field.password.label", { ns: nsCommonFormsBtns })}
                  helperText={t("field.password.error_min", { ns: nsCommonFormsBtns })}
                  required={isRequiredField("password", schema)}
                  sx={{ marginBottom: "24px" }}
                />
                <FormPasswordField
                  name="confirmedPassword"
                  label={t("field.reenter_password.label", { ns: nsCommonFormsBtns })}
                  helperText={t("field.reenter_password.error_must_match", { ns: nsCommonFormsBtns })}
                  required={isRequiredField("confirmedPassword", schema)}
                />
              </Constraint>

              <Constraint columns={8} mt={8}>
                {isInvitedUser && (
                  <CheckboxField
                    name="terms"
                    style={{ padding: "18px 0px 0px", marginBottom: "32px" }}
                    label={
                      <Body>
                        <Trans
                          t={t}
                          i18nKey="field.tos_checkbox.description_required"
                          ns={nsCommonFormsBtns}
                          components={{
                            ctos: <MuiLink href={ROUTES.termsOfService} target="_blank" />,
                            prpo: <MuiLink href={ROUTES.privacyPolicy} target="_blank" />,
                            asterisk: <b />,
                          }}
                          values={{
                            ctos_key: t("field.tos_checkbox.carina_tos.label", { ns: nsCommonFormsBtns }),
                            prpo_key: t("field.tos_checkbox.privacy_policy.label", {
                              ns: nsCommonFormsBtns,
                            }),
                          }}
                        />
                      </Body>
                    }
                  />
                )}
                <Box pb={12} textAlign="center">
                  <FormAlert schema={schema} sx={{ mt: 2, mb: 2, textAlign: "left" }} />
                  <Button variant="contained" type="submit" disabled={isSubmitting} data-testid="create-account-button">
                    {isSubmitting
                      ? t("button.creating_account", { ns: nsCommonFormsBtns })
                      : t("button.create_account", { ns: nsCommonFormsBtns })}
                  </Button>
                </Box>
              </Constraint>
            </Form>
          )}
        </Formik>
      </Section>
    </RegistrationPageContainer>
  );
};

export default Password;
