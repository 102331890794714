import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { nsMedicaidProviderProfile } from "src/i18n/Namespaces";
import ProviderProfileDetailsContent from "src/pages/medicaidAccount/sharedComponents/ProviderProfileDetailsContent";
import Footer from "src/reusable_view_elements/Footer";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";

const ProviderProfileDetails = () => {
  const { id: pathId } = useParams<{ id: string | undefined }>();
  const { userSession } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidProviderProfile]);

  // If pathId exists, we know that user is coming from someplace other than "My Profile" link
  const id = pathId || userSession?.id || "";

  return (
    <>
      <Helmet>
        <title>
          {ready
            ? t("browser_tab_title.profile_details", { ns: nsMedicaidProviderProfile })
            : "Carina | Medicaid Provider Profile Details"}
        </title>
      </Helmet>
      <MedicaidNavbar />
      <main id="main-content">
        <ProviderProfileDetailsContent profileId={id} />
      </main>
      <Footer />
    </>
  );
};

export default ProviderProfileDetails;
