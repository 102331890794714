import { Container, Divider, Grid, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { funderSelectionController } from "src/pages/supportAdminAccount/ControllerClients";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { SelectOption } from "src/reusable_view_elements/form_fields";
import Select from "src/reusable_view_elements/form_fields/Select";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PageContainerDashboard, { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";

export enum SupportAdminDashboardTab {
  ACTIVE,
  INVITED,
  // BLOCKED, // Postponed blocked users list #181991643
  REPORTED,
}

export interface SupportAdminDashboardContainerProps {
  tab: SupportAdminDashboardTab;
}

export const SUPPORT_ADMIN_PROGRAM_KEY = "support-admin-program";

interface DropdownProps {
  dashboardType: DashboardType;
}

const ProgramDropdown = ({ dashboardType }: DropdownProps) => {
  const { program, setProgram, programOptions } = useSupportAdminContext();

  useEffect(() => {
    const programId = sessionStorage.getItem(SUPPORT_ADMIN_PROGRAM_KEY) || "none";
    setProgram(programId);
  }, []);

  function getProgramOptions(): SelectOption<string>[] {
    return programOptions.map((option) => {
      return {
        label: dashboardType === DashboardType.CHILDCARE ? `${option.segment} ${option.name}` : option.segment,
        value: option.id,
      };
    });
  }

  return (
    <Select
      name="programSelect"
      label="Program"
      value={program === "none" ? "" : program}
      onChange={(event: SelectChangeEvent<any>) => {
        setProgram(event.target.value);
        sessionStorage.setItem(SUPPORT_ADMIN_PROGRAM_KEY, event.target.value);
      }}
      selectOptions={getProgramOptions()}
      placeholderText="Select Program"
    />
  );
};

export const MedicaidSupportAdminDashboardContainer = ({
  tab,
  children,
}: PropsWithChildren<SupportAdminDashboardContainerProps>) => {
  const { programOptions, setProgramOptions } = useSupportAdminContext();
  const { showSnackbar } = useNotification();
  const [isLoadingPrograms, setLoadingPrograms] = useState<boolean>(false);
  const { isUserType } = useUserSession();

  useEffect(() => {
    (async () => {
      if (programOptions.length === 0 && isUserType(UserType.UserSupportManager)) {
        try {
          setLoadingPrograms(true);
          const res = await funderSelectionController.fetchSelectOptions({ offering: "HC Medicaid" });
          setProgramOptions(res.data);
        } catch (e) {
          showSnackbar("Unable to load funder segment options", "error");
        } finally {
          setLoadingPrograms(false);
        }
      }
    })();
  }, []);

  return (
    <>
      {isUserType(UserType.UserSupportManager) && (
        <Helmet>
          <title>Carina | Medicaid: Support Manager Dashboard</title>
        </Helmet>
      )}
      {isUserType(UserType.UserSupportAgent) && (
        <Helmet>
          <title>Carina | Medicaid: Support Agent Dashboard</title>
        </Helmet>
      )}
      <PageContainerDashboard
        title={
          isUserType(UserType.UserSupportManager)
            ? "Carina User Support Manager Tools: Medicaid"
            : "Carina User Support Agent Tools: Medicaid"
        }
        maxWidth="xl"
      >
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Tabs value={tab}>
              <Tab component={Link} label="Users" to={SUPPORT_ADMIN_DASHBOARD_ROUTES.active_user} />
              <Tab component={Link} label="Invited Users" to={SUPPORT_ADMIN_DASHBOARD_ROUTES.invited_user} />
              {/* <Tab
                component={Link}
                label="Blocked Users"
                to={SUPPORT_ADMIN_DASHBOARD_ROUTES.blocked_user}
              /> */}
              {isUserType(UserType.UserSupportManager) && (
                <Tab component={Link} label="Reported Cases" to={SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_cases} />
              )}
            </Tabs>
          </Grid>
          {isUserType(UserType.UserSupportManager) && (
            <Grid item style={{ paddingBottom: 4 }}>
              {isLoadingPrograms ? <LoadingCircle /> : <ProgramDropdown dashboardType={DashboardType.MEDICAID} />}
            </Grid>
          )}
        </Grid>
        <Divider />
        <Container maxWidth={false}>{isLoadingPrograms ? <LoadingCircle /> : children}</Container>
      </PageContainerDashboard>
    </>
  );
};

export const ChildcareSupportAdminDashboardContainer = ({
  tab,
  children,
}: PropsWithChildren<SupportAdminDashboardContainerProps>) => {
  const { programOptions, setProgramOptions } = useSupportAdminContext();
  const { showSnackbar } = useNotification();
  const [isLoadingPrograms, setLoadingPrograms] = useState<boolean>(false);
  const { isUserType } = useUserSession();

  useEffect(() => {
    (async () => {
      if (programOptions.length === 0 && isUserType(UserType.UserSupportManager)) {
        try {
          setLoadingPrograms(true);
          const res = await funderSelectionController.fetchSelectOptions({ offering: "CC Parents" });
          setProgramOptions(res.data);
        } catch (e) {
          showSnackbar("Unable to load funder segment options", "error");
        } finally {
          setLoadingPrograms(false);
        }
      }
    })();
  }, []);

  return (
    <>
      {isUserType(UserType.UserSupportManager) && (
        <Helmet>
          <title>Carina | Childcare: Support Manager Dashboard</title>
        </Helmet>
      )}
      {isUserType(UserType.UserSupportAgent) && (
        <Helmet>
          <title>Carina | Childcare: Support Agent Dashboard</title>
        </Helmet>
      )}
      <PageContainerDashboard
        title={
          isUserType(UserType.UserSupportManager)
            ? "Carina User Support Manager Tools: Childcare"
            : "Carina User Support Agent Tools: Childcare"
        }
        maxWidth="xl"
      >
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Tabs value={tab}>
              <Tab component={Link} label="Active Users" to={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_active_user} />
              <Tab component={Link} label="Invited Users" to={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_invited_user} />
            </Tabs>
          </Grid>
          {isUserType(UserType.UserSupportManager) && (
            <Grid item style={{ paddingBottom: 4 }}>
              {isLoadingPrograms ? <LoadingCircle /> : <ProgramDropdown dashboardType={DashboardType.CHILDCARE} />}
            </Grid>
          )}
        </Grid>
        <Divider />
        <Container maxWidth={false}>{isLoadingPrograms ? <LoadingCircle /> : children}</Container>
      </PageContainerDashboard>
    </>
  );
};
