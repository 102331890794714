import { format } from "date-fns";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import {
  ConsumerApplication_DTO_Response_Details,
  ConsumerApplication_DTO_Response_Listing,
  ConsumerApplicationGender,
  ContactMethod,
  HighlightReason,
} from "src/generated/api_types";
import {
  getAgencyNotes,
  getConsumerNotes,
  getDeclinedNotes,
} from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import {
  getCareRecipientOrContactFirstName,
  getCareRecipientOrContactLastName,
} from "src/pages/agencyAdmin/dashboard/consumers/GetConsumers";
import { useFunderAdminConsumersDatatable } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumerDashboard";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import { statusColumn } from "src/pages/offerings/pphc/datatable_columns/consumer_applications/StatusColumn";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { getConsumerAppDeclinedReasonLabel } from "src/utilities/ConsumerApplicationUtilities";
import { applyPhoneFormat, hasOnlyDigits, removeNonDigits } from "src/utilities/DashboardUtilities";

export function getCounty(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.county.replace("County", "") : "";
}

function getContactMethod(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.contact.preferredMethod) {
    switch (data.contact.preferredMethod) {
      case ContactMethod.EMAIL:
        return data.contact.email || "";
      case ContactMethod.PHONE:
        return applyPhoneFormat(data.contact.phone);
      case ContactMethod.SMS:
        return applyPhoneFormat(data.contact.phone);
      default:
        return "";
    }
  } else {
    return applyPhoneFormat(data.contact.phone) || data.contact.email;
  }
}

function getPetsResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (
    data.homeEnvironment.DOGS === true ||
    data.homeEnvironment.CATS === true ||
    data.homeEnvironment.BIRDS === true ||
    data.homeEnvironment.OTHER_PETS === true
  ) {
    return "Yes";
  }

  if (
    data.homeEnvironment.DOGS === false &&
    data.homeEnvironment.CATS === false &&
    data.homeEnvironment.BIRDS === false &&
    data.homeEnvironment.OTHER_PETS === false
  ) {
    return "No";
  }

  return "";
}

function getSmokingResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.homeEnvironment.SMOKING_HOME === true) {
    return "Yes";
  }

  if (data.homeEnvironment.SMOKING_HOME === false) {
    return "No";
  }

  return "";
}

export function getUpdatedDate(
  data: ConsumerApplication_DTO_Response_Listing | ConsumerApplication_DTO_Response_Details,
): Date {
  return data.updatedAt ? new Date(data.updatedAt) : new Date();
}

export function getCreatedDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return new Date(data.createdAt);
}

export function getDobDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return data.careRecipient.dateOfBirth ? new Date(data.careRecipient.dateOfBirth) : new Date(1800, 0, 1);
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getGender(data: ConsumerApplication_DTO_Response_Listing): string {
  switch (data.careRecipient.gender) {
    case ConsumerApplicationGender.NOT_DISCLOSED:
      return "Not Disclosed";
    case ConsumerApplicationGender.DIFFERENT_IDENTITY:
      return "Different Identity";
    case ConsumerApplicationGender.FEMALE:
      return "Female";
    case ConsumerApplicationGender.MALE:
      return "Male";
    default:
      return "Not Disclosed";
  }
}

function getZipCode(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.zipCode : "";
}

function getCity(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.city : "";
}

function getLanguages(data: ConsumerApplication_DTO_Response_Listing): string {
  const languagesArray = data.languages
    .map((language) => _.startCase(_.lowerCase(`${language.toString().replace(/_/g, " ")}`)))
    .sort();
  return languagesArray.toString().replace(/,/g, ", ");
}

/*********************************************************
 ******************* Page Definition *********************
 *********************************************************/
export const FunderAdminConsumersList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useFunderAdminConsumersDatatable();

  function isStatusHighlighted(highlightReasons?: HighlightReason[]): boolean {
    if (highlightReasons && highlightReasons.length > 0) {
      if (highlightReasons.includes(HighlightReason.NEW) || highlightReasons.includes(HighlightReason.STALLED)) {
        return true;
      }
    }
    return false;
  }

  function isAssignedToHighlighted(highlightReasons?: HighlightReason[]): boolean {
    if (highlightReasons && highlightReasons.length > 0) {
      if (highlightReasons.includes(HighlightReason.UNASSIGNED)) {
        return true;
      }
    }
    return false;
  }

  /*********************************************************
   ******************* Column Definitions ******************
   *********************************************************/
  const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<ConsumerApplication_DTO_Response_Listing> } = {
    agency: {
      title: "Agency",
      field: "agency.name",
    },
    county: {
      title: "County",
      render: (data) => getCounty(data),
      customFilterAndSearch: (term, data) => {
        return getCounty(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getCounty(data1).toLowerCase().localeCompare(getCounty(data2).toLowerCase());
      },
    },
    first_name: {
      title: "First Name",
      render: (data) => {
        return (
          <>
            <Body>
              <InternalLink
                to={
                  data.id! &&
                  generatePath(FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_details, {
                    id: data.id,
                  })
                }
              >
                {data.careRecipient.firstName ? data.careRecipient.firstName : data.contact.firstName}
              </InternalLink>
            </Body>
            {!data.careRecipient.firstName && <Body>(representative)</Body>}
          </>
        );
      },
      customFilterAndSearch: (term, data) => {
        return getCareRecipientOrContactFirstName(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        const name1 = getCareRecipientOrContactFirstName(data1);
        const name2 = getCareRecipientOrContactFirstName(data2);
        return name1.toLowerCase().localeCompare(name2.toLowerCase());
      },
    },
    last_name: {
      title: "Last Name",
      render: (data) => {
        return <Body>{getCareRecipientOrContactLastName(data)}</Body>;
      },
      customFilterAndSearch: (term, data) => {
        return getCareRecipientOrContactLastName(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        const name1 = getCareRecipientOrContactLastName(data1);
        const name2 = getCareRecipientOrContactLastName(data2);
        return name1.toLowerCase().localeCompare(name2.toLowerCase());
      },
      hidden: true,
    },
    contact_method: {
      title: "Contact Method",
      render: getContactMethod,
      customFilterAndSearch: (term, data) => {
        const cmStr = getContactMethod(data);
        if (term.match(/^\d+$/)) {
          return cmStr.replace(/\D|\s/g, "").indexOf(term) !== -1;
        }
        return cmStr.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) =>
        getContactMethod(data1).toLowerCase().localeCompare(getContactMethod(data2).toLowerCase()),
      hidden: true,
    },
    email: {
      title: "Email",
      render: (data) => data.contact.email,
      customFilterAndSearch: (term, data) =>
        data.contact.email.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1,
      customSort: (data1, data2) => data1.contact.email.toLowerCase().localeCompare(data2.contact.email.toLowerCase()),
      hidden: true,
    },
    phone: {
      title: "Phone",
      render: (data) => applyPhoneFormat(data.contact.phone),
      customFilterAndSearch: (term, data) => {
        if (hasOnlyDigits(term)) {
          return removeNonDigits(data.contact.phone).indexOf(term) !== -1;
        }
        return data.contact.phone.indexOf(term) !== -1;
      },
      customSort: (data1, data2) => {
        return data1.contact.phone.localeCompare(data2.contact.phone.toLowerCase());
      },
      hidden: true,
    },
    address: {
      title: "Address",
      field: "careRecipient.address",
      hidden: true,
    },
    city: {
      title: "City",
      render: (data) => getCity(data),
      customFilterAndSearch: (term, data) => {
        return getCity(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getCity(data1).toLowerCase().localeCompare(getCity(data2).toLowerCase());
      },
      hidden: true,
    },
    zip_code: {
      title: "ZIP Code",
      render: (data) => getZipCode(data),
      customFilterAndSearch: (term, data) => {
        return getZipCode(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getZipCode(data1).localeCompare(getZipCode(data2));
      },
      hidden: true,
    },
    assignedTo: {
      title: "Assigned to",
      customFilterAndSearch: (term, data) =>
        `${data.assignedAgencyAdmin?.firstName} ${data.assignedAgencyAdmin?.lastName}`
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
      render: (row) => `${row.assignedAgencyAdmin?.firstName || ""} ${row.assignedAgencyAdmin?.lastName || ""}`,
      cellStyle: (data, rowData) => ({
        color: isAssignedToHighlighted(rowData.highlightReasons) ? CivColors.deepRose : CivColors.coreDarkNavy,
        fontWeight: isAssignedToHighlighted(rowData.highlightReasons) ? 700 : 400,
      }),
    },
    status: {
      ...statusColumn(),
      cellStyle: (data, rowData) => ({
        color: isStatusHighlighted(rowData.highlightReasons) ? CivColors.deepRose : CivColors.coreDarkNavy,
        fontWeight: isStatusHighlighted(rowData.highlightReasons) ? 700 : 400,
      }),
    },
    hours: {
      title: "Req'd Hrs/Wk",
      field: "hours",
      hidden: true,
    },
    consumerNotes: {
      title: "Care Needs Notes",
      render: getConsumerNotes,
      hiddenByColumnsButton: false,
      customFilterAndSearch: (term, data) => data.consumerNotes.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    agencyNotes: {
      title: "Agency Notes",
      render: getAgencyNotes,
      hiddenByColumnsButton: false,
      customFilterAndSearch: (term, data) => data.agencyNotes.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    declinedReason: {
      title: "Reason for Decline",
      render: (data) => getConsumerAppDeclinedReasonLabel(data.declinedReason),
      hidden: true,
      customFilterAndSearch: (term, data) => data.declinedReason?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    declinedNotes: {
      title: "Declined Status Notes",
      render: getDeclinedNotes,
      hidden: true,
      customFilterAndSearch: (term, data) => data.declinedNotes?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    pets: {
      title: "Pets",
      render: getPetsResponse,
      hidden: true,
      customFilterAndSearch: (term, data) => getPetsResponse(data).toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    smoking: {
      title: "Smoking",
      render: getSmokingResponse,
      hidden: true,
      customFilterAndSearch: (term, data) => getSmokingResponse(data).toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    languages: {
      title: "Languages",
      render: (data) => getLanguages(data),
      customFilterAndSearch: (term, data) => {
        return getLanguages(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getLanguages(data1).toLowerCase().localeCompare(getLanguages(data2).toLowerCase());
      },
      hidden: true,
    },
    gender: {
      title: "Gender",
      render: (data) => getGender(data),
      customFilterAndSearch: (term, data) => {
        return getGender(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getGender(data1).toLowerCase().localeCompare(getGender(data2).toLowerCase());
      },
      hidden: true,
    },
    dob: {
      title: "DOB",
      render: (data) => (getDobDate(data).getFullYear() === 1800 ? "" : formatDate(getDobDate(data))),
      customFilterAndSearch: (term, data) => formatDate(getDobDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getDobDate(data1).getTime() - getDobDate(data2).getTime(),
      hidden: true,
    },
    updated: {
      title: "Last Updated",
      render: (data) => formatDate(getUpdatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getUpdatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getUpdatedDate(data1).getTime() - getUpdatedDate(data2).getTime(),
      defaultSort: "desc",
    },
    created: {
      title: "Create Date",
      render: (data) => formatDate(getCreatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getCreatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getCreatedDate(data1).getTime() - getCreatedDate(data2).getTime(),
      defaultSort: "desc",
      hidden: true,
    },
  };

  return (
    <>
      <Helmet>
        <title>Carina | Internal Dashboard Clients Tab</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="consumers-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Clients"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching client applications..." : "No client records to display",
          },
        }}
        highlightRowFn={(rowData: ConsumerApplication_DTO_Response_Listing) => {
          return !!rowData.highlightReasons && rowData.highlightReasons.length > 0;
        }}
      />
    </>
  );
};
