import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import { CarinaAdminFunderManagementController_FunderListingDTO } from "src/generated/api_types";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { useFunderMgmtDatatable } from "src/pages/carinaAdmin/management/funders/FunderMgmtRoot";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";

const COLUMN_DEFINITIONS: {
  [k: string]: ClientsideColumn<CarinaAdminFunderManagementController_FunderListingDTO>;
} = {
  name: {
    title: "Name",
    render: (data) => (
      <InternalLink
        to={generatePath(CARINA_ADMIN_MGMT_ROUTES.funder_details, {
          id: data.id,
        })}
      >
        <Body>{data.name}</Body>
      </InternalLink>
    ),
  },
  offering: {
    title: "Offering",
    field: "offering",
  },
  segment: {
    title: "Segment",
    field: "segment",
  },
};

export const FunderMgmtList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useFunderMgmtDatatable();

  return (
    <>
      <Helmet>
        <title>Carina | Internal Tools | Funder</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="funder-mgmt-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="Funders"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching funders..." : "No funders to display",
          },
        }}
      />
    </>
  );
};
