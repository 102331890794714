import { BoxProps, useMediaQuery } from "@mui/material";
import theme from "src/themes/civilization/CivTheme";

interface PanelListProps extends BoxProps {
  stretch?: boolean;
}

const defaultProps = {
  stretch: false,
};

const StretchPanelList = ({ ...rest }) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const desktopStyle = { display: "flex", justifyContent: "stretch", padding: 4 };
  const mobileStyle = { padding: 4 };
  const style = desktopSize ? desktopStyle : mobileStyle;

  return <div style={style} {...rest} />;
};

const NormalPanelList = ({ ...rest }) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const desktopStyle = { display: "flex", justifyContent: "center", padding: 4 };
  const mobileStyle = { padding: 4 };
  const style = desktopSize ? desktopStyle : mobileStyle;

  return <div style={style} {...rest} />;
};

const PanelList = ({ stretch, ...rest }: PanelListProps) => {
  if (stretch) {
    return <StretchPanelList {...rest} />;
  }

  return <NormalPanelList {...rest} />;
};

PanelList.defaultProps = defaultProps;
export default PanelList;
