/* eslint-disable max-len */
import { Box, Grid, Hidden, Link as MuiLink } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AxiosMessagingControllerClient, Permission, ProviderProfileTagEnum_Category } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidProviderProfile,
  nsMedicaidSearch,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { ProviderListOrigin, useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import AdvancedProviderFilters from "src/pages/medicaidAccount/searchComponents/AdvancedProviderFilters";
import FirstMessageDialog, { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import ProviderProfileDrawer from "src/pages/medicaidAccount/sharedComponents/ProviderProfileDrawer";
import { bookmarkProfile, unBookmarkProfile } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { getDashboardPortalTitle } from "src/pages/supportAdminAccount/portal/SupportPortalMain";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MessageFollowUpButton from "src/reusable_view_elements/MessageFollowUpButton";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import ProviderCard from "src/reusable_view_elements/provider_card/ProviderCard";
import Section from "src/reusable_view_elements/Section";
import { Snackbar } from "src/reusable_view_elements/Snackbar";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { scrollToId } from "src/utilities/ScrollToId";
import AddressSearch, { FiltersType } from "./searchComponents/AddressSearch";

const FindProviders = () => {
  const { showSnackbar, useMainSnackbar, closeSnackbar } = useNotification();
  const {
    isLoadingProviders,
    providerProfileList,
    setProviderProfileList,
    setProviderListOrigin,
    setClearProviderFilters,
    totalProviderCount,
    setProvidersPage,
    loadingNextProvidersPage,
    sendMsgDialogProps,
    setSendMsgDialogProps,
    setNextProviderPageWasCalled,
    visitedProviderIndex,
    setVisitedProviderIndex,
    setIsProvFilterDirty,
    setIsInitialJobsSearch,
  } = useMedicaidContext();
  const { userSession, hasPermission, isUserType, isAssistiveRoleView } = useUserSession();
  const { setTotalUnreadCount } = useInboxContext();
  const { t, ready: translationReady } = useTranslation([
    nsMedicaidSearch,
    nsMedicaidProviderProfile,
    nsCommonFormsBtns,
    nsCommonToasts,
    nsMedicaidTagsEnums,
  ]);

  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [msgBtnWasClicked, setMsgBtnWasClicked] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string>();
  const [profileDrawerId, setProfileDrawerId] = useState<string>();
  const [isProfileDrawerOpen, setProfileDrawerState] = useState<boolean>(false);

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/

  const enableMessageSnackbar = () => {
    closeSnackbar();
    setMsgBtnWasClicked(true);
  };

  useEffect(() => {
    if (sendMsgDialogProps) {
      setMsgModalOpen(true);
    }
  }, [sendMsgDialogProps]);

  /**********************************************************************************************************
   ******* Next block drives infinite scrolling *************************************************************
   ****** more info: https://medium.com/suyeonme/react-how-to-implement-an-infinite-scroll-749003e9896a *****
   *********************************************************************************************************/
  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setNextProviderPageWasCalled(true);
      setProvidersPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "5px",
      threshold: 0.2,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver, loadingNextProvidersPage]);
  /************************************************* *********************************************************/
  /************************************************* *********************************************************
   The next two functions update the providerProfileList object without having to
   make a call to the BE. This prevents rerendering the list, which would break the focus sequence of
   any open 'first message success snackbar' or move the user back to the top when using infinite scrolling
   ************************************************* *********************************************************/
  const updateProviderProfileAfterFirstMessage = (threadId?: string) => {
    if (!threadId) return;
    const updatedProviderProfileList = providerProfileList?.map((profile) => {
      return profile.entity.provider.id === selectedProviderId
        ? { ...profile, entity: { ...profile.entity, threadIds: [threadId] } }
        : profile;
    });
    setProviderProfileList(updatedProviderProfileList);
  };
  /************************************************* *********************************************************/

  useEffect(() => {
    setProviderListOrigin(ProviderListOrigin.FIND_PROVIDERS);
    if (providerProfileList.length > 0 && visitedProviderIndex !== -1) scrollToId(visitedProviderIndex.toString());
  }, []);

  const dashboardNavBarTriggerFn = (programId: string) => {
    setIsProvFilterDirty(true);
    setIsInitialJobsSearch(true);
    new AxiosMessagingControllerClient()
      .getUnreadThreadCount({ selectedFunderId: programId })
      .then((resp) => {
        setTotalUnreadCount(resp.data);
      })
      .catch(() => showSnackbar(t("error.fetching_unread_messages_count", { ns: nsCommonToasts }), "error"));
  };

  const handleBookmarkClick = async (profileId: string, isBookmarked?: boolean) => {
    if (isBookmarked === undefined) {
      return;
    }
    try {
      if (isBookmarked) {
        await unBookmarkProfile(profileId);
      } else {
        await bookmarkProfile(profileId);
      }
      const updatedProfileList = providerProfileList?.map((profile) => {
        return profile.entity.id === profileId
          ? { ...profile, entity: { ...profile.entity, bookmarked: !isBookmarked } }
          : profile;
      });
      setProviderProfileList(updatedProfileList);
    } catch (e) {
      showSnackbar(t("error.error.processing_bookmark", { ns: nsCommonToasts }), "error");
    }
  };

  const getTitle = (): string | ReactElement => {
    if (!translationReady) return <Skeleton height={60} />;
    if (isLoadingProviders) return t("searching.label", { ns: nsMedicaidSearch });
    return t("count_providers_near_you.label", { ns: nsMedicaidProviderProfile, count: totalProviderCount });
  };

  return (
    <>
      <Helmet>
        <title>Carina | Medicaid Providers List</title>
      </Helmet>

      {isUserType(UserType.UserSupportManager) && (
        <DashboardNavbar
          title={getDashboardPortalTitle(UserType.UserSupportManager)}
          withProgramDropdown
          searchTriggerFn={dashboardNavBarTriggerFn}
        />
      )}

      <MedicaidNavbar />

      {isAssistiveRoleView() && (
        <ProviderProfileDrawer
          providerId={profileDrawerId}
          open={isProfileDrawerOpen}
          onClose={() => setProfileDrawerState(false)}
        />
      )}

      <main id="main-content">
        <Box bgcolor={CivColors.lightGray} pt={3} pb={1}>
          <Constraint columns={8}>
            <SectionTitle align="center">{getTitle()}</SectionTitle>
          </Constraint>
        </Box>

        <Section bgcolor={CivColors.lightGray} paddingBottom={0} paddingTop={0}>
          <Constraint columns={12}>
            <AddressSearch DrawerContents={<AdvancedProviderFilters />} filtersType={FiltersType.PROVIDERS} />
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
          <Constraint columns={12}>
            <Grid container spacing={2}>
              {translationReady && (
                <Hidden smDown>
                  <Grid item style={{ width: "300px" }}>
                    <BodyTitle paragraph>{t("refine_your_search.label", { ns: nsMedicaidSearch })}</BodyTitle>
                    <AdvancedProviderFilters />
                  </Grid>
                </Hidden>
              )}

              <Grid item sm container spacing={2}>
                {isLoadingProviders && (
                  <LoadingCircle
                    text={translationReady ? t("loading_your_search_results.label", { ns: nsMedicaidSearch }) : ""}
                  />
                )}

                {!isLoadingProviders && providerProfileList.length === 0 && translationReady && (
                  <Box textAlign="center" margin="0px auto" padding="100px 0px">
                    <Body paragraph>{t("currently_no_results.description", { ns: nsMedicaidSearch })}</Body>
                    <Trans
                      t={t}
                      i18nKey="you_can_clear_filters.description"
                      ns={nsMedicaidSearch}
                      components={{
                        clearfltr: (
                          <MuiLink component="button" variant="body1" onClick={() => setClearProviderFilters(true)} />
                        ),
                      }}
                      values={{
                        clearfltr_key: t("clear_search_filters.label", { ns: nsMedicaidSearch }),
                      }}
                      parent={Body}
                    />
                  </Box>
                )}

                {!isLoadingProviders && providerProfileList.length > 0 && (
                  <>
                    {sendMsgDialogProps && (
                      <FirstMessageDialog
                        {...sendMsgDialogProps}
                        isDialogOpen={isMsgModalOpen}
                        onCloseDialog={(threadId) => {
                          updateProviderProfileAfterFirstMessage(threadId);
                          setMsgModalOpen(false);
                          setSendMsgDialogProps(undefined);
                        }}
                      />
                    )}
                    <Grid item style={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {providerProfileList.map((profile, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={isAssistiveRoleView() ? 12 : 6}
                              style={{ display: "flex" }}
                              key={profile.entity.id}
                              id={index.toString()}
                              onClick={() => setVisitedProviderIndex(index)}
                            >
                              <ProviderCard
                                profile={profile}
                                hideBookmark={userSession?.id === profile.entity.provider.id}
                                onBookmark={() => handleBookmarkClick(profile.entity.id, profile.entity.bookmarked)}
                                buttonSection={
                                  <Grid container spacing={2} direction="column" alignItems="center">
                                    <Grid item sx={{ width: isAssistiveRoleView() ? "100%" : undefined }}>
                                      <LinkStyledAsButton
                                        variant="contained"
                                        component={!isAssistiveRoleView() && Link}
                                        to={
                                          !isAssistiveRoleView() &&
                                          generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
                                            id: profile.entity.provider.id,
                                          })
                                        }
                                        onClick={
                                          isAssistiveRoleView() &&
                                          (() => {
                                            setProfileDrawerId(profile.entity.provider.id);
                                            setProfileDrawerState(true);
                                          })
                                        }
                                        sx={{ height: "48px", textWrap: "nowrap", width: "100%" }}
                                      >
                                        {t("button.view_profile", { ns: nsCommonFormsBtns })}
                                      </LinkStyledAsButton>
                                    </Grid>
                                    {hasPermission(Permission.MESSAGING_ACCESS) &&
                                      userSession &&
                                      profile.entity.provider.id !== userSession.id &&
                                      profile.entity.threadIds && (
                                        <Grid item sx={{ width: isAssistiveRoleView() ? "100%" : undefined }}>
                                          <MessageFollowUpButton
                                            variant="outlined"
                                            onMessageClick={() => {
                                              setSelectedProviderId(profile.entity.provider.id);
                                              enableMessageSnackbar();
                                              setSendMsgDialogProps({
                                                recipientId: profile.entity.provider.id,
                                                recipientFirstName: profile.entity.provider.firstName,
                                                recipientLastName: profile.entity.provider.lastName,
                                                recipientRole: profile.entity.provider.role as UserType,
                                                context: FirstMessageContext.PROVIDER_PROFILE,
                                                pronouns: profile.entity.tags
                                                  .filter(
                                                    (tagObj) =>
                                                      tagObj.category === ProviderProfileTagEnum_Category.PRONOUN,
                                                  )
                                                  .sort((a, b) => a.order - b.order)
                                                  .map((tagObj) =>
                                                    t(tagObj.tag.toLowerCase(), { ns: nsMedicaidTagsEnums }),
                                                  ),
                                              });
                                            }}
                                            threadIds={profile.entity.threadIds}
                                            otherUserId={profile.entity.provider.id}
                                            sx={{
                                              height: "48px",
                                              textWrap: "nowrap",
                                              width: "100%",
                                              paddingBottom: "20px",
                                            }}
                                          />
                                          <Snackbar
                                            isEnabled={
                                              msgBtnWasClicked && index === visitedProviderIndex && !useMainSnackbar
                                            }
                                            onCloseCallback={() => setMsgBtnWasClicked(false)}
                                          />
                                        </Grid>
                                      )}
                                  </Grid>
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </>
                )}
                {loadingNextProvidersPage && (
                  <Box textAlign="center" margin="0px auto" padding="100px 0px">
                    <Body>{translationReady ? t("loading.label", { ns: nsMedicaidSearch }) : "Loading..."}</Body>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Constraint>
        </Section>
      </main>

      {/* this div is used as a reference to trigger request for next page of results */}
      <div>
        {!loadingNextProvidersPage && <div ref={loader} />}
        <Footer />
      </div>
    </>
  );
};
export default FindProviders;
