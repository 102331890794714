import { StepIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { CSSProperties } from "react";
import { CheckCircle1Icon } from "src/assets/icons/StreamlineIcons";
import {
  Circle1OutlinedIcon,
  Circle2OutlinedIcon,
  Circle3OutlinedIcon,
  Circle4OutlinedIcon,
  Circle5OutlinedIcon,
} from "src/assets/icons/CustomIcons";
import CivColors from "src/themes/civilization/CivColors";

// Helper to determine default or custom size for icon
function getIconSize(style: CSSProperties | undefined) {
  const defaultSize = 47;
  return (style && (style.width || style.height)) || defaultSize;
}

const StyledStepIconDiv = styled("div")(({ style }) => {
  const size = getIconSize(style);

  return {
    zIndex: 1,
    width: size,
    height: size,
    "&.Mui-active path": {
      fill: CivColors.coreDarkNavy,
    },
    "&.Mui-completed path": {
      fill: CivColors.coreOrange,
    },
    "&.Mui-incomplete path": {
      fill: CivColors.mediumGray,
    },
    "& .MuiStepIcon-icon": {
      width: size,
      height: size,
    },
  };
});

function StyledStepIcon(props: StepIconProps) {
  const { active, completed } = props;
  const size = getIconSize(props.style);

  const iconClass = clsx({
    "Mui-active": active,
    "Mui-completed": completed,
    "Mui-incomplete": !active && !completed,
    "MuiStepIcon-icon": true,
  });

  const outlinedIcons = {
    1: <Circle1OutlinedIcon className={iconClass} style={{ width: size, height: size }} />,
    2: <Circle2OutlinedIcon className={iconClass} style={{ width: size, height: size }} />,
    3: <Circle3OutlinedIcon className={iconClass} style={{ width: size, height: size }} />,
    4: <Circle4OutlinedIcon className={iconClass} style={{ width: size, height: size }} />,
    5: <Circle5OutlinedIcon className={iconClass} style={{ width: size, height: size }} />,
  };

  return (
    <StyledStepIconDiv style={props.style}>
      {completed ? (
        <CheckCircle1Icon className={clsx("MuiStepIcon-icon", "Mui-completed")} style={{ width: size, height: size }} />
      ) : (
        // @ts-ignore
        outlinedIcons[String(props.icon)]
      )}
    </StyledStepIconDiv>
  );
}

export default StyledStepIcon;
