import { Box, Grid, GridProps, Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  CommunicationMethod,
  CurrentSettingsDTO,
  LocizeLanguage,
  NotificationFrequency,
  NotificationType,
  Permission,
} from "src/generated/api_types";
import {
  nsCommonAria,
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidBEContent,
  nsMedicaidSettings,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedSettingsResponse } from "src/i18n/Utilities";
import { requestPasswordReset } from "src/pages/auth/Queries";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import EmailSection from "src/pages/medicaidAccount/settingsComponents/EmailSection";
import LanguagePrefsSection from "src/pages/medicaidAccount/settingsComponents/LanguagePrefsSection";
import { getUserSettings, updateUserSettings } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { FILTER_SETTING_COPY, FREQUENCY_OPTIONS, USER_COPY } from "src/pages/onboarding/step_pages/MedicaidSettings";
import { getDashboardPortalTitle } from "src/pages/supportAdminAccount/portal/SupportPortalMain";
import Constraint from "src/reusable_view_elements/Constraint";
import DashSection from "src/reusable_view_elements/DashSection";
import Footer from "src/reusable_view_elements/Footer";
import { FormAddressField, FormSelectField } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { removeNonDigits } from "src/utilities/DashboardUtilities";
import { object, string } from "yup";
import Form from "src/reusable_view_elements/form_fields/Form";

const StyledGrid = styled((props: GridProps & { disabled?: boolean }) => <Grid {...props} />)((props) => ({
  color: props.disabled ? CivColors.mediumGray : "inherit",
  "& path": {
    color: props.disabled ? CivColors.mediumGray : "inherit",
  },
}));

const ItemLine = () => (
  <Box style={{ padding: 0, margin: "14px 0px", borderBottom: `1px solid ${CivColors.mediumGray}` }} />
);

const MedicaidSettings = () => {
  const { showSnackbar } = useNotification();

  const [disclaimerHidden, setDisclaimerHidden] = useState<boolean>(true);
  const { userSession, isUserType, hasPermission } = useUserSession();
  const [userSettings, setUserSettings] = useState<CurrentSettingsDTO>();
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const userType = isUserType(UserType.Consumer) ? "CONSUMER" : "PROVIDER";
  const [isSavingSettings, setSavingSettings] = useState(false);
  const [smsConsentedInBackend, setSmsConsentedInBackend] = useState(false);
  const [editEmailSection, setEditEmailSection] = useState(false);
  const [editLangPrefSection, setEditLangPrefSection] = useState(false);
  const { t, ready } = useTranslation([
    nsMedicaidSettings,
    nsCommonFormsBtns,
    nsMedicaidTagsEnums,
    nsCommonToasts,
    nsMedicaidBEContent,
    nsCommonAria,
  ]);
  const {
    enableJobFltrWkNotif,
    setJobFilterNotificationFrequency,
    enableProviderFltrWkNotif,
    setProviderFilterNotificationFrequency,
  } = useMedicaidContext();

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingSettings(true);
        const res = await getUserSettings();
        setUserSettings(res.data);

        setSmsConsentedInBackend(res.data.contactInfo.smsConsent === true);
      } catch (e) {
        showSnackbar(
          ready
            ? t("error.something_wrong_loading_settings", { ns: nsCommonToasts })
            : "Something went wrong loading your settings. Please try again later.",
          "error",
        );
      } finally {
        setIsLoadingSettings(false);
      }
    })();
  }, []);

  function updateEmailInSettingsObject(newEmail: string) {
    if (userSettings) {
      setUserSettings({
        ...userSettings,
        contactInfo: {
          ...userSettings.contactInfo,
          email: newEmail,
        },
      });
    }
  }

  function updateLangPrefInSettingsObject(newLangPref: LocizeLanguage) {
    if (userSettings) {
      setUserSettings({
        ...userSettings,
        languagePreference: newLangPref,
      });
    }
  }

  /** -----------------------------------------------
   * ------- MAIN SETTINGS FORM COMPONENTS ---------
   * ------------------------------------------------
   */

  const handleSettingsSubmit = async (values: MedicaidSettingsFormFields) => {
    const updateSettingsObj: CurrentSettingsDTO = {
      contactInfo: {
        email: values.contactInfo.email,
        phone: values.contactInfo.phone,
        smsConsent: values.smsNotifications.consented,
        address: values.contactInfo.address,
      },
      languagePreference: values.languagePreference,
      emailSettings: {
        newJobs:
          values.emailNotifications.newJobs.selected && values.emailNotifications.newJobs.frequency
            ? {
                type: NotificationType.NEW_JOBS_NEARBY,
                communicationMethod: CommunicationMethod.EMAIL,
                frequency: values.emailNotifications.newJobs.frequency,
                enable: values.emailNotifications.newJobs.selected,
              }
            : undefined,
        newProviders:
          values.emailNotifications.newProviders.selected && values.emailNotifications.newProviders.frequency
            ? {
                type: NotificationType.NEW_PROVIDERS_NEARBY,
                communicationMethod: CommunicationMethod.EMAIL,
                frequency: values.emailNotifications.newProviders.frequency,
                enable: values.emailNotifications.newProviders.selected,
              }
            : undefined,
        jobFilter:
          values.emailNotifications.jobFilterMatch.selected && values.emailNotifications.jobFilterMatch.frequency
            ? {
                type: NotificationType.NEW_JOBS_FILTER,
                communicationMethod: CommunicationMethod.EMAIL,
                frequency: values.emailNotifications.jobFilterMatch.frequency,
                enable: values.emailNotifications.jobFilterMatch.selected,
              }
            : undefined,
        providerFilter:
          values.emailNotifications.providerFilterMatch.selected &&
          values.emailNotifications.providerFilterMatch.frequency
            ? {
                type: NotificationType.NEW_PROVIDERS_FILTER,
                communicationMethod: CommunicationMethod.EMAIL,
                frequency: values.emailNotifications.providerFilterMatch.frequency,
                enable: values.emailNotifications.providerFilterMatch.selected,
              }
            : undefined,
        jobPostExpiration: values.emailNotifications.jobPostExpiration
          ? {
              type: NotificationType.JOB_POST_EXPIRATION_FIRST_WARNING,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: NotificationFrequency.DELAYED,
              enable: values.emailNotifications.jobPostExpiration,
            }
          : undefined,
        updateProfileReminder: values.emailNotifications.updateProfileReminder
          ? {
              type: NotificationType.AVAILABILITY_STATUS_FIRST_WARNING,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: NotificationFrequency.DELAYED,
              enable: values.emailNotifications.updateProfileReminder,
            }
          : undefined,
        newMessage: values.emailNotifications.newMessage.frequency
          ? {
              type: NotificationType.NEW_MESSAGE,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.emailNotifications.newMessage.frequency,
              enable: values.emailNotifications.newMessage.selected,
            }
          : undefined,
      },
      smsSettings: {
        newJobs:
          values.smsNotifications.newJobs.selected && values.smsNotifications.newJobs.frequency
            ? {
                type: NotificationType.NEW_JOBS_NEARBY,
                communicationMethod: CommunicationMethod.SMS,
                frequency: values.smsNotifications.newJobs.frequency,
                enable: values.smsNotifications.newJobs.selected,
              }
            : undefined,
        newProviders:
          values.smsNotifications.newProviders.selected && values.smsNotifications.newProviders.frequency
            ? {
                type: NotificationType.NEW_PROVIDERS_NEARBY,
                communicationMethod: CommunicationMethod.SMS,
                frequency: values.smsNotifications.newProviders.frequency,
                enable: values.smsNotifications.newProviders.selected,
              }
            : undefined,
        jobFilter:
          values.smsNotifications.jobFilterMatch.selected && values.smsNotifications.jobFilterMatch.frequency
            ? {
                type: NotificationType.NEW_JOBS_FILTER,
                communicationMethod: CommunicationMethod.EMAIL,
                frequency: values.smsNotifications.jobFilterMatch.frequency,
                enable: values.smsNotifications.jobFilterMatch.selected,
              }
            : undefined,
        providerFilter:
          values.smsNotifications.providerFilterMatch.selected && values.smsNotifications.providerFilterMatch.frequency
            ? {
                type: NotificationType.NEW_PROVIDERS_FILTER,
                communicationMethod: CommunicationMethod.EMAIL,
                frequency: values.smsNotifications.providerFilterMatch.frequency,
                enable: values.smsNotifications.providerFilterMatch.selected,
              }
            : undefined,
        jobPostExpiration: values.smsNotifications.jobPostExpiration
          ? {
              type: NotificationType.JOB_POST_EXPIRATION_FIRST_WARNING,
              communicationMethod: CommunicationMethod.SMS,
              frequency: NotificationFrequency.DELAYED,
              enable: values.smsNotifications.jobPostExpiration,
            }
          : undefined,
        updateProfileReminder: values.smsNotifications.updateProfileReminder
          ? {
              type: NotificationType.AVAILABILITY_STATUS_FIRST_WARNING,
              communicationMethod: CommunicationMethod.SMS,
              frequency: NotificationFrequency.DELAYED,
              enable: values.smsNotifications.updateProfileReminder,
            }
          : undefined,
        newMessage: values.smsNotifications.newMessage.frequency
          ? {
              type: NotificationType.NEW_MESSAGE,
              communicationMethod: CommunicationMethod.SMS,
              frequency: values.smsNotifications.newMessage.frequency,
              enable: values.smsNotifications.newMessage.selected,
            }
          : undefined,
      },
    };

    try {
      setSavingSettings(true);
      const res = await updateUserSettings(updateSettingsObj);

      updateFiltersSettingsInContext(updateSettingsObj, res.data);

      if (res.data !== "Settings changes have been saved") {
        showSnackbar(getLocalizedSettingsResponse(res.data, t));
      } else if (
        res.data === "Settings changes have been saved" &&
        updateSettingsObj.contactInfo.smsConsent &&
        !smsConsentedInBackend
      ) {
        showSnackbar(t("success.settings_saved_opt_in", { ns: nsCommonToasts }), "success");
      } else if (res.data === "Settings changes have been saved") {
        showSnackbar(t("success.settings_changes_saved", { ns: nsCommonToasts }), "success");
      }
    } catch (e) {
      showSnackbar(t("error.something_wrong_saving_settings", { ns: nsCommonToasts }), "error");
    } finally {
      setSavingSettings(false);
    }
  };

  const updateFiltersSettingsInContext = (updateSettingsObj: CurrentSettingsDTO, apiResponseString: string) => {
    if (apiResponseString !== "Settings changes have been saved") return;
    if (updateSettingsObj.emailSettings.jobFilter?.enable) {
      enableJobFltrWkNotif(true);
      setJobFilterNotificationFrequency(updateSettingsObj.emailSettings.jobFilter.frequency);
    } else {
      enableJobFltrWkNotif(false);
    }
    if (updateSettingsObj.emailSettings.providerFilter?.enable) {
      enableProviderFltrWkNotif(true);
      setProviderFilterNotificationFrequency(updateSettingsObj.emailSettings.providerFilter.frequency);
    } else {
      enableProviderFltrWkNotif(false);
    }
  };

  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const settingsValidationSchema = object({
    languagePreference: string().required(t("field.generic.error.select_one_dropdown", { ns: nsCommonFormsBtns })),
    contactInfo: object().shape({
      phone: string()
        .test(
          "phone-validation",
          t("field.phone_number.error", { ns: nsCommonFormsBtns }),
          function testFunction(value) {
            // @ts-ignore
            const { from } = this; // based on code in https://github.com/jquense/yup/issues/735#issuecomment-873828710
            const isSmsConsented = from[1].value.smsNotifications.consented;
            const isFieldEmpty = !removeNonDigits(value as string | undefined);
            return !(isSmsConsented && isFieldEmpty);
          },
        )
        .matches(phoneRegExp, { message: t("field.phone_number.error", { ns: nsCommonFormsBtns }) })
        .nullable(),
      address: string().required(t("field.address.error", { ns: nsCommonFormsBtns })),
    }),
  });

  const settingsInitialValues: MedicaidSettingsFormFields = userSettings
    ? {
        languagePreference: userSettings.languagePreference || LocizeLanguage.English,
        contactInfo: {
          email: userSettings.contactInfo.email,
          phone: userSettings.contactInfo.phone,
          address: userSettings.contactInfo.address,
        },
        emailNotifications: {
          newJobs: {
            selected: !!userSettings.emailSettings?.newJobs,
            frequency: userSettings.emailSettings?.newJobs?.frequency
              ? userSettings.emailSettings.newJobs.frequency
              : NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: !!userSettings.emailSettings?.newProviders,
            frequency: userSettings.emailSettings?.newProviders?.frequency
              ? userSettings.emailSettings.newProviders.frequency
              : NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: !!userSettings.emailSettings?.jobFilter,
            frequency: userSettings.emailSettings?.jobFilter?.frequency
              ? userSettings.emailSettings.jobFilter.frequency
              : NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: !!userSettings.emailSettings?.providerFilter,
            frequency: userSettings.emailSettings?.providerFilter?.frequency
              ? userSettings.emailSettings.providerFilter.frequency
              : NotificationFrequency.DAILY,
          },
          jobPostExpiration: !!userSettings.emailSettings?.jobPostExpiration,
          updateProfileReminder: !!userSettings.emailSettings?.updateProfileReminder,
          newMessage: {
            selected: !!userSettings.emailSettings?.newMessage,
            frequency: userSettings.emailSettings?.newMessage?.frequency
              ? userSettings.emailSettings.newMessage.frequency
              : NotificationFrequency.IMMEDIATE,
          },
        },
        smsNotifications: {
          consented: smsConsentedInBackend,
          newJobs: {
            selected: !!userSettings.smsSettings?.newJobs,
            frequency: userSettings.smsSettings?.newJobs?.frequency
              ? userSettings.smsSettings.newJobs.frequency
              : NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: !!userSettings.smsSettings?.newProviders,
            frequency: userSettings.smsSettings?.newProviders?.frequency
              ? userSettings.smsSettings.newProviders.frequency
              : NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: !!userSettings.smsSettings?.jobFilter,
            frequency: userSettings.smsSettings?.jobFilter?.frequency
              ? userSettings.smsSettings.jobFilter.frequency
              : NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: !!userSettings.smsSettings?.providerFilter,
            frequency: userSettings.smsSettings?.providerFilter?.frequency
              ? userSettings.smsSettings.providerFilter.frequency
              : NotificationFrequency.DAILY,
          },
          jobPostExpiration: !!userSettings.smsSettings?.jobPostExpiration,
          updateProfileReminder: !!userSettings.smsSettings?.updateProfileReminder,
          newMessage: {
            selected: !!userSettings.smsSettings?.newMessage,
            frequency: userSettings.smsSettings?.newMessage?.frequency
              ? userSettings.smsSettings.newMessage.frequency
              : NotificationFrequency.IMMEDIATE,
          },
        },
      }
    : {
        languagePreference: userSession?.languagePreference || LocizeLanguage.English,
        contactInfo: {
          email: "email@email.com",
          phone: "",
          address: "",
        },
        emailNotifications: {
          newJobs: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobPostExpiration:
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidReferralCoordinator) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.ProxyProvider),
          updateProfileReminder: false,
          newMessage: {
            selected: false,
            frequency: NotificationFrequency.IMMEDIATE,
          },
        },
        smsNotifications: {
          consented: false,
          newJobs: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobPostExpiration:
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidReferralCoordinator) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.ProxyProvider),
          updateProfileReminder: false,
          newMessage: {
            selected: false,
            frequency: NotificationFrequency.IMMEDIATE,
          },
        },
      };

  /** -----------------------------------------------
   * ------- RESET PASSWORD FORM COMPONENTS ---------
   * ------------------------------------------------
   */

  interface ResetPwdFields {
    email: string;
  }

  const pwdValidationSchema = object({
    email: string().email(t("field.valid_email.error", { ns: nsCommonFormsBtns })),
  });

  const pwdInitialValues: ResetPwdFields = {
    email: "",
  };

  function handlePwdResetSubmit() {
    if (userSession?.email) {
      requestPasswordReset({ email: userSession.email })
        .then(() => {})
        .catch(() => {
          showSnackbar(
            t(
              "error.submitting_password_reset_request",
              "Error submitting password reset request. Please try again later.",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        });
    } else {
      showSnackbar("User session is missing");
    }
  }

  /** ----------------------------------------------- */

  if (!ready) {
    return (
      <div>
        <Helmet>
          <title>Carina | Medicaid Settings</title>
        </Helmet>
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.medicaid", { ns: nsMedicaidSettings })}</title>
      </Helmet>

      {isUserType(UserType.UserSupportManager) && (
        <DashboardNavbar title={getDashboardPortalTitle(UserType.UserSupportManager)} />
      )}

      <MedicaidNavbar />

      <main id="main-content">
        <Section bgcolor={CivColors.lightGray}>
          <Constraint columns={8}>
            <Constraint columns={6}>
              <SectionTitle align="center">{t("account_settings.label", { ns: nsMedicaidSettings })}</SectionTitle>
              <Body align="center" paragraph>
                {t("account_settings.description", { ns: nsMedicaidSettings })}
              </Body>
            </Constraint>

            {isLoadingSettings ? (
              <Section bgcolor={CivColors.lightGray}>
                <LoadingCircle />
              </Section>
            ) : (
              <>
                <DashSection bodyTitleLabel label={t("password.label", { ns: nsMedicaidSettings })} topBorder={false}>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={pwdInitialValues}
                      validationSchema={pwdValidationSchema}
                      onSubmit={handlePwdResetSubmit}
                    >
                      {({ isSubmitting, submitCount }) => {
                        if (submitCount > 0) {
                          return (
                            <>
                              <BodyEmphasis>{t("email_sent.label", { ns: nsMedicaidSettings })}</BodyEmphasis>
                              <Box p={2} />
                              <Body>{t("email_sent.description.check_your_email", { ns: nsMedicaidSettings })}</Body>
                              <Box p={2} />
                              <Body>{t("email_sent.description.if_your_email", { ns: nsMedicaidSettings })}</Body>
                              <Box p={2} />
                            </>
                          );
                        }

                        return (
                          <Form localizationReady={ready}>
                            <Body>{t("password.description", { ns: nsMedicaidSettings })}</Body>
                            <Box p={2} />
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                              {t("button.send_link_to_email", { ns: nsCommonFormsBtns })}
                            </Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </DashSection>

                {userSettings && (
                  <EmailSection
                    currentEmail={userSettings.contactInfo.email}
                    setCurrentEmail={updateEmailInSettingsObject}
                    editMode={editEmailSection}
                    toggleEditMode={setEditEmailSection}
                  />
                )}

                {userSettings && (
                  <LanguagePrefsSection
                    currentLangPref={userSettings.languagePreference}
                    setCurrentLangPref={updateLangPrefInSettingsObject}
                    editMode={editLangPrefSection}
                    toggleEditMode={setEditLangPrefSection}
                  />
                )}

                {settingsInitialValues && (
                  <Formik
                    initialValues={settingsInitialValues}
                    validationSchema={settingsValidationSchema}
                    onSubmit={(values) => handleSettingsSubmit(values)}
                  >
                    {({ values, setFieldValue }) => (
                      <Form localizationReady={ready}>
                        {isSavingSettings ? (
                          <Section bgcolor={CivColors.lightGray}>
                            <LoadingCircle text={t("saving_your_settings.label", { ns: nsMedicaidSettings })} />
                          </Section>
                        ) : (
                          <>
                            <DashSection
                              bodyTitleLabel
                              label={t("email_notifications.label", { ns: nsMedicaidSettings })}
                              topBorder
                            >
                              <BodyEmphasis>
                                {t("email_notifications.email_me_when.label", { ns: nsMedicaidSettings })}
                              </BodyEmphasis>
                              <ItemLine />
                              <Grid container direction="row">
                                <Grid xs={12} item>
                                  <CheckboxField
                                    name={
                                      isUserType(UserType.Consumer)
                                        ? "emailNotifications.newProviders.selected"
                                        : "emailNotifications.newJobs.selected"
                                    }
                                    id={
                                      isUserType(UserType.Consumer)
                                        ? "emailNotifications.newProviders.selected"
                                        : "emailNotifications.newJobs.selected"
                                    }
                                    label={
                                      <BodyEmphasis>
                                        {t(USER_COPY[userType]!.newJobsNotifKey, { ns: nsMedicaidSettings })}
                                      </BodyEmphasis>
                                    }
                                    style={{ paddingTop: 0 }}
                                  />
                                </Grid>
                                <Grid item container>
                                  <Grid item xs={12}>
                                    <Box maxWidth={200} ml={6}>
                                      <FormSelectField
                                        label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                        name={
                                          isUserType(UserType.Consumer)
                                            ? "emailNotifications.newProviders.frequency"
                                            : "emailNotifications.newJobs.frequency"
                                        }
                                        selectOptions={FREQUENCY_OPTIONS.filter(
                                          (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                        )}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {hasPermission(Permission.SETTINGS_ACCESS_JOB_FILTERS) && (
                                <>
                                  <ItemLine />
                                  <Grid container>
                                    <Grid xs={12} item>
                                      <CheckboxField
                                        name="emailNotifications.jobFilterMatch.selected"
                                        id="emailNotifications.jobFilterMatch.selected"
                                        label={
                                          <BodyEmphasis>
                                            {t(FILTER_SETTING_COPY.jobFilters, { ns: nsMedicaidSettings })}
                                          </BodyEmphasis>
                                        }
                                        style={{ paddingTop: 0 }}
                                      />
                                    </Grid>
                                    <Grid item container>
                                      <Grid item xs={12}>
                                        <Box maxWidth={200} ml={6}>
                                          <FormSelectField
                                            label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                            name="emailNotifications.jobFilterMatch.frequency"
                                            selectOptions={FREQUENCY_OPTIONS.filter(
                                              (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                            )}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {hasPermission(Permission.SETTINGS_ACCESS_PROVIDER_FILTERS) && (
                                <>
                                  <ItemLine />
                                  <Grid container>
                                    <Grid xs={12} item>
                                      <CheckboxField
                                        name="emailNotifications.providerFilterMatch.selected"
                                        id="emailNotifications.providerFilterMatch.selected"
                                        label={
                                          <BodyEmphasis>
                                            {t(FILTER_SETTING_COPY.providerFilters, { ns: nsMedicaidSettings })}
                                          </BodyEmphasis>
                                        }
                                        style={{ paddingTop: 0 }}
                                      />
                                    </Grid>
                                    <Grid item container>
                                      <Grid item xs={12}>
                                        <Box maxWidth={200} ml={6}>
                                          <FormSelectField
                                            label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                            name="emailNotifications.providerFilterMatch.frequency"
                                            selectOptions={FREQUENCY_OPTIONS.filter(
                                              (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                            )}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {!isUserType(UserType.Consumer) && !isUserType(UserType.MedicaidReferralCoordinator) && (
                                <>
                                  <ItemLine />
                                  <Grid container direction="row">
                                    <Grid xs={12} item>
                                      <CheckboxField
                                        name="emailNotifications.updateProfileReminder"
                                        id="emailNotifications.updateProfileReminder"
                                        label={
                                          <BodyEmphasis>
                                            {t(USER_COPY[userType]!.sendUpdateReminderKey1, { ns: nsMedicaidSettings })}
                                          </BodyEmphasis>
                                        }
                                        style={{ paddingTop: 0 }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Box ml={6}>
                                        <Body>
                                          {t(USER_COPY[userType]!.sendUpdateReminderKey2, { ns: nsMedicaidSettings })}
                                        </Body>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </>
                              )}

                              <ItemLine />
                              <Grid container direction="row">
                                <Grid item xs={12}>
                                  <CheckboxField
                                    name="emailNotifications.newMessage.selected"
                                    id="emailNotifications.newMessage.selected"
                                    label={
                                      <BodyEmphasis>
                                        {t("someone_messages_me.label", { ns: nsMedicaidSettings })}
                                      </BodyEmphasis>
                                    }
                                    style={{ paddingTop: 0 }}
                                  />
                                </Grid>
                                <Grid item container>
                                  <Grid item xs={12}>
                                    <Box maxWidth={200} ml={6}>
                                      <FormSelectField
                                        label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                        name="emailNotifications.newMessage.frequency"
                                        selectOptions={FREQUENCY_OPTIONS.filter(
                                          (it) =>
                                            it.value !== NotificationFrequency.MONTHLY &&
                                            it.value !== NotificationFrequency.WEEKLY,
                                        )}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </DashSection>
                            <DashSection
                              bodyTitleLabel
                              label={t("phone_and_text_notifications.label", { ns: nsMedicaidSettings })}
                              topBorder
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <BodyEmphasis>
                                    {t("phone_and_text_notifications.would_you_like_receive.label", {
                                      ns: nsMedicaidSettings,
                                    })}
                                  </BodyEmphasis>
                                </Grid>
                                <Grid item container>
                                  <Grid xs={12} item>
                                    <CheckboxField
                                      name="smsNotifications.consented"
                                      id="smsNotifications.consented"
                                      label={
                                        <Body>
                                          {t("phone_and_text_notifications.yes_i_consent.label", {
                                            ns: nsMedicaidSettings,
                                          })}
                                        </Body>
                                      }
                                      style={{ paddingTop: 0 }}
                                      onClick={() => {
                                        setFieldValue("smsNotifications.newJobs.selected", false, false);
                                        setFieldValue("smsNotifications.newProviders.selected", false, false);
                                        setFieldValue("smsNotifications.jobFilterMatch.selected", false, false);
                                        setFieldValue("smsNotifications.providerFilterMatch.selected", false, false);
                                        setFieldValue("smsNotifications.jobPostExpiration", false, false);
                                        setFieldValue("smsNotifications.updateProfileReminder", false, false);
                                        setFieldValue("smsNotifications.newMessage.selected", false, false);
                                      }}
                                    />
                                  </Grid>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Box maxWidth={520} ml={6}>
                                        <Box style={{ display: disclaimerHidden ? "none" : "inherit", marginTop: 20 }}>
                                          <Body>
                                            {t("phone_and_text_notifications.messaging_frequency_varies.description", {
                                              ns: nsMedicaidSettings,
                                            })}
                                          </Body>
                                        </Box>

                                        <MuiLink
                                          component="button"
                                          onClick={(event: { preventDefault: () => void }) => {
                                            event.preventDefault(); // Prevent form submission
                                            setDisclaimerHidden(!disclaimerHidden);
                                          }}
                                          aria-label={
                                            disclaimerHidden
                                              ? t("settings.show_disclaimer_text_messages", { ns: nsCommonAria })
                                              : t("settings.hide_disclaimer_text_messages", { ns: nsCommonAria })
                                          }
                                        >
                                          <Body style={{ marginTop: 20 }}>
                                            {disclaimerHidden
                                              ? t("button.read_more", { ns: nsCommonFormsBtns })
                                              : t("button.read_less", { ns: nsCommonFormsBtns })}
                                          </Body>
                                        </MuiLink>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: 25, paddingBottom: 40 }}>
                                  <FastMaskTextField
                                    name="contactInfo.phone"
                                    maskType="phone"
                                    label={t("field.phone_number.label", { ns: nsCommonFormsBtns })}
                                    required
                                    disabled={!values.smsNotifications.consented}
                                    controlledRerender={values.smsNotifications.consented}
                                  />
                                </Grid>
                              </Grid>
                              <StyledGrid item xs={12} disabled={!values.smsNotifications.consented}>
                                <BodyEmphasis>
                                  {t("phone_and_text_notifications.text_me_when.label", { ns: nsMedicaidSettings })}
                                </BodyEmphasis>
                                <ItemLine />
                              </StyledGrid>
                              <StyledGrid container direction="row" disabled={!values.smsNotifications.consented}>
                                <Grid xs={12} item>
                                  <CheckboxField
                                    name={
                                      isUserType(UserType.Consumer)
                                        ? "smsNotifications.newProviders.selected"
                                        : "smsNotifications.newJobs.selected"
                                    }
                                    id={
                                      isUserType(UserType.Consumer)
                                        ? "smsNotifications.newProviders.selected"
                                        : "smsNotifications.newJobs.selected"
                                    }
                                    label={
                                      <BodyEmphasis>
                                        {t(USER_COPY[userType]!.newJobsNotifKey, { ns: nsMedicaidSettings })}
                                      </BodyEmphasis>
                                    }
                                    style={{ paddingTop: 0 }}
                                    disabled={!values.smsNotifications.consented}
                                    controlledRerender={values.smsNotifications.consented}
                                  />
                                </Grid>
                                <Grid item container>
                                  <Grid item xs={12}>
                                    <Box maxWidth={200} ml={6}>
                                      <FormSelectField
                                        label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                        name={
                                          isUserType(UserType.Consumer)
                                            ? "smsNotifications.newProviders.frequency"
                                            : "smsNotifications.newJobs.frequency"
                                        }
                                        selectOptions={FREQUENCY_OPTIONS.filter(
                                          (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                        )}
                                        disabled={!values.smsNotifications.consented}
                                        controlledRerender={values.smsNotifications.consented}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </StyledGrid>
                              {hasPermission(Permission.SETTINGS_ACCESS_JOB_FILTERS) && (
                                <>
                                  <ItemLine />
                                  <StyledGrid container disabled={!values.smsNotifications.consented}>
                                    <Grid xs={12} item>
                                      <CheckboxField
                                        name="smsNotifications.jobFilterMatch.selected"
                                        id="smsNotifications.jobFilterMatch.selected"
                                        label={
                                          <BodyEmphasis>
                                            {t(FILTER_SETTING_COPY.jobFilters, { ns: nsMedicaidSettings })}
                                          </BodyEmphasis>
                                        }
                                        style={{ paddingTop: 0 }}
                                        disabled={!values.smsNotifications.consented}
                                        controlledRerender={values.smsNotifications.consented}
                                      />
                                    </Grid>
                                    <Grid item container>
                                      <Grid item xs={12}>
                                        <Box maxWidth={200} ml={6}>
                                          <FormSelectField
                                            label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                            name="smsNotifications.jobFilterMatch.frequency"
                                            selectOptions={FREQUENCY_OPTIONS.filter(
                                              (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                            )}
                                            disabled={!values.smsNotifications.consented}
                                            controlledRerender={values.smsNotifications.consented}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </StyledGrid>
                                </>
                              )}
                              {hasPermission(Permission.SETTINGS_ACCESS_PROVIDER_FILTERS) && (
                                <>
                                  <ItemLine />
                                  <StyledGrid container disabled={!values.smsNotifications.consented}>
                                    <Grid xs={12} item>
                                      <CheckboxField
                                        name="smsNotifications.providerFilterMatch.selected"
                                        id="smsNotifications.providerFilterMatch.selected"
                                        label={
                                          <BodyEmphasis>
                                            {t(FILTER_SETTING_COPY.providerFilters, { ns: nsMedicaidSettings })}
                                          </BodyEmphasis>
                                        }
                                        style={{ paddingTop: 0 }}
                                        disabled={!values.smsNotifications.consented}
                                        controlledRerender={values.smsNotifications.consented}
                                      />
                                    </Grid>
                                    <Grid item container>
                                      <Grid item xs={12}>
                                        <Box maxWidth={200} ml={6}>
                                          <FormSelectField
                                            label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                            name="smsNotifications.providerFilterMatch.frequency"
                                            selectOptions={FREQUENCY_OPTIONS.filter(
                                              (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                            )}
                                            disabled={!values.smsNotifications.consented}
                                            controlledRerender={values.smsNotifications.consented}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </StyledGrid>
                                </>
                              )}
                              {!isUserType(UserType.Consumer) && !isUserType(UserType.MedicaidReferralCoordinator) && (
                                <>
                                  <ItemLine />
                                  <StyledGrid container direction="row" disabled={!values.smsNotifications.consented}>
                                    <Grid xs={12} item>
                                      <CheckboxField
                                        name="smsNotifications.updateProfileReminder"
                                        id="smsNotifications.updateProfileReminder"
                                        label={
                                          <BodyEmphasis>
                                            {t(USER_COPY[userType]!.sendUpdateReminderKey1, { ns: nsMedicaidSettings })}
                                          </BodyEmphasis>
                                        }
                                        style={{ paddingTop: 0 }}
                                        disabled={!values.smsNotifications.consented}
                                        controlledRerender={values.smsNotifications.consented}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Box ml={6}>
                                        <Body>
                                          {t(USER_COPY[userType]!.sendUpdateReminderKey2, { ns: nsMedicaidSettings })}
                                        </Body>
                                      </Box>
                                    </Grid>
                                  </StyledGrid>
                                </>
                              )}

                              <ItemLine />
                              <StyledGrid container direction="row" disabled={!values.smsNotifications.consented}>
                                <Grid item xs={12}>
                                  <CheckboxField
                                    name="smsNotifications.newMessage.selected"
                                    id="smsNotifications.newMessage.selected"
                                    label={
                                      <BodyEmphasis>
                                        {t("someone_messages_me.label", { ns: nsMedicaidSettings })}
                                      </BodyEmphasis>
                                    }
                                    disabled={!values.smsNotifications.consented}
                                    controlledRerender={values.smsNotifications.consented}
                                    style={{ paddingTop: 0 }}
                                  />
                                </Grid>
                                <Grid item container>
                                  <Grid item xs={12}>
                                    <Box maxWidth={200} ml={6}>
                                      <FormSelectField
                                        label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                        name="smsNotifications.newMessage.frequency"
                                        selectOptions={FREQUENCY_OPTIONS.filter(
                                          (it) =>
                                            it.value !== NotificationFrequency.MONTHLY &&
                                            it.value !== NotificationFrequency.WEEKLY,
                                        )}
                                        disabled={!values.smsNotifications.consented}
                                        controlledRerender={values.smsNotifications.consented}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </StyledGrid>
                            </DashSection>
                            <DashSection
                              bodyTitleLabel
                              label={t("address.label", { ns: nsMedicaidSettings })}
                              topBorder
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <BodyEmphasis style={{ marginBottom: 30 }}>
                                    {t(USER_COPY[userType]!.addressKey1, { ns: nsMedicaidSettings })}
                                  </BodyEmphasis>
                                  <Body style={{ marginRight: 15 }}>
                                    {t(USER_COPY[userType]!.addressKey2, { ns: nsMedicaidSettings })}
                                    <b style={{ fontFamily: "Europa-Bold" }}>
                                      {t(USER_COPY[userType]!.addressKey3, { ns: nsMedicaidSettings })}
                                    </b>
                                  </Body>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: 25, paddingBottom: 40 }}>
                                  <FormAddressField
                                    name="contactInfo.address"
                                    label={t("field.address.label_street_address", { ns: nsCommonFormsBtns })}
                                    helperText="ex. 1234 Carina Ave NE"
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </DashSection>
                            <Box textAlign="right">
                              <Button
                                variant="contained"
                                type="submit"
                                disabled={isSavingSettings || editEmailSection || editLangPrefSection}
                              >
                                {isSavingSettings
                                  ? t("button.saving_settings", { ns: nsCommonFormsBtns })
                                  : t("button.save", { ns: nsCommonFormsBtns })}
                              </Button>
                            </Box>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </>
            )}
          </Constraint>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export interface MedicaidSettingsFormFields {
  languagePreference: LocizeLanguage;
  contactInfo: {
    email: string;
    phone?: string;
    address: string;
  };
  emailNotifications: {
    newJobs: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    newProviders: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    jobFilterMatch: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    providerFilterMatch: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    jobPostExpiration?: boolean;
    updateProfileReminder?: boolean;
    newMessage: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
  };
  smsNotifications: {
    consented?: boolean;
    newJobs: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    newProviders: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    jobFilterMatch: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    providerFilterMatch: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
    jobPostExpiration?: boolean;
    updateProfileReminder?: boolean;
    newMessage: {
      selected?: boolean;
      frequency?: NotificationFrequency;
    };
  };
}

export default MedicaidSettings;
