import { Box, Grid, useMediaQuery } from "@mui/material";
import Illustration from "src/assets/images/emans-homecare-match-2.svg";
import DesktopStep1_WA from "src/assets/tutorial/client/desktop-5-1-WA.jpg";
import DesktopStep1_OR from "src/assets/tutorial/client/desktop-5-1-OR.jpg";
import DesktopStep2_WA from "src/assets/tutorial/client/desktop-5-2-WA.jpg";
import DesktopStep2_OR from "src/assets/tutorial/client/desktop-5-2-OR.jpg";
import DesktopStep3_WA from "src/assets/tutorial/client/desktop-5-3-WA.jpg";
import DesktopStep3_OR from "src/assets/tutorial/client/desktop-5-3-OR.jpg";
import DesktopStep4_WA from "src/assets/tutorial/client/desktop-5-4-WA.jpg";
import DesktopStep4_OR from "src/assets/tutorial/client/desktop-5-4-OR.jpg";
import MobileStep1a from "src/assets/tutorial/client/mobile-5-1a.jpg";
import MobileStep1b_WA from "src/assets/tutorial/client/mobile-5-1b-WA.jpg";
import MobileStep1b_OR from "src/assets/tutorial/client/mobile-5-1b-OR.jpg";
import MobileStep2 from "src/assets/tutorial/client/mobile-5-2.jpg";
import MobileStep3 from "src/assets/tutorial/client/mobile-5-3.jpg";
import MobileStep4a from "src/assets/tutorial/client/mobile-5-4a.jpg";
import MobileStep4b from "src/assets/tutorial/client/mobile-5-4b.jpg";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const screenshotFilesMap = new Map<string, string>([
  ["DesktopStep1_WA", DesktopStep1_WA],
  ["DesktopStep1_OR", DesktopStep1_OR],
  ["DesktopStep2_WA", DesktopStep2_WA],
  ["DesktopStep2_OR", DesktopStep2_OR],
  ["DesktopStep3_WA", DesktopStep3_WA],
  ["DesktopStep3_OR", DesktopStep3_OR],
  ["DesktopStep4_WA", DesktopStep4_WA],
  ["DesktopStep4_OR", DesktopStep4_OR],
  ["MobileStep1b_WA", MobileStep1b_WA],
  ["MobileStep1b_OR", MobileStep1b_OR],
]);

const DeleteJobPost = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  const { isFunderState } = useUserSession();

  const getScreenshot = (filePrefix: string) => {
    let file = `${filePrefix}_WA`;
    if (isFunderState(FunderState.Oregon)) file = `${filePrefix}_OR`;
    return screenshotFilesMap.get(file);
  };

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Delete Job Post</SectionTitle>
            {isFunderState(FunderState.Washington) && (
              <Body paragraph>
                Once your new provider receives an Okay to Work from Consumer Direct Care Network Washington (CDWA), log
                back in to <b>delete your job post</b>.
              </Body>
            )}
            {isFunderState(FunderState.Oregon) && (
              <Body paragraph>
                Once your new provider receives authorization from your case manager, log back in to{" "}
                <b>delete your job post</b>.
              </Body>
            )}
            <Body paragraph>
              Deleting your job post helps other providers know that the position has been filled and that you are not
              looking for care anymore.
            </Body>
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Login and go to &#34;My Jobs&#34;</BodyTitle>
        {onDesktop && (
          <>
            <img src={getScreenshot("DesktopStep1")} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <img src={MobileStep1a} width="100%" alt="" />
            <Box p={1} />
            <img src={getScreenshot("MobileStep1b")} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Select the job post that was filled</BodyTitle>
        <img src={onDesktop ? getScreenshot("DesktopStep2") : MobileStep2} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Click on &#34;Delete&#34;</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>
            Check that you are deleting the correct job by double checking the job number. Once you&apos;ve checked,
            click &#34;Delete&#34;.
          </Body>
        </Constraint>
        <img src={onDesktop ? getScreenshot("DesktopStep3") : MobileStep3} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>4. Tell us about your experience using Carina</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>
            Your feedback helps us improve our services. Let us know about your experience connecting on Carina.
          </Body>
          {onDesktop && <Body paragraph>Once you are done, click &#34;Delete&#34;.</Body>}
        </Constraint>
        <img src={onDesktop ? getScreenshot("DesktopStep4") : MobileStep4a} width="100%" alt="" />
        {onMobile && (
          <>
            <Box p={2} />
            <Body paragraph>Once you are done, click “Delete”.</Body>
            <img src={MobileStep4b} width="100%" alt="" />
          </>
        )}
      </Segment>
    </>
  );
};

export default DeleteJobPost;
