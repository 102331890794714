import { Redirect, Route, RouteProps } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { ROUTES } from "src/MainRouter";
import { Body } from "src/reusable_view_elements/Typography";

export const UnauthenticatedRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const { userSession, loading } = useUserSession();

  if (loading) {
    return <Body>Loading user session...</Body>;
  }

  if (userSession) {
    return <Redirect to={ROUTES.loginSuccess} />;
  }

  return <Route {...props} />;
};
