import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { nsChildcareProgramPage, nsCommonForms } from "src/i18n/Namespaces";
import { useChildCareContext } from "src/pages/childcare/ChildCareContext";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import Form from "src/reusable_view_elements/form_fields/Form";
import { Button } from "src/reusable_view_elements/Button";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { FormAddressField } from "src/reusable_view_elements/form_fields";
import { object, string } from "yup";
import { zipRegExp } from "src/utilities/GeneralUtilities";

interface FormFields {
  zip: string;
}

const ChildCareAddressForm = () => {
  const { t, ready } = useTranslation([nsCommonForms, nsChildcareProgramPage]);
  const history = useHistory();
  const { setSearchInput } = useChildCareContext();

  const validationSchema = object().shape({
    zip: string()
      .required(t("zip error", { ns: nsCommonForms }))
      .matches(zipRegExp, {
        message: t("zip error", { ns: nsCommonForms }),
      }),
  });

  const initialValues: FormFields = {
    zip: "",
  };

  const handleSubmit = async (values: FormFields) => {
    setSearchInput(values.zip);
    history.push(CHILD_CARE_ROUTES.searchList);
  };

  return !ready ? (
    <LoadingCircle />
  ) : (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form localizationReady={ready}>
        <FormAddressField zipCodeOnly fullWidth name="zip" label={t("zip", { ns: nsCommonForms })} />

        <Button variant="contained" type="submit" disabled={false} style={{ marginTop: "24px" }}>
          {t("hero search button", { ns: nsChildcareProgramPage })}
        </Button>
      </Form>
    </Formik>
  );
};

export default ChildCareAddressForm;
