import { Grid, ListItem, ListItemTextProps, SvgIconProps } from "@mui/material";

interface IconListItemProps extends ListItemTextProps {
  icon: React.ComponentType<SvgIconProps>;
  iconProps?: SvgIconProps;
}

// Used in Medicaid JobDetails and ProviderProfileDetails, usually as children of IconList
const IconListItem = ({ icon: Component, primary, secondary, iconProps }: IconListItemProps) => {
  const style = {
    listItem: {
      padding: "12px 0px",
      alignItems: "flex-start",
      lineHeight: 0, // Removes extra padding when containing Typography components
    },
    iconComponent: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
  };
  return (
    <ListItem style={style.listItem}>
      <Component style={style.iconComponent} {...iconProps} />
      <Grid container spacing={1}>
        <Grid item>{primary}</Grid>
        <Grid item>{secondary}</Grid>
      </Grid>
    </ListItem>
  );
};

export default IconListItem;
