import { Box, Grid, useMediaQuery } from "@mui/material";
import Illustration from "src/assets/images/emans-homecare-step-3.svg";
import DesktopStep1a_WA from "src/assets/tutorial/client/desktop-1-1a-WA.jpg";
import DesktopStep1a_OR from "src/assets/tutorial/client/desktop-1-1a-OR.jpg";
import DesktopStep1b_WA from "src/assets/tutorial/client/desktop-1-1b-WA.jpg";
import DesktopStep1b_OR from "src/assets/tutorial/client/desktop-1-1b-OR.jpg";
import DesktopStep1c_WA from "src/assets/tutorial/client/desktop-1-1c-WA.jpg";
import DesktopStep1c_OR from "src/assets/tutorial/client/desktop-1-1c-OR.jpg";
import DesktopStep2_WA from "src/assets/tutorial/client/desktop-1-2-WA.jpg";
import DesktopStep2_OR from "src/assets/tutorial/client/desktop-1-2-OR.jpg";
import DesktopStep3a from "src/assets/tutorial/client/desktop-1-3a.jpg";
import DesktopStep3b_WA from "src/assets/tutorial/client/desktop-1-3b-WA.jpg";
import DesktopStep3b_OR from "src/assets/tutorial/client/desktop-1-3b-OR.jpg";
import MobileStep1a from "src/assets/tutorial/client/mobile-1-1a.jpg";
import MobileStep1b_WA from "src/assets/tutorial/client/mobile-1-1b-WA.jpg";
import MobileStep1b_OR from "src/assets/tutorial/client/mobile-1-1b-OR.jpg";
import MobileStep1c from "src/assets/tutorial/client/mobile-1-1c.jpg";
import MobileStep2 from "src/assets/tutorial/client/mobile-1-2.jpg";
import MobileStep3a from "src/assets/tutorial/client/mobile-1-3.jpg";
import MobileStep3b from "src/assets/tutorial/client/mobile-1-4.jpg";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const screenshotFilesMap = new Map<string, string>([
  ["DesktopStep1a_WA", DesktopStep1a_WA],
  ["DesktopStep1a_OR", DesktopStep1a_OR],
  ["DesktopStep1b_WA", DesktopStep1b_WA],
  ["DesktopStep1b_OR", DesktopStep1b_OR],
  ["DesktopStep1c_WA", DesktopStep1c_WA],
  ["DesktopStep1c_OR", DesktopStep1c_OR],
  ["DesktopStep2_WA", DesktopStep2_WA],
  ["DesktopStep2_OR", DesktopStep2_OR],
  ["DesktopStep3b_WA", DesktopStep3b_WA],
  ["DesktopStep3b_OR", DesktopStep3b_OR],
  ["MobileStep1b_WA", MobileStep1b_WA],
  ["MobileStep1b_OR", MobileStep1b_OR],
]);

const JobPost = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  const { isFunderState } = useUserSession();

  const getScreenshot = (filePrefix: string) => {
    let file = `${filePrefix}_WA`;
    if (isFunderState(FunderState.Oregon)) file = `${filePrefix}_OR`;
    return screenshotFilesMap.get(file);
  };

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Create a Job Post</SectionTitle>
            <Body>
              If you are ready to have providers message you on Carina, <b>Post a job</b> to let providers know that you
              are searching for care.
            </Body>
          </Grid>
          {onMobile && (
            <>
              <Box p={2} />
              <Grid item xs={12} sm={5}>
                <img src={Illustration} width="100%" alt="" />
              </Grid>
            </>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Go to &#34;Post a Job&#34;</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Once you complete the steps to set up your account, you will be able to create a job post.
              </Body>
              <Body paragraph>Click &#34;My Jobs&#34; at the top of the page, then click &#34;Post a Job&#34;.</Body>
            </Constraint>
            <img src={getScreenshot("DesktopStep1a")} width="100%" alt="" />
            <Box p={1} />
            <img src={getScreenshot("DesktopStep1b")} width="100%" alt="" />
            <Constraint columns={6} pt={6} pb={6}>
              <Body>
                You can also directly click &#34;Post a Job&#34; in the &#34;My Messages&#34; section of your home page.
              </Body>
            </Constraint>
            <img src={getScreenshot("DesktopStep1c")} width="100%" alt="" />
          </>
        )}

        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Once you complete the steps to set up your account, you will be able to create a job post.
              </Body>
              <Body paragraph>
                To start, click &#34;Menu&#34; at the top of the page, then click &#34;My Jobs&#34;.
              </Body>
            </Constraint>
            <img src={MobileStep1a} width="100%" alt="" />
            <Box p={1} />
            <img src={getScreenshot("MobileStep1b")} width="100%" alt="" />
            <Constraint columns={6} pt={6}>
              <Body paragraph>In the &#34;My job posts&#34; page, click &#34;Post a Job&#34;.</Body>
            </Constraint>
            <img src={MobileStep1c} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Fill out the form</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>
            After clicking &#34;Post a Job&#34;, please fill out the form that appears so providers can learn more about
            your care needs.
          </Body>
          <Body paragraph>
            Your personal contact information or address will not be displayed. Providers will message you in the app
            and you can respond to them via the app until you decide to exchange personal contact information.
          </Body>
        </Constraint>
        <img src={onDesktop ? getScreenshot("DesktopStep2") : MobileStep2} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Post your job</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>Once you have finished filling out the form, click &#34;Post Job.&#34;</Body>
        </Constraint>
        <img src={onDesktop ? DesktopStep3a : MobileStep3a} width="100%" alt="" />
        <Constraint columns={6} pt={6}>
          <Body paragraph>
            Hooray! After posting a job, you&#39;ll receive confirmation that it is now visible to providers in your
            area.
          </Body>
        </Constraint>
        <img src={onDesktop ? getScreenshot("DesktopStep3b") : MobileStep3b} width="100%" alt="" />
      </Segment>
    </>
  );
};

export default JobPost;
