import { Box, BoxProps, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { Link as RouterLink } from "react-router-dom";

interface BlogPostProps extends BoxProps {
  img: string;
  title: string;
  link: string;
}

function BlogPost({ img, title, link, ...props }: BlogPostProps) {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsCommonFormsBtns]);

  return (
    <Box bgcolor={CivColors.white} width="290px" {...props}>
      <img src={img} alt="" width="100%" />
      <Grid container style={{ padding: "24px" }} direction="column" alignItems="center">
        <Box style={desktopSize ? { height: "100px" } : {}}>
          <Body paragraph>{title}</Body>
        </Box>
        <LinkStyledAsButton variant="contained" component={RouterLink} to={link}>
          {ready ? t("button.read_more") : "Read More"}
        </LinkStyledAsButton>
      </Grid>
    </Box>
  );
}

export default BlogPost;
