import { Avatar as MAvatar, AvatarProps as MAvatarProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CivColors from "src/themes/civilization/CivColors";
import { removeNonDigits } from "src/utilities/DashboardUtilities";

// TODO: https://github.com/CarinaWeb/CarinaCore/issues/1015: All utility functions need tests
function convertToNumber(input: string | number): number {
  if (typeof input === "number") {
    return input;
  }
  const justDigits = removeNonDigits(input);
  return parseInt(justDigits, 10);
}

const StyledAvatar = styled(MAvatar)(() => ({
  backgroundColor: "inherit",
  color: CivColors.coreNavy,
  fontFamily: "Europa-Bold",
  fontVariantLigatures: "no-contextual",
}));

const Avatar = ({ style, ...rest }: MAvatarProps) => {
  // Calculate font size based on width of the component
  const defaultWidth = 60;
  const height = convertToNumber(style?.height || defaultWidth);
  const width = convertToNumber(style?.width || defaultWidth);
  const fontSize = `${width / 2.5}px`; // TODO: This should be converted to rem

  // Calculate border thickness based on width of the component
  let borderSize = 1;
  if (width > 40) borderSize = 2;
  if (width > 80) borderSize = 4;
  const border = !rest.src ? `${borderSize}px solid ${CivColors.coreNavy}` : "none";

  return <StyledAvatar style={{ ...style, height, width, fontSize, border }} {...rest} />;
};

export default Avatar;
