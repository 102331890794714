import { Box, Grid, GridProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  CommunicationMethod,
  CurrentSettingsDTO,
  LocizeLanguage,
  NotificationFrequency,
  NotificationType,
  OnboardingStepType,
  Permission,
  RoleType,
} from "src/generated/api_types";
import { changeLanguage, LangKeyByUserLangPref, LangPrefByKey, LanguageLabel } from "src/i18n/Languages";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidBEContent,
  nsMedicaidOnboardingMain,
  nsMedicaidSettings,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedSettingsResponse } from "src/i18n/Utilities";
import { Lock1Icon } from "src/assets/icons/StreamlineIcons";
import { MedicaidSettingsFormFields } from "src/pages/medicaidAccount/Settings";
import { getUserSettings } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import { onboardingSnackbarShouldBeShown } from "src/pages/onboarding/MedicaidOnboardingUtilities";
import { onboardingClient } from "src/pages/onboarding/Queries";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import Footer from "src/reusable_view_elements/Footer";
import { FormAddressField, FormSelectField, SelectOption } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import LongForm from "src/reusable_view_elements/form_fields/LongForm";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import UserNavbar from "src/reusable_view_elements/navbars/UserNavbar";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { removeNonDigits } from "src/utilities/DashboardUtilities";
import { object, string } from "yup";

const StyledGrid = styled((props: GridProps & { disabled?: boolean }) => <Grid {...props} />)((props) => ({
  color: props.disabled ? CivColors.mediumGray : "inherit",
  "& path": {
    color: props.disabled ? CivColors.mediumGray : "inherit",
  },
}));

const ItemLine = () => (
  <Box style={{ padding: 0, margin: "14px 0px", borderBottom: `1px solid ${CivColors.mediumGray}` }} />
);

interface CopyType {
  [k: string]: string;
}

type UserCopyType = {
  [c in keyof typeof RoleType]?: CopyType;
};

export const FILTER_SETTING_COPY = {
  jobFilters: "job_matches_filters.label",
  providerFilters: "provider_matches_filters.label",
};

export const USER_COPY: UserCopyType = {
  CONSUMER: {
    newJobsNotifKey: "new_providers_near.label",
    filterMatchNotifKey: "provider_matches_filters.label",
    sendUpdateReminderKey1: "my_jobs_expire.label",
    sendUpdateReminderKey2: "my_jobs_expire.description",
    addressKey1: "address.enter_your_address.label_consumer",
    addressKey2: "address.enter_your_address.description_normal_font_consumer",
    addressKey3: "address.enter_your_address.description_focused_font_consumer",
  },
  PROVIDER: {
    newJobsNotifKey: "new_jobs_near.label",
    filterMatchNotifKey: "job_matches_filters.label",
    sendUpdateReminderKey1: "time_update_profile.label",
    sendUpdateReminderKey2: "time_update_profile.description",
    addressKey1: "address.enter_your_address.label_provider",
    addressKey2: "address.enter_your_address.description_normal_font_provider",
    addressKey3: "address.enter_your_address.description_focused_font_provider",
  },
};

export const FREQUENCY_OPTIONS: SelectOption<string>[] = [
  {
    value: NotificationFrequency.IMMEDIATE,
    label: "Immediately",
    locizeKey: "immediately",
    namespace: nsMedicaidTagsEnums,
  },
  { value: NotificationFrequency.DAILY, label: "Daily", locizeKey: "daily", namespace: nsMedicaidTagsEnums },
  { value: NotificationFrequency.WEEKLY, label: "Weekly", locizeKey: "weekly", namespace: nsMedicaidTagsEnums },
  { value: NotificationFrequency.MONTHLY, label: "Monthly", locizeKey: "monthly", namespace: nsMedicaidTagsEnums },
];

const MedicaidSettings = () => {
  const { showSnackbar } = useNotification();
  const history = useHistory();

  const [disclaimerHidden, setDisclaimerHidden] = useState<boolean>(true);
  const { addCompletedStep, onboardingState } = useOnboardingContext();
  const { userSession, isUserType, hasPermission } = useUserSession();
  const [userSettings, setUserSettings] = useState<CurrentSettingsDTO>();
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [prepopulatedAddress, setPrepopulatedAddress] = useState<string | undefined>();
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const userType = isUserType(UserType.Consumer) ? "CONSUMER" : "PROVIDER";
  const { t, ready } = useTranslation([
    nsMedicaidSettings,
    nsMedicaidOnboardingMain,
    nsMedicaidTagsEnums,
    nsCommonFormsBtns,
    nsCommonToasts,
    nsMedicaidBEContent,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingSettings(true);
        const res = await getUserSettings();
        setUserSettings(res.data);
      } catch (e) {
        showSnackbar(
          ready
            ? t("error.something_wrong_loading_settings", { ns: nsCommonToasts })
            : "Something went wrong loading your settings. Please try again later.",
          "error",
        );
      } finally {
        setIsLoadingSettings(false);
      }
    })();
    (async () => {
      try {
        setIsLoadingAddress(true);
        const res = await onboardingClient.getPrepopulatedAddress();
        setPrepopulatedAddress(res.data);
      } catch (e: any) {
        console.log(e.toString());
      } finally {
        setIsLoadingAddress(false);
      }
    })();
  }, []);

  /** -----------------------------------------------
   * ------- MAIN SETTINGS FORM COMPONENTS ---------
   * ------------------------------------------------
   */

  const handleSettingsSubmit = async (
    values: MedicaidSettingsFormFields,
    helpers: FormikHelpers<MedicaidSettingsFormFields>,
  ) => {
    const updateSettingsObj: CurrentSettingsDTO = {
      contactInfo: {
        email: values.contactInfo.email,
        phone: values.contactInfo.phone,
        smsConsent: values.smsNotifications.consented,
        address: values.contactInfo.address,
      },
      languagePreference: values.languagePreference,
      emailSettings: {
        newJobs: values.emailNotifications.newJobs.selected
          ? {
              type: NotificationType.NEW_JOBS_NEARBY,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.emailNotifications.newJobs.frequency!!,
              enable: values.emailNotifications.newJobs.selected,
            }
          : undefined,
        newProviders: values.emailNotifications.newProviders.selected
          ? {
              type: NotificationType.NEW_PROVIDERS_NEARBY,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.emailNotifications.newProviders.frequency!!,
              enable: values.emailNotifications.newProviders.selected,
            }
          : undefined,
        jobFilter: values.emailNotifications.jobFilterMatch.selected
          ? {
              type: NotificationType.NEW_JOBS_FILTER,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.emailNotifications.jobFilterMatch.frequency!!,
              enable: values.emailNotifications.jobFilterMatch.selected,
            }
          : undefined,
        providerFilter: values.emailNotifications.providerFilterMatch.selected
          ? {
              type: NotificationType.NEW_PROVIDERS_FILTER,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.emailNotifications.providerFilterMatch.frequency!!,
              enable: values.emailNotifications.providerFilterMatch.selected,
            }
          : undefined,
        jobPostExpiration: values.emailNotifications.jobPostExpiration
          ? {
              type: NotificationType.JOB_POST_EXPIRATION_FIRST_WARNING,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: NotificationFrequency.DELAYED,
              enable: values.emailNotifications.jobPostExpiration,
            }
          : undefined,
        updateProfileReminder: values.emailNotifications.updateProfileReminder
          ? {
              type: NotificationType.AVAILABILITY_STATUS_FIRST_WARNING,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: NotificationFrequency.DELAYED,
              enable: values.emailNotifications.updateProfileReminder,
            }
          : undefined,
        newMessage: {
          type: NotificationType.NEW_MESSAGE,
          communicationMethod: CommunicationMethod.EMAIL,
          frequency: values.emailNotifications.newMessage.frequency!!,
          enable: true,
        },
      },
      smsSettings: {
        newJobs: values.smsNotifications.newJobs.selected
          ? {
              type: NotificationType.NEW_JOBS_NEARBY,
              communicationMethod: CommunicationMethod.SMS,
              frequency: values.smsNotifications.newJobs.frequency!!,
              enable: values.smsNotifications.newJobs.selected,
            }
          : undefined,
        newProviders: values.smsNotifications.newProviders.selected
          ? {
              type: NotificationType.NEW_PROVIDERS_NEARBY,
              communicationMethod: CommunicationMethod.SMS,
              frequency: values.smsNotifications.newProviders.frequency!!,
              enable: values.smsNotifications.newProviders.selected,
            }
          : undefined,
        jobFilter: values.smsNotifications.jobFilterMatch.selected
          ? {
              type: NotificationType.NEW_JOBS_FILTER,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.smsNotifications.jobFilterMatch.frequency!!,
              enable: values.smsNotifications.jobFilterMatch.selected,
            }
          : undefined,
        providerFilter: values.smsNotifications.providerFilterMatch.selected
          ? {
              type: NotificationType.NEW_PROVIDERS_FILTER,
              communicationMethod: CommunicationMethod.EMAIL,
              frequency: values.smsNotifications.providerFilterMatch.frequency!!,
              enable: values.smsNotifications.providerFilterMatch.selected,
            }
          : undefined,
        jobPostExpiration: values.smsNotifications.jobPostExpiration
          ? {
              type: NotificationType.JOB_POST_EXPIRATION_FIRST_WARNING,
              communicationMethod: CommunicationMethod.SMS,
              frequency: NotificationFrequency.DELAYED,
              enable: values.smsNotifications.jobPostExpiration,
            }
          : undefined,
        updateProfileReminder: values.smsNotifications.updateProfileReminder
          ? {
              type: NotificationType.AVAILABILITY_STATUS_FIRST_WARNING,
              communicationMethod: CommunicationMethod.SMS,
              frequency: NotificationFrequency.DELAYED,
              enable: values.smsNotifications.updateProfileReminder,
            }
          : undefined,
        newMessage: {
          type: NotificationType.NEW_MESSAGE,
          communicationMethod: CommunicationMethod.SMS,
          frequency: values.smsNotifications.newMessage.frequency!!,
          enable: true,
        },
      },
    };

    try {
      const res = await onboardingClient.completeOnboardingStep({
        json: JSON.stringify(values),
        stepType: OnboardingStepType.SETTINGS,
        settings: updateSettingsObj,
      });
      helpers.setSubmitting(false);
      addCompletedStep(OnboardingStepType.SETTINGS, values);
      changeLanguage(LangKeyByUserLangPref[values.languagePreference], () => {});
      history.push(ONBOARDING_ROUTES.root);

      if (res.data !== "Settings changes have been saved") {
        showSnackbar(getLocalizedSettingsResponse(res.data, t));
      } else if (onboardingSnackbarShouldBeShown(onboardingState)) {
        showSnackbar(t("success.notif_settings_saved", { ns: nsCommonToasts }), "success");
      }
    } catch (e) {
      helpers.setSubmitting(false);
      showSnackbar(t("error.something_wrong_saving_settings", { ns: nsCommonToasts }), "error");
    }
  };

  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const settingsValidationSchema = object({
    languagePreference: string().required(t("field.generic.error.select_one_dropdown", { ns: nsCommonFormsBtns })),
    contactInfo: object().shape({
      phone: string()
        .test(
          "phone-validation",
          t("field.phone_number.error", { ns: nsCommonFormsBtns }),
          function testFunction(value) {
            // @ts-ignore
            const { from } = this; // based on code in https://github.com/jquense/yup/issues/735#issuecomment-873828710
            const isSmsConsented = from[1].value.smsNotifications.consented;
            const isFieldEmpty = !removeNonDigits(value as string | undefined);
            return !(isSmsConsented && isFieldEmpty);
          },
        )
        .matches(phoneRegExp, { message: t("field.phone_number.error", { ns: nsCommonFormsBtns }) })
        .nullable(),
      address: string().required(t("field.address.error", { ns: nsCommonFormsBtns })),
    }),
  });

  const settingsInitialValues: MedicaidSettingsFormFields = userSettings
    ? {
        languagePreference: userSettings.languagePreference,
        contactInfo: {
          email: userSettings.contactInfo.email,
          phone: userSettings.contactInfo.phone,
          address: prepopulatedAddress || userSettings.contactInfo.address,
        },
        emailNotifications: {
          newJobs: {
            selected: !!userSettings.emailSettings?.newJobs,
            frequency: userSettings.emailSettings?.newJobs?.frequency
              ? userSettings.emailSettings.newJobs.frequency
              : NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: !!userSettings.emailSettings?.newProviders,
            frequency: userSettings.emailSettings?.newProviders?.frequency
              ? userSettings.emailSettings.newProviders.frequency
              : NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: !!userSettings.emailSettings?.jobFilter,
            frequency: userSettings.emailSettings?.jobFilter?.frequency
              ? userSettings.emailSettings.jobFilter.frequency
              : NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: !!userSettings.emailSettings?.providerFilter,
            frequency: userSettings.emailSettings?.providerFilter?.frequency
              ? userSettings.emailSettings.providerFilter.frequency
              : NotificationFrequency.DAILY,
          },
          jobPostExpiration:
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidReferralCoordinator) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.ProxyProvider) ||
            !!userSettings.emailSettings?.jobPostExpiration,
          updateProfileReminder: !!userSettings.emailSettings?.updateProfileReminder,
          newMessage: {
            frequency: userSettings.emailSettings?.newMessage?.frequency
              ? userSettings.emailSettings?.newMessage?.frequency
              : NotificationFrequency.IMMEDIATE,
          },
        },
        smsNotifications: {
          consented: userSettings.contactInfo.smsConsent,
          newJobs: {
            selected: !!userSettings.smsSettings?.newJobs,
            frequency: userSettings.smsSettings?.newJobs?.frequency
              ? userSettings.smsSettings.newJobs.frequency
              : NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: !!userSettings.smsSettings?.newProviders,
            frequency: userSettings.smsSettings?.newProviders?.frequency
              ? userSettings.smsSettings.newProviders.frequency
              : NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: !!userSettings.smsSettings?.jobFilter,
            frequency: userSettings.smsSettings?.jobFilter?.frequency
              ? userSettings.smsSettings.jobFilter.frequency
              : NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: !!userSettings.smsSettings?.providerFilter,
            frequency: userSettings.smsSettings?.providerFilter?.frequency
              ? userSettings.smsSettings.providerFilter.frequency
              : NotificationFrequency.DAILY,
          },
          jobPostExpiration:
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidReferralCoordinator) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.ProxyProvider) ||
            !!userSettings.smsSettings?.jobPostExpiration,
          updateProfileReminder: !!userSettings.smsSettings?.updateProfileReminder,
          newMessage: {
            frequency: userSettings.smsSettings?.newMessage?.frequency
              ? userSettings.smsSettings.newMessage.frequency
              : NotificationFrequency.IMMEDIATE,
          },
        },
      }
    : {
        languagePreference: userSession?.languagePreference || LocizeLanguage.English,
        contactInfo: {
          email: "email@email.com",
          phone: "",
          address: "",
        },
        emailNotifications: {
          newJobs: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobPostExpiration:
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidReferralCoordinator) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.ProxyProvider),
          updateProfileReminder: false,
          newMessage: {
            frequency: NotificationFrequency.IMMEDIATE,
          },
        },
        smsNotifications: {
          consented: false,
          newJobs: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          newProviders: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          providerFilterMatch: {
            selected: false,
            frequency: NotificationFrequency.DAILY,
          },
          jobPostExpiration:
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidReferralCoordinator) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.ProxyProvider),
          updateProfileReminder: false,
          newMessage: {
            frequency: NotificationFrequency.IMMEDIATE,
          },
        },
      };

  if (!ready) {
    return (
      <div>
        <Helmet>
          <title>Carina | Medicaid Onboard</title>
        </Helmet>
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.medicaid", { ns: nsMedicaidOnboardingMain })}</title>
      </Helmet>

      <UserNavbar showLanguage />
      <Section bgcolor={CivColors.lightGray} minimal>
        <Constraint columns={12}>
          <DashReturnLink to={ONBOARDING_ROUTES.root}>
            {t("button.back_to_previous_page", { ns: nsCommonFormsBtns })}
          </DashReturnLink>
        </Constraint>
      </Section>

      <main id="main-content">
        <Section bgcolor={CivColors.lightGray}>
          <Constraint columns={8}>
            <Constraint columns={6}>
              <SectionTitle align="center">{t("account_settings.label", { ns: nsMedicaidSettings })}</SectionTitle>
              <Body align="center" paragraph>
                {t("account_settings.description", { ns: nsMedicaidSettings })}
              </Body>
            </Constraint>

            {isLoadingSettings || isLoadingAddress ? (
              <Section bgcolor={CivColors.lightGray}>
                <LoadingCircle />
              </Section>
            ) : (
              <>
                <Formik
                  initialValues={settingsInitialValues}
                  validationSchema={settingsValidationSchema}
                  onSubmit={handleSettingsSubmit}
                >
                  {({ values, setFieldValue, isSubmitting }) => (
                    <LongForm localizationReady={ready}>
                      <DashSection bodyTitleLabel label={t("language_preferences.label", { ns: nsMedicaidSettings })}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Body>{t("language_preferences.description", { ns: nsMedicaidSettings })}</Body>
                          </Grid>
                          <Grid item xs={12}>
                            <FormSelectField
                              name="languagePreference" //TODO: Should we replaced FormSelectField with TextField ???
                              label={t("field.language.label", { ns: nsCommonFormsBtns })}
                              selectOptions={Object.entries(LanguageLabel).map((item) => {
                                return {
                                  value: LangPrefByKey[item[0]],
                                  label: item[1],
                                  lang: item[0],
                                };
                              })}
                            />
                          </Grid>
                        </Grid>
                      </DashSection>
                      <DashSection
                        bodyTitleLabel
                        label={t("email_notifications.label", { ns: nsMedicaidSettings })}
                        topBorder
                      >
                        <Grid item mb={6}>
                          <BodyEmphasis>
                            {t("email_notifications.login_contact_email.label", { ns: nsMedicaidSettings })}
                          </BodyEmphasis>
                          <Body paragraph data-testid="current-email" mt={2} ml={2}>
                            {settingsInitialValues.contactInfo.email}
                          </Body>
                        </Grid>
                        <BodyEmphasis>
                          {t("email_notifications.email_me_when.label", { ns: nsMedicaidSettings })}
                        </BodyEmphasis>
                        <ItemLine />
                        <Grid container direction="row">
                          <Grid xs={12} item>
                            <CheckboxField
                              name={
                                isUserType(UserType.Consumer)
                                  ? "emailNotifications.newProviders.selected"
                                  : "emailNotifications.newJobs.selected"
                              }
                              id={
                                isUserType(UserType.Consumer)
                                  ? "emailNotifications.newProviders.selected"
                                  : "emailNotifications.newJobs.selected"
                              }
                              label={
                                <BodyEmphasis>
                                  {t(USER_COPY[userType]!.newJobsNotifKey, { ns: nsMedicaidSettings })}
                                </BodyEmphasis>
                              }
                              style={{ paddingTop: 0 }}
                            />
                          </Grid>
                          <Grid item container>
                            <Grid item xs={12}>
                              <Box maxWidth={200} ml={6}>
                                <FormSelectField
                                  label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                  name={
                                    isUserType(UserType.Consumer)
                                      ? "emailNotifications.newProviders.frequency"
                                      : "emailNotifications.newJobs.frequency"
                                  }
                                  selectOptions={FREQUENCY_OPTIONS.filter(
                                    (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                  )}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        {hasPermission(Permission.SETTINGS_ACCESS_JOB_FILTERS) && (
                          <>
                            <ItemLine />
                            <Grid container>
                              <Grid xs={12} item>
                                <CheckboxField
                                  name="emailNotifications.jobFilterMatch.selected"
                                  id="emailNotifications.jobFilterMatch.selected"
                                  label={
                                    <BodyEmphasis>
                                      {t(FILTER_SETTING_COPY.jobFilters, { ns: nsMedicaidSettings })}
                                    </BodyEmphasis>
                                  }
                                  style={{ paddingTop: 0 }}
                                />
                              </Grid>
                              <Grid item container>
                                <Grid item xs={12}>
                                  <Box maxWidth={200} ml={6}>
                                    <FormSelectField
                                      label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                      name="emailNotifications.jobFilterMatch.frequency"
                                      selectOptions={FREQUENCY_OPTIONS.filter(
                                        (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                      )}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {hasPermission(Permission.SETTINGS_ACCESS_PROVIDER_FILTERS) && (
                          <>
                            <ItemLine />
                            <Grid container>
                              <Grid xs={12} item>
                                <CheckboxField
                                  name="emailNotifications.providerFilterMatch.selected"
                                  id="emailNotifications.providerFilterMatch.selected"
                                  label={
                                    <BodyEmphasis>
                                      {t(FILTER_SETTING_COPY.providerFilters, { ns: nsMedicaidSettings })}
                                    </BodyEmphasis>
                                  }
                                  style={{ paddingTop: 0 }}
                                />
                              </Grid>
                              <Grid item container>
                                <Grid item xs={12}>
                                  <Box maxWidth={200} ml={6}>
                                    <FormSelectField
                                      label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                      name="emailNotifications.providerFilterMatch.frequency"
                                      selectOptions={FREQUENCY_OPTIONS.filter(
                                        (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                      )}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {!isUserType(UserType.Consumer) &&
                          !isUserType(UserType.MedicaidReferralCoordinator) &&
                          !isUserType(UserType.MedicaidCaseManager) && (
                            <>
                              <ItemLine />
                              <Grid container direction="row">
                                <Grid xs={12} item>
                                  <CheckboxField
                                    name="emailNotifications.updateProfileReminder"
                                    id="emailNotifications.updateProfileReminder"
                                    label={
                                      <BodyEmphasis>
                                        {t(USER_COPY[userType]!.sendUpdateReminderKey1, { ns: nsMedicaidSettings })}
                                      </BodyEmphasis>
                                    }
                                    style={{ paddingTop: 0 }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Box ml={6}>
                                    <Body>
                                      {t(USER_COPY[userType]!.sendUpdateReminderKey2, { ns: nsMedicaidSettings })}
                                    </Body>
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          )}

                        <ItemLine />
                        <Grid container>
                          <Grid item xs={12}>
                            <Box maxWidth={400} ml={1}>
                              <Grid container spacing={3}>
                                <Grid xs={1} item>
                                  <Lock1Icon />
                                </Grid>
                                <Grid item xs={11}>
                                  <BodyEmphasis>
                                    {t("someone_messages_me.label", { ns: nsMedicaidSettings })}
                                  </BodyEmphasis>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item container>
                          <Grid item xs={12}>
                            <Box maxWidth={200} ml={6}>
                              <FormSelectField
                                label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                name="emailNotifications.newMessage.frequency"
                                selectOptions={FREQUENCY_OPTIONS.filter(
                                  (it) =>
                                    it.value !== NotificationFrequency.WEEKLY &&
                                    it.value !== NotificationFrequency.MONTHLY,
                                )}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box maxWidth={520} ml={6} mt={1}>
                              <Body>{t("someone_messages_me.description", { ns: nsMedicaidSettings })}</Body>
                            </Box>
                          </Grid>
                        </Grid>
                      </DashSection>
                      <DashSection
                        bodyTitleLabel
                        label={t("phone_and_text_notifications.label", { ns: nsMedicaidSettings })}
                        topBorder
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <BodyEmphasis>
                              {t("phone_and_text_notifications.would_you_like_receive.label", {
                                ns: nsMedicaidSettings,
                              })}
                            </BodyEmphasis>
                          </Grid>
                          <Grid item container>
                            <Grid xs={12} item>
                              <CheckboxField
                                name="smsNotifications.consented"
                                id="smsNotifications.consented"
                                label={
                                  <Body>
                                    {t("phone_and_text_notifications.yes_i_consent.label", { ns: nsMedicaidSettings })}
                                  </Body>
                                }
                                style={{ paddingTop: 0 }}
                                onClick={() => {
                                  setFieldValue("contactInfo.phone", "", false);
                                  setFieldValue("smsNotifications.newJobs.selected", false, false);
                                  setFieldValue("smsNotifications.newProviders.selected", false, false);
                                  setFieldValue("smsNotifications.jobFilterMatch.selected", false, false);
                                  setFieldValue("smsNotifications.providerFilterMatch.selected", false, false);
                                  setFieldValue("smsNotifications.jobPostExpiration", false, false);
                                  setFieldValue("smsNotifications.updateProfileReminder", false, false);
                                  setFieldValue("smsNotifications.newMessage.selected", false, false);
                                }}
                              />
                            </Grid>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box maxWidth={520} ml={6}>
                                  <Box style={{ display: disclaimerHidden ? "none" : "inherit", marginTop: 20 }}>
                                    <Body>
                                      {t("phone_and_text_notifications.messaging_frequency_varies.description", {
                                        ns: nsMedicaidSettings,
                                      })}
                                    </Body>
                                  </Box>
                                  <Box
                                    onClick={() => setDisclaimerHidden(!disclaimerHidden)}
                                    style={{ cursor: "pointer", textDecoration: "underline", marginTop: 20 }}
                                  >
                                    <Body>
                                      {disclaimerHidden
                                        ? t("button.read_more", { ns: nsCommonFormsBtns })
                                        : t("button.read_less", { ns: nsCommonFormsBtns })}
                                    </Body>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: 25, paddingBottom: 40 }}>
                            <FastMaskTextField
                              name="contactInfo.phone"
                              maskType="phone"
                              label={t("field.phone_number.label", { ns: nsCommonFormsBtns })}
                              required
                              disabled={!values.smsNotifications.consented}
                              controlledRerender={values.smsNotifications.consented}
                            />
                          </Grid>
                        </Grid>
                        <StyledGrid item xs={12} disabled={!values.smsNotifications.consented}>
                          <BodyEmphasis>
                            {t("phone_and_text_notifications.text_me_when.label", { ns: nsMedicaidSettings })}
                          </BodyEmphasis>
                          <ItemLine />
                        </StyledGrid>
                        <StyledGrid container direction="row" disabled={!values.smsNotifications.consented}>
                          <Grid xs={12} item>
                            <CheckboxField
                              name={
                                isUserType(UserType.Consumer)
                                  ? "smsNotifications.newProviders.selected"
                                  : "smsNotifications.newJobs.selected"
                              }
                              id={
                                isUserType(UserType.Consumer)
                                  ? "smsNotifications.newProviders.selected"
                                  : "smsNotifications.newJobs.selected"
                              }
                              label={
                                <BodyEmphasis>
                                  {t(USER_COPY[userType]!.newJobsNotifKey, { ns: nsMedicaidSettings })}
                                </BodyEmphasis>
                              }
                              style={{ paddingTop: 0 }}
                              disabled={!values.smsNotifications.consented}
                              controlledRerender={values.smsNotifications.consented}
                            />
                          </Grid>
                          <Grid item container>
                            <Grid item xs={12}>
                              <Box maxWidth={200} ml={6}>
                                <FormSelectField
                                  label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                  name={
                                    isUserType(UserType.Consumer)
                                      ? "smsNotifications.newProviders.frequency"
                                      : "smsNotifications.newJobs.frequency"
                                  }
                                  selectOptions={FREQUENCY_OPTIONS.filter(
                                    (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                  )}
                                  disabled={!values.smsNotifications.consented}
                                  controlledRerender={values.smsNotifications.consented}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </StyledGrid>

                        {hasPermission(Permission.SETTINGS_ACCESS_JOB_FILTERS) && (
                          <>
                            <ItemLine />
                            <StyledGrid container disabled={!values.smsNotifications.consented}>
                              <Grid xs={12} item>
                                <CheckboxField
                                  name="smsNotifications.jobFilterMatch.selected"
                                  id="smsNotifications.jobFilterMatch.selected"
                                  label={
                                    <BodyEmphasis>
                                      {t(FILTER_SETTING_COPY.jobFilters, { ns: nsMedicaidSettings })}
                                    </BodyEmphasis>
                                  }
                                  style={{ paddingTop: 0 }}
                                  disabled={!values.smsNotifications.consented}
                                  controlledRerender={values.smsNotifications.consented}
                                />
                              </Grid>
                              <Grid item container>
                                <Grid item xs={12}>
                                  <Box maxWidth={200} ml={6}>
                                    <FormSelectField
                                      label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                      name="smsNotifications.jobFilterMatch.frequency"
                                      selectOptions={FREQUENCY_OPTIONS.filter(
                                        (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                      )}
                                      disabled={!values.smsNotifications.consented}
                                      controlledRerender={values.smsNotifications.consented}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </StyledGrid>
                          </>
                        )}
                        {hasPermission(Permission.SETTINGS_ACCESS_PROVIDER_FILTERS) && (
                          <>
                            <ItemLine />
                            <StyledGrid container disabled={!values.smsNotifications.consented}>
                              <Grid xs={12} item>
                                <CheckboxField
                                  name="smsNotifications.providerFilterMatch.selected"
                                  id="smsNotifications.providerFilterMatch.selected"
                                  label={
                                    <BodyEmphasis>
                                      {t(FILTER_SETTING_COPY.providerFilters, { ns: nsMedicaidSettings })}
                                    </BodyEmphasis>
                                  }
                                  style={{ paddingTop: 0 }}
                                  disabled={!values.smsNotifications.consented}
                                  controlledRerender={values.smsNotifications.consented}
                                />
                              </Grid>
                              <Grid item container>
                                <Grid item xs={12}>
                                  <Box maxWidth={200} ml={6}>
                                    <FormSelectField
                                      label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                      name="smsNotifications.providerFilterMatch.frequency"
                                      selectOptions={FREQUENCY_OPTIONS.filter(
                                        (it) => it.value !== NotificationFrequency.IMMEDIATE,
                                      )}
                                      disabled={!values.smsNotifications.consented}
                                      controlledRerender={values.smsNotifications.consented}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </StyledGrid>
                          </>
                        )}

                        {!isUserType(UserType.Consumer) &&
                          !isUserType(UserType.MedicaidReferralCoordinator) &&
                          !isUserType(UserType.MedicaidCaseManager) && (
                            <>
                              <ItemLine />
                              <StyledGrid container direction="row" disabled={!values.smsNotifications.consented}>
                                <Grid xs={12} item>
                                  <CheckboxField
                                    name="smsNotifications.updateProfileReminder"
                                    id="smsNotifications.updateProfileReminder"
                                    label={
                                      <BodyEmphasis>
                                        {t(USER_COPY[userType]!.sendUpdateReminderKey1, { ns: nsMedicaidSettings })}
                                      </BodyEmphasis>
                                    }
                                    style={{ paddingTop: 0 }}
                                    disabled={!values.smsNotifications.consented}
                                    controlledRerender={values.smsNotifications.consented}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Box ml={6}>
                                    <Body>
                                      {t(USER_COPY[userType]!.sendUpdateReminderKey2, { ns: nsMedicaidSettings })}
                                    </Body>
                                  </Box>
                                </Grid>
                              </StyledGrid>
                            </>
                          )}

                        <ItemLine />
                        <StyledGrid container direction="row" disabled={!values.smsNotifications.consented}>
                          <Grid item xs={12}>
                            <Box maxWidth={400} ml={1}>
                              <Grid container spacing={3}>
                                <Grid xs={1} item>
                                  <Lock1Icon />
                                </Grid>
                                <Grid item xs={11}>
                                  <BodyEmphasis>
                                    {t("someone_messages_me.label", { ns: nsMedicaidSettings })}
                                  </BodyEmphasis>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </StyledGrid>
                        <StyledGrid item container disabled={!values.smsNotifications.consented}>
                          <Grid item xs={12}>
                            <Box maxWidth={200} ml={6}>
                              <FormSelectField
                                label={t("notify_me.label", { ns: nsMedicaidSettings })}
                                name="smsNotifications.newMessage.frequency"
                                selectOptions={FREQUENCY_OPTIONS.filter(
                                  (it) => it.value !== "WEEKLY" && it.value !== "MONTHLY",
                                )}
                                disabled={!values.smsNotifications.consented}
                                controlledRerender={values.smsNotifications.consented}
                              />
                            </Box>
                          </Grid>
                        </StyledGrid>
                        <StyledGrid container disabled={!values.smsNotifications.consented}>
                          <Grid item xs={12}>
                            <Box maxWidth={520} ml={6} mt={1}>
                              <Body>{t("if_you_opt_into_text.description", { ns: nsMedicaidSettings })}</Body>
                            </Box>
                          </Grid>
                        </StyledGrid>
                      </DashSection>
                      <DashSection bodyTitleLabel label={t("address.label", { ns: nsMedicaidSettings })} topBorder>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <BodyEmphasis style={{ marginBottom: 30 }}>
                              {t(USER_COPY[userType]!.addressKey1, { ns: nsMedicaidSettings })}
                            </BodyEmphasis>
                            <Body style={{ marginRight: 15 }}>
                              {t(USER_COPY[userType]!.addressKey2, { ns: nsMedicaidSettings })}
                              <b style={{ fontFamily: "Europa-Bold" }}>
                                {t(USER_COPY[userType]!.addressKey3, { ns: nsMedicaidSettings })}
                              </b>
                            </Body>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: 25, paddingBottom: 40 }}>
                            <FormAddressField
                              name="contactInfo.address"
                              label={t("field.address.label_street_address", { ns: nsCommonFormsBtns })}
                              helperText="ex. 1234 Carina Ave NE"
                              required
                            />
                          </Grid>
                        </Grid>
                      </DashSection>

                      <Box textAlign="right">
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {isSubmitting
                            ? `${t("button.saving", { ns: nsCommonFormsBtns })}`
                            : `${t("button.save", { ns: nsCommonFormsBtns })}`}
                        </Button>
                      </Box>
                    </LongForm>
                  )}
                </Formik>
              </>
            )}
          </Constraint>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default MedicaidSettings;
