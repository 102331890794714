import {
  ConsumerApplication_DTO_Response_Details,
  ConsumerApplication_DTO_Response_Listing,
} from "src/generated/api_types";

export function getCareRecipientOrContactFirstName(
  data: ConsumerApplication_DTO_Response_Details | ConsumerApplication_DTO_Response_Listing,
): string {
  if (data.careRecipient.firstName) {
    return data.careRecipient.firstName;
  }
  return `${data.contact.firstName} (representative)`;
}

export function getCareRecipientOrContactLastName(
  data: ConsumerApplication_DTO_Response_Details | ConsumerApplication_DTO_Response_Listing,
): string {
  if (data.careRecipient.firstName) {
    return data.careRecipient.lastName;
  }
  return data.contact.lastName;
}

export function getContactName(
  data: ConsumerApplication_DTO_Response_Details | ConsumerApplication_DTO_Response_Listing,
): string {
  if (data.contact.lastName) {
    return `${data.contact.firstName} ${data.contact.lastName}`;
  }
  return data.contact.firstName || "";
}
