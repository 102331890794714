import { Box, Grid, useMediaQuery } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import {
  MedicaidProviderProfile_DTO_Request_ProfileDetails,
  OnboardingStepType,
  ProviderProfileTagEnum,
} from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidOnboardingMain,
  nsMedicaidProviderProfile,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getUserFriendlyFileSize } from "src/pages/medicaidAccount/providerAccount/EditProviderProfile";
import { updateProfilePhoto } from "src/pages/medicaidAccount/providerAccount/Queries";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import { onboardingSnackbarShouldBeShown } from "src/pages/onboarding/MedicaidOnboardingUtilities";
import { onboardingClient } from "src/pages/onboarding/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Avatar from "src/reusable_view_elements/Avatar";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import Footer from "src/reusable_view_elements/Footer";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import { FormCheckboxGroupField } from "src/reusable_view_elements/form_fields/FormCheckboxGroupField";
import { FormMultiSelectChipsField } from "src/reusable_view_elements/form_fields/FormMultiSelectChipsField";
import { FormRadioGroupField } from "src/reusable_view_elements/form_fields/FormRadioGroupField";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import LongForm from "src/reusable_view_elements/form_fields/LongForm";
import { MultiAutocompleteAndChipsField } from "src/reusable_view_elements/form_fields/MultiAutocompleteAndChipsField";
import InteractiveWrapper from "src/reusable_view_elements/InteractiveWrapper";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import UserNavbar from "src/reusable_view_elements/navbars/UserNavbar";
import { Button } from "src/reusable_view_elements/Button";
import GenderPopover from "src/reusable_view_elements/popovers/GenderPopover";
import LgbtPopover from "src/reusable_view_elements/popovers/LgbtPopover";
import NurseDelegationPopover from "src/reusable_view_elements/popovers/NurseDelegationPopover";
import PronounsPopover from "src/reusable_view_elements/popovers/PronounsPopover";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";
import { cleanBase64String, imageExists, isRequiredField } from "src/utilities/GeneralUtilities";
import { number, object, string } from "yup";

export const LegacyLanguage = [
  { value: ProviderProfileTagEnum.ENGLISH, label: "English", locizeKey: "english", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.RUSSIAN, label: "Russian", locizeKey: "russian", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.SPANISH, label: "Spanish", locizeKey: "spanish", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.VIETNAMESE,
    label: "Vietnamese",
    locizeKey: "vietnamese",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.KOREAN, label: "Korean", locizeKey: "korean", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.CANTONESE,
    label: "Cantonese",
    locizeKey: "cantonese",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.ARABIC, label: "Arabic", locizeKey: "arabic", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.SOMALI, label: "Somali", locizeKey: "somali", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.UKRAINIAN,
    label: "Ukrainian",
    locizeKey: "ukrainian",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.KHMER, label: "Khmer", locizeKey: "khmer", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.TAGALOG, label: "Tagalog", locizeKey: "tagalog", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.MANDARIN,
    label: "Mandarin",
    locizeKey: "mandarin",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.LAOTIAN, label: "Laotian", locizeKey: "laotian", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.SAMOAN, label: "Samoan", locizeKey: "samoan", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.AMERICAN_SIGN,
    label: "American Sign Language",
    locizeKey: "american_sign",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.AMHARIC, label: "Amharic", locizeKey: "amharic", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.PUNJABI, label: "Punjabi", locizeKey: "punjabi", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.NEPALI, label: "Nepali", locizeKey: "nepali", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.FARSI, label: "Farsi", locizeKey: "farsi", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.TIGRINYA,
    label: "Tigrinya",
    locizeKey: "tigrinya",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.OROMO, label: "Oromo", locizeKey: "oromo", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.ARMENIAN,
    label: "Armenian",
    locizeKey: "armenian",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.JAPANESE,
    label: "Japanese",
    locizeKey: "japanese",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ROMANIAN,
    label: "Romanian",
    locizeKey: "romanian",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.SWAHILI, label: "Swahili", locizeKey: "swahili", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.BOSNIAN, label: "Bosnian", locizeKey: "bosnian", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.THAI, label: "Thai", locizeKey: "thai", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.HINDI, label: "Hindi", locizeKey: "hindi", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.ALBANIAN,
    label: "Albanian",
    locizeKey: "albanian",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.FRENCH, label: "French", locizeKey: "french", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.OTHER_LANGUAGE,
    label: "Other Language",
    locizeKey: "other_language",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.BENGALI, label: "Bengali", locizeKey: "bengali", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.DARI, label: "Dari", locizeKey: "dari", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.KAREN, label: "Karen", locizeKey: "karen", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.TURKISH, label: "Turkish", locizeKey: "turkish", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.BURMESE, label: "Burmese", locizeKey: "burmese", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.URDU, label: "Urdu", locizeKey: "urdu", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.BULGARIAN,
    label: "Bulgarian",
    locizeKey: "bulgarian",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.CHUUKESE,
    label: "Chuukese",
    locizeKey: "chuukese",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.HMONG, label: "Hmong", locizeKey: "hmong", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.KURDISH, label: "Kurdish", locizeKey: "kurdish", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.MOLDAVIAN,
    label: "Moldavian",
    locizeKey: "moldavian",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.POLISH, label: "Polish", locizeKey: "polish", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.PORTUGUESE,
    label: "Portuguese",
    locizeKey: "portuguese",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.TAMIL, label: "Tamil", locizeKey: "tamil", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.CROATIAN,
    label: "Croatian",
    locizeKey: "croatian",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.GERMAN, label: "German", locizeKey: "german", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.PASHTU, label: "Pashtu", locizeKey: "pashtu", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.TONGAN, label: "Tongan", locizeKey: "tongan", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.ITALIAN, label: "Italian", locizeKey: "italian", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.CAMBODIAN,
    label: "Cambodian",
    locizeKey: "cambodian",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.CREOLE, label: "Creole", locizeKey: "creole", namespace: nsMedicaidTagsEnums },
];

export const AvailabilityOptions = [
  {
    key: ProviderProfileTagEnum.SEEKING,
    optionLabel: "Yes, I am currently available",
    locizeKey: "seeking.yes_currently_available",
    namespace: nsMedicaidTagsEnums,
  },
  {
    key: ProviderProfileTagEnum.SOON,
    optionLabel: "No, but I will be soon",
    locizeKey: "soon.no_will_be_soon",
    namespace: nsMedicaidTagsEnums,
  },
  {
    key: ProviderProfileTagEnum.NOTSEEKING,
    optionLabel: "No, I am not available",
    locizeKey: "notseeking.no_im_not_available",
    namespace: nsMedicaidTagsEnums,
  },
];

export const PronounsOptions = [
  { value: ProviderProfileTagEnum.HE_HIM, label: "He/Him", locizeKey: "he_him", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.SHE_HER,
    label: "She/Her",
    locizeKey: "she_her",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.THEY_THEM,
    label: "They/Them",
    locizeKey: "they_them",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.XE_XEM,
    label: "Xe/Xem",
    locizeKey: "xe_xem",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ZE_ZIR,
    label: "Ze/Zir",
    locizeKey: "ze_zir",
    namespace: nsMedicaidTagsEnums,
  },
];

export const ProviderGenderOptions = [
  { value: ProviderProfileTagEnum.MALE, label: "Man", locizeKey: "male_v2", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.FEMALE,
    label: "Woman",
    locizeKey: "female_v2",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NONBINARY,
    label: "Nonbinary",
    locizeKey: "nonbinary",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NO_GENDER_PREFERENCE,
    label: "Prefer not to say",
    locizeKey: "no_gender_preference.prefer_not_to_say",
    namespace: nsMedicaidTagsEnums,
  },
];

export const PreferredConsumerGenderOptions = [
  {
    value: ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT,
    label: "All genders",
    locizeKey: "no_gender_preference.all_genders",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.MALE_CLIENT,
    label: "Man",
    locizeKey: "male_client_v2",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.FEMALE_CLIENT,
    label: "Woman",
    locizeKey: "female_client_v2",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NONBINARY_CLIENT,
    label: "Nonbinary",
    locizeKey: "nonbinary_client",
    namespace: nsMedicaidTagsEnums,
  },
];

export const CareFrequencyOptions = [
  {
    value: ProviderProfileTagEnum.ONGOING,
    label: "Routine care",
    locizeKey: "ongoing",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ONETIME,
    label: "Relief or respite care",
    locizeKey: "onetime",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ONCALL,
    label: "Emergency or on-call care",
    locizeKey: "oncall",
    namespace: nsMedicaidTagsEnums,
  },
];

export const AgeRangeOptions = [
  {
    value: ProviderProfileTagEnum.CHILD,
    label: "Children (17 years old or younger)",
    locizeKey: "child",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ADULT,
    label: "Adults (18 – 64 years old)",
    locizeKey: "adult",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.OLDER_ADULT,
    label: "Older adults (65 years old or older)",
    locizeKey: "older_adult",
    namespace: nsMedicaidTagsEnums,
  },
];

export const TypeOfSupportOptions = [
  {
    value: ProviderProfileTagEnum.ADDICTIONS_MENTAL,
    label: "Addictions and/or mental health needs",
    locizeKey: "addictions_mental",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.BEHAVIORAL,
    label: "Behavioral needs",
    locizeKey: "behavioral",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.DEVELOPMENTAL_DISABILITY,
    label: "Developmental disability",
    locizeKey: "developmental_disability",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.HOME_GENERAL,
    label: "General care to remain at home",
    locizeKey: "home_general",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.PHYSICAL_DISABILITY,
    label: "Physical disability",
    locizeKey: "physical_disability",
    namespace: nsMedicaidTagsEnums,
  },
];

export const GeneralSkillsOptions = [
  {
    value: ProviderProfileTagEnum.HOUSEKEEPING,
    label: "Housekeeping",
    locizeKey: "housekeeping",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.LAUNDRY, label: "Laundry", locizeKey: "laundry", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.MEAL_PREP,
    label: "Meal preparation",
    locizeKey: "meal_prep",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.SHOPPING,
    label: "Shopping",
    locizeKey: "shopping",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.PERSONAL_CARE,
    label: "Hygiene & personal care",
    locizeKey: "personal_care",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.MEDS_MGMT,
    label: "Medication management",
    locizeKey: "meds_mgmt",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.BEHAVIOR_CHALLENGE,
    label: "Working with behavioral challenges",
    locizeKey: "behavior_challenge",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NURSE_DELEGATION,
    label: "Nurse delegated tasks",
    locizeKey: "nurse_delegation",
    namespace: nsMedicaidTagsEnums,
  },
];

export const MovementSkillsOptions = [
  {
    value: ProviderProfileTagEnum.AMBULATION,
    label: "Mobility assistance (ambulation)",
    locizeKey: "ambulation",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.POSITIONING,
    label: "Positioning",
    locizeKey: "positioning",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.TRANSFER,
    label: "Transfers",
    locizeKey: "transfer",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.TRANSFER_ASSISTED,
    label: "Assisted transfers (using Hoyer Lift, etc.)",
    locizeKey: "transfer_assisted",
    namespace: nsMedicaidTagsEnums,
  },
];

export const TransportSkillsOptions = [
  {
    value: ProviderProfileTagEnum.TRANSPORT,
    label: "Transport - using the consumer's vehicle",
    locizeKey: "transport",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.TRANSPORT_PROVIDER,
    label: "Transport - using the provider's vehicle",
    locizeKey: "transport_provider",
    namespace: nsMedicaidTagsEnums,
  },
];

export const WorkEnvOptions = [
  {
    value: ProviderProfileTagEnum.FRAG_FREE,
    label: "Fragrance-free home",
    locizeKey: "frag_free",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NON_SMOKING,
    label: "Non-smoking home",
    locizeKey: "non_smoking",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.SMOKING,
    label: "Smoking home",
    locizeKey: "smoking",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NON_SMOKING_PROVIDER,
    label: "I do not smoke",
    locizeKey: "non_smoking_provider",
    namespace: nsMedicaidTagsEnums,
  },
];

export const PetOptions = [
  { value: ProviderProfileTagEnum.DOG, label: "Dog(s)", locizeKey: "dog", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.CAT, label: "Cat(s)", locizeKey: "cat", namespace: nsMedicaidTagsEnums },
  { value: ProviderProfileTagEnum.BIRD, label: "Bird(s)", locizeKey: "bird", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.OTHER_PET,
    label: "Other(s)",
    locizeKey: "other_pet",
    namespace: nsMedicaidTagsEnums,
  },
];

export const SpecializedSkillsOptions = [
  {
    value: ProviderProfileTagEnum.CLEAN,
    label: "Clean and tidy",
    locizeKey: "clean",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.COOKDIET,
    label: "Cooks for special diets",
    locizeKey: "cookdiet",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.EMPATHETIC,
    label: "Empathetic",
    locizeKey: "empathetic",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.COMMUNICATOR,
    label: "Excellent communicator",
    locizeKey: "communicator",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.BEHAVIORXP,
    label: "Experience with consumers with behavioral challenges",
    locizeKey: "behaviorxp",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.LISTENER,
    label: "Good listener",
    locizeKey: "listener",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ADVOCATE,
    label: "Great consumer advocate",
    locizeKey: "advocate",
    namespace: nsMedicaidTagsEnums,
  },
  { value: ProviderProfileTagEnum.COOK, label: "Great cook", locizeKey: "cook", namespace: nsMedicaidTagsEnums },
  {
    value: ProviderProfileTagEnum.RELATIONSHIPS,
    label: "Great relationship builder",
    locizeKey: "relationships",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.KIND,
    label: "Kind and warm-hearted",
    locizeKey: "kind",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.TALKER,
    label: "Loves talking to consumers",
    locizeKey: "talker",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.MOTIVATOR,
    label: "Motivator",
    locizeKey: "motivator",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.ORGANIZED,
    label: "Organized",
    locizeKey: "organized",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.PUNCTUAL,
    label: "Punctual",
    locizeKey: "punctual",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.AUTISTIC,
    label: "Skilled with Autistic consumers",
    locizeKey: "autistic",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.MEMORY_CARE,
    label: "Skilled with memory care consumers",
    locizeKey: "memory_care",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: ProviderProfileTagEnum.NON_VERBAL,
    label: "Skilled with non-verbal consumers",
    locizeKey: "non_verbal",
    namespace: nsMedicaidTagsEnums,
  },
];

export const handleProviderGenderChange = (
  e: ChangeEvent<HTMLInputElement>,
  { values, setFieldValue }: Pick<FormikProps<MedicaidProfileFormFields>, "values" | "setFieldValue">,
) => {
  if (e.currentTarget.value === ProviderProfileTagEnum.NO_GENDER_PREFERENCE) {
    setFieldValue(
      e.currentTarget.name,
      values.gender.includes(ProviderProfileTagEnum.NO_GENDER_PREFERENCE)
        ? []
        : [ProviderProfileTagEnum.NO_GENDER_PREFERENCE],
      false,
    );
  }
};

export const isProviderGenderCheckboxDisabled = (
  checkboxValue: keyof typeof ProviderProfileTagEnum,
  { values }: Pick<FormikProps<MedicaidProfileFormFields>, "values">,
): boolean => {
  // Disable "no gender preference" if any other values are selected
  return (
    (checkboxValue === ProviderProfileTagEnum.NO_GENDER_PREFERENCE &&
      (values.gender.includes(ProviderProfileTagEnum.MALE) ||
        values.gender.includes(ProviderProfileTagEnum.FEMALE) ||
        values.gender.includes(ProviderProfileTagEnum.NONBINARY))) ||
    // Disable all other fields if "no gender preference" is selected
    (checkboxValue !== ProviderProfileTagEnum.NO_GENDER_PREFERENCE &&
      values.gender.includes(ProviderProfileTagEnum.NO_GENDER_PREFERENCE))
  );
};

export const handleConsumerGenderChange = (
  e: ChangeEvent<HTMLInputElement>,
  { values, setFieldValue }: Pick<FormikProps<MedicaidProfileFormFields>, "values" | "setFieldValue">,
) => {
  // If "All Genders" is being toggled...
  if (e.target.value === ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT) {
    if (e.target.checked) {
      // Select everything if we're selecting "All Genders"
      setFieldValue(e.currentTarget.name, [
        ProviderProfileTagEnum.MALE_CLIENT,
        ProviderProfileTagEnum.FEMALE_CLIENT,
        ProviderProfileTagEnum.NONBINARY_CLIENT,
        ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT,
      ]);
    } else {
      // Clear all selections if we're deselecting "All Genders"
      setFieldValue(e.currentTarget.name, []);
    }
  }

  // Auto-select "All Genders" if everything else was selected
  if (e.target.value !== ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT) {
    // If we're currently selecting (toggling on) a value...
    if (e.target.checked) {
      const ValuesNotSelected = [
        ProviderProfileTagEnum.MALE_CLIENT,
        ProviderProfileTagEnum.FEMALE_CLIENT,
        ProviderProfileTagEnum.NONBINARY_CLIENT,
      ]
        .filter((tag) => tag !== e.target.value)
        .filter((tag) => !values.consumerGender.includes(tag));
      // And if everything else is also selected...
      if (ValuesNotSelected.length === 0) {
        // Select everything
        setFieldValue(e.currentTarget.name, [
          ProviderProfileTagEnum.MALE_CLIENT,
          ProviderProfileTagEnum.FEMALE_CLIENT,
          ProviderProfileTagEnum.NONBINARY_CLIENT,
          ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT,
        ]);
      }
    }
  }
};

export const isConsumerGenderCheckboxDisabled = (
  checkboxValue: keyof typeof ProviderProfileTagEnum,
  { values }: Pick<FormikProps<MedicaidProfileFormFields>, "values">,
): boolean => {
  // If "All genders" is selected, then disable all other values
  return (
    checkboxValue !== ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT &&
    values.consumerGender.includes(ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT)
  );
};

const MedicaidProfile = () => {
  const { isInEnv } = useConfig();
  const { userSession } = useUserSession();
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const desktopSize = useMediaQuery(`(min-width:${CivTheme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([
    nsMedicaidProviderProfile,
    nsMedicaidOnboardingMain,
    nsMedicaidTagsEnums,
    nsCommonFormsBtns,
    nsCommonTooltips,
    nsCommonToasts,
  ]);

  const { addCompletedStep, onboardingState } = useOnboardingContext();
  const { initialValues, FURTHER_INTRO_MAX, SCHEDULE_MAX } = useMedicaidProfileForm();

  const [isSavingProfile, setSavingProfile] = useState(false);
  const [isUploadingPhoto, setUploadingPhoto] = useState(false);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [fileString, setFileString] = useState<string>("");
  const [fileSizeBytes, setFileSizeBytes] = useState<number | undefined>();
  const [fileSizeError, setFileSizeError] = useState<string | undefined>();

  const validationSchema = object({
    hours: number()
      .label(t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns }))
      .when("availability", {
        is: (val: ProviderProfileTagEnum) => val === ProviderProfileTagEnum.SEEKING,
        then: number()
          .label(t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns }))
          .required(t("field.available_monthly_hours.error", { ns: nsCommonFormsBtns }))
          .max(320, t("field.available_monthly_hours.error_max", { ns: nsCommonFormsBtns }))
          .min(0, t("field.available_monthly_hours.error_min", { ns: nsCommonFormsBtns })),
      })
      .nullable(),
    scheduleNotes: string()
      .label(t("field.care_schedule.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(SCHEDULE_MAX, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    gender: string()
      .label(t("gender_s.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    consumerGender: string()
      .label(t("consumer_gender_s.description", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    languages: string()
      .label(t("field.languages.label", { ns: nsCommonFormsBtns }))
      .required(t("field.languages.error", { ns: nsCommonFormsBtns })),
    typeOfCare: string()
      .label(t("general_info.willing_to_provide.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    ageRange: string()
      .label(t("general_info.willing_to_care_for.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    typeOfSupport: string()
      .label(
        t("general_info.willing_to_provide_support_for.label", {
          ns: nsMedicaidProviderProfile,
        }),
      )
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    generalSkills: string()
      .label(t("skills_and_experience.general_care.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    workEnvironment: string()
      .label(t("work_environment.scents_smoking_pref.label", { ns: nsMedicaidProviderProfile }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    specializedSkills: string()
      .label(t("field.specialized_skill.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    furtherIntroduction: string()
      .label(t("field.hobbies_and_further_info.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(FURTHER_INTRO_MAX, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
  });

  useEffect(() => {
    const profileImage = `https://s3.us-west-2.amazonaws.com/${
      isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
    }/${userSession?.id}.jpg`;
    imageExists(profileImage, (exists) => {
      if (exists) {
        setImageUrl(profileImage);
      }
    });
  }, []);

  /**
   * Photo Upload Functions
   */

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      loadImageAsBase64String(selectedImage);
    }
  }, [selectedImage]);

  const loadImageAsBase64String = (file: File) => {
    let base64String = "";
    const reader = new FileReader();
    reader.onload = function fn() {
      if (typeof reader.result === "string") {
        base64String = cleanBase64String(reader.result);
        setFileString(base64String);
      }
    };
    reader.readAsDataURL(file);
  };

  /**
   * Handlers
   */

  const handlePhotoSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const fileSizeLimitMB = 3;
    const fileSizeLimitBytes = fileSizeLimitMB * 1000 * 1000;
    const file = e.target.files ? e.target.files[0] : null;
    if (file?.size && file.size <= fileSizeLimitBytes) {
      setFileSizeBytes(file.size);
      setSelectedImage(file);
      setFileSizeError(undefined);
    } else {
      setFileSizeError(
        ready
          ? t("field.upload_profile_picture.error", { ns: nsCommonFormsBtns, size: fileSizeLimitMB })
          : `Image must be ${fileSizeLimitMB} MB or less`,
      );
    }
  };

  const handleSubmit = async (values: MedicaidProfileFormFields) => {
    const profileTagArr = [
      ...values.gender,
      ...values.consumerGender,
      ...values.typeOfCare,
      ...values.typeOfSupport,
      ...values.ageRange,
      ...values.languages,
      ...values.generalSkills,
      ...values.movementSkills,
      ...values.transportSkills,
      ...values.generalWorkEnvironment,
      ...values.workEnvironment,
      ...values.pets,
      ...values.specializedSkills,
      ...values.pronouns,
    ].map((it) => {
      return { tag: it as ProviderProfileTagEnum };
    });
    let providerProfileVals: MedicaidProviderProfile_DTO_Request_ProfileDetails = {
      availability: values.availability,
      hours: values.hours!,
      hoursFlexible: values.hoursFlexible,
      schedulingNotes: values.scheduleNotes,
      schedulingFlexible: values.scheduleFlexible,
      furtherIntroNotes: values.furtherIntroduction,
      providerProfileTags: profileTagArr,
      hasImg: false,
    };

    try {
      if (fileString) {
        setUploadingPhoto(true);
        const res = await updateProfilePhoto(fileString);
        if (res.status === 200) {
          providerProfileVals = { ...providerProfileVals, hasImg: true };
        }
      }
      setSavingProfile(true);
      await onboardingClient.completeProfileOnboardingStep({
        profileValues: providerProfileVals,
        stepType: OnboardingStepType.PROFILE,
      });
      addCompletedStep(OnboardingStepType.PROFILE, values);

      if (onboardingSnackbarShouldBeShown(onboardingState)) {
        showSnackbar(t("success.profile_created_description", { ns: nsCommonToasts }), "success");
      }

      history.push(ONBOARDING_ROUTES.root);
    } catch (e) {
      if (fileString) {
        showSnackbar(t("error.couldnt_upload_photo", { ns: nsCommonToasts }), "error");
      } else {
        showSnackbar(t("error.something_wrong_saving_profile", { ns: nsCommonToasts }), "error");
      }
      setUploadingPhoto(false);
      setSavingProfile(false);
    }
  };

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Onboard</title>
        </Helmet>

        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </>
    );
  }

  const profileLanguages = LegacyLanguage.map((option) => ({
    label: t(option.locizeKey, { ns: option.namespace }),
    value: option.value,
  }));

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.medicaid", { ns: nsMedicaidOnboardingMain })}</title>
      </Helmet>

      <UserNavbar showLanguage />

      <main id="main-content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, setFieldValue }) => {
            return (
              <LongForm localizationReady={ready}>
                <Section bgcolor={CivColors.lightGray} minimal>
                  <Constraint columns={12}>
                    <DashReturnLink to={ONBOARDING_ROUTES.root}>
                      {t("button.back_to_previous_page", { ns: nsCommonFormsBtns })}
                    </DashReturnLink>
                  </Constraint>
                </Section>
                <Section bgcolor={CivColors.lightGray} textAlign="center" minimizeBottomGutter>
                  <Constraint columns={6}>
                    <SectionTitle>{t("create_your_profile.label", { ns: nsMedicaidProviderProfile })}</SectionTitle>
                    <Body>{t("create_your_profile.description", { ns: nsMedicaidProviderProfile })}</Body>
                  </Constraint>
                </Section>
                <Section bgcolor={CivColors.lightGray}>
                  <Constraint columns={8}>
                    <Box textAlign="center">
                      <Avatar
                        src={imageUrl}
                        style={{
                          width: "185px",
                          height: "185px",
                          margin: "0px auto",
                          fontSize: "3.2rem",
                        }}
                      >
                        {userSession?.firstName.charAt(0)}
                        {userSession?.lastName.charAt(0)}
                      </Avatar>
                      {fileSizeBytes && (
                        <Body>
                          {t("file_size.label", { ns: nsMedicaidProviderProfile })}{" "}
                          {getUserFriendlyFileSize(fileSizeBytes)}
                        </Body>
                      )}
                      <label htmlFor="select-profile-picture">
                        <input
                          accept="image/jpeg"
                          type="file"
                          id="select-profile-picture"
                          onChange={handlePhotoSelection}
                          style={{ display: "none", height: "100px", width: "100px" }}
                        />
                        <Button
                          component="span"
                          variant="contained"
                          type="button"
                          sx={{ marginTop: "24px", marginBottom: "8px" }}
                          disabled={isSavingProfile || isUploadingPhoto}
                        >
                          {(isUploadingPhoto && t("button.uploading_photo", { ns: nsCommonFormsBtns })) ||
                            (isSavingProfile && t("button.saving_profile", { ns: nsCommonFormsBtns })) ||
                            t("button.upload_profile_picture", { ns: nsCommonFormsBtns })}
                        </Button>
                        {fileSizeError && <Body color="error">{fileSizeError}</Body>}
                      </label>
                    </Box>
                    <Body sx={{ marginTop: "16px" }}>
                      {t("indicated_required_field.label", { ns: nsMedicaidProviderProfile })}
                    </Body>
                    <DashSection
                      bodyTitleLabel
                      label={t("availability.label", { ns: nsMedicaidProviderProfile })}
                      topBorder={false}
                    >
                      <FormRadioGroupField<string>
                        name="availability"
                        label={`${t("availability.are_you_available_provide_care.label", {
                          ns: nsMedicaidProviderProfile,
                        })} *`}
                        labelAlwaysOnTop
                        options={AvailabilityOptions}
                      />
                    </DashSection>
                    <DashSection
                      bodyTitleLabel
                      label={t("schedule.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      {values.availability === ProviderProfileTagEnum.SEEKING && (
                        <>
                          <Grid item xs={12} md={9} id="hours-grid">
                            <FormTextField
                              type="number"
                              name="hours"
                              label={`${t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns })} *`}
                              inputProps={{ min: 0 }}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={3} direction="column" container style={{ justifyContent: "center" }}>
                            <Grid item>
                              <CheckboxField
                                name="hoursFlexible"
                                id="hoursFlexible"
                                label={
                                  !desktopSize
                                    ? t("field.available_monthly_hours.flexible.label_mobile", {
                                        ns: nsCommonFormsBtns,
                                      })
                                    : t("field.available_monthly_hours.flexible.label_desktop", {
                                        ns: nsCommonFormsBtns,
                                      })
                                }
                              />
                            </Grid>
                          </Grid>

                          {desktopSize && (
                            <Grid item xs={12}>
                              <Box
                                mb={20}
                                style={{ padding: 0, margin: 0, borderBottom: `1px solid ${CivColors.mediumGray}` }}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      <Grid item xs={12} md={9} id="scheduleNotes-grid">
                        <FormTextField
                          label={t("field.care_schedule.label", { ns: nsCommonFormsBtns })}
                          name="scheduleNotes"
                          placeholder={t("field.care_schedule.placeholder", { ns: nsCommonFormsBtns })}
                          multiline
                          minRows={5}
                          helperText={""}
                          required={isRequiredField("scheduleNotes", validationSchema)}
                          maximumlength={SCHEDULE_MAX}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} direction="column" style={{ justifyContent: "center" }} container>
                        <Grid item>
                          <CheckboxField
                            name="scheduleFlexible"
                            id="scheduleFlexible"
                            label={
                              !desktopSize
                                ? t("field.available_monthly_hours.flexible.label_mobile", {
                                    ns: nsCommonFormsBtns,
                                  })
                                : t("field.available_monthly_hours.flexible.label_desktop", {
                                    ns: nsCommonFormsBtns,
                                  })
                            }
                          />
                        </Grid>
                      </Grid>
                    </DashSection>

                    <DashSection
                      bodyTitleLabel
                      label={t("gender_s.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      <Grid item xs={12} id="gender-grid">
                        <InteractiveWrapper
                          fieldName="gender"
                          sx={{ marginBottom: "24px" }}
                          languagePeek={t("gender_s.label", { ns: nsMedicaidProviderProfile })}
                        >
                          <Trans
                            t={t}
                            i18nKey="gender_s.description.check_all_apply"
                            ns={nsMedicaidProviderProfile}
                            components={{ popover: <GenderPopover /> }}
                            parent={Body}
                          />
                        </InteractiveWrapper>
                        <FormCheckboxGroupField<keyof typeof ProviderProfileTagEnum>
                          name="gender"
                          label={t("gender_s.label", { ns: nsMedicaidProviderProfile })}
                          options={ProviderGenderOptions}
                          checkboxesProps={{
                            onChange: (e) => handleProviderGenderChange(e, { values, setFieldValue }),
                            disabled: (checkboxValue) => isProviderGenderCheckboxDisabled(checkboxValue, { values }),
                          }}
                          required={isRequiredField("gender", validationSchema)}
                        />
                      </Grid>
                    </DashSection>

                    <DashSection
                      bodyTitleLabel
                      label={t("pronouns.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      <Grid item xs={12}>
                        <InteractiveWrapper
                          fieldName="pronouns"
                          sx={{ marginBottom: "24px" }}
                          languagePeek={t("field.pronouns.label", { ns: nsMedicaidProviderProfile })}
                        >
                          <Trans
                            t={t}
                            i18nKey="pronouns.description.enter_your_pronouns"
                            ns={nsMedicaidProviderProfile}
                            components={{ popover: <PronounsPopover /> }}
                            parent={Body}
                          />
                        </InteractiveWrapper>
                        <Body paragraph>
                          {t("pronouns.description.you_can_add", { ns: nsMedicaidProviderProfile })}
                        </Body>
                        <Body paragraph>
                          {t("pronouns.description.pronouns_are_optional", { ns: nsMedicaidProviderProfile })}
                        </Body>
                      </Grid>
                      <Grid item xs={12}>
                        <MultiAutocompleteAndChipsField<ProviderProfileTagEnum>
                          name="pronouns"
                          label={t("field.pronouns.label", { ns: nsMedicaidProviderProfile })}
                          maxSelections={2}
                          noOptionsText={t("field.pronouns.no_options_text", { ns: nsMedicaidProviderProfile })}
                          reachedMaxText={t("field.pronouns.remove_existing_pronoun_text", {
                            ns: nsMedicaidProviderProfile,
                          })}
                          selectOptions={PronounsOptions.map((tag) => ({
                            label: t(tag.locizeKey, { ns: tag.namespace }),
                            value: tag.value,
                          }))}
                        />
                      </Grid>
                    </DashSection>

                    <DashSection
                      bodyTitleLabel
                      label={t("consumer_gender_s.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      <Grid item xs={12} id="consumerGender-grid">
                        <FormCheckboxGroupField<keyof typeof ProviderProfileTagEnum>
                          name="consumerGender"
                          label={t("consumer_gender_s.description", { ns: nsMedicaidProviderProfile })}
                          options={PreferredConsumerGenderOptions}
                          checkboxesProps={{
                            onChange: (e) => handleConsumerGenderChange(e, { values, setFieldValue }),
                            disabled: (checkboxValue) => isConsumerGenderCheckboxDisabled(checkboxValue, { values }),
                          }}
                          required={isRequiredField("consumerGender", validationSchema)}
                        />
                      </Grid>
                    </DashSection>

                    <DashSection
                      bodyTitleLabel
                      label={`${t("languages.label", { ns: nsMedicaidProviderProfile })}`}
                      topBorder
                      container
                    >
                      <Grid item xs={12} id="languages-grid">
                        <MultiAutocompleteAndChipsField<ProviderProfileTagEnum>
                          name="languages"
                          label={`${t("field.languages.label", { ns: nsCommonFormsBtns })} *`}
                          disableDefaultSort
                          maxSelections={profileLanguages.length}
                          selectOptions={[
                            [...profileLanguages.filter((v) => v.value === ProviderProfileTagEnum.ENGLISH)],
                            [...profileLanguages.filter((v) => v.value !== ProviderProfileTagEnum.ENGLISH)].sort(
                              (a, b) => a.label.localeCompare(b.label),
                            ),
                          ].flat()}
                        />
                      </Grid>
                    </DashSection>
                    <DashSection
                      bodyTitleLabel
                      label={t("caregiving_prefs.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      <Grid item xs={12} id="typeOfCare-grid">
                        <FormCheckboxGroupField<string>
                          name="typeOfCare"
                          label={t("general_info.willing_to_provide.label", { ns: nsMedicaidProviderProfile })}
                          options={CareFrequencyOptions}
                          required={isRequiredField("typeOfCare", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12} id="ageRange-grid">
                        <FormCheckboxGroupField<string>
                          name="ageRange"
                          label={t("general_info.willing_to_care_for.label", { ns: nsMedicaidProviderProfile })}
                          options={AgeRangeOptions}
                          required={isRequiredField("ageRange", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12} id="typeOfSupport-grid">
                        <FormCheckboxGroupField<string>
                          name="typeOfSupport"
                          label={t("general_info.willing_to_provide_support_for.label", {
                            ns: nsMedicaidProviderProfile,
                          })}
                          options={TypeOfSupportOptions}
                          required={isRequiredField("typeOfSupport", validationSchema)}
                        />
                      </Grid>
                    </DashSection>
                    <DashSection
                      bodyTitleLabel
                      label={t("care_skills.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      <Grid item xs={12}>
                        <Body>{t("skills_and_experience.description", { ns: nsMedicaidProviderProfile })}</Body>
                      </Grid>
                      <Grid item xs={12} id="generalSkills-grid">
                        <FormCheckboxGroupField<string>
                          name="generalSkills"
                          label={t("skills_and_experience.general_care.label", { ns: nsMedicaidProviderProfile })}
                          options={GeneralSkillsOptions.map((tag) =>
                            tag.value === ProviderProfileTagEnum.NURSE_DELEGATION
                              ? {
                                  value: tag.value,
                                  label: (
                                    <NurseDelegationPopover>
                                      {t(tag.locizeKey, { ns: tag.namespace })}
                                    </NurseDelegationPopover>
                                  ),
                                }
                              : tag,
                          )}
                          required={isRequiredField("generalSkills", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormCheckboxGroupField<string>
                          name="movementSkills"
                          label={t("skills_and_experience.movement.label", { ns: nsMedicaidProviderProfile })}
                          options={MovementSkillsOptions}
                          required={isRequiredField("movementSkills", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormCheckboxGroupField<string>
                          name="transportSkills"
                          label={t("skills_and_experience.transportation.label", { ns: nsMedicaidProviderProfile })}
                          options={TransportSkillsOptions}
                          required={isRequiredField("transportSkills", validationSchema)}
                        />
                      </Grid>
                    </DashSection>
                    <DashSection
                      bodyTitleLabel
                      label={t("work_environment.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                    >
                      <Grid item xs={12}>
                        <Body gutterBottom>
                          {`(${t("check_all_that_apply.label", {
                            ns: nsMedicaidProviderProfile,
                          })})`}
                        </Body>
                      </Grid>
                      <Grid item xs={12} id="generalWorkEnvironment-grid">
                        <FormCheckboxGroupField<string>
                          name="generalWorkEnvironment"
                          label={t("work_environment.general.label", { ns: nsMedicaidProviderProfile })}
                          options={[
                            {
                              value: ProviderProfileTagEnum.MULTIGENERATIONAL_HOUSEHOLDS,
                              label: t("multigenerational_households", { ns: nsMedicaidTagsEnums }),
                            },
                            {
                              value: ProviderProfileTagEnum.MULTILINGUAL_HOUSEHOLDS,
                              label: t("multilingual_households", { ns: nsMedicaidTagsEnums }),
                            },
                            {
                              value: ProviderProfileTagEnum.LGBTQIA_HOUSEHOLDS,
                              label: <LgbtPopover>{t("lgbtqia_households", { ns: nsMedicaidTagsEnums })}</LgbtPopover>,
                            },
                          ]}
                          required={isRequiredField("generalWorkEnvironment", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12} id="workEnvironment-grid">
                        <FormCheckboxGroupField<string>
                          name="workEnvironment"
                          label={t("work_environment.scents_smoking_pref.label", { ns: nsMedicaidProviderProfile })}
                          options={WorkEnvOptions}
                          required={isRequiredField("workEnvironment", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12} id="pets-grid">
                        <FormCheckboxGroupField<string>
                          name="pets"
                          label={t("work_environment.pets_comfortable_with.label", { ns: nsMedicaidProviderProfile })}
                          options={PetOptions}
                          required={isRequiredField("pets", validationSchema)}
                        />
                      </Grid>
                    </DashSection>
                    <DashSection
                      bodyTitleLabel
                      label={t("personality.label", { ns: nsMedicaidProviderProfile })}
                      topBorder
                      container
                      bottomBorder={false}
                    >
                      <Grid item xs={12} id="specializedSkills-grid">
                        <Body style={{ marginBottom: CivTheme.spacing(2) }}>
                          {t("personality.description.specialized_care_skills", { ns: nsMedicaidProviderProfile })}
                        </Body>
                        <FormMultiSelectChipsField
                          name="specializedSkills"
                          label={`${t("field.specialized_skill.label", { ns: nsCommonFormsBtns })} *`}
                          selectOptions={SpecializedSkillsOptions}
                          helperText={t("field.specialized_skill.helper_text", { ns: nsCommonFormsBtns })}
                        />
                      </Grid>
                      <Grid item xs={12} id="furtherIntroduction-grid">
                        <Body style={{ marginBottom: CivTheme.spacing(2) }}>
                          {t("personality.description.further_intro", { ns: nsMedicaidProviderProfile })}
                        </Body>
                        <FormTextField
                          label={t("field.hobbies_and_further_info.label", { ns: nsCommonFormsBtns })}
                          name="furtherIntroduction"
                          placeholder={t("field.hobbies_and_further_info.placeholder", { ns: nsCommonFormsBtns })}
                          multiline
                          minRows={5}
                          helperText={""}
                          required={isRequiredField("furtherIntroduction", validationSchema)}
                          maximumlength={FURTHER_INTRO_MAX}
                        />
                      </Grid>
                    </DashSection>
                    <FormAlert schema={validationSchema} sx={{ marginBottom: "64px" }} />
                    <Box textAlign="center" pt={3} pb={3}>
                      <Button variant="contained" type="submit" disabled={isSavingProfile || isUploadingPhoto}>
                        {isUploadingPhoto || isSavingProfile
                          ? t("button.saving_profile", { ns: nsCommonFormsBtns })
                          : t("button.complete", { ns: nsCommonFormsBtns })}
                      </Button>
                    </Box>
                  </Constraint>
                </Section>
              </LongForm>
            );
          }}
        </Formik>
      </main>
      <Footer />
    </>
  );
};

export interface MedicaidProfileFormFields {
  availability: ProviderProfileTagEnum;
  hours?: number;
  hoursFlexible: boolean;
  scheduleNotes: string;
  scheduleFlexible: boolean;
  gender: ProviderProfileTagEnum[];
  consumerGender: ProviderProfileTagEnum[];
  languages: ProviderProfileTagEnum[];
  typeOfCare: ProviderProfileTagEnum[];
  typeOfSupport: ProviderProfileTagEnum[];
  ageRange: ProviderProfileTagEnum[];
  generalSkills: ProviderProfileTagEnum[];
  movementSkills: ProviderProfileTagEnum[];
  transportSkills: ProviderProfileTagEnum[];
  generalWorkEnvironment: ProviderProfileTagEnum[];
  workEnvironment: ProviderProfileTagEnum[];
  pets: ProviderProfileTagEnum[];
  specializedSkills: ProviderProfileTagEnum[];
  furtherIntroduction: string;
  pronouns: ProviderProfileTagEnum[];
}

export function useMedicaidProfileForm() {
  const SCHEDULE_MAX = 500;
  const FURTHER_INTRO_MAX = 500;

  const initialValues: MedicaidProfileFormFields = {
    availability: ProviderProfileTagEnum.SEEKING,
    consumerGender: [],
    furtherIntroduction: "",
    gender: [],
    hours: 0,
    hoursFlexible: false,
    languages: [],
    pets: [],
    scheduleFlexible: false,
    scheduleNotes: "",
    generalSkills: [],
    movementSkills: [],
    transportSkills: [],
    specializedSkills: [],
    typeOfCare: [],
    typeOfSupport: [],
    ageRange: [],
    generalWorkEnvironment: [],
    workEnvironment: [],
    pronouns: [],
  };

  return {
    initialValues,
    SCHEDULE_MAX,
    FURTHER_INTRO_MAX,
  };
}

export default MedicaidProfile;
