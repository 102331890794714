import { Box, Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { nsUniversalPrivacyPolicy } from "src/i18n/Namespaces";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PublicPrivateNavBar from "src/reusable_view_elements/navbars/PublicPrivateNavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

export const StyledBreak = styled(Box)(() => ({
  height: 60,
}));

export const anchorStyle = {
  color: CivColors.coreDarkNavy,
  textDecoration: "none",
};

const PrivacyPolicy = () => {
  const { t, ready } = useTranslation([nsUniversalPrivacyPolicy]);

  return (
    <>
      <Helmet>
        <title>Carina | Privacy Policy</title>
      </Helmet>

      <PublicPrivateNavBar showLanguage />

      <main id="main-content">
        <Section bgcolor={CivColors.lightGray} style={{ overflowWrap: "anywhere" }}>
          {ready ? (
            <Constraint columns={10}>
              <SectionTitle style={{ textAlign: "center", marginBottom: 60 }}>{t("page title")}</SectionTitle>
              <Body paragraph>
                {t("intro 1")} <b>April 7, 2020.</b>
              </Body>
              <Trans
                t={t}
                i18nKey="intro 2"
                components={{
                  intro2link1: <MuiLink href="https://www.carinacare.com" target="_blank" />,
                  intro2link2: <MuiLink href="https://www.carina.org" target="_blank" />,
                }}
                parent={Body}
              />

              <BodyEmphasis paragraph>{t("intro 3")}</BodyEmphasis>
              <Body>{t("intro 4")}</Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("info use 1")}</BodyTitle>
              <Body>{t("info use 2")}</Body>

              <StyledBreak />

              <BodyEmphasis paragraph>{t("personal info 1")}</BodyEmphasis>
              <Body paragraph>{t("personal info 2")}</Body>
              <Body paragraph>{t("personal info 3")}</Body>
              <Body>{t("personal info 4")}</Body>

              <StyledBreak />

              <BodyEmphasis paragraph>{t("auto collect 1")}</BodyEmphasis>
              <Body paragraph>{t("auto collect 2")}</Body>
              <Body paragraph>{t("auto collect 3")}</Body>
              <Body paragraph>{t("auto collect 4")}</Body>
              <Body>{t("auto colleft 5")}</Body>

              <StyledBreak />

              <BodyEmphasis paragraph>{t("3rd party analytics 1")}</BodyEmphasis>
              <Trans
                t={t}
                i18nKey="3rd party analytics 2"
                components={{
                  analytics2link1: <MuiLink href="https://tools.google.com/dlpage/gaoptout" target="_blank" />,
                }}
                parent={Body}
              />
              <StyledBreak />

              <Trans
                t={t}
                i18nKey="3rd party analytics 3"
                components={{
                  analytics3link1: <MuiLink href="http://legal.hubspot.com/privacy-policy" target="_blank" />,
                  analytics3link2: <MuiLink href="https://www.salesforce.com/company/privacy/" target="_blank" />,
                }}
                parent={Body}
              />
              <StyledBreak />

              <Trans
                t={t}
                i18nKey="3rd party analytics 4"
                components={{
                  analytics4link1: <MuiLink href="https://www.aboutads.info/choices/" target="_blank" />,
                }}
                parent={Body}
              />
              <StyledBreak />

              <Body>{t("3rd party analytics 6")}</Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("under 13 1")}</BodyTitle>
              <Body>{t("under 13 2")}</Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("3rd party disclosure 1")}</BodyTitle>
              <Body paragraph>{t("3rd party disclosure 2")}</Body>
              <Body paragraph>
                <b>{t("3rd party disclosure 3 heading")}</b> {t("3rd party disclosure 3")}
              </Body>
              <Body paragraph>
                <b>{t("3rd party disclosure 4 heading")}</b> {t("3rd party disclosure 4")}
              </Body>
              <Body paragraph>
                <b>{t("3rd party disclosure 5 heading")}</b> {t("3rd party disclosure 5")}
              </Body>
              <Body paragraph>
                <b>{t("3rd party disclosure 6 heading")}</b> {t("3rd party disclosure 6")}
              </Body>
              <Body paragraph>
                <b>{t("3rd party disclosure 7 heading")}</b> {t("3rd party disclosure 7")}
              </Body>
              <Body>
                <b>{t("3rd party disclosure 8 heading")}</b> {t("3rd party disclosure 8")}
              </Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("additional use 1")}</BodyTitle>
              <Body paragraph>
                <b>{t("additional use 2 heading")}</b> {t("additional use 2")}
              </Body>
              <Body paragraph>
                <b>{t("additional use 3 heading")}</b> {t("additional use 3")}
              </Body>
              <Body>
                <b>{t("additional use 4 heading")}</b> {t("additional use 4")}
              </Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("links 1")}</BodyTitle>
              <Body>{t("links 2")}</Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("security 1")}</BodyTitle>
              <Body paragraph>{t("security 2")}</Body>
              <Body paragraph>{t("security 3")}</Body>
              <Body paragraph>{t("security 4")}</Body>
              <Body>{t("security 5")}</Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("account access 1")}</BodyTitle>
              <Body>{t("")}</Body>
              <Trans
                t={t}
                i18nKey="account access 2"
                components={{
                  access2link1: <MuiLink href="mailto:contactus@carina.org" />,
                }}
                parent={Body}
              />
              <StyledBreak />

              <BodyTitle paragraph>{t("notification of changes 1")}</BodyTitle>
              <Body>{t("notification of changes 2")}</Body>

              <StyledBreak />

              <BodyTitle paragraph>{t("contact information 0")}</BodyTitle>
              <Body paragraph>{t("contact information 1")}</Body>
              <Body paragraph>
                Carina
                <br />
                215 Columbia Street
                <br />
                Seattle, WA 98104
                <br />
              </Body>
              <Body>
                <MuiLink href="mailto:contactus@carina.org">contactus@carina.org</MuiLink>
                <br />
                1-855-796-0605
              </Body>
            </Constraint>
          ) : (
            <LoadingCircle />
          )}
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
