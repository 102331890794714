import { Box, BoxProps, ButtonProps as MButtonProps, Link as MuiLink } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { nsChildcareProviderProfile, nsCommonCTAbtns } from "src/i18n/Namespaces";
import {
  CalendarIcon,
  ChatTranslateIcon,
  TaskChecklistCheckIcon,
  PinLocation1Icon,
} from "src/assets/icons/StreamlineIcons";
import IconListItem from "src/reusable_view_elements/IconListItem";
import { InternalLink } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import CivColors from "src/themes/civilization/CivColors";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import IconList from "src/reusable_view_elements/IconList";
import { toSentenceCase, toTitleCase } from "src/utilities/GeneralUtilities";

interface ChildCareSearchCardProps extends BoxProps {
  name: string;
  hasOpenings: boolean;
  location: string;
  locationLink: string;
  milesAway: number;
  schedule: Array<string>;
  languages: Array<string>;
  expertises: Array<string>;
  onButtonClick: (link?: string) => void;
  ButtonProps?: Omit<MButtonProps, "type">;
}

const ChildCareSearchCard = ({
  name,
  hasOpenings,
  location,
  milesAway,
  locationLink,
  schedule,
  languages,
  expertises,
  onButtonClick,
  ButtonProps = {},
  ...restBoxProps
}: ChildCareSearchCardProps) => {
  const { t, ready } = useTranslation([nsChildcareProviderProfile, nsCommonCTAbtns]);

  return (
    <Box bgcolor={CivColors.white} width="100%" {...restBoxProps}>
      <Box width="100%">
        <Box bgcolor={CivColors.paleBlue} padding={3}>
          <BodyTitle paragraph style={{ overflowWrap: "anywhere" }}>
            <InternalLink
              to={locationLink}
              style={{ textDecoration: "none" }}
              onClick={() => onButtonClick(locationLink)}
            >
              {name}
            </InternalLink>
          </BodyTitle>
          {hasOpenings && ready && <Body>{t("openings available", { ns: nsChildcareProviderProfile })}</Body>}
        </Box>

        <Box bgcolor={CivColors.white} padding={3} paddingBottom={0}>
          {!ready ? (
            <LoadingCircle />
          ) : (
            <IconList>
              <IconListItem
                icon={PinLocation1Icon}
                primary={
                  <>
                    <Body gutterBottom>
                      {location} ({t("miles away", { ns: nsChildcareProviderProfile, distance: _.round(milesAway, 1) })}
                      )
                    </Body>
                    <Body>
                      <MuiLink href={`${locationLink}#map`} onClick={() => onButtonClick(`${locationLink}#map`)}>
                        {t("show map", { ns: nsChildcareProviderProfile })}
                      </MuiLink>
                    </Body>
                  </>
                }
              />
              <IconListItem
                icon={CalendarIcon}
                primary={
                  <Body>
                    {schedule
                      .map(toTitleCase)
                      .map((option) => t(`${option.toLowerCase()}`, { ns: nsChildcareProviderProfile }))
                      .join(", ")}
                  </Body>
                }
              />
              <IconListItem
                icon={ChatTranslateIcon}
                primary={
                  <Body>
                    {languages
                      .map(toTitleCase)
                      .map((option) => t(`${option.toLowerCase()}`, { ns: nsChildcareProviderProfile }))
                      .join(", ")}
                  </Body>
                }
              />
              <IconListItem
                icon={TaskChecklistCheckIcon}
                primary={
                  <Body paragraph>
                    {expertises
                      .map(toSentenceCase)
                      .map((option) => t(`${option.toLowerCase()}`, { ns: nsChildcareProviderProfile }))
                      .join(" • ")}
                  </Body>
                }
              />
            </IconList>
          )}
        </Box>
      </Box>

      <Box bgcolor={CivColors.white} padding={3} paddingTop={0}>
        {ready && (
          <Button
            variant="contained"
            type="button"
            sx={{ margin: "0px auto", display: "flex" }}
            onClick={() => onButtonClick(locationLink)}
            {...ButtonProps}
          >
            {t("view profile", { ns: nsCommonCTAbtns })}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ChildCareSearchCard;
