import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { nsCommonFormsBtns, nsMedicaidJobSurveyConfirmation } from "src/i18n/Namespaces";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";

interface SimpleThreadSurveyConfirmationProps {
  threadId: string;
}

// Since Case Managers and Referral Coordinators can submit a match survey, they need a confirmation
const SimpleThreadSurveyConfirmation = (props: SimpleThreadSurveyConfirmationProps) => {
  const { t, ready } = useTranslation([nsMedicaidJobSurveyConfirmation, nsCommonFormsBtns]);

  if (!ready) {
    return (
      <>
        <Section minimizeBottomGutter minHeight="80vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  return (
    <Section textAlign="center" minHeight="50vh">
      <Constraint columns={8}>
        <SectionTitle>{t("received_your_response.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
        <Body paragraph>{t("received_your_response.description", { ns: nsMedicaidJobSurveyConfirmation })}</Body>
        <Grid container>
          <Grid item xs={12}>
            <LinkStyledAsButton
              variant="outlined"
              component={Link}
              to={generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: props.threadId })}
            >
              {t("button.return_to_thread", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
          </Grid>
        </Grid>
      </Constraint>
    </Section>
  );
};

export default SimpleThreadSurveyConfirmation;
