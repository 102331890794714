import { useUserSession } from "src/context/UserSessionContext";
import { sendLogout as sendLogoutType } from "src/pages/auth/AuthRequests";
import { Body } from "src/reusable_view_elements/Typography";
import { useOnce } from "src/utilities/useOnce";

interface LoginProps {
  sendLogout: typeof sendLogoutType;
}

export const Logout: React.FC<LoginProps> = ({ sendLogout }) => {
  const { reloadAuthState } = useUserSession();

  useOnce(() => {
    sendLogout().finally(() => {
      reloadAuthState();
    });
  });

  return <Body>Logging out...</Body>;
};
