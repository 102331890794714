import { AxiosAuthControllerClient, LoginRequestDTO } from "src/generated/api_types";

const authClient = new AxiosAuthControllerClient();

export async function postLogin(formData: LoginRequestDTO) {
  return authClient.login(formData);
}

export async function sendLogout() {
  return authClient.logout().then(() => {
    localStorage.setItem("logoutEvent", "logout" + new Date().toISOString());
  });
}
