import { Alert, Box, Dialog, DialogContent, DialogTitle, Grid, Snackbar } from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  AgencyAdmin_DTO_Response_Session,
  ConsumerApplication_DTO_Response_Details,
  ConsumerApplicationCareNeeds,
  ConsumerApplicationDeclinedReason,
  ConsumerApplicationFundingSource,
  ConsumerApplicationGender,
  ConsumerApplicationHomeEnvironmentKey,
  ConsumerApplicationLanguage,
  ConsumerApplicationRelationship,
  ConsumerApplicationStatus,
  ContactMethod,
  User_DTO_Response_NameAndIdOnly,
} from "src/generated/api_types";
import { useConsumersDatatable } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerDashboard";
import {
  AGENCY_NOTES_MAX,
  PREF_DAYS_TIMES_MAX,
  SCHEDULE_MAX,
  DECLINED_NOTES_MAX,
} from "src/pages/agencyAdmin/dashboard/consumers/ConsumerValidationSchema";
import { getCareRecipientOrContactFirstName } from "src/pages/agencyAdmin/dashboard/consumers/GetConsumers";
import UnsavedChangesDialog from "src/pages/agencyAdmin/dashboard/dashboard_components/UnsavedChangesDialog";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { useCarinaAdminConsumersDatatable } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/CarinaAdminConsumerDashboard";
import { archiveClient as archiveClientCarina } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/Queries";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { useFunderAdminConsumersDatatable } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumerDashboard";
import { archiveClient as archiveClientFunder } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/Queries";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import BottomActionBar from "src/reusable_view_elements/BottomActionBar";
import DashSection from "src/reusable_view_elements/DashSection";
import { FormMultiSelectChipsField } from "src/reusable_view_elements/form_fields";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormAddressField } from "src/reusable_view_elements/form_fields/address_autocomplete/FormAddressField";
import { FormCheckboxGroupField } from "src/reusable_view_elements/form_fields/FormCheckboxGroupField";
import { FormRadioGroupField, RadioGroupOption } from "src/reusable_view_elements/form_fields/FormRadioGroupField";
import { FormSelectField, SelectOption } from "src/reusable_view_elements/form_fields/FormSelectField";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import colors from "src/themes/civilization/CivColors";
import { ConsumerApplicationDeclinedReasonOptions } from "src/utilities/ConsumerApplicationUtilities";

function getAgencyAdminOptions(
  data: AgencyAdmin_DTO_Response_Session[] | User_DTO_Response_NameAndIdOnly | undefined,
): SelectOption<string>[] {
  if (!data) {
    return [{ label: "Unassigned", value: "unassigned" }];
  }
  if (Array.isArray(data)) {
    return [
      { label: "Unassigned", value: "unassigned" },
      ...data.map((agencyAdmin) => {
        return { label: `${agencyAdmin.firstName} ${agencyAdmin.lastName}`, value: agencyAdmin.id };
      }),
    ];
  }
  return [{ label: `${data.firstName} ${data.lastName}`, value: `${data.id}` }];
}

interface ConsumerDetailsFormCareRecipientFields {
  firstName: string;
  lastName: string;
  address: string;
  dateOfBirth: string | undefined;
  gender: ConsumerApplicationGender;
}

interface ConsumerDetailsFormContactFields {
  firstName: string;
  lastName: string;
  relationship: ConsumerApplicationRelationship | "";
  email: string;
  phone: string;
  preferredMethod: ContactMethod | "";
  preferredTime: string;
}

interface HomeEnvironmentRadioProps {
  homeEnvironmentKey: ConsumerApplicationHomeEnvironmentKey;
  label?: string;
  trueLabel?: string;
  falseLabel?: string;
  unknownLabel?: string;
  flipTrueFalse?: boolean;
  disabled: boolean;
}

const HomeEnvironmentRadio: React.FC<HomeEnvironmentRadioProps> = ({
  homeEnvironmentKey,
  label,
  trueLabel = "Yes",
  falseLabel = "No",
  unknownLabel = "Unknown",
  flipTrueFalse = false,
  disabled,
}) => {
  const options: RadioGroupOption<boolean | "">[] = [];
  if (flipTrueFalse) {
    options.push({ key: false, optionLabel: falseLabel }, { key: true, optionLabel: trueLabel });
  } else {
    options.push({ key: true, optionLabel: trueLabel }, { key: false, optionLabel: falseLabel });
  }
  options.push({ key: "", optionLabel: unknownLabel });
  return (
    <FormRadioGroupField<boolean | "">
      name={`homeEnvironment.${homeEnvironmentKey}`}
      label={label}
      labelAlwaysOnTop={true}
      options={options}
      disabled={disabled}
    />
  );
};

type ConsumerDetailsFormProps = {
  editable: boolean;
} & FormikProps<ConsumerDetailsFormFields>;

export interface ConsumerDetailsFormFields {
  careRecipient: ConsumerDetailsFormCareRecipientFields;
  contact: ConsumerDetailsFormContactFields;
  status: ConsumerApplicationStatus;
  declinedReason?: ConsumerApplicationDeclinedReason;
  declinedNotes?: string;
  assignedTo: string;
  hours: number;
  languages: ConsumerApplicationLanguage[];
  careNeeds: ConsumerApplicationCareNeeds[];
  homeEnvironment: { [K in ConsumerApplicationHomeEnvironmentKey]?: boolean | "" };
  fundingSources: ConsumerApplicationFundingSource[];
  schedule: string;
  consumerNotes: string;
  agencyNotes: string;
  consumer: ConsumerApplication_DTO_Response_Details;
  agencyAdmins: AgencyAdmin_DTO_Response_Session[] | User_DTO_Response_NameAndIdOnly | undefined;
}

export const ConsumerDetailsForm: React.FC<ConsumerDetailsFormProps> = (props) => {
  const { dirty, isSubmitting, errors, values, editable } = props;
  const history = useHistory();
  const { showSnackbar } = useNotification();
  const { isUserType } = useUserSession();
  const [isArchiving, setArchiving] = useState<boolean>(false);

  const [snackbarOpen, setSnackbarOpen] = useState(Object.keys(errors).length > 0);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  const { removeData } =
    // eslint-disable-next-line no-nested-ternary
    editable && isUserType(UserType.FunderAdmin)
      ? useFunderAdminConsumersDatatable()
      : editable && (isUserType(UserType.CarinaAdmin) || isUserType(UserType.UserSupportManager))
        ? useCarinaAdminConsumersDatatable()
        : useConsumersDatatable();

  function handleSnackbarClose(_: object, reason?: string) {
    if (reason !== "clickaway") setSnackbarOpen(false);
  }

  function handleArchiveClient(data: ConsumerApplication_DTO_Response_Details) {
    if (isUserType(UserType.CarinaAdmin)) {
      setArchiving(true);
      archiveClientCarina(data.id)
        .then(() => {
          removeData(data.id);
          history.push(CARINA_ADMIN_DASHBOARD_ROUTES.consumer_list);
        })
        .catch(() => {
          setArchiving(false);
          showSnackbar("Error archiving client. Please try again.", "error");
        });
    }

    if (isUserType(UserType.FunderAdmin)) {
      setArchiving(true);
      archiveClientFunder(data.id)
        .then(() => {
          removeData(data.id);
          history.push(FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_list);
        })
        .catch(() => {
          setArchiving(false);
          showSnackbar("Error archiving client. Please try again.", "error");
        });
    }
  }

  function onUnload(e: BeforeUnloadEvent) {
    if (dirty) {
      e.preventDefault();
      e.returnValue = "Unsaved changes, Are you sure you want to leave?";
      return "Unsaved changes, do you want to exit?";
    }
    return null;
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);

  return (
    <>
      <UnsavedChangesDialog when={dirty && !isSubmitting} nextPath={DASHBOARD_ROUTES.consumer_list} />
      <Form name="consumer_details" localizationReady={true}>
        <Box marginBottom={6}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormSelectField
                name="assignedTo"
                label="Assigned to"
                selectOptions={getAgencyAdminOptions(values.agencyAdmins)}
                disabled={editable}
              />
            </Grid>
          </Grid>
        </Box>
        <DashSection container label="Lead Status" topBorder bottomBorder>
          <Grid item xs={12} md={6}>
            <FormSelectField
              name="status"
              label="Lead Status"
              selectOptions={[
                { value: ConsumerApplicationStatus.NEW_LEAD, label: "New" },
                { value: ConsumerApplicationStatus.CONTACTED, label: "Contacted (1x)" },
                { value: ConsumerApplicationStatus.CONTACTED_2, label: "Contacted (2x)" },
                {
                  value: ConsumerApplicationStatus.PHONE_SCREENED,
                  label: "Phone Screened",
                },
                { value: ConsumerApplicationStatus.ASSIGNMENT_PENDING, label: "Assignment Pending" },
                { value: ConsumerApplicationStatus.NOT_NOW, label: "Not Now" },
                { value: ConsumerApplicationStatus.MATCHED, label: "Matched" },
                { value: ConsumerApplicationStatus.DECLINED, label: "Declined" },
              ]}
              disabled={editable}
            />
          </Grid>
          {values.status === ConsumerApplicationStatus.DECLINED && (
            <Grid item xs={12} md={6}>
              <FormSelectField
                name="declinedReason"
                label="Reason for Decline*"
                selectOptions={ConsumerApplicationDeclinedReasonOptions}
                disabled={editable}
              />
            </Grid>
          )}
          {values.declinedReason === ConsumerApplicationDeclinedReason.OTHER &&
            values.status === ConsumerApplicationStatus.DECLINED && (
              <Grid item xs={12}>
                <FormTextField
                  name="declinedNotes"
                  label="Declined Status Notes*"
                  multiline
                  helperText={values.declinedNotes?.length && `${values.declinedNotes.length} / ${DECLINED_NOTES_MAX}`}
                />
              </Grid>
            )}
        </DashSection>

        <DashSection container label="Care Recipient" bottomBorder>
          <Grid item xs={12} md={6}>
            <FormTextField name="careRecipient.firstName" label="First Name" disabled={editable} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name="careRecipient.lastName" label="Last Name" disabled={editable} />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* TODO: If this is replaced with FastMaskTextField, please note the following:
                      This currently sends the date as yyyy-mm-dd, while FastMaskTextField sends the date as mm/dd/yyyy.
                      Note the hyphens/slashes and the order. */}
            <FormTextField name="careRecipient.dateOfBirth" label="Date of Birth" type="date" disabled={editable} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelectField
              name="careRecipient.gender"
              label="Gender"
              selectOptions={[
                { value: ConsumerApplicationGender.NOT_DISCLOSED, label: "Not Disclosed" },
                { value: ConsumerApplicationGender.MALE, label: "Male" },
                { value: ConsumerApplicationGender.FEMALE, label: "Female" },
                {
                  value: ConsumerApplicationGender.DIFFERENT_IDENTITY,
                  label: "Different Identity",
                },
              ]}
              disabled={editable}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormAddressField name="careRecipient.address" label="Address" disabled={editable} required />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormMultiSelectChipsField
              name="languages"
              label="Languages"
              selectOptions={[
                { value: ConsumerApplicationLanguage.ENGLISH, label: "English" },
                { value: ConsumerApplicationLanguage.SPANISH, label: "Spanish" },
                { value: ConsumerApplicationLanguage.VIETNAMESE, label: "Vietnamese" },
                { value: ConsumerApplicationLanguage.RUSSIAN, label: "Russian" },
                { value: ConsumerApplicationLanguage.CHINESE_CANTONESE, label: "Chinese-Cantonese" },
                { value: ConsumerApplicationLanguage.CHINESE_MANDARIN, label: "Chinese-Mandarin" },
                { value: ConsumerApplicationLanguage.TAGALOG, label: "Tagalog" },
                { value: ConsumerApplicationLanguage.KOREAN, label: "Korean" },
                {
                  value: ConsumerApplicationLanguage.AMERICAN_SIGN_LANGUAGE,
                  label: "American Sign Language",
                },
                { value: ConsumerApplicationLanguage.AMHARIC, label: "Amharic" },
                { value: ConsumerApplicationLanguage.ARABIC, label: "Arabic" },
                { value: ConsumerApplicationLanguage.SOMALI, label: "Somali" },
                { value: ConsumerApplicationLanguage.UKRAINIAN, label: "Ukrainian" },
                { value: ConsumerApplicationLanguage.BENGALI, label: "Bengali" },
                { value: ConsumerApplicationLanguage.HAITIAN_CREOLE, label: "Haitian Creole" },
                { value: ConsumerApplicationLanguage.ITALIAN, label: "Italian" },
                { value: ConsumerApplicationLanguage.POLISH, label: "Polish" },
                { value: ConsumerApplicationLanguage.YIDDISH, label: "Yiddish" },
                { value: ConsumerApplicationLanguage.HEBREW, label: "Hebrew" },
                { value: ConsumerApplicationLanguage.EDO_BINI, label: "Edo/Bini" },
                { value: ConsumerApplicationLanguage.URDU, label: "Urdu" },
                { value: ConsumerApplicationLanguage.HINDI, label: "Hindi" },
                { value: ConsumerApplicationLanguage.FRENCH, label: "French" },
                { value: ConsumerApplicationLanguage.TWI, label: "Twi" },
              ]}
              disabled={editable}
            />
          </Grid>
        </DashSection>

        <DashSection container label="Point of Contact or Representative" bottomBorder>
          <Grid item xs={12} md={12}>
            <FormSelectField
              name="contact.relationship"
              label="Relationship"
              selectOptions={[
                { value: ConsumerApplicationRelationship.MYSELF, label: "Myself" },
                {
                  value: ConsumerApplicationRelationship.SON_DAUGHTER,
                  label: "Son / Daughter",
                },
                {
                  value: ConsumerApplicationRelationship.SPOUSE_PARTNER,
                  label: "Spouse / Partner",
                },
                { value: ConsumerApplicationRelationship.RELATIVE, label: "Relative" },
                { value: ConsumerApplicationRelationship.FRIEND, label: "Friend" },
                {
                  value: ConsumerApplicationRelationship.CASE_WORKER,
                  label: "Case worker",
                },
                {
                  value: ConsumerApplicationRelationship.NON_PROFIT,
                  label: "Non-profit",
                },
                { value: ConsumerApplicationRelationship.OTHER, label: "Other" },
              ]}
              disabled={editable}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name="contact.firstName" label="First Name" disabled={editable} required />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField name="contact.lastName" label="Last Name" disabled={editable} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FastMaskTextField name="contact.phone" label="Mobile Phone" maskType="phone" disabled={editable} />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormTextField name="contact.email" label="Email Address" disabled={editable} required />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelectField
              name="contact.preferredMethod"
              label="Preferred Contact Method"
              selectOptions={[
                { value: ContactMethod.PHONE, label: "Call" },
                { value: ContactMethod.SMS, label: "Text" },
                { value: ContactMethod.EMAIL, label: "Email" },
              ]}
              disabled={editable}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              name="contact.preferredTime"
              label="Preferred days and times to call"
              inputProps={{ maxLength: PREF_DAYS_TIMES_MAX }}
              disabled={editable}
              helperText={`${values.contact.preferredTime.length} / ${PREF_DAYS_TIMES_MAX}`}
            />
          </Grid>
        </DashSection>

        <DashSection container label="Notes" bottomBorder>
          <Grid item xs={12} md={12} style={{ paddingBottom: 30 }}>
            <FormTextField
              multiline
              maxRows={5}
              name="consumerNotes"
              label="Care Needs Notes"
              placeholder="Any original notes from the care seeker about the care recipient would be here."
              inputProps={{ readOnly: true }}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              multiline
              maxRows={5}
              name="agencyNotes"
              label="Agency Notes"
              placeholder="Agency Notes"
              inputProps={{
                maxLength: AGENCY_NOTES_MAX,
              }}
              disabled={editable}
              helperText={`${values.agencyNotes.length} / ${AGENCY_NOTES_MAX}`}
            />
          </Grid>
        </DashSection>

        <DashSection container label="Care Schedule" bottomBorder>
          <Grid item xs={12} md={12}>
            <FormTextField
              name="schedule"
              label="Weekly Schedule"
              inputProps={{
                maxLength: SCHEDULE_MAX,
              }}
              disabled={editable}
              helperText={`${values.schedule.length} / ${SCHEDULE_MAX}`}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              disabled={editable}
              type="number"
              name="hours"
              label="Requested Hours Per Week"
              inputProps={{ min: 0 }}
              required
            />
          </Grid>
        </DashSection>

        <DashSection label="Care Needs" bottomBorder>
          <Grid item>
            <FormCheckboxGroupField<ConsumerApplicationCareNeeds>
              name="careNeeds"
              label="General Care"
              options={[
                { value: ConsumerApplicationCareNeeds.HOUSEKEEPING, label: "Housekeeping" },
                { value: ConsumerApplicationCareNeeds.LAUNDRY, label: "Laundry" },
                { value: ConsumerApplicationCareNeeds.MEAL_PREP, label: "Meal preparation" },
                { value: ConsumerApplicationCareNeeds.SHOPPING, label: "Shopping" },
                { value: ConsumerApplicationCareNeeds.HYGIENE, label: "Hygiene & personal care" },
                {
                  value: ConsumerApplicationCareNeeds.BEHAVIORAL_CHALLENGES,
                  label: "Working with behavioral challenges",
                },
              ]}
              disabled={editable}
            />
          </Grid>
          <Grid item mt={4}>
            <FormCheckboxGroupField<ConsumerApplicationCareNeeds>
              name="careNeeds"
              label="Mobility"
              pt={32}
              options={[
                {
                  value: ConsumerApplicationCareNeeds.AMBULATION,
                  label: "Mobility assistance (ambulation)",
                },
                { value: ConsumerApplicationCareNeeds.POSITIONING, label: "Positioning" },
                { value: ConsumerApplicationCareNeeds.TRANSFER, label: "Transfers" },
                {
                  value: ConsumerApplicationCareNeeds.ASSISTED_TRANSFER,
                  label: "Assisted transfers (Hoyer Lift, etc.)",
                },
              ]}
              disabled={editable}
            />
          </Grid>
        </DashSection>

        <DashSection label="Home Environment" bottomBorder>
          <BodyEmphasis paragraph>Does the care recipient have pets?</BodyEmphasis>
          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.DOGS}
              label="Dog(s)"
              disabled={editable}
            />
          </Grid>
          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.CATS}
              label="Cat(s)"
              disabled={editable}
            />
          </Grid>
          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.BIRDS}
              label="Bird(s)"
              disabled={editable}
            />
          </Grid>
          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.OTHER_PETS}
              label="Other(s)"
              disabled={editable}
            />
          </Grid>
          <BodyEmphasis paragraph style={{ paddingTop: 32 }}>
            Care recipient&apos;s home scents
          </BodyEmphasis>

          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.SMOKING_HOME}
              label="Smoking home"
              disabled={editable}
            />
          </Grid>
          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.FRAGRANCE_FREE}
              label="Fragrance-free home"
              disabled={editable}
            />
          </Grid>
          <Grid item style={{ paddingBottom: 32 }}>
            <HomeEnvironmentRadio
              homeEnvironmentKey={ConsumerApplicationHomeEnvironmentKey.PROVIDER_SMOKING}
              label="Preference on provider's smoking habits"
              trueLabel="OK with smoking"
              falseLabel="Non-smoking"
              flipTrueFalse
              disabled={editable}
            />
          </Grid>
        </DashSection>

        <DashSection label="Funding" style={{ marginBottom: 40 }}>
          <FormCheckboxGroupField<ConsumerApplicationFundingSource>
            name="fundingSources"
            options={[
              { value: ConsumerApplicationFundingSource.PRIVATE, label: "Private funds" },
              { value: ConsumerApplicationFundingSource.PUBLIC, label: "Government assistance" },
              { value: ConsumerApplicationFundingSource.OTHER, label: "Other sources" },
            ]}
            disabled={editable}
          />
        </DashSection>

        <BottomActionBar
          bottomActionButtons={[
            // @ts-ignore
            ...(editable
              ? [
                  {
                    variant: "text",
                    buttonText: "Archive Client",
                    buttonProps: {
                      onClick: () => setIsArchiveDialogOpen(true),
                      style: { color: colors.deepRose },
                    },
                  },
                ]
              : []),
            {
              // @ts-ignore
              variant: "outlined",
              buttonText: "Cancel",
              buttonProps: {
                onClick: () => history.push(DASHBOARD_ROUTES.consumer_list),
                disabled: isSubmitting,
              },
            },
            {
              // @ts-ignore
              variant: "contained",
              buttonText: "Save",
              buttonProps: {
                type: "submit",
                onClick: () => setSnackbarOpen(Object.keys(errors).length > 0),
                disabled: isSubmitting,
                // @ts-ignore
                "data-testid": "submit-button",
              },
            },
          ]}
        />

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} style={{ bottom: 100 }}>
          <Alert onClose={handleSnackbarClose} severity="error">
            Please check the form for errors then try submitting again.
          </Alert>
        </Snackbar>
        <Dialog
          open={isArchiveDialogOpen}
          onClose={(_event, reason) => {
            if (reason !== "backdropClick") {
              setIsArchiveDialogOpen(false);
            }
          }}
          maxWidth="xs"
          PaperProps={{
            style: { padding: "8px 0px 16px 0px" },
          }}
        >
          <DialogTitle>
            <BodyTitle>
              Are you sure you want to archive {getCareRecipientOrContactFirstName(values.consumer)}?
            </BodyTitle>
          </DialogTitle>
          <DialogContent>
            <Body paragraph>If you confirm, you cannot undo this action.</Body>
            <Grid style={{ textAlign: "right" }}>
              <Button
                variant="text"
                type="button"
                onClick={() => handleArchiveClient(values.consumer)}
                disabled={isArchiving}
                sx={{ color: CivColors.deepRose }}
              >
                Yes, archive
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => setIsArchiveDialogOpen(false)}
                disabled={isArchiving}
              >
                No
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </Form>
    </>
  );
};
