import { withFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  AgencyAdminForm,
  AgencyAdminFormFields,
  AgencyAdminFormProps,
  validationSchema,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyAdminForm";
import {
  generateInvite,
  getAllAgencies as getAllAgenciesType,
  getAllFunders as getAllFundersType,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import BottomActionBar from "src/reusable_view_elements/BottomActionBar";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { object } from "yup";

export const SUBMIT_FAILED_MESSAGE = "We are sorry, the invite could not be created";

type AgencyAdminInviteProps = {
  getAllFunders: typeof getAllFundersType;
  getAllAgencies: typeof getAllAgenciesType;
  onSubmit: typeof generateInvite;
};

export const AgencyAdminCreateInvite: React.FC<AgencyAdminInviteProps> = ({
  onSubmit,
  getAllFunders,
  getAllAgencies,
}) => {
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const { isUserType } = useUserSession();

  return (
    <>
      <DashReturnLink
        to={
          isUserType(UserType.CarinaAdmin)
            ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
            : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list
        }
      >
        Return to Agencies Dashboard
      </DashReturnLink>

      <Constraint columns={8} paddingTop={4}>
        <SectionTitle>Invite Agency Admin</SectionTitle>
      </Constraint>

      <Constraint columns={8} paddingTop={2} paddingBottom={14}>
        <WrappedAgencyAdminInviteForm
          getAllFunders={getAllFunders}
          getAllAgencies={getAllAgencies}
          handleSubmit={(values, { resetForm }) => {
            onSubmit(values.firstName, values.lastName, values.email, values.agencyId)
              .then((response) => {
                resetForm();
                showSnackbar(response.data.message, response.data.success ? "success" : "error");
              })
              .catch(() => {
                showSnackbar(SUBMIT_FAILED_MESSAGE, "error");
              });
          }}
        >
          {() => (
            <BottomActionBar
              bottomActionButtons={[
                {
                  variant: "outlined",
                  buttonText: "Cancel",
                  buttonProps: {
                    onClick: () =>
                      history.push(
                        isUserType(UserType.CarinaAdmin)
                          ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
                          : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list,
                      ),
                  },
                },
                {
                  variant: "contained",
                  buttonText: "Invite",
                  buttonProps: {
                    type: "submit",
                    //@ts-ignore
                    "data-testid": "invite-button",
                  },
                },
              ]}
            />
          )}
        </WrappedAgencyAdminInviteForm>
      </Constraint>
    </>
  );
};

const WrappedAgencyAdminInviteForm = withFormik<AgencyAdminFormProps, AgencyAdminFormFields>({
  mapPropsToValues: () => ({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    agencyId: "",
    agencyZip: "",
    agencyFunder: "",
  }),
  validationSchema: object(validationSchema),
  handleSubmit: (values, formikBag) => {
    const {
      props: { handleSubmit },
    } = formikBag;
    if (handleSubmit) {
      handleSubmit(values, formikBag);
    }
  },
})(AgencyAdminForm);
