import { List, ListItem, ListItemTextProps, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * IconList and IconListItem specifically for ChildCareCards (based on the ones in AgencyCards)
 */
const IconList = styled(List)(() => ({
  padding: 0,
  margin: 0,
}));

interface IconListItemProps extends ListItemTextProps {
  icon: React.ComponentType<SvgIconProps>;
}

export const IconListItem = ({ icon: Component, primary, secondary }: IconListItemProps) => {
  const style = {
    item: {
      padding: "12px 0px",
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
  };
  return (
    <ListItem alignItems="flex-start" style={style.item}>
      <Component style={style.icon} />
      <div>
        {primary}
        {secondary}
      </div>
    </ListItem>
  );
};

export default IconList;
