import { Redirect, Route, Switch } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import AuthenticatedRoute from "src/pages/auth/AuthenticatedRoute";
import Inbox from "src/pages/inbox/Inbox";
import { InboxTabState } from "src/pages/inbox/InboxContext";
import AllThreadsTabView from "src/pages/inbox/views/AllThreadsTabView";
import ArchivedThreadsTabView from "src/pages/inbox/views/ArchivedThreadsTabView";
import JobResponsesTabView from "src/pages/inbox/views/JobResponsesTabView";
import JobResponseThreadList from "src/pages/inbox/views/JobResponseThreadList";
import StarredThreadsTabView from "src/pages/inbox/views/StarredThreadsTabView";
import ThreadsWithUser from "src/pages/inbox/views/ThreadsWithUser";
import UnreadThreadsTabView from "src/pages/inbox/views/UnreadThreadsTabView";
import ViewThread from "./views/ViewThread";

export const MEDICAID_INBOX_PREFIX = "/homecare/medicaid/inbox";
export const MEDICAID_INBOX_ROUTES = {
  root: `${MEDICAID_INBOX_PREFIX}`,
  allThreads: `${MEDICAID_INBOX_PREFIX}/all`,
  unreadThreads: `${MEDICAID_INBOX_PREFIX}/unread`,
  starredThreads: `${MEDICAID_INBOX_PREFIX}/starred`,
  archivedThreads: `${MEDICAID_INBOX_PREFIX}/archived`,
  viewThread: `${MEDICAID_INBOX_PREFIX}/thread/:threadId`,
  jobResponses: `${MEDICAID_INBOX_PREFIX}/job-responses`,
  jobResponseThreads: `${MEDICAID_INBOX_PREFIX}/job-responses/:jobPostId`,
  jobResponseThread: `${MEDICAID_INBOX_PREFIX}/job-responses/:jobPostId/:threadId`,
  threadsWithUser: `${MEDICAID_INBOX_PREFIX}/threads/user/:userId`,
};

const InboxRouter = () => {
  const { isUserType } = useUserSession();

  return (
    <Switch>
      {/* Make Job Responses the default tab for MRC; Everyone else is directed to All tab */}
      <Redirect
        exact
        path={MEDICAID_INBOX_ROUTES.root}
        to={
          isUserType(UserType.MedicaidReferralCoordinator)
            ? MEDICAID_INBOX_ROUTES.jobResponses
            : MEDICAID_INBOX_ROUTES.allThreads
        }
      />
      <Route exact path={MEDICAID_INBOX_ROUTES.allThreads}>
        <Inbox tab={InboxTabState.all_threads}>
          <AllThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.unreadThreads}>
        <Inbox tab={InboxTabState.unread_threads}>
          <UnreadThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.starredThreads}>
        <Inbox tab={InboxTabState.starred_threads}>
          <StarredThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.archivedThreads}>
        <Inbox tab={InboxTabState.archived_threads}>
          <ArchivedThreadsTabView />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.viewThread}>
        <Inbox
          // TODO: Tab state should be determined by thread's archived state: if thread is archived, set tab to archived
          // https://github.com/CarinaWeb/CarinaCore/issues/998
          tab={InboxTabState.all_threads}
        >
          <ViewThread />
        </Inbox>
      </Route>
      <Route exact path={MEDICAID_INBOX_ROUTES.threadsWithUser}>
        <Inbox tab={InboxTabState.all_threads}>
          <ThreadsWithUser />
        </Inbox>
      </Route>
      <AuthenticatedRoute
        exact
        path={MEDICAID_INBOX_ROUTES.jobResponses}
        userType={UserType.MedicaidReferralCoordinator}
      >
        <Inbox tab={InboxTabState.job_responses}>
          <JobResponsesTabView />
        </Inbox>
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={MEDICAID_INBOX_ROUTES.jobResponseThreads}
        userType={UserType.MedicaidReferralCoordinator}
      >
        <Inbox tab={InboxTabState.job_responses}>
          <JobResponseThreadList />
        </Inbox>
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={MEDICAID_INBOX_ROUTES.jobResponseThread}
        userType={UserType.MedicaidReferralCoordinator}
      >
        <Inbox tab={InboxTabState.job_responses}>
          <ViewThread />
        </Inbox>
      </AuthenticatedRoute>
      <Redirect to={MEDICAID_INBOX_ROUTES.allThreads} />
    </Switch>
  );
};

export default InboxRouter;
