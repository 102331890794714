import { JobPostTagEnum, ProviderProfileTagEnum } from "src/generated/api_types";
import { nsMedicaidTagsEnums } from "src/i18n/Namespaces";

export interface LocalizedTag {
  key: string;
  namespace: string;
}

export function getLocalizedJobPostTag(apiTag: JobPostTagEnum): LocalizedTag {
  switch (apiTag) {
    // These tags do not map 1:1 to a localization key
    case JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT:
      return {
        key: "no_gender_preference.prefer_not_to_say", // Client's gender
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.NO_GENDER_PREFERENCE:
      return {
        key: "no_gender_preference.all_genders", // Client's preferred provider gender
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.CHILD:
      return {
        key: "child.job_post_v2", //TODO: Replace with 'child.job_post' once it is updated
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.ADULT:
      return {
        key: "adult.job_post_v2", //TODO: Replace with 'adult.job_post' once it is updated
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.OLDER_ADULT:
      return {
        key: "older_adult.job_post_v2", //TODO: Replace with 'older_adult.job_post' once it is updated
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.MALE_CLIENT: //TODO: Remove once content for male_client is updated
      return {
        key: "male_client_v2",
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.FEMALE_CLIENT: //TODO: Remove once content for female_client is updated
      return {
        key: "female_client_v2",
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.MALE: //TODO: Remove once content for 'male' is updated
      return {
        key: "male_v2",
        namespace: nsMedicaidTagsEnums,
      };
    case JobPostTagEnum.FEMALE: //TODO: Remove once content for fe'male' is updated
      return {
        key: "female_v2",
        namespace: nsMedicaidTagsEnums,
      };
    // All other tags map to a localization key in lowercase form
    default:
      return {
        key: apiTag?.toLowerCase() || "",
        namespace: nsMedicaidTagsEnums,
      };
  }
}

export function getLocalizedProviderProfileTag(apiTag: ProviderProfileTagEnum): LocalizedTag {
  switch (apiTag) {
    // These tags do not map 1:1 to a localization key
    case ProviderProfileTagEnum.SEEKING:
      return {
        key: "seeking.available_now",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.NOTSEEKING:
      return {
        key: "notseeking.not_available",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.SOON:
      return {
        key: "seeking.available_soon",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.NO_GENDER_PREFERENCE_CLIENT:
      return {
        key: "no_gender_preference.all_genders",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.NO_GENDER_PREFERENCE:
      return {
        key: "no_gender_preference.prefer_not_to_say",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.MALE:
      return {
        key: "male_v2",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.FEMALE:
      return {
        key: "female_v2",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.MALE_CLIENT:
      return {
        key: "male_client_v2",
        namespace: nsMedicaidTagsEnums,
      };
    case ProviderProfileTagEnum.FEMALE_CLIENT:
      return {
        key: "female_client_v2",
        namespace: nsMedicaidTagsEnums,
      };
    // All other tags map to a localization key in lowercase form
    default:
      return {
        key: apiTag.toLowerCase(),
        namespace: nsMedicaidTagsEnums,
      };
  }
}
