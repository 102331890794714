import { Grid, useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import HeroImageDesktop from "src/assets/images/emans-main.svg";
import CarinaLogo from "src/assets/images/logo-carina-version-2.svg";
import Constraint from "src/reusable_view_elements/Constraint";
import Hero from "src/reusable_view_elements/Hero";
import { InternalLink } from "src/reusable_view_elements/Link";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const desktopStyle = {
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 24,
  paddingRight: 24,
} as React.CSSProperties;
const mobileStyle = {
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 16,
  paddingRight: 16,
} as React.CSSProperties;

export const StaticDown: React.FC = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const style = desktopSize ? desktopStyle : mobileStyle;

  return (
    <>
      <Helmet>
        <title>Carina | Unavailable </title>
      </Helmet>

      <Grid style={style} container justifyContent="space-between" alignItems="center">
        <Grid item sm={6}>
          <InternalLink to="/">
            <img src={CarinaLogo} alt="" width={141} height={66} />
          </InternalLink>
        </Grid>
      </Grid>
      <Section>
        <Constraint columns={6} textAlign="center">
          <SectionTitle>Carina is currently unavailable</SectionTitle>
          <Body paragraph component="span">
            <BodyEmphasis>We apologize for the inconvenience and will return as soon as possible.</BodyEmphasis>
            <br />
            If you have any questions or concerns, please reach us at{" "}
            <a href="mailto:contactus@carina.org">contactus@carina.org</a>.
          </Body>
        </Constraint>
      </Section>

      <Hero bgcolor={CivColors.white}>
        <Constraint columns={8} textAlign="center">
          {/*<ImpactTitle>Better Care. Better Jobs.</ImpactTitle>*/}
          <img src={HeroImageDesktop} alt="" width="100%" />
        </Constraint>
      </Hero>
    </>
  );
};

export const MaintenanceStaticDown: React.FC = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const style = desktopSize ? desktopStyle : mobileStyle;

  return (
    <>
      <Helmet>
        <title>Carina | Unavailable </title>
      </Helmet>

      <Grid style={style} container justifyContent="space-between" alignItems="center">
        <Grid item sm={6}>
          <InternalLink to="/">
            <img src={CarinaLogo} alt="" width={141} height={66} />
          </InternalLink>
        </Grid>
      </Grid>
      <Section>
        <Constraint columns={6} textAlign="center">
          <SectionTitle>Carina is currently unavailable for scheduled maintenance</SectionTitle>
          <Body paragraph>
            <BodyEmphasis>We apologize for the inconvenience.</BodyEmphasis>
            <br />
            If you have any questions or concerns, please reach us at{" "}
            <a href="mailto:contactus@carina.org">contactus@carina.org</a>.
          </Body>
        </Constraint>
      </Section>

      <Hero bgcolor={CivColors.white}>
        <Constraint columns={8} textAlign="center">
          {/*<ImpactTitle>Better Care. Better Jobs.</ImpactTitle>*/}
          <img src={HeroImageDesktop} alt="" width="100%" />
        </Constraint>
      </Hero>

      {/*<Hidden xsDown>*/}
      {/*  <Separator bgcolor={CivColors.lightGray} />*/}
      {/*</Hidden>*/}
    </>
  );
};

export default StaticDown;
