import { Field, FieldProps } from "formik";
import {
  AddressField,
  AddressFieldProps,
} from "src/reusable_view_elements/form_fields/address_autocomplete/AddressField";

export const FormAddressField = (props: AddressFieldProps) => {
  return (
    <Field name={props.name}>
      {(formikProps: FieldProps<string>) => <AddressField formikFieldProps={formikProps} {...props} />}
    </Field>
  );
};
