import { ConsumerApplication_DTO_Status, ConsumerApplicationStatus } from "src/generated/api_types";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { toTitleCase } from "src/utilities/GeneralUtilities";

export function renderStatusString(status: ConsumerApplicationStatus): string {
  switch (status) {
    case ConsumerApplicationStatus.NEW_LEAD:
      return "New";
    case ConsumerApplicationStatus.CONTACTED:
      return "Contacted (1x)";
    case ConsumerApplicationStatus.CONTACTED_2:
      return "Contacted (2x)";
    default:
      return toTitleCase(status);
  }
}

function filterStatus(term: string, data: ConsumerApplication_DTO_Status) {
  return data.status.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
}

const statusOrder = [
  ConsumerApplicationStatus.NEW_LEAD,
  ConsumerApplicationStatus.CONTACTED,
  ConsumerApplicationStatus.CONTACTED_2,
  ConsumerApplicationStatus.PHONE_SCREENED,
  ConsumerApplicationStatus.ASSIGNMENT_PENDING,
  ConsumerApplicationStatus.MATCHED,
  ConsumerApplicationStatus.NOT_NOW,
  ConsumerApplicationStatus.DECLINED,
];
function sortStatus(data1: ConsumerApplication_DTO_Status, data2: ConsumerApplication_DTO_Status) {
  // Calculate which direction data1 is in the order from data1
  const diff = statusOrder.indexOf(data1.status) - statusOrder.indexOf(data2.status);
  // Normalize to 1 / -1
  return diff / Math.abs(diff);
}

export function statusColumn<T extends ConsumerApplication_DTO_Status>(): ClientsideColumn<T> {
  return {
    title: "Status",
    field: "status",
    customExport: (data) => renderStatusString(data.status),
    render: (data) => renderStatusString(data.status),
    customFilterAndSearch: filterStatus,
    customSort: sortStatus,
  };
}
