import { Grid, Link as MuiLink } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { nsCommonLogin } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import Alert, { AlertProps } from "src/reusable_view_elements/alert/Alert";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";

const LoginFailedErrorBox = (props: AlertProps) => {
  const { t } = useTranslation([nsCommonLogin]);
  const location = useLocation();
  const supportPhoneNumber = "18557960605";

  return (
    <Alert
      id="login-failed-error"
      severity="error"
      title={t("login_failed_error.label", { ns: nsCommonLogin })}
      {...props}
    >
      <Body gutterBottom>{t("login_failed_error.description", { ns: nsCommonLogin })}</Body>
      <Grid container>
        <Grid item container>
          <Grid item xs={1}>
            <Body>1 .</Body>
          </Grid>
          <Grid item xs={11}>
            <Body gutterBottom>{t("login_failed_error.description_step_1", { ns: nsCommonLogin })}</Body>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={1}>
            <Body>2 .</Body>
          </Grid>
          <Grid item xs={11}>
            <Body gutterBottom>{t("login_failed_error.description_step_2", { ns: nsCommonLogin })}</Body>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={1}>
            <Body>3 .</Body>
          </Grid>
          <Grid item xs={11}>
            <Trans
              t={t}
              i18nKey="login_failed_error.description_step_3"
              ns={nsCommonLogin}
              parent={Body}
              style={{ paddingRight: 38 }}
              components={{
                phone: <MuiLink href={`tel:${supportPhoneNumber}`} />,
                cntctus: (
                  <InternalLink
                    to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }}
                    target="_blank"
                  />
                ),
              }}
              values={{
                phone_value: "1-855-796-0605",
                cntctus_key: t("login_failed_error.cntctus.label", { ns: nsCommonLogin }),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Alert>
  );
};

export default LoginFailedErrorBox;
