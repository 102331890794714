import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { FieldProps } from "formik";
import { useState } from "react";
import TextField, { TextFieldProps } from "src/reusable_view_elements/form_fields/TextField";
import { ControllableFastField } from "src/reusable_view_elements/form_fields/ControllableFastField";
import CivColors from "src/themes/civilization/CivColors";

export type FormPasswordFieldProps = {
  name: string;
} & Omit<TextFieldProps, "name" | "type">;

//TODO: Evaluate possibility of integrating this component into FormTextField
export const FormPasswordField = (props: FormPasswordFieldProps) => {
  const [isShowingPassword, setIsShowingPassword] = useState<boolean>(false);

  const passwordIconStyle = { opacity: "0.54", color: CivColors.coreDarkNavy };
  const passwordIconButtonStyle = {
    padding: "12px",
    width: "48px",
    height: "48px",
  };

  return (
    <ControllableFastField name={props.name} controlledRerender={isShowingPassword} languagePeek={props.label || "--"}>
      {({ field, meta, form }: FieldProps<string>) => {
        return (
          <TextField
            {...props}
            type={isShowingPassword ? "text" : "password"}
            id={field.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase())}
            variant="outlined"
            placeholder={props.placeholder}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            helperText={meta.touched && meta.error ? meta.error : props.helperText}
            error={Boolean(meta.touched && meta.error)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsShowingPassword(!isShowingPassword);
                      form.setFieldValue(field.name, field.value);
                    }}
                    aria-label={isShowingPassword ? "Hide password" : "Show password"}
                    style={passwordIconButtonStyle}
                  >
                    {isShowingPassword ? (
                      <VisibilityRounded style={passwordIconStyle} />
                    ) : (
                      <VisibilityOffRounded style={passwordIconStyle} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    </ControllableFastField>
  );
};
