import { Box, Divider } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { urlShortener as urlShortenerType } from "src/pages/carinaAdmin/management/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { object, string } from "yup";
import Form from "src/reusable_view_elements/form_fields/Form";

interface URLShortenerProps {
  urlShortener: typeof urlShortenerType;
}

interface URLShortenerFormFields {
  url: string;
}

const initialValues: URLShortenerFormFields = {
  url: "",
};

const validationSchema = object({
  url: string().url(),
});

export const URLShortener: React.FC<URLShortenerProps> = ({ urlShortener }) => {
  const [urlData, setURLData] = useState("");
  const [error, setError] = useState();

  function handleSubmit(values: URLShortenerFormFields, helpers: FormikHelpers<URLShortenerFormFields>) {
    urlShortener(values.url)
      .then((res) => {
        setURLData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  }

  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.URL_SHORTENER}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form localizationReady={true}>
            <Constraint columns={8}>
              <Box p={4} />
              <SectionTitle>URL Shortener</SectionTitle>
              <Box p={2} />
              <FormTextField name="url" label="URL" />
              <Box p={2} />
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
              {urlData && (
                <>
                  <Box p={4} />
                  <Divider />
                  <Box p={4} />
                  <pre>{JSON.stringify(urlData, null, 2)}</pre>
                </>
              )}
              {error && (
                <>
                  <Box p={4} />
                  <Divider />
                  <Box p={4} />
                  <pre>{JSON.stringify(error, null, 2)}</pre>
                </>
              )}
            </Constraint>
          </Form>
        )}
      </Formik>
    </CarinaAdminMgmtContainer>
  );
};
