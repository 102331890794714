import { Redirect, Route, Switch } from "react-router-dom";
import { FunderAdminAgencyAdminDashboard } from "src/pages/funderAdmin/dashboard/funder_admin_agencies/FunderAdminAgencyAdminDashboard";
import { FunderAdminConsumerDashboard } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumerDashboard";
import { getAllFunderAgencyAdmins } from "src/pages/funderAdmin/dashboard/funder_admin_agencies/Queries";
import { getAllFunderConsumers } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/Queries";
import { FunderAdminProviderDashboard } from "src/pages/funderAdmin/dashboard/funder_admin_providers/FunderAdminProviderDashboard";
import { getAllFunderProviders } from "src/pages/funderAdmin/dashboard/funder_admin_providers/Queries";

const FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX = "/homecare/funder";
export const FUNDER_ADMIN_DASHBOARD_ROUTES = {
  root: FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX,
  consumer_root: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer`,
  agencyadmins_list: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/agencyadmins/list`,
  agencyadmins_details: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/agencyadmins/list/:id`,
  consumer_list: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer/list`,
  consumer_details: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer/list/:id`,
  provider_root: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/provider`,
  provider_list: `${FUNDER_ADMIN_DASHBOARD_ROUTE_PREFIX}/provider/list`,
};

const FunderAdminDashboardRouter: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_root}>
          <FunderAdminConsumerDashboard getAllFunderConsumers={getAllFunderConsumers} />
        </Route>
        <Route path={FUNDER_ADMIN_DASHBOARD_ROUTES.agencyadmins_list}>
          <FunderAdminAgencyAdminDashboard getAllFunderAgencyAdmins={getAllFunderAgencyAdmins} />
        </Route>
        <Route path={FUNDER_ADMIN_DASHBOARD_ROUTES.provider_root}>
          <FunderAdminProviderDashboard getAllFunderProviders={getAllFunderProviders} />
        </Route>
        <Redirect to={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_list} />
      </Switch>
    </>
  );
};

export default FunderAdminDashboardRouter;
