import { AxiosRoleSelectionControllerClient, RoleSelectionController_RoleSelectionDTO } from "src/generated/api_types";

const roleSelectionClient = new AxiosRoleSelectionControllerClient();

export async function getFundersWithRoles(offering: string) {
  return roleSelectionClient.getAllFundersWithRegPaths({ offering });
}

export async function getRegPath(regPathId: string) {
  return roleSelectionClient.getRegPath(regPathId);
}

export async function selectRole(formValues: RoleSelectionController_RoleSelectionDTO) {
  return roleSelectionClient.selectRole(formValues);
}
