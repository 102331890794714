import { PropsWithChildren, useState } from "react";
import { OnboardingDTO, OnboardingStepProgress, OnboardingStepType } from "src/generated/api_types";
import { createContainer } from "unstated-next";

interface InitialConfig {
  onboardingState: OnboardingDTO;
}

function onboardingContext(_initialState?: InitialConfig) {
  const [onboardingState, setOnboardingState] = useState<OnboardingDTO | undefined>(undefined);

  function addCompletedStep(step: keyof typeof OnboardingStepType, pageContent?: object | string) {
    const updatedStepTrack: OnboardingStepProgress = {
      // @ts-ignore
      isComplete: true,
      json: getStepProgress(step, pageContent),
      stepOrder: onboardingState?.progressDTO.stepProgress[step]?.stepOrder!,
    };
    type progressMapType = {
      [k in keyof typeof OnboardingStepType]: OnboardingStepProgress;
    };
    const updatedStepMap: progressMapType | {} = {};
    // @ts-ignore
    updatedStepMap[step] = updatedStepTrack;
    const updatedStepProgress = { ...onboardingState?.progressDTO.stepProgress, ...updatedStepMap };
    const updatedProgress = { ...onboardingState!.progressDTO, stepProgress: updatedStepProgress };
    setOnboardingState({ ...onboardingState!, progressDTO: updatedProgress });
  }

  function isStepComplete(step: keyof typeof OnboardingStepType): boolean {
    // @ts-ignore-next-line
    return onboardingState?.progressDTO.stepProgress[step]?.isComplete || false;
  }

  function getStepProgress(step: keyof typeof OnboardingStepType, pageContent: object | string | undefined): string {
    if (!pageContent) return "";
    return typeof pageContent === "string" ? pageContent : JSON.stringify(pageContent);
  }

  return {
    onboardingState,
    setOnboardingState,
    addCompletedStep,
    isStepComplete,
  };
}

const OnboardingContext = createContainer(onboardingContext);

interface OnboardingContextProviderProps {
  initialState?: InitialConfig;
}

export const OnboardingContextProvider: React.FC<PropsWithChildren<OnboardingContextProviderProps>> = ({
  initialState,
  children,
}) => <OnboardingContext.Provider initialState={initialState}>{children}</OnboardingContext.Provider>;

export const useOnboardingContext = () => {
  const { ...context } = OnboardingContext.useContainer();

  return {
    ...context,
  };
};
