import { Redirect, Route, Switch } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { requestPasswordResetOnBehalf } from "src/pages/auth/Queries";
import { AgencyInviteDashboard } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyInviteDashboard";
import { getAgencyInvites } from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { CarinaAdminConsumerDashboard } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/CarinaAdminConsumerDashboard";
import { getAllConsumers } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/Queries";
import { CarinaAdminFunderDashboard } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/CarinaAdminFunderDashboard";
import { getAllFunderAdmins } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/Queries";
import { CarinaAdminProviderDashboard } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/CarinaAdminProviderDashboard";
import { getAllProviders } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/Queries";
import { getReportDetails } from "src/pages/supportAdminAccount/ControllerClients";
import InviteDetails from "src/pages/supportAdminAccount/dashboard/components/InviteDetails";
import {
  ActiveChildcareUsers,
  ActiveMedicaidUsers,
  InvitedChildcareUsers,
  InvitedMedicaidUsers,
  MedicaidReportedCases,
} from "src/pages/supportAdminAccount/dashboard/components/SupportAdminDashboard";
import UserDetails from "src/pages/supportAdminAccount/dashboard/components/UserDetails";
import UserReportDetails from "src/pages/supportAdminAccount/dashboard/components/UserReportDetails";
import { ResetPassword } from "src/pages/supportAdminAccount/forms/ResetPassword";
import SupportPortalMain from "src/pages/supportAdminAccount/portal/SupportPortalMain";
import { SupportAdminContextProvider } from "src/pages/supportAdminAccount/SupportAdminContext";
import { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";

const SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX = "/support";

export const SUPPORT_ADMIN_DASHBOARD_ROUTES = {
  //MEDICAID ROUTES
  root: SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX,
  portal: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/portal`,
  dashboard: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools`,
  active_user: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/active`,
  invited_user: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/invited`,
  // blocked_user: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/blocked`, // Postponed blocked users list #181991643
  reported_cases: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/reported/list`,
  reported_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/reported/details/:id`,
  user_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/user/details/:id`,
  invite_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/invite/details/:id`,
  reset_password: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/tools/user/reset-password/:id`,

  //PRIVATE PAY ROUTES
  agency_root: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency`,
  agency_list: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/list`,
  agency_invite: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/invite`,
  agency_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/details/:id`,
  agency_invite_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/agency/admin/invite/details/:id`,
  consumer_list: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer/list`,
  consumer_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/consumer/list/:id`,
  funder_list: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/funder`,
  funder_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/funder/:id`,
  provider_list: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/provider/list`,

  //CHILDCARE ROUTES
  cc_dashboard: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/childcare/tools`,
  cc_active_user: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/childcare/tools/active`,
  cc_invited_user: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/childcare/tools/invited`,
  cc_user_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/childcare/tools/user/details/:id`,
  cc_invite_details: `${SUPPORT_ADMIN_DASHBOARD_ROUTE_PREFIX}/childcare/tools/invite/details/:id`,
};

const SupportAdminDashboardRouter = () => {
  const { isUserType, userSession } = useUserSession();

  return (
    <SupportAdminContextProvider
      initialState={isUserType(UserType.UserSupportAgent) ? { program: userSession?.funder.id } : { program: "none" }}
    >
      <Switch>
        <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.portal}>
          <SupportPortalMain />
        </Route>
        <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.active_user}>
          <ActiveMedicaidUsers />
        </Route>
        <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.invited_user}>
          <InvitedMedicaidUsers />
        </Route>
        {/* <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.blocked_user}>
          <BlockedMedicaidUsers />
        </Route> */}
        {isUserType(UserType.UserSupportManager) && (
          <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_cases}>
            <MedicaidReportedCases />
          </Route>
        )}

        {isUserType(UserType.UserSupportManager) && (
          <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_details}>
            <UserReportDetails getDetails={getReportDetails} />
          </Route>
        )}
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.user_details}>
          <UserDetails dashboardType={DashboardType.MEDICAID} />
        </Route>
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.invite_details}>
          <InviteDetails dashboardType={DashboardType.MEDICAID} />
        </Route>
        <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.reset_password}>
          <ResetPassword onSubmit={requestPasswordResetOnBehalf} />
        </Route>

        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_root}>
          <AgencyInviteDashboard getAgencyInvites={getAgencyInvites} />
        </Route>
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.consumer_list}>
          <CarinaAdminConsumerDashboard getAllConsumers={getAllConsumers} />
        </Route>
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.funder_list}>
          <CarinaAdminFunderDashboard getAllFunderAdmins={getAllFunderAdmins} />
        </Route>
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.provider_list}>
          <CarinaAdminProviderDashboard getAllProviders={getAllProviders} />
        </Route>

        <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_active_user}>
          <ActiveChildcareUsers />
        </Route>
        <Route exact path={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_invited_user}>
          <InvitedChildcareUsers />
        </Route>
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_user_details}>
          <UserDetails dashboardType={DashboardType.CHILDCARE} />
        </Route>
        <Route path={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_invite_details}>
          <InviteDetails dashboardType={DashboardType.CHILDCARE} />
        </Route>

        <Redirect to={SUPPORT_ADMIN_DASHBOARD_ROUTES.portal} />
      </Switch>
    </SupportAdminContextProvider>
  );
};

export default SupportAdminDashboardRouter;
