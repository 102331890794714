import { Redirect, Route, Switch } from "react-router-dom";
import { ConsumerDashboard } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerDashboard";
import AgencyAdminFAQ from "src/pages/agencyAdmin/AgencyAdminFAQ";
import { getConsumers } from "src/pages/agencyAdmin/dashboard/consumers/Queries";
import { getAllAgencyProviderListings } from "src/pages/agencyAdmin/dashboard/providers/AgencyAdmin_Provider_Requests";
import { ProviderDashboard } from "src/pages/agencyAdmin/dashboard/providers/ProviderDashboard";

const DASHBOARD_ROUTE_PREFIX = "/homecare/dashboard";
export const DASHBOARD_ROUTES = {
  root: DASHBOARD_ROUTE_PREFIX,
  consumer_root: `${DASHBOARD_ROUTE_PREFIX}/consumer`,
  consumer_list: `${DASHBOARD_ROUTE_PREFIX}/consumer/list`,
  consumer_list_paging: `${DASHBOARD_ROUTE_PREFIX}/consumer/list/paging`,
  consumer_details: `${DASHBOARD_ROUTE_PREFIX}/consumer/:id`,
  provider_root: `${DASHBOARD_ROUTE_PREFIX}/provider`,
  provider_list: `${DASHBOARD_ROUTE_PREFIX}/provider/list`,
  provider_create: `${DASHBOARD_ROUTE_PREFIX}/provider/create`,
  provider_details: `${DASHBOARD_ROUTE_PREFIX}/provider/:id`,
  faq: `${DASHBOARD_ROUTE_PREFIX}/faq`,
};

const DashboardRouter: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path={DASHBOARD_ROUTES.consumer_root}>
          <ConsumerDashboard getConsumers={getConsumers} />
        </Route>
        <Route path={DASHBOARD_ROUTES.provider_root}>
          <ProviderDashboard getProviderListings={getAllAgencyProviderListings} />
        </Route>
        <Route path={DASHBOARD_ROUTES.faq}>
          <AgencyAdminFAQ />
        </Route>
        <Redirect to={DASHBOARD_ROUTES.consumer_list} />
      </Switch>
    </>
  );
};

export default DashboardRouter;
