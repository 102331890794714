import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CivColors from "src/themes/civilization/CivColors";

const StyledBox = styled(Box)({
  background: CivColors.paleBlue,
  borderRadius: 4,
  padding: "16px",
  textAlign: "left",
  display: "flex",
});

const InformationBox = (props: BoxProps) => {
  return (
    <>
      <StyledBox {...props}>
        <Box marginRight={2}>
          <InfoRoundedIcon />
        </Box>
        <Box>{props.children}</Box>
      </StyledBox>
    </>
  );
};

export default InformationBox;
