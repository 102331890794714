import { Grid } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { OnboardingStepType, RoleType } from "src/generated/api_types";
import { nsMedicaidOnboardingMain } from "src/i18n/Namespaces";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import OnboardingStepCard from "src/pages/onboarding/components/OnboardingStepCard";
import { getOnboardingState } from "src/pages/onboarding/Queries";
import CompleteOnboarding from "src/pages/onboarding/step_pages/CompleteOnboarding";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import ProgressBar from "src/reusable_view_elements/ProgressBar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import UserNavbar from "src/reusable_view_elements/navbars/UserNavbar";
import CivColors from "src/themes/civilization/CivColors";

const OnboardingMain = () => {
  const { onboardingState, setOnboardingState } = useOnboardingContext();
  const { isUserType, userSession } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidOnboardingMain]);

  useEffect(() => {
    if (!onboardingState) {
      getOnboardingState()
        .then((res) => {
          if (res.status === 200) {
            setOnboardingState(res.data);
          } else {
            // eslint-disable-next-line no-console
            console.error(`Failed to load onboarding progress info`);
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${res.status}`);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("Failed to load onboarding progress info");
          // eslint-disable-next-line no-console
          console.error(`Error: ${err}`);
        });
    }
  }, []);

  const completedQty = onboardingState
    ? Object.values(onboardingState.progressDTO.stepProgress).filter((stepProgress) =>
        // @ts-ignore-next-line
        stepProgress ? stepProgress.isComplete : false,
      ).length
    : 0;
  const maxQty = onboardingState ? Object.keys(onboardingState.progressDTO.stepProgress).length : 1;

  const getOrderedArrayOfSteps = (): string[] => {
    return Object.entries(onboardingState!.progressDTO.stepProgress)
      .sort((a, b) => a[1]!.stepOrder - b[1]!.stepOrder)
      .map((x) => x[0]);
  };

  const isProvider = onboardingState?.roleDTO.roleType === RoleType.PROVIDER;
  const titleAddedText = isProvider ? `, ${userSession?.firstName}` : "";

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>{`Carina | ${isUserType(UserType.ChildcareProvider) ? "Childcare" : "Medicaid"} Onboard`}</title>
        </Helmet>

        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </>
    );
  }

  /* eslint-disable dot-notation */
  return (
    <>
      <Helmet>
        <title>
          {isUserType(UserType.ChildcareProvider) ? t("browser_tab_title.childcare") : t("browser_tab_title.medicaid")}
        </title>
      </Helmet>

      <UserNavbar showLanguage />

      <main id="main-content">
        {completedQty === maxQty && <CompleteOnboarding />}

        {completedQty < maxQty && (
          <Section bgcolor={CivColors.lightGray} minimizeTopGutter minHeight="75vh">
            <Constraint columns={6}>
              <SectionTitle align="center">
                {`${t("welcome_to_carina.label", { title_add_txt: titleAddedText })}`}
              </SectionTitle>
              <Body paragraph align="center">
                {isProvider
                  ? t("welcome_to_carina.description.searching_for_work")
                  : t("welcome_to_carina.description.quick_easy_steps")}
              </Body>
            </Constraint>
            <Constraint columns={6} paddingTop={4} paddingBottom={5}>
              <ProgressBar current={completedQty} max={maxQty} uniqueId="onboarding-progress" />
            </Constraint>
            <Constraint columns={10}>
              <Grid container spacing={2} justifyContent="center">
                {!onboardingState ? (
                  <LoadingCircle />
                ) : (
                  getOrderedArrayOfSteps().map((step, i) => (
                    <Grid item key={i} xs={12} sm={3}>
                      <OnboardingStepCard
                        state={onboardingState.roleDTO.funder.segment.split("-")[1]}
                        role={onboardingState.roleDTO}
                        step={step as keyof typeof OnboardingStepType}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Constraint>
          </Section>
        )}
      </main>

      <Footer />
    </>
  );
  /* eslint-disable dot-notation */
};

export default OnboardingMain;
