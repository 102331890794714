import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import ChildcareUsersInviteForm from "src/pages/supportAdminAccount/forms/ChildcareUsersInviteForm";
import MedicaidUsersInviteForm from "src/pages/supportAdminAccount/forms/MedicaidUsersInviteForm";
import { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";

interface UserInviteModalProps {
  showModal: boolean;
  handleModalClose: () => void;
  dashboardType: DashboardType;
}

const UserInviteModal = ({ showModal, handleModalClose, dashboardType }: UserInviteModalProps) => {
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Modal
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby="Medicaid-user-invite-modal"
        aria-describedby="Invite medicaid user"
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "#fff",
            border: 0,
            borderRadius: 2,
            color: "#000",
            top: "50%",
            left: 0,
            right: 0,
            padding: "26px 24px",
            transform: "translateY(-50%)",
            maxWidth: "584px",
            // maxHeight: "636px",
            margin: "auto",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            {dashboardType === DashboardType.MEDICAID ? (
              <MedicaidUsersInviteForm closeCallback={handleModalClose} />
            ) : (
              <ChildcareUsersInviteForm closeCallback={handleModalClose} />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserInviteModal;
