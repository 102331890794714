import { Grid, Hidden } from "@mui/material";
import { Formik } from "formik";
import { Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  CarinaAdminFunderManagementController_GeneralApiConfigDTO,
  CarinaAdminFunderManagementController_RegistrationStepDTO,
  CarinaAdminFunderManagementController_VerificationRegistrationStepDTO,
  HttpMethod,
  VerificationStrategyEnum,
} from "src/generated/api_types";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import {
  getRegStep as getRegStepType,
  testApi as testApiType,
  updateApiCreds as updateApiCredsType,
  updateRegStep as updateRegStepType,
  updateVerificationRegStep as updateVerificationRegStepType,
} from "src/pages/carinaAdmin/management/funders/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import { FormPasswordField, FormSelectField, FormTextField, TextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { useOnce } from "src/utilities/useOnce";
import { useStateIfMounted } from "use-state-if-mounted";
import { object, string } from "yup";

interface ApiConfigFormFields {
  url: string;
  username: string;
  password: string;
  healthcheckPath: string;
  healthcheckMethod: HttpMethod;
}

const validationSchema = object({
  url: string().url("Please enter a valid URL").required("Please enter a URL"),
  username: string().required("Please enter the username used to access the API"),
  password: string().required("Please enter the password used to access the API"),
  healthcheckPath: string().required("Please enter path to API healthcheck endpoint"),
  healthcheckMethod: string().oneOf(Object.values(HttpMethod)),
});

const HttpMethodOptions = Object.values(HttpMethod).map((method) => ({
  label: method,
  value: method,
}));

interface ApiConfigFormProps {
  apiConfig: CarinaAdminFunderManagementController_GeneralApiConfigDTO;
  updateApiCreds: typeof updateApiCredsType;
  testApi: typeof testApiType;
}

const ApiConfigForm: React.FC<ApiConfigFormProps> = ({ apiConfig, updateApiCreds, testApi }) => {
  const { showSnackbar } = useNotification();

  const initialValues: ApiConfigFormFields = {
    url: apiConfig.url,
    username: apiConfig.username || "",
    password: apiConfig.password || "",
    healthcheckPath: apiConfig.path,
    healthcheckMethod: apiConfig.method,
  };

  function handleSubmit(values: ApiConfigFormFields) {
    updateApiCreds(apiConfig.id, values.username, values.password)
      .then(() => {
        showSnackbar("API credentials successfully updated!", "success");
      })
      .catch(() => {
        showSnackbar("Oops! There was an error updating the API credentials.", "error");
      });
  }

  function requestApiTest() {
    showSnackbar("Requesting API healthcheck...");
    testApi(apiConfig.id).then((res) => {
      if (res.data) {
        showSnackbar("API healthcheck passed.", "success");
      } else {
        showSnackbar("API healthcheck failed.", "error");
      }
    });
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ submitForm }) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField name="url" label="API URL" fullWidth />
            </Grid>
            <Hidden lgDown>
              <Grid item md={6} />
            </Hidden>
            <Grid item xs={12} md={6}>
              <FormTextField name="username" label="API Username" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPasswordField name="password" label="API Password" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField name="healthcheckPath" label="API Healthcheck Path" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelectField
                name="healthcheckMethod"
                label="API Healthcheck Method"
                selectOptions={HttpMethodOptions}
              />
            </Grid>
            <Hidden lgDown>
              <Grid item md={6} />
            </Hidden>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              <Button variant="contained" type="button" onClick={submitForm}>
                Update
              </Button>
              <Button variant="contained" type="button" onClick={requestApiTest}>
                Test
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

interface VerificationRegStepFormFields {
  path: string;
  method: HttpMethod;
  classRef: string;
}

const verificationRegStepValidationSchema = object({
  path: string().required("Please enter path to verification endpoint"),
  method: string().oneOf(Object.values(HttpMethod)),
  classRef: string()
    .matches(/^(?:[\w_]+\.){4,}[\w_]+$/)
    .required("Please enter a reference to the verification response class"),
});

function isVerificationRegStep(regStep: CarinaAdminFunderManagementController_RegistrationStepDTO): boolean {
  return "verificationConfig" in regStep;
}

interface VerificationDataProps {
  regStep: CarinaAdminFunderManagementController_RegistrationStepDTO;
  updateVerificationRegStep: typeof updateVerificationRegStepType;
}

const VerificationData: React.FC<VerificationDataProps> = ({ regStep, updateVerificationRegStep }) => {
  const { showSnackbar } = useNotification();

  if (!isVerificationRegStep(regStep)) {
    return <></>;
  }

  const verRegStep = regStep as CarinaAdminFunderManagementController_VerificationRegistrationStepDTO;
  const initialValues: VerificationRegStepFormFields = {
    path: verRegStep.verificationConfig.path,
    method: verRegStep.verificationConfig.method,
    classRef: verRegStep.verificationConfig.classRef,
  };

  function handleSubmit(values: VerificationRegStepFormFields) {
    updateVerificationRegStep({
      regStepId: regStep.id,
      ...values,
    })
      .then(() => {
        showSnackbar("Verification endpoint configuration successfully updated!", "success");
      })
      .catch(() => {
        showSnackbar("Oops! There was an error updating the verification endpoint configuration.", "error");
      });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            InputProps={{ readOnly: true }}
            fullWidth
            label="Verification Strategy"
            value={verRegStep.verificationConfig.verificationStrategy}
          />
        </Grid>
        {verRegStep.verificationConfig.verificationStrategy !== VerificationStrategyEnum.API && (
          <Hidden lgDown>
            <Grid item md={6} />
          </Hidden>
        )}
      </Grid>
      {verRegStep.verificationConfig.verificationStrategy === VerificationStrategyEnum.API && (
        <ApiConfigForm
          apiConfig={verRegStep.verificationConfig.apiConfig}
          updateApiCreds={updateApiCredsType}
          testApi={testApiType}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={verificationRegStepValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField name="path" label="Verification Endpoint Path" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField name="method" label="Verification Endpoint Method" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormTextField name="classRef" label="Verification Endpoint Response Class" fullWidth />
            </Grid>
            <Hidden lgDown>
              <Grid item md={6} />
            </Hidden>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              <Button variant="contained" type="button" onClick={submitForm}>
                Update
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

interface RegStepFormFields {
  name: string;
}

const regStepValidationSchema = object({
  name: string().required("Please enter a display name."),
});

interface PathParams {
  id: string;
}

interface FunderMgmtDetailProps {
  getRegStep: typeof getRegStepType;
  updateRegStep: typeof updateRegStepType;
}

export const FunderMgmtRegStepDetail: React.FC<FunderMgmtDetailProps> = ({ getRegStep, updateRegStep }) => {
  const { showSnackbar } = useNotification();
  const { id } = useParams<PathParams>();
  const [isFetching, setIsFetching] = useStateIfMounted<boolean>(true);
  const [regStep, setRegStep] = useStateIfMounted<CarinaAdminFunderManagementController_RegistrationStepDTO>(
    undefined as unknown as CarinaAdminFunderManagementController_RegistrationStepDTO,
  );

  useOnce(() => {
    getRegStep(id)
      .then((res) => {
        setRegStep(res.data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  });

  if (isFetching) {
    return (
      <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
        <Body>Loading...</Body>
      </CarinaAdminMgmtContainer>
    );
  }

  if (!isFetching && !regStep) {
    showSnackbar(`Could not find Registration Step ${id}`, "error");
    return <Redirect to={CARINA_ADMIN_MGMT_ROUTES.root} />;
  }

  const initialValues: RegStepFormFields = {
    name: regStep.displayName,
  };

  function handleSubmit(values: RegStepFormFields) {
    updateRegStep(id, values)
      .then(() => {
        showSnackbar("Registration step successfully updated!", "success");
      })
      .catch(() => {
        showSnackbar("Oops! There was an error updating the registration step.", "error");
      });
  }

  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
      <Constraint columns={10} paddingTop={4}>
        <SectionTitle>Registration Step</SectionTitle>
      </Constraint>
      <Constraint columns={10}>
        <Formik initialValues={initialValues} validationSchema={regStepValidationSchema} onSubmit={handleSubmit}>
          {({ submitForm }) => (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormTextField name="name" label="Registration Step Name" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputProps={{ readOnly: true }}
                  fullWidth
                  label="Registration Step Type"
                  value={regStep.step}
                />
              </Grid>
              <Hidden lgDown>
                <Grid item md={6} />
              </Hidden>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <Button variant="contained" type="button" onClick={submitForm}>
                  Update
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
        <VerificationData regStep={regStep} updateVerificationRegStep={updateVerificationRegStepType} />
      </Constraint>
    </CarinaAdminMgmtContainer>
  );
};
