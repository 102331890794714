import { Box, BoxProps } from "@mui/material";
import CivColors from "src/themes/civilization/CivColors";

const PortalCardDivider = (props: BoxProps) => (
  <Box
    height={0}
    borderBottom={`1px solid ${CivColors.mediumGray}`}
    width="100%"
    marginTop="24px"
    marginBottom="16px"
    {...props}
  />
);

export default PortalCardDivider;
