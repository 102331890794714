import { format, getTime, parseISO } from "date-fns";
import { withFormik } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import {
  ConsumerApplication_DTO_Request_Update,
  ConsumerApplication_DTO_Response_Details,
  ConsumerApplicationGender,
  ConsumerApplicationHomeEnvironmentKey,
} from "src/generated/api_types";
import { getUpdatedDate } from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import { consumerValidationSchema } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerValidationSchema";
import {
  getCareRecipientOrContactFirstName,
  getCareRecipientOrContactLastName,
} from "src/pages/agencyAdmin/dashboard/consumers/GetConsumers";
import { getConsumer as getConsumerType } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/Queries";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import { ConsumerDetailsForm, ConsumerDetailsFormFields } from "src/reusable_view_elements/forms/ConsumerDetailsForm";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { mapFieldToDTO } from "src/utilities/ConsumerApplicationUtilities";
import { useOnce } from "src/utilities/useOnce";

function getCreatedDate(data: ConsumerApplication_DTO_Response_Details): Date {
  return data.createdAt ? new Date(data.createdAt) : new Date();
}

type ConsumerDetailsPageProps = {
  getConsumer: typeof getConsumerType;
};

interface WrappedConsumerDetailsFormProps {
  consumer: ConsumerApplication_DTO_Response_Details;
  editable: boolean;
}

export const FunderAdminConsumerDetails: React.FC<ConsumerDetailsPageProps> = ({ getConsumer }) => {
  const [consumer, setConsumer] = useState<ConsumerApplication_DTO_Response_Details>();
  const [isFetching, setFetching] = useState(true);

  const { id } = useParams<{ id: string }>();

  useOnce(() => {
    Promise.allSettled([
      getConsumer(id)
        .then((response) => {
          if (response.status === 200) {
            setConsumer(response.data);
          } else {
            // eslint-disable-next-line no-console
            console.error(`Failed to load client with ID ${id}`);
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${response.status}`);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("Failed to load client details");
          // eslint-disable-next-line no-console
          console.error(`Error: ${err}`);
        }),
    ]).finally(() => {
      setFetching(false);
    });
  });

  if (!consumer) {
    if (isFetching) {
      return <div data-testid="loading-text">Fetching client application...</div>;
    }

    return <div data-testid="loading-error-text">Error fetching client application!</div>;
  }

  function formatDate(date: Date) {
    return format(date, "MMMM d, yyyy, h:mma");
  }

  // TODO: https://github.com/CarinaWeb/CarinaCore/issues/300
  const WrappedConsumerDetailsForm = withFormik<WrappedConsumerDetailsFormProps, ConsumerDetailsFormFields>({
    mapPropsToValues: ({
      consumer: {
        careRecipient: { dateOfBirth, gender, ...restCareRecipient },
        contact: { relationship, preferredMethod, phone, ...restContact },
        assignedAgencyAdmin,
        homeEnvironment,
        ...restConsumer
      },
    }): ConsumerDetailsFormFields => ({
      ...restConsumer,
      assignedTo: !assignedAgencyAdmin ? "unassigned" : assignedAgencyAdmin.id,
      careRecipient: {
        ...restCareRecipient,
        dateOfBirth: dateOfBirth ? format(dateOfBirth, "yyyy-MM-dd") : undefined,
        gender: gender || ConsumerApplicationGender.NOT_DISCLOSED,
      },
      contact: {
        ...restContact,
        phone: phone || "",
        relationship: relationship || "",
        preferredMethod: preferredMethod || "",
      },
      homeEnvironment: Object.fromEntries(
        Object.keys(ConsumerApplicationHomeEnvironmentKey).map((k) => [
          k,
          homeEnvironment[k as ConsumerApplicationHomeEnvironmentKey] === undefined
            ? ""
            : homeEnvironment[k as ConsumerApplicationHomeEnvironmentKey],
        ]),
      ),
      consumer,
      agencyAdmins: assignedAgencyAdmin,
    }),
    validationSchema: consumerValidationSchema,
    handleSubmit: async (values) => {
      const {
        careRecipient: { dateOfBirth: dob, ...restCareRecipient },
        contact: { relationship, preferredMethod, phone, ...restContact },
        homeEnvironment,
        assignedTo,
        // eslint-disable-next-line no-shadow
        ...restOfValues
      } = values;
      // TODO ESLint: This is unused, how is this working?
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const consumerUpdateDTO: ConsumerApplication_DTO_Request_Update = {
        ...restOfValues,
        careRecipient: {
          ...restCareRecipient,
          dateOfBirth: dob ? getTime(parseISO(dob)) : undefined,
        },
        contact: {
          ...restContact,
          phone: phone.replace(/[^0-9]/g, ""),
          relationship: relationship || undefined,
          preferredMethod: preferredMethod || undefined,
        },
        homeEnvironment: mapFieldToDTO(homeEnvironment),
        assignedAgencyAdmin: assignedTo !== "unassigned" ? assignedTo : undefined,
      };
    },
  })(ConsumerDetailsForm);

  return (
    <>
      <Helmet>
        <title>Carina | Internal Dashboard Client Details</title>
      </Helmet>
      <DashReturnLink to={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_list}>Return to Client Dashboard</DashReturnLink>

      <Constraint columns={8} paddingTop={4}>
        <SectionTitle>
          {getCareRecipientOrContactFirstName(consumer)} {getCareRecipientOrContactLastName(consumer)}
        </SectionTitle>
        <Body gutterBottom>Created: {formatDate(getCreatedDate(consumer))}</Body>
        <Body>Last updated: {formatDate(getUpdatedDate(consumer))}</Body>
      </Constraint>

      <Constraint columns={8} paddingTop={4} paddingBottom={14}>
        <WrappedConsumerDetailsForm editable consumer={consumer} />
      </Constraint>
    </>
  );
};
