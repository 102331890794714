import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Redirect, useLocation, useParams } from "react-router-dom";
import HeroImage from "src/assets/images/emans-main.svg";
import { RegistrationController_RegistrationStepDTO, SingleInviteType } from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidRegVerification } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import LoadingPage from "src/pages/LoadingPage";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { redeemSingleInvite as redeemSingleInviteType } from "src/pages/registration/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import Hero from "src/reusable_view_elements/Hero";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { useOnce } from "src/utilities/useOnce";

interface InviteLandingParams {
  token: string;
}

interface InviteLandingProps {
  redeemSingleInvite: typeof redeemSingleInviteType;
  inviteType: SingleInviteType;
}

const InviteError = (props: { apiErrorMessage: string }) => {
  const { t, ready } = useTranslation([nsMedicaidRegVerification, nsCommonFormsBtns]);
  const location = useLocation();

  const message = (): string => {
    if (props.apiErrorMessage.toLowerCase().indexOf("token does not exist") !== -1) {
      return t("single_invite_redemption.error.label.invalid", { ns: nsMedicaidRegVerification });
    }
    if (props.apiErrorMessage.toLowerCase().indexOf("expired") !== -1) {
      return t("single_invite_redemption.error.label.expired", { ns: nsMedicaidRegVerification });
    }
    if (props.apiErrorMessage.toLowerCase().indexOf("invalidated") !== -1) {
      return t("single_invite_redemption.error.label.invalidated", { ns: nsMedicaidRegVerification });
    }
    return "";
  };
  return (
    <>
      <Helmet>
        <title>Carina | Invite Error</title>
      </Helmet>

      <NavBar showLanguage />

      <main id="main-content">
        {ready ? (
          <Section bgcolor={CivColors.lightGray}>
            <Constraint columns={6} textAlign="center">
              <SectionTitle>{message()}</SectionTitle>
              <Trans
                t={t}
                i18nKey="single_invite_redemption.error.description"
                ns={nsMedicaidRegVerification}
                parent={(parentProps) => <Body paragraph>{parentProps.children}</Body>}
                components={{
                  contact_link: (
                    <InternalLink
                      to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }}
                      target="_blank"
                    />
                  ),
                }}
                values={{
                  contact_us: t("button.contact_us", {
                    ns: nsCommonFormsBtns,
                  }),
                }}
              />
              <LinkStyledAsButton variant="contained" href={ROUTES.contactUs}>
                {t("button.contact_us", {
                  ns: nsCommonFormsBtns,
                })}
              </LinkStyledAsButton>
            </Constraint>
            <Hero bgcolor={CivColors.lightGray}>
              <Constraint columns={8} textAlign="center">
                <img src={HeroImage} alt="" width="100%" />
              </Constraint>
            </Hero>
          </Section>
        ) : (
          <Section bgcolor={CivColors.lightGray}>
            <LoadingCircle />
          </Section>
        )}
      </main>

      <Footer />
    </>
  );
};

const SingleInviteLanding: React.FC<InviteLandingProps> = ({ redeemSingleInvite, inviteType }) => {
  const { token } = useParams<InviteLandingParams>();
  const { regPath, setRegPath, getNextStepRoute, sendUserInfoToContext, setIsInvitedUser } = useRegistrationContext();
  const [isLoadingInvite, setIsLoadingInvite] = useState(true);
  const [regStep, setRegStep] = useState<RegistrationController_RegistrationStepDTO | undefined>(undefined);
  const [apiErrorMsg, setApiErrorMsg] = useState("");

  useOnce(() => {
    redeemSingleInvite(token, inviteType)
      .then((res) => {
        if (res.data.error) {
          setApiErrorMsg(res.data.error.message);
          setRegStep(undefined);
          setRegPath(undefined);
        } else if (res.data.result) {
          setIsInvitedUser(true);
          setRegStep(res.data!!.result.regStep);
          setRegPath(res.data!!.result.invitedUser.registrationPath);
          sendUserInfoToContext({
            email: res.data!!.result.invitedUser.email,
            firstName: res.data!!.result.invitedUser.firstName,
            lastName: res.data!!.result.invitedUser.lastName,
            medicaidState: res.data!!.result.invitedUser.registrationPath.roleConfig.funder.segment,
          });
        }
      })
      .finally(() => {
        setIsLoadingInvite(false);
      });
  });

  if (isLoadingInvite) {
    return <LoadingPage />;
  }

  if (!regStep || !regPath) {
    return <InviteError apiErrorMessage={apiErrorMsg} />;
  }

  return (
    <Redirect
      to={getNextStepRoute(regStep.step, {
        funder: regPath.roleConfig.funder.segment.split("-")[1].toLowerCase(),
        role: regPath.roleConfig.roleType.toLowerCase(),
      })}
    />
  );
};

export default SingleInviteLanding;
