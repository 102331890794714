import { buttonClasses } from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { nsCommonNavigation } from "src/i18n/Namespaces";
import { Button } from "src/reusable_view_elements/Button";

/**
 * This button allows users, particularly screen reader users, to skip navigation and go straight to the main content.
 * When the button is not in focus, it is moved far off-screen and is fully transparent.
 * When the button is in focus, it is reset to the top left corner of the page and is fully visible.
 */
const SkipNavigationButton = () => {
  const { t } = useTranslation(nsCommonNavigation);
  return (
    <Button
      variant="contained"
      type="button"
      href="#main-content"
      sx={{
        position: "absolute",
        left: "-9999px",
        zIndex: "999",
        opacity: 0,
        [`&.${buttonClasses.focusVisible}`]: {
          left: "initial",
          opacity: 1,
        },
        marginTop: "8px",
        marginLeft: "8px",
      }}
    >
      {t("link.skip_to_main_content", { defaultValue: "Skip to main content" })}
    </Button>
  );
};

export default SkipNavigationButton;
