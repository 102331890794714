import {
  AxiosRegistrationControllerClient,
  AxiosRepresentativeJobPosterControllerClient,
  RegistrationController_VerifyCredentialsDTO,
} from "src/generated/api_types";

const registrationClient = new AxiosRegistrationControllerClient();
const repJobPosterClient = new AxiosRepresentativeJobPosterControllerClient();

export async function verifyUserCredentials(userCredentials: RegistrationController_VerifyCredentialsDTO) {
  return registrationClient.verifyCredentials(userCredentials);
}

export async function preVerifyConsumerCreds(userCredentials: RegistrationController_VerifyCredentialsDTO) {
  return repJobPosterClient.preJobPostVerifyConsumerCreds(userCredentials);
}
