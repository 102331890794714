import MerlinBaseComponent from "src/pages/carinaAdmin/dashboard/merlin/MerlinBaseComponent";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import Constraint from "src/reusable_view_elements/Constraint";
import Section from "src/reusable_view_elements/Section";
import CivColors from "src/themes/civilization/CivColors";

const MerlinPage = () => {
  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.MERLIN}>
      <Section bgcolor={CivColors.white}>
        <Constraint columns={8}>
          <MerlinBaseComponent />
        </Constraint>
      </Section>
    </CarinaAdminMgmtContainer>
  );
};

export default MerlinPage;
