import { ConsumerApplicationDeclinedReason, ConsumerApplicationStatus, ContactMethod } from "src/generated/api_types";
import { number, object, string } from "yup";
// import 'yup-phone'

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const PREF_DAYS_TIMES_MAX = 500;
export const AGENCY_NOTES_MAX = 1000;
export const SCHEDULE_MAX = 140;
export const DECLINED_NOTES_MAX = 1000;

export const consumerValidationSchema = object({
  careRecipient: object({
    address: string().required("Please enter an address"),
  }),
  contact: object({
    firstName: string().required("Please enter a first name"),
    email: string().required("Please enter an email").email("Please enter a valid email address"),
    phone: string().matches(phoneRegExp, { message: "Please enter a valid phone number" }).nullable(),
    preferredMethod: string().when("phone", {
      is: (value: string | undefined) => {
        if (!value) return true;
        return value.length < 10;
      },
      then: string().oneOf(
        ["", ContactMethod.EMAIL],
        "Preferred contact method cannot be Call or Text without a phone number",
      ),
    }),
  }),
  hours: number().required("Please enter hours required").min(0, "Please enter a value greater than or equal to 0"),
  agencyNotes: string().max(AGENCY_NOTES_MAX, "Exceeded maximum space for notes").nullable(),
  schedule: string().max(SCHEDULE_MAX, "Exceeded maximum space for schedule").nullable(),
  status: string(),
  declinedReason: string()
    .nullable()
    .when("status", {
      is: ConsumerApplicationStatus.DECLINED,
      then: string().required(`Please choose a reason for why the care recipient declined`),
    }),
  declinedNotes: string()
    .nullable()
    .when("declinedReason", {
      is: ConsumerApplicationDeclinedReason.OTHER,
      then: string()
        .max(DECLINED_NOTES_MAX, "Exceeded maximum character limit for notes")
        .required(`Please tell us why the care recipient declined`),
    }),
});
