import { Redirect, Route, Switch } from "react-router-dom";
import ThreadMatchingSurvey from "src/pages/inbox/ThreadMatchingSurvey";
import MyDeletedJobList from "src/pages/medicaidAccount/consumerAccount/MyDeletedJobList";
import DeleteJobSurvey from "src/pages/medicaidAccount/consumerAccount/DeleteJobSurvey";
import { JobFormType } from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJobForm";
import MyJobList from "src/pages/medicaidAccount/consumerAccount/MyJobList";
import CreateEditRepostJob from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJob";
import { RenewJobPost } from "src/pages/medicaidAccount/consumerAccount/RenewJobPost";
import JobDetails from "src/pages/medicaidAccount/JobDetails";
import { JobListOrigin } from "src/pages/medicaidAccount/MedicaidContext";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import ClientTutorial from "src/pages/tutorials/ClientTutorial";

const MEDICAID_ACCOUNT_CONSUMER_PREFIX = "/homecare/medicaid/consumer";

export interface ConsumerRoutes {
  root: string;
  tutorial: string;
  matchSurvey: string;
  myJobList: string;
  myJobDetails: string;
  jobEdit: string;
  jobDelete: string;
  jobRenew: string;
  jobCreate: string;
  myDeletedJobs: string;
  myDeletedJobDetails: string;
  jobRepost: string;
}

export const Medicaid_Consumer_Dashboard_Routes: ConsumerRoutes = {
  root: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}`,
  tutorial: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/tutorial`,
  matchSurvey: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/match-survey/thread/:id`,

  myJobList: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/jobs`,
  myJobDetails: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/jobs/:id`,
  jobEdit: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/jobs/:id/edit`,
  jobDelete: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/jobs/:id/delete`,
  jobRenew: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/jobs/:id/renew`,
  jobCreate: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/job/create`,

  myDeletedJobs: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/deleted/jobs`,
  myDeletedJobDetails: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/deleted/jobs/:id`,
  jobRepost: `${MEDICAID_ACCOUNT_CONSUMER_PREFIX}/deleted/jobs/:id/repost`,
};

const ConsumerRouter = () => {
  return (
    <Switch>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.tutorial}>
        <ClientTutorial />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.matchSurvey}>
        <ThreadMatchingSurvey />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.myJobList}>
        <MyJobList />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.myJobDetails}>
        <JobDetails jobListView={JobListOrigin.MY_JOBS} />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.myDeletedJobs}>
        <MyDeletedJobList />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.myDeletedJobDetails}>
        <JobDetails jobListView={JobListOrigin.DELETED} />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.jobCreate}>
        <CreateEditRepostJob formType={JobFormType.JOB_CREATE} />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.jobEdit}>
        <CreateEditRepostJob formType={JobFormType.JOB_EDIT} />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.jobRepost}>
        <CreateEditRepostJob formType={JobFormType.JOB_REPOST} />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.jobDelete}>
        <DeleteJobSurvey />
      </Route>
      <Route exact path={Medicaid_Consumer_Dashboard_Routes.jobRenew}>
        <RenewJobPost />
      </Route>
      <Redirect to={MEDICAID_ACCOUNT_ROUTES.homepage} />
    </Switch>
  );
};

export default ConsumerRouter;
