import { Redirect, Route, Switch } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { ConsumerApplication_DTO_Response_Listing } from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import { FunderAdminConsumersList } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumersList";
import { FunderAdminConsumerDetails } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumerDetails";
import {
  getConsumer,
  getAllFunderConsumers as getConsumersType,
} from "src/pages/funderAdmin/dashboard/funder_admin_consumers/Queries";
import {
  FunderAdminDashboardContainer,
  FunderAdminDashboardTabs,
} from "src/pages/funderAdmin/dashboard/funder_admin_dashboard_components/FunderAdminDashboardContainer";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useFunderAdminConsumersDatatable,
  ClientsideDatatableContextProvider: FunderAdminConsumersDatatableContextProvider,
} = GenerateClientsideDatatable<
  ConsumerApplication_DTO_Response_Listing,
  typeof getConsumersType,
  (funderId: string) => void
>();

interface FunderAdminConsumerDashboardContainerProps {
  getAllFunderConsumers: typeof getConsumersType;
}

export const FunderAdminConsumerDashboard: React.FC<FunderAdminConsumerDashboardContainerProps> = ({
  getAllFunderConsumers,
}) => {
  const { userSession } = useUserSession();

  if (userSession == null) {
    return <Redirect to={ROUTES.login} />;
  }

  if (!userSession) {
    return <Redirect to={ROUTES.login} />;
  }
  return (
    <FunderAdminDashboardContainer tab={FunderAdminDashboardTabs.CONSUMERS}>
      <FunderAdminConsumersDatatableContextProvider
        getData={getAllFunderConsumers}
        getDataArgs={[userSession.funder.id]}
      >
        <Switch>
          <Route exact path={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_list}>
            <FunderAdminConsumersList />
          </Route>
          <Route exact path={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_details}>
            <FunderAdminConsumerDetails getConsumer={getConsumer} />
          </Route>
        </Switch>
      </FunderAdminConsumersDatatableContextProvider>
    </FunderAdminDashboardContainer>
  );
};
