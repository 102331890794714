import { Location } from "history";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { User_AccountStatus } from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import PageContainer from "src/reusable_view_elements/PageContainer";

interface AuthenticatedRouteProps extends RouteProps {
  userType?: UserType;
  accountStatus?: User_AccountStatus;
}

function isNotLoginFlowRoute(location: Location): boolean {
  return location.pathname.indexOf(ROUTES.loginSuccess) < 0;
}

const AuthenticatedRoute = ({ userType, accountStatus, ...props }: AuthenticatedRouteProps) => {
  const { isUserType, hasAccountStatus, userSession, loading } = useUserSession();
  const location = useLocation();

  if (loading) {
    return <PageContainer>Fetching user data...</PageContainer>;
  }

  if (!userSession) {
    sessionStorage.setItem("post-login-path", location.pathname);
    return <Redirect to={ROUTES.login} />;
  }

  if (isNotLoginFlowRoute(location)) {
    if (userType && !isUserType(userType)) {
      return <Redirect to={ROUTES.loginSuccess} />;
    }

    if (accountStatus && !hasAccountStatus(accountStatus)) {
      return <Redirect to={ROUTES.loginSuccess} />;
    }
  }

  return <Route {...props} />;
};

AuthenticatedRoute.defaultProps = {
  userType: null,
  accountStatus: null,
};

export default AuthenticatedRoute;
