import { Box, Grid, IconButton, MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Formik } from "formik";
import { useNotification } from "src/context/NotificationContext";
import { ApiUserType } from "src/generated/api_types";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { usersAdministrationController } from "src/pages/supportAdminAccount/ControllerClients";
import { getRoleDisplayName } from "src/pages/supportAdminAccount/dashboard/components/UserDetails";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyEmphasis, PanelTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

interface ChangeUserRoleModalProps {
  userId: string;
  currentRole: ApiUserType;
  showModal: boolean;
  handleModalClose: () => void;
  updateDisplayedRole: (newRole: string) => void;
}

const ChangeUserRoleModal = ({
  userId,
  currentRole,
  showModal,
  handleModalClose,
  updateDisplayedRole,
}: ChangeUserRoleModalProps) => {
  const { showSnackbar } = useNotification();

  const CHANGE_NOTES_MAX_LENGTH = 500;

  const formValidationSchema = object({
    newRole: string()
      .required("Please select one from the drop down list")
      .notOneOf([currentRole], "This is your current role"),
    changeNotes: string()
      .required("Please fill out the text box")
      .max(CHANGE_NOTES_MAX_LENGTH, "Exceeded maximum space for this field"),
  });

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Modal
        open={showModal}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick") {
            handleModalClose();
          }
        }}
        aria-labelledby="Change-user-role-modal"
        aria-describedby="Change User Role Modal"
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "#fff",
            border: 0,
            borderRadius: 2,
            color: "#000",
            top: "50%",
            left: 0,
            right: 0,
            padding: "26px 24px",
            transform: "translateY(-50%)",
            maxWidth: "584px",
            margin: "auto",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Grid container item justifyContent="space-between">
            <Grid item xs={10}>
              <PanelTitle id="change_user-role-modal-title" paragraph>
                Change role
              </PanelTitle>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <IconButton onClick={handleModalClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box mt={6} mb={6}>
            <Body style={{ marginBottom: 14 }}>Current role:</Body>
            <BodyEmphasis paragraph>{getRoleDisplayName(currentRole)}</BodyEmphasis>
          </Box>
          <Body>Select role</Body>

          <Formik
            initialValues={{ newRole: ApiUserType.MedicaidCaseManager, changeNotes: "" }}
            validationSchema={formValidationSchema}
            onSubmit={(values, helpers) => {
              usersAdministrationController
                .requestRoleChange({
                  userId,
                  currentRole,
                  newRole: values.newRole,
                  changeNotes: values.changeNotes,
                })
                .then((res) => {
                  helpers.setSubmitting(false);
                  showSnackbar(res.data, "success");
                  updateDisplayedRole(values.newRole);
                  handleModalClose();
                })
                .catch((error) => {
                  helpers.setSubmitting(false);
                  const errorStatus = error.response.status;
                  if ((errorStatus === 409 || errorStatus === 400) && typeof error.response.data === "string") {
                    helpers.setFieldError("email", error.response.data);
                  } else {
                    helpers.setFieldError("email", "Something went wrong. Please try again later");
                  }
                });
            }}
          >
            {({ isSubmitting, dirty }) => (
              <Form localizationReady={true}>
                <Box mt={1} mb={6}>
                  <FormTextField
                    select
                    name="newRole"
                    label="Role"
                    required={isRequiredField("newRole", formValidationSchema)}
                  >
                    {[ApiUserType.MedicaidCaseManager].map((role) => (
                      <MenuItem key={role} value={role}>
                        {getRoleDisplayName(role)}
                      </MenuItem>
                    ))}
                  </FormTextField>
                </Box>
                <Body>Enter the reason for the role change.</Body>
                <Box mt={3} mb={6}>
                  <FormTextField
                    minRows={4}
                    name="changeNotes"
                    label="Enter your message here"
                    multiline
                    helperText={""}
                    maximumlength={CHANGE_NOTES_MAX_LENGTH}
                    required={isRequiredField("changeNotes", formValidationSchema)}
                  />
                </Box>
                <Grid container justifyContent="flex-end">
                  <Button variant="text" type="button" onClick={handleModalClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={isSubmitting || !dirty}>
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChangeUserRoleModal;
