import { AxiosPPHCFunderDashboardAgencyAdminsControllerClient } from "src/generated/api_types";

const funderDashboardAgencyAdminsClient = new AxiosPPHCFunderDashboardAgencyAdminsControllerClient();

export async function getAllFunderAgencyAdmins() {
  return funderDashboardAgencyAdminsClient.getAgencyAdminsByFunder();
}

export async function getAgencyAdmin(id: string) {
  return funderDashboardAgencyAdminsClient.getAgencyAdmin(id);
}

export async function getFunder() {
  return funderDashboardAgencyAdminsClient.getFunder();
}
