import { Redirect, Route, Switch } from "react-router-dom";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import CaseWorkerTutorial from "src/pages/tutorials/CaseWorkerTutorial";

const MEDICAID_ACCOUNT_CASE_WORKER_PREFIX = "/homecare/medicaid/case-worker";

export interface CaseWorkerRouterProps {
  root: string;
  tutorial: string;
}

export const Medicaid_Case_Worker_Dashboard_Routes: CaseWorkerRouterProps = {
  root: `${MEDICAID_ACCOUNT_CASE_WORKER_PREFIX}`,
  tutorial: `${MEDICAID_ACCOUNT_CASE_WORKER_PREFIX}/tutorial`,
};

const CaseWorkerRouter = () => {
  return (
    <Switch>
      <Route exact path={Medicaid_Case_Worker_Dashboard_Routes.tutorial}>
        <CaseWorkerTutorial />
      </Route>
      <Redirect to={MEDICAID_ACCOUNT_ROUTES.homepage} />
    </Switch>
  );
};

export default CaseWorkerRouter;
