import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { ProviderProfileTagEnum } from "src/generated/api_types";
import { nsCommonToasts } from "src/i18n/Namespaces";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import { updateAvailability } from "src/pages/medicaidAccount/sharedComponents/Queries";
import Footer from "src/reusable_view_elements/Footer";

export const UpdateAvailability = () => {
  const { id: providerId, status: newAvailability } = useParams<{
    id: string;
    status: string;
  }>();
  const { showSnackbar } = useNotification();
  const [isRenewing, setIsRenewing] = useState(true);
  const { t } = useTranslation(nsCommonToasts);

  useEffect(() => {
    // eslint-disable-next-line
    console.log(providerId);
    // eslint-disable-next-line
    console.log(newAvailability);
    updateAvailability(providerId, newAvailability as ProviderProfileTagEnum)
      .then(() => {
        showSnackbar(t("info.availability_updated", `Availability successfully updated!`, { ns: nsCommonToasts }));
      })
      .finally(() => {
        setIsRenewing(false);
      });
  });

  if (isRenewing) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Availability Update</title>
        </Helmet>
        <main id="main-content">
          {/* TODO: Localize this string: https://github.com/CarinaWeb/CarinaCore/issues/992 */}
          <div>Updating availability...</div>
        </main>
        <Footer />
      </>
    );
  }

  return <Redirect to={Medicaid_Provider_Dashboard_Routes.viewMyProfile} />;
};
