import { Box, Grid, useMediaQuery } from "@mui/material";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import Illustration from "src/assets/images/emans-homecare-process-report.svg";
import DesktopSearchStep1 from "src/assets/tutorial/provider/desktop-4-1.jpg";
import DesktopSearchStep2a from "src/assets/tutorial/provider/desktop-4-2a.jpg";
import DesktopSearchStep2b from "src/assets/tutorial/provider/desktop-4-2b.jpg";
import DesktopSearchStep3 from "src/assets/tutorial/provider/desktop-4-3.jpg";
import DesktopSearchStep4 from "src/assets/tutorial/provider/desktop-4-4.jpg";
import MobileSearchStep1a from "src/assets/tutorial/provider/mobile-4-1a.jpg";
import MobileSearchStep1b from "src/assets/tutorial/provider/mobile-4-1b.jpg";
import MobileSearchStep2a from "src/assets/tutorial/provider/mobile-4-2a.jpg";
import MobileSearchStep2b from "src/assets/tutorial/provider/mobile-4-2b.jpg";
import MobileSearchStep3 from "src/assets/tutorial/provider/mobile-4-3.jpg";
import MobileSearchStep4a from "src/assets/tutorial/provider/mobile-4-4a.jpg";
import MobileSearchStep4b from "src/assets/tutorial/provider/mobile-4-4b.jpg";

const ReportMatch = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Share your match</SectionTitle>
            <Body paragraph>
              Help us keep our service free by sharing when you have matched with a consumer on Carina. This information
              is shared with our funders to measure our impact.
            </Body>
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Login and go to Messages</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Log into your account and go to your messages by clicking &#34;Messages&#34; at the top of the page or
                &#34;View&#34; in &#34;My Messages&#34;.
              </Body>
            </Constraint>
            <img src={DesktopSearchStep1} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Log into your account and go to your messages by clicking &#34;Menu&#34; at the top of the page, then
                click &#34;Messages&#34;.
              </Body>
            </Constraint>
            <img src={MobileSearchStep1a} width="100%" alt="" />
            <Box p={1} />
            <img src={MobileSearchStep1b} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Select the consumer you will work with</BodyTitle>
        <img src={onDesktop ? DesktopSearchStep2a : MobileSearchStep2a} width="100%" alt="" />
        <Constraint columns={6} pt={6} pb={4}>
          <Body>
            If you are discussing a specific job post with this consumer, double check that it is the right job post
            listed in the conversation.
          </Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep2b : MobileSearchStep2b} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Click on &#34;Working together?&#34;</BodyTitle>
        <img src={onDesktop ? DesktopSearchStep3 : MobileSearchStep3} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>4. Tell us about your experience using Carina</BodyTitle>

        <Constraint columns={6}>
          <Body paragraph>
            Your feedback helps us improve our services. Let us know about your experience connecting on Carina.
          </Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep4 : MobileSearchStep4a} width="100%" alt="" />
        {onMobile && (
          <>
            <Constraint columns={6} pt={6} pb={4}>
              <Body>Once you are done, click &#34;Submit&#34;.</Body>
            </Constraint>
            <img src={MobileSearchStep4b} width="100%" alt="" />
          </>
        )}
      </Segment>
    </>
  );
};

export default ReportMatch;
