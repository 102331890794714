import { FastFieldProps } from "formik";
import { useState } from "react";
import InputMask, { Props as InputMaskProps } from "react-input-mask";
import {
  ControllableFastField,
  ControllableFastFieldProps,
} from "src/reusable_view_elements/form_fields/ControllableFastField";
import TextField, { TextFieldProps } from "src/reusable_view_elements/form_fields/TextField";

type FastMaskTextFieldProps = {
  name: string;
  maskType: "phone" | "date";
  inputMaskProps?: Omit<InputMaskProps, "mask">; // Don't need `mask` because we are restricting to phone and date at the moment
} & TextFieldProps &
  Omit<ControllableFastFieldProps<any>, "languagePeek">;

const FastMaskTextField = (props: FastMaskTextFieldProps) => {
  const { controlledRerender, inputMaskProps, inputProps, maskType, ...rest } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  // If more patterns are added by design team, then define them here:
  const maskPattern = maskType === "date" ? "99/99/9999" : "999-999-9999";
  const maskPlaceholder = maskType === "date" ? "__/__/____" : "___-___-____";

  return (
    <ControllableFastField
      name={props.name}
      controlledRerender={controlledRerender}
      languagePeek={props.label || props.placeholder || "--"}
    >
      {({ field, meta, form }: FastFieldProps<string>) => {
        // 'isSubmitting' replaces 'form.isSubmitting' and/or 'meta.touched' which
        // values flip between false and true upon submission
        if (form.isSubmitting) setIsSubmitting(form.isSubmitting);
        return (
          <InputMask
            id={props.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase())}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            disabled={props.disabled}
            mask={maskPattern}
            placeholder={maskPlaceholder}
            {...inputMaskProps}
          >
            <TextField
              error={Boolean((isSubmitting || meta.touched) && meta.error)}
              helperText={(isSubmitting || meta.touched) && meta.error ? meta.error : props.helperText}
              inputProps={{
                ...inputProps,
                "data-testid": props.name,
              }}
              {...rest}
            />
          </InputMask>
        );
      }}
    </ControllableFastField>
  );
};

export default FastMaskTextField;
