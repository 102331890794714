import { ListItem, ListItemTextProps, SvgIconProps } from "@mui/material";
import { useUserSession } from "src/context/UserSessionContext";

interface Props extends ListItemTextProps {
  icon: React.ComponentType<SvgIconProps>;
  titleAccess?: string;
}

const IconListItemForCard = ({ icon: Component, primary, secondary, titleAccess }: Props) => {
  //TODO: Update style import and remove context to make this component agnostic of the context
  const { isAssistiveRoleView } = useUserSession();
  const style = {
    item: {
      padding: isAssistiveRoleView() ? "8px 0px" : "12px 0px",
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
  };
  return (
    <ListItem alignItems="flex-start" style={style.item}>
      <Component style={style.icon} titleAccess={titleAccess} />
      <div>
        {primary}
        {secondary}
      </div>
    </ListItem>
  );
};

export default IconListItemForCard;
