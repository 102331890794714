import { styled } from "@mui/material/styles";
import { ReactElement, useState } from "react";
import {
  Accordion as MAccordion,
  AccordionSummary as MAccordionSummary,
  AccordionDetails as MAccordionDetails,
  AccordionProps as MAccordionProps,
  AccordionSummaryProps as MAccordionSummaryProps,
  AccordionDetailsProps as MAccordionDetailsProps,
  AccordionSummaryProps,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { nsMedicaidSearch } from "src/i18n/Namespaces";
import Badge from "src/reusable_view_elements/Badge";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

/**
 * - Carina Docs: [Accordion](https://www.notion.so/carinatechnology/Component-Library-29e1351c3d2d415097a2e978ae641b3c)
 * - Built on top of Material UI [Accordion API](https://material-ui.com/api/accordion/)
 * - Inherits [Paper API](https://material-ui.com/api/paper/)
 */
export const Accordion = styled(MAccordion)(() => ({
  backgroundColor: "inherit",
  border: "none",
  boxShadow: "none",
  "&:not(:first-child)": {
    borderBottom: `1px solid ${CivColors.mediumGray}`,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: "auto",
  },
}));

/**
 * - Carina Docs: [Accordion](https://www.notion.so/carinatechnology/Component-Library-29e1351c3d2d415097a2e978ae641b3c)
 * - Built on top of Material UI [AccordionSummary API](https://material-ui.com/api/accordion-summary/) and
 * [Accordion](https://material-ui.com/components/accordion/)
 * - Inherits Material UI's [ButtonBase API](https://material-ui.com/api/button-base/)
 */
const StyledAccordionSummary = styled(MAccordionSummary)(() => ({
  border: "none",
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: "24px 0",
    "&.Mui-expanded": {
      margin: "24px 0",
    },
  },
}));

export const AccordionSummary = (props: AccordionSummaryProps) => {
  return <StyledAccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} {...props} />;
};

// Made to support the Phone Accordion Component designed in story #184775920
const StyledAccordionSummaryWithIcon = styled(MAccordionSummary)(() => ({
  border: "none",
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
    "&.Mui-expanded": {
      margin: "8px 0",
    },
  },
}));

export const AccordionSummaryWithIcon = (props: AccordionSummaryProps) => {
  return <StyledAccordionSummaryWithIcon expandIcon={<ExpandMoreIcon color="primary" />} {...props} />;
};

/**
 * - Carina Docs: [Accordion](https://www.notion.so/carinatechnology/Component-Library-29e1351c3d2d415097a2e978ae641b3c)
 * - Built on top of Material UI [AccordionDetails](https://material-ui.com/api/accordion-details/) and
 * [Accordion](https://material-ui.com/components/accordion/)
 */
export const AccordionDetails = styled(MAccordionDetails)(() => ({
  display: "block",
  padding: 0,
}));

// Made to support the Phone Accordion Component designed in story #184775920
export const AccordionDetailsWithIcon = styled(MAccordionDetails)(() => ({
  display: "block",
  paddingLeft: 30,
  paddingY: 0,
}));

const StyledBadge = styled(Badge)(() => ({
  lineHeight: 1.65,
  backgroundColor: CivColors.coreDarkNavy,
  fontSize: "0.7rem",
  alignSelf: "flex-start",
}));

interface AccordionListProps
  extends Partial<{
    AccordionProps?: MAccordionProps;
    AccordionSummaryProps?: MAccordionSummaryProps;
    AccordionDetailsProps?: MAccordionDetailsProps;
  }> {
  data: Array<{ title: string; body: string | ReactElement; count?: number }>;
  titleTypography?: "BodyEmphasis" | "BodyTitle";
  showListItemBadge?: boolean;
}

/**
 * - Carina Docs:
 * [AccordionList](https://www.notion.so/carinatechnology/Component-Library-29e1351c3d2d415097a2e978ae641b3c)
 */
export const AccordionList = (props: AccordionListProps) => {
  const { t } = useTranslation([nsMedicaidSearch]);
  const [expanded, setExpanded] = useState<string | false>(false);
  // Behavior: Only show one expanded panel at a time
  const handleChange = (panel: string) => (_event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const titleType = props.titleTypography || "BodyTitle";
  return (
    <>
      {props.data.map((item, i) => {
        // Hide travel time on large screens since it's already next to address search
        if (isLargeScreen && item.title === t("max_travel_time.label", { ns: nsMedicaidSearch })) {
          return null;
        }
        return (
          <Accordion
            key={i}
            expanded={expanded === i.toString()}
            onChange={handleChange(i.toString())}
            {...props.AccordionProps}
          >
            <AccordionSummary {...props.AccordionSummaryProps}>
              {titleType === "BodyEmphasis" && <BodyEmphasis style={{ width: "100%" }}>{item.title}</BodyEmphasis>}
              {titleType === "BodyTitle" && <BodyTitle>{item.title}</BodyTitle>}
              {typeof item.body === "object" &&
                props.showListItemBadge &&
                item.count != undefined &&
                item.count > 0 && <StyledBadge number={item.count} />}
            </AccordionSummary>
            <AccordionDetails {...props.AccordionDetailsProps}>
              {/* Break up string at newlines and wrap with paragraph */}
              {typeof item.body === "string"
                ? item.body.split(/\r|\n/).map((paragraph, j) => (
                    //eslint-disable-next-line
                    <Body paragraph key={j}>
                      {paragraph}
                    </Body>
                  ))
                : item.body}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
