import { Box, IconButton, Menu } from "@mui/material";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { ActiveUserListDTO } from "src/generated/api_types";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MessageDialog from "src/pages/supportAdminAccount/dashboard/components/MessageDialog";
import { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import { impersonateUser } from "src/reusable_view_elements/StartImpersonationButton";

const ActiveUserListActionMenuButton = (props: {
  data: ActiveUserListDTO;
  callingPageUrl: string;
  dashboardType: DashboardType;
}) => {
  const { data, dashboardType } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { reloadAuthState, loading: loadingUserSession, isUserType } = useUserSession();
  const history = useHistory();
  const [showMessageDialog, setMessageDialog] = useState<boolean>(false);

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MessageDialog
        name={data.firstName}
        open={showMessageDialog}
        onClose={() => setMessageDialog(false)}
        setDialogVisibility={setMessageDialog}
        recipientId={data.id}
      />
      <IconButton aria-controls="user-action-menu" aria-haspopup="true" onClick={handleIconClick} size="large">
        <AddRoundedIcon />
      </IconButton>
      <Menu id="user-action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem
          onClick={async () => {
            await impersonateUser(data.id, reloadAuthState, loadingUserSession, history, props.callingPageUrl);
          }}
        >
          Impersonate {data.firstName} {data.lastName}
        </MenuItem>
        {isUserType(UserType.UserSupportManager) && (
          <Box>
            <MenuItem onClick={handleCloseMenu} disabled>
              Update Email
            </MenuItem>
            {dashboardType !== DashboardType.CHILDCARE && (
              <MenuItem onClick={() => setMessageDialog(true)}>
                Message {data.firstName} {data.lastName}
              </MenuItem>
            )}
            <MenuItem onClick={handleCloseMenu} disabled>
              Reset Password
            </MenuItem>
            {dashboardType !== DashboardType.CHILDCARE && (
              <MenuItem onClick={handleCloseMenu} disabled>
                Block
              </MenuItem>
            )}
            <MenuItem onClick={handleCloseMenu} disabled>
              Deactivate
            </MenuItem>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default ActiveUserListActionMenuButton;
