import {
  Box,
  CircularProgress,
  Grid,
  GridProps,
  IconButton,
  keyframes,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { UserAlertType } from "src/generated/api_types";
import { nsCommonBanners, nsCommonFormsBtns } from "src/i18n/Namespaces";
import { AlertAlarmBellRingIcon } from "src/assets/icons/StreamlineIcons";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getGenderEquityBlogPost } from "src/pages/blog/BlogPosts";
import { userControllerClient } from "src/pages/medicaidAccount/portal/AccountPortalMain";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    display: none;
    z-index: 1;
  }
  99% {
    transform: translateY(0px);
    display: block;
    z-index: 1;
  }
  100% {
    transform: translateY(0px);
    display: block;
    z-index: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0px);
    display: block;
    z-index: 1;
  }
  99% {
    transform: translateY(-100%);
    display: block;
    z-index: 1;
  }
  100% {
    transform: translateY(-100%);
    display: none;
    z-index: 1;
  }
`;

const StyledBanner = styled("div")<{ view?: ViewEnvOptionsBannerEnum; safariHide: boolean }>`
  z-index: 1;
  position: relative;

  ${({ view }) =>
    view === ViewEnvOptionsBannerEnum.VISIBLE &&
    `
    animation: ${slideIn} 0.4s ease-in-out;
    animation-delay: 0.2s;
    animation-fill-mode: both;
  `}

  ${({ view }) =>
    view === ViewEnvOptionsBannerEnum.HIDDEN &&
    `
    animation: ${slideOut} 0.3s ease-in-out;
    animation-delay: 0s;
    animation-fill-mode: both;
  `}

  ${({ view, safariHide }) =>
    (view === undefined || safariHide) &&
    `
    display: none;
  `}

  ${({ view, safariHide }) =>
    view !== undefined &&
    !safariHide &&
    `
    display: flex;
  `}
`;

const useStyles = () => {
  return useMemo(
    () => ({
      bannerDefault: "bannerDefault", // This will not be used but included for completeness
    }),
    [],
  );
};

export default useStyles;

interface BannerProps {
  view?: ViewEnvOptionsBannerEnum;
  setView: React.Dispatch<React.SetStateAction<ViewEnvOptionsBannerEnum | undefined>>;
}

export enum ViewEnvOptionsBannerEnum {
  HIDDEN,
  VISIBLE,
}

export const NewEnvOptionsBanner: React.FC<BannerProps & GridProps> = ({ view, setView }) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const { t, ready } = useTranslation([nsCommonBanners, nsCommonFormsBtns]);
  const [safariHide, setSafariHide] = useState(false);

  const handleClose = async () => {
    try {
      await userControllerClient.updateUserAlert({ alertType: UserAlertType.LGBT_BANNER, acknowledged: true });
    } catch (e) {
      //eslint-disable-next-line
      console.error(`Something went wrong while updating user alert acknowledgement: ${e}`);
    } finally {
      setView(ViewEnvOptionsBannerEnum.HIDDEN);
      // The next delay has to be the same set in animatedWrapperOut.animation. This is for Safari browser, in which
      // the space where the dynamic banner goes remains empty and does not close after the banner is hidden. This
      // also sets view back to undefined so banner is hidden when user navigates back to portal page.
      setTimeout(() => {
        setSafariHide(true);
        setView(undefined);
      }, 300);
    }
  };

  const classes = useStyles();

  return (
    <StyledBanner view={view} safariHide={safariHide} className={classes.bannerDefault}>
      <Grid container style={{ backgroundColor: CivColors.paleBlue, padding: "24px 0px 24px 0px" }}>
        <>
          <Grid item xs={2} sm={1} style={{ textAlign: "right", paddingRight: desktopSize ? 24 : 12 }}>
            <AlertAlarmBellRingIcon />
          </Grid>
          <Grid item container xs={8} sm={10} style={{ flexDirection: "column" }}>
            {!ready ? (
              <>
                <Box textAlign="center" margin="0px auto">
                  <CircularProgress color="secondary" />
                </Box>
              </>
            ) : (
              <>
                <Grid item>
                  <BodyEmphasis style={{ paddingBottom: 12 }}>
                    {t("announcing_features.label", { ns: nsCommonBanners })}
                  </BodyEmphasis>
                  <Body paragraph>{t("announcing_features_new_categories.description", { ns: nsCommonBanners })}</Body>
                  <Body paragraph>{t("announcing_features_go_to.description", { ns: nsCommonBanners })}</Body>
                  <Trans
                    t={t}
                    i18nKey="announcing_features_learn_more.description"
                    ns={nsCommonBanners}
                    components={{
                      bloglink: <MuiLink href={getGenderEquityBlogPost(i18next.language)} target="_blank" />,
                    }}
                    parent={Body}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={2} sm={1} style={{ textAlign: "center" }}>
            <IconButton onClick={handleClose} size="large">
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </>
      </Grid>
    </StyledBanner>
  );
};
