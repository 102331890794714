import { ButtonProps as MuiButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import { Button } from "src/reusable_view_elements/Button";

interface MessageFollowUpButtonProps extends Omit<MuiButtonProps, "children" | "onClick"> {
  threadIds: string[];
  otherUserId: string;
  onMessageClick: () => void;
}

/**
 - If threadIds.length == 0, show "Message", which opens the initial message modal
 - If threadIds.length == 1, show "Follow Up", which links to the thread
 - If threadIds.length > 1, show "Follow Up", which links to the inbox page that lists all threads with the other user
 */
const MessageFollowUpButton = ({
  threadIds,
  otherUserId,
  onMessageClick,
  ...buttonProps
}: MessageFollowUpButtonProps) => {
  const { t, ready } = useTranslation([nsCommonFormsBtns]);
  const history = useHistory();

  if (!ready) {
    return <></>;
  }

  if (threadIds.length === 0) {
    return (
      <Button {...buttonProps} variant={buttonProps.variant || "contained"} type="button" onClick={onMessageClick}>
        {t("button.message", { ns: nsCommonFormsBtns })}
      </Button>
    );
  }

  const inboxPath =
    threadIds.length === 1
      ? generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: threadIds[0] })
      : generatePath(MEDICAID_INBOX_ROUTES.threadsWithUser, { userId: otherUserId });

  return (
    <Button
      {...buttonProps}
      variant={buttonProps.variant || "contained"}
      type="button"
      onClick={() => history.push(inboxPath)}
    >
      {t("button.follow_up", { ns: nsCommonFormsBtns })}
    </Button>
  );
};

export default MessageFollowUpButton;
