import {
  Box,
  Button as MuiButton,
  ButtonProps,
  Drawer,
  IconButton,
  Popover as MuiPopover,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropsWithChildren, ReactNode, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

/***********************************************************************************************************************
 ******************************************** POPOVER: HOW TO USE IT ***************************************************
 *    <Popover
 *       contentTitle="Title of the popover at the top, that goes in BodyEmphasis style and it's optional"
 *       content={<Body>This is the body of the popover that goes below the title</Body>}
 *       contentId="notes-popover"
 *       data-testid="notes-icon"
 *     >
 *         <QuestionCircleIcon />  or <Body>I'm a popover trigger</Body> or "I'm a popover trigger"
 *     </Popover>
 **********************************************************************************************************************/

type PopoverTextProps = {
  content: ReactNode;
  contentTitle?: string;
  contentId: string;
} & Omit<ButtonProps, "content" | "contentTitle" | "contentId">;

const Trigger = styled(MuiButton)({
  //Styling of Text triggers
  minWidth: 0,
  margin: 0,
  padding: 0,
  paddingBottom: "5px", //When the trigger is plane text. This is to match the padding of neighboring text
  height: "24px",
  boxSizing: "border-box",
  backgroundColor: "inherit",
  "&:not(:has(svg))": {
    //Styling for triggers that don't contain svg (e.g. IconButton)
    border: `2px solid transparent`,
    borderRadius: 1,
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    //When plain text is passed in instead of a Typography component, the 'Body' style is applied:
    fontFamily: "Europa-Regular, sans-serif",
    fontSize: "1.0rem",
    lineHeight: 1.2,
    fontVariantLigatures: "no-contextual",
    verticalAlign: "center",
  },

  //Styling of IconButton triggers
  "& .MuiSvgIcon-root": {
    width: 24,
    height: 24,
    margin: 0,
    padding: 0,
    color: CivColors.coreDarkNavy,
    border: `2px solid transparent`,
    boxSizing: "content-box",
  },

  //Styling of triggers when hovered
  "&:hover": {
    backgroundColor: "inherit",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    //Styling of IconButton triggers when hovered
    "& .MuiSvgIcon-root": {
      color: CivColors.coreDarkNavy,
    },
  },

  //Styling of triggers when focused
  "&.Mui-focusVisible": {
    backgroundColor: "inherit",
    //Styling of text triggers when focused
    "&:not(:has(svg))": {
      border: `2px solid ${CivColors.coreDarkNavy}`,
      borderRadius: 4,
    },
    //Styling of IconButton triggers when focused
    "& .MuiSvgIcon-root": {
      color: CivColors.coreDarkNavy,
      border: `2px solid ${CivColors.coreDarkNavy}`,
    },
  },
});

const StyledPopover = styled(MuiPopover)({
  maxWidth: 800,
  "& .MuiPaper-root": {
    minWidth: 400,
    padding: "24px",
  },
});

const StyledDrawer = styled(Drawer)({
  "& .MuiPaper-root": {
    padding: "24px",
  },
});

const Popover = ({
  content,
  contentTitle,
  contentId,
  children,
  ...buttonProps
}: PropsWithChildren<PopoverTextProps>) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const [contentIsOpen, setContentOpen] = useState<boolean>(false);
  const [popupAnchor, setPopupAnchor] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setContentOpen(true);
    if (desktopSize) {
      setPopupAnchor(event.currentTarget);
    }
  };

  const handleClose = () => {
    setContentOpen(false);
    if (desktopSize) {
      setPopupAnchor(null);
    }
  };

  const Header = () => (
    <Box justifyContent="space-between" display="flex" mb="12px">
      <BodyEmphasis>{contentTitle}</BodyEmphasis>
      <IconButton
        aria-label="close-popover"
        sx={{ height: "24px", width: "24px", padding: 0, marginLeft: "10px" }}
        onClick={handleClose}
        size="large"
      >
        <CloseRoundedIcon />
      </IconButton>
    </Box>
  );

  return (
    <>
      <Trigger
        onClick={handleOpen}
        disableRipple
        aria-owns={contentIsOpen ? contentId : undefined}
        aria-haspopup
        {...buttonProps}
      >
        {children}
      </Trigger>
      {desktopSize ? (
        <StyledPopover
          id={contentId}
          open={contentIsOpen}
          onClose={handleClose}
          anchorEl={popupAnchor}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Header />
          {content}
        </StyledPopover>
      ) : (
        <StyledDrawer id={contentId} anchor="bottom" open={contentIsOpen} onClose={handleClose}>
          <Header />
          {content}
        </StyledDrawer>
      )}
    </>
  );
};

export default Popover;
