import { AxiosPPHCCarinaAdminDashboardFundersControllerClient } from "src/generated/api_types";

const carinaAdminDashboardFunderClient = new AxiosPPHCCarinaAdminDashboardFundersControllerClient();

export async function getAllFunderAdmins() {
  return carinaAdminDashboardFunderClient.listFunderAdmins();
}

export async function getFunderAdmin(id: string) {
  return carinaAdminDashboardFunderClient.getFunderAdmin(id);
}
