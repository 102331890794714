import { Redirect, Route, Switch } from "react-router-dom";
import { asAgencyAdminResponseDTO, useUserSession } from "src/context/UserSessionContext";
import { ConsumerApplication_DTO_Response_Listing } from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import { ConsumerDetails } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerDetails";
import {
  getConsumer,
  getConsumers as getConsumersType,
  updateConsumer,
  getAllAgencyAdmins,
} from "src/pages/agencyAdmin/dashboard/consumers/Queries";
import {
  DashboardContainer,
  DashboardTabs,
} from "src/pages/agencyAdmin/dashboard/dashboard_components/DashboardContainer";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { ConsumersList } from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { ConsumersListPaging } from "./ConsumersListPaging";

export const {
  useClientsideDatatable: useConsumersDatatable,
  ClientsideDatatableContextProvider: ConsumersDatatableContextProvider,
} = GenerateClientsideDatatable<
  ConsumerApplication_DTO_Response_Listing,
  typeof getConsumersType,
  (agencyId: string) => void
>();

interface ConsumerDashboardContainerProps {
  getConsumers: typeof getConsumersType;
}

export const ConsumerDashboard: React.FC<ConsumerDashboardContainerProps> = ({ getConsumers }) => {
  const { userSession } = useUserSession();

  if (userSession == null) {
    return <Redirect to={ROUTES.login} />;
  }

  const agencyAdmin = asAgencyAdminResponseDTO(userSession);

  if (!agencyAdmin) {
    return <Redirect to={ROUTES.login} />;
  }

  return (
    <DashboardContainer tab={DashboardTabs.CONSUMERS}>
      <ConsumersDatatableContextProvider getData={getConsumers} getDataArgs={[agencyAdmin.agency.id]}>
        <Switch>
          <Route exact path={DASHBOARD_ROUTES.consumer_list} component={ConsumersList} />
          <Route exact path={DASHBOARD_ROUTES.consumer_details}>
            <ConsumerDetails
              getAllAgencyAdmins={getAllAgencyAdmins}
              getConsumer={getConsumer}
              updateConsumer={updateConsumer}
            />
          </Route>
          <Route exact path={DASHBOARD_ROUTES.consumer_list_paging} component={ConsumersListPaging} />
        </Switch>
      </ConsumersDatatableContextProvider>
    </DashboardContainer>
  );
};
