import { Grid } from "@mui/material";
import { withFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails } from "src/generated/api_types";
import {
  AgencyAdminForm,
  AgencyAdminFormFields,
  AgencyAdminFormProps,
} from "src/pages/funderAdmin/dashboard/funder_admin_agencies/FunderAdminAgencyAdminForm";
import {
  getAgencyAdmin as getAgencyAdminType,
  getFunder as getFunderType,
} from "src/pages/funderAdmin/dashboard/funder_admin_agencies/Queries";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { StartImpersonationButton } from "src/reusable_view_elements/StartImpersonationButton";

type AgencyAdminDetailsProps = {
  getAgencyAdmin: typeof getAgencyAdminType;
  getFunder: typeof getFunderType;
};

type AgencyAdminDetailsFormFields = AgencyAdminFormFields & {
  generatedPassword?: string;
};

export const FunderAdminAgencyAdminDetails: React.FC<AgencyAdminDetailsProps> = ({ getAgencyAdmin, getFunder }) => {
  const { showSnackbar } = useNotification();
  const [agencyAdmin, setAgencyAdmin] = useState<PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails>();
  const [isFetching, setIsFetching] = useState(true);

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    getAgencyAdmin(id)
      .then((res) => {
        if (res.status === 200) {
          setAgencyAdmin(res.data);
        } else {
          showSnackbar("Error loading agency admin. Please try again.", "error");
        }
      })
      .catch(() => {
        showSnackbar("Error loading agency admin. Please try again.", "error");
      })
      .finally(() => setIsFetching(false));
  }, [id]);

  if (isFetching) {
    return <>Loading Agency Admin...</>;
  }

  if (!agencyAdmin) {
    return <>Error loading Agency Admin!</>;
  }

  return (
    <>
      <DashReturnLink to={FUNDER_ADMIN_DASHBOARD_ROUTES.agencyadmins_list}>
        Return to Agency Admins Dashboard
      </DashReturnLink>

      <Constraint columns={8} paddingTop={4}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <SectionTitle>
              {agencyAdmin.firstName} {agencyAdmin.lastName}
            </SectionTitle>
          </Grid>
          <Grid item>
            <StartImpersonationButton userId={id} userDisplayName={agencyAdmin.firstName} />
          </Grid>
        </Grid>
      </Constraint>

      <Constraint columns={8} paddingTop={2} paddingBottom={14}>
        <WrappedAgencyAdminInviteForm data={agencyAdmin} getFunder={getFunder} editable={false} />
      </Constraint>
    </>
  );
};
const WrappedAgencyAdminInviteForm = withFormik<
  AgencyAdminFormProps<PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails, AgencyAdminDetailsFormFields>,
  AgencyAdminDetailsFormFields
>({
  mapPropsToValues: ({ data: agencyAdmin }) => ({
    firstName: agencyAdmin?.firstName || "",
    lastName: agencyAdmin?.lastName || "",
    phone: "",
    email: agencyAdmin?.email || "",
    agencyId: agencyAdmin?.agency.id || "",
    agencyZip: "",
    agencyFunder: agencyAdmin?.agency.funder.segment || "",
    agencyName: agencyAdmin?.agency.name || "",
  }),
  handleSubmit: () => {},
})(AgencyAdminForm);
