import { Box } from "@mui/material";
import { RoleType, ThreadListDTO } from "src/generated/api_types";
import ThreadListCard from "src/pages/inbox/inboxComponents/ThreadListCard";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import { generatePath, useHistory } from "react-router-dom";

interface ThreadListGridProps {
  threads: ThreadListDTO[];
}

// Shared across inbox tab views: All, Unread, Archived, Starred, Job Response Threads
const ThreadListGrid = (props: ThreadListGridProps) => {
  const history = useHistory();
  return (
    <Constraint columns={12}>
      <Box paddingBottom={4} paddingTop={4}>
        {props.threads.map((thread) => (
          <ThreadListCard
            key={thread.threadId}
            onClick={() => {
              history.push(generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: thread.threadId }), {
                from: history.location.pathname, // Helps with back button logic
              });
            }}
            avatarId={thread.otherUser.roleType === RoleType.PROVIDER ? thread.otherUser.id : undefined}
            hasImg={thread.otherUser.roleType === RoleType.PROVIDER ? thread.otherUser.hasImg : undefined}
            date={thread.lastMessageDate}
            firstName={thread.otherUser.firstName}
            lastInitial={thread.otherUser.roleType !== RoleType.CONSUMER ? thread.otherUser.lastNameInitial : undefined}
            organization={thread.otherUser.organizationName}
            roleType={thread.otherUser.roleType}
            clientName={
              thread.otherUser.roleType === RoleType.SUPPORT ||
              thread.otherUser.roleType === RoleType.MEDICAID_CASE_MANAGER ||
              thread.otherUser.roleType === RoleType.PROXY_PROVIDER ||
              thread.otherUser.roleType === RoleType.MEDICAID_REFERRAL_COORDINATOR
                ? thread.jobPost?.consumerFirstName
                : undefined
            }
            jobNum={thread.jobPost?.jobPostNumber}
            jobId={thread.jobPost?.jobPostId}
            jobIsResolved={thread.jobPost?.jobIsResolved}
            message={thread.lastMessageText}
            unreadMessages={thread.unreadMessageCount}
          />
        ))}
      </Box>
    </Constraint>
  );
};

export default ThreadListGrid;
