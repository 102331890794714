import { Box, LinearProgress, LinearProgressProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { nsMedicaidOnboardingMain } from "src/i18n/Namespaces";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const BasicProgressBar = styled(LinearProgress)({
  height: 17,
  border: `1px solid ${CivColors.coreOrange}`,
  borderRadius: 8,
  marginTop: 8,
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "inherit",
  },
  "& .MuiLinearProgress-bar": {
    backgroundColor: CivColors.coreOrange,
  },
});

const ProgressBar = (props: LinearProgressProps & { current: number; max: number; uniqueId: string }) => {
  const { t } = useTranslation([nsMedicaidOnboardingMain]);
  const { uniqueId, ...rest } = props;

  let { current, max } = props;
  if (current < 0) current = 0;
  if (max < 0) max = 1;
  if (current > max) current = max;
  let hundredScaledValue = 0;
  hundredScaledValue = Math.round((current / max) * 100);
  return (
    <Box display="inline" alignItems="center">
      <Box minWidth={35}>
        <BodyEmphasis id={props.uniqueId}>{`${hundredScaledValue}% ${t("complete.label").toLowerCase()}`}</BodyEmphasis>
      </Box>
      <Box width="100%" mr={1}>
        <BasicProgressBar variant="determinate" {...rest} value={hundredScaledValue} aria-labelledby={props.uniqueId} />
      </Box>
    </Box>
  );
};

export default ProgressBar;
