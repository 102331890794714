import { Box, CircularProgress, Grid } from "@mui/material";
import { AxiosError } from "axios";
import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import {
  ChildcareProviderProfileResponseDetailDTO,
  OnboardingStepType,
  ProviderProfileTagEnum,
} from "src/generated/api_types";
import { nsChildcareProviderProfile, nsCommonCTAbtns, nsCommonFormsBtns } from "src/i18n/Namespaces";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { ChildcareProviderProfileControllerClient } from "src/pages/childcare/ControllerClients";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import { getInvitedChildcareProviderDetails } from "src/pages/supportAdminAccount/forms/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import Footer from "src/reusable_view_elements/Footer";
import {
  FormAddressField,
  FormRadioGroupField,
  FormTextField,
  FormMultiSelectChipsField,
} from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import { FormCheckboxGroupField } from "src/reusable_view_elements/form_fields/FormCheckboxGroupField";
import LongForm from "src/reusable_view_elements/form_fields/LongForm";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import ChildcareNavbar from "src/reusable_view_elements/navbars/ChildcareNavbar";
import UserNavbar from "src/reusable_view_elements/navbars/UserNavbar";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField, phoneRegExp } from "src/utilities/GeneralUtilities";
import { mixed, object, string } from "yup";

export enum CCProfileForm {
  CREATE,
  EDIT,
}

interface ChildcareProviderProfileProps {
  formType: CCProfileForm;
}

const ProfileForm: React.FC<ChildcareProviderProfileProps> = ({ formType }) => {
  const { t, ready } = useTranslation([nsCommonCTAbtns, nsChildcareProviderProfile]);

  const { addCompletedStep } =
    formType === CCProfileForm.CREATE
      ? useOnboardingContext()
      : {
          addCompletedStep: () => {},
        };
  const { userSession } = useUserSession();
  const [profile, setProfile] = useState<ChildcareProviderProfileResponseDetailDTO>({
    about: "",
    address: "",
    businessName: "",
    businessPhone: "",
    email: userSession?.email || "",
    firstName: "",
    id: "",
    lastName: "",
    licenseNumber: "",
    personalPhone: "",
    searchStatus: ProviderProfileTagEnum.SEEKING,
    smsConsent: false,
    tagGroups: { LANGUAGE: [], SCHEDULE: [], SPECIALIZED_SKILL: [] },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string>();
  const { showSnackbar } = useNotification();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setLoadingError(undefined);

    (async () => {
      try {
        if (formType === CCProfileForm.CREATE) {
          const res1 = await getInvitedChildcareProviderDetails();
          if (res1.data)
            setProfile({
              ...profile,
              firstName: res1.data.firstName,
              lastName: res1.data.lastName,
              email: res1.data.email,
              licenseNumber: res1.data.licenseNumber,
            });
        } else {
          const res2 = await ChildcareProviderProfileControllerClient.getChildcareProviderProfile(
            userSession?.id || "",
          );
          if (res2.data) setProfile(res2.data);
        }
      } catch (e: any) {
        if (Object.prototype.hasOwnProperty.call(e, "message")) {
          setLoadingError(e.message);
        }
      }
      setLoading(false);
    })();
  }, []);

  interface EditProviderProfileForm {
    openSlots: ProviderProfileTagEnum;
    licenseId: string;
    businessName: string;
    firstName: string;
    lastName: string;
    businessAddress: string;
    languages: ProviderProfileTagEnum[];
    operatingHours: ProviderProfileTagEnum[];
    careSkills: ProviderProfileTagEnum[];
    businessDescription: string;
    email: string;
    businessPhone: string;
    mobilePhone: string;
    smsConsent: boolean;
  }

  const getSubmitBtnTitle = (submitting: boolean, form: CCProfileForm): string => {
    if (submitting) {
      return form === CCProfileForm.CREATE
        ? t("submitting", { ns: nsCommonCTAbtns })
        : t("saving profile", { ns: nsChildcareProviderProfile });
    }
    return form === CCProfileForm.CREATE
      ? t("submit", { ns: nsCommonCTAbtns })
      : t("save profile", { ns: nsChildcareProviderProfile });
  };

  const initialValues: EditProviderProfileForm = {
    openSlots: profile?.searchStatus || ProviderProfileTagEnum.SEEKING,
    licenseId: profile?.licenseNumber || "",
    businessName: profile?.businessName || "",
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    businessAddress: profile?.address || "",
    languages: profile?.tagGroups.LANGUAGE || [],
    operatingHours: profile?.tagGroups.SCHEDULE || [],
    careSkills: profile?.tagGroups.SPECIALIZED_SKILL || [],
    businessDescription: profile?.about || "",
    email: profile?.email || "",
    businessPhone: profile?.businessPhone || "",
    mobilePhone: profile?.personalPhone || "",
    smsConsent: profile?.smsConsent || false,
  };

  const BUSINESS_DESC_MAX_LENGTH = 500;
  const validationSchema = object({
    openSlots: mixed().oneOf([ProviderProfileTagEnum.SEEKING, ProviderProfileTagEnum.NOTSEEKING]),
    licenseId: string(),
    businessName: string().optional(),
    firstName: string().required(t("field required", { ns: nsChildcareProviderProfile })),
    lastName: string().required(t("field required", { ns: nsChildcareProviderProfile })),
    businessAddress: string().required(t("business address reminder", { ns: nsChildcareProviderProfile })),
    languages: string().required(t("field required", { ns: nsChildcareProviderProfile })),
    operatingHours: string().required(t("field required", { ns: nsChildcareProviderProfile })),
    careSkills: string().required(t("field required", { ns: nsChildcareProviderProfile })),
    businessDescription: string()
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(BUSINESS_DESC_MAX_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    email: string(),
    businessPhone: string().optional(),
    mobilePhone: string().when("smsConsent", {
      is: true,
      then: string()
        .required(t("mobile phone error", { ns: nsChildcareProviderProfile }))
        .matches(phoneRegExp, { message: t("mobile phone error", { ns: nsChildcareProviderProfile }) }),
      otherwise: string().nullable(),
    }),
  });

  const handleSubmit = (values: EditProviderProfileForm, { setSubmitting }: FormikHelpers<EditProviderProfileForm>) => {
    const dto = {
      about: values.businessDescription,
      businessAddress: values.businessAddress,
      businessName: values.businessName || `${values.firstName} ${values.lastName.substr(0, 1)}`,
      businessPhone: values.businessPhone === "" ? undefined : values.businessPhone.replace(/[^0-9]/g, ""),
      firstName: values.firstName,
      lastName: values.lastName,
      licenseNumber: values.licenseId,
      personalPhone: values.mobilePhone === "" ? undefined : values.mobilePhone.replace(/[^0-9]/g, ""),
      searchStatus: values.openSlots,
      smsConsent: values.smsConsent,
      tags: [...values.careSkills, ...values.languages, ...values.operatingHours],
    };
    if (formType === CCProfileForm.CREATE) {
      ChildcareProviderProfileControllerClient.createChildcareProviderProfile(dto)
        .then(() => {
          if (formType === CCProfileForm.CREATE) {
            addCompletedStep(OnboardingStepType.PROFILE, values);
          }
          history.push(ONBOARDING_ROUTES.root);
        })
        .catch((e: AxiosError) => {
          if (
            e.response &&
            e.response.data ===
              "Unable to save this cell phone number because it is already associated with another account"
          ) {
            setSubmitting(false);
            showSnackbar(t("error duplicate cell", { ns: nsChildcareProviderProfile }), "error");
          } else {
            setSubmitting(false);
            showSnackbar(t("error cannot save", { ns: nsChildcareProviderProfile }), "error");
          }
        });
    } else {
      ChildcareProviderProfileControllerClient.updateChildcareProviderProfile(profile?.id || "", dto)
        .then(() => {
          history.push(CHILD_CARE_ROUTES.homepage);
        })
        .catch((e: AxiosError) => {
          if (
            e.response &&
            e.response.data ===
              "Unable to save this cell phone number because it is already associated with another account"
          ) {
            setSubmitting(false);
            showSnackbar(t("error duplicate cell", { ns: nsChildcareProviderProfile }), "error");
          } else {
            setSubmitting(false);
            showSnackbar(t("error cannot save", { ns: nsChildcareProviderProfile }), "error");
          }
        });
    }
  };

  return (
    <>
      {(!ready || loading) && (
        <Section bgcolor={CivColors.lightGray} textAlign="center" minHeight={600}>
          <LoadingCircle />
        </Section>
      )}

      {ready && !loading && loadingError && (
        <Section bgcolor={CivColors.lightGray} textAlign="center">
          <Constraint columns={8}>
            <BodyTitle paragraph>{t("something went wrong", { ns: nsChildcareProviderProfile })}</BodyTitle>
            <Body paragraph>{loadingError}</Body>
          </Constraint>
        </Section>
      )}

      {ready && !loading && !loadingError && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <LongForm name="edit-provider-profile" localizationReady={ready}>
              {/* Name */}
              {formType === CCProfileForm.CREATE && (
                <DashSection bodyTitleLabel label={t("name", { ns: nsChildcareProviderProfile })} container>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="firstName"
                      label={`${t("first name", { ns: nsChildcareProviderProfile })} *`}
                      required={isRequiredField("firstName", validationSchema)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="lastName"
                      label={`${t("last name", { ns: nsChildcareProviderProfile })} *`}
                      required={isRequiredField("lastName", validationSchema)}
                    />
                  </Grid>
                </DashSection>
              )}
              {/* Open Slots Availability */}
              <DashSection
                bodyTitleLabel
                label={t("open slots", { ns: nsChildcareProviderProfile })}
                topBorder={formType === CCProfileForm.CREATE}
              >
                <Grid item xs={12}>
                  <FormRadioGroupField<ProviderProfileTagEnum>
                    name="openSlots"
                    label={`${t("open slots question", {
                      ns: nsChildcareProviderProfile,
                    })} *`}
                    labelAlwaysOnTop
                    options={[
                      {
                        key: ProviderProfileTagEnum.SEEKING,
                        optionLabel: t("yes", { ns: nsChildcareProviderProfile }),
                      },
                      {
                        key: ProviderProfileTagEnum.NOTSEEKING,
                        optionLabel: t("no", { ns: nsChildcareProviderProfile }),
                      },
                    ]}
                  />
                </Grid>
              </DashSection>

              {/* Business Information */}
              <DashSection
                bodyTitleLabel
                label={t("child care business", { ns: nsChildcareProviderProfile })}
                topBorder
              >
                {/* License, Name, Address */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="licenseId"
                      label={t("license id #", { ns: nsChildcareProviderProfile })}
                      disabled
                      required={isRequiredField("licenseId", validationSchema)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="businessName"
                      label={t("business name", { ns: nsChildcareProviderProfile })}
                      helperText={t("business name helper text", { ns: nsChildcareProviderProfile })}
                      required={isRequiredField("businessName", validationSchema)}
                    />
                  </Grid>
                  {formType !== CCProfileForm.CREATE && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          name="firstName"
                          label={t("first name", { ns: nsChildcareProviderProfile })}
                          required={isRequiredField("firstName", validationSchema)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          name="lastName"
                          label={t("last name", { ns: nsChildcareProviderProfile })}
                          required={isRequiredField("lastName", validationSchema)}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <FormAddressField
                      name="businessAddress"
                      label={t("business address question", { ns: nsChildcareProviderProfile })}
                      required={isRequiredField("businessAddress", validationSchema)}
                    />
                  </Grid>
                </Grid>

                <Box pt={4} />

                <Grid container spacing={4}>
                  {/* Operating Hours, Languages, Expertise Skills, Description */}
                  <Grid item xs={12}>
                    <FormMultiSelectChipsField
                      name="languages"
                      label={`${t("languages question", { ns: nsChildcareProviderProfile })} *`}
                      selectOptions={[
                        {
                          value: ProviderProfileTagEnum.ENGLISH,
                          label: t("english", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.SPANISH,
                          label: t("spanish", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.MANDARIN,
                          label: t("mandarin", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.CANTONESE,
                          label: t("cantonese", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.RUSSIAN,
                          label: t("russian", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.SOMALI,
                          label: t("somali", { ns: nsChildcareProviderProfile }),
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormCheckboxGroupField
                      name="operatingHours"
                      label={t("operating hours", { ns: nsChildcareProviderProfile })}
                      options={[
                        {
                          value: ProviderProfileTagEnum.DAYTIME,
                          label: t("daytime", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.EVENING,
                          label: t("evening", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.OVERNIGHTS,
                          label: t("overnight", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.WEEKENDS,
                          label: t("weekends", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.FLEXIBLE,
                          label: t("flexible", { ns: nsChildcareProviderProfile }),
                        },
                      ]}
                      required={isRequiredField("operatingHours", validationSchema)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormCheckboxGroupField
                      name="careSkills"
                      label={t("expertise skills and offerings", { ns: nsChildcareProviderProfile })}
                      options={[
                        {
                          value: ProviderProfileTagEnum.BILINGUAL_CARE,
                          label: t("bilingual care", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.INFANT_CARE,
                          label: t("infant care", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.PRESCHOOL_CURRICULUM,
                          label: t("preschool curriculum", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.SCHOOL_AGE,
                          label: t("school age care", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.SPECIAL_NEEDS,
                          label: t("special needs", { ns: nsChildcareProviderProfile }),
                        },
                        {
                          value: ProviderProfileTagEnum.TODDLER_CARE,
                          label: t("toddler care", { ns: nsChildcareProviderProfile }),
                        },
                      ]}
                      required={isRequiredField("careSkills", validationSchema)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      name="businessDescription"
                      label={t("about my child care business", { ns: nsChildcareProviderProfile })}
                      placeholder={t("about my child care business placeholder", { ns: nsChildcareProviderProfile })}
                      multiline
                      minRows={6}
                      required={isRequiredField("businessDescription", validationSchema)}
                      helperText={""}
                      maximumlength={BUSINESS_DESC_MAX_LENGTH}
                    />
                  </Grid>
                </Grid>
              </DashSection>

              {/* Contact Information: Email, Business Phone, Mobile Phone */}
              <DashSection label={t("contact information", { ns: nsChildcareProviderProfile })} topBorder>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField
                      name="email"
                      label={t("Email Address", { ns: nsChildcareProviderProfile })}
                      required={isRequiredField("email", validationSchema)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FastMaskTextField
                      name="mobilePhone"
                      maskType="phone"
                      placeholder={t("cell phone helper text", { ns: nsChildcareProviderProfile })}
                      label={t("cell phone number", { ns: nsChildcareProviderProfile })}
                      required={Boolean(values.smsConsent)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FastMaskTextField
                      name="businessPhone"
                      maskType="phone"
                      placeholder={t("business phone helper text", { ns: nsChildcareProviderProfile })}
                      label={t("business phone number", { ns: nsChildcareProviderProfile })}
                      required={isRequiredField("businessPhone", validationSchema)}
                    />
                  </Grid>
                  {formType === CCProfileForm.CREATE ? (
                    <>
                      <Grid item xs={12} style={{ marginTop: 20, paddingRight: 100 }}>
                        <CheckboxField
                          name="smsConsent"
                          label={
                            <Body gutterBottom>
                              {t("cell phone permissions 1 - create", { ns: nsChildcareProviderProfile })}
                            </Body>
                          }
                          style={{ paddingTop: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: 60, paddingRight: 100 }}>
                        <Body>&#8226; {t("cell phone permissions 2", { ns: nsChildcareProviderProfile })}</Body>
                        <Body>&#8226; {t("cell phone permissions 3", { ns: nsChildcareProviderProfile })}</Body>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Body gutterBottom>{t("cell phone permissions 1", { ns: nsChildcareProviderProfile })}</Body>
                        <Body>&#8226; {t("cell phone permissions 2", { ns: nsChildcareProviderProfile })}</Body>
                        <Body>&#8226; {t("cell phone permissions 3", { ns: nsChildcareProviderProfile })}</Body>
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxField
                          name="smsConsent"
                          label={<Body>{t("sms consent", { ns: nsChildcareProviderProfile })}</Body>}
                          style={{ paddingTop: 0 }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </DashSection>

              {/* Submit Button */}
              <Box textAlign={formType === CCProfileForm.CREATE ? "right" : "center"} pt={4}>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <CircularProgress size={20} style={{ marginRight: 10 }} />}
                  {getSubmitBtnTitle(isSubmitting, formType)}
                </Button>
              </Box>
            </LongForm>
          )}
        </Formik>
      )}
    </>
  );
};

const ChildcareProviderProfile: React.FC<ChildcareProviderProfileProps> = ({ formType }) => {
  const { t, ready } = useTranslation([nsChildcareProviderProfile, nsCommonFormsBtns]);

  const getTitle = (locReady: boolean, form: CCProfileForm): string => {
    if (locReady) {
      return form === CCProfileForm.CREATE
        ? t("create profile title", { ns: nsChildcareProviderProfile })
        : t("my profile", { ns: nsChildcareProviderProfile });
    }
    return form === CCProfileForm.CREATE ? "Create your business profile" : "My Profile";
  };

  // Page Layout
  return (
    <>
      {formType === CCProfileForm.CREATE ? <UserNavbar showLanguage /> : <ChildcareNavbar showLanguage />}

      {formType === CCProfileForm.CREATE && (
        <Section bgcolor={CivColors.lightGray} minimal>
          <Constraint columns={12}>
            <DashReturnLink to={ONBOARDING_ROUTES.root}>
              {t("button.back_to_previous_page", { ns: nsCommonFormsBtns })}
            </DashReturnLink>
          </Constraint>
        </Section>
      )}

      {/* Header */}
      <Section minimizeBottomGutter textAlign="center" bgcolor={CivColors.lightGray}>
        <Constraint columns={8}>
          <SectionTitle>{getTitle(ready, formType)}</SectionTitle>
        </Constraint>
      </Section>

      <main id="main-content">
        {!ready && (
          <Section bgcolor={CivColors.lightGray} textAlign="center" minHeight={600}>
            <LoadingCircle />
          </Section>
        )}

        {ready && (
          <Section minimizeTopGutter bgcolor={CivColors.lightGray}>
            <Constraint columns={9}>
              <ProfileForm formType={formType} />
            </Constraint>
          </Section>
        )}
      </main>

      <Footer />
    </>
  );
};
export default ChildcareProviderProfile;
