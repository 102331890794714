import { Route, Switch } from "react-router-dom";
import { OrganizationJobPostDTO } from "src/generated/api_types";
import { ConsumerJobsList } from "src/pages/medicaidAccount/coordinatorAccount/ConsumerJobsList";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import {
  MedicaidCoordDashboardContainer,
  MedicaidCoordDashboardTabs,
} from "src/pages/medicaidAccount/coordinatorAccount/MedicaidCoordDashboardContainer";
import { getOrgJobPostsList as getOrgJobPostsListType } from "src/pages/medicaidAccount/coordinatorAccount/Queries";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useOrgJobsDatatable,
  ClientsideDatatableContextProvider: OrgJobsDatatableContextProvider,
} = GenerateClientsideDatatable<OrganizationJobPostDTO, typeof getOrgJobPostsListType, () => void>();

interface MedicaidCoordJobsDashboardProps {
  getOrgJobPostsList: typeof getOrgJobPostsListType;
}

export const MedicaidCoordJobsDashboard: React.FC<MedicaidCoordJobsDashboardProps> = ({ getOrgJobPostsList }) => {
  return (
    <MedicaidCoordDashboardContainer tab={MedicaidCoordDashboardTabs.CONSUMER_JOB_POSTS}>
      <OrgJobsDatatableContextProvider getData={getOrgJobPostsList}>
        <Switch>
          <Route exact path={Medicaid_Coordinator_Dashboard_Routes.jobs_list}>
            <ConsumerJobsList />
          </Route>
        </Switch>
      </OrgJobsDatatableContextProvider>
    </MedicaidCoordDashboardContainer>
  );
};
