import {
  Box,
  FormControl as MFormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MSelect,
  SelectProps as MSelectProps,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectOption } from "src/reusable_view_elements/form_fields/FormSelectField";
import Radio from "src/reusable_view_elements/form_fields/Radio";
import { DescriptiveText, StyledInputLabel } from "src/reusable_view_elements/form_fields/TextField";
import CivColors from "src/themes/civilization/CivColors";
import { Body } from "../Typography";

type SelectFieldProps<T> = {
  selectOptions: SelectOption<T>[];
  helperText?: string;
  placeholderText?: string;
} & MSelectProps;

export const StyledSelectControl = styled(MFormControl)({
  "& .ConsumersList-cellHighlight": {
    "& label": {
      color: CivColors.deepRose,
      fontWeight: 700,
    },
  },
  "& .ConsumersList-cellNotHighlight": {
    "& label": {
      color: CivColors.coreDarkNavy,
    },
  },
  "& .ConsumersListPaging-cellHighlight": {
    "& label": {
      color: CivColors.deepRose,
      fontWeight: 700,
    },
  },
  "& .ConsumersListPaging-cellNotHighlight": {
    "& label": {
      color: CivColors.coreDarkNavy,
    },
  },
  "& label": {
    fontVariantLigatures: "no-contextual",
    color: CivColors.coreDarkNavy,
    paddingRight: "20px", // creates space between dropdown icon
    height: "24px",
    lineHeight: 1.2,
    "&.placeholderText": {
      color: CivColors.deepGray,
    },
    "&.placeholderText-disabled": {
      color: CivColors.mediumGray,
    },
  },
  "& .MuiInputBase-root": {
    marginTop: "4px",
    marginBottom: "4px",
    padding: "12px 14px",
    "& .MuiInputBase-input": {
      padding: 0,
      minHeight: "24px",
    },
    "&.Mui-readOnly": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    backgroundColor: CivColors.white,
    "& fieldset": {
      borderColor: CivColors.coreDarkNavy,
    },
    "&.Mui-focused fieldset": {
      borderColor: CivColors.coreDarkNavy,
    },
    "&.Mui-error fieldset": {
      borderColor: CivColors.deepRose,
    },
    "&.Mui-disabled": {
      backgroundColor: "inherit",
      borderColor: CivColors.mediumGray,
      "& .MuiSvgIcon-root": {
        color: CivColors.mediumGray,
        opacity: "revert",
      },
    },
    "&.Mui-readOnly fieldset": {
      borderColor: "rgba(45, 48, 69, .23)",
    },
    "&.Mui-readOnly.Mui-focused fieldset": {
      borderColor: CivColors.coreDarkNavy,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: CivColors.coreDarkNavy,
    opacity: "54%",
  },
  "& .MuiFormHelperText-root": {
    margin: 0,
    lineHeight: 1.2,
    color: CivColors.coreGray,
    "&.Mui-disabled": {
      color: CivColors.mediumGray,
    },
    "&.Mui-error": {
      color: CivColors.deepRose,
    },
  },
});

const Select = <T extends string | number>(props: SelectFieldProps<T>) => {
  const { label, placeholderText, helperText, selectOptions, sx, ...restProps } = props;
  const { t, ready } = useTranslation();
  const [formControlWidth, setFormControlWidth] = useState(undefined);

  // this gets the width of the form control element so we can use it to explicitly set the width of the menu
  const callbackRef = useCallback((element) => {
    if (element) {
      setFormControlWidth(element.getBoundingClientRect().width);
    }
  }, []);

  const getSelectedLabel = (value: T) => {
    const selectOption = selectOptions.find((option) => option.value === value);
    if (selectOption) {
      return selectOption.locizeKey && selectOption.namespace
        ? t(selectOption.locizeKey, { ns: selectOption.namespace })
        : selectOption.label;
    }
    return value;
  };

  return (
    <Box sx={{ ...sx }}>
      <StyledInputLabel
        id={`label-${restProps.name}`}
        required={restProps.required}
        disabled={restProps.disabled}
        htmlFor={restProps.name}
      >
        {label}
      </StyledInputLabel>
      <StyledSelectControl fullWidth variant={restProps.variant || "outlined"} ref={callbackRef}>
        <MSelect
          // TODO: [issue #1706] Update 'renderValue' prop to unselect the value when the value is not in the options or the value resets programmatically
          value={restProps.value}
          onChange={restProps.onChange}
          fullWidth
          labelId={`label-${restProps.name}`}
          displayEmpty // this tells field to display a value when nothing is selected, requires renderValue prop (Select does not currently support a placeholder prop)
          renderValue={(selected: any) => {
            return restProps.value ? (
              <InputLabel>{getSelectedLabel(restProps.value || selected)}</InputLabel>
            ) : (
              <InputLabel className={restProps.disabled ? "placeholderText-disabled" : "placeholderText"}>
                {placeholderText}
              </InputLabel>
            );
          }}
          IconComponent={ArrowDropDownRoundedIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                width: formControlWidth,
                "& .Mui-selected": {
                  backgroundColor: `${CivColors.white}!important`,
                },
                "& .Mui-selected:focus": {
                  backgroundColor: "rgba(236, 99, 62, 0.08)!important",
                },
                "& .Mui-focusVisible": {
                  backgroundColor: "rgba(45, 48, 69, 0.12)!important",
                },
              },
            },
          }}
          {...restProps}
        >
          {selectOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              lang={option.lang}
              data-testid={option.value}
              sx={{ whiteSpace: "unset", display: "flex" }}
              disabled={option.disabled}
            >
              <Radio
                sx={{ alignSelf: "flex-start" }}
                checked={restProps.value ? (restProps.value as any).toString().indexOf(option.value) > -1 : undefined}
              />
              <ListItemText sx={{ padding: "12px 0px" }}>
                <Body>
                  {ready && option.locizeKey && option.namespace
                    ? t(option.locizeKey, { ns: option.namespace })
                    : option.label}
                </Body>
              </ListItemText>
            </MenuItem>
          ))}
        </MSelect>
        <FormHelperText component="span">
          <DescriptiveText
            helperText={helperText}
            isFormikError={restProps.error}
            isLengthError={false}
            disabled={restProps.disabled}
          />
        </FormHelperText>
      </StyledSelectControl>
    </Box>
  );
};

export default Select;
