import { Box, BoxProps } from "@mui/material";
import { width } from "src/themes/civilization/CivTheme";

export interface ConstraintProps extends BoxProps {
  columns: number;
}

function Constraint({ columns, ...props }: ConstraintProps) {
  return (
    <Box
      maxWidth={width(columns)}
      width="100%"
      margin="0px auto"
      boxSizing="border-box"
      display="block" // Fix IE 11 layout when used with main
      {...props}
    />
  );
}

export default Constraint;
