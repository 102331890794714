import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CivColors from "src/themes/civilization/CivColors";

const StyledBox = styled(Box)({
  background: CivColors.sandyDune,
  borderRadius: 4,
  padding: "16px",
  textAlign: "left",
  display: "flex",
});

const WarningBox = (props: BoxProps) => {
  return (
    <>
      <StyledBox {...props}>
        <Box marginRight={2}>
          <WarningRoundedIcon htmlColor={CivColors.sunnyDune} />
        </Box>
        <Box>{props.children}</Box>
      </StyledBox>
    </>
  );
};

export default WarningBox;
