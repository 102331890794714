import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { BodyTitle } from "src/reusable_view_elements/Typography";
import AddOrEditProviderForm, {
  AddOrEditProviderRowFormFields,
} from "src/reusable_view_elements/forms/AddOrEditProviderForm";

type ProviderListingInteractionDialogProps = {
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  existingZipCodes: Set<string>;
  initialValues: AddOrEditProviderRowFormFields;
};

export const ProviderListingInteractionDialog: React.FC<ProviderListingInteractionDialogProps> = ({
  isModalOpen,
  setModalOpen,
  existingZipCodes,
  initialValues,
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick") {
          setModalOpen(false);
        }
      }}
      aria-labelledby="add-providers-modal-title"
      aria-describedby="add-providers-modal-content"
      maxWidth="sm"
      PaperProps={{
        style: { padding: "0px 12px" },
      }}
    >
      <DialogTitle component={BodyTitle} id="add-providers-modal-title">
        {initialValues.zip === "" ? "Add Row" : "Edit Row"}
      </DialogTitle>
      <DialogContent id="add-providers-modal-content" style={{ paddingTop: 0 }}>
        <AddOrEditProviderForm
          actionToClose={() => setModalOpen(false)}
          existingZipCodes={existingZipCodes}
          initialValues={initialValues}
        />
      </DialogContent>
    </Dialog>
  );
};
