import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { LocizeLanguage } from "src/generated/api_types";
import { changeLanguage, LanguageLabel, LangKeyByUserLangPref, LangPrefByKey } from "src/i18n/Languages";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidSettings } from "src/i18n/Namespaces";
import { getLocalizedLangPrefChangeResponse } from "src/i18n/Utilities";
import { medicaidClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import DashSection from "src/reusable_view_elements/DashSection";
import { FormSelectField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import Form from "src/reusable_view_elements/form_fields/Form";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import { object, string } from "yup";

interface LangPrefSectionProps {
  currentLangPref: LocizeLanguage;
  setCurrentLangPref: (newLangPref: LocizeLanguage) => void;
  editMode: boolean;
  toggleEditMode: (bool: boolean) => void;
}

const LanguagePrefsSection = (props: LangPrefSectionProps) => {
  const { showSnackbar } = useNotification();
  const { isImpersonated } = useUserSession();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsCommonToasts, nsMedicaidSettings]);

  const formValidationSchema = object({
    languagePreference: string().required(t("field.generic.error.select_one_dropdown", { ns: nsCommonFormsBtns })),
  });

  const getLanguageLabel = (userLangPref: LocizeLanguage) => {
    const languageKey = LangKeyByUserLangPref[userLangPref];
    return LanguageLabel[languageKey];
  };

  if (!props.editMode) {
    return (
      <DashSection bodyTitleLabel label={t("language_preferences.label", { ns: nsMedicaidSettings })} topBorder>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Body paragraph>{t("language_preferences.description", { ns: nsMedicaidSettings })}</Body>
          </Grid>
          <Grid item>
            <Body>{t("language.label", { ns: nsMedicaidSettings })}</Body>
            <BodyEmphasis paragraph>{getLanguageLabel(props.currentLangPref)}</BodyEmphasis>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              onClick={() => {
                if (isImpersonated()) {
                  showSnackbar(
                    t(
                      "info.cant_change_lang_pref_if_impersonating",
                      "You cannot change this user's language preference if you are in impersonation mode.",
                      { ns: nsCommonToasts },
                    ),
                    "info",
                  );
                } else {
                  props.toggleEditMode(true);
                }
              }}
            >
              {t("button.change_language", { ns: nsCommonFormsBtns })}
            </Button>
          </Grid>
        </Grid>
      </DashSection>
    );
  }

  return (
    <DashSection bodyTitleLabel label={t("language_preferences.label", { ns: nsMedicaidSettings })} topBorder>
      <Body paragraph>{t("language_preferences.description", { ns: nsMedicaidSettings })}</Body>
      <Formik
        initialValues={{ languagePreference: props.currentLangPref }}
        validationSchema={formValidationSchema}
        onSubmit={(values, helpers) => {
          medicaidClient
            .updateLanguagePreference({ newLangPref: values.languagePreference })
            .then((res) => {
              changeLanguage(LangKeyByUserLangPref[values.languagePreference], () => {
                showSnackbar(getLocalizedLangPrefChangeResponse(res.data, t), "success");
              });
              props.toggleEditMode(false);
              props.setCurrentLangPref(res.data);
            })
            .catch(() => {
              showSnackbar(
                t("error.generic", "Something went wrong. Please try again later.", { ns: nsCommonToasts }),
                "error",
              );
            })
            .finally(() => {
              helpers.setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form localizationReady={ready}>
            <Box mb={6}>
              <FormSelectField
                label={t("field.language.label", { ns: nsCommonFormsBtns })}
                name="languagePreference"
                disabled={isSubmitting}
                selectOptions={Object.entries(LanguageLabel).map((item) => {
                  return {
                    value: LangPrefByKey[item[0]],
                    label: item[1],
                    lang: item[0],
                  };
                })}
              />
            </Box>
            <Grid container justifyContent="flex-end">
              <Button variant="text" type="button" onClick={() => props.toggleEditMode(false)} disabled={isSubmitting}>
                {t("button.cancel", { ns: nsCommonFormsBtns })}
              </Button>
              <Button variant="contained" type="submit" disabled={isSubmitting || !dirty}>
                {isSubmitting
                  ? t("button.saving_changes", { ns: nsCommonFormsBtns })
                  : t("button.save_changes", { ns: nsCommonFormsBtns })}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DashSection>
  );
};

export default LanguagePrefsSection;
