import { Link as MuiLink } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { nsCommonLogin } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import Alert, { AlertProps } from "src/reusable_view_elements/alert/Alert";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";

const BlockedUserErrorBox = (props: AlertProps) => {
  const { t } = useTranslation([nsCommonLogin]);
  const location = useLocation();
  const supportPhoneNumber = "18557960605";

  return (
    <Alert
      id="blocked-user-error"
      severity="error"
      title={t("blocked_user_error.label", { ns: nsCommonLogin })}
      {...props}
    >
      <Trans
        t={t}
        i18nKey="blocked_user_error.description"
        ns={nsCommonLogin}
        parent={Body}
        style={{ paddingRight: 38 }}
        components={{
          phone: <MuiLink href={`tel:${supportPhoneNumber}`} />,
          cntctus: (
            <InternalLink to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }} target="_blank" />
          ),
        }}
        values={{ cntctus_key: t("blocked_user_error.contact_us.label", { ns: nsCommonLogin }) }}
      />
    </Alert>
  );
};

export default BlockedUserErrorBox;
