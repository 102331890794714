import { Box, BoxProps } from "@mui/material";
import CivColors from "src/themes/civilization/CivColors";

interface BadgeProps extends BoxProps {
  number: number;
}

const Badge = ({ number, ...boxProps }: BadgeProps) => (
  <Box
    display="inline-block"
    bgcolor={CivColors.tangerine}
    borderRadius="20px"
    color={CivColors.white}
    padding="0px 8px"
    ml="4px"
    fontWeight={700}
    fontSize="1.0rem"
    lineHeight={1.35}
    {...boxProps}
  >
    {number}
  </Box>
);

export default Badge;
