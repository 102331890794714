import { Button as MuiButton, ButtonProps } from "@mui/material";
import { CSSProperties } from "react";

/** All the button styling is handled in the CivTheme **/

/** This union of interfaces is to remind devs how to use the variations of this button. It enforces the variant prop to remind
 * us to declare "contained", "outlined", or "text" at use site. It also enforces the type prop, and if type="submit"
 * it enforces a disabled prop. This is to maintain the disabled-while-submitting pattern. **/

interface NonSubmitButtonProps extends Omit<ButtonProps, "variant" | "type"> {
  variant: "outlined" | "contained" | "text";
  type: "button" | "reset";
}

interface SubmitButtonProps extends Omit<ButtonProps, "variant" | "type"> {
  variant: "outlined" | "contained" | "text";
  type: "submit";
  disabled: boolean;
}

type CustomButton = NonSubmitButtonProps | SubmitButtonProps;

export const Button = (props: CustomButton) => {
  const { variant, type, ...restProps } = props;
  return <MuiButton type={type} variant={variant} {...restProps} />;
};

/**
 * This CSS helps resolve the alignment issue when a Button is in a Grid row with form elements.
 * The top margin aligns the Button with the form field's input instead of their label.
 * If the field has an error, the message won't displace the Button.
 */
export const FormButtonRowStyle: CSSProperties = { marginTop: "28px" };
