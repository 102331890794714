import { ButtonProps, Grid, GridProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Button } from "src/reusable_view_elements/Button";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const Container = styled(Grid)({
  background: CivColors.coreOrange,
  paddingTop: 14,
  paddingBottom: 14,
  paddingLeft: 24,
  paddingRight: 24,
  "& button": {
    color: CivColors.white,
  },
});

interface Props extends GridProps {
  onStop: () => void;
  buttonProps?: Omit<ButtonProps, "type">;
  name: string;
}

/**
 * The Banner component (reusable_view_elements/civilization/Banner) could have been used in lieu of this component;
 * however, that would mean refactoring Banner to accept props to determine whether to center its children (usually
 * text) or place it on the left, and whether to render an icon "X" button or a regular text button.
 *
 * I also can't predict how much more flexible the banner will need to be to accommodate future updates for either the
 * public-facing banner or this impersonation banner e.g. we may want to add more functionality when impersonating.
 *
 * Example Usage:
 * <ImpersonationBanner name="Flora Mazing" onStop={() => window.alert("stop impersonating!")} />
 */
const ImpersonationBanner = ({ name, onStop, buttonProps, ...rootGridProps }: Props) => {
  const [stoppingImpersonation, setStoppingImpersonation] = useState(false);
  return (
    <Container container justifyContent="space-between" alignItems="center" wrap="nowrap" {...rootGridProps}>
      <Grid item>
        <BodyEmphasis style={{ color: CivColors.white }}>You are currently impersonating {name}</BodyEmphasis>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => {
            setStoppingImpersonation(true);
            onStop();
          }}
          disabled={stoppingImpersonation}
          {...buttonProps}
        >
          {stoppingImpersonation ? "Stopping impersonation..." : "Stop impersonating"}
        </Button>
      </Grid>
    </Container>
  );
};

ImpersonationBanner.defaultProps = {
  buttonProps: {},
};

export default ImpersonationBanner;
