import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Illustration from "src/assets/images/emans-about.svg";
import { nsMedicaidRegVerification } from "src/i18n/Namespaces";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { BasicNavBar } from "src/reusable_view_elements/navbars/NavBar";
import LanguageButton from "src/reusable_view_elements/navbars/navbar_elements/LanguageButton";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const VerificationConfirmation = () => {
  const { t, ready } = useTranslation([nsMedicaidRegVerification]);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  return (
    <>
      <BasicNavBar right={<LanguageButton menuType={desktopSize ? "desktop" : "mobile"} />} />
      <main id="main-content">
        {ready ? (
          <Section bgcolor={CivColors.lightGray} textAlign="center">
            <Constraint columns={12}>
              <SectionTitle>{t("check_work_email.label", { ns: nsMedicaidRegVerification })}</SectionTitle>
              <Body paragraph>
                {t("check_work_email.description.we_have_just_sent", { ns: nsMedicaidRegVerification })}
              </Body>
              <Body paragraph>
                {t("check_work_email.description.if_you_have_questions", { ns: nsMedicaidRegVerification })}
              </Body>
            </Constraint>
          </Section>
        ) : (
          <Section bgcolor={CivColors.lightGray} textAlign="center">
            <Constraint columns={12}>
              <LoadingCircle />
            </Constraint>
          </Section>
        )}
        <Section bgcolor={CivColors.lightGray} textAlign="center">
          <Constraint columns={6}>
            <img src={Illustration} alt="" width="100%" />
          </Constraint>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default VerificationConfirmation;
