// COMMON
export const nsCommonAlt = "common.alt";
export const nsCommonFormsBtns = "common.forms_buttons";
export const nsCommonLogin = "common.login";
export const nsCommonNavigation = "common.navigation";
export const nsCommonToasts = "common.toasts";
export const nsCommonTooltips = "common.tooltips";
export const nsCommonBanners = "common.banners";
export const nsCommonAria = "common.aria";

//MEDICAID
export const nsMedicaidAccountPortal = "medicaid.account_portal";
export const nsMedicaidBEContent = "medicaid.backend_content";
export const nsMedicaidCommunityGuidelines = "medicaid.community_guidelines";
export const nsMedicaidCompleteOnboarding = "medicaid.complete_onboarding";
export const nsCoordinatorAndSupportTools = "medicaid.coordinator_support_tools";
export const nsMedicaidInbox = "medicaid.inbox";
export const nsMedicaidJobPost = "medicaid.job_post";
export const nsMedicaidJobSurvey = "medicaid.job_survey";
export const nsMedicaidJobSurveyConfirmation = "medicaid.job_survey_confirmation";
export const nsMedicaidLandingPage = "medicaid.landing_page";
export const nsMedicaidOnboardingMain = "medicaid.onboarding_main";
export const nsMedicaidProviderProfile = "medicaid.provider_profile";
export const nsMedicaidRegPassword = "medicaid.registration.password";
export const nsMedicaidRegRoleSelection = "medicaid.registration.role_selection";
export const nsMedicaidRegVerification = "medicaid.registration.verification";
export const nsMedicaidReportUser = "medicaid.report_user";
export const nsMedicaidSearch = "medicaid.search";
export const nsMedicaidSettings = "medicaid.settings";
export const nsMedicaidTagsEnums = "medicaid.tags_enums";
export const nsMedicaidTestimonialConsent = "medicaid.testimonial_consent";

// PRIVATE PAY
export const nsPrivatePayMsgAgencyConfirmation = "private_pay.contact_agency_confirmation";
export const nsPrivatePayLandingPage = "private_pay.landing_page";
export const nsPrivatePayWaitlist = "private_pay.waitlist";
export const nsPPaySearch = "private_pay.search";
export const nsPPayAgencyInfo = "private_pay.agency_info";
export const nsPPayBEContent = "private_pay.backend_content";

//UNIVERSAL
export const nsUnivContactUS = "universal.contact_us";
export const nsUniversalDatatables = "universal.datatables";

// PGS THAT NEED NAMESPACE UPDATING
// TODO: [issue #1128] Update these namespaces (and keys?) in locize and throughout code to match medicaid pattern
export const nsAboutPage = "about page";
export const nsChildcareMessageProviderForm = "childcare message provider form";
export const nsChildcareProgramPage = "childcare program page";
export const nsChildcareProviderAcctPortal = "childcare provider account portal";
export const nsChildcareProviderProfile = "childcare provider profile";
export const nsChildcareProviderProfileView = "childcare provider profile view page";
export const nsChildcareSearchResults = "childcare search results page";
export const nsCommonCTAbtns = "common cta buttons";
export const nsCommonForms = "common forms";
export const nsCommonModules = "common modules";
export const nsCovidChildcareFAQ = "covid19 childcare faq";
export const nsUniversalFindHomecare = "Universal - Find Home Care Page";
export const nsUniversalHomepage = "Universal - Home Page";
export const nsUniversalPrivacyPolicy = "Universal privacy policy";
export const nsUniversalProvideCare = "Universal - Provide Care Page";
export const nsUniversalTOS = "Universal TOS";
