import { useTranslation } from "react-i18next";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { nsMedicaidAccountPortal } from "src/i18n/Namespaces";
import { ToolsWrenchIcon } from "src/assets/icons/StreamlineIcons";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { BodyTitle } from "src/reusable_view_elements/Typography";

const CoordinatorToolsPortalCard = () => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidAccountPortal]);

  function getViewMorePath(): string {
    if (isUserType(UserType.MedicaidReferralCoordinator)) {
      return Medicaid_Coordinator_Dashboard_Routes.team;
    }
    return "";
  }

  if (!ready) {
    return <LoadingPortalCardSkeleton titleOnly />;
  }

  return (
    <PortalCard data-testid="coordinator-tools-card">
      <PortalCardTitle icon={ToolsWrenchIcon} viewMorePath={getViewMorePath()}>
        <BodyTitle>{t("coordinator_tools.label", { ns: nsMedicaidAccountPortal })}</BodyTitle>
      </PortalCardTitle>
    </PortalCard>
  );
};

export default CoordinatorToolsPortalCard;
