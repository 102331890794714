import { useTranslation } from "react-i18next";
import { RegistrationStepEnum } from "src/generated/api_types";
import { nsMedicaidRegVerification } from "src/i18n/Namespaces";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { RegistrationPageContainer } from "src/pages/registration/components/RegistrationPageContainer";
import { AccordionList } from "src/reusable_view_elements/Accordion";
import Constraint from "src/reusable_view_elements/Constraint";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

/**********************
 ***** WA imports *****
 *********************/
import { WAConsumerVerificationForm } from "src/pages/registration/verification/WA-Consumer-Components";
import { WAProviderVerificationForm } from "src/pages/registration/verification/WA-Provider-Components";

/**********************
 ***** OR imports *****
 *********************/
import { ORProviderVerificationForm } from "src/pages/registration/verification/OR-Provider-Components";
import { ORConsumerVerificationForm } from "src/pages/registration/verification/OR-Consumer-Components";

/**********************
 * Case Worker imports
 *********************/
import { CaseWorkerVerificationForm } from "src/pages/registration/verification/Case-Worker-Components";

const VerificationBasePage = () => {
  const { regPath, userInfo } = useRegistrationContext();
  const { t, ready } = useTranslation(nsMedicaidRegVerification);

  const concatFunderRoleType = `${userInfo.funder}_${regPath?.roleConfig?.roleType}`;

  function getTitleDescription(): string {
    switch (concatFunderRoleType) {
      case "HC Medicaid-WA_PROVIDER":
        return t("verification.description_provider_wa", { ns: nsMedicaidRegVerification });
      case "HC Medicaid-WA_CONSUMER":
        return t("verification.description_consumer_wa", { ns: nsMedicaidRegVerification });
      case "HC Medicaid-WA_CASE_WORKER":
        return t("verification.description_case_worker", { ns: nsMedicaidRegVerification });
      case "HC Medicaid-OR_PROVIDER":
        return t("verification.description_provider_or", { ns: nsMedicaidRegVerification });
      case "HC Medicaid-OR_CONSUMER":
        return t("verification.description_consumer_or", { ns: nsMedicaidRegVerification });
      case "HC Medicaid-OR_CASE_WORKER":
        return t("verification.description_case_worker", { ns: nsMedicaidRegVerification });
      default:
        return "";
    }
  }

  function getAccordionData(): { title: string; body: string }[] {
    switch (concatFunderRoleType) {
      case "HC Medicaid-WA_PROVIDER":
        return [
          {
            title: t("why_do_we_need.label", { ns: nsMedicaidRegVerification }),
            body: t("why_do_we_need.description.provider.wa", { ns: nsMedicaidRegVerification }),
          },
          {
            title: t("how_is_my_verification.label", { ns: nsMedicaidRegVerification }),
            body: t("how_is_my_verification.description.wa", { ns: nsMedicaidRegVerification }),
          },
        ];
      case "HC Medicaid-WA_CONSUMER":
        return [
          {
            title: t("why_do_we_need.label", { ns: nsMedicaidRegVerification }),
            body: t("why_do_we_need.description.consumer.wa", { ns: nsMedicaidRegVerification }),
          },
        ];
      case "HC Medicaid-WA_CASE_WORKER":
        return [];
      case "HC Medicaid-OR_PROVIDER":
        return [
          {
            title: t("why_do_we_need.label", { ns: nsMedicaidRegVerification }),
            body: t("why_do_we_need.description.provider.or", { ns: nsMedicaidRegVerification }),
          },
          {
            title: t("how_is_my_verification.label", { ns: nsMedicaidRegVerification }),
            body: t("how_is_my_verification.description.or", { ns: nsMedicaidRegVerification }),
          },
        ];
      case "HC Medicaid-OR_CONSUMER":
        return [
          {
            title: t("why_do_we_need.label", { ns: nsMedicaidRegVerification }),
            body: t("why_do_we_need.description.consumer.or", { ns: nsMedicaidRegVerification }),
          },
        ];
      case "HC Medicaid-OR_CASE_WORKER":
        return [];
      default:
        return [];
    }
  }

  function getFormComponent() {
    switch (concatFunderRoleType) {
      case "HC Medicaid-WA_PROVIDER":
        return <WAProviderVerificationForm />;
      case "HC Medicaid-WA_CONSUMER":
        return <WAConsumerVerificationForm />;
      case "HC Medicaid-WA_CASE_WORKER":
        return <CaseWorkerVerificationForm />;
      case "HC Medicaid-OR_PROVIDER":
        return <ORProviderVerificationForm />;
      case "HC Medicaid-OR_CONSUMER":
        return <ORConsumerVerificationForm />;
      case "HC Medicaid-OR_CASE_WORKER":
        return <CaseWorkerVerificationForm />;
      default:
        return <></>;
    }
  }

  return (
    <RegistrationPageContainer currentStep={RegistrationStepEnum.VERIFICATION}>
      {ready ? (
        <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
          <Constraint columns={6}>
            <SectionTitle align="center">{t("verification.label", { ns: nsMedicaidRegVerification })}</SectionTitle>
            <Body paragraph align="center">
              {getTitleDescription()}
            </Body>
            <AccordionList data={getAccordionData()} />
          </Constraint>
        </Section>
      ) : (
        <LoadingCircle />
      )}
      <Section bgcolor={CivColors.lightGray}>{getFormComponent()}</Section>
    </RegistrationPageContainer>
  );
};

export default VerificationBasePage;
