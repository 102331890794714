import { PropsWithChildren, useState } from "react";
import { createContainer } from "unstated-next";

export enum InboxTabState {
  all_threads,
  unread_threads,
  starred_threads,
  archived_threads,
  job_responses,
}

function inboxContext() {
  const [tab, setTab] = useState(InboxTabState.all_threads);
  const [profileDetailExpanded, setProfileDetailExpanded] = useState<boolean>(true);

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  // Search field state shared across tabs; resets in Inbox when before tab state changes
  const [pageNumber, setPageNumber] = useState<number>(1); // 1-based
  const [searchedTerm, setSearchedTerm] = useState<string>(""); // The term sent in latest request
  const [searchQuery, setSearchQuery] = useState<string>(""); // Up-to-date value for search field
  const [showSearchResetButton, setShowSearchResetButton] = useState<boolean>(false);

  // Helper function to help reset search field state and paging state.
  // Useful for the user experience, such as when the tab and location changes or the reset button is clicked
  function resetSearchAndPagination(): void {
    setSearchedTerm("");
    setSearchQuery("");
    setShowSearchResetButton(false);
    setPageNumber(1);
  }

  return {
    tab,
    setTab,
    profileDetailExpanded,
    setProfileDetailExpanded,
    totalUnreadCount,
    setTotalUnreadCount,
    pageNumber,
    setPageNumber,
    searchedTerm,
    setSearchedTerm,
    searchQuery,
    setSearchQuery,
    showSearchResetButton,
    setShowSearchResetButton,
    resetSearchAndPagination,
  };
}

const InboxContext = createContainer(inboxContext);

export const InboxContextProvider = ({ children }: PropsWithChildren<{}>) => (
  <InboxContext.Provider>{children}</InboxContext.Provider>
);

export const useInboxContext = () => {
  return InboxContext.useContainer();
};
