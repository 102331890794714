import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";

interface Props {
  title: string;
  body: string;
  isDialogOpen: boolean;
  onYesClick: () => void;
  onNoClick: () => void;
}

const ConfirmationDialog = ({ title, body, isDialogOpen, onYesClick, onNoClick }: Props) => {
  const { t, ready } = useTranslation(nsCommonFormsBtns);
  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="xs"
      PaperProps={{
        style: { padding: "8px 0px 16px 0px" },
      }}
    >
      <DialogTitle>
        <BodyTitle>{title}</BodyTitle>
      </DialogTitle>
      <DialogContent>
        <Body>{body}</Body>
        {ready && (
          <Grid style={{ textAlign: "right" }}>
            <Button variant="text" type="button" onClick={onYesClick}>
              {t("button.yes", { ns: nsCommonFormsBtns })}
            </Button>
            <Button variant="contained" type="button" onClick={onNoClick}>
              {t("button.no", { ns: nsCommonFormsBtns })}
            </Button>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
