import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "src/reusable_view_elements/Button";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

type ExpandMoreButtonProps = {
  text?: string;
  expanded: boolean;
  onClick: () => void;
};

export const ExpandMoreButton = (props: ExpandMoreButtonProps) => {
  const { text, expanded, onClick } = props;
  return (
    <Button
      variant="text"
      type="button"
      size="small"
      endIcon={<ExpandMoreIcon />}
      onClick={onClick}
      aria-expanded={expanded}
      aria-label="show more"
    >
      {text && <Body style={{ width: "140px", color: CivColors.coreDarkNavy }}>{text}</Body>}
    </Button>
  );
};
