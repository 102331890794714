import { Box, Grid, Link as MuiLink } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useHistory, useParams } from "react-router-dom";
import { JobResponsesMetadataDTO, JobResponseThreadDTO, RoleType } from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidInbox, nsUniversalDatatables } from "src/i18n/Namespaces";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { EmptyContainer } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListCard from "src/pages/inbox/inboxComponents/ThreadListCard";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";

const JobResponseThreadList = () => {
  const { jobPostId } = useParams<{ jobPostId: string }>();
  const history = useHistory();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsUniversalDatatables]);

  const [threads, setThreads] = useState<JobResponseThreadDTO[]>();
  const [metadata, setMetadata] = useState<JobResponsesMetadataDTO>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    // Reset view
    setThreads(undefined);
    setMetadata(undefined);
    setErrorMessage(undefined);
    setLoading(true);

    // Load threads by job id
    messagingClient
      .getResponsesForJob(jobPostId)
      .then((res) => {
        setThreads(res.data.threads);
        setMetadata(res.data.jobResponsesMetadata);
      })
      .catch((error: AxiosError) => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobPostId]);

  if (loading || !ready) {
    return <LoadingCircle />;
  }

  if (!loading && threads && metadata) {
    return (
      <>
        {/* Page Title Banner and Back Link */}
        <Box pt="14px" pb="18px">
          <Constraint columns={14}>
            {/* Back to All Job Responses Link Component */}
            <Box pb={2} pt={2}>
              <MuiLink component={Link} to={MEDICAID_INBOX_ROUTES.jobResponses}>
                <Grid container alignItems="center">
                  <ChevronLeftRoundedIcon style={{ marginRight: "8px" }} />
                  <Body>{t("button.back_to_job_responses", { ns: nsCommonFormsBtns })}</Body>
                </Grid>
              </MuiLink>
            </Box>

            {/* Page Title */}
            <BodyTitle align="center">
              {`${t("responses_to_name.label", { ns: nsMedicaidInbox, name: metadata.clientName })} `}
              <InternalLink to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: metadata.id })}>
                {`${t("job_num.label", { ns: nsUniversalDatatables })}${metadata.jobNumber}`}
              </InternalLink>
            </BodyTitle>
          </Constraint>
        </Box>

        {/* Thread List */}
        <Constraint columns={14} pb={24}>
          {threads.map((thread) => (
            <ThreadListCard
              key={thread.threadId}
              avatarId={thread.providerId}
              hasImg={thread.providerHasImg}
              firstName={thread.providerFirstName}
              lastInitial={thread.providerLastNameInitial}
              roleType={RoleType.PROVIDER}
              date={thread.lastMessageDate}
              message={thread.lastMessageText}
              jobNum={metadata.jobNumber}
              jobId={metadata.id}
              unreadMessages={thread.numUnreadMessages}
              jobIsResolved={metadata?.jobIsResolved}
              onClick={() => {
                history.push(
                  generatePath(MEDICAID_INBOX_ROUTES.jobResponseThread, { jobPostId, threadId: thread.threadId }),
                );
              }}
            />
          ))}
        </Constraint>
      </>
    );
  }

  return (
    <EmptyContainer>
      <Body paragraph>{errorMessage || t("field.email.error.generic", { ns: nsCommonFormsBtns })}</Body>
      <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_INBOX_ROUTES.jobResponses}>
        {t("button.back_to_job_responses", { ns: nsCommonFormsBtns })}
      </LinkStyledAsButton>
    </EmptyContainer>
  );
};

export default JobResponseThreadList;
