import { Box } from "@mui/material";
import { ReactNode } from "react";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const ErrorGuidance = (props: { helperText: ReactNode }) => {
  return (
    <Box display="flex" component="span">
      <ErrorRoundedIcon sx={{ width: 24, height: 24, marginRight: "12px", "& path": { fill: CivColors.deepRose } }} />
      <Body component="span">{props.helperText}</Body>
    </Box>
  );
};

export default ErrorGuidance;
