import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { nsMedicaidTestimonialConsent } from "src/i18n/Namespaces";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const TestimonialConsent = () => {
  const { t, ready } = useTranslation([nsMedicaidTestimonialConsent]);

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>Carina | Written Statement Consent</title>
        </Helmet>
        <MedicaidNavbar />
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title")}</title>
      </Helmet>

      <MedicaidNavbar />

      <Section bgcolor={CivColors.lightGray} minHeight="80vh">
        <Constraint columns={10}>
          <SectionTitle style={{ textAlign: "center", marginBottom: 60 }}>{t("written_consent.label")}</SectionTitle>

          <Body paragraph>{t("written_consent.description.i_grant_permission")}</Body>
          <Body paragraph>{t("written_consent.description.i_authorize_use")}</Body>
          <Body paragraph>{t("written_consent.description.i_assign_rights")}</Body>
          <Body>{t("written_consent.description.i_release")}</Body>
        </Constraint>
      </Section>

      <Footer />
    </>
  );
};
export default TestimonialConsent;
