import { Divider, Drawer, DrawerProps, Grid, IconButton, List } from "@mui/material";
import { useTranslation } from "react-i18next";
import { nsCommonNavigation } from "src/i18n/Namespaces";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { BodyTitle } from "src/reusable_view_elements/Typography";

// Omit the onClose prop so that closeMenu can be used by both the Drawer and IconButton components
interface FullSizeMenuProps extends Omit<DrawerProps, "onClose"> {
  closeMenu: () => void;
}

/**
 * Full size menu that slides up from the bottom of the screen.
 * - Feature Story: https://www.pivotaltracker.com/story/show/185791776
 * - Child props should typically be ListItems or Dividers.
 */
const FullSizeMenu = ({ children, closeMenu, ...rest }: FullSizeMenuProps) => {
  const { t, ready } = useTranslation(nsCommonNavigation);

  const MenuTitle = () => (
    <Grid style={{ padding: "16px" }} container alignItems="center" justifyContent="space-between">
      <BodyTitle>{ready ? t("link.menu") : "Menu"}</BodyTitle>
      <IconButton
        aria-label="close"
        onClick={closeMenu}
        sx={{ padding: "12px", "& svg": { height: "24px", width: "24px" } }}
      >
        <CloseRoundedIcon />
      </IconButton>
    </Grid>
  );

  return (
    <Drawer
      anchor="bottom"
      onClose={closeMenu}
      // How to modify the MUI Drawer to be full size:
      // https://stackoverflow.com/questions/57720337/how-to-build-a-full-width-mega-menu-using-material-ui
      PaperProps={{
        style: {
          width: "100%",
          height: "100%",
          maxHeight: "unset",
          maxWidth: "unset",
        },
      }}
      {...rest}
    >
      <List>
        <MenuTitle />
        <Divider />
        {children}
      </List>
    </Drawer>
  );
};

export default FullSizeMenu;
