import { Route, Switch } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AgencyProviderListing_DTO_Response_ListView } from "src/generated/api_types";
import {
  CarinaAdminDashboardContainer,
  CarinaAdminDashboardTabs,
} from "src/pages/carinaAdmin/dashboard/carina_admin_dashboard_components/CarinaAdminDashboardContainer";
import { CarinaAdminProvidersList } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/CarinaAdminProvidersList";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { getAllProviders as getAllProvidersType } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/Queries";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useCarinaAdminProvidersDatatable,
  ClientsideDatatableContextProvider: CarinaAdminProvidersDatatableContextProvider,
} = GenerateClientsideDatatable<AgencyProviderListing_DTO_Response_ListView, typeof getAllProvidersType>();

interface CarinaAdminProviderDashboardContainerProps {
  getAllProviders: typeof getAllProvidersType;
}

export const CarinaAdminProviderDashboard: React.FC<CarinaAdminProviderDashboardContainerProps> = ({
  getAllProviders,
}) => {
  const { isUserType } = useUserSession();

  return (
    <CarinaAdminDashboardContainer tab={CarinaAdminDashboardTabs.PROVIDERS}>
      <CarinaAdminProvidersDatatableContextProvider getData={getAllProviders}>
        <Switch>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.provider_list
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.provider_list
            }
          >
            <CarinaAdminProvidersList />
          </Route>
        </Switch>
      </CarinaAdminProvidersDatatableContextProvider>
    </CarinaAdminDashboardContainer>
  );
};
