import { OnboardingDTO } from "src/generated/api_types";

export const onboardingSnackbarShouldBeShown = (onboardingState?: OnboardingDTO): boolean => {
  //If this is the last step in a users Onboarding, they should not see the confirmation Snackbar on the following page
  const completedQty = onboardingState
    ? Object.values(onboardingState.progressDTO.stepProgress).filter((stepProgress) =>
        // @ts-ignore-next-line
        stepProgress ? stepProgress.isComplete : false,
      ).length
    : 0;
  const maxQty = onboardingState ? Object.keys(onboardingState.progressDTO.stepProgress).length : 1;
  return maxQty - completedQty > 1;
};
