import { Box, Divider } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { decryptVerification as decryptVerificationType } from "src/pages/carinaAdmin/management/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import Form from "src/reusable_view_elements/form_fields/Form";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { object, string } from "yup";

interface VerificationMgmtProps {
  decryptVerification: typeof decryptVerificationType;
}

interface VerificationMgmtFormFields {
  id: string;
}

const initialValues: VerificationMgmtFormFields = {
  id: "",
};

const validationSchema = object({
  id: string().uuid(),
});

export const VerificationMgmt: React.FC<VerificationMgmtProps> = ({ decryptVerification }) => {
  const [verificationData, setVerificationData] = useState("");
  const [error, setError] = useState();

  function handleSubmit(values: VerificationMgmtFormFields, helpers: FormikHelpers<VerificationMgmtFormFields>) {
    decryptVerification(values.id)
      .then((res) => {
        setVerificationData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  }

  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.VERIFICATIONS}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form localizationReady={true}>
            <Constraint columns={8}>
              <Box p={4} />
              <SectionTitle>Lookup Verification Details</SectionTitle>
              <Box p={2} />
              <FormTextField name="id" label="ID" />
              <Box p={2} />
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                Lookup
              </Button>
              {verificationData && (
                <>
                  <Box p={4} />
                  <Divider />
                  <Box p={4} />
                  <pre>{JSON.stringify(verificationData, null, 2)}</pre>
                </>
              )}
              {error && (
                <>
                  <Box p={4} />
                  <Divider />
                  <Box p={4} />
                  <pre>{JSON.stringify(error, null, 2)}</pre>
                </>
              )}
            </Constraint>
          </Form>
        )}
      </Formik>
    </CarinaAdminMgmtContainer>
  );
};
