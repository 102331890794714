import {
  AxiosCarinaAdminFunderManagementControllerClient,
  CarinaAdminFunderManagementController_RegStepUpdateDTO,
  HttpMethod,
} from "src/generated/api_types";

const carinaAdminFunderMgmtClient = new AxiosCarinaAdminFunderManagementControllerClient();

export async function getFunders() {
  return carinaAdminFunderMgmtClient.getFunders();
}

export async function getFunder(id: string) {
  return carinaAdminFunderMgmtClient.getFunder(id);
}

export async function getRole(id: string) {
  return carinaAdminFunderMgmtClient.getRoleConfig(id);
}

export async function getRegPath(id: string) {
  return carinaAdminFunderMgmtClient.getRegPath(id);
}

export async function getRegStep(id: string) {
  return carinaAdminFunderMgmtClient.getRegStep(id);
}

export async function updateRegStep(id: string, dto: CarinaAdminFunderManagementController_RegStepUpdateDTO) {
  return carinaAdminFunderMgmtClient.updateRegStep(id, dto);
}

export async function updateApiCreds(apiConfigId: string, username: string, password: string) {
  return carinaAdminFunderMgmtClient.setApiCreds(apiConfigId, {
    username,
    password,
  });
}

export async function testApi(apiConfigId: string) {
  return carinaAdminFunderMgmtClient.testApi(apiConfigId);
}

export async function updateVerificationRegStep(data: {
  regStepId: string;
  path: string;
  method: HttpMethod;
  classRef: string;
}) {
  const { regStepId, ...rest } = data;
  return carinaAdminFunderMgmtClient.updateVerificationRegStep(regStepId, rest);
}
