import { IconButton, useMediaQuery } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

// Inherits the IconButton MUI component and adds styling that's specific to being inside a DialogHeader
// Pairs with DialogHeader
const DialogCloseIconButton = (props: IconButtonProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  return (
    <IconButton aria-label="close" {...props} size="large">
      {desktopSize ? <CloseRoundedIcon /> : <CloseRoundedIcon htmlColor={CivColors.white} />}
    </IconButton>
  );
};

export default DialogCloseIconButton;
