import { Box, Grid, useMediaQuery } from "@mui/material";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import Illustration from "src/assets/images/emans-homecare-process-explore.svg";
import theme from "src/themes/civilization/CivTheme";
import DesktopStep1 from "src/assets/tutorial/caseWorker/desktop-1-1.jpg";
import DesktopStep2 from "src/assets/tutorial/caseWorker/desktop-1-2.jpg";
import DesktopStep3a from "src/assets/tutorial/caseWorker/desktop-1-3a.jpg";
import DesktopStep3b from "src/assets/tutorial/caseWorker/desktop-1-3b.jpg";
import MobileStep1a from "src/assets/tutorial/caseWorker/mobile-1-1a.jpg";
import MobileStep1b from "src/assets/tutorial/caseWorker/mobile-1-1b.jpg";
import MobileStep2a from "src/assets/tutorial/caseWorker/mobile-1-2a.jpg";
import MobileStep2b from "src/assets/tutorial/caseWorker/mobile-1-2b.jpg";
import MobileStep3a from "src/assets/tutorial/caseWorker/mobile-1-3a.jpg";
import MobileStep3b from "src/assets/tutorial/caseWorker/mobile-1-3b.jpg";

const ViewProviders = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>View Provider Profiles</SectionTitle>
            <Body paragraph>
              As a case worker, you can view jobs and providers, and familiarize yourself with how the service works.
            </Body>
            <Body>You can search and bookmark providers on Carina, however, you will not be able message anyone.</Body>
          </Grid>
          {onMobile && (
            <>
              <Box p={2} />
              <Grid item xs={12} sm={5}>
                <img src={Illustration} width="100%" alt="" />
              </Grid>
            </>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Go to &#34;Find Providers&#34;</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                To view provider profiles listed on Carina, click &#34;Find Providers&#34; at the top of the page or on
                &#34;Search for providers&#34; in the &#34;Saved Providers&#34; section.
              </Body>
            </Constraint>
            <img src={DesktopStep1} width="100%" alt="" />
          </>
        )}

        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                To view providers on Carina, click &#34;Menu&#34; at the top of the page, then click &#34;Find
                Providers&#34;.
              </Body>
            </Constraint>
            <img src={MobileStep1a} width="100%" alt="" />
            <Box p={1} />
            <img src={MobileStep1b} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Refine your search</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>Use the sort feature on the top and the filters on the left to refine your search.</Body>
            </Constraint>
            <img src={DesktopStep2} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Click on &#34;Filter&#34; and/or &#34;Sort&#34; to see a list of what you can filter and sort by to
                refine your search.
              </Body>
            </Constraint>
            <img src={MobileStep2a} width="100%" alt="" />
            <Constraint columns={6} pt={6} pb={4}>
              <Body>Here is an example of what you&apos;ll see when you click &#34;Filter&#34;.</Body>
            </Constraint>
            <img src={MobileStep2b} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Learn more about a provider</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                On this page, you&apos;ll see provider profiles that match your filters. To learn more about each
                provider, click &#34;View Profile&#34;.
              </Body>
            </Constraint>
            <img src={DesktopStep3a} width="100%" alt="" />
            <Constraint columns={6} pt={6}>
              <Body paragraph>
                Clicking &#34;View Profile&#34; allows you to view a specific provider&apos;s skills, experience,
                interests and other information.
              </Body>
            </Constraint>
            <img src={DesktopStep3b} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                On this page, you&apos;ll see providers that match your filters. To learn more about each provider,
                click &#34;View Profile&#34;.
              </Body>
            </Constraint>
            <img src={MobileStep3a} width="100%" alt="" />
            <Constraint columns={6} pt={6}>
              <Body paragraph>
                Clicking &#34;View Profile&#34; allows you to view a specific provider&apos;s skills, experience,
                interests and other information.
              </Body>
            </Constraint>
            <img src={MobileStep3b} width="100%" alt="" />
          </>
        )}
      </Segment>
    </>
  );
};

export default ViewProviders;
