import { CheckboxProps, FormControl, FormGroup, FormLabel as MFormLabel } from "@mui/material";
import { FastFieldProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorGuidance from "src/reusable_view_elements/ErrorGuidance";
import {
  Checkbox,
  CheckboxControlLabel,
  CheckboxOption,
  FormHelperText,
} from "src/reusable_view_elements/form_fields/CheckboxField";
import {
  ControllableFastField,
  ControllableFastFieldProps,
} from "src/reusable_view_elements/form_fields/ControllableFastField";
import { Body, pBodyEmphasis, withAttrs } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

type ValidDataType = string | number | boolean;

type CheckboxesProps<T> = {
  disabled?: (checkboxValue: T) => boolean;
} & Omit<CheckboxProps, "disabled">;

type FormCheckboxGroupFieldProps<T> = {
  name: string;
  label?: string;
  options: CheckboxOption<T>[];
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  checkboxesProps?: CheckboxesProps<T>;
} & Omit<ControllableFastFieldProps<any>, "languagePeek">;

const FormLabel = withAttrs(pBodyEmphasis, {
  component: MFormLabel,
  sx: {
    color: CivColors.coreDarkNavy,
    "&.Mui-disabled": { color: CivColors.mediumGray },
  },
});

export const FormCheckboxGroupField = <T extends ValidDataType>({
  checkboxesProps,
  ...props
}: FormCheckboxGroupFieldProps<T>) => {
  const { t, ready } = useTranslation();
  //'meta.touched' gets triggered when the user clicks the submit button
  //'touched' gets triggered when the user clicks on a checkbox
  const [touched, setTouched] = useState(false);

  return (
    <ControllableFastField
      name={props.name}
      controlledRerender={props.controlledRerender}
      languagePeek={props.label ?? "--"}
    >
      {({ field, meta }: FastFieldProps<T[]>) => {
        return (
          <FormControl disabled={props.disabled} required={props.required}>
            <FormLabel>{props.label}</FormLabel>
            <FormGroup>
              {props.options.map(({ label, value, locizeKey, namespace }, key) => (
                <CheckboxControlLabel
                  key={key}
                  label={ready && locizeKey && namespace ? t(locizeKey, { ns: namespace }) : label}
                  control={
                    <Checkbox
                      {...checkboxesProps}
                      name={props.name}
                      checked={field.value.includes(value)}
                      value={value}
                      onChange={(event, checked) => {
                        field.onChange(event);
                        checkboxesProps?.onChange && checkboxesProps.onChange(event, checked);
                        setTouched(true);
                      }}
                      inputProps={{
                        // @ts-ignore
                        "data-testid": `${props.name}.${value}`,
                      }}
                      disabled={props.disabled || (checkboxesProps?.disabled ? checkboxesProps.disabled(value) : false)}
                    />
                  }
                />
              ))}
            </FormGroup>
            {(props.helperText || Boolean(meta.error && (touched || meta.touched))) && (
              <FormHelperText disabled={props.disabled} error={Boolean(meta.error && (touched || meta.touched))}>
                {meta.error && (touched || meta.touched) ? (
                  <ErrorGuidance helperText={meta.error} />
                ) : (
                  <Body>{props.helperText}</Body>
                )}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </ControllableFastField>
  );
};
