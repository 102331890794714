import { Box, BoxProps, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsMedicaidInbox } from "src/i18n/Namespaces";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";

export const EmptyContainer = (props: BoxProps) => {
  const desktopSize = useMediaQuery(DesktopMinWidth);
  return (
    <Box paddingX="16px" textAlign="center" paddingY={desktopSize ? "200px" : "64px"} {...props}>
      {props.children}
    </Box>
  );
};

export const EmptyInbox = ({ title }: { title: string }) => {
  const { isUserType } = useUserSession();
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const { t } = useTranslation([nsMedicaidInbox, nsCommonFormsBtns]);

  if (
    isUserType(UserType.Consumer) ||
    isUserType(UserType.MedicaidReferralCoordinator) ||
    isUserType(UserType.MedicaidCaseManager)
  ) {
    return (
      <EmptyContainer>
        <SectionTitle>{title}</SectionTitle>
        <Body paragraph>{t("you_have_zero_messages.description_jobposter_view", { ns: nsMedicaidInbox })}</Body>
        <Grid
          container
          direction={desktopSize ? "row" : "column-reverse"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
              {t("button.find_a_provider", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
          </Grid>
          <Grid item>
            <LinkStyledAsButton
              variant="contained"
              component={Link}
              to={getMedicaidRouteByUserType("myJobList", isUserType)}
            >
              {t("button.view_my_jobs", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
          </Grid>
        </Grid>
      </EmptyContainer>
    );
  }

  if (isUserType(UserType.Provider)) {
    return (
      <EmptyContainer>
        <SectionTitle>{title}</SectionTitle>
        <Body paragraph>{t("you_have_zero_messages.description_provider_view", { ns: nsMedicaidInbox })}</Body>
        <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
          {t("button.find_a_consumer", { ns: nsCommonFormsBtns })}
        </LinkStyledAsButton>
      </EmptyContainer>
    );
  }

  return (
    <EmptyContainer>
      <SectionTitle>{title}</SectionTitle>
      <Body paragraph>{t("you_have_zero_messages.description_generic_view", { ns: nsMedicaidInbox })}</Body>
      <Grid container spacing={2} style={{ margin: "0px auto" }} justifyContent="center" alignItems="center">
        <Grid item>
          <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
            {t("button.find_a_provider", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Grid>
        <Grid>
          <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
            {t("button.find_a_consumer", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Grid>
      </Grid>
    </EmptyContainer>
  );
};
