import { Form as FormikForm } from "formik";
import { FormikFormProps } from "formik/dist/Form";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";

export type FormProps = {
  localizationReady: boolean;
} & FormikFormProps;
const Form = ({ children, localizationReady, ...formikFormProps }: FormProps) => {
  if (!localizationReady) return <LoadingCircle />;

  return (
    <FormikForm noValidate {...formikFormProps}>
      {children}
    </FormikForm>
  );
};

export default Form;
