/**************************************************
 *                                                 *
 * This is a generated file. Please do not modify. *
 *                                                 *
 ***************************************************/
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as Circle1Filled } from "./Custom/Circle-1-Filled.svg";
import { ReactComponent as Equity } from "./Custom/Equity.svg";
import { ReactComponent as Circle1Outlined } from "./Custom/Circle-1-Outlined.svg";
import { ReactComponent as Circle3Filled } from "./Custom/Circle-3-Filled.svg";
import { ReactComponent as Circle2Outlined } from "./Custom/Circle-2-Outlined.svg";
import { ReactComponent as Circle5Outlined } from "./Custom/Circle-5-Outlined.svg";
import { ReactComponent as Circle4Outlined } from "./Custom/Circle-4-Outlined.svg";
import { ReactComponent as Circle3Outlined } from "./Custom/Circle-3-Outlined.svg";
import { ReactComponent as Circle2Filled } from "./Custom/Circle-2-Filled.svg";

export const Circle1FilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle1Filled />
  </SvgIcon>
);
export const EquityIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Equity />
  </SvgIcon>
);
export const Circle1OutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle1Outlined />
  </SvgIcon>
);
export const Circle3FilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle3Filled />
  </SvgIcon>
);
export const Circle2OutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle2Outlined />
  </SvgIcon>
);
export const Circle5OutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle5Outlined />
  </SvgIcon>
);
export const Circle4OutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle4Outlined />
  </SvgIcon>
);
export const Circle3OutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle3Outlined />
  </SvgIcon>
);
export const Circle2FilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Circle2Filled />
  </SvgIcon>
);
