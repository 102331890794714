import { PropsWithChildren } from "react";
import CivColors from "src/themes/civilization/CivColors";

export const InlineDivider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderBottom: `1px solid ${CivColors.mediumGray}`,
          width: "100%",
        }}
      />
      <span style={{ padding: "0px 0px 0px 0px" }}>{children}</span>
      <div
        style={{
          borderBottom: `1px solid ${CivColors.mediumGray}`,
          width: "100%",
        }}
      />
    </div>
  );
};
