import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostListDTO } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidInbox,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { QuestionCircleIcon } from "src/assets/icons/StreamlineIcons";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import { medicaidMessagingClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import { Button } from "src/reusable_view_elements/Button";
import DialogButtons from "src/reusable_view_elements/dialog/DialogButtons";
import { FormSelectField, FormTextField } from "src/reusable_view_elements/form_fields";
import { Checkbox, CheckboxControlLabel, CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Popover from "src/reusable_view_elements/Popover";
import { Body } from "src/reusable_view_elements/Typography";
import { phoneRegExp, roleCanPostAJob } from "src/utilities/GeneralUtilities";
import { boolean, object, string } from "yup";
import { getMyJobPosts } from "../consumerAccount/Queries";

enum PhoneNumberFieldType {
  OPTIONAL,
  REQUIRED,
  NONE,
}

// DTO between the form and the handleSubmit function
interface MessageFormFields {
  message: string;
  phoneNumber?: string;
  selectedJobPostId?: string;
  approvalAcknowledgement?: boolean;
}

// Component Props
interface FirstMessageFormProps {
  context: FirstMessageContext;
  recipientId: string;
  recipientRole: UserType;
  contextJobPostId?: string;
  closeDialog: (threadId?: string) => void;
}

const FirstMessageForm = ({
  context,
  recipientId,
  recipientRole,
  contextJobPostId,
  closeDialog,
}: FirstMessageFormProps) => {
  const { isUserType, userSession } = useUserSession();
  const { lastMessageSent, setLastMessageSent } = useMedicaidContext();
  const { showSnackbar } = useNotification();
  const { t, ready } = useTranslation([
    nsMedicaidInbox,
    nsCommonFormsBtns,
    nsCommonTooltips,
    nsMedicaidTagsEnums,
    nsCommonToasts,
  ]);

  // Only applicable if the sender can post jobs and the context is not a job post
  const [senderJobPosts, setSenderJobPosts] = useState<JobPostListDTO[]>();
  const [isLoadingJobPosts, setLoadingJobPosts] = useState<boolean>(false);

  // Render Logic
  const showJobSelectorField = !contextJobPostId && senderJobPosts && senderJobPosts.length > 1;
  const phoneNumberFieldType = getPhoneNumberFieldType(context, recipientRole, userSession?.role as UserType);
  const [sendPhoneNumberIsChecked, setSendPhoneNumberCheckbox] = useState<boolean>(false);
  const showApprovalCheckboxField =
    context === FirstMessageContext.JOB_POST && (isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider));

  useEffect(() => {
    // If the sender is not on a job post, then retrieve the sender's job posts
    if (
      !contextJobPostId &&
      userSession &&
      roleCanPostAJob(userSession.role) &&
      context !== FirstMessageContext.REFERRAL_COORDINATOR_TEAM
    ) {
      setLoadingJobPosts(true);
      // TODO: https://github.com/CarinaWeb/CarinaCore/issues/1006 - Replace with a lighter weight endpoint
      getMyJobPosts()
        .then((res) => {
          setSenderJobPosts(res.data);
        })
        .catch(() => {
          showSnackbar(t("error.loading_your_active_jobs", { ns: nsCommonToasts }), "error");
        })
        .finally(() => {
          setLoadingJobPosts(false);
        });
    }
  }, []);

  function handleCancelClick() {
    closeDialog();
  }

  function handleSubmit(values: MessageFormFields) {
    setLastMessageSent(values.message);
    const jobPostToSubmit = getJobPostToSubmit({
      contextJobPostId,
      selectedJobPostId: values.selectedJobPostId,
      senderJobPosts,
    });
    const phoneNumberToSubmit = values.phoneNumber ? values.phoneNumber : undefined;

    medicaidMessagingClient
      .sendInitialMessage({
        otherUserId: recipientId,
        messageText: values.message,
        phone: phoneNumberToSubmit,
        jobPostId: jobPostToSubmit,
      })
      .then((resp) => {
        showSnackbar(
          {
            messageTitle: t("success.message_sent_title", { ns: nsCommonToasts }),
            messageBody: t("success.message_sent_description", { ns: nsCommonToasts }),
          },
          "success",
          true,
          false,
        );
        closeDialog(resp.data.threadId);
      })
      .catch(() => {
        showSnackbar(t("error.sending_your_message", { ns: nsCommonToasts }), "error");
      });
  }

  const maxMessageLength = 500;
  const validationSchema = object({
    message: string()
      .label(t("field.message.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(maxMessageLength, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    phoneNumber: (() => {
      if (
        (phoneNumberFieldType === PhoneNumberFieldType.OPTIONAL && sendPhoneNumberIsChecked) ||
        phoneNumberFieldType === PhoneNumberFieldType.REQUIRED
      ) {
        return string()
          .label(t("field.phone_number.label", { ns: nsCommonFormsBtns }))
          .required(t("field.phone_number.label", { ns: nsCommonFormsBtns }))
          .matches(phoneRegExp, { message: t("field.phone_number.error", { ns: nsCommonFormsBtns }) });
      }
      return string().notRequired();
    })(),
    selectedJobPostId: (() => {
      if (showJobSelectorField) {
        return string()
          .label(t("field.select_job_dropdown.label", { ns: nsCommonFormsBtns }))
          .required(t("field.select_job_dropdown.error", { ns: nsCommonFormsBtns }));
      }
      return string().notRequired();
    })(),
    approvalAcknowledgement: (() => {
      if (showApprovalCheckboxField) {
        return boolean()
          .label(
            t(
              userSession?.funder.segment === "HC Medicaid-WA"
                ? "message_modal_acknowledgement_checkbox.label_wa"
                : "message_modal_acknowledgement_checkbox.label_case_manager",
              { ns: nsMedicaidInbox },
            ),
          )
          .oneOf(
            [true],
            t(
              userSession?.funder.segment === "HC Medicaid-WA"
                ? "message_modal_acknowledgement_checkbox.error_wa"
                : "message_modal_acknowledgement_checkbox.error_case_manager",
              { ns: nsMedicaidInbox },
            ),
          );
      }
      return boolean().notRequired();
    })(),
  });

  if (isLoadingJobPosts || !ready) {
    return <LoadingCircle />;
  }

  return (
    <Formik<MessageFormFields>
      validationSchema={validationSchema}
      initialValues={{ message: lastMessageSent, approvalAcknowledgement: false, selectedJobPostId: "" }}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => (
        <Form localizationReady={ready}>
          <FormTextField
            name="message"
            label={t("field.message.label", { ns: nsCommonFormsBtns })}
            placeholder={t("field.message.placeholder", { ns: nsCommonFormsBtns })}
            multiline
            minRows={5}
            helperText={""}
            maximumlength={maxMessageLength}
            required
          />
          {showJobSelectorField && senderJobPosts && (
            <FormSelectField
              name="selectedJobPostId"
              label={t("field.select_job_dropdown.label", { ns: nsCommonFormsBtns })}
              required
              selectOptions={senderJobPosts.map((job) => ({
                label: job.entity.firstName
                  ? `#${job.entity.jobNumber.toString()}: ${job.entity.firstName}`
                  : `#${job.entity.jobNumber.toString()}`,
                value: job.entity.id,
              }))}
            />
          )}
          {phoneNumberFieldType === PhoneNumberFieldType.OPTIONAL && (
            <Box marginTop={2}>
              <CheckboxControlLabel
                // Purpose: Control showing or hiding the optional phone number field
                // It is NOT part of the Formik form
                control={
                  <Checkbox
                    name="sendPhoneNumber"
                    value={sendPhoneNumberIsChecked}
                    onClick={() => {
                      if (values.phoneNumber && values.phoneNumber?.length > 0) {
                        setFieldValue("phoneNumber", undefined); // Clear phone field when toggling checkbox
                      }
                      setSendPhoneNumberCheckbox(!sendPhoneNumberIsChecked);
                    }}
                  />
                }
                label={t("field.send_my_phone.label", { ns: nsCommonFormsBtns })}
                style={{ paddingTop: 0 }}
              />
              {sendPhoneNumberIsChecked && (
                <FastMaskTextField
                  name="phoneNumber"
                  maskType="phone"
                  label={t("field.phone_number.label", { ns: nsCommonFormsBtns })}
                  required
                />
              )}
            </Box>
          )}
          {phoneNumberFieldType === PhoneNumberFieldType.REQUIRED && (
            <Grid container spacing={1} wrap="nowrap">
              <Grid item xs>
                <FastMaskTextField
                  name="phoneNumber"
                  maskType="phone"
                  label={t("field.phone_number.label", { ns: nsCommonFormsBtns })}
                  required
                />
              </Grid>
              <Grid item direction="column" sx={{ alignContent: "start", marginTop: "36px" }}>
                <Popover
                  content={
                    <Body paragraph>
                      {t("phone_number_required_when_behalf.description", {
                        ns: nsCommonTooltips,
                        roles: (() => {
                          let rolePluralKey = "user_plural";
                          if (recipientRole === UserType.MedicaidReferralCoordinator) {
                            rolePluralKey = "medicaid_referral_coordinator_plural";
                          }
                          if (recipientRole === UserType.MedicaidCaseManager) {
                            rolePluralKey = "case_manager_plural";
                          }
                          return t(rolePluralKey, { ns: nsMedicaidTagsEnums });
                        })(),
                      })}
                    </Body>
                  }
                  contentId="phone-number-popover"
                >
                  <QuestionCircleIcon />
                </Popover>
              </Grid>
            </Grid>
          )}
          {showApprovalCheckboxField && (
            <CheckboxField
              name="approvalAcknowledgement"
              id="approvalAcknowledgement"
              label={
                <Body>
                  {t(
                    userSession?.funder.segment === "HC Medicaid-WA"
                      ? "message_modal_acknowledgement_checkbox.label_wa"
                      : "message_modal_acknowledgement_checkbox.label_case_manager",
                    { ns: nsMedicaidInbox },
                  )}
                </Body>
              }
              helperText={errors.approvalAcknowledgement ? errors.approvalAcknowledgement : ""}
              error={!!errors.approvalAcknowledgement}
              style={{ marginTop: 16 }}
            />
          )}
          <FormAlert schema={validationSchema} sx={{ margin: "24px 0" }} />
          <DialogButtons>
            <Button variant="outlined" type="button" onClick={handleCancelClick}>
              {t("button.cancel", { ns: nsCommonFormsBtns })}
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{ marginLeft: "24px", padding: "14px 34px" }}
              disabled={isSubmitting}
            >
              {t(isSubmitting ? "button.sending" : "button.send", { ns: nsCommonFormsBtns })}
            </Button>
          </DialogButtons>
        </Form>
      )}
    </Formik>
  );
};

export default FirstMessageForm;

function getPhoneNumberFieldType(
  context: FirstMessageContext,
  recipientRole: UserType,
  senderRole: UserType,
): PhoneNumberFieldType {
  // Never show the phone number field when sender is a user support agent or user support manager
  if (senderRole === UserType.UserSupportAgent || senderRole === UserType.UserSupportManager) {
    return PhoneNumberFieldType.NONE;
  }

  // Phone number is optional on:
  // - provider profiles and proxy provider profiles
  // - job posts by consumers and job posts by proxy providers
  if (context === FirstMessageContext.PROVIDER_PROFILE) {
    return PhoneNumberFieldType.OPTIONAL;
  }
  if (
    context === FirstMessageContext.JOB_POST &&
    (recipientRole === UserType.Consumer || recipientRole === UserType.ProxyProvider)
  ) {
    return PhoneNumberFieldType.OPTIONAL;
  }

  // Phone number is required on job posts by referral coordinators and case managers
  if (
    context === FirstMessageContext.JOB_POST &&
    (recipientRole === UserType.MedicaidReferralCoordinator || recipientRole === UserType.MedicaidCaseManager)
  ) {
    return PhoneNumberFieldType.REQUIRED;
  }

  // In all other cases, do not display phone number field
  return PhoneNumberFieldType.NONE;
}

function getJobPostToSubmit({
  contextJobPostId,
  selectedJobPostId,
  senderJobPosts,
}: {
  contextJobPostId?: string;
  selectedJobPostId?: string;
  senderJobPosts?: JobPostListDTO[];
}): string | undefined {
  if (contextJobPostId) {
    return contextJobPostId;
  }
  if (selectedJobPostId) {
    return selectedJobPostId;
  }
  if (senderJobPosts?.length === 1) {
    return senderJobPosts[0].entity.id;
  }
  return undefined;
}
