import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CivColors from "src/themes/civilization/CivColors";

// MUI5 does not support styled(Typography).attrs() yet, so attempting this workaround:
// https://github.com/mui/material-ui/issues/29492#issuecomment-993423755

// @ts-ignore
// eslint-disable-next-line react/display-name
export const withAttrs = (Component, attrs) => (props) => <Component {...attrs} {...props} />;

export const H1ImpactTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rooney-Medium, serif",
  fontSize: "2.4rem",
  lineHeight: 1.125,
  letterSpacing: 0,
  marginBottom: theme.spacing(3),
  fontVariantNumeric: "lining-nums",
}));

export const ImpactTitle = withAttrs(H1ImpactTitle, { component: "h1" });

export const H2SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rooney-Medium, serif",
  fontSize: "2.0rem",
  lineHeight: 1.15,
  marginBottom: theme.spacing(3),
  fontVariantNumeric: "lining-nums",
}));

export const SectionTitle = withAttrs(H2SectionTitle, { component: "h2" });

export const H3HeaderTitle = styled(Typography)(() => ({
  fontFamily: "Rooney-Medium, serif",
  color: CivColors.white,
  fontSize: "1.4rem",
  lineHeight: 1.143,
  textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
  fontVariantNumeric: "lining-nums",
}));

export const HeaderTitle = withAttrs(H3HeaderTitle, { component: "h3" });

export const basicBodyStyle = {
  fontFamily: "Europa-Regular, sans-serif",
  fontSize: "1.0rem",
  lineHeight: 1.2,
  fontVariantLigatures: "no-contextual",
};

export const pBody = styled(Typography)(({ theme, paragraph }) => ({
  ...basicBodyStyle,
  ...(paragraph && {
    marginBottom: theme.spacing(3),
  }),
}));

export const Body = withAttrs(pBody, { component: "p" });

export const basicBodyEmphasisStyle = {
  fontFamily: "Europa-Bold, sans-serif",
  fontSize: "1.0rem",
  lineHeight: 1.2,
  fontVariantLigatures: "no-contextual",
};

export const pBodyEmphasis = styled(Typography)(({ theme, paragraph }) => ({
  ...basicBodyEmphasisStyle,
  ...(paragraph && {
    marginBottom: theme.spacing(3),
  }),
}));

export const BodyEmphasis = withAttrs(pBodyEmphasis, { component: "p" });

export const pBodyEmphasisHeading = styled(Typography)(({ theme, paragraph }) => ({
  fontFamily: "Europa-Bold, sans-serif",
  fontSize: "1.0rem",
  lineHeight: 1.2,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  fontVariantLigatures: "no-contextual",
  ...(paragraph && {
    marginBottom: theme.spacing(3),
  }),
}));

export const BodyEmphasisHeading = withAttrs(pBodyEmphasisHeading, { component: "p" });

export const H3BodyTitle = styled(Typography)(({ theme, paragraph }) => ({
  fontFamily: "Europa-Bold, sans-serif",
  fontSize: "1.4rem",
  lineHeight: 1.143,
  fontVariantLigatures: "no-contextual",
  ...(paragraph && {
    marginBottom: theme.spacing(3),
  }),
}));

export const BodyTitle = withAttrs(H3BodyTitle, { component: "h3" });

export const H4PanelTitle = styled(Typography)(({ theme, paragraph }) => ({
  fontFamily: "Europa-Bold, sans-serif",
  fontSize: "1.2rem",
  lineHeight: 1.167,
  fontVariantLigatures: "no-contextual",
  ...(paragraph && {
    marginBottom: theme.spacing(2),
  }),
}));

export const PanelTitle = withAttrs(H4PanelTitle, { component: "h4" });

export const PDescriptionEmphasis = styled(Typography)(() => ({
  fontFamily: "Europa-Bold, sans-serif",
  fontSize: "1.0rem",
}));

export const DescriptionEmphasis = withAttrs(PDescriptionEmphasis, { component: "p" });

export const H5QuoteHeader = styled(Typography)(({ theme, paragraph }) => ({
  fontFamily: "Europa-Regular, sans-serif",
  fontSize: "0.8rem",
  lineHeight: 1,
  textTransform: "uppercase",
  fontVariantLigatures: "no-contextual",
  ...(paragraph && {
    marginBottom: theme.spacing(3),
  }),
}));

export const QuoteHeader = withAttrs(H5QuoteHeader, { component: "h5" });

export const PQuote = styled(Typography)(({ theme, paragraph }) => ({
  fontFamily: "Rooney-MediumItalic, serif",
  fontSize: "1.4rem",
  lineHeight: 1.36,
  ...(paragraph && {
    marginBottom: theme.spacing(3),
    fontVariantNumeric: "lining-nums",
  }),
}));
export const Quote = withAttrs(PQuote, { component: "p" });

export const PQuoteAttribution = styled(Typography)(() => ({
  fontFamily: "Europa-Regular, sans-serif",
  fontSize: "1.0rem",
  lineHeight: 1.2,
  fontVariantLigatures: "no-contextual",
}));

export const QuoteAttribution = withAttrs(PQuoteAttribution, { component: "p" });
