import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

export const DeleteJobFromNotifConfirmation = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  return (
    <>
      <Helmet>
        <title>Carina | Medicaid Delete Job From Notification</title>
      </Helmet>

      <NavBar />

      <main id="main-content">
        <Section
          bgcolor={CivColors.lightGray}
          style={desktopSize ? { paddingTop: "200px", paddingBottom: "200px" } : {}}
        >
          <Constraint columns={8} textAlign="center">
            <SectionTitle>You successfully deleted your job post</SectionTitle>
            <Body paragraph style={{ margin: 10 }}>
              You can always repost this job if you are still looking for a provider.
            </Body>
          </Constraint>
        </Section>
      </main>

      <Footer englishOnly />
    </>
  );
};
