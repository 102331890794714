import { AlertColor } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { createContainer } from "unstated-next";

interface InitialConfig {
  alertType: "error" | "info" | "success" | "warning";
  message: string;
  autoHideTime: number;
  title: string;
}

function notificationContext(initialState?: InitialConfig) {
  const defaultAutoHideTime = initialState?.autoHideTime || 20 * 1000;
  const [alertType, setAlertType] = useState(initialState?.alertType || "info");
  const [title, setTitle] = useState(initialState?.title);
  const [message, setMessage] = useState(initialState?.message);
  const [isOpen, setIsOpen] = useState(false);
  const [autoHideTime, setAutoHideTime] = useState<number | null>(defaultAutoHideTime);
  const [useMainSnackbar, setUseMainSnackbar] = useState(true);

  type SnackbarMessage = string | { messageBody: string; messageTitle: string };
  const showSnackbar = (
    _message: SnackbarMessage,
    _alertType: AlertColor = "info",
    shouldAutoHide: boolean = true,
    _useMainSnackbar: boolean = true,
  ) => {
    setUseMainSnackbar(_useMainSnackbar);
    if (typeof _message === "string") {
      setTitle("");
      setMessage(_message);
    } else {
      setTitle(_message.messageTitle || "");
      setMessage(_message.messageBody);
    }
    setAlertType(_alertType);
    if (shouldAutoHide) {
      setAutoHideTime(defaultAutoHideTime);
    } else setAutoHideTime(null);
    setIsOpen(true);
  };

  const closeSnackbar = () => setIsOpen(false);

  return {
    alertType,
    title,
    message,
    isOpen,
    autoHideTime,
    showSnackbar,
    closeSnackbar,
    useMainSnackbar,
  };
}

const NotificationContext = createContainer(notificationContext);

interface NotificationContextProviderProps {
  initialState?: InitialConfig;
}

export const NotificationContextProvider: React.FC<PropsWithChildren<NotificationContextProviderProps>> = ({
  initialState,
  children,
}) => <NotificationContext.Provider initialState={initialState}>{children}</NotificationContext.Provider>;

export const useNotification = () => {
  const { ...context } = NotificationContext.useContainer();

  return {
    ...context,
  };
};
