import { Redirect, Route, Switch } from "react-router-dom";
import MerlinPage from "src/pages/carinaAdmin/dashboard/merlin/MerlinPage";
import { FunderMgmtDetail } from "src/pages/carinaAdmin/management/funders/FunderMgmtDetail";
import { FunderMgmtRegPathDetail } from "src/pages/carinaAdmin/management/funders/FunderMgmtRegPathDetail";
import { FunderMgmtRoleDetail } from "src/pages/carinaAdmin/management/funders/FunderMgmtRoleDetail";
import { FunderMgmtRegStepDetail } from "src/pages/carinaAdmin/management/funders/FunderMgmtRegStepDetail";
import { FunderMgmtRoot } from "src/pages/carinaAdmin/management/funders/FunderMgmtRoot";
import {
  getFunder,
  getFunders,
  getRegPath,
  getRegStep,
  getRole,
  updateRegStep,
} from "src/pages/carinaAdmin/management/funders/Queries";
import { decryptVerification, urlShortener } from "src/pages/carinaAdmin/management/Queries";
import { URLShortener } from "src/pages/carinaAdmin/management/URLShortener";
import { VerificationMgmt } from "src/pages/carinaAdmin/management/VerificationMgmt";

const CARINA_ADMIN_MGMT_ROUTE_PREFIX = "/admin/mgmt";
export const CARINA_ADMIN_MGMT_ROUTES = {
  root: CARINA_ADMIN_MGMT_ROUTE_PREFIX,
  funder_mgmt: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/funders`,
  funder_details: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/funder/:id`,
  funder_role_details: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/funder/role/:id`,
  funder_reg_path_details: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/funder/role/regPath/:id`,
  funder_reg_step_details: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/funder/role/regPath/regStep/:id`,
  verification_mgmt: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/verification`,
  url_shortener: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/url_shortener`,
  merlin: `${CARINA_ADMIN_MGMT_ROUTE_PREFIX}/merlin`,
};

const CarinaAdminMgmtRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.funder_mgmt}>
        <FunderMgmtRoot getFunders={getFunders} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.funder_details}>
        <FunderMgmtDetail getFunder={getFunder} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.funder_role_details}>
        <FunderMgmtRoleDetail getRole={getRole} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.funder_reg_path_details}>
        <FunderMgmtRegPathDetail getRegPath={getRegPath} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.funder_reg_step_details}>
        <FunderMgmtRegStepDetail getRegStep={getRegStep} updateRegStep={updateRegStep} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.verification_mgmt}>
        <VerificationMgmt decryptVerification={decryptVerification} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.url_shortener}>
        <URLShortener urlShortener={urlShortener} />
      </Route>
      <Route exact path={CARINA_ADMIN_MGMT_ROUTES.merlin}>
        <MerlinPage />
      </Route>
      <Redirect to={CARINA_ADMIN_MGMT_ROUTES.funder_mgmt} />
    </Switch>
  );
};

export default CarinaAdminMgmtRouter;
