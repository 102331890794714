import { Box } from "@mui/material";
import { AxiosError } from "axios";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import {
  nsCommonFormsBtns,
  nsCommonLogin,
  nsCommonToasts,
  nsMedicaidAccountPortal,
  nsMedicaidJobPost,
  nsMedicaidProviderProfile,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { AuthPageContainer } from "src/pages/auth/AuthPageContainer";
import { postLogin as postLoginType } from "src/pages/auth/AuthRequests";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import BlockedUserErrorBox from "src/reusable_view_elements/BlockedUserErrorBox";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormPasswordField } from "src/reusable_view_elements/form_fields/FormPasswordField";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { InternalLink } from "src/reusable_view_elements/Link";
import LoginFailedErrorBox from "src/reusable_view_elements/LoginFailedErrorBox";
import { Button } from "src/reusable_view_elements/Button";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

interface LoginProps {
  postLogin: typeof postLoginType;
}

interface LoginFields {
  email: string;
  password: string;
}

export const Login: React.FC<LoginProps> = ({ postLogin }) => {
  const { reloadAuthState } = useUserSession();
  const { showSnackbar } = useNotification();
  const [invalidLogin, setInvalidLogin] = useState(false);
  // load namespaces used in AccountPortalMain cards so immediate lang change has ns context
  const { t, ready } = useTranslation([
    nsCommonLogin,
    nsCommonToasts,
    nsCommonFormsBtns,
    nsMedicaidAccountPortal,
    nsMedicaidProviderProfile,
    nsMedicaidJobPost,
    nsMedicaidTagsEnums,
  ]);
  const [isBlocked, setIsBlocked] = useState(false);

  const initialValues: LoginFields = {
    email: "",
    password: "",
  };

  const validationSchema = object({
    email: string()
      .label(t("field.email.label.email", { ns: nsCommonFormsBtns }))
      .required(t("field.email.error.valid", { ns: nsCommonFormsBtns }))
      .email(t("field.email.error.valid", { ns: nsCommonFormsBtns })),
    password: string()
      .label(t("field.password.label", { ns: nsCommonFormsBtns }))
      .required(t("field.password.error_required_enter", { ns: nsCommonFormsBtns })),
  });

  function handleSubmit(values: LoginFields, { setSubmitting }: FormikHelpers<LoginFields>) {
    setInvalidLogin(false);
    setIsBlocked(false);
    postLogin(values)
      .then((res) => {
        if (res.status === 200) {
          reloadAuthState();
          showSnackbar(t("success.youve_successfully_logged_into", { ns: nsCommonToasts }), "success");
        } else {
          setInvalidLogin(true);
          setSubmitting(false);
        }
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 451) setIsBlocked(true);
        else setInvalidLogin(true);
        setSubmitting(false);
      });
  }

  return (
    <AuthPageContainer>
      <Helmet>
        <title>Carina | Login</title>
      </Helmet>
      {ready && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form data-testid="login-form" localizationReady={ready}>
              <Box maxWidth={450} margin="0px auto">
                <SectionTitle align="center">{t("welcome_back.label", { ns: nsCommonLogin })}</SectionTitle>
              </Box>

              <Box maxWidth={450} margin="0px auto">
                <FormTextField
                  name="email"
                  label={t("field.email.label.email", { ns: nsCommonFormsBtns })}
                  required={isRequiredField("email", validationSchema)}
                />
                <Box p={1} />
                <FormPasswordField
                  name="password"
                  label={t("field.password.label", { ns: nsCommonFormsBtns })}
                  required={isRequiredField("password", validationSchema)}
                />

                {invalidLogin && <LoginFailedErrorBox sx={{ mt: 2 }} />}
                {isBlocked && <BlockedUserErrorBox sx={{ mt: 2 }} />}
                <FormAlert schema={validationSchema} sx={{ mt: 2 }} />

                <Box textAlign="center" mt={2}>
                  <Button variant="contained" type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? t("button.logging_in", { ns: nsCommonFormsBtns })
                      : t("button.login", { ns: nsCommonFormsBtns })}
                  </Button>
                  <Box p={2} />
                  <Body>
                    <InternalLink to={ROUTES.forgotPassword}>
                      {t("button.forgot_your_password", { ns: nsCommonFormsBtns })}
                    </InternalLink>
                  </Body>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </AuthPageContainer>
  );
};
