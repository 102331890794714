import { Grid } from "@mui/material";
import { withFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { PPHCCarinaAdminDashboardFundersController_FunderAdminDetails } from "src/generated/api_types";
import { requestPasswordReset as requestPasswordResetType } from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import {
  FunderAdminForm,
  FunderAdminFormFields,
  FunderAdminFormProps,
} from "src/pages/carinaAdmin/dashboard/carina_admin_funders/FunderAdminForm";
import { getFunderAdmin as getFunderAdminType } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/Queries";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { StartImpersonationButton } from "src/reusable_view_elements/StartImpersonationButton";
import { useOnce } from "src/utilities/useOnce";
import { Button } from "src/reusable_view_elements/Button";

type FunderAdminDetailsProps = {
  getFunderAdmin: typeof getFunderAdminType;
  requestPasswordReset: typeof requestPasswordResetType;
};

export const CarinaAdminFunderAdminDetails: React.FC<FunderAdminDetailsProps> = ({
  getFunderAdmin,
  requestPasswordReset,
}) => {
  const { showSnackbar } = useNotification();
  const [funderAdmin, setFunderAdmin] = useState<PPHCCarinaAdminDashboardFundersController_FunderAdminDetails>();
  const [isFetching, setIsFetching] = useState(true);
  const { isUserType } = useUserSession();

  const { id } = useParams<{ id: string }>();
  useOnce(() => {
    getFunderAdmin(id)
      .then((res) => {
        if (res.status === 200) {
          setFunderAdmin(res.data);
        } else {
          showSnackbar("Error loading funder admin. Please try again.", "error");
        }
      })
      .catch(() => {
        showSnackbar("Error loading funder admin. Please try again.", "error");
      })
      .finally(() => setIsFetching(false));
  });

  if (isFetching) {
    return <>Loading Funder Admin...</>;
  }

  if (!funderAdmin) {
    return <>Error loading Funder Admin!</>;
  }

  // TODO: https://github.com/CarinaWeb/CarinaCore/issues/300
  const WrappedFunderAdminForm = withFormik<FunderAdminFormProps<FunderAdminFormFields>, FunderAdminFormFields>({
    mapPropsToValues: () => ({
      firstName: funderAdmin.firstName,
      lastName: funderAdmin.lastName,
      email: funderAdmin.email || "",
      funder: funderAdmin.funder,
    }),
    handleSubmit: () => {},
  })(FunderAdminForm);

  return (
    <>
      <DashReturnLink
        to={
          isUserType(UserType.CarinaAdmin)
            ? CARINA_ADMIN_DASHBOARD_ROUTES.funder_list
            : SUPPORT_ADMIN_DASHBOARD_ROUTES.funder_list
        }
      >
        Return to Funder Admins Dashboard
      </DashReturnLink>

      <Constraint columns={8} paddingTop={4}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <SectionTitle>
              {funderAdmin.firstName} {funderAdmin.lastName}
            </SectionTitle>
            {/**
             TODO: The created date is the invite's create date, not the account's create date:
              https://github.com/CarinaWeb/CarinaCore/issues/993
             */}
            {/* <Body paragraph>Created: some date</Body> */}
          </Grid>
          <Grid item>
            <StartImpersonationButton userId={id} userDisplayName={funderAdmin.firstName} />
          </Grid>
        </Grid>
      </Constraint>

      <Constraint columns={8} paddingTop={2} paddingBottom={14}>
        <WrappedFunderAdminForm editable={false}>
          {() => {
            return (
              <DashSection label="Other Actions" container topBorder>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    type="button"
                    data-testid="pwd-reset-button"
                    onClick={() => {
                      requestPasswordReset(id)
                        .then((res) => {
                          if (res.status === 200) {
                            showSnackbar("Successfully requested password reset.", "success");
                          } else {
                            showSnackbar("Error requesting password reset. Try again later.", "error");
                          }
                        })
                        .catch(() => {
                          showSnackbar("Error requesting password reset. Try again later.", "error");
                        });
                    }}
                  >
                    Reset Password
                  </Button>
                </Grid>
              </DashSection>
            );
          }}
        </WrappedFunderAdminForm>
      </Constraint>
    </>
  );
};
