/**
 * Design Wiki: Colors
 * https://www.notion.so/carinatechnology/a8add5065b964d478febb7ae98a5bc21?v=a605f303deef4b00b68705b66f24cff4
 *
 * Figma Design Library
 * https://www.figma.com/file/SKE7jkeslrYZdGzuzYNRAa/Civilization-Design-Library?node-id=6%3A1
 */

export default {
  coreOrange: "#EC633E", // New color for accessibility
  coreNavy: "#3D3D5C",
  coreDarkNavy: "#2D3045",
  coreGray: "#5A5B5B",
  deepGray: "#767676",
  mediumGray: "#989898",
  lightGray: "#F7F5F5",
  coreGold: "#F6BF75",
  palePeach: "#F9EFE5",
  paleBlue: "#E7EFF0",
  pastelBlue: "#D1E0FF",
  richBlue: "#295DC1",
  lightOrange: "#F2C4A0",
  paleWhite: "#F8FBF8",
  white: "#ffffff",
  gradient1: "linear-gradient(45deg, rgba(175, 203, 213, 0.7) 25%, rgba(254, 237, 212, 0.7) 75%)",
  gradient2: "linear-gradient(45deg, rgba(11, 144, 173, 0.5) 25%, rgba(32, 185, 162, 0.5) 75%)",
  gradient3: "linear-gradient(45deg, rgba(11, 144, 173, 0.3) 25%, rgba(32, 185, 162, 0.3) 75%)",
  gradient4: "linear-gradient(180deg, rgba(0, 0, 0, 0) 50.52%, rgba(0, 0, 0, 0.5) 100%)",
  tangerine: "#FF5F33",
  greenSpring: "#C0E0B5",
  newGreenSpring: "#D8EDD0",
  forestSpring: "#457B32",
  dustyRose: "#FDDCDC",
  deepRose: "#C13939", // New error color for accessibility
  sandyDune: "#F6F3AD",
  newSandyDune: "#F8F2B6",
  sunnyDune: "#ECB800",
};
