import { ConsumerApplicationDeclinedReason, ConsumerApplicationHomeEnvironmentKey } from "src/generated/api_types";

export function mapFieldToDTO(field: { [K in ConsumerApplicationHomeEnvironmentKey]?: boolean | "" }): {
  [P in ConsumerApplicationHomeEnvironmentKey]?: boolean;
} {
  return Object.fromEntries(
    Object.keys(ConsumerApplicationHomeEnvironmentKey)
      .filter((k) => field[k as ConsumerApplicationHomeEnvironmentKey] !== "")
      .filter((k) => field[k as ConsumerApplicationHomeEnvironmentKey] !== undefined)
      .map((k) => [
        k,
        (() => {
          const rawValue = field[k as ConsumerApplicationHomeEnvironmentKey];
          return String(rawValue) === "true";
        })(),
      ]),
  );
}

export function getConsumerAppDeclinedReasonLabel(reason?: ConsumerApplicationDeclinedReason): string {
  switch (reason) {
    case ConsumerApplicationDeclinedReason.AGENCY_CANNOT_MEET_CLIENT_REQUIREMENTS:
      return "Agency is not able to meet client’s staffing or hours requirements";
    case ConsumerApplicationDeclinedReason.AGENCY_HAS_NO_AVAILABLE_PROVIDERS:
      return "Agency does not have available providers with specific care skills or expertise in client’s area";
    case ConsumerApplicationDeclinedReason.CLIENT_NEEDS_OUTSIDE_AGENCY_SCOPE:
      return "Client’s needs are not within agency’s scope";
    case ConsumerApplicationDeclinedReason.CLIENT_DID_NOT_RESPOND:
      return "Client did not call back or respond to agency’s outreach";
    case ConsumerApplicationDeclinedReason.CLIENT_NO_LONGER_NEEDS_SERVICES:
      return "Client no longer needs agency’s services";
    case ConsumerApplicationDeclinedReason.CLIENT_CANNOT_AFFORD_RATES:
      return "Client cannot afford rates";
    case ConsumerApplicationDeclinedReason.INDIVIDUAL_LOOKING_FOR_CAREGIVING_WORK_NOT_SERVICES:
      return "Individual looking for caregiving work, not services";
    case ConsumerApplicationDeclinedReason.OTHER:
      return "Other";
    default:
      return "";
  }
}

export const ConsumerApplicationDeclinedReasonOptions: Array<{
  value: ConsumerApplicationDeclinedReason;
  label: string;
}> = Object.values(ConsumerApplicationDeclinedReason).map((key) => ({
  value: key,
  label: getConsumerAppDeclinedReasonLabel(key),
}));
