import CirclePlaceHolder from "src/assets/images/round-placeholder.png";

export interface RoundImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const defaultProps = {
  src: CirclePlaceHolder,
  width: "100%",
  height: "auto",
};

const style = { borderRadius: "100%" };

// Note: alt is destructured here because we were getting TS error
// about alt missing on <img />, even if it had a default prop
const RoundImage = ({ alt = "", ...props }: RoundImageProps) => {
  return <img style={style} alt={alt} {...props} />;
};

RoundImage.defaultProps = defaultProps;
export default RoundImage;
