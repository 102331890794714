import { generatePath, Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  CarinaAdminFunderManagementController_RegistrationStepDTO,
  CarinaAdminFunderManagementController_RegPathDetailDTO,
} from "src/generated/api_types";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { getRegPath as getRegPathType } from "src/pages/carinaAdmin/management/funders/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import { Column, Datatable } from "src/reusable_view_elements/datatable/Datatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { useOnce } from "src/utilities/useOnce";
import { useStateIfMounted } from "use-state-if-mounted";

interface PathParams {
  id: string;
}

interface FunderMgmtRegPathDetailProps {
  getRegPath: typeof getRegPathType;
}

export const FunderMgmtRegPathDetail: React.FC<FunderMgmtRegPathDetailProps> = ({ getRegPath }) => {
  const { showSnackbar } = useNotification();
  const { id } = useParams<PathParams>();
  const [isFetching, setIsFetching] = useStateIfMounted<boolean>(true);
  const [regPath, setRegPath] = useStateIfMounted<CarinaAdminFunderManagementController_RegPathDetailDTO>(
    undefined as unknown as CarinaAdminFunderManagementController_RegPathDetailDTO,
  );

  const REG_STEP_COLUMN_DEFINITIONS: {
    [k: string]: Column<CarinaAdminFunderManagementController_RegistrationStepDTO>;
  } = {
    name: {
      title: "Registration Step",
      render: (data) => (
        <InternalLink
          to={generatePath(CARINA_ADMIN_MGMT_ROUTES.funder_reg_step_details, {
            id: data.id,
          })}
        >
          <Body>{data.displayName}</Body>
        </InternalLink>
      ),
    },
  };

  useOnce(() => {
    getRegPath(id)
      .then((res) => {
        setRegPath(res.data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  });

  if (isFetching) {
    return (
      <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
        <Body>Loading...</Body>
      </CarinaAdminMgmtContainer>
    );
  }

  if (!isFetching && !regPath) {
    showSnackbar(`Could not find Registration Path ${id}`, "error");
    return <Redirect to={CARINA_ADMIN_MGMT_ROUTES.root} />;
  }

  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
      <Constraint columns={8} paddingTop={4}>
        <SectionTitle>
          {regPath.dtype} ({regPath.id})
        </SectionTitle>
      </Constraint>
      <Constraint columns={8}>
        <Datatable
          columnDefinitions={REG_STEP_COLUMN_DEFINITIONS}
          persistKeyPrefix="funder-mgmt-role-reg-steps"
          data={regPath.registrationSteps}
        />
      </Constraint>
    </CarinaAdminMgmtContainer>
  );
};
