import { List, ListItem, ListItemTextProps, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FeatureList = styled(List)({
  padding: 0,
  margin: 0,
});

interface FeatureListItemProps extends ListItemTextProps {
  icon: React.ComponentType<SvgIconProps>;
}

export const FeatureListItem = ({ icon: Component, primary, secondary }: FeatureListItemProps) => {
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        padding: 0,
        margin: 0,
      }}
    >
      <Component
        sx={{
          width: 48,
          height: 48,
          marginRight: "24px",
        }}
      />
      <div>
        {primary}
        {secondary}
      </div>
    </ListItem>
  );
};
