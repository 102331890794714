import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AxiosUserControllerClient, Permission, UserAlertType } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidAccountPortal } from "src/i18n/Namespaces";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import ActiveJobPostsPortalCard from "src/pages/medicaidAccount/portal/portalCards/ActiveJobPostsPortalCard";
import CarinaTutorialsPortalCard from "src/pages/medicaidAccount/portal/portalCards/CarinaTutorialsPortalCard";
import ConsumerGroupInvitePortalCard from "src/pages/medicaidAccount/portal/portalCards/ConsumerGroupInvitePortalCard";
import CoordinatorToolsPortalCard from "src/pages/medicaidAccount/portal/portalCards/CoordinatorToolsPortalCard";
import MessagesPortalCard from "src/pages/medicaidAccount/portal/portalCards/MessagesPortalCard";
import MyProfilePortalCard from "src/pages/medicaidAccount/portal/portalCards/MyProfilePortalCard";
import SavedJobPostsPortalCard from "src/pages/medicaidAccount/portal/portalCards/SavedJobPostsPortalCard";
import SavedProvidersPortalCard from "src/pages/medicaidAccount/portal/portalCards/SavedProvidersPortalCard";
import {
  NewEnvOptionsBanner,
  ViewEnvOptionsBannerEnum,
} from "src/pages/medicaidAccount/sharedComponents/NewEnvOptionsBanner";
import { funderSelectionController } from "src/pages/supportAdminAccount/ControllerClients";
import { getDashboardPortalTitle } from "src/pages/supportAdminAccount/portal/SupportPortalMain";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import Section from "src/reusable_view_elements/Section";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

export const userControllerClient = new AxiosUserControllerClient();

const AccountPortalMain = () => {
  const { userSession, isUserType, impersonatorIsUserType, hasPermission } = useUserSession();
  const { programOptions, setProgramOptions, setLoadingPrograms } = useSupportAdminContext();
  const { setIsInitialProvidersSearch, setIsInitialJobsSearch, newEnvOptionsBannerView, setNewEnvOptionsBannerView } =
    useMedicaidContext();
  const { showSnackbar } = useNotification();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsCommonToasts, nsMedicaidAccountPortal]);

  useEffect(() => {
    (async () => {
      try {
        const getAlertInfoRes = await userControllerClient.getUserAlert({ alertType: UserAlertType.LGBT_BANNER });
        if (getAlertInfoRes.data) {
          // We know user is in the user_alert table and was at one point eligible for the alert,
          // but we need to check if they've already seen and acknowledged it
          const userAlreadyAcknowledgedBanner = getAlertInfoRes.data?.acknowledged;
          userAlreadyAcknowledgedBanner
            ? setNewEnvOptionsBannerView(ViewEnvOptionsBannerEnum.HIDDEN)
            : setNewEnvOptionsBannerView(ViewEnvOptionsBannerEnum.VISIBLE);
        }
      } catch (e) {
        //eslint-disable-next-line
        console.error(`Something went wrong while fetching user alert status: ${e}`);
      }

      if (programOptions.length === 0 && isUserType(UserType.UserSupportManager)) {
        try {
          setLoadingPrograms(true);
          const res = await funderSelectionController.fetchSelectOptions({ offering: "HC Medicaid" });
          setProgramOptions(res.data);
        } catch (e) {
          showSnackbar(
            t("error.load_funder_segment_options", "Unable to load funder segment options", { ns: nsCommonToasts }),
            "error",
          );
        } finally {
          setLoadingPrograms(false);
        }
      }
    })();
  }, []);

  const dashboardNavBarTriggerFn = () => {
    setIsInitialProvidersSearch(true);
    setIsInitialJobsSearch(true);
  };

  return (
    <>
      <Helmet>
        <title>Carina | Medicaid Account Portal</title>
      </Helmet>

      <NewEnvOptionsBanner view={newEnvOptionsBannerView} setView={setNewEnvOptionsBannerView} />

      {isUserType(UserType.UserSupportManager) && (
        <DashboardNavbar
          title={getDashboardPortalTitle(UserType.UserSupportManager)}
          withProgramDropdown
          searchTriggerFn={dashboardNavBarTriggerFn}
        />
      )}
      <MedicaidNavbar />

      <main id="main-content">
        <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
          <Constraint columns={8}>
            {ready && (
              <SectionTitle align="center">
                {t("hi_name.label", { ns: nsCommonFormsBtns, name: userSession?.firstName })}
              </SectionTitle>
            )}
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
          <Constraint columns={8}>
            {!impersonatorIsUserType(UserType.UserSupportAgent) && hasPermission(Permission.MESSAGING_ACCESS) && (
              <MessagesPortalCard />
            )}

            {(isUserType(UserType.Consumer) || isUserType(UserType.ProxyProvider)) && (
              <>
                {isUserType(UserType.ProxyProvider) && <SavedJobPostsPortalCard />}
                <SavedProvidersPortalCard />
                <ActiveJobPostsPortalCard />
                <CarinaTutorialsPortalCard />
              </>
            )}

            {isUserType(UserType.Provider) && (
              <>
                <SavedJobPostsPortalCard />
                <SavedProvidersPortalCard />
                <MyProfilePortalCard />
                <CarinaTutorialsPortalCard />
              </>
            )}

            {isUserType(UserType.MedicaidReferralCoordinator) && (
              <>
                <ActiveJobPostsPortalCard />
                <SavedProvidersPortalCard />
                <CoordinatorToolsPortalCard />
                <CarinaTutorialsPortalCard />
              </>
            )}

            {isUserType(UserType.MedicaidCaseManager) && (
              <>
                <ActiveJobPostsPortalCard />
                <SavedProvidersPortalCard />
                {userSession?.funder.state === "OR" && <ConsumerGroupInvitePortalCard />}
                <CarinaTutorialsPortalCard />
              </>
            )}

            {isUserType(UserType.CaseWorker) && (
              <>
                <SavedJobPostsPortalCard />
                <SavedProvidersPortalCard />
                {userSession?.funder.state === "OR" && <ConsumerGroupInvitePortalCard />}
                <CarinaTutorialsPortalCard />
              </>
            )}

            {isUserType(UserType.UserSupportManager) && (
              <>
                <SavedJobPostsPortalCard />
                <SavedProvidersPortalCard />
              </>
            )}
          </Constraint>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default AccountPortalMain;
