import { useHistory } from "react-router-dom";
import { OnboardingRoleDTO, OnboardingStepType } from "src/generated/api_types";
import { getCardContent } from "src/pages/onboarding/components/OnboardingCardsContent";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import { StepCard } from "src/reusable_view_elements/StepCard";

interface OnboardingStepCardProps {
  state: string;
  role: OnboardingRoleDTO;
  step: keyof typeof OnboardingStepType;
}

const OnboardingStepCard = ({ state, role, step }: OnboardingStepCardProps) => {
  const cardContent = getCardContent(state, role.roleType, step);

  const { addCompletedStep, isStepComplete } = useOnboardingContext();
  const history = useHistory();
  const handleClick = () => {
    const url = cardContent!.linkUrl;
    if (!url) {
      addCompletedStep(step);
    } else {
      history.push(cardContent!.linkUrl);
    }
  };

  return (
    <StepCard
      name={step}
      title={cardContent!.title}
      icon={cardContent!.icon}
      subtitle={cardContent!.subtitle}
      onClick={handleClick}
      isComplete={isStepComplete(step)}
    />
  );
};

export default OnboardingStepCard;
