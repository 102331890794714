import { Tab, TabProps as MuiTabProps } from "@mui/material";
import Badge from "src/reusable_view_elements/Badge";

interface InboxTabProps extends MuiTabProps {
  badgeNumber?: number;
  component?: React.ElementType; // to support using a Link component
  to?: string; // to support using a Link component
}

const InboxTab = ({ badgeNumber, label, ...tabProps }: InboxTabProps) => {
  return (
    <Tab
      label={label}
      icon={badgeNumber ? <Badge number={badgeNumber} /> : undefined}
      iconPosition="end"
      {...tabProps}
    />
  );
};

export default InboxTab;
