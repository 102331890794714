import { format } from "date-fns";
import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO } from "src/generated/api_types";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { ExportMenuOption } from "src/reusable_view_elements/datatable/Datatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { useCarinaAdminFundersDatatable } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/CarinaAdminFunderDashboard";
import { Body } from "src/reusable_view_elements/Typography";

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getFunderAdminNameText(data: PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO): string {
  return `${data.firstName} ${data.lastName}`;
}

export const CarinaAdminFundersList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useCarinaAdminFundersDatatable();
  const { isUserType } = useUserSession();

  const COLUMN_DEFINITIONS: {
    [k: string]: ClientsideColumn<PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO>;
  } = {
    first_name: {
      title: " Admin Name",
      field: "firstName",
      customExport: getFunderAdminNameText,
      render: (data) => {
        return (
          <>
            <InternalLink
              id="firstNameLink"
              to={
                data.id! &&
                generatePath(
                  isUserType(UserType.CarinaAdmin)
                    ? CARINA_ADMIN_DASHBOARD_ROUTES.funder_details
                    : SUPPORT_ADMIN_DASHBOARD_ROUTES.funder_details,
                  {
                    id: data.id,
                  },
                )
              }
            >
              <Body>{getFunderAdminNameText(data)}</Body>
            </InternalLink>
          </>
        );
      },
      customFilterAndSearch: (term, data) =>
        `${data.firstName} ${data.lastName}`.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    contact: {
      title: "Contact",
      field: "email",
      customFilterAndSearch: (term, data) => data.email?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    funder: {
      title: "Funder",
      field: "funder.name",
      customFilterAndSearch: (term, data) =>
        data.funder.name.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    created_at: {
      title: "Created At",
      field: "createdAt",
      customExport: (data) => formatDate(new Date(data.createdAt)),
      render: (data) => formatDate(new Date(data.createdAt)),
      customFilterAndSearch: (term, data) => formatDate(new Date(data.createdAt)).indexOf(term) !== -1,
    },
  };

  return (
    <>
      <Helmet>
        <title>Carina | Internal Tools | Funder Admins</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="funder-admins-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Funder Admins"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching funders..." : "No funder records to display",
          },
        }}
        options={{
          exportMenu: [ExportMenuOption.csv({ filename: "Funder Admins" })],
        }}
      />
    </>
  );
};
