import { Box, Grid, useMediaQuery } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostExtendedDTO, JobPostListDTO } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidAccountPortal,
  nsMedicaidJobPost,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedJobPostTag } from "src/i18n/TagHelpers";
import { CalendarIcon, TaskChecklistCheckIcon, TimeClockCircleAlternateIcon } from "src/assets/icons/StreamlineIcons";
import { getTagsObject } from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJobForm";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import { getBookmarkedJobs } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardDivider from "src/reusable_view_elements/PortalCardDivider";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

/**
 * - For Providers: Show both number of saved job posts and list up to 3 on mobile, 6 on desktop
 * - This card isn't shown to Consumers
 */
const SavedJobPostsPortalCard = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const [bookMarkedJobsList, setBookMarkedJobsList] = useState<JobPostListDTO[]>([]);
  const { showSnackbar } = useNotification();
  const { program } = useSupportAdminContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { isUserType } = useUserSession();
  const { t, ready: translationReady } = useTranslation([nsMedicaidAccountPortal, nsCommonFormsBtns, nsCommonToasts]);

  useEffect(() => {
    (async () => {
      if ((isUserType(UserType.UserSupportManager) && program !== "none") || !isUserType(UserType.UserSupportManager)) {
        const programId = isUserType(UserType.UserSupportManager) ? program : "";
        try {
          setLoading(true);
          const resp = await getBookmarkedJobs(programId);
          setBookMarkedJobsList(resp.data);
        } catch (e) {
          showSnackbar(
            t(
              "error.retrieving_bookmarked_jobs",
              "Sorry, there was an issue retrieving your bookmarked jobs. Please try again later.",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [program]);

  const slicedJobs = bookMarkedJobsList.slice(0, desktopSize ? 6 : 3);

  if (!translationReady) {
    return <LoadingPortalCardSkeleton />;
  }

  return (
    <PortalCard>
      <PortalCardTitle icon={TaskChecklistCheckIcon} viewMorePath={MEDICAID_ACCOUNT_ROUTES.savedJobs}>
        <BodyTitle>{t("saved_job_posts.label", { ns: nsMedicaidAccountPortal })}</BodyTitle>
        {!isLoading && bookMarkedJobsList.length >= 1 && (
          <Body gutterBottom>
            {t("saved_job_posts.description.job_count", {
              ns: nsMedicaidAccountPortal,
              count: bookMarkedJobsList.length,
            })}
          </Body>
        )}
        <Body>{t("saved_job_posts.description.only_active_shown", { ns: nsMedicaidAccountPortal })}</Body>
      </PortalCardTitle>

      <PortalCardDivider />

      {isLoading && <LoadingCircle />}

      {!isLoading &&
        bookMarkedJobsList.length > 0 &&
        slicedJobs.map((jobPost, index) => (
          <JobPostCard key={jobPost.entity.id} data={jobPost.entity} showTopBorder={index !== 0} />
        ))}

      {!isLoading && bookMarkedJobsList.length === 0 && (
        <Box textAlign={desktopSize ? "left" : "center"}>
          <Body paragraph>
            {t("saved_job_posts.description.job_count", {
              ns: nsMedicaidAccountPortal,
              count: 0,
            })}
          </Body>
          <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
            {t("button.search_for_jobs", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Box>
      )}
    </PortalCard>
  );
};

export default SavedJobPostsPortalCard;

interface JobPostCardProps {
  showTopBorder: boolean;
  data: JobPostExtendedDTO;
}

const JobPostCard = ({ showTopBorder, data: jobPost }: JobPostCardProps) => {
  const { t, ready: translationReady } = useTranslation([nsMedicaidJobPost, nsMedicaidTagsEnums]);

  const skillTags: string = getTagsObject(jobPost)
    .skillTags.map(getLocalizedJobPostTag)
    .map((localizedTag) => t(localizedTag.key, { ns: localizedTag.namespace }))
    .join(" • ");
  const topBorderStyle: string = `1px solid ${CivColors.mediumGray}`;

  if (!translationReady) {
    return (
      <Box key={jobPost.id} py="16px">
        <Skeleton variant="rectangular" height={20} width="40%" style={{ marginBottom: 16 }} />
        <Skeleton variant="rectangular" height={80} />
      </Box>
    );
  }

  return (
    <Box key={jobPost.id} borderTop={showTopBorder ? topBorderStyle : undefined} py="16px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InternalLink to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: jobPost.id })}>
            <BodyEmphasis style={{ overflowWrap: "anywhere" }}>
              {t("work_with_name.label", {
                ns: nsMedicaidJobPost,
                name: jobPost.firstName || jobPost.postingUserInfo.firstName,
              })}
            </BodyEmphasis>
          </InternalLink>
        </Grid>

        <Grid item xs={12} sm={6} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item>
              <TimeClockCircleAlternateIcon style={{ width: "24px", height: "24px", marginRight: "6px" }} />
            </Grid>
            <Grid item xs>
              <Body>{t("count_hours_per_month.label", { ns: nsMedicaidJobPost, count: jobPost.hours })}</Body>
            </Grid>
          </Grid>

          <Grid item xs={12} container>
            <Grid item>
              <CalendarIcon style={{ width: "24px", height: "24px", marginRight: "6px" }} />
            </Grid>
            <Grid item xs>
              <Body style={{ marginBottom: 0, overflowWrap: "anywhere" }}>{jobPost.scheduleNotes}</Body>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <BodyEmphasis gutterBottom>{t("care_needs.label", { ns: nsMedicaidJobPost })}:</BodyEmphasis>
          <Body style={{ marginBottom: 0 }}>{skillTags}</Body>
        </Grid>
      </Grid>
    </Box>
  );
};
