import { Box, BoxProps } from "@mui/material";
import { CertifiedRibbon1Icon } from "src/assets/icons/StreamlineIcons";
import { IconAndBodyEmphasis } from "src/reusable_view_elements/IconAndBodyEmphasis";
import CivColors from "src/themes/civilization/CivColors";

interface CredentialBadgeProps extends BoxProps {
  name: string;
}

const CredentialBadge = ({ name, ...boxProps }: CredentialBadgeProps) => {
  return (
    <Box bgcolor={CivColors.coreGold} borderRadius="4px" width="fit-content" {...boxProps}>
      <Box pt={1} px={2}>
        <IconAndBodyEmphasis icon={CertifiedRibbon1Icon} iconColor={CivColors.coreDarkNavy} title={name} />
      </Box>
    </Box>
  );
};

export default CredentialBadge;
