import { Box, Grid, useMediaQuery } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsMedicaidJobSurveyConfirmation } from "src/i18n/Namespaces";
import { Circle1FilledIcon, Circle2FilledIcon, Circle3FilledIcon } from "src/assets/icons/CustomIcons";
import { Consumer_Blog_Posts } from "src/pages/blog/BlogPosts";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { FeatureList, FeatureListItem } from "src/reusable_view_elements/FeatureList";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const Unfilled = () => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobSurveyConfirmation, nsCommonFormsBtns]);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  if (!ready) {
    return (
      <>
        <Section minimizeBottomGutter minHeight="100vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  return (
    <>
      <Section textAlign="center">
        <Constraint columns={8}>
          <SectionTitle>{t("job_post_deleted.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
          <Body paragraph>
            {t("job_post_deleted.description.your_answers_will", { ns: nsMedicaidJobSurveyConfirmation })}
          </Body>
          <Body paragraph>
            {t("job_post_deleted.description.you_can_repost", { ns: nsMedicaidJobSurveyConfirmation })}
          </Body>
          <Grid container>
            <Grid item xs={12}>
              <LinkStyledAsButton
                variant="outlined"
                component={Link}
                to={getMedicaidRouteByUserType("myJobList", isUserType)}
                sx={desktopSize ? { marginRight: "24px" } : { marginBottom: "24px" }}
              >
                {t("button.return_my_jobs", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
              <LinkStyledAsButton
                variant="outlined"
                component={Link}
                to={getMedicaidRouteByUserType("myDeletedJobs", isUserType)}
              >
                {t("button.view_deleted_jobs", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
          </Grid>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.paleBlue} textAlign="center">
        <Constraint columns={8}>
          <SectionTitle>{t("next_steps.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
          <FeatureList>
            <FeatureListItem
              icon={Circle1FilledIcon}
              primary={
                <BodyTitle sx={{ marginBottom: "16px" }}>
                  {t("post_if_not_already.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <>
                  <Body paragraph>{t("post_if_not_already.description", { ns: nsMedicaidJobSurveyConfirmation })}</Body>
                  <InternalLink to={getMedicaidRouteByUserType("jobCreate", isUserType)}>
                    <Body>{t("button.post_a_job_now", { ns: nsCommonFormsBtns })}</Body>
                  </InternalLink>
                </>
              }
            />
            <Box marginBottom={6} />
            <FeatureListItem
              icon={Circle2FilledIcon}
              primary={
                <BodyTitle sx={{ marginBottom: "16px" }}>
                  {t("continue_searching_providers.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <>
                  <Body paragraph>
                    {t("continue_searching_providers.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  </Body>
                  <InternalLink to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
                    <Body>{t("button.find_providers_now", { ns: nsCommonFormsBtns })}</Body>
                  </InternalLink>
                </>
              }
            />
            <Box marginBottom={6} />
            <FeatureListItem
              icon={Circle3FilledIcon}
              primary={
                <BodyTitle sx={{ marginBottom: "16px" }}>
                  {t("checkout_tips_for_finding_provider.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Body>
                  <Trans
                    t={t}
                    i18nKey={Consumer_Blog_Posts["tips-for-choosing-a-provider"].translateKey}
                    ns={Consumer_Blog_Posts["tips-for-choosing-a-provider"].namespace}
                    components={{
                      stcp: (
                        <InternalLink to={Consumer_Blog_Posts["tips-for-choosing-a-provider"].url} target="_blank" />
                      ),
                    }}
                  />
                </Body>
              }
            />
          </FeatureList>
        </Constraint>
      </Section>
    </>
  );
};

export default Unfilled;
