import { Box } from "@mui/material";
import { Field, Formik } from "formik";
import i18n from "i18next";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RegistrationController_RegPathDTO, VerificationParameterType } from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidRegVerification } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { REGISTRATION_ROUTES } from "src/pages/registration/components/RegistrationRouter";
import { verifyUserCredentials } from "src/pages/registration/verification/Queries";
import Alert from "src/reusable_view_elements/alert/Alert";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import { FormTextField, TextField } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";
import { object, string } from "yup";

interface VerificationFormFields {
  [key: string]: string;

  supervisorFirstName: string;
  supervisorLastName: string;
  supervisorEmail: string;
}

export const CaseWorkerVerificationForm = () => {
  const { language } = i18n;
  const { userInfo, regPath } = useRegistrationContext();
  const history = useHistory();
  const [credentialsError, setCredentialsError] = useState(false);
  const { t, ready } = useTranslation([nsMedicaidRegVerification, nsCommonFormsBtns]);
  const location = useLocation();

  const schema = object().shape({
    supervisorFirstName: string()
      .label(t("field.supervisor_first_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.supervisor_first_name.error", { ns: nsCommonFormsBtns })),
    supervisorLastName: string()
      .label(t("field.supervisor_last_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.supervisor_last_name.error", { ns: nsCommonFormsBtns })),
    supervisorEmail: string()
      .label(t("field.supervisor_email.label", { ns: nsCommonFormsBtns }))
      .required(t("field.supervisor_email.error.required", { ns: nsCommonFormsBtns }))
      .email(t("field.supervisor_email.error.email", { ns: nsCommonFormsBtns })),
  });

  const initialValues: VerificationFormFields = {
    supervisorFirstName: "",
    supervisorLastName: "",
    supervisorEmail: "",
  };

  const handleSubmit = async (values: VerificationFormFields) => {
    const verificationData = {
      params: {
        firstName: {
          type: VerificationParameterType.FIRST_NAME,
          value: userInfo.firstName,
        },
        lastName: {
          type: VerificationParameterType.LAST_NAME,
          value: userInfo.lastName,
        },
        email: {
          type: VerificationParameterType.EMAIL,
          value: userInfo.email.toLowerCase(),
        },
        supervisorFirstName: {
          type: VerificationParameterType.SUPERVISOR_INFO,
          value: values.supervisorFirstName,
        },
        supervisorLastName: {
          type: VerificationParameterType.SUPERVISOR_INFO,
          value: values.supervisorLastName,
        },
        supervisorEmail: {
          type: VerificationParameterType.SUPERVISOR_INFO,
          value: values.supervisorEmail,
        },
        prefLanguage: {
          type: VerificationParameterType.LANGUAGE_PREF,
          value: language,
        },
      },
    };

    try {
      const response = await verifyUserCredentials(verificationData);

      const verificationResMsg = response.data.message;

      if (verificationResMsg === "Verification succeeded") {
        history.push(REGISTRATION_ROUTES.verificationConfirmation);
      } else {
        setCredentialsError(true);
        scrollToId("credentials-error");
      }
    } catch (err) {
      setCredentialsError(true);
      scrollToId("credentials-error");
    }
  };

  interface SupportContactTextProps {
    rp?: RegistrationController_RegPathDTO;
  }

  const SupportContactText = (props: SupportContactTextProps) => {
    switch (props.rp?.roleConfig.funder.segment) {
      case "HC Medicaid-OR":
        return (
          <Body>{t("we_couldnt_verify_work_email.description.if_correct_or", { ns: nsMedicaidRegVerification })}</Body>
        );
      case "HC Medicaid-WA":
        return (
          <Trans
            t={t}
            i18nKey="we_couldnt_verify_work_email.description.if_correct_wa"
            ns={nsMedicaidRegVerification}
            parent={(parentProps) => <Body paragraph>{parentProps.children}</Body>}
            components={{
              here: (
                <InternalLink to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }} target="_blank" />
              ),
            }}
            values={{
              here_key: t("we_couldnt_verify_work_email.here.label", {
                ns: nsMedicaidRegVerification,
              }),
            }}
          />
        );
      default:
        return (
          <Body>
            {t("we_couldnt_verify_work_email.description.if_correct_default", {
              ns: nsMedicaidRegVerification,
            })}
          </Body>
        );
    }
  };

  if (!ready) {
    return (
      <Section bgcolor={CivColors.lightGray}>
        <LoadingCircle />
      </Section>
    );
  }

  return (
    <Section bgcolor={CivColors.lightGray} sx={{ padding: 2 }}>
      {credentialsError && (
        <Constraint columns={6}>
          <Alert
            severity="error"
            title={t("we_couldnt_verify_work_email_error.label", { ns: nsMedicaidRegVerification })}
            sx={{ mb: "48px" }}
          >
            <Body paragraph>
              {t("we_couldnt_verify_work_email.description.check_work_email", {
                ns: nsMedicaidRegVerification,
              })}
            </Body>
            <SupportContactText rp={regPath} />
          </Alert>
        </Constraint>
      )}
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <Form localizationReady={ready}>
            <Constraint columns={4}>
              <Body sx={{ marginBottom: "24px" }}>
                {t("field.marked_with_asterisk.label", { ns: nsCommonFormsBtns })}
              </Body>
              <TextField
                label={t("your_information.first_name.label", { ns: nsMedicaidRegVerification })}
                name="firstName"
                required
                value={userInfo.firstName}
                InputProps={{ readOnly: true }}
                sx={{ marginBottom: "24px" }}
              />
              <TextField
                label={t("your_information.last_name.label", { ns: nsMedicaidRegVerification })}
                name="lastName"
                required
                value={userInfo.lastName}
                InputProps={{ readOnly: true }}
                sx={{ marginBottom: "24px" }}
              />
              <TextField
                label={t("your_information.work_email.label", { ns: nsMedicaidRegVerification })}
                name="workEmail"
                required
                value={userInfo.email}
                InputProps={{ readOnly: true }}
                sx={{ marginBottom: "32px" }}
              />

              <BodyEmphasis gutterBottom>
                {t("supervisors_information.label", { ns: nsMedicaidRegVerification })}
              </BodyEmphasis>
              <Body paragraph>{t("supervisors_information.description", { ns: nsMedicaidRegVerification })}</Body>
              <FormTextField
                label={t("field.supervisor_first_name.label", { ns: nsCommonFormsBtns })}
                id="supervisorFirstName"
                name="supervisorFirstName"
                error={Boolean(touched.supervisorFirstName && errors.supervisorFirstName)}
                helperText={touched.supervisorFirstName && errors.supervisorFirstName}
                required={isRequiredField("supervisorFirstName", schema)}
                sx={{ marginBottom: "32px" }}
              />
              <Box mb={3} />
              <Field
                as={TextField}
                label={t("field.supervisor_last_name.label", { ns: nsCommonFormsBtns })}
                id="supervisorLastName"
                name="supervisorLastName"
                error={Boolean(touched.supervisorLastName && errors.supervisorLastName)}
                helperText={touched.supervisorLastName && errors.supervisorLastName}
                required={isRequiredField("supervisorLastName", schema)}
              />
              <Box mb={3} />
              <Field
                as={TextField}
                label={t("field.supervisor_email.label", { ns: nsCommonFormsBtns })}
                id="supervisorEmail"
                name="supervisorEmail"
                error={Boolean(touched.supervisorEmail && errors.supervisorEmail)}
                helperText={touched.supervisorEmail && errors.supervisorEmail}
                required={isRequiredField("supervisorEmail", schema)}
              />
            </Constraint>
            <Constraint columns={6} textAlign="center">
              <Box mt={8}>
                {credentialsError && (
                  <LinkStyledAsButton
                    variant="outlined"
                    href={REGISTRATION_ROUTES.contactInfo}
                    sx={{ marginRight: "12px" }}
                  >
                    {t("button.go_back", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                )}
                <FormAlert schema={schema} sx={{ mt: 2, mb: 2, textAlign: "left" }} />
                <Button variant="contained" type="submit" disabled={isSubmitting || credentialsError}>
                  {isSubmitting
                    ? t("button.submitting", { ns: nsCommonFormsBtns })
                    : t("button.submit", { ns: nsCommonFormsBtns })}
                </Button>
              </Box>
            </Constraint>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
