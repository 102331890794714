import { Redirect, Route, Switch } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { AgencyAdminInviteDTO } from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import {
  getAgencyAdmin,
  getFunder,
  getAllFunderAgencyAdmins as getAgencyAdminInvitesType,
} from "src/pages/funderAdmin/dashboard/funder_admin_agencies/Queries";
import { FunderAdminAgencyAdminsList } from "src/pages/funderAdmin/dashboard/funder_admin_agencies/FunderAdminAgencyAdminsList";
import { FunderAdminAgencyAdminDetails } from "src/pages/funderAdmin/dashboard/funder_admin_agencies/FunderAdminAgencyAdminDetails";
import {
  FunderAdminDashboardContainer,
  FunderAdminDashboardTabs,
} from "src/pages/funderAdmin/dashboard/funder_admin_dashboard_components/FunderAdminDashboardContainer";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useFunderAdminAgencyInvitesDatatable,
  ClientsideDatatableContextProvider: FunderAdminAgencyInvitesDatatableContextProvider,
} = GenerateClientsideDatatable<AgencyAdminInviteDTO, typeof getAgencyAdminInvitesType, (funderId: string) => void>();

interface FunderAdminAgencyAdminDashboardContainerProps {
  getAllFunderAgencyAdmins: typeof getAgencyAdminInvitesType;
}

export const FunderAdminAgencyAdminDashboard: React.FC<FunderAdminAgencyAdminDashboardContainerProps> = ({
  getAllFunderAgencyAdmins,
}) => {
  const { userSession } = useUserSession();

  if (userSession == null) {
    return <Redirect to={ROUTES.login} />;
  }

  if (!userSession) {
    return <Redirect to={ROUTES.login} />;
  }
  return (
    <FunderAdminDashboardContainer tab={FunderAdminDashboardTabs.AGENCYADMINS}>
      <FunderAdminAgencyInvitesDatatableContextProvider
        getData={getAllFunderAgencyAdmins}
        getDataArgs={[userSession.funder.id]}
      >
        <Switch>
          <Route exact path={FUNDER_ADMIN_DASHBOARD_ROUTES.agencyadmins_list}>
            <FunderAdminAgencyAdminsList />
          </Route>
          <Route exact path={FUNDER_ADMIN_DASHBOARD_ROUTES.agencyadmins_details}>
            <FunderAdminAgencyAdminDetails getAgencyAdmin={getAgencyAdmin} getFunder={getFunder} />
          </Route>
        </Switch>
      </FunderAdminAgencyInvitesDatatableContextProvider>
    </FunderAdminDashboardContainer>
  );
};
