import { AxiosMedicaidCoordinatorControllerClient } from "src/generated/api_types";

const client = new AxiosMedicaidCoordinatorControllerClient();

// TODO: Update this endpoint to include threadIds, just like on ProviderProfileSearchAndFilterController.search, so
//   that we can support the messaging feature in the coordinators list better (i.e. checking for existing thread):
//   https://github.com/CarinaWeb/CarinaCore/issues/1001
export async function getCoordinatorsList() {
  return client.getCoordinatorsList();
}

export async function getOrgJobPostsList() {
  return client.getOrgJobPostsList();
}
