import { Grid, Link as MuiLink } from "@mui/material";
import { withFormik } from "formik";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails } from "src/generated/api_types";
import {
  AgencyAdminForm,
  AgencyAdminFormFields,
  AgencyAdminFormProps,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyAdminForm";
import { useAgencyInviteDatatable } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyInviteDashboard";
import {
  getAgencyAdmin as getAgencyAdminType,
  getAllAgencies as getAllAgenciesType,
  getAllFunders as getAllFundersType,
  requestDeactivation as requestDeactivationType,
  requestPasswordReset as requestPasswordResetType,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { UserDeactivationDialog } from "src/pages/carinaAdmin/dashboard/agencyInvite/UserDeactivationDialog";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { StartImpersonationButton } from "src/reusable_view_elements/StartImpersonationButton";
import { useOnce } from "src/utilities/useOnce";
import { Button } from "src/reusable_view_elements/Button";

type AgencyAdminDetailsProps = {
  getAgencyAdmin: typeof getAgencyAdminType;
  getAllFunders: typeof getAllFundersType;
  getAllAgencies: typeof getAllAgenciesType;
  requestPasswordReset: typeof requestPasswordResetType;
  requestDeactivation: typeof requestDeactivationType;
};

type AgencyAdminDetailsFormFields = AgencyAdminFormFields & {
  generatedPassword?: string;
};

export const AgencyAdminDetails: React.FC<AgencyAdminDetailsProps> = ({
  getAgencyAdmin,
  getAllFunders,
  getAllAgencies,
  requestDeactivation,
  requestPasswordReset,
}) => {
  const { showSnackbar } = useNotification();
  const [agencyAdmin, setAgencyAdmin] = useState<PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails>();
  const [isFetching, setIsFetching] = useState(true);
  const history = useHistory();
  const { removeData } = useAgencyInviteDatatable();
  const { isUserType } = useUserSession();

  const { id } = useParams<{ id: string }>();
  useOnce(() => {
    getAgencyAdmin(id)
      .then((res) => {
        if (res.status === 200) {
          setAgencyAdmin(res.data);
        } else {
          showSnackbar("Error loading agency admin. Please try again.", "error");
        }
      })
      .catch(() => {
        showSnackbar("Error loading agency admin. Please try again.", "error");
      })
      .finally(() => setIsFetching(false));
  });

  if (isFetching) {
    return <>Loading Agency Admin...</>;
  }

  if (!agencyAdmin) {
    return <>Error loading Agency Admin!</>;
  }

  return (
    <>
      <DashReturnLink
        to={
          isUserType(UserType.CarinaAdmin)
            ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
            : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list
        }
      >
        Return to Agency Admins Dashboard
      </DashReturnLink>

      <Constraint columns={8} paddingTop={4}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <SectionTitle>
              {agencyAdmin.firstName} {agencyAdmin.lastName}
            </SectionTitle>
            {/**
             TODO: The created date is the invite's create date, not the account's create date:
              https://github.com/CarinaWeb/CarinaCore/issues/993
             */}
            {/* <Body paragraph>Created: some date</Body> */}
          </Grid>
          <Grid item>
            <StartImpersonationButton userId={id} userDisplayName={agencyAdmin.firstName} />
          </Grid>
        </Grid>
      </Constraint>

      <Constraint columns={8} paddingTop={2} paddingBottom={14}>
        <WrappedAgencyAdminInviteForm
          data={agencyAdmin}
          getAllFunders={getAllFunders}
          getAllAgencies={getAllAgencies}
          editable={false}
        >
          {({ setFieldValue, values }) => {
            return (
              <DashSection label="Other Actions" container topBorder>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    type="button"
                    data-testid="pwd-reset-button"
                    onClick={() => {
                      requestPasswordReset(id)
                        .then((res) => {
                          if (res.status === 200) {
                            setFieldValue("generatedPassword", res.data);
                            showSnackbar("Successfully requested password reset.", "success");
                          } else {
                            showSnackbar("Error requesting password reset. Try again later.", "error");
                          }
                        })
                        .catch(() => {
                          showSnackbar("Error requesting password reset. Try again later.", "error");
                        });
                    }}
                  >
                    Reset Password
                  </Button>
                </Grid>

                {values.generatedPassword && (
                  <Grid item xs={12}>
                    <FormTextField name="generatedPassword" label="Generated Password" disabled />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <UserDeactivationDialog
                    userFirstName={agencyAdmin?.firstName}
                    onSubmit={async (accepted) => {
                      if (accepted) {
                        try {
                          await requestDeactivation({
                            userId: id,
                          });
                          removeData(id);
                          showSnackbar(`Successfully deactivated ${agencyAdmin?.firstName}'s account`, "success");
                          history.push(
                            isUserType(UserType.CarinaAdmin)
                              ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
                              : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list,
                          );
                        } catch (e) {
                          showSnackbar(`Error requesting user deactivation: ${e}`, "error");
                        }
                      }
                    }}
                  >
                    {(setModalOpen) => <MuiLink onClick={() => setModalOpen(true)}>Deactivate admin</MuiLink>}
                  </UserDeactivationDialog>
                </Grid>
              </DashSection>
            );
          }}
        </WrappedAgencyAdminInviteForm>
      </Constraint>
    </>
  );
};
const WrappedAgencyAdminInviteForm = withFormik<
  AgencyAdminFormProps<PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails, AgencyAdminDetailsFormFields>,
  AgencyAdminDetailsFormFields
>({
  mapPropsToValues: ({ data: agencyAdmin }) => ({
    firstName: agencyAdmin?.firstName || "",
    lastName: agencyAdmin?.lastName || "",
    phone: "",
    email: agencyAdmin?.email || "",
    agencyId: agencyAdmin?.agency.id || "",
    agencyZip: "",
    agencyFunder: "",
  }),
  handleSubmit: () => {},
})(AgencyAdminForm);
