import {
  AxiosChildcareInvitesControllerClient,
  AxiosMedicaidInvitesControllerClient,
  ChildcareInvitesController_ChildcareInviteDTO,
  MedicaidInvitesController_MedicaidInviteDTO,
  MedicaidInvitesController_MedicaidInviteRegPathOptionDTO,
  RestResponse,
} from "src/generated/api_types";

const medicaidClient = new AxiosMedicaidInvitesControllerClient();
const childcareClient = new AxiosChildcareInvitesControllerClient();

export function getMedicaidInviteRegPaths(
  funderId: string,
): RestResponse<MedicaidInvitesController_MedicaidInviteRegPathOptionDTO[]> {
  return medicaidClient.getMedicaidInviteRegPaths({ funderId });
}

export function getFunderOrganizations(funderId: string) {
  return medicaidClient.getFunderOrganizations({ funderId });
}

export function createMedicaidInvite(dto: MedicaidInvitesController_MedicaidInviteDTO) {
  return medicaidClient.createMedicaidInvite(dto);
}

export function createChildcareInvite(dto: ChildcareInvitesController_ChildcareInviteDTO) {
  return childcareClient.createChildcareInvite(dto);
}

export function getInvitedChildcareProviderDetails() {
  return childcareClient.getInvitedChildcareProviderDetails();
}
