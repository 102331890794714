import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { BehaviorEnum, ReportSourceEnum } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidReportUser, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getKeyForRoleDisplayName } from "src/i18n/Utilities";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ROUTES } from "src/MainRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { medicaidClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import { FormSelectField, SelectOption, FormTextField } from "src/reusable_view_elements/form_fields";
import ApiResponseBanner, {
  ViewAPIResBannerControlEnum,
} from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import Form from "src/reusable_view_elements/form_fields/Form";
import { ExternalLink, InternalLink } from "src/reusable_view_elements/Link";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyEmphasis, PanelTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

enum BehaviorCategory {
  UNPROFESSIONAL = "UNPROFESSIONAL",
  OFFENSIVE_HOSTILE = "OFFENSIVE_HOSTILE",
  ABUSIVE_BEHAVIOR = "ABUSIVE_BEHAVIOR",
  SOMETHING_ELSE = "SOMETHING_ELSE",
}

export const BehaviorCategoryOptions: SelectOption<string>[] = [
  {
    value: "UNPROFESSIONAL",
    label: "Unprofessional behavior",
    locizeKey: "unprofessional",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: "OFFENSIVE_HOSTILE",
    label: "Hostile behavior",
    locizeKey: "offensive_hostile",
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: "ABUSIVE_BEHAVIOR",
    label: "Abusive behavior",
    locizeKey: "abusive_behavior",
    namespace: nsMedicaidTagsEnums,
  },
  { value: "SOMETHING_ELSE", label: "Something else", locizeKey: "something_else", namespace: nsMedicaidTagsEnums },
];

export const BehaviorOptions: { [behavior in BehaviorCategory]: SelectOption<string>[] } = {
  UNPROFESSIONAL: [
    {
      value: "NON_RESPONSIVE",
      label: "Not responding to a message",
      locizeKey: "non_responsive",
      namespace: nsMedicaidTagsEnums,
    },
    { value: "NO_SHOW", label: "Missed scheduled meeting", locizeKey: "no_show", namespace: nsMedicaidTagsEnums },
    {
      value: "CURSING",
      label: "Cursing, threatening or unprofessional language",
      locizeKey: "cursing",
      namespace: nsMedicaidTagsEnums,
    },
    {
      value: "NEGATIVE_EXPERIENCE",
      label: "Negative experience after hiring or on the job",
      locizeKey: "negative_experience",
      namespace: nsMedicaidTagsEnums,
    },
    {
      value: "OTHER_UNPROFESSIONAL",
      label: "Other",
      locizeKey: "other_unprofessional",
      namespace: nsMedicaidTagsEnums,
    },
  ],

  OFFENSIVE_HOSTILE: [
    {
      value: "SEXUALLY_SUGGESTIVE",
      label: "Sexual/graphic language or behavior",
      locizeKey: "sexually_suggestive",
      namespace: nsMedicaidTagsEnums,
    },
    {
      value: "DISCRIMINATORY",
      label: "Discrimination (e.g. racism, sexism, homophobia, etc.)",
      locizeKey: "discriminatory",
      namespace: nsMedicaidTagsEnums,
    },
    { value: "OTHER_HOSTILE", label: "Other", locizeKey: "other_hostile", namespace: nsMedicaidTagsEnums },
  ],

  ABUSIVE_BEHAVIOR: [
    {
      value: "ADULT_ABUSE_NEGLECT",
      label: "Abuse/neglect/abandonment of vulnerable adult",
      locizeKey: "adult_abuse_neglect",
      namespace: nsMedicaidTagsEnums,
    },
    {
      value: "CHILD_ABUSE_NEGLECT",
      label: "Abuse/neglect/abandonment of child",
      locizeKey: "child_abuse_neglect",
      namespace: nsMedicaidTagsEnums,
    },
    {
      value: "VIOLENCE",
      label: "Violence/physical abuse of provider or consumer",
      locizeKey: "violence",
      namespace: nsMedicaidTagsEnums,
    },
    { value: "OTHER_ABUSIVE", label: "Other", locizeKey: "other_abusive", namespace: nsMedicaidTagsEnums },
  ],

  SOMETHING_ELSE: [
    { value: "SOMETHING_ELSE", label: "Something else", locizeKey: "something_else", namespace: nsMedicaidTagsEnums },
  ],
};

interface UserReportModalProps {
  subjectId: string;
  subjectFirstName: string;
  subjectLastName?: string;
  subjectUserType: UserType;
  reportSource: ReportSourceEnum;
  onClose: () => void;
  open: boolean;
}

interface FormFields {
  behaviorCategory?: BehaviorCategory;
  behavior?: BehaviorEnum;
  notes: string;
}

export const UserReportModal = ({
  subjectId,
  subjectFirstName,
  subjectLastName,
  subjectUserType,
  reportSource,
  onClose,
  open,
}: UserReportModalProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const { setReportResponseView } = useMedicaidContext();
  const { userSession } = useUserSession();
  const [helperToggle, setHelperToggle] = useState(false);
  const { t, ready } = useTranslation([nsMedicaidReportUser, nsCommonFormsBtns, nsMedicaidTagsEnums, nsCommonToasts]);
  const { showSnackbar } = useNotification();

  // Confirmation and Next Steps Content
  const [showAdultAbuseMessage, setAdultAbuseMessage] = useState(false);
  const [showChildAbuseMessage, setChildAbuseMessage] = useState(false);
  const [showViolenceMessage, setViolenceMessage] = useState(false);
  const [showGenericMessage, setGenericMessage] = useState(false);
  const showForm = !(showAdultAbuseMessage || showChildAbuseMessage || showViolenceMessage || showGenericMessage);

  const funderState =
    userSession && userSession.funder.segment !== "Carina"
      ? userSession.funder.segment.split("-")[1].toUpperCase()
      : "";

  //TODO: [Issue # 1683] - Address console errors related to setting initial values as 'undefined' ( it reads "MUI: A
  // component is changing the uncontrolled value state of Select to be controlled."). This pattern is used in other
  // components as well, particularly where the 'Select' component is used, producing similar errors.
  const initialValues: FormFields = {
    behaviorCategory: undefined,
    behavior: undefined,
    notes: "",
  };

  const NOTES_MAX_LENGTH = 500;
  const schema = object().shape({
    behaviorCategory: string()
      .label(t("field.behavior_category.label", { ns: nsCommonFormsBtns }))
      .required(t("field.behavior_category.error", { ns: nsCommonFormsBtns })),
    behavior: string()
      .label(t("field.behavior.label", { ns: nsCommonFormsBtns }))
      .required(t("field.behavior.error", { ns: nsCommonFormsBtns }))
      .notOneOf([""], t("field.behavior.error", { ns: nsCommonFormsBtns })),
    // .matches("", t("field.behavior.error", { ns: nsCommonFormsBtns })),
    notes: string()
      .label(t("field.comments.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(NOTES_MAX_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
  });

  const handleSubmit = async (values: FormFields) => {
    if (values.behavior) {
      try {
        setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
        await medicaidClient.submitUserReport({
          reportedUserId: subjectId,
          behavior: values.behavior,
          notes: values.notes,
          reportSource,
        });
        setReportResponseView(ViewAPIResBannerControlEnum.VISIBLE_SUCCESS);
        switch (values.behavior) {
          case BehaviorEnum.ADULT_ABUSE_NEGLECT:
            setAdultAbuseMessage(true);
            break;
          case BehaviorEnum.CHILD_ABUSE_NEGLECT:
            setChildAbuseMessage(true);
            break;
          case BehaviorEnum.VIOLENCE:
            setViolenceMessage(true);
            break;
          default:
            setGenericMessage(true);
        }
      } catch (e) {
        showSnackbar(t("error.server_failed_to_send", { ns: nsCommonToasts }), "error");
      }
    }
  };

  /*********************** ADULT ABUSE CONTENT **************************/

  const AdultAbuseContent = () => {
    switch (funderState) {
      case "WA":
        return <AdultAbuseContentWA />;
      case "OR":
        return <AdultAbuseContentOR />;
      default:
        return <></>;
    }
  };

  const AdultAbuseContentWA = () => (
    <>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_report_to_ps_adult"
          ns={nsMedicaidReportUser}
          components={{
            aps: (
              <ExternalLink
                //eslint-disable-next-line max-len
                to="https://www.dshs.wa.gov/altsa/home-and-community-services/report-concerns-involving-vulnerable-adults"
                target="_blank"
              />
            ),
          }}
        />
      </Body>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_report_to_cm_dshs_list"
          ns={nsMedicaidReportUser}
          components={{
            ldopn: <ExternalLink to="https://www.dshs.wa.gov/office-locations" target="_blank" />,
          }}
        />
      </Body>
      <BodyEmphasis paragraph>
        {t("thank_you_for_your_message.description_if_in_danger_adult", { ns: nsMedicaidReportUser })}
      </BodyEmphasis>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_at_carina_we_make_sure"
          ns={nsMedicaidReportUser}
          components={{
            usrspprt: <ExternalLink to={ROUTES.contactUs} target="_blank" />,
          }}
        />
      </Body>
    </>
  );

  const AdultAbuseContentOR = () => (
    <>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_report_to_oregon_dhs_adult"
          ns={nsMedicaidReportUser}
          components={{
            odhs: (
              <ExternalLink
                //eslint-disable-next-line max-len
                to="https://www.oregon.gov/dhs/abuse/Pages/index.aspx"
                target="_blank"
              />
            ),
          }}
        />
      </Body>
      <Body paragraph>{t("thank_you_for_your_message.description_report_to_cm", { ns: nsMedicaidReportUser })}</Body>
      <BodyEmphasis paragraph>
        {t("thank_you_for_your_message.description_if_in_danger_adult", { ns: nsMedicaidReportUser })}
      </BodyEmphasis>
      <Body paragraph>
        {t("thank_you_for_your_message.description_at_carina_we_make_sure_call_us", { ns: nsMedicaidReportUser })}
      </Body>
    </>
  );

  /*********************** CHILD ABUSE CONTENT **************************/

  const ChildAbuseContent = () => {
    switch (funderState) {
      case "WA":
        return <ChildAbuseContentWA />;
      case "OR":
        return <ChildAbuseContentOR />;
      default:
        return <></>;
    }
  };

  const ChildAbuseContentWA = () => (
    <>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_report_to_ps_child"
          ns={nsMedicaidReportUser}
          components={{
            cps: (
              <ExternalLink
                //eslint-disable-next-line max-len
                to="https://www.dcyf.wa.gov/safety/report-abuse"
                target="_blank"
              />
            ),
          }}
        />
      </Body>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_report_to_cm_dshs_list"
          ns={nsMedicaidReportUser}
          components={{
            ldopn: <ExternalLink to="https://www.dshs.wa.gov/office-locations" target="_blank" />,
          }}
        />
      </Body>
      <BodyEmphasis paragraph>
        {t("thank_you_for_your_message.description_if_in_danger_child", { ns: nsMedicaidReportUser })}
      </BodyEmphasis>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_at_carina_we_make_sure"
          ns={nsMedicaidReportUser}
          components={{
            usrspprt: <ExternalLink to={ROUTES.contactUs} target="_blank" />,
          }}
        />
      </Body>
    </>
  );

  const ChildAbuseContentOR = () => (
    <>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_report_to_oregon_dhs_child"
          ns={nsMedicaidReportUser}
          components={{
            odhs: (
              <ExternalLink
                //eslint-disable-next-line max-len
                to="https://www.dcyf.wa.gov/safety/report-abuse"
                target="_blank"
              />
            ),
          }}
        />
      </Body>
      <Body paragraph>{t("thank_you_for_your_message.description_report_to_cm", { ns: nsMedicaidReportUser })}</Body>
      <BodyEmphasis paragraph>
        {t("thank_you_for_your_message.description_if_in_danger_child", { ns: nsMedicaidReportUser })}
      </BodyEmphasis>
      <Body paragraph>
        {t("thank_you_for_your_message.description_at_carina_we_make_sure_call_us", { ns: nsMedicaidReportUser })}
      </Body>
    </>
  );

  /*********************** VIOLENCE CONTENT **************************/

  const ViolenceContent = () => (
    <>
      <Body paragraph>
        {t("thank_you_for_your_message.description_report_to_police", { ns: nsMedicaidReportUser })}
      </Body>
      <BodyEmphasis paragraph>
        {t("thank_you_for_your_message.description_if_in_danger_someone", { ns: nsMedicaidReportUser })}
      </BodyEmphasis>
      <Body paragraph>
        {t("thank_you_for_your_message.description_report_to_cm_cps", { ns: nsMedicaidReportUser })}
      </Body>
      <Body paragraph>
        {t("thank_you_for_your_message.description_at_carina_we_make_sure_call_us", { ns: nsMedicaidReportUser })}
      </Body>
    </>
  );

  /*********************** GENERIC CONTENT **************************/

  const GenericContent = () => (
    <>
      <Body paragraph>
        {t("thank_you_for_your_message.description_cases_serious_misconduct", { ns: nsMedicaidReportUser })}
      </Body>
      <Body paragraph>
        <Trans
          t={t}
          i18nKey="thank_you_for_your_message.description_carina_listing_site"
          ns={nsMedicaidReportUser}
          components={{
            usrspprt: <ExternalLink to={ROUTES.contactUs} target="_blank" />,
          }}
        />
      </Body>
    </>
  );

  /*********************** *************** **************************/

  function getSubjectName(): string {
    const displayRole =
      subjectUserType === UserType.ProxyProvider
        ? t("proxy_provider.user_report_view", { ns: nsMedicaidTagsEnums })
        : t(getKeyForRoleDisplayName(subjectUserType.toString()), { ns: nsMedicaidTagsEnums });
    if (subjectUserType !== UserType.Consumer && !!subjectLastName && subjectLastName.length > 0) {
      return `${subjectFirstName} ${subjectLastName[0]}. (${displayRole})`;
    }
    return `${subjectFirstName} (${displayRole})`;
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm">
      {desktopSize ? (
        <DialogTitle style={{ marginTop: 10 }}>
          <PanelTitle id="report-user-modal-title" paragraph>
            {showForm && t("report_user.label", { ns: nsMedicaidReportUser })}
            {!showForm && !showGenericMessage && t("thank_you_for_your_message.label", { ns: nsMedicaidReportUser })}
            {!showForm &&
              showGenericMessage &&
              t("thank_you_for_your_message_extended.label", { ns: nsMedicaidReportUser })}
          </PanelTitle>
          <IconButton
            aria-label="close"
            style={{ position: "absolute", right: 12, top: 10 }}
            onClick={onClose}
            size="large"
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <DialogTitle
          style={{
            backgroundColor: CivColors.coreDarkNavy,
            color: CivColors.paleWhite,
            marginBottom: 16,
          }}
        >
          <PanelTitle id="report-user-modal-title">
            {showForm && t("report_user.label", { ns: nsMedicaidReportUser })}
            {!showForm && !showGenericMessage && t("thank_you_for_your_message.label", { ns: nsMedicaidReportUser })}
            {!showForm &&
              showGenericMessage &&
              t("thank_you_for_your_message_extended.label", { ns: nsMedicaidReportUser })}
          </PanelTitle>
          <IconButton
            aria-label="close"
            style={{ position: "absolute", right: 12, top: 6 }}
            onClick={onClose}
            size="large"
          >
            <CloseRoundedIcon htmlColor={CivColors.white} />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent id="report-user-modal-content" style={desktopSize ? { paddingTop: 0 } : { paddingTop: 12 }}>
        {showAdultAbuseMessage && <AdultAbuseContent />}
        {showChildAbuseMessage && <ChildAbuseContent />}
        {showViolenceMessage && <ViolenceContent />}
        {showGenericMessage && <GenericContent />}
        {!showForm && (
          <Box textAlign="right" pb={1}>
            <Button variant="contained" type="button" onClick={onClose}>
              Close
            </Button>
          </Box>
        )}

        {showForm && (
          <>
            <Body paragraph>{t("report_user.description.take_seriously", { ns: nsMedicaidReportUser })}</Body>
            <Body paragraph>{t("report_user.description.will_follow_up", { ns: nsMedicaidReportUser })}</Body>

            {desktopSize ? (
              <>
                <Body paragraph>
                  <b>{t("you_are_reporting.label", { ns: nsMedicaidReportUser })}</b> {getSubjectName()}
                </Body>
              </>
            ) : (
              <>
                <BodyEmphasis gutterBottom>
                  {`${t("you_are_reporting.label", { ns: nsMedicaidReportUser })} `}
                </BodyEmphasis>
                <Body paragraph>{getSubjectName()}</Body>
              </>
            )}

            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSubmit(values)}>
              {({ values, isSubmitting, setFieldValue, touched, errors }) => (
                <Form localizationReady={ready}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormSelectField
                        name="behaviorCategory"
                        label={t("field.behavior_category.label", { ns: nsCommonFormsBtns })}
                        InputLabelProps={{
                          shrink: true,
                          fontSize: "1.25rem",
                        }}
                        selectOptions={BehaviorCategoryOptions}
                        onChange={(e) => {
                          if (e.target.value === BehaviorCategory.SOMETHING_ELSE) {
                            setFieldValue("behavior", BehaviorEnum.SOMETHING_ELSE, false);
                          } else {
                            setFieldValue("behavior", "", false);
                          }
                          setHelperToggle(!helperToggle);
                        }}
                        required={isRequiredField("behaviorCategory", schema)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelectField
                        name="behavior"
                        label={t("field.behavior.label", { ns: nsCommonFormsBtns })}
                        selectOptions={values.behaviorCategory ? BehaviorOptions[values.behaviorCategory] : []}
                        controlledRerender={helperToggle}
                        disabled={values.behavior === BehaviorEnum.SOMETHING_ELSE || !values.behaviorCategory}
                        helperText={(() => {
                          if (values.behavior === BehaviorEnum.SOMETHING_ELSE) {
                            return "";
                          } else if (touched.behavior && errors.behavior) {
                            return errors.behavior;
                          }
                        })()}
                        required={isRequiredField("behavior", schema)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="notes"
                        label={t("field.comments.label", { ns: nsCommonFormsBtns })}
                        multiline
                        minRows={8}
                        required={isRequiredField("notes", schema)}
                        helperText={""}
                        maximumlength={NOTES_MAX_LENGTH}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "24px" }}>
                      <FormAlert schema={schema} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "24px", textAlign: "right" }}>
                      <Button variant="text" type="button" onClick={onClose}>
                        {t("button.cancel", { ns: nsCommonFormsBtns })}
                      </Button>
                      <Button type="submit" variant="contained" disabled={isSubmitting}>
                        {isSubmitting
                          ? t("button.sending", { ns: nsCommonFormsBtns })
                          : t("button.report_user", { ns: nsCommonFormsBtns })}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

interface UserReportResponseBannerProps {
  view: ViewAPIResBannerControlEnum;
  setView: React.Dispatch<React.SetStateAction<ViewAPIResBannerControlEnum>>;
}

export const UserReportResponseBanner: React.FC<UserReportResponseBannerProps> = ({ view, setView }) => {
  const location = useLocation();
  const { t } = useTranslation();

  if (view === ViewAPIResBannerControlEnum.HIDDEN) return <></>;

  const handleClose = () => {
    setView(ViewAPIResBannerControlEnum.HIDDEN);
  };

  const isSuccessful = view === ViewAPIResBannerControlEnum.VISIBLE_SUCCESS;

  return (
    <ApiResponseBanner color={isSuccessful ? "success" : "error"} onClose={handleClose}>
      <BodyEmphasis id="report-user-response-banner-text">
        {isSuccessful ? (
          <Trans
            t={t}
            i18nKey="user_report_response_banner.label_success"
            ns={nsMedicaidReportUser}
            components={{
              usrspprt: <InternalLink to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }} />,
            }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="user_report_response_banner.label_error"
            ns={nsMedicaidReportUser}
            components={{
              usrspprt: <InternalLink to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }} />,
            }}
          />
        )}
      </BodyEmphasis>
    </ApiResponseBanner>
  );
};
