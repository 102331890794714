import {
  Box,
  Grid,
  InputAdornment,
  PaginationItem as MUIPaginationItem,
  PaginationRenderItemParams,
  useMediaQuery,
} from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { SortDirection, ThreadFilterEnum, ThreadListDTO } from "src/generated/api_types";
import { getGenericError, getLocalizedRoleType } from "src/i18n/Utilities";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidInbox } from "src/i18n/Namespaces";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { EmptyContainer } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListGrid from "src/pages/inbox/inboxComponents/ThreadListGrid";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Constraint from "src/reusable_view_elements/Constraint";
import { TextField } from "src/reusable_view_elements/form_fields";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button, FormButtonRowStyle } from "src/reusable_view_elements/Button";
import Pagination from "src/reusable_view_elements/Pagination";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";

interface ThreadListContainerProps {
  threadFilter: ThreadFilterEnum;
  loadingText: string;
}

const ThreadListContainer = (props: PropsWithChildren<ThreadListContainerProps>) => {
  const { showSnackbar } = useNotification();
  const { isUserType } = useUserSession();
  const { program } = useSupportAdminContext();
  const {
    pageNumber,
    setPageNumber,
    searchedTerm,
    setSearchedTerm,
    searchQuery,
    setSearchQuery,
    showSearchResetButton,
    setShowSearchResetButton,
    resetSearchAndPagination,
  } = useInboxContext();
  const { userId: otherUserId } = useParams<{ userId: string }>();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonToasts]);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  const [threadList, setThreadList] = useState<ThreadListDTO[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const PAGE_SIZE = 5;
  const searchFieldLabel = otherUserId
    ? t("field.inbox_search.label_job_number", { ns: nsCommonFormsBtns })
    : t("field.inbox_search.label_name_or_job", { ns: nsCommonFormsBtns });

  // User does not have conversations if thread list is empty and searched term is empty
  const userHasNoConversations: boolean = threadList.length === 0 && searchedTerm === "";

  // User Support Managers need a program selected; if user is any other role, proceed with search
  const canRunSearch: boolean =
    (isUserType(UserType.UserSupportManager) && program !== "none") || !isUserType(UserType.UserSupportManager);

  useEffect(() => {
    if (canRunSearch) {
      runSearch(pageNumber - 1);
    }
  }, [program]);

  function runSearch(__pageNumber0Based: number, __reset?: boolean) {
    setLoading(true);
    if (__reset) {
      resetSearchAndPagination();
    } else {
      setSearchedTerm(searchQuery);
    }
    messagingClient
      .getThreads({
        threadFilter: props.threadFilter,
        withUserId: otherUserId,
        pageInfo: {
          pageNum: __pageNumber0Based,
          pageSize: PAGE_SIZE,
          filters: {},
          search: __reset ? "" : searchQuery,
          sort: {
            columnName: "messageThread.lastMessageDate",
            direction: SortDirection.DESC,
          },
          searchMap: {},
        },
        selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
      })
      .then((res) => {
        setThreadList(res.data.content);
        setTotalPages(res.data.totalPages);
        setPageNumber(res.data.number + 1);
      })
      .catch(() => {
        showSnackbar(getGenericError(t), "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading || !ready) {
    return (
      <EmptyContainer>
        <LoadingCircle text={props.loadingText} />
      </EmptyContainer>
    );
  }

  if (!userHasNoConversations) {
    return (
      <>
        {/* Page Title, only in the case of searching by user ID */}
        {otherUserId && (
          <Constraint columns={8} px="16px" pt={4} textAlign="center">
            <BodyTitle>
              {t("threads_with_user.label", {
                ns: nsMedicaidInbox,
                name: (() => {
                  const locizeRole = getLocalizedRoleType(threadList[0].otherUser.roleType);
                  return `${threadList[0].otherUser.firstName} (${t(locizeRole.key, { ns: locizeRole.namespace })})`;
                })(),
              })}
            </BodyTitle>
          </Constraint>
        )}

        {/* Search Bar */}
        <Constraint columns={8}>
          <Box px="16px" pt={4}>
            <form>
              <Grid container alignItems="center" spacing={2} justifyContent="center">
                <Grid item xs={12} sm>
                  <TextField
                    fullWidth
                    label={searchFieldLabel}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    value={searchQuery}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sx={{ alignSelf: "end", marginBottom: "4px" }}>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={false}
                    onClick={() => {
                      setShowSearchResetButton(true);
                      runSearch(0);
                    }}
                    style={desktopSize ? FormButtonRowStyle : {}}
                    sx={{ height: "48px" }}
                  >
                    {t("button.search", { ns: nsCommonFormsBtns })}
                  </Button>
                </Grid>
                {showSearchResetButton && (
                  <Grid item sx={{ alignSelf: "end", marginBottom: "4px" }}>
                    <Button variant="text" type="button" onClick={() => runSearch(0, true)}>
                      {t("button.reset", { ns: nsCommonFormsBtns })}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Constraint>

        {threadList.length > 0 && (
          <>
            {/* Thread List */}
            <ThreadListGrid threads={threadList} />

            {/* Pagination: Next Page, Previous Page Buttons */}
            <Box display="flex" justifyContent="center" paddingY={6}>
              <Pagination
                page={pageNumber}
                count={totalPages}
                renderItem={(item: PaginationRenderItemParams) => {
                  return (
                    <MUIPaginationItem
                      {...item}
                      onClick={() => {
                        setPageNumber(item.page || 1); // item.page is 1-based
                        runSearch(item.page ? item.page - 1 : 0);
                      }}
                    />
                  );
                }}
              />
            </Box>
          </>
        )}

        {threadList.length === 0 && (
          <Box display="flex" justifyContent="center" paddingY={6}>
            <Body>{t("no_results_found_for_term.label", { ns: nsMedicaidInbox, term: searchedTerm })}</Body>
          </Box>
        )}
      </>
    );
  }

  return <>{props.children}</>;
};

export default ThreadListContainer;
