import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TravelDataDTO } from "src/generated/api_types";
import { Bus2Icon, CarRetro2Icon, TripPinsIcon } from "src/assets/icons/StreamlineIcons";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";

interface Props {
  translationNamespace: string;
  carTravelDTO?: TravelDataDTO;
  busTravelDTO?: TravelDataDTO;
}

const TravelInformation = ({ translationNamespace, carTravelDTO, busTravelDTO }: Props) => {
  const { t } = useTranslation([translationNamespace]);

  let carDistanceSubtitleTranslated = t("no_route_available.label", { ns: translationNamespace });
  let carTimeSubtitleTranslated = t("no_route_available.label", { ns: translationNamespace });
  let busTimeSubtitleTranslated = t("no_route_available.label", { ns: translationNamespace });

  if (carTravelDTO) {
    if (carTravelDTO.dist_miles) {
      carDistanceSubtitleTranslated = t("count_miles_away_by_car.label", {
        ns: translationNamespace,
        count: carTravelDTO.dist_miles,
      });
    }

    if (carTravelDTO.time_minutes >= 0) {
      carTimeSubtitleTranslated = t("count_minutes_away_by_car.label", {
        ns: translationNamespace,
        count: carTravelDTO.time_minutes,
      });
    }
  }

  if (busTravelDTO) {
    if (busTravelDTO.time_minutes >= 0) {
      busTimeSubtitleTranslated = t("count_minutes_away_by_bus.label", {
        ns: translationNamespace,
        count: busTravelDTO.time_minutes,
      });
    }
  }

  return (
    <Grid container style={{ padding: "12px" }} spacing={2}>
      <Grid item xs={4}>
        <Box maxWidth="90px" mx="auto">
          <TripPinsIcon color="primary" sx={{ width: "100%", height: "auto" }} />
        </Box>
        <BodyEmphasis>{carDistanceSubtitleTranslated}</BodyEmphasis>
      </Grid>
      <Grid item xs={4}>
        <Box maxWidth="90px" mx="auto">
          <CarRetro2Icon color="primary" sx={{ width: "100%", height: "auto" }} />
        </Box>
        <BodyEmphasis>{carTimeSubtitleTranslated}</BodyEmphasis>
      </Grid>
      <Grid item xs={4}>
        <Box maxWidth="90px" mx="auto">
          <Bus2Icon color="primary" sx={{ width: "100%", height: "auto" }} />
        </Box>
        <BodyEmphasis>{busTimeSubtitleTranslated}</BodyEmphasis>
      </Grid>
    </Grid>
  );
};

export default TravelInformation;
