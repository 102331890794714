import { useTranslation } from "react-i18next";
import { UserType } from "src/context/UserSessionContext";
import { RoleType } from "src/generated/api_types";
import { nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedRoleType } from "src/i18n/Utilities";

export const NameOrgRoleTitle = ({
  component: Component,
  roleType,
  firstName,
  lastInitial,
  org,
}: {
  component: React.FC;
  firstName: string;
  lastInitial?: string;
  roleType: RoleType;
  org?: string;
}) => {
  const { t } = useTranslation(nsMedicaidTagsEnums);

  enum ParenthesesDataType {
    organization,
    role,
    none,
  }

  // Helps determine what sort of information to render based on their role
  function determineParenthesesDataType(senderRole: RoleType): ParenthesesDataType {
    if (senderRole === RoleType.MEDICAID_REFERRAL_COORDINATOR) {
      return ParenthesesDataType.organization;
    }
    if (senderRole === RoleType.MEDICAID_CASE_MANAGER || senderRole === RoleType.PROXY_PROVIDER) {
      return ParenthesesDataType.role;
    }
    return ParenthesesDataType.none;
  }

  let text = firstName;
  if (lastInitial) {
    text += ` ${lastInitial[0]}.`;
  }
  // Information shown in parentheses after name (role or organization)
  const type = determineParenthesesDataType(roleType);
  if (type === ParenthesesDataType.organization && org) {
    text += ` (${org})`;
  }
  if (type === ParenthesesDataType.role) {
    const locizeRole = getLocalizedRoleType(roleType);
    text += ` (${t(locizeRole.key, { ns: locizeRole.namespace })})`;
  }
  return <Component>{text}</Component>;
};

// When survey link is allowed to be shown
export function showSurveyBanner(otherUserRole: RoleType, isUserType: (type: UserType) => boolean): boolean {
  // No surveys should be made for user support
  if (otherUserRole === RoleType.USER_SUPPORT_MANAGER || otherUserRole === RoleType.USER_SUPPORT_AGENT) {
    return false;
  }

  // Case Workers cannot make surveys
  if (isUserType(UserType.CaseWorker)) return false;

  // Providers cannot match with another Provider
  if (isUserType(UserType.Provider) && otherUserRole === RoleType.PROVIDER) {
    return false;
  }

  // Consumers, referral coordinators, and case managers cannot survey each other
  if (
    isUserType(UserType.Consumer) ||
    isUserType(UserType.MedicaidReferralCoordinator) ||
    isUserType(UserType.MedicaidCaseManager)
  ) {
    if (
      otherUserRole === RoleType.CONSUMER ||
      otherUserRole === RoleType.MEDICAID_REFERRAL_COORDINATOR ||
      otherUserRole === RoleType.MEDICAID_CASE_MANAGER
    ) {
      return false;
    }
  }

  return true;
}
