import { PropsWithChildren } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import RoundImage, { RoundImageProps } from "src/reusable_view_elements/RoundImage";
import CivTheme, { width } from "src/themes/civilization/CivTheme";

interface BlurbProps {
  imgProps: RoundImageProps;
  reverse?: boolean;
}

const defaultProps = {
  reverse: false,
};

const BlurbImage = {
  maxWidth: width(3),
};

function Blurb({ reverse, imgProps, ...props }: PropsWithChildren<BlurbProps>) {
  const isScreenXS = useMediaQuery(CivTheme.breakpoints.down("sm"));
  const columnDirection = reverse ? "column-reverse" : "column";
  const contentDirection = isScreenXS ? columnDirection : "row";
  const rowMarginDirection = reverse ? { marginRight: 48 } : { marginLeft: 48 };
  const contentStyle = isScreenXS ? { marginTop: 48 } : rowMarginDirection;
  const Image = <RoundImage style={BlurbImage} {...imgProps} />;

  return (
    <Grid container direction={contentDirection} alignItems="center">
      {!reverse && Image}
      <Grid item sm style={contentStyle}>
        {props.children}
      </Grid>
      {reverse && Image}
    </Grid>
  );
}

Blurb.defaultProps = defaultProps;
export default Blurb;
