import { Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { GroupInviteResponseDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidAccountPortal } from "src/i18n/Namespaces";
import { TaskChecklistCheckIcon } from "src/assets/icons/StreamlineIcons";
import { getLastGroupInviteSentByMe, reGenerateGroupInvite } from "src/pages/carinaAdmin/management/Queries";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import { TextField } from "src/reusable_view_elements/form_fields";
import InformationBox from "src/reusable_view_elements/InformationBox";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardDivider from "src/reusable_view_elements/PortalCardDivider";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import WarningBox from "src/reusable_view_elements/WarningBox";
import theme from "src/themes/civilization/CivTheme";
import { applyMMddyyyyFormat } from "src/utilities/DashboardUtilities";

const ConsumerGroupInvitePortalCard = () => {
  const { showSnackbar } = useNotification();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready: translationReady } = useTranslation([nsMedicaidAccountPortal, nsCommonFormsBtns, nsCommonToasts]);

  const [latestInvite, setLatestInvite] = useState<GroupInviteResponseDTO>();
  const [loadingInvite, setLoadingInvite] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoadingInvite(true);
        const res = await getLastGroupInviteSentByMe();
        setLatestInvite(res.data);
        setLoadingInvite(false);
      } catch (e) {
        showSnackbar(t("consumer_invite_link.error.issue_retrieving", { ns: nsCommonToasts }), "error");
      } finally {
        setLoadingInvite(false);
      }
    })();
  }, []);

  const handleGenerateLink = async () => {
    setLoadingInvite(true);
    try {
      const res = await reGenerateGroupInvite();
      setLatestInvite(res.data);
      setLoadingInvite(false);
      showSnackbar(t("consumer_invite_link.success.group_invite_link_created", { ns: nsCommonToasts }), "success");
    } catch (e) {
      showSnackbar(t("consumer_invite_link.error.issue_generating_new_link", { ns: nsCommonToasts }), "error");
    } finally {
      setLoadingInvite(false);
    }
  };

  const handleCopyInviteLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    showSnackbar(t("consumer_invite_link.success.invite_link_copied", { ns: nsCommonToasts }), "success");
  };

  if (!translationReady) {
    return <LoadingPortalCardSkeleton />;
  }

  return (
    <PortalCard>
      <PortalCardTitle icon={TaskChecklistCheckIcon} viewMorePath="#" hideViewMore>
        <BodyTitle>{t("consumer_invite_link.label", { ns: nsMedicaidAccountPortal })}</BodyTitle>
        <Body>{t("consumer_invite_link.description.share_this_link", { ns: nsMedicaidAccountPortal })}</Body>
      </PortalCardTitle>

      <PortalCardDivider />

      {loadingInvite && <LoadingCircle text={t("loading_group_invite.label", { ns: nsMedicaidAccountPortal })} />}

      {!loadingInvite && (
        <>
          {latestInvite && latestInvite.expiresAt < Date.now() && (
            <WarningBox style={{ marginTop: 30 }}>
              <Body>{t("consumer_invite_link.description.link_expired", { ns: nsMedicaidAccountPortal })}</Body>
            </WarningBox>
          )}
          {!latestInvite && (
            <InformationBox style={{ marginTop: 30 }}>
              <Body>{t("consumer_invite_link.description.how_to_get", { ns: nsMedicaidAccountPortal })}</Body>
            </InformationBox>
          )}
          {latestInvite && (
            <Grid item style={{ paddingTop: 24, overflowX: "scroll" }}>
              <TextField
                id="group-invite-link"
                label="Link"
                value={latestInvite.link}
                helperText={t("field.consumer_invite_link.helper_text", {
                  ns: nsCommonFormsBtns,
                  expdate: applyMMddyyyyFormat(latestInvite.expiresAt),
                })}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          )}

          <Grid
            container
            spacing={2}
            direction={desktopSize ? "row" : "column-reverse"}
            alignItems={desktopSize ? "flex-start" : "center"}
            style={{ marginTop: 20 }}
          >
            {latestInvite && (
              <Grid item>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => handleCopyInviteLink(latestInvite.link)}
                  disabled={latestInvite.expiresAt <= Date.now()}
                >
                  {t("button.copy_invite_link", { ns: nsCommonFormsBtns })}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                type="button"
                onClick={handleGenerateLink}
                disabled={latestInvite && latestInvite?.expiresAt > Date.now()}
              >
                {t("button.generate_new_link", { ns: nsCommonFormsBtns })}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </PortalCard>
  );
};

export default ConsumerGroupInvitePortalCard;
