import { IconButton, Menu } from "@mui/material";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { TFunction } from "i18next";
import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { CoordinatorsListDTO } from "src/generated/api_types";
import { nsCoordinatorAndSupportTools, nsUniversalDatatables } from "src/i18n/Namespaces";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { useCoordinatorsDatatable } from "src/pages/medicaidAccount/coordinatorAccount/MedicaidCoordTeamDashboard";
import FirstMessageDialog, {
  FirstMessageContext,
  FirstMessageDialogProps,
} from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import Constraint from "src/reusable_view_elements/Constraint";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { impersonateUser } from "src/reusable_view_elements/StartImpersonationButton";
import CivColors from "src/themes/civilization/CivColors";

type MessageDialogState = Omit<FirstMessageDialogProps, "context" | "isDialogOpen" | "onCloseDialog">;

export const CoordinatorsList = () => {
  const { userSession } = useUserSession();
  const { ClientsideDatatable, isFetching, addOrUpdateData } = useCoordinatorsDatatable();
  const { t, ready } = useTranslation([nsUniversalDatatables, nsCoordinatorAndSupportTools]);

  const [selectedRowData, setSelectedRowData] = useState<CoordinatorsListDTO>();
  const [showMessageDialog, setShowMessageDialog] = useState<boolean>(false);

  const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<CoordinatorsListDTO> } = {
    firstName: {
      title: t("first_name.label", { ns: nsUniversalDatatables }),
      field: "firstName",
    },
    lastName: {
      title: t("last_name.label", { ns: nsUniversalDatatables }),
      field: "lastName",
    },
    email: {
      title: t("email.label", { ns: nsUniversalDatatables }),
      field: "email",
    },
    actions: {
      title: t("actions.label", { ns: nsUniversalDatatables }),
      render: (data) => data.id !== userSession?.id && getActionsTooltip(data, handleMessageClick, t),
    },
  };

  /************************************************* *********************************************************/
  /************************************************* *********************************************************
   The next two functions update a coordinator data row  without having to
   make a call to the BE. This prevents rerendering the list, which would break the focus sequence of
   any open 'first message success snackbar' or move the user back to the top when using infinite scrolling
   ************************************************* *********************************************************/
  const updateCoordinatorRowAfterFirstMessage = (threadId?: string) => {
    if (!threadId || !selectedRowData) return;
    const dataRowConversionFn = (existing?: CoordinatorsListDTO) =>
      existing ? { ...existing, threadIds: [threadId] } : selectedRowData;
    addOrUpdateData(selectedRowData.id, dataRowConversionFn);
  };
  /************************************************* *********************************************************/

  const handleMessageClick = (data: CoordinatorsListDTO) => {
    setShowMessageDialog(true);
    setSelectedRowData(data);
  };

  function messageData(data: CoordinatorsListDTO): MessageDialogState {
    return {
      recipientId: data.id,
      recipientFirstName: data.firstName,
      recipientLastName: data.lastName,
      recipientRole: UserType.MedicaidReferralCoordinator,
    };
  }

  if (!ready) {
    return (
      <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
        <Constraint columns={6}>
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  return (
    <>
      {selectedRowData && (
        <FirstMessageDialog
          {...messageData(selectedRowData)}
          context={FirstMessageContext.REFERRAL_COORDINATOR_TEAM}
          isDialogOpen={showMessageDialog}
          onCloseDialog={(threadId) => {
            updateCoordinatorRowAfterFirstMessage(threadId);
            setShowMessageDialog(false);
            setSelectedRowData(undefined);
          }}
        />
      )}
      <ClientsideDatatable
        columnDefinitions={COLUMN_DEFINITIONS}
        persistKeyPrefix="coordinators-list"
        title={t("all_coordinators.label", { ns: nsCoordinatorAndSupportTools })}
        localization={{
          body: {
            emptyDataSourceMessage: isFetching
              ? `${t("fetching_coords_list.description", { ns: nsCoordinatorAndSupportTools })}...`
              : t("no_coords_records.description", { ns: nsCoordinatorAndSupportTools }),
            filterRow: {
              filterTooltip: t("filter.label", { ns: nsUniversalDatatables }),
            },
          },
          toolbar: {
            addRemoveColumns: t("add_remove_columns.label", { ns: nsUniversalDatatables }),
            showColumnsTitle: t("show_hide_columns.label", { ns: nsUniversalDatatables }),
            showColumnsAriaLabel: t("show_hide_columns.label", { ns: nsUniversalDatatables }),
            searchPlaceholder: t("search.label", { ns: nsUniversalDatatables }),
          },
          pagination: {
            labelDisplayedRows: `{from}-{to} ${t("of.label", { ns: nsUniversalDatatables })} {count}`,
            // labelRowsSelect: t("rows.label", { ns: nsUniversalDatatables }), // TODO: Unsure which one to map to
            firstAriaLabel: t("first_page.label", { ns: nsUniversalDatatables }),
            firstTooltip: t("first_page.label", { ns: nsUniversalDatatables }),
            previousAriaLabel: t("previous_page.label", { ns: nsUniversalDatatables }),
            previousTooltip: t("previous_page.label", { ns: nsUniversalDatatables }),
            nextAriaLabel: t("next_page.label", { ns: nsUniversalDatatables }),
            nextTooltip: t("next_page.label", { ns: nsUniversalDatatables }),
            lastAriaLabel: t("last_page.label", { ns: nsUniversalDatatables }),
            lastTooltip: t("last_page.label", { ns: nsUniversalDatatables }),
          },
        }}
      />
    </>
  );
};

function getActionsTooltip(
  data: CoordinatorsListDTO,
  msgClickHandler: (rowData: CoordinatorsListDTO) => void,
  t: TFunction<string[]>,
) {
  const history = useHistory();
  const { reloadAuthState, loading: loadingUserSession, isImpersonated } = useUserSession();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={`row-actions-button-${data.id}`}
        aria-label="open actions menu"
        aria-controls={open ? "row-actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="large"
      >
        <AddRoundedIcon />
      </IconButton>
      <Menu
        id={`row-actions-menu-${data.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `row-actions-button-${data.id}`,
        }}
      >
        <MenuItem
          disabled={isImpersonated()}
          onClick={async () => {
            await impersonateUser(
              data.id,
              reloadAuthState,
              loadingUserSession,
              history,
              Medicaid_Coordinator_Dashboard_Routes.team,
            );
          }}
        >
          {t("actions.impersonate_name.label", {
            ns: nsUniversalDatatables,
            name: `${data.firstName} ${data.lastName}`,
          })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            switch (data.threadIds.length) {
              case 0:
                msgClickHandler(data);
                break;
              case 1:
                history.push(generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: data.threadIds[0] }));
                break;
              default:
                history.push(generatePath(MEDICAID_INBOX_ROUTES.threadsWithUser, { userId: data.id }));
            }
          }}
        >
          {data.threadIds.length === 0
            ? t("actions.message.label", { ns: nsUniversalDatatables })
            : t("actions.follow_up.label", { ns: nsUniversalDatatables })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.setItem("default-job-coord-filter", `${data.firstName} ${data.lastName}` || "");
            history.push(Medicaid_Coordinator_Dashboard_Routes.jobs_list);
          }}
        >
          {t("actions.see_job_posts.label", { ns: nsUniversalDatatables })}
        </MenuItem>
      </Menu>
    </>
  );
}
