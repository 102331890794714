import {
  Box,
  Divider,
  Grid,
  Hidden,
  Link as MuiLink,
  ListItem,
  MenuList,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Location } from "history";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, NavLink as NavRouterLink, NavLinkProps as RouterLinkProps } from "react-router-dom";
import CarinaLogo from "src/assets/images/logo-carina-version-2.svg";
import { nsCommonAlt, nsCommonNavigation } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import Header from "src/reusable_view_elements/Header";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { MenuButton } from "src/reusable_view_elements/MenuButton";
import MenuItem from "src/reusable_view_elements/MenuItem";
import FullSizeMenu from "src/reusable_view_elements/navbars/navbar_elements/FullSizeMenu";
import { basicBodyEmphasisStyle, basicBodyStyle, Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import LanguageButton from "./navbar_elements/LanguageButton";

/**
 * Link Component (specialized for NavBar)
 */
export const StyledText = styled(Body)({
  display: "inline-block",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}) as typeof Typography;

// Helper function
export const isNavStyleActive = (match: any, location: Location): boolean =>
  (!!match && match.isExact) ||
  (!!match && match.url !== "" && match.url !== "/" && location.pathname.includes(match.url));

export const NavLink = (props: RouterLinkProps) => (
  // component="span" is needed for the case when <Badge> nests within <StyledText> which uses Typography
  // https://stackoverflow.com/questions/41928567/div-cannot-appear-as-a-descendant-of-p
  <StyledText component="span">
    <MuiLink
      component={NavRouterLink}
      to={props.to}
      activeStyle={{ color: CivColors.coreOrange, ...basicBodyEmphasisStyle }}
      isActive={isNavStyleActive}
      style={{ textDecoration: "none", ...basicBodyStyle }}
    >
      {props.children}
    </MuiLink>
  </StyledText>
);

/**
 * Carina Logo (specific to NavBar)
 */
export function NavBarLogo() {
  const { t } = useTranslation([nsCommonAlt]);
  return (
    <InternalLink to="/" sx={{ lineHeight: 0 }}>
      <img
        src={CarinaLogo}
        alt={t("navigation.carina_logo", { ns: nsCommonAlt })}
        width={141}
        height={66}
        id="nav-bar-carina-logo"
      />
    </InternalLink>
  );
}

/**
 * Basic Navigation Bar
 *
 * Provides a basic NavBar which includes the Carina logo. Child components will have space in between.
 */
type BasicNavBarProps = {
  left?: ReactElement;
  center?: ReactElement;
  right?: ReactElement;
};

export function BasicNavBar(props: BasicNavBarProps) {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const desktopStyle = { padding: 24 } as React.CSSProperties;
  const mobileStyle = { padding: "24px 16px" } as React.CSSProperties;
  const style = desktopSize ? desktopStyle : mobileStyle;

  return (
    <Header>
      <Grid style={style} container justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Grid container alignItems="center">
            <NavBarLogo />
            {props.left}
          </Grid>
        </Grid>
        {props.center && (
          <Hidden mdDown>
            <Grid item style={{ textAlign: "center" }}>
              {props.center}
            </Grid>
          </Hidden>
        )}
        <Grid item xs style={{ textAlign: "right" }}>
          {props.right}
        </Grid>
      </Grid>
    </Header>
  );
}

BasicNavBar.defaultProps = {
  center: undefined,
  right: undefined,
};

interface NavBarProps {
  showLanguage?: boolean;
  hideMenu?: boolean;
  hideLogin?: boolean;
}

const NavBarDefaults = {
  showLanguage: false,
  hideMenu: false,
  hideLogin: false,
};

/**
 * Composite Navigation Bar for Public Pages
 *
 * - A ready-to-use nav bar that was originally built with the program pages in mind. By default, it shows main menu and
 * hides the language button. Via props, you can choose to hide the menu and show the language button.
 *
 * - Built on top of the BasicNavBar
 */
function NavBar({ showLanguage, hideMenu, hideLogin }: NavBarProps) {
  const { t, ready } = useTranslation([nsCommonNavigation]);
  const [mainMenuIsOpen, setMainMenuVisibility] = useState(false);

  const list = [
    {
      label: ready && showLanguage ? t("link.home") : "Home",
      route: ROUTES.root,
    },
    {
      label: ready && showLanguage ? t("link.find_home_care") : "Find Home Care",
      route: ROUTES.homeCareOptions,
    },
    {
      label: ready && showLanguage ? t("link.find_child_care") : "Find Child Care",
      route: CHILD_CARE_ROUTES.landing,
    },
    {
      label: ready && showLanguage ? t("link.provide_care") : "Provide Care",
      route: ROUTES.providers,
    },
    {
      label: ready && showLanguage ? t("link.about_carina") : "About Carina",
      route: ROUTES.aboutCarina,
    },
  ];

  return (
    <BasicNavBar
      center={
        !hideMenu ? (
          <>
            {/* DESKTOP SIZE */}
            <Hidden lgDown>
              <Box component="nav" aria-label="Site Wide" display="flex" sx={{ height: "24px" }}>
                {list.map((link, i) => (
                  <Box
                    key={link.label}
                    sx={{
                      borderLeft: i != 0 ? `1px solid ${CivColors.coreDarkNavy}` : "none",
                      "& .MuiTypography-root": { display: "flex" },
                    }}
                  >
                    <NavLink to={link.route}>{link.label}</NavLink>
                  </Box>
                ))}
              </Box>
            </Hidden>
          </>
        ) : undefined
      }
      right={
        <>
          {/* DESKTOP SIZE */}
          <Hidden lgDown>
            {showLanguage && <LanguageButton menuType="desktop" />}
            {!hideLogin && (
              <LinkStyledAsButton variant="contained" component={RouterLink} to={ROUTES.login}>
                {ready ? t("link.login") : "Login"}
              </LinkStyledAsButton>
            )}
          </Hidden>

          {/* MOBILE SIZE */}
          <Hidden lgUp>
            {hideMenu && showLanguage && <LanguageButton menuType="mobile" />}
            {!hideMenu && (
              <>
                <MenuButton
                  onClick={() => {
                    setMainMenuVisibility(true);
                  }}
                >
                  {ready ? t("link.menu") : "Menu"}
                </MenuButton>

                <FullSizeMenu open={mainMenuIsOpen} closeMenu={() => setMainMenuVisibility(false)}>
                  <MenuList aria-label="Site Wide" sx={{ margin: "24px 0px", padding: 0 }}>
                    {list.map((item) => (
                      <MenuItem
                        key={item.label}
                        component={NavRouterLink}
                        // @ts-ignore
                        to={item.route}
                        selected={window.location.pathname === item.route}
                        onClick={() => setMainMenuVisibility(false)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                  <Divider />
                  {!hideLogin && (
                    <ListItem sx={{ padding: 0, marginTop: "24px" }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <LinkStyledAsButton variant="contained" component={RouterLink} to={ROUTES.login}>
                          {ready ? t("link.login") : "Login"}
                        </LinkStyledAsButton>
                      </div>
                    </ListItem>
                  )}
                  {showLanguage && (
                    <ListItem sx={{ marginTop: "16px", padding: 0, justifyContent: "center" }}>
                      <LanguageButton menuType="mobile" sx={{ paddingLeft: "16px", paddingRight: "8px" }} />
                    </ListItem>
                  )}
                </FullSizeMenu>
              </>
            )}
          </Hidden>
        </>
      }
    />
  );
}

NavBar.defaultProps = NavBarDefaults;
export default NavBar;
