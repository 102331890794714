import { CancelRounded } from "@mui/icons-material";
import { alpha, Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const StyledChip = styled(MuiChip)((props: MuiChipProps) => ({
  height: "100%", //Preventing the text from overflowing the chip
  backgroundColor: alpha(CivColors.coreDarkNavy, 0.08),
  borderRadius: "48px",
  padding: "8px 6px 8px 12px",
  paddingRight: !props.disabled && props.onDelete ? "6px" : "12px", //Adjusting depending on delete icon's presence
  ":hover": {
    cursor: "auto", // set cursor on area of chip outside the delete icon
    backgroundColor: alpha(CivColors.coreDarkNavy, 0.12),
  },
  "&.Mui-focusVisible": {
    backgroundColor: alpha(CivColors.coreDarkNavy, 0.12),
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(CivColors.coreDarkNavy, 0.08),
    opacity: 1,
    color: CivColors.mediumGray,
  },
  "& .MuiChip-label": {
    padding: 0,
    textWrap: "wrap",
  },
  "& .MuiChip-deleteIcon": {
    marginLeft: !props.disabled && props.onDelete ? "6px" : 0, //Adjusting depending on delete icon's presence
    marginRight: 0,
    fontSize: "1.1rem", // Override default 'small' size of 16px, set by the prop size="small"
  },
}));

type ChipProps = { label: string; readonly?: boolean } & Omit<MuiChipProps, "label">; //Customizing the type of 'label' prop

const Chip = ({ label, readonly, onDelete, ...restProps }: ChipProps) => {
  return (
    <StyledChip
      size="small" // Override default 'medium' size, which doesn't fit the design
      label={<Body>{label}</Body>}
      onPointerDown={(e) => e.preventDefault()} // Prevents opening the dropdown when clicking on the chip
      onDelete={!restProps.disabled && !readonly && onDelete ? onDelete : undefined} //Show delete icon only if not disabled and fn is provided
      deleteIcon={<CancelRounded />}
      {...restProps}
    />
  );
};

export default Chip;
