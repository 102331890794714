import {
  alpha,
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { ComponentType } from "react";
import { Checkbox } from "src/reusable_view_elements/form_fields/CheckboxField";
import Radio from "src/reusable_view_elements/form_fields/Radio";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface MenuItemProps extends MuiMenuItemProps {
  variant?: "radio" | "checkbox";
  Icon?: ComponentType<SvgIconProps>;
  SelectedIcon?: ComponentType<SvgIconProps>;
}

const StyledMenuItem = styled(MuiMenuItem)({
  "&.Mui-selected": {
    backgroundColor: "inherit",
    "& span": {
      // Applied when content passed to the Text component is not text only (e.g. it has a badge or icon)
      fontWeight: 700,
      fontFamily: "Europa-Bold, sans-serif",
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: alpha(CivColors.coreDarkNavy, 0.12),
  },
  "&.Mui-selected.Mui-focusVisible": {
    backgroundColor: alpha(CivColors.coreOrange, 0.08),
  },
});

/**
 * Usage:
 * - Text-only: `<MenuItem />`
 * - Icon: `<MenuItem icon={StarOutlineIcon} />`
 * - Icon with a "selected" version: `<MenuItem Icon={StarOutlineIcon} SelectedIcon={StarFilledIcon} />`
 * - Radio or checkbox: `<MenuItem variant="checkbox" />`
 */
const MenuItem = ({ children, Icon, SelectedIcon, variant, ...menuItemProps }: MenuItemProps) => {
  const textOnly: boolean = !Icon && !variant;

  const Decoration = () => {
    if (Icon)
      return menuItemProps.selected && SelectedIcon ? <SelectedIcon color="secondary" /> : <Icon color="primary" />;
    if (variant === "radio") return <Radio checked={menuItemProps.selected} />;
    if (variant === "checkbox") return <Checkbox checked={menuItemProps.selected} />;
    return null;
  };

  const Text = (props: TypographyProps) => {
    return menuItemProps.selected && textOnly ? <BodyEmphasis color="secondary" {...props} /> : <Body {...props} />;
  };

  return (
    <StyledMenuItem {...menuItemProps}>
      {!textOnly && (
        <ListItemIcon>
          <Decoration />
        </ListItemIcon>
      )}
      <ListItemText sx={{ paddingTop: "12px", paddingBottom: "12px" }}>
        <Text>{children}</Text>
      </ListItemText>
    </StyledMenuItem>
  );
};

export default MenuItem;
