import { Grid, useMediaQuery } from "@mui/material";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import Illustration from "src/assets/images/emans-home-care-process-4.svg";
import theme from "src/themes/civilization/CivTheme";

const CaseManager = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  const { isFunderState } = useUserSession();
  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>
              {isFunderState(FunderState.Washington) && `Work with CDWA`}
              {isFunderState(FunderState.Oregon) && `Work with the consumer's case manager`}
            </SectionTitle>
            {isFunderState(FunderState.Washington) && (
              <Body>
                Once you find a consumer to work with, you{" "}
                <b>must receive an Okay to Work from Consumer Direct Care Network Washington (CDWA)</b> to be approved
                before you can start working.
              </Body>
            )}
            {isFunderState(FunderState.Oregon) && (
              <Body>
                Once you find a consumer to work with, you{" "}
                <b>must receive authorization from their case manager manager</b> before you begin work.
              </Body>
            )}
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>
    </>
  );
};

export default CaseManager;
