import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CivColors from "src/themes/civilization/CivColors";

const StyledTooltip = styled(MuiTooltip)({
  backgroundColor: "inherit",
  color: CivColors.coreDarkNavy,
  borderRadius: "0px",
  width: 24,
  height: 24,
});

/**
 *   With a mouse:
 *   - The tooltip contents will appear if it's hovered over.
 *
 *   On mobile devices:
 *   - The tooltip contents will appear if it's tapped (touched).
 *   - enterTouchDelay is set to 0 so that a tap will open the tooltip. A longer delay means a long press is required.
 *   - leaveTouchDelay is the number of ms to leave the tooltip open before it disappears automatically. Because our
 *   tooltips usually have information to read, a long leaveTouchDelay gives the user enough time to read the content.
 *   - The tooltip will also disappear if the user clicks away at any time.
 */
const Tooltip = ({ children, ...rest }: MuiTooltipProps) => (
  <StyledTooltip enterTouchDelay={0} leaveTouchDelay={8000} {...rest}>
    {children}
  </StyledTooltip>
);

export default Tooltip;
