import { SelectChangeEvent, SelectProps } from "@mui/material";
import { FastFieldProps } from "formik";
import { useState } from "react";
import Select from "src/reusable_view_elements/form_fields/Select";
import { ControllableFastField, ControllableFastFieldProps } from "./ControllableFastField";

export interface SelectOption<T> {
  label: string;
  value: T;
  disabled?: boolean;
  locizeKey?: string;
  namespace?: string;
  lang?: string;
}

type FormSelectFieldProps<T, FFControl> = {
  name: string;
  label: string;
  selectOptions: SelectOption<T>[];
  onChange?: (e: SelectChangeEvent<unknown>) => void;
  placeholderText?: string;
} & Omit<ControllableFastFieldProps<FFControl>, "name"> &
  Omit<SelectProps, "label">;

export const FormSelectField = <T extends string, FFControl>(props: FormSelectFieldProps<T, FFControl>) => {
  const { name, label, selectOptions, onChange, controlledRerender, ...restProps } = props;
  // 'meta.touched' resets at some point, so the 'touched' variable keeps track of whether the field has been touched
  const [touched, setTouched] = useState(false);

  return (
    <ControllableFastField name={name} controlledRerender={controlledRerender} languagePeek={label}>
      {({ field, meta, form }: FastFieldProps<T>) => {
        if (meta.touched) setTouched(true);
        return (
          <>
            <Select
              name={name}
              value={field.value}
              label={label}
              selectOptions={selectOptions}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
              onBlur={(e) => {
                form.setFieldTouched(name, true);
                field.onBlur(e);
              }}
              required={restProps.required}
              disabled={restProps.disabled}
              helperText={touched && meta.error ? meta.error : restProps.helperText}
              error={Boolean(touched && meta.error)}
              placeholderText={restProps.placeholderText}
              inputProps={{
                ...restProps.inputProps,
              }}
              {...restProps}
            />
          </>
        );
      }}
    </ControllableFastField>
  );
};
FormSelectField.defaultProps = {
  onChange: () => {},
};
