import { Container, Divider, Tab, Tabs } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import PageContainerDashboard from "src/reusable_view_elements/PageContainerDashboard";

export enum CarinaAdminDashboardTabs {
  AGENCIES,
  CONSUMERS,
  FUNDERS,
  PROVIDERS,
}

export interface CarinaAdminDashboardContainerProps {
  tab: CarinaAdminDashboardTabs;
}

export const CarinaAdminDashboardContainer: React.FC<PropsWithChildren<CarinaAdminDashboardContainerProps>> = ({
  tab,
  children,
}) => {
  const { isUserType } = useUserSession();

  return (
    <PageContainerDashboard
      title={isUserType(UserType.CarinaAdmin) ? "Carina Admin Dashboard" : "Private Pay Home Care Dashboard"}
      maxWidth="xl"
    >
      <Tabs value={tab}>
        <Tab
          component={Link}
          label="Agency Admins"
          to={
            isUserType(UserType.CarinaAdmin)
              ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
              : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list
          }
        />
        <Tab
          component={Link}
          label="Clients"
          to={
            isUserType(UserType.CarinaAdmin)
              ? CARINA_ADMIN_DASHBOARD_ROUTES.consumer_list
              : SUPPORT_ADMIN_DASHBOARD_ROUTES.consumer_list
          }
        />
        <Tab
          component={Link}
          label="Funder Admins"
          to={
            isUserType(UserType.CarinaAdmin)
              ? CARINA_ADMIN_DASHBOARD_ROUTES.funder_list
              : SUPPORT_ADMIN_DASHBOARD_ROUTES.funder_list
          }
        />
        <Tab
          component={Link}
          label="Providers"
          to={
            isUserType(UserType.CarinaAdmin)
              ? CARINA_ADMIN_DASHBOARD_ROUTES.provider_list
              : SUPPORT_ADMIN_DASHBOARD_ROUTES.provider_list
          }
        />
      </Tabs>
      <Divider />
      <Container maxWidth={false}>{children!}</Container>
    </PageContainerDashboard>
  );
};
