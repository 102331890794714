import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { format } from "date-fns";
import { Formik } from "formik";
import _ from "lodash";
import { ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  AxiosPPHCAgencyDashboardConsumersControllerClient,
  ConsumerApplication_DTO_Response_Details,
  ConsumerApplication_DTO_Response_Listing,
  ConsumerApplicationDeclinedReason,
  ConsumerApplicationGender,
  ConsumerApplicationStatus,
  ContactMethod,
  HighlightReason,
  User_DTO_Response_NameAndIdOnly,
} from "src/generated/api_types";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import {
  AdminAssignmentDialog,
  SelectedAdminAssignment,
} from "src/pages/agencyAdmin/dashboard/consumers/AdminAssignmentDialog";
import { useConsumersDatatable } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerDashboard";
import { DECLINED_NOTES_MAX } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerValidationSchema";
import {
  getCareRecipientOrContactFirstName,
  getCareRecipientOrContactLastName,
} from "src/pages/agencyAdmin/dashboard/consumers/GetConsumers";
import { updateConsumerStatusOnly } from "src/pages/agencyAdmin/dashboard/consumers/Queries";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { getCounty } from "src/pages/funderAdmin/dashboard/funder_admin_consumers/FunderAdminConsumersList";
import {
  renderStatusString,
  statusColumn,
} from "src/pages/offerings/pphc/datatable_columns/consumer_applications/StatusColumn";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { FormSelectField, FormTextField, SelectOption } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import Select from "src/reusable_view_elements/form_fields/Select";
import { InternalLink } from "src/reusable_view_elements/Link";
import Tooltip from "src/reusable_view_elements/Tooltip";
import { Body, PanelTitle } from "src/reusable_view_elements/Typography";
import { Button } from "src/reusable_view_elements/Button";
import {
  ConsumerApplicationDeclinedReasonOptions,
  getConsumerAppDeclinedReasonLabel,
} from "src/utilities/ConsumerApplicationUtilities";
import { object, string } from "yup";

const PREFIX = "ConsumersList";

const classes = {
  cellHighlight: `${PREFIX}-cellHighlight`,
  cellNotHighlight: `${PREFIX}-cellNotHighlight`,
};

/*********************************************************
 ************* Start Column Helper Functions *************
 *********************************************************/
export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = phoneNumberString.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

function getContactMethod(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.contact.preferredMethod) {
    switch (data.contact.preferredMethod) {
      case ContactMethod.EMAIL:
        return data.contact.email || "";
      case ContactMethod.PHONE:
        return formatPhoneNumber(data.contact.phone) || "";
      case ContactMethod.SMS:
        return formatPhoneNumber(data.contact.phone) || "";
      default:
        return "";
    }
  } else {
    return formatPhoneNumber(data.contact.phone) || data.contact.email || "";
  }
}

function getPetsResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (
    data.homeEnvironment.DOGS === true ||
    data.homeEnvironment.CATS === true ||
    data.homeEnvironment.BIRDS === true ||
    data.homeEnvironment.OTHER_PETS === true
  ) {
    return "Yes";
  }

  if (
    data.homeEnvironment.DOGS === false &&
    data.homeEnvironment.CATS === false &&
    data.homeEnvironment.BIRDS === false &&
    data.homeEnvironment.OTHER_PETS === false
  ) {
    return "No";
  }

  return "";
}

function getSmokingResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.homeEnvironment.SMOKING_HOME === true) {
    return "Yes";
  }

  if (data.homeEnvironment.SMOKING_HOME === false) {
    return "No";
  }

  return "";
}

export function getUpdatedDate(
  data: ConsumerApplication_DTO_Response_Listing | ConsumerApplication_DTO_Response_Details,
): Date {
  return data.updatedAt ? new Date(data.updatedAt) : new Date();
}

export function getCreatedDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return new Date(data.createdAt);
}

export function getDobDate(data: ConsumerApplication_DTO_Response_Listing): Date {
  return data.careRecipient.dateOfBirth ? new Date(data.careRecipient.dateOfBirth) : new Date(1800, 0, 1);
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getGender(data: ConsumerApplication_DTO_Response_Listing): string {
  switch (data.careRecipient.gender) {
    case ConsumerApplicationGender.NOT_DISCLOSED:
      return "Not Disclosed";
    case ConsumerApplicationGender.DIFFERENT_IDENTITY:
      return "Different Identity";
    case ConsumerApplicationGender.FEMALE:
      return "Female";
    case ConsumerApplicationGender.MALE:
      return "Male";
    default:
      return "Not Disclosed";
  }
}

export function getConsumerNotesText(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.consumerNotes) {
    return `Notes about ${
      data.careRecipient?.firstName ? data.careRecipient.firstName : "care recipient"
    }: ${data.consumerNotes}`;
  }
  return "";
}

export function getConsumerNotes(data: ConsumerApplication_DTO_Response_Listing): ReactElement | string {
  if (data.consumerNotes) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Tooltip
          title={`Notes about ${data.careRecipient.firstName ? data.careRecipient.firstName : "care recipient"}: ${data.consumerNotes}`}
          data-testid="consumer-notes-icon"
        >
          <IconButton size="large">
            <DescriptionRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
  return "";
}

export function getAgencyNotesText(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.agencyNotes) {
    return `Agency Notes: ${data.agencyNotes}`;
  }
  return "";
}

export function getAgencyNotes(data: ConsumerApplication_DTO_Response_Listing): ReactElement | string {
  if (data.agencyNotes) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Tooltip title={data.agencyNotes} data-testid="agency-notes-icon">
          <IconButton size="large">
            <DescriptionRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
  return "";
}

export function getDeclinedNotes(data: ConsumerApplication_DTO_Response_Listing): ReactElement | string {
  if (data.declinedNotes) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Tooltip
          title={
            `Declined status notes for ${
              data.careRecipient.firstName ? data.careRecipient.firstName : "care recipient"
            }` + `: ${data.declinedNotes}`
          }
          data-testid="declined-notes-icon"
        >
          <IconButton size="large">
            <DescriptionRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
  return "";
}

function renderAssignedAdmin(data?: User_DTO_Response_NameAndIdOnly): string {
  if (data) {
    return `${data.firstName} ${data.lastName}`;
  }

  return "";
}

function getZipCode(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.zipCode : "";
}

function getCity(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.city : "";
}

function getLanguages(data: ConsumerApplication_DTO_Response_Listing): string {
  const languagesArray = data.languages
    .map((language) => _.startCase(_.lowerCase(`${language.toString().replace(/_/g, " ")}`)))
    .sort();
  return languagesArray.toString().replace(/,/g, ", ");
}

/**
 * Agency Admin Assignment Dropdown Filter
 * Story: https://www.pivotaltracker.com/story/show/178506636
 */
const client = new AxiosPPHCAgencyDashboardConsumersControllerClient();
const useAssignmentFilter = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [options, setOptions] = useState([
    { label: "All", value: "ALL" },
    { label: "Unassigned", value: "UNASSIGNED" },
  ]);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setLoading(true);
        const res = await client.listAgencyAdmins();
        const adminOptions = res.data.map((admin) => ({
          label: `${admin.firstName} ${admin.lastName}`,
          value: admin.id,
        }));
        const combinedOptions = [...options, ...adminOptions];
        setOptions(combinedOptions);
      } catch (e) {
        setError("Server Error: Unable to retrieve agency admins");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    options,
    isLoading,
    error,
  };
};

/*********************************************************
 ******************* Page Definition *********************
 *********************************************************/
export const ConsumersList: React.FC = () => {
  const { ClientsideDatatable, isFetching, addOrUpdateData } = useConsumersDatatable();
  const { options } = useAssignmentFilter();
  const { showSnackbar } = useNotification();
  const [currentEditingConsumerApp, setCurrentEditingConsumerApp] = useState<
    ConsumerApplication_DTO_Response_Listing | undefined
  >();
  const [selectedAdmin, setSelectedAdmin] = useState<SelectedAdminAssignment | undefined>();
  const [showDeclinedModal, setDeclinedModal] = useState<boolean>(false);
  const [dataIdForStatusChange, setDataIdForStatusChange] = useState<string>("");

  function getHighlightReasonArray(data: ConsumerApplication_DTO_Response_Listing): HighlightReason[] {
    const highlightReasonArray = [];
    if (!data.assignedAgencyAdmin) {
      highlightReasonArray.push(HighlightReason.UNASSIGNED);
    }
    if (data.status === ConsumerApplicationStatus.NEW_LEAD) {
      highlightReasonArray.push(HighlightReason.NEW);
    }
    return highlightReasonArray;
  }

  async function handleInlineStatusChange(
    updatedStatus: ConsumerApplicationStatus,
    data: ConsumerApplication_DTO_Response_Listing,
  ) {
    if (updatedStatus === ConsumerApplicationStatus.DECLINED) {
      // Leave it to the Declined Modal to handle submit for a declined status
      setDeclinedModal(true);
      setDataIdForStatusChange(data.id);
      return;
    }

    try {
      const response = await updateConsumerStatusOnly(data.id, { status: updatedStatus });

      const newId = response.data.id;
      const newEntry = response.data;
      addOrUpdateData(newId, () => {
        return {
          ...newEntry,
          updatedAt: newEntry.updatedAt,
          highlightReasons: getHighlightReasonArray(response.data),
        };
      });
    } catch (e) {
      showSnackbar("Error saving status update. Please try again or refresh browser.", "error");
    }
  }

  /*********************************************************
   ******************* Column Definitions ******************
   *********************************************************/

  const getAssignedToOptions = (data: ConsumerApplication_DTO_Response_Listing): SelectOption<string>[] => {
    // start array with currently assigned admin
    const optionsArr = data.assignedAgencyAdmin
      ? [
          {
            label: renderAssignedAdmin(data.assignedAgencyAdmin),
            value: renderAssignedAdmin(data.assignedAgencyAdmin),
          },
        ]
      : [];

    const availableOptions = options.filter((option) =>
      data.assignedAgencyAdmin
        ? option.value !== "ALL" && option.value !== data.assignedAgencyAdmin?.id
        : option.value !== "ALL" && option.value !== "UNASSIGNED",
    );

    availableOptions.map((option) => {
      optionsArr.push({
        label: option.label,
        value: option.value,
      });
    });

    return optionsArr;
  };

  const getStatusOptions = (data: ConsumerApplication_DTO_Response_Listing): SelectOption<string>[] => {
    // start array with current status
    const optionsArr = [
      {
        label: renderStatusString(data.status),
        value: renderStatusString(data.status),
      },
    ];

    const availableStatuses = Object.values(ConsumerApplicationStatus).filter((status) => status !== data.status);

    availableStatuses.map((option) => {
      optionsArr.push({
        label: renderStatusString(option),
        value: option,
      });
    });

    return optionsArr;
  };

  const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<ConsumerApplication_DTO_Response_Listing> } = {
    readable_id: {
      title: "ID",
      render: (data) => data.readableId,
      customFilterAndSearch: (term, data) => {
        return data.readableId?.indexOf(term) !== -1;
      },
      customSort: (data1, data2) => {
        if (data1.readableId == null && data2.readableId == null) return 0;
        if (data1.readableId == null) return 1;
        if (data2.readableId == null) return -1;
        return data1.readableId.localeCompare(data2.readableId);
      },
      hidden: true,
    },
    county: {
      title: "County",
      render: (data) => getCounty(data),
      customFilterAndSearch: (term, data) => {
        return getCounty(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getCounty(data1).toLowerCase().localeCompare(getCounty(data2).toLowerCase());
      },
      hidden: true,
    },
    first_name: {
      title: "First Name",
      render: (data) => {
        return (
          <>
            <Body>
              <InternalLink
                to={
                  data.id! &&
                  generatePath(DASHBOARD_ROUTES.consumer_details, {
                    id: data.id,
                  })
                }
              >
                {data.careRecipient.firstName ? data.careRecipient.firstName : data.contact.firstName}
              </InternalLink>
            </Body>
            {!data.careRecipient.firstName && <Body>(representative)</Body>}
          </>
        );
      },
      customFilterAndSearch: (term, data) => {
        return getCareRecipientOrContactFirstName(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        const name1 = getCareRecipientOrContactFirstName(data1);
        const name2 = getCareRecipientOrContactFirstName(data2);
        return name1.toLowerCase().localeCompare(name2.toLowerCase());
      },
    },
    last_name: {
      title: "Last Name",
      render: (data) => {
        return <Body>{getCareRecipientOrContactLastName(data)}</Body>;
      },
      customFilterAndSearch: (term, data) => {
        return getCareRecipientOrContactLastName(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        const name1 = getCareRecipientOrContactLastName(data1);
        const name2 = getCareRecipientOrContactLastName(data2);
        return name1.toLowerCase().localeCompare(name2.toLowerCase());
      },
      hidden: true,
    },
    contact_method: {
      title: "Contact Method",
      render: getContactMethod,
      customFilterAndSearch: (term, data) => {
        const cmStr = getContactMethod(data);
        if (term.match(/^\d+$/)) {
          return cmStr.replace(/\D|\s/g, "").indexOf(term) !== -1;
        }
        return cmStr.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) =>
        getContactMethod(data1).toLowerCase().localeCompare(getContactMethod(data2).toLowerCase()),
    },
    email: {
      title: "Email",
      render: (data) => data.contact.email,
      customFilterAndSearch: (term, data) =>
        data.contact.email.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1,
      customSort: (data1, data2) => data1.contact.email.toLowerCase().localeCompare(data2.contact.email.toLowerCase()),
      hidden: true,
    },
    phone: {
      title: "Phone",
      render: (data) => (formatPhoneNumber(data.contact.phone) ? formatPhoneNumber(data.contact.phone) : ""),
      customFilterAndSearch: (term, data) => {
        const cmStr = getContactMethod(data);
        if (term.match(/^\d+$/)) {
          return cmStr.replace(/\D|\s/g, "").indexOf(term) !== -1;
        }
        return cmStr.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return data1.contact.phone.toLowerCase().localeCompare(data2.contact.phone.toLowerCase());
      },
      hidden: true,
    },
    address: {
      title: "Address",
      field: "careRecipient.address",
      hidden: true,
    },
    city: {
      title: "City",
      render: (data) => getCity(data),
      customFilterAndSearch: (term, data) => {
        return getCity(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getCity(data1).toLowerCase().localeCompare(getCity(data2).toLowerCase());
      },
      hidden: true,
    },
    zip_code: {
      title: "ZIP code",
      render: (data) => getZipCode(data),
      customFilterAndSearch: (term, data) => {
        return getZipCode(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getZipCode(data1).toLowerCase().localeCompare(getZipCode(data2).toLowerCase());
      },
    },
    assignedTo: {
      title: "Assigned to",
      customFilterAndSearch: (term, data) =>
        `${data.assignedAgencyAdmin?.firstName} ${data.assignedAgencyAdmin?.lastName}`
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
      render: (data) => {
        return (
          <Select
            variant="standard"
            value={renderAssignedAdmin(data.assignedAgencyAdmin)}
            selectOptions={getAssignedToOptions(data)}
            className={
              isAssignedToHighlighted(data.highlightReasons!) ? classes.cellHighlight : classes.cellNotHighlight
            }
            data-testid="assigned-to-select"
            placeholderText="Select"
            onChange={(props) => {
              setCurrentEditingConsumerApp(data);
              setSelectedAdmin({
                id: props.target.value as string,
                name: options.filter((option) => option.value === props.target.value)[0].label,
              });
            }}
          />
        );
      },
    },
    status: {
      ...statusColumn(),
      render: (data) => {
        return (
          <Select
            variant="standard"
            className={isStatusHighlighted(data.highlightReasons) ? classes.cellHighlight : classes.cellNotHighlight}
            selectOptions={getStatusOptions(data)}
            value={renderStatusString(data.status)}
            onChange={(props) => {
              handleInlineStatusChange(props.target.value as ConsumerApplicationStatus, data);
            }}
          />
        );
      },
    },
    hours: {
      title: "Req'd Hrs/Wk",
      field: "hours",
      hidden: true,
    },
    consumerNotes: {
      title: "Care Needs Notes",
      render: getConsumerNotes,
      hiddenByColumnsButton: false,
      customFilterAndSearch: (term, data) => data.consumerNotes.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    agencyNotes: {
      title: "Agency Notes",
      render: getAgencyNotes,
      hiddenByColumnsButton: false,
      customFilterAndSearch: (term, data) => data.agencyNotes.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    declinedReason: {
      title: "Reason for Decline",
      render: (data) => getConsumerAppDeclinedReasonLabel(data.declinedReason),
      hidden: true,
      customFilterAndSearch: (term, data) => data.declinedReason?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    declinedNotes: {
      title: "Declined Status Notes",
      render: getDeclinedNotes,
      hidden: true,
      customFilterAndSearch: (term, data) => data.declinedNotes?.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    },
    pets: {
      title: "Pets",
      render: getPetsResponse,
      customFilterAndSearch: (term, data) => getPetsResponse(data).toLowerCase().indexOf(term.toLowerCase()) !== -1,
      hidden: true,
    },
    smoking: {
      title: "Smoking",
      render: getSmokingResponse,
      customFilterAndSearch: (term, data) => getSmokingResponse(data).toLowerCase().indexOf(term.toLowerCase()) !== -1,
      hidden: true,
    },
    languages: {
      title: "Languages",
      render: (data) => getLanguages(data),
      customFilterAndSearch: (term, data) => {
        return getLanguages(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getLanguages(data1).toLowerCase().localeCompare(getLanguages(data2).toLowerCase());
      },
      hidden: true,
    },
    gender: {
      title: "Gender",
      render: (data) => getGender(data),
      customFilterAndSearch: (term, data) => {
        return getGender(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (data1, data2) => {
        return getGender(data1).toLowerCase().localeCompare(getGender(data2).toLowerCase());
      },
      hidden: true,
    },
    dob: {
      title: "DOB",
      render: (data) => (getDobDate(data).getFullYear() === 1800 ? "" : formatDate(getDobDate(data))),
      customFilterAndSearch: (term, data) => formatDate(getDobDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getDobDate(data1).getTime() - getDobDate(data2).getTime(),
      hidden: true,
    },
    updated: {
      title: "Last Updated",
      render: (data) => formatDate(getUpdatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getUpdatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getUpdatedDate(data1).getTime() - getUpdatedDate(data2).getTime(),
      defaultSort: "desc",
    },
    created: {
      title: "Create Date",
      render: (data) => formatDate(getCreatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getCreatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getCreatedDate(data1).getTime() - getCreatedDate(data2).getTime(),
      defaultSort: "desc",
    },
  };

  function isStatusHighlighted(highlightReasons?: HighlightReason[]): boolean {
    if (highlightReasons && highlightReasons.length > 0) {
      if (highlightReasons.includes(HighlightReason.NEW) || highlightReasons.includes(HighlightReason.STALLED)) {
        return true;
      }
    }
    return false;
  }

  function isAssignedToHighlighted(highlightReasons?: HighlightReason[]): boolean {
    if (highlightReasons && highlightReasons.length > 0) {
      if (highlightReasons.includes(HighlightReason.UNASSIGNED)) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Helmet>
        <title>Carina | Agency Dashboard Clients Tab</title>
      </Helmet>

      <Dialog open={showDeclinedModal}>
        <Formik
          initialValues={{
            declinedReason: null,
            declinedNotes: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);

              const response = await updateConsumerStatusOnly(dataIdForStatusChange, {
                status: ConsumerApplicationStatus.DECLINED,
                // @ts-ignore The validation schema validates this
                declinedReason: values.declinedReason,
                declinedNotes:
                  values.declinedReason === ConsumerApplicationDeclinedReason.OTHER ? values.declinedNotes : undefined,
              });

              setDeclinedModal(false);
              showSnackbar("Details have been successfully saved.", "success");

              // Update the table
              const newId = response.data.id;
              const newEntry = response.data;
              addOrUpdateData(newId, () => {
                return {
                  ...newEntry,
                  updatedAt: newEntry.updatedAt,
                  highlightReasons: getHighlightReasonArray(response.data),
                };
              });
            } catch {
              showSnackbar("Error saving status update. Please try again or refresh browser.", "error");
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={object({
            declinedReason: string().nullable().required(`Please choose a reason for why the care recipient declined`),
            declinedNotes: string()
              .nullable()
              .when("declinedReason", {
                is: ConsumerApplicationDeclinedReason.OTHER,
                then: string()
                  .max(DECLINED_NOTES_MAX, "Exceeded maximum character limit for notes")
                  .required(`Please tell us why the care recipient declined`),
              }),
          })}
        >
          {({ values, isSubmitting }) => (
            <Form localizationReady={true}>
              <DialogTitle>
                <PanelTitle>Please explain why the client was declined</PanelTitle>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormSelectField
                      name="declinedReason"
                      label="Reason for Decline*"
                      selectOptions={ConsumerApplicationDeclinedReasonOptions}
                    />
                  </Grid>
                  {values.declinedReason === ConsumerApplicationDeclinedReason.OTHER && (
                    <Grid item xs={12}>
                      <FormTextField
                        name="declinedNotes"
                        label="Declined Status Notes"
                        multiline
                        minRows={2}
                        helperText={""}
                        required={true}
                        maximumlength={1000}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="text" type="button" onClick={() => setDeclinedModal(false)} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <AdminAssignmentDialog
        consumerApp={currentEditingConsumerApp}
        newAdmin={selectedAdmin}
        onSubmit={() => {
          setCurrentEditingConsumerApp(undefined);
        }}
      />
      <ClientsideDatatable
        columnDefinitions={COLUMN_DEFINITIONS}
        persistKeyPrefix="consumers-list"
        title="All Clients"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching client applications..." : "No client records to display",
          },
        }}
      />
    </>
  );
};
