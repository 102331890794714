import { Grid, SvgIconProps } from "@mui/material";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";

interface Props {
  icon: React.ComponentType<SvgIconProps>;
  iconColor: string;
  title: string;
}

export const IconAndBodyEmphasis: React.FC<Props> = ({ icon: IconComponent, title, iconColor }) => {
  return (
    <>
      <Grid container justifyContent="center" sx={{ marginBottom: "20px" }}>
        <Grid item>
          <IconComponent
            sx={{
              marginRight: 1,
              "& path": {
                fill: iconColor,
              },
            }}
          />
        </Grid>
        <Grid item>
          <BodyEmphasis>{title}</BodyEmphasis>
        </Grid>
      </Grid>
    </>
  );
};
