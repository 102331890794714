import { generatePath } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  CaseManagerRoutes,
  Medicaid_Case_Manager_Routes,
} from "src/pages/medicaidAccount/consumerAccount/CaseManagerAccount/CaseManagerRouter";
import {
  ConsumerRoutes,
  Medicaid_Consumer_Dashboard_Routes,
} from "src/pages/medicaidAccount/consumerAccount/ConsumerRouter";
import {
  CoordinatorRoutes,
  Medicaid_Coordinator_Dashboard_Routes,
} from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import {
  Medicaid_Proxy_Provider_Routes,
  ProxyProviderRoutes,
} from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";

// TODO: https://github.com/CarinaWeb/CarinaCore/issues/1008
//  Refactor getMedicaidRouteByUserType, getRoutePrefix, getRepostRoute and/or routers to simplify overall routing logic
export function getMedicaidRouteByUserType(route: string, isUserTypeFn: (type: UserType) => boolean) {
  if (isUserTypeFn(UserType.Consumer)) {
    return Medicaid_Consumer_Dashboard_Routes[route as keyof ConsumerRoutes];
  }
  if (isUserTypeFn(UserType.Provider)) {
    return Medicaid_Provider_Dashboard_Routes[route as keyof typeof Medicaid_Provider_Dashboard_Routes];
  }
  if (isUserTypeFn(UserType.MedicaidReferralCoordinator)) {
    // handles edge case where we want to pre verify consumer before getting to job create pg
    return route === "jobCreate"
      ? Medicaid_Coordinator_Dashboard_Routes.preVerifyJobPost
      : Medicaid_Coordinator_Dashboard_Routes[route as keyof CoordinatorRoutes];
  }
  if (isUserTypeFn(UserType.MedicaidCaseManager)) {
    // handles edge case where we want to pre verify consumer before getting to job create pg
    return route === "jobCreate"
      ? Medicaid_Case_Manager_Routes.preVerifyJobPost
      : Medicaid_Case_Manager_Routes[route as keyof CaseManagerRoutes];
  }
  if (isUserTypeFn(UserType.ProxyProvider)) {
    // handles edge case where we want to pre verify consumer before getting to job create pg
    return route === "jobCreate"
      ? Medicaid_Proxy_Provider_Routes.preVerifyJobPost
      : Medicaid_Proxy_Provider_Routes[route as keyof ProxyProviderRoutes];
  }
  return MEDICAID_ACCOUNT_ROUTES.homepage;
}

export function getRoutePrefix(isUserTypeFn: (type: UserType) => boolean) {
  if (isUserTypeFn(UserType.MedicaidReferralCoordinator)) {
    return Medicaid_Coordinator_Dashboard_Routes;
  }
  if (isUserTypeFn(UserType.MedicaidCaseManager)) {
    return Medicaid_Case_Manager_Routes;
  }
  if (isUserTypeFn(UserType.ProxyProvider)) {
    return Medicaid_Proxy_Provider_Routes;
  }
  return Medicaid_Consumer_Dashboard_Routes;
}

export function getRepostRoute(id: string) {
  const { isUserType } = useUserSession();
  if (isUserType(UserType.MedicaidReferralCoordinator)) {
    return generatePath(Medicaid_Coordinator_Dashboard_Routes.preVerifyJobRepost, { id });
  }
  if (isUserType(UserType.MedicaidCaseManager)) {
    return generatePath(Medicaid_Case_Manager_Routes.preVerifyJobRepost, { id });
  }
  if (isUserType(UserType.ProxyProvider)) {
    return generatePath(Medicaid_Proxy_Provider_Routes.preVerifyJobRepost, { id });
  }
  if (isUserType(UserType.Consumer)) {
    return generatePath(Medicaid_Consumer_Dashboard_Routes.jobRepost, { id });
  }
  return MEDICAID_ACCOUNT_ROUTES.homepage;
}
