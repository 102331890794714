import { FormGroup, Link as MuiLink, RadioGroup } from "@mui/material";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { FunderState, UserType, useUserSession } from "src/context/UserSessionContext";
import { Permission, ProviderProfileTagEnum } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonNavigation, nsMedicaidSearch, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import LoadingFiltersSkeleton from "src/pages/medicaidAccount/searchComponents/LoadingFiltersSkeleton";
import SimpleChipsMultiSelect, { ICheckbox } from "src/pages/medicaidAccount/searchComponents/SimpleChipsMultiSelect";
import { providerSearchAndFilterClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { AgeRangeOptions, LegacyLanguage, TypeOfSupportOptions } from "src/pages/onboarding/step_pages/MedicaidProfile";
import { AccordionList } from "src/reusable_view_elements/Accordion";
import { ControlLabel, Radio } from "src/reusable_view_elements/form_fields";
import { Checkbox, CheckboxControlLabel } from "src/reusable_view_elements/form_fields/CheckboxField";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body } from "src/reusable_view_elements/Typography";
import { getNotificationFrequencyFriendlyName } from "src/utilities/GeneralUtilities";

const AdvancedProviderFilters = () => {
  const {
    providerSearchDTO,
    setProviderSearchDTO,
    setIsProvFilterDirty,
    providerFltrWkNotifEnabled,
    enableProviderFltrWkNotif,
    providerFilterNotificationFrequency,
    loadingProviderFilterNotification,
    setProvidersPage,
    setClearProviderFilters,
  } = useMedicaidContext();
  const { isUserType, hasPermission, isFunderState, isAssistiveRoleView } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidSearch, nsCommonFormsBtns, nsCommonNavigation, nsMedicaidTagsEnums]);
  const { showSnackbar } = useNotification();

  const getHoursRange = (): string => {
    const { minHours } = providerSearchDTO;
    switch (minHours) {
      case 120:
        return "Greater than 120";
      case 80:
        return "80 - 119";
      case 40:
        return "40 - 79";
      case 0:
        return "Less than 40";
      default:
        return "";
    }
  };

  const getDistanceRange = (): number => {
    const { distanceMinutes } = providerSearchDTO;
    return distanceMinutes;
  };

  const ProviderTrainingOptions = isFunderState(FunderState.Washington)
    ? [
        ProviderProfileTagEnum.EXEMPT,
        ProviderProfileTagEnum.HCA_AHCAS,
        ProviderProfileTagEnum.IN_PROCESS,
        ProviderProfileTagEnum.RESPITE_LIMITED,
        ProviderProfileTagEnum.OTHER_FILTER,
      ]
    : isFunderState(FunderState.Oregon)
      ? [ProviderProfileTagEnum.HCW, ProviderProfileTagEnum.PCA, ProviderProfileTagEnum.PSW]
      : [];

  const handleAvailSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const av = e.target.name as ProviderProfileTagEnum;
    const newAvailability = providerSearchDTO.availability.filter((tag) => tag !== av);
    if (e.target.checked) newAvailability.push(av);
    const newSearchDTO = { ...providerSearchDTO, availability: newAvailability };
    setProviderSearchDTO(newSearchDTO);
    setIsProvFilterDirty(true);
    setProvidersPage(0);
  };

  const handleHoursSelection = (e: ChangeEvent<HTMLInputElement>) => {
    let maxHrs;
    let minHrs;
    switch (e.target.value) {
      case "Greater than 120":
        maxHrs = Infinity;
        minHrs = 120;
        break;
      case "80 - 119":
        maxHrs = 119;
        minHrs = 80;
        break;
      case "40 - 79":
        maxHrs = 79;
        minHrs = 40;
        break;
      default:
        maxHrs = 39;
        minHrs = 0;
    }
    setProviderSearchDTO({ ...providerSearchDTO, minHours: minHrs, maxHours: maxHrs });
    setIsProvFilterDirty(true);
    setProvidersPage(0);
  };

  const handleDistanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProviderSearchDTO({ ...providerSearchDTO, distanceMinutes: parseInt(e.target.value) });
    setIsProvFilterDirty(true);
    setProvidersPage(0);
  };

  const ageRangeKeys = AgeRangeOptions.map((option) => option.value);
  const typeOfSupportKeys = TypeOfSupportOptions.map((option) => option.value);
  // TODO: Map these keys like AgeRangeOptions: https://github.com/CarinaWeb/CarinaCore/issues/1005
  const frequencyKeys = ["ONGOING", "ONETIME", "ONCALL"];
  const providerGenderKeys = ["MALE", "FEMALE", "NONBINARY"];
  const needsKeys = [
    "PERSONAL_CARE",
    "NURSE_DELEGATION",
    "TRANSFER",
    "TRANSFER_ASSISTED",
    "TRANSPORT",
    "TRANSPORT_PROVIDER",
    "BEHAVIOR_CHALLENGE",
    "SMOKING",
    "PETS",
    "LGBTQIA_HOUSEHOLDS",
    "NON_SMOKING_PROVIDER",
    "FRAG_FREE",
  ];

  const keysGroups: { [k: string]: string[] } = {
    providerTrainingCategory: ProviderTrainingOptions,
    ageRangeCategory: ageRangeKeys,
    typeOfSupportCategory: typeOfSupportKeys,
    frequency: frequencyKeys,
    providerGender: providerGenderKeys,
    needs: needsKeys,
  };

  const arePetsIncluded = (tags: string[]): boolean => {
    return tags.some(
      (tag) => tag.includes("DOG") || tag.includes("CAT") || tag.includes("BIRD") || tag.includes("OTHER_PET"),
    );
  };

  const getFiltersObjectForGroup = (filterGroupName: string) => {
    const allTags: string[] = [];
    providerSearchDTO.tagGroups.forEach((tagGroup) => {
      if (tagGroup) {
        tagGroup.forEach((tag) => allTags.push(tag));
      }
    });
    const entriesArray = keysGroups[filterGroupName].map((key) => {
      if (key === "PETS") return [key, arePetsIncluded(allTags)];
      return [key, allTags.includes(key)];
    });
    return Object.fromEntries(entriesArray);
  };

  const isLanguage = (tag: ProviderProfileTagEnum | string) =>
    LegacyLanguage.map((ll: any) => ll.value).includes(tag as unknown as ProviderProfileTagEnum);

  const getIncludedLanguages = (): ProviderProfileTagEnum[] => {
    const isLanguageGroup = (gr: ProviderProfileTagEnum[]) => gr.some(isLanguage);
    const currentLangGroup = providerSearchDTO.tagGroups.filter(isLanguageGroup)[0];
    if (!currentLangGroup) return [];
    return currentLangGroup;
  };

  const handleGenderSelection = (e: any) => {
    const tagName = e.target.name;

    function tagArrBelongsToGenderGroup(tagArr: ProviderProfileTagEnum[]): boolean {
      return tagArr.some((tag) => keysGroups["providerGender"].includes(tag));
    }

    // Check for existing filter tagGroups with a gender tag
    const tagGroupWithProviderGenderTags =
      providerSearchDTO.tagGroups.length > 0 ? providerSearchDTO.tagGroups.filter(tagArrBelongsToGenderGroup) : [];

    // If a group exists, remove the interacted-with tag and the no_gender_pref tag
    const targetGroup =
      tagGroupWithProviderGenderTags.length > 0
        ? tagGroupWithProviderGenderTags[0].filter(
            (tag) => (tag as string) !== tagName && (tag as string) !== "NO_GENDER_PREFERENCE",
          )
        : [];

    // We just removed the tags which means we're done for unchecked scenarios, but if it was checked we need to add it
    if (e.target.checked) targetGroup.push(tagName as ProviderProfileTagEnum);

    // If there is at least one other providerGender tag, we want to also add no_gender_pref
    if (targetGroup.length > 0 && providerGenderKeys.includes(tagName)) {
      targetGroup.push("NO_GENDER_PREFERENCE" as ProviderProfileTagEnum);
    }

    // Remove the tagGroups that have providerGender tag
    const newTagGroups = providerSearchDTO.tagGroups.filter((group) => !tagArrBelongsToGenderGroup(group));

    // If there are still gender tags in targetGroup, add group back to filter
    if (targetGroup.length > 0) newTagGroups.push(targetGroup);
    return newTagGroups;
  };

  const handleTagFilterSelection = (e: any, filterGroupName: string) => {
    const tagName = e.target.name;
    let newTagGroups: ProviderProfileTagEnum[][];

    // Needs-related keys are handled differently than other sections
    if (needsKeys.includes(tagName)) {
      let selection;
      if (tagName === "PETS") {
        newTagGroups = providerSearchDTO.tagGroups.filter((group) => !arePetsIncluded(group as string[]));
        selection = [
          ProviderProfileTagEnum.DOG,
          ProviderProfileTagEnum.CAT,
          ProviderProfileTagEnum.BIRD,
          ProviderProfileTagEnum.OTHER_PET,
        ];
      } else {
        newTagGroups = providerSearchDTO.tagGroups.filter((group) => !group.includes(tagName));
        selection = [tagName];
      }
      if (e.target.checked) {
        newTagGroups.push(selection);
      }
    } else if (filterGroupName === "providerGender") {
      newTagGroups = handleGenderSelection(e);
    } else {
      const tagBelongsToGroup = (tag: ProviderProfileTagEnum): boolean => keysGroups[filterGroupName].includes(tag);
      const tagArrBelongsToGroup = (tagArr: ProviderProfileTagEnum[]): boolean => tagArr.some(tagBelongsToGroup);

      // Using helper fns above, get the existing tagGroup with tags in same filter group
      const tagGroupWithTagInSameFilterGroup =
        providerSearchDTO.tagGroups.length > 0 ? providerSearchDTO.tagGroups.filter(tagArrBelongsToGroup) : [];

      // If any exist, remove the tag itself
      const targetGroup =
        tagGroupWithTagInSameFilterGroup.length > 0
          ? tagGroupWithTagInSameFilterGroup[0].filter((tag) => (tag as string) !== tagName)
          : [];

      // If the filter was checked then we need to add tag back
      if (e.target.checked) targetGroup.push(tagName as ProviderProfileTagEnum);

      // Remove tagGroup with any tags in same filter group
      newTagGroups = providerSearchDTO.tagGroups.filter((group) => !tagArrBelongsToGroup(group));

      // Add target group back to whole tagGroups
      if (targetGroup.length > 0) newTagGroups.push(targetGroup);
    }
    const newSearchDTO = { ...providerSearchDTO, tagGroups: newTagGroups };
    setProviderSearchDTO(newSearchDTO);
    setIsProvFilterDirty(true);
    setProvidersPage(0);
  };

  const isChecked = (group: string, tag: string): boolean => {
    if (group === "availability") return providerSearchDTO.availability.includes(tag as ProviderProfileTagEnum);
    const filterObject = getFiltersObjectForGroup(group);
    return filterObject ? filterObject[tag] : false;
  };

  const handleNotifCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    const originalNotificationBoxEnabled = providerFltrWkNotifEnabled;
    const persistFilter = e.target.checked;
    enableProviderFltrWkNotif(persistFilter);
    if (persistFilter) {
      Promise.allSettled([
        providerSearchAndFilterClient.persistFilter(providerSearchDTO),
        providerSearchAndFilterClient.updateEmailFilterNotification({
          frequency: providerFilterNotificationFrequency,
          enable: true,
        }),
      ]).catch(() => enableProviderFltrWkNotif(originalNotificationBoxEnabled));
    } else {
      Promise.allSettled([
        providerSearchAndFilterClient.clearFilter(),
        providerSearchAndFilterClient.updateEmailFilterNotification({
          frequency: providerFilterNotificationFrequency,
          enable: false,
        }),
      ]).catch(() => enableProviderFltrWkNotif(originalNotificationBoxEnabled));
    }
  };

  const handleLanguageSelection = (selectedLanguages: ProviderProfileTagEnum[]) => {
    const isNotLanguageGroup = (gr: ProviderProfileTagEnum[]) => !gr.some(isLanguage);
    const currentNoLangGroups = providerSearchDTO.tagGroups.filter(isNotLanguageGroup);
    const newTagGroups = [...currentNoLangGroups, selectedLanguages];
    const newSearchDTO = { ...providerSearchDTO, tagGroups: newTagGroups };
    setProviderSearchDTO(newSearchDTO);
    setIsProvFilterDirty(true);
    setProvidersPage(0);
  };

  const handleApplyJobSettingsClick = () => {
    providerSearchAndFilterClient.getJobPosterPreferences().then((res) => {
      const preferences = res.data;
      if (preferences) {
        let minHr;
        let maxHr;
        if (preferences.hours >= 120) {
          maxHr = Infinity;
          minHr = 120;
        } else if (preferences.hours >= 80) {
          maxHr = 119;
          minHr = 80;
        } else if (preferences.hours >= 40) {
          maxHr = 79;
          minHr = 40;
        } else {
          maxHr = 39;
          minHr = 0;
        }
        const prefTags = preferences.jobTags;
        const langs = prefTags.filter(isLanguage) as ProviderProfileTagEnum[];
        let newTagGroups = Object.keys(keysGroups).map((groupKey) => {
          return Object.values(keysGroups[groupKey])
            .filter((tag) => prefTags.includes(tag))
            .map((tag) => tag as ProviderProfileTagEnum);
        });
        newTagGroups = [...newTagGroups, langs];
        const newSearchDTO = { ...providerSearchDTO, tagGroups: newTagGroups, minHours: minHr, maxHours: maxHr };
        setProviderSearchDTO(newSearchDTO);
        setIsProvFilterDirty(true);
        setProvidersPage(0);
      } else {
        showSnackbar("No Job Post detected, create a Job to use this feature.", "error");
      }
    });
  };

  if (!ready) {
    return <LoadingFiltersSkeleton />;
  }

  return (
    <>
      {isUserType(UserType.Consumer) && (
        <MuiLink component="button" onClick={handleApplyJobSettingsClick}>
          <Body paragraph textAlign="left">
            {t("apply_my_profile_pref.label", { ns: nsMedicaidSearch })}
          </Body>
        </MuiLink>
      )}
      <MuiLink component="button" onClick={() => setClearProviderFilters(true)}>
        <Body paragraph>{t("clear_filters.label", { ns: nsMedicaidSearch })}</Body>
      </MuiLink>

      <AccordionList
        titleTypography="BodyEmphasis"
        AccordionDetailsProps={{
          style: {
            paddingBottom: 16,
          },
        }}
        showListItemBadge
        data={[
          {
            title: t("max_travel_time.label", { ns: nsMedicaidSearch }),
            body: (
              <RadioGroup name="distanceMinutes" onChange={handleDistanceChange} value={getDistanceRange()}>
                <ControlLabel
                  value={15}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 15 })}
                />
                <ControlLabel
                  value={30}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 30 })}
                />
                <ControlLabel
                  value={45}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 45 })}
                />
                <ControlLabel
                  value={60}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 60 })}
                />
              </RadioGroup>
            ),
            count: providerSearchDTO.distanceMinutes ? 1 : 0,
          },
          {
            title: t("provider_availability.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={handleAvailSelection}>
                <ICheckbox
                  name="SEEKING"
                  group="availability"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.SEEKING).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="SOON"
                  group="availability"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.SOON).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="NOTSEEKING"
                  group="availability"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.NOTSEEKING).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
              </FormGroup>
            ),
            count: providerSearchDTO.availability.length,
          },
          {
            title: t("monthly_hours.label", { ns: nsMedicaidSearch }),
            body: (
              <RadioGroup onChange={handleHoursSelection} value={getHoursRange()}>
                <ControlLabel
                  value="Less than 40"
                  control={<Radio />}
                  label={t("monthly_hours.less_than_40.label", { ns: nsMedicaidSearch })}
                />
                <ControlLabel
                  value="40 - 79"
                  control={<Radio />}
                  label={t("monthly_hours.40_to_79.label", { ns: nsMedicaidSearch })}
                />
                <ControlLabel
                  value="80 - 119"
                  control={<Radio />}
                  label={t("monthly_hours.80_to_119.label", { ns: nsMedicaidSearch })}
                />
                <ControlLabel
                  value="Greater than 120"
                  control={<Radio />}
                  label={t("monthly_hours.greater_than_120.label", { ns: nsMedicaidSearch })}
                />
              </RadioGroup>
            ),
            count: providerSearchDTO.minHours !== undefined && providerSearchDTO.minHours !== null ? 1 : 0,
          },
          {
            title: t("age.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "ageRangeCategory")}>
                {AgeRangeOptions.map((option) => (
                  <ICheckbox
                    key={option.value}
                    name={option.value}
                    group="ageRangeCategory"
                    checkFn={isChecked}
                    label={t(getLocalizedProviderProfileTag(option.value).key, {
                      ns: nsMedicaidTagsEnums,
                    })}
                  />
                ))}
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("ageRangeCategory")).filter((v) => v).length,
          },
          ...(isAssistiveRoleView()
            ? [
                {
                  title: t("provider_training.label", { ns: nsMedicaidSearch }),
                  body: (
                    <FormGroup onChange={(e) => handleTagFilterSelection(e, "providerTrainingCategory")}>
                      {ProviderTrainingOptions.map((option) => (
                        <ICheckbox
                          key={option}
                          name={option}
                          group="providerTrainingCategory"
                          checkFn={isChecked}
                          label={t(getLocalizedProviderProfileTag(option).key, {
                            ns: nsMedicaidTagsEnums,
                          })}
                        />
                      ))}
                    </FormGroup>
                  ),
                  count: Object.values(getFiltersObjectForGroup("providerTrainingCategory")).filter((v) => v).length,
                },
              ]
            : []),
          {
            title: t("care_support.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "typeOfSupportCategory")}>
                {TypeOfSupportOptions.map((option) => (
                  <ICheckbox
                    key={option.value}
                    name={option.value}
                    group="typeOfSupportCategory"
                    checkFn={isChecked}
                    label={t(getLocalizedProviderProfileTag(option.value).key, { ns: nsMedicaidTagsEnums })}
                  />
                ))}
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("typeOfSupportCategory")).filter((v) => v).length,
          },
          {
            title: t("frequency.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "frequency")}>
                <ICheckbox
                  name="ONGOING"
                  group="frequency"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.ONGOING).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="ONETIME"
                  group="frequency"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.ONETIME).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="ONCALL"
                  group="frequency"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.ONCALL).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("frequency")).filter((v) => v).length,
          },
          {
            title: t("provider_gender.label_v2", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "providerGender")}>
                <ICheckbox
                  name="MALE"
                  group="providerGender"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.MALE).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="FEMALE"
                  group="providerGender"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.FEMALE).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="NONBINARY"
                  group="providerGender"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.NONBINARY).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("providerGender")).filter((v) => v).length,
          },
          {
            title: t("languages.label", { ns: nsMedicaidSearch }),
            body: (
              <SimpleChipsMultiSelect
                name="languages"
                label={t("field.languages.label", { ns: nsCommonFormsBtns })}
                options={LegacyLanguage.map((option) => ({
                  ...option,
                  label: t(getLocalizedProviderProfileTag(option.value).key, { ns: nsMedicaidTagsEnums }),
                }))}
                selected={getIncludedLanguages()}
                selectionHandlerFn={handleLanguageSelection}
              />
            ),
            count: Object.values(getIncludedLanguages()).filter((v) => v).length,
          },
          {
            title: t("i_need.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "needs")}>
                <ICheckbox
                  name="PERSONAL_CARE"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.PERSONAL_CARE).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="NURSE_DELEGATION"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.NURSE_DELEGATION).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="TRANSFER"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.TRANSFER).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="TRANSFER_ASSISTED"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.TRANSFER_ASSISTED).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="TRANSPORT"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.TRANSPORT).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="TRANSPORT_PROVIDER"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.TRANSPORT_PROVIDER).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="BEHAVIOR_CHALLENGE"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.BEHAVIOR_CHALLENGE).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="SMOKING"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.SMOKING).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="PETS"
                  group="needs"
                  checkFn={isChecked}
                  label={t("pet.ok_with_pets", {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="LGBTQIA_HOUSEHOLDS"
                  group="needs"
                  checkFn={isChecked}
                  label={t(`${getLocalizedProviderProfileTag(ProviderProfileTagEnum.LGBTQIA_HOUSEHOLDS).key}.filters`, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="NON_SMOKING_PROVIDER"
                  group="needs"
                  checkFn={isChecked}
                  label={t("non_smoking_provider.i_prefer_non_smoking_provider", {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
                <ICheckbox
                  name="FRAG_FREE"
                  group="needs"
                  checkFn={isChecked}
                  label={t(getLocalizedProviderProfileTag(ProviderProfileTagEnum.FRAG_FREE).key, {
                    ns: nsMedicaidTagsEnums,
                  })}
                />
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("needs")).filter((v) => v).length,
          },
        ]}
      />
      {hasPermission(Permission.SETTINGS_ACCESS_PROVIDER_FILTERS) && (
        <>
          {loadingProviderFilterNotification ? (
            <LoadingCircle />
          ) : (
            <CheckboxControlLabel
              name="email-filter-match"
              control={
                <Checkbox
                  data-testid="filter-persist-checkbox"
                  checked={providerFltrWkNotifEnabled}
                  onChange={handleNotifCheckboxClick}
                />
              }
              label={t("email_me_when_provider_checkbox.label", {
                ns: nsMedicaidSearch,
                frequency: t(`${getNotificationFrequencyFriendlyName(providerFilterNotificationFrequency)}`, {
                  ns: nsMedicaidTagsEnums,
                }).toLowerCase(),
              })}
              style={{ marginBottom: "20px", marginTop: "20px" }}
            />
          )}
          <MuiLink href={MEDICAID_ACCOUNT_ROUTES.accountSettings}>
            <Body paragraph>
              {t("view_settings_to_change_pref.label", {
                ns: nsMedicaidSearch,
                sttngspg_name: t("link.settings", { ns: nsCommonNavigation }),
              })}
            </Body>
          </MuiLink>
        </>
      )}
    </>
  );
};

export default AdvancedProviderFilters;
