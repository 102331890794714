import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import MaterialTable, {
  Column as MColumn,
  MaterialTableProps,
  MTableEditRow,
  MTableToolbar,
  Options as MOptions,
} from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { forwardRef, ReactNode } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CivColors from "src/themes/civilization/CivColors";

const DATA_TABLE_KEY = "dashboard-datatable";
export const COLUMN_ORDER_KEY = `${DATA_TABLE_KEY}-column-order`;
export const PAGE_SIZE_KEY = `${DATA_TABLE_KEY}-page-size`;
export const COLUMN_SORT_KEY = `${DATA_TABLE_KEY}-column-sort`;
export const COLUMN_HIDE_STATE_KEY = `${DATA_TABLE_KEY}-column-hide-state`;
export const CURRENT_PAGE_KEY = `${DATA_TABLE_KEY}-current-page`;
// export const COLUMN_FILTER_KEY = `${DATA_TABLE_KEY}-column-filter`;

interface ColumnSort {
  columnKey: string;
  sortDir: "asc" | "desc";
}

interface ColumnHideState {
  [k: string]: boolean;
}

// interface ColumnFilterValue {
//   [k: string]: string;
// }

export type Column<T extends object> = {
  title: string;
} & Omit<MColumn<T>, "title">;

export type DatatableOptions<T extends object> = MOptions<T>;

export type DatatableProps<D extends object, T extends Column<D>> = {
  columnDefinitions: { [k: string]: T };
  persistKeyPrefix: string;
  options?: DatatableOptions<D>;
  toolbarExtras?: ReactNode;
  editRowTooltip?: string;
  editRowActionFunction?: (event: any, rowData: any) => void;
  highlightRowFn?: (rowData: D) => boolean;
} & Omit<MaterialTableProps<D>, "columns" | "options">;

export const ExportMenuOption = {
  csv: (props?: { label?: string; filename?: string }) => ({
    label: props?.label || "Export as CSV",
    // The types are supposed to be cols: MColumn<D>[], data: D[] but I'm unsure how to properly define that
    exportFunc: (cols: any, data: any) => ExportCsv(cols, data, props?.filename || "Carina Exported Csv"),
  }),
};

export function Datatable<D extends object, T extends Column<D>>(props: DatatableProps<D, T>) {
  const {
    title,
    options,
    editRowTooltip,
    editRowActionFunction,
    toolbarExtras,
    columnDefinitions,
    persistKeyPrefix: keyPrefix,
    style,
    localization,
    highlightRowFn,
    ...restDatatableProps
  } = props;

  /*********************************************************
   ***************** Load Column State Fns *****************
   *********************************************************/
  function loadFromStorage<R>(
    key: string,
    validityFn: (v: any) => boolean,
    defaultValue: R,
    useSessionStorage = false,
  ): R {
    const rawStorageVal = useSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (!rawStorageVal) return defaultValue;

    const parsedVal = JSON.parse(rawStorageVal);
    if (!validityFn(parsedVal)) return defaultValue;

    return parsedVal;
  }

  function loadColumnOrder(): string[] {
    const defaults = Object.keys(columnDefinitions);
    return loadFromStorage(
      `${keyPrefix}-${COLUMN_ORDER_KEY}`,
      (parsedVal) => {
        // False means we reset to the defaults
        if (!Array.isArray(parsedVal)) return false;
        // Columns have been added/removed
        if (parsedVal.length !== defaults.length) return false;
        // Clone the array to a new object
        const parsedValClone = parsedVal.concat().sort();
        const defaultsClone = defaults.concat().sort();
        for (let i = 0; i < parsedVal.length; i += 1) {
          // Ensure sorted column keys match between localStorage and code-defined columns
          if (parsedValClone[i] !== defaultsClone[i]) return false;
        }
        return true;
      },
      defaults,
    );
  }

  function loadPageSize(): number {
    return loadFromStorage(`${keyPrefix}-${PAGE_SIZE_KEY}`, Number.isInteger, options?.pageSize || 10);
  }

  function loadColumnSort(): ColumnSort | undefined {
    return loadFromStorage<ColumnSort | undefined>(
      `${keyPrefix}-${COLUMN_SORT_KEY}`,
      (parsedVal) => {
        if (!_.isObject(parsedVal)) return false;
        const parsedSort = parsedVal as ColumnSort;
        // Check that column definitions contain the ColumnSort column key, otherwise use code-defined default sort
        return Object.keys(columnDefinitions).indexOf(parsedSort.columnKey) >= 0;
      },
      undefined,
    );
  }

  function loadColumnHideState(): ColumnHideState {
    return loadFromStorage(`${keyPrefix}-${COLUMN_HIDE_STATE_KEY}`, _.isObject, {});
  }

  function loadCurrentPage(): number {
    return loadFromStorage(`${keyPrefix}-${CURRENT_PAGE_KEY}`, Number.isInteger, 0, true);
  }

  // function loadColumnFilter(): ColumnFilterValue {
  //   return loadFromStorage(`${keyPrefix}-${COLUMN_FILTER_KEY}`, _.isObject, {}, true);
  // }

  /*********************************************************
   **************** Calculate Column State *****************
   *********************************************************/
  const colDefEntries = Object.entries(columnDefinitions);
  const titleToIdMap = Object.fromEntries(colDefEntries.map(([k, v]) => [v.title, k]));
  const columns: Column<D>[] = [];
  const columnOrder = loadColumnOrder();
  const columnSort = loadColumnSort();
  const columnHideState = loadColumnHideState();
  let totalVisibleColumns = 0;
  // let columnFilterValue = loadColumnFilter();
  columnOrder.forEach((k) => {
    let defaultSort = columnSort ? undefined : columnDefinitions[k].defaultSort;
    if (columnSort && columnSort.columnKey === k) {
      defaultSort = columnSort.sortDir;
    }
    const hidden = isColumnHidden(k);
    if (!hidden) totalVisibleColumns += 1;
    // const defaultFilter = columnFilterValue[k] ? columnFilterValue[k] : columnDefinitions[k].defaultFilter;
    columns.push({
      ...columnDefinitions[k],
      defaultSort,
      hidden,
      width: `calc((100% - (0px)) / ${columnOrder.length})`,
      // defaultFilter,
    });
  });

  /*********************************************************
   ***************** Save Column State Fns *****************
   *********************************************************/
  // TODO ESLint: Resolve the rule being ignored here
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function saveToStorage<D>(key: string, data: D, useSessionStorage = false): void {
    if (useSessionStorage) {
      sessionStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  function saveColumnOrder(): void {
    saveToStorage(`${keyPrefix}-${COLUMN_ORDER_KEY}`, columnOrder);
  }

  function savePageSize(pageSize: number): void {
    saveToStorage(`${keyPrefix}-${PAGE_SIZE_KEY}`, pageSize);
  }

  function saveColumnSort(columnKey: string, sortDir: "asc" | "desc"): void {
    saveToStorage(`${keyPrefix}-${COLUMN_SORT_KEY}`, { columnKey, sortDir });
  }

  function saveColumnHideState(): void {
    saveToStorage(`${keyPrefix}-${COLUMN_HIDE_STATE_KEY}`, columnHideState);
  }

  function saveCurrentPage(currPage: number): void {
    saveToStorage(`${keyPrefix}-${CURRENT_PAGE_KEY}`, currPage, true);
  }

  // function saveFilterValue(): void {
  //   saveToStorage(`${keyPrefix}-${COLUMN_FILTER_KEY}`, columnFilterValue, false);
  // }

  function isColumnHidden(key: string): boolean {
    return (_.isBoolean(columnHideState[key]) ? columnHideState[key] : columnDefinitions[key].hidden) || false;
  }

  const defaultOptions: DatatableOptions<D> = {
    showTitle: false,
    headerStyle: {
      fontWeight: "bold",
      fontSize: "1.0rem",
      fontFamily: "Europa-Bold, sans-serif",
    },
    maxColumnSort: 1,
    emptyRowsWhenPaging: false,
    draggable: false,
    filtering: true,
    columnsButton: true, // Show columns button by default
    initialPage: loadCurrentPage(),
    thirdSortClick: false, // If true, then onOrderChange()'s orderedColumnId will always return -1 on third click
    search: true, // Show search field by default
    searchFieldAlignment: "left",
    searchFieldVariant: "standard",
    searchFieldStyle: {
      border: `1px solid ${CivColors.coreDarkNavy}`,
      borderRadius: 0,
      padding: 8,
    },
    pageSize: loadPageSize(),
    pageSizeOptions: [5, 10, 20, 50, 100],
    toolbarButtonAlignment: "left",
    actionsColumnIndex: 100,
    rowStyle: (rowData: any, rowIdx: number) => ({
      // Highlight row if given a reason; otherwise, fallback on alternating background color
      backgroundColor:
        (highlightRowFn && highlightRowFn(rowData) && CivColors.palePeach) ||
        (rowIdx % 2 === 0 && CivColors.paleWhite) ||
        CivColors.white,
    }),
  };

  // Define forwardRefs for icons to avoid linting error re missing display name (this is especially relevant during debugging)
  const SearchIcon = () => {
    return <div />;
  };
  const SearchIconRef = forwardRef(SearchIcon);

  const DeleteIcon = () => {
    return <DeleteOutlineOutlinedIcon />;
  };
  const DeleteIconRef = forwardRef(DeleteIcon);

  /*********************************************************
   ******************** Render Component *******************
   *********************************************************/
  return (
    <>
      <MaterialTable
        columns={columns}
        options={{
          ...defaultOptions,
          ...options, // options passed in via props to override/add to these defaults
        }}
        style={{
          marginTop: "15px",
          fontSize: "1.0rem",
          ...style, // style passed in via props to override/add to these defaults
        }}
        icons={{
          Search: SearchIconRef,
          Delete: DeleteIconRef,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "No records to display",
          },
          toolbar: {
            showColumnsTitle: "Show or Hide Columns",
            showColumnsAriaLabel: "Show or Hide Columns",
          },
          ...localization, // localization passed in via props to override/add to these defaults
        }}
        actions={
          editRowActionFunction
            ? [
                {
                  icon: "edit",
                  tooltip: `${editRowTooltip}`,
                  onClick: (event, rowData) => {
                    if (editRowActionFunction) {
                      editRowActionFunction(event, rowData);
                    }
                  },
                },
              ]
            : undefined
        }
        components={{
          Container: (containerProps) => <Paper {...containerProps} elevation={0} />,
          Toolbar: (toolbarProps) => {
            const userDefinedSearch = _.isBoolean(options?.search); // If false, then search was not passed via props
            // If user did not set the search option, fallback to default setting
            const search = userDefinedSearch ? options?.search : defaultOptions.search;

            return search || toolbarExtras ? (
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs>
                  <MTableToolbar {...toolbarProps} />
                </Grid>
                {toolbarExtras && <Grid item>{toolbarExtras}</Grid>}
              </Grid>
            ) : (
              <></>
            );
          },
          EditRow: (editRowProps) => {
            const StyledRow = styled(MTableEditRow)({
              background: CivColors.palePeach,
              "& > td": {
                textAlign: "right",
                paddingRight: "32px",
                "& > h6": {
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                },
              },
            });
            return <StyledRow {...editRowProps} />;
          },
        }}
        onChangeColumnHidden={(columnData, isHidden) => {
          const columnKey = titleToIdMap[columnData.title as string];
          columnHideState[columnKey] = isHidden;
          saveColumnHideState();
          if (props.onChangeColumnHidden) props.onChangeColumnHidden(columnData, isHidden);
        }}
        onOrderChange={(sortByIdx, sortDir) => {
          saveColumnSort(columnOrder[sortByIdx], sortDir);
          if (props.onOrderChange) props.onOrderChange(sortByIdx, sortDir);
        }}
        onRowsPerPageChange={(pageSize) => {
          savePageSize(pageSize);
          if (props.onRowsPerPageChange) props.onRowsPerPageChange(pageSize);
        }}
        onColumnDragged={(srcVisibleIdx, destVisibleIdx) => {
          if (srcVisibleIdx === destVisibleIdx) return;

          const forward = srcVisibleIdx < destVisibleIdx;

          let currVisibleIdx = forward ? -1 : totalVisibleColumns;
          let currTrueIdx = forward ? 0 : columnOrder.length - 1;
          const getNextTrue = forward ? () => currTrueIdx + 1 : () => currTrueIdx - 1;
          const getNextVisible = forward ? () => currVisibleIdx + 1 : () => currVisibleIdx - 1;
          const checkEnd = forward ? currTrueIdx < columnOrder.length : currTrueIdx >= 0;
          let src: string | undefined;
          while (checkEnd) {
            if (!isColumnHidden(columnOrder[currTrueIdx])) {
              currVisibleIdx = getNextVisible();
              if (currVisibleIdx === srcVisibleIdx) {
                src = columnOrder[currTrueIdx];
              }
              if (currVisibleIdx === destVisibleIdx) {
                break;
              }
            }

            if (src !== undefined) {
              columnOrder[currTrueIdx] = columnOrder[getNextTrue()];
            }
            currTrueIdx = getNextTrue();
          }
          columnOrder[currTrueIdx] = src!!;
          saveColumnOrder();
          if (props.onColumnDragged) props.onColumnDragged(srcVisibleIdx, destVisibleIdx);
        }}
        onPageChange={(pageNum, pageSize) => {
          saveCurrentPage(pageNum);
          if (props.onPageChange) props.onPageChange(pageNum, pageSize);
        }}
        // TODO: https://github.com/CarinaWeb/CarinaCore/issues/1011 Re-implement? onFilterChange is not in docs
        // onFilterChange={(filteredColumns) => {
        //   columnFilterValue = {};
        //   let i = 0;
        //   while (i < filteredColumns.length) {
        //     const columnKey = titleToIdMap[filteredColumns[i].column.title as string];
        //     columnFilterValue[columnKey] = filteredColumns[i].value;
        //     i += 1;
        //   }
        //   saveFilterValue();
        // }}
        {...restDatatableProps}
      />
    </>
  );
}
