import { Box } from "@mui/material";
import { Field, Formik } from "formik";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { RegistrationController_VerifyCredentialsDTO, VerificationParameterType } from "src/generated/api_types";
import { getLocalizedVerificationResponse } from "src/i18n/Utilities";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidBEContent,
  nsMedicaidRegVerification,
} from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { verifyUserCredentials } from "src/pages/registration/verification/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Constraint from "src/reusable_view_elements/Constraint";
import ErrorBox from "src/reusable_view_elements/ErrorBox";
import { FormRadioGroupField, FormTextField, TextField } from "src/reusable_view_elements/form_fields";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { InternalLink } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";
import { date, object, string } from "yup";

/***********************************
 *** WA Consumer Form Components ***
 **********************************/

export interface WAConsumerVerificationFormFields {
  firstName: string;
  lastName: string;
  verificationMethod: string;
  pon: string;
  ssn: string;
  dob: string;
}

export const useVerificationSchemaConsumerWA = () => {
  const { t, ready: translationReady } = useTranslation(nsCommonFormsBtns);

  const VerificationSchemaConsumerWA = object().shape({
    firstName: string()
      .label(t("field.first_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.first_name.error.provide", { ns: nsCommonFormsBtns })),
    lastName: string()
      .label(t("field.last_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.last_name.error.provide", { ns: nsCommonFormsBtns })),
    verificationMethod: string()
      .label(t("field.verification_method.label.care_recipient_info", { ns: nsCommonFormsBtns }))
      .required("Please select a verification method"),
    pon: string()
      .label(t("field.providerone_number.label", { ns: nsCommonFormsBtns }))
      .when("verificationMethod", {
        is: (val) => val === "pon",
        then: string().required(t("field.providerone_number.error", { ns: nsCommonFormsBtns })),
      }),
    ssn: string()
      .label(t("field.last_4_ssn.label", { ns: nsCommonFormsBtns }))
      .when("verificationMethod", {
        is: (val) => val === "ssn",
        then: string()
          .required(t("field.last_4_ssn.error_required", { ns: nsCommonFormsBtns }))
          .matches(/^[0-9]{4}$/, { message: t("field.last_4_ssn.error_matches", { ns: nsCommonFormsBtns }) }),
      }),
    dob: date()
      .label(t("field.dob.label", { ns: nsCommonFormsBtns }))
      .when("verificationMethod", {
        is: (val) => val === "ssn",
        then: date()
          .required(t("field.dob.error_required", { ns: nsCommonFormsBtns }))
          .typeError(t("field.dob.error_type_error", { ns: nsCommonFormsBtns }))
          .max(new Date(), t("field.dob.error_max", { ns: nsCommonFormsBtns }))
          .min("01/01/1800", t("field.dob.error_min", { ns: nsCommonFormsBtns })),
        otherwise: date()
          .nullable()
          .transform((value) => (!isNaN(new Date(value).getDate()) ? value : null)),
      }),
  });

  return {
    VerificationSchemaConsumerWA,
    translationReady,
  };
};

export const WAConsumerVerificationForm = () => {
  const { userInfo, getNextStepRoute, regPath } = useRegistrationContext();
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const [credentialsError, setCredentialsError] = useState(false);
  const { t, ready } = useTranslation([
    nsMedicaidRegVerification,
    nsCommonFormsBtns,
    nsCommonTooltips,
    nsCommonToasts,
    nsMedicaidBEContent,
  ]);
  const location = useLocation();
  const { VerificationSchemaConsumerWA, translationReady: schemaReady } = useVerificationSchemaConsumerWA();

  const initialValues: WAConsumerVerificationFormFields = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    verificationMethod: "ssn",
    pon: "",
    ssn: "",
    dob: "",
  };

  const handleSubmit = async (values: WAConsumerVerificationFormFields) => {
    const verificationData: RegistrationController_VerifyCredentialsDTO =
      values.verificationMethod === "ssn"
        ? {
            params: {
              clientFirstName: {
                type: VerificationParameterType.FIRST_NAME,
                value: values.firstName,
              },
              clientLastName: {
                type: VerificationParameterType.LAST_NAME,
                value: values.lastName,
              },
              clientLast4: {
                type: VerificationParameterType.LAST_4,
                value: values.ssn,
              },
              clientDateOfBirth: {
                type: VerificationParameterType.DOB,
                value: values.dob,
              },
            },
          }
        : {
            params: {
              clientFirstName: {
                type: VerificationParameterType.FIRST_NAME,
                value: values.firstName,
              },
              clientLastName: {
                type: VerificationParameterType.LAST_NAME,
                value: values.lastName,
              },
              clientP1Id: {
                type: VerificationParameterType.UNIQUE_ID,
                value: values.pon,
              },
            },
          };

    try {
      const response = await verifyUserCredentials(verificationData);

      const verificationResMsg = response.data.message;

      if (verificationResMsg === "Verification succeeded") {
        history.push(
          getNextStepRoute(response.data.regStep.step, {
            funder: userInfo.funder.split("-")[1].toLowerCase(),
            role: regPath?.roleConfig?.roleType.toLowerCase() || "",
          }),
        );
      }

      if (verificationResMsg === "API deactivated or bad verification config") {
        showSnackbar(getLocalizedVerificationResponse(verificationResMsg, "error", t), "error");
      }

      if (verificationResMsg === "Verification failed" || verificationResMsg === "User is blocked") {
        setCredentialsError(true);
        scrollToId("credentials-error");
      }
    } catch (err: any) {
      setCredentialsError(true);
      scrollToId("credentials-error");
    }
  };

  if (!(ready && schemaReady)) {
    return (
      <Section bgcolor={CivColors.lightGray}>
        <LoadingCircle />
      </Section>
    );
  }

  return (
    <Section bgcolor={CivColors.lightGray} sx={{ padding: 2 }}>
      {credentialsError && (
        <Constraint columns={8}>
          <ErrorBox marginBottom={6} id="credentials-error">
            <BodyEmphasis paragraph>
              {t("we_couldnt_verify_error.label", { ns: nsMedicaidRegVerification })}
            </BodyEmphasis>
            <Body paragraph>
              {t("we_couldnt_verify_error.description.please_check", { ns: nsMedicaidRegVerification })}
            </Body>
            <Trans
              t={t}
              i18nKey="we_couldnt_verify_error.description.if_need_call_and_select"
              ns={nsMedicaidRegVerification}
              parent={Body}
              components={{
                cntctus: (
                  <InternalLink
                    to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }}
                    target="_blank"
                  />
                ),
              }}
              values={{
                cntctus_key: t("we_couldnt_verify_error.contact_us.label", {
                  ns: nsMedicaidRegVerification,
                }),
              }}
            />
          </ErrorBox>
        </Constraint>
      )}
      <Formik initialValues={initialValues} validationSchema={VerificationSchemaConsumerWA} onSubmit={handleSubmit}>
        {({ values, errors, touched, isSubmitting }) => (
          <Form localizationReady={ready}>
            <Constraint columns={4}>
              <Body sx={{ marginBottom: "24px" }}>
                {t("field.marked_with_asterisk.label", { ns: nsCommonFormsBtns })}
              </Body>
              <FormTextField
                label={t("field.first_name.label", { ns: nsCommonFormsBtns })}
                name="firstName"
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                required={isRequiredField("firstName", VerificationSchemaConsumerWA)}
                sx={{ marginBottom: "24px" }}
                InputProps={{ readOnly: true }}
              />
              <Field
                as={TextField}
                label={t("field.last_name.label", { ns: nsCommonFormsBtns })}
                id="lastName"
                name="lastName"
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                required={isRequiredField("lastName", VerificationSchemaConsumerWA)}
                sx={{ marginBottom: "32px" }}
                InputProps={{ readOnly: true }}
              />

              <BodyEmphasis gutterBottom>
                {t("field.verification_method.label.care_recipient_info", { ns: nsCommonFormsBtns })}
              </BodyEmphasis>
              <FormRadioGroupField<string>
                name="verificationMethod"
                withTooltip
                options={[
                  {
                    key: "pon",
                    optionLabel: t("field.verification_method.providerone_number_option.label", {
                      ns: nsCommonFormsBtns,
                    }),
                    tooltipBody: t("providerone_number", { ns: nsCommonTooltips }),
                  },
                  {
                    key: "ssn",
                    optionLabel: t("field.verification_method.ssn_and_dob_option.label", {
                      ns: nsCommonFormsBtns,
                    }),
                  },
                ]}
              />
              {values.verificationMethod === "pon" ? (
                <Field
                  as={TextField}
                  label={t("field.providerone_number.label", { ns: nsCommonFormsBtns })}
                  id="pon"
                  name="pon"
                  error={Boolean(touched.pon && errors.pon)}
                  helperText={touched.pon && errors.pon}
                  required
                  sx={{ marginTop: "24px" }}
                />
              ) : (
                <>
                  <Field
                    as={TextField}
                    label={t("field.last_4_ssn.label", { ns: nsCommonFormsBtns })}
                    id="ssn"
                    name="ssn"
                    error={Boolean(touched.ssn && errors.ssn)}
                    helperText={touched.ssn && errors.ssn}
                    required
                    sx={{ marginTop: "24px", marginBottom: "24px" }}
                  />
                  <FastMaskTextField
                    label={t("field.dob.label", { ns: nsCommonFormsBtns })}
                    id="dob"
                    name="dob"
                    maskType="date"
                    error={Boolean(touched.dob && errors.dob)}
                    helperText={touched.dob && errors.dob ? errors.dob : "MM/DD/YYYY"}
                    required
                  />
                </>
              )}
            </Constraint>

            <Constraint columns={6}>
              <Box mt={8} textAlign="center">
                <Body paragraph>{t("we_value_privacy.description", { ns: nsMedicaidRegVerification })}</Body>
                <FormAlert
                  schema={VerificationSchemaConsumerWA}
                  reset={values.verificationMethod}
                  sx={{ mt: 2, mb: 2, textAlign: "left" }}
                />
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting
                    ? t("button.verifying", { ns: nsCommonFormsBtns })
                    : t("button.verify", { ns: nsCommonFormsBtns })}
                </Button>
              </Box>
            </Constraint>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
