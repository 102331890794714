import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonToasts } from "src/i18n/Namespaces";
import { renewJobPost } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Footer from "src/reusable_view_elements/Footer";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";

export const RenewJobPost = () => {
  const { id: jobId } = useParams<{ id: string }>();
  const { showSnackbar } = useNotification();
  const [isRenewing, setIsRenewing] = useState(true);
  const { isUserType } = useUserSession();
  const { t } = useTranslation(nsCommonToasts);

  useEffect(() => {
    renewJobPost(jobId)
      .then((res) => {
        showSnackbar(
          t("info.job_successfully_renewed", `Job post #${res.data} successfully renewed!`, {
            ns: nsCommonToasts,
            job_number: `${res.data}`,
          }),
        );
      })
      .finally(() => {
        setIsRenewing(false);
      });
  });

  if (isRenewing) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Job Renewal</title>
        </Helmet>
        <MedicaidNavbar />
        <main id="main-content">
          {/* TODO: Localize this string: https://github.com/CarinaWeb/CarinaCore/issues/992 */}
          <div>Renewing job post...</div>
        </main>
        <Footer />
      </>
    );
  }

  return <Redirect to={getMedicaidRouteByUserType("myJobList", isUserType)} />;
};
