import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { Formik } from "formik";
import { useNotification } from "src/context/NotificationContext";
import { messagingController } from "src/pages/supportAdminAccount/ControllerClients";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";
import Form from "src/reusable_view_elements/form_fields/Form";

interface MessageDialogProps extends Partial<DialogProps> {
  name: string;
  open: boolean;
  setDialogVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  recipientId: string;
}

const MessageDialog = ({ name, setDialogVisibility, recipientId, ...dialogProps }: MessageDialogProps) => {
  const { showSnackbar } = useNotification();

  async function handleSubmit(values: { message: string }) {
    try {
      await messagingController.sendInitialMessage({
        otherUserId: recipientId,
        messageText: values.message,
        phone: "", // for some reason, phone and jobPostId cannot be null
        jobPostId: "",
      });
      showSnackbar("Message sent! Go to Messages to view your conversations.", "success");
      setDialogVisibility(false);
    } catch (e) {
      showSnackbar("Something went wrong sending your message, please try again", "error");
    }
  }

  function handleCancel() {
    setDialogVisibility(false);
  }

  const maxMessageLength = 500;
  const validationSchema = object({
    message: string()
      .required("Please enter your message")
      .max(maxMessageLength, "Exceeded maximum space for this field"),
  });
  const initialValues = {
    message: "",
  };

  return (
    <Dialog
      open={dialogProps.open}
      onClose={dialogProps.onClose}
      aria-labelledby="send-message-modal-title"
      aria-describedby="send-message-modal-content"
      maxWidth="sm"
    >
      <DialogTitle>
        <BodyTitle id="send-message-modal-title">Message {name}</BodyTitle>
      </DialogTitle>
      <DialogContent>
        <Body color="textSecondary" id="send-message-modal-content">
          Reminder: never share a consumer&apos;s protected health information (PHI) with others
        </Body>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ touched, errors, isSubmitting }) => (
            <Form localizationReady={true}>
              <FormTextField
                name="message"
                label="Message"
                placeholder="Enter your message here"
                multiline
                minRows={5}
                helperText={""}
                maximumlength={maxMessageLength}
                required={isRequiredField("message", validationSchema)}
                error={Boolean(touched.message && errors.message)}
              />
              <Box textAlign="right" marginBottom={1}>
                <Button variant="text" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "Send"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default MessageDialog;
