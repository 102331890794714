import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Box, FormHelperText, ListItemText, MenuItem, Select as MSelect, SelectProps } from "@mui/material";
import { FastFieldProps } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Chip from "src/reusable_view_elements/Chip";
import { Checkbox } from "src/reusable_view_elements/form_fields/CheckboxField";
import { ControllableFastField } from "src/reusable_view_elements/form_fields/ControllableFastField";
import { StyledSelectControl } from "src/reusable_view_elements/form_fields/Select";
import { DescriptiveText, StyledInputLabel } from "src/reusable_view_elements/form_fields/TextField";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface SelectOption<T> {
  label: string;
  value: T;
  locizeKey?: string;
  namespace?: string;
}

interface OutlinedMultiSelectAndChipsFieldProps<T> {
  name: string;
  selectOptions: SelectOption<T>[];
  label: string;
  closeOnSelect?: boolean;
  helperText?: string;
  disableDefaultSort?: boolean;
}

export const FormMultiSelectChipsField = <T extends string>(
  props: OutlinedMultiSelectAndChipsFieldProps<T> & Omit<SelectProps, "label">,
) => {
  const { selectOptions, label, closeOnSelect, helperText, ...restProps } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formControlWidth, setFormControlWidth] = useState(undefined);
  const { t, ready } = useTranslation();

  // this gets the width of the form control element so we can use it to explicitly set the width of the menu
  const callbackRef = useCallback((element) => {
    if (element) {
      setFormControlWidth(element.getBoundingClientRect().width);
    }
  }, []);

  const getChipLabel = (value: string): string => {
    const selectOption = selectOptions.find((option) => option.value === value);
    if (selectOption) {
      return selectOption.locizeKey && selectOption.namespace
        ? t(selectOption.locizeKey, { ns: selectOption.namespace })
        : selectOption.label;
    }
    return value;
  };

  const getSelectOptionsList = (fieldValue: T[]) => {
    const orderedOptions = props.disableDefaultSort
      ? selectOptions
      : selectOptions.sort((a, b) => a.value.localeCompare(b.value));

    return orderedOptions.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        data-testid={option.value}
        sx={{ whiteSpace: "unset", display: "flex" }}
      >
        <Checkbox
          sx={{ alignSelf: "flex-start", paddingTop: "12px" }}
          checked={fieldValue.indexOf(option.value) > -1}
        />
        <ListItemText sx={{ padding: "12px 0px" }}>
          <Body>
            {ready && option.locizeKey && option.namespace
              ? t(option.locizeKey, { ns: option.namespace })
              : option.label}
          </Body>
        </ListItemText>
      </MenuItem>
    ));
  };

  return (
    <ControllableFastField name={props.name} controlledRerender={isOpen} languagePeek={label}>
      {({ field, meta, form }: FastFieldProps<T[]>) => {
        const handleDeleteChip = (value: string) => {
          const result = field.value.filter((item: string) => item !== value);
          form.setFieldValue(props.name, result);
        };

        return (
          <Box>
            <StyledInputLabel
              id={`label-${restProps.name}`}
              required={restProps.required}
              disabled={restProps.disabled}
              htmlFor={restProps.name}
            >
              {label}
            </StyledInputLabel>
            <StyledSelectControl fullWidth variant={restProps.variant || "outlined"} ref={callbackRef}>
              <MSelect
                multiple
                fullWidth
                labelId={`label-${props.name}`}
                value={field.value}
                displayEmpty // this tells field to display a value when nothing is selected, requires renderValue prop (Select does not currently support a placeholder prop)
                renderValue={(selected: any) => (
                  <>
                    {selected.length > 0 && (
                      <Box style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {selected.map((value: string) => (
                          <Chip
                            key={value}
                            disabled={restProps.disabled}
                            label={getChipLabel(value)}
                            onDelete={() => handleDeleteChip(value)}
                          />
                        ))}
                      </Box>
                    )}
                  </>
                )}
                onChange={(e) => {
                  if (closeOnSelect) {
                    setIsOpen(false);
                  }
                  field.onChange(e);
                }}
                onBlur={(e) => {
                  if (props.onBlur) props.onBlur(e);
                  field.onBlur(e);
                }}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                error={Boolean(meta.touched && meta.error)}
                IconComponent={ArrowDropDownRoundedIcon}
                SelectDisplayProps={{
                  // @ts-ignore
                  "data-testid": `button-${props.name}`,
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: formControlWidth,
                      "& .Mui-selected": {
                        backgroundColor: `${CivColors.white}!important`,
                      },
                      "& .Mui-selected:focus": {
                        backgroundColor: "rgba(236, 99, 62, 0.08)!important",
                      },
                      "& .Mui-focusVisible": {
                        backgroundColor: "rgba(45, 48, 69, 0.12)!important",
                      },
                    },
                  },
                }}
                {...restProps}
              >
                {getSelectOptionsList(field.value)}
              </MSelect>
              <FormHelperText component="span">
                <DescriptiveText
                  helperText={meta.touched && meta.error ? meta.error : helperText}
                  isFormikError={Boolean(meta.touched && meta.error)}
                  isLengthError={false}
                  disabled={restProps.disabled}
                />
              </FormHelperText>
            </StyledSelectControl>
          </Box>
        );
      }}
    </ControllableFastField>
  );
};

FormMultiSelectChipsField.defaultProps = {
  closeOnSelect: false,
  helperText: "",
};
