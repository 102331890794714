import { Link as MuiLink } from "@mui/material";
import { PropsWithChildren } from "react";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { nsCommonTooltips, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getGenderEquityBlogPost } from "src/pages/blog/BlogPosts";
import Popover from "src/reusable_view_elements/Popover";
import { Body } from "../Typography";

const PronounsPopover = (props: PropsWithChildren<{}>) => {
  const { t } = useTranslation([nsMedicaidTagsEnums, nsCommonTooltips]);

  const pronounsPopoverDescription = (
    <>
      <Body paragraph>{t("popover.pronouns.description_help_people", { ns: nsCommonTooltips })}</Body>
      <Body paragraph>{t("popover.pronouns.description_examples_are", { ns: nsCommonTooltips })}</Body>
      <ul>
        {[
          ["she_her", "popover.pronouns.description_example_her"],
          ["they_them", "popover.pronouns.description_example_them"],
          ["xe_xem", "popover.pronouns.description_example_xem"],
          ["ze_zir", "popover.pronouns.description_example_zir"],
        ].map(([pronounKey, exampleTextKey]) => (
          <li style={{ marginBottom: 22 }} key={pronounKey}>
            <Body>{t(pronounKey, { ns: nsMedicaidTagsEnums })}</Body>
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                }}
              >
                <Trans t={t} i18nKey={exampleTextKey} ns={nsCommonTooltips} components={{ b: <b /> }} parent={Body} />
              </li>
            </ul>
          </li>
        ))}
      </ul>
      <Trans
        t={t}
        i18nKey="popover.pronouns.description_learn_more"
        ns={nsCommonTooltips}
        components={{ bloglink: <MuiLink href={getGenderEquityBlogPost(i18next.language)} target="_blank" /> }}
        parent={Body}
      />
    </>
  );

  return (
    <Popover
      contentTitle={t("popover.pronouns.label", { ns: nsCommonTooltips })}
      content={pronounsPopoverDescription}
      contentId="pronouns-popover"
    >
      {props.children}
    </Popover>
  );
};

export default PronounsPopover;
