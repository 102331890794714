import { Container, Divider, Tab, Tabs } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import PageContainerDashboard from "src/reusable_view_elements/PageContainerDashboard";

export enum FunderAdminDashboardTabs {
  AGENCYADMINS,
  CONSUMERS,
  PROVIDERS,
}

export interface FunderAdminDashboardContainerProps {
  tab: FunderAdminDashboardTabs;
}

export const FunderAdminDashboardContainer: React.FC<PropsWithChildren<FunderAdminDashboardContainerProps>> = ({
  tab,
  children,
}) => {
  return (
    <PageContainerDashboard title="Funder Dashboard" maxWidth="xl">
      <Tabs value={tab}>
        <Tab component={Link} label="Agency Admins" to={FUNDER_ADMIN_DASHBOARD_ROUTES.agencyadmins_list} />
        <Tab component={Link} label="Clients" to={FUNDER_ADMIN_DASHBOARD_ROUTES.consumer_list} />
        <Tab component={Link} label="Providers" to={FUNDER_ADMIN_DASHBOARD_ROUTES.provider_list} />
      </Tabs>
      <Divider />
      <Container maxWidth={false}>{children!}</Container>
    </PageContainerDashboard>
  );
};
