import { PropsWithChildren, useState } from "react";
import { createContainer } from "unstated-next";

function childCareContext() {
  // Captured from URL when accessing registration, and is stored here until registration process is completed
  const [registrationToken, setRegistrationToken] = useState<string>();

  // For saving and retrieving the address object that was searched for
  const [geoDataId, setGeoDataId] = useState<string>();

  // Pre-populates the address search field, i.e. when being redirected from landing page to the search list page
  const [searchInput, setSearchInput] = useState<string>();

  // Search page number
  const [pageNumber, setPageNumber] = useState<number>(1); // 1-based

  // Last profile ID viewed. Used to scroll back to card in search results
  const [lastViewedProfile, setLastViewedProfile] = useState<string>();

  return {
    registrationToken,
    setRegistrationToken,
    geoDataId,
    setGeoDataId,
    searchInput,
    setSearchInput,
    pageNumber,
    setPageNumber,
    lastViewedProfile,
    setLastViewedProfile,
  };
}

const ChildCareContext = createContainer(childCareContext);

export const ChildCareContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <ChildCareContext.Provider>{children}</ChildCareContext.Provider>
);

export const useChildCareContext = () => ChildCareContext.useContainer();
