import { useEffect, useState } from "react";
import { generatePath, Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  CarinaAdminFunderManagementController_FunderDetailDTO,
  CarinaAdminFunderManagementController_RoleConfigDTO,
  RoleConfigsWGroupInviteDTO,
  RoleType,
} from "src/generated/api_types";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { getFunder as getFunderType } from "src/pages/carinaAdmin/management/funders/Queries";
import { getGroupInviteRegPaths } from "src/pages/carinaAdmin/management/Queries";
import GroupInviteLinkModal from "src/pages/supportAdminAccount/forms/GroupInviteLinkModal";
import Constraint from "src/reusable_view_elements/Constraint";
import { Column, Datatable } from "src/reusable_view_elements/datatable/Datatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Button } from "src/reusable_view_elements/Button";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { useOnce } from "src/utilities/useOnce";
import { useStateIfMounted } from "use-state-if-mounted";

interface PathParams {
  id: string;
}

interface FunderMgmtDetailProps {
  getFunder: typeof getFunderType;
}

export const FunderMgmtDetail: React.FC<FunderMgmtDetailProps> = ({ getFunder }) => {
  const { showSnackbar } = useNotification();
  const { id } = useParams<PathParams>();
  const [isFetchingFunder, setIsFetchingFunder] = useStateIfMounted<boolean>(true);
  const [isFetchingRoleConfigs, setIsFetchingRoleConfigs] = useStateIfMounted<boolean>(false);
  const [groupInviteRoleConfig, setGroupInviteRoleConfig] = useState<RoleConfigsWGroupInviteDTO>();

  const [funder, setFunder] = useStateIfMounted<CarinaAdminFunderManagementController_FunderDetailDTO>(
    undefined as unknown as CarinaAdminFunderManagementController_FunderDetailDTO,
  );
  const [openGroupInviteLinkModal, setOpenGroupInviteLinkModal] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenGroupInviteLinkModal(false);
  };

  const ROLE_COLUMN_DEFINITIONS: {
    [k: string]: Column<CarinaAdminFunderManagementController_RoleConfigDTO>;
  } = {
    name: {
      title: "Role Name",
      render: (data) => (
        <InternalLink
          to={generatePath(CARINA_ADMIN_MGMT_ROUTES.funder_role_details, {
            funderId: id,
            id: data.id,
          })}
        >
          <Body>{data.roleDisplayName}</Body>
        </InternalLink>
      ),
    },
  };

  useOnce(() => {
    getFunder(id)
      .then((res) => {
        setFunder(res.data);
      })
      .finally(() => {
        setIsFetchingFunder(false);
      });
  });

  useEffect(() => {
    setIsFetchingRoleConfigs(true);
    getGroupInviteRegPaths(id)
      .then((res) => {
        if (res.data.length > 0) {
          // set up for future where there might be more group invite reg paths and there will be a role select
          // on this form, but right now the only option is OR consumer
          const consumer = res.data.filter((role) => role.roleConfig.roleType === RoleType.CONSUMER);
          setGroupInviteRoleConfig(consumer[0]);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch role configs with token verification strategy. Error: ${e}`);
      })
      .finally(() => {
        setIsFetchingRoleConfigs(false);
      });
  }, [funder]);

  if (isFetchingFunder) {
    return (
      <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
        <Body>Loading...</Body>
      </CarinaAdminMgmtContainer>
    );
  }

  if (!isFetchingFunder && !funder) {
    showSnackbar(`Could not find Funder ${id}`, "error");
    return <Redirect to={CARINA_ADMIN_MGMT_ROUTES.root} />;
  }

  return (
    <>
      <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
        <Constraint columns={8} paddingTop={4}>
          <SectionTitle>{funder.name}</SectionTitle>
          {!isFetchingRoleConfigs && groupInviteRoleConfig && (
            <Button
              variant="outlined"
              type="button"
              sx={{ marginRight: "20px", marginTop: "20px", marginBottom: "20px" }}
              onClick={() => setOpenGroupInviteLinkModal(true)}
            >
              Generate Consumer Link
            </Button>
          )}
        </Constraint>

        <Constraint columns={8}>
          <Datatable
            columnDefinitions={ROLE_COLUMN_DEFINITIONS}
            persistKeyPrefix="funder-mgmt-roles"
            data={funder.roleConfigs}
          />
        </Constraint>
      </CarinaAdminMgmtContainer>
      {groupInviteRoleConfig && (
        <GroupInviteLinkModal
          groupInviteRoleConfig={groupInviteRoleConfig}
          showModal={openGroupInviteLinkModal}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};
