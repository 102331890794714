import { FormGroup, RadioGroup, Link as MuiLink } from "@mui/material";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostTagEnum, Permission } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonNavigation, nsMedicaidSearch, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedJobPostTag } from "src/i18n/TagHelpers";
import {
  ageRangeOptions,
  languageOptions,
  typeOfSupportOptions,
} from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJobForm";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import LoadingFiltersSkeleton from "src/pages/medicaidAccount/searchComponents/LoadingFiltersSkeleton";
import SimpleChipsMultiSelect, {
  ICheckbox,
  XCheckbox,
} from "src/pages/medicaidAccount/searchComponents/SimpleChipsMultiSelect";
import { jobSearchAndFilterClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { AccordionList } from "src/reusable_view_elements/Accordion";
import { ControlLabel, Radio } from "src/reusable_view_elements/form_fields";
import { Checkbox, CheckboxControlLabel } from "src/reusable_view_elements/form_fields/CheckboxField";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body } from "src/reusable_view_elements/Typography";
import { getNotificationFrequencyFriendlyName } from "src/utilities/GeneralUtilities";

const AdvancedJobFilters = () => {
  const {
    jobSearchDTO,
    setJobSearchDTO,
    setIsJobFilterDirty,
    jobFltrWkNotifEnabled,
    enableJobFltrWkNotif,
    jobFilterNotificationFrequency,
    loadingJobFilterNotification,
    setJobsPage,
    setClearJobFilters,
  } = useMedicaidContext();
  const { isUserType, hasPermission } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidSearch, nsCommonFormsBtns, nsCommonNavigation, nsMedicaidTagsEnums]);

  const getDistanceRange = (): number => {
    const { distanceMinutes } = jobSearchDTO;
    return distanceMinutes;
  };
  const getHoursRange = (): string => {
    const { minHours } = jobSearchDTO;
    switch (minHours) {
      case 120:
        return "Greater than 120";
      case 80:
        return "80 - 119";
      case 40:
        return "40 - 79";
      case 0:
        return "Less than 40";
      default:
        return "";
    }
  };

  const handleDistanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setJobSearchDTO({ ...jobSearchDTO, distanceMinutes: parseInt(e.target.value) });
    setIsJobFilterDirty(true);
    setJobsPage(0);
  };

  const handleHoursSelection = (e: ChangeEvent<HTMLInputElement>) => {
    let maxHrs;
    let minHrs;
    switch (e.target.value) {
      case "Greater than 120":
        maxHrs = Infinity;
        minHrs = 120;
        break;
      case "80 - 119":
        maxHrs = 119;
        minHrs = 80;
        break;
      case "40 - 79":
        maxHrs = 79;
        minHrs = 40;
        break;
      default:
        maxHrs = 39;
        minHrs = 0;
    }
    setJobSearchDTO({ ...jobSearchDTO, minHours: minHrs, maxHours: maxHrs });
    setIsJobFilterDirty(true);
    setJobsPage(0);
  };

  const ageRangeKeys = ageRangeOptions.map((option) => option.key);
  const typeOfSupportKeys = typeOfSupportOptions.map((option) => option.value);
  // TODO: Map these keys like ageRangeOptions: https://github.com/CarinaWeb/CarinaCore/issues/1005
  const frequencyKeys = ["ONGOING", "ONETIME", "ONCALL"];
  const consumeGenderKeys = ["MALE_CLIENT", "FEMALE_CLIENT", "NONBINARY_CLIENT"];
  const environmentKeys = ["NON_SMOKING", "LGBTQIA_HOUSEHOLD"];
  const excludableKeys = [
    "PERSONAL_CARE",
    "NURSE_DELEGATION",
    "TRANSFER",
    "TRANSFER_ASSISTED",
    "TRANSPORT",
    "TRANSPORT_PROVIDER",
    "BEHAVIOR_CHALLENGE",
    "NO_PETS",
  ];

  const keysGroups: { [k: string]: string[] } = {
    ageRangeCategory: ageRangeKeys,
    typeOfSupportCategory: typeOfSupportKeys,
    frequency: frequencyKeys,
    consumerGender: consumeGenderKeys,
    environment: environmentKeys,
  };

  const getFiltersObjectForGroup = (filterGroupName: string) => {
    const allTags: string[] = [];
    jobSearchDTO.tagGroups.forEach((tagGroup) => {
      if (tagGroup) {
        tagGroup.forEach((tag) => allTags.push(tag));
      }
    });
    const entriesArray = keysGroups[filterGroupName].map((key) => [key, allTags.includes(key)]);
    return Object.fromEntries(entriesArray);
  };

  const isLanguage = (tag: JobPostTagEnum | string) =>
    languageOptions.map((lo) => lo.value).includes(tag as unknown as JobPostTagEnum);

  const getIncludedLanguages = (): JobPostTagEnum[] => {
    const isLanguageGroup = (gr: JobPostTagEnum[]) => gr.some(isLanguage);
    const currentLangGroup = jobSearchDTO.tagGroups.filter(isLanguageGroup)[0];
    if (!currentLangGroup) return [];
    return currentLangGroup;
  };

  const getExcludedValuesObject = () => {
    const isNotPet = (tg: JobPostTagEnum) => {
      return (
        tg !== JobPostTagEnum.DOG &&
        tg !== JobPostTagEnum.CAT &&
        tg !== JobPostTagEnum.BIRD &&
        tg !== JobPostTagEnum.OTHER_PET
      );
    };
    const excludedKeys: string[] = jobSearchDTO.excludedTags.filter(isNotPet);
    if (
      jobSearchDTO.excludedTags.includes(JobPostTagEnum.DOG) ||
      jobSearchDTO.excludedTags.includes(JobPostTagEnum.CAT) ||
      jobSearchDTO.excludedTags.includes(JobPostTagEnum.BIRD) ||
      jobSearchDTO.excludedTags.includes(JobPostTagEnum.OTHER_PET)
    ) {
      excludedKeys.push("NO_PETS");
    }
    const entriesArray = excludableKeys.map((key) => {
      return [key, excludedKeys.includes(key)];
    });
    return Object.fromEntries(entriesArray);
  };

  const handleTagFilterSelection = (e: any, filterGroupName: string) => {
    const tagName = e.target.name;
    if (tagName === "NO_PETS") {
      handleTagExclusion(e);
    } else if (filterGroupName === "consumerGender") {
      // This largely does the same thing as the else block below, but has special handling to add NO_GENDER_PREFERENCE_CLIENT
      handleGenderSelection(e);
    } else {
      // Check if the selected tag belongs to the specified filterGroupName (should almost always be the case)
      const tagBelongsToGroup = (tag: JobPostTagEnum): boolean => keysGroups[filterGroupName].includes(tag);

      // Check if the specified filterGroupName contains any tags already
      const tagArrBelongsToGroup = (tagArr: JobPostTagEnum[]): boolean => tagArr.some(tagBelongsToGroup);

      // Get the current tag group map if it has any tags in same filter group, or create an empty array if it doesn't
      const targetMtrx = jobSearchDTO.tagGroups.length > 0 ? jobSearchDTO.tagGroups.filter(tagArrBelongsToGroup) : [];

      // Remove the tag from the group if it's already there
      const targetGroup = targetMtrx.length > 0 ? targetMtrx[0].filter((tag) => (tag as string) !== tagName) : [];

      // Add the tag to the targetGroup if checked
      if (e.target.checked) targetGroup.push(tagName as JobPostTagEnum);

      // Remove the target group from the tagGroups array if it's already there
      const notTargetGroups = jobSearchDTO.tagGroups.filter((group) => !tagArrBelongsToGroup(group));

      // Create a new tagGroups array with the target group included
      const newTagGroups = [...notTargetGroups];

      // Add group back to Filter if it has tags
      if (targetGroup.length > 0) newTagGroups.push(targetGroup);

      const newSearchDTO = { ...jobSearchDTO, tagGroups: newTagGroups };
      setJobSearchDTO(newSearchDTO);
      setIsJobFilterDirty(true);
      setJobsPage(0);
    }
  };

  const handleTagExclusion = (e: ChangeEvent<HTMLInputElement>) => {
    const tagName = e.target.name;
    const newExcludedTags: JobPostTagEnum[] = [];
    const isNotPet = (tag: JobPostTagEnum): boolean => {
      return (
        tag !== JobPostTagEnum.DOG &&
        tag !== JobPostTagEnum.CAT &&
        tag !== JobPostTagEnum.BIRD &&
        tag !== JobPostTagEnum.OTHER_PET
      );
    };
    if (tagName === "NO_PETS") {
      jobSearchDTO.excludedTags.filter(isNotPet).forEach((tg) => newExcludedTags.push(tg));
      if (e.target.checked) {
        newExcludedTags.push(JobPostTagEnum.DOG);
        newExcludedTags.push(JobPostTagEnum.CAT);
        newExcludedTags.push(JobPostTagEnum.BIRD);
        newExcludedTags.push(JobPostTagEnum.OTHER_PET);
      }
    } else {
      jobSearchDTO.excludedTags.filter((tag) => tag !== tagName).forEach((tg) => newExcludedTags.push(tg));
      if (e.target.checked) newExcludedTags.push(tagName as JobPostTagEnum);
    }
    const newSearchDTO = { ...jobSearchDTO, excludedTags: newExcludedTags };
    setJobSearchDTO(newSearchDTO);
    setIsJobFilterDirty(true);
    setJobsPage(0);
  };

  const isChecked = (group: string, tag: string): boolean => {
    if (tag === "NO_PETS") return isExcluded(tag);
    const filterObject = getFiltersObjectForGroup(group);
    return filterObject ? filterObject[tag] : false;
  };

  const isExcluded = (tag: string): boolean => {
    const excludedValues = getExcludedValuesObject();
    return excludedValues[tag] ? excludedValues[tag] : false;
  };

  const handleNotifCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    const originalNotificationBoxEnabled = jobFltrWkNotifEnabled;
    const persistFilter = e.target.checked;
    enableJobFltrWkNotif(persistFilter);
    if (persistFilter) {
      Promise.allSettled([
        jobSearchAndFilterClient.persistFilter(jobSearchDTO),
        jobSearchAndFilterClient.updateEmailFilterNotification({
          frequency: jobFilterNotificationFrequency,
          enable: true,
        }),
      ]).catch(() => enableJobFltrWkNotif(originalNotificationBoxEnabled));
    } else {
      Promise.allSettled([
        jobSearchAndFilterClient.clearFilter(),
        jobSearchAndFilterClient.updateEmailFilterNotification({
          frequency: jobFilterNotificationFrequency,
          enable: false,
        }),
      ]).catch(() => enableJobFltrWkNotif(originalNotificationBoxEnabled));
    }
  };

  const handleLanguageSelection = (selectedLanguages: JobPostTagEnum[]) => {
    const isNotLanguageGroup = (gr: JobPostTagEnum[]) => !gr.some(isLanguage);
    const currentNoLangGroups = jobSearchDTO.tagGroups.filter(isNotLanguageGroup);
    const newTagGroups = [...currentNoLangGroups, selectedLanguages];
    const newSearchDTO = { ...jobSearchDTO, tagGroups: newTagGroups };
    setJobSearchDTO(newSearchDTO);
    setIsJobFilterDirty(true);
    setJobsPage(0);
  };

  const handleProfilePrefClick = () => {
    jobSearchAndFilterClient.getProviderPreferences().then((res) => {
      const preferences = res.data;
      if (preferences) {
        let minHr;
        let maxHr;
        if (preferences.hours >= 120) {
          maxHr = Infinity;
          minHr = 120;
        } else if (preferences.hours >= 80) {
          maxHr = 119;
          minHr = 80;
        } else if (preferences.hours >= 40) {
          maxHr = 79;
          minHr = 40;
        } else {
          maxHr = 39;
          minHr = 0;
        }
        const prefTags = preferences.providerProfileTags;
        const langs = prefTags.filter(isLanguage) as JobPostTagEnum[];
        let newTagGroups = Object.keys(keysGroups).map((groupKey) => {
          return Object.values(keysGroups[groupKey])
            .filter((tag) => prefTags.includes(tag))
            .map((tag) => tag as JobPostTagEnum);
        });
        newTagGroups = [...newTagGroups, langs];
        const newSearchDTO = {
          ...jobSearchDTO,
          tagGroups: newTagGroups,
          minHours: minHr,
          maxHours: maxHr,
          excludedTags: [],
        };
        setJobSearchDTO(newSearchDTO);
        setIsJobFilterDirty(true);
        setJobsPage(0);
      }
    });
  };

  const handleGenderSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const tagName = e.target.name;

    const tagBelongsToGroup = (tag: JobPostTagEnum): boolean => keysGroups["consumerGender"].includes(tag);
    const tagArrBelongsToGroup = (tagArr: JobPostTagEnum[]): boolean => tagArr.some(tagBelongsToGroup);

    const targetMtrx = jobSearchDTO.tagGroups.length > 0 ? jobSearchDTO.tagGroups.filter(tagArrBelongsToGroup) : [];
    // Remove the tag from the group if it's already there, and always remove NO_GENDER_PREFERENCE_CLIENT
    const targetGroup =
      targetMtrx.length > 0
        ? targetMtrx[0].filter(
            (tag) => (tag as string) !== tagName && (tag as string) !== "NO_GENDER_PREFERENCE_CLIENT",
          )
        : [];

    if (e.target.checked) targetGroup.push(tagName as JobPostTagEnum);

    const notTargetGroups = jobSearchDTO.tagGroups.filter((group) => !tagArrBelongsToGroup(group));
    const newTagGroups = [...notTargetGroups];

    // Include NO_GENDER_PREFERENCE_CLIENT if one of the specific gender tags is selected
    if (targetGroup.length > 0 && ["MALE_CLIENT", "FEMALE_CLIENT", "NONBINARY_CLIENT"].includes(tagName)) {
      targetGroup.push("NO_GENDER_PREFERENCE_CLIENT" as JobPostTagEnum);
    }

    if (targetGroup.length > 0) newTagGroups.push(targetGroup);
    const newSearchDTO = { ...jobSearchDTO, tagGroups: newTagGroups };
    setJobSearchDTO(newSearchDTO);
    setIsJobFilterDirty(true);
    setJobsPage(0);
  };

  if (!ready) {
    return <LoadingFiltersSkeleton />;
  }

  return (
    <>
      {isUserType(UserType.Provider) && (
        <MuiLink component="button" onClick={handleProfilePrefClick}>
          <Body paragraph textAlign="left">
            {t("apply_my_profile_pref.label", { ns: nsMedicaidSearch })}
          </Body>
        </MuiLink>
      )}
      <MuiLink component="button" onClick={() => setClearJobFilters(true)}>
        <Body paragraph>{t("clear_filters.label", { ns: nsMedicaidSearch })}</Body>
      </MuiLink>

      <AccordionList
        titleTypography="BodyEmphasis"
        AccordionDetailsProps={{
          style: {
            paddingBottom: 16,
          },
        }}
        showListItemBadge
        data={[
          {
            title: t("max_travel_time.label", { ns: nsMedicaidSearch }),
            body: (
              <RadioGroup name="distanceMinutes" onChange={handleDistanceChange} value={getDistanceRange()}>
                <ControlLabel
                  value={15}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 15 })}
                />
                <ControlLabel
                  value={30}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 30 })}
                />
                <ControlLabel
                  value={45}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 45 })}
                />
                <ControlLabel
                  value={60}
                  control={<Radio />}
                  label={t("count_minutes.label", { ns: nsMedicaidSearch, count: 60 })}
                />
              </RadioGroup>
            ),
            count: jobSearchDTO.distanceMinutes ? 1 : 0,
          },
          {
            title: t("monthly_hours.label", { ns: nsMedicaidSearch }),
            body: (
              <RadioGroup onChange={handleHoursSelection} value={getHoursRange()}>
                <ControlLabel
                  value="Less than 40"
                  control={<Radio />}
                  label={t("monthly_hours.less_than_40.label", { ns: nsMedicaidSearch })}
                />
                <ControlLabel
                  value="40 - 79"
                  control={<Radio />}
                  label={t("monthly_hours.40_to_79.label", { ns: nsMedicaidSearch })}
                />
                <ControlLabel
                  value="80 - 119"
                  control={<Radio />}
                  label={t("monthly_hours.80_to_119.label", { ns: nsMedicaidSearch })}
                />
                <ControlLabel
                  value="Greater than 120"
                  control={<Radio />}
                  label={t("monthly_hours.greater_than_120.label", { ns: nsMedicaidSearch })}
                />
              </RadioGroup>
            ),
            count: jobSearchDTO.minHours !== undefined && jobSearchDTO.minHours != null ? 1 : 0,
          },
          {
            title: t("age.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "ageRangeCategory")}>
                {ageRangeOptions.map((option) => (
                  <ICheckbox
                    key={option.key}
                    name={option.key}
                    group="ageRangeCategory"
                    checkFn={isChecked}
                    label={t(getLocalizedJobPostTag(option.key).key, { ns: nsMedicaidTagsEnums })}
                  />
                ))}
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("ageRangeCategory")).filter((v) => v).length,
          },
          {
            title: t("care_support.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "typeOfSupportCategory")}>
                {typeOfSupportOptions.map((option) => (
                  <ICheckbox
                    key={option.value.toString()}
                    name={option.value}
                    group="typeOfSupportCategory"
                    checkFn={isChecked}
                    label={t(getLocalizedJobPostTag(option.value).key, { ns: nsMedicaidTagsEnums })}
                  />
                ))}
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("typeOfSupportCategory")).filter((v) => v).length,
          },
          {
            title: t("frequency.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "frequency")}>
                <ICheckbox
                  name="ONGOING"
                  group="frequency"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.ONGOING).key, { ns: nsMedicaidTagsEnums })}
                />
                <ICheckbox
                  name="ONETIME"
                  group="frequency"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.ONETIME).key, { ns: nsMedicaidTagsEnums })}
                />
                <ICheckbox
                  name="ONCALL"
                  group="frequency"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.ONCALL).key, { ns: nsMedicaidTagsEnums })}
                />
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("frequency")).filter((v) => v).length,
          },
          {
            title: t("consumer_gender.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "consumerGender")}>
                <ICheckbox
                  name="MALE_CLIENT"
                  group="consumerGender"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.MALE_CLIENT).key, { ns: nsMedicaidTagsEnums })}
                />
                <ICheckbox
                  name="FEMALE_CLIENT"
                  group="consumerGender"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.FEMALE_CLIENT).key, { ns: nsMedicaidTagsEnums })}
                />
                <ICheckbox
                  name="NONBINARY_CLIENT"
                  group="consumerGender"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.NONBINARY_CLIENT).key, { ns: nsMedicaidTagsEnums })}
                />
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("consumerGender")).filter((v) => v).length,
          },
          {
            title: t("work_environment.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={(e) => handleTagFilterSelection(e, "environment")}>
                <ICheckbox
                  name="NON_SMOKING"
                  group="environment"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.NON_SMOKING).key, { ns: nsMedicaidTagsEnums })}
                />
                {/*TODO: move to excluded ? */}
                <ICheckbox
                  name="NO_PETS"
                  group="environment"
                  checkFn={isChecked}
                  label={t("pet.no_pets", { ns: nsMedicaidTagsEnums })}
                />
                <ICheckbox
                  name="LGBTQIA_HOUSEHOLD"
                  group="environment"
                  checkFn={isChecked}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.LGBTQIA_HOUSEHOLD).key, { ns: nsMedicaidTagsEnums })}
                />
              </FormGroup>
            ),
            count: Object.values(getFiltersObjectForGroup("environment"))
              .concat(isChecked("environment", "NO_PETS"))
              .filter((v) => v).length,
          },
          {
            title: t("languages.label", { ns: nsMedicaidSearch }),
            body: (
              <SimpleChipsMultiSelect
                name="languages"
                label={t("field.languages.label", { ns: nsCommonFormsBtns })}
                options={languageOptions.map((option) => ({
                  ...option,
                  label: t(getLocalizedJobPostTag(option.value).key, { ns: nsMedicaidTagsEnums }),
                }))}
                selected={getIncludedLanguages()}
                selectionHandlerFn={handleLanguageSelection}
              />
            ),
            count: getIncludedLanguages().length,
          },
          {
            title: t("exclude_consumers_who.label", { ns: nsMedicaidSearch }),
            body: (
              <FormGroup onChange={handleTagExclusion}>
                <XCheckbox
                  name="PERSONAL_CARE"
                  checkFn={isExcluded}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.PERSONAL_CARE).key, { ns: nsMedicaidTagsEnums })}
                />
                <XCheckbox
                  name="NURSE_DELEGATION"
                  checkFn={isExcluded}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.NURSE_DELEGATION).key, { ns: nsMedicaidTagsEnums })}
                />
                <XCheckbox
                  name="TRANSFER"
                  checkFn={isExcluded}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.TRANSFER).key, { ns: nsMedicaidTagsEnums })}
                />
                <XCheckbox
                  name="TRANSFER_ASSISTED"
                  checkFn={isExcluded}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.TRANSFER_ASSISTED).key, { ns: nsMedicaidTagsEnums })}
                />
                <XCheckbox name="TRANSPORT" checkFn={isExcluded} label="Transport - using the client's vehicle" />
                <XCheckbox
                  name="TRANSPORT_PROVIDER"
                  checkFn={isExcluded}
                  label={t(getLocalizedJobPostTag(JobPostTagEnum.TRANSPORT_PROVIDER).key, { ns: nsMedicaidTagsEnums })}
                />
                <XCheckbox
                  name="BEHAVIOR_CHALLENGE"
                  checkFn={isExcluded}
                  label={t("behavior_working_with", { ns: nsMedicaidTagsEnums })}
                />
              </FormGroup>
            ),
            count: Object.values(
              Object.fromEntries(Object.entries(getExcludedValuesObject()).filter(([k]) => k !== "NO_PETS")),
            ).filter((v) => v).length,
          },
        ]}
      />
      {hasPermission(Permission.SETTINGS_ACCESS_JOB_FILTERS) && (
        <>
          {loadingJobFilterNotification ? (
            <LoadingCircle />
          ) : (
            <CheckboxControlLabel
              name="email-filter-match"
              control={
                <Checkbox
                  data-testid="filter-persist-checkbox"
                  checked={jobFltrWkNotifEnabled}
                  onChange={handleNotifCheckboxClick}
                />
              }
              label={t("email_me_when_job_checkbox.label", {
                ns: nsMedicaidSearch,
                frequency: t(`${getNotificationFrequencyFriendlyName(jobFilterNotificationFrequency)}`, {
                  ns: nsMedicaidTagsEnums,
                }).toLowerCase(),
              })}
              style={{ marginBottom: "20px", marginTop: "20px" }}
            />
          )}

          <MuiLink href={MEDICAID_ACCOUNT_ROUTES.accountSettings}>
            <Body paragraph>
              {t("view_settings_to_change_pref.label", {
                ns: nsMedicaidSearch,
                sttngspg_name: t("link.settings", { ns: nsCommonNavigation }),
              })}
            </Body>
          </MuiLink>
        </>
      )}
    </>
  );
};

export default AdvancedJobFilters;
