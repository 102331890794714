import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { format } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { InviteDetailsDTO } from "src/generated/api_types";
import { REGISTRATION_ROUTES } from "src/pages/registration/components/RegistrationRouter";
import { usersAdministrationController } from "src/pages/supportAdminAccount/ControllerClients";
import { getDashboardTitle } from "src/pages/supportAdminAccount/dashboard/components/UserDetails";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import TextField from "src/reusable_view_elements/form_fields/TextField";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import PageContainerDashboard, { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";

interface InviteDetailsProps {
  dashboardType: DashboardType;
}

const InviteDetails = ({ dashboardType }: InviteDetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const { showSnackbar } = useNotification();
  const [isLoadingInvite, setLoadingInvite] = useState<boolean>(false);
  const [inviteData, setInviteData] = useState<InviteDetailsDTO>();
  const { userSession } = useUserSession();

  useEffect(() => {
    const fetchInvite = async () => {
      setLoadingInvite(true);
      const res =
        dashboardType === DashboardType.MEDICAID
          ? await usersAdministrationController.getMedicaidInviteDetails(id)
          : await usersAdministrationController.getChildcareInviteDetails(id);
      setInviteData(res.data);
    };
    fetchInvite()
      .catch(() => showSnackbar("Sorry, there was an issue retrieving this user's invite.", "error"))
      .finally(() => {
        setLoadingInvite(false);
      });
  }, []);

  const generateInviteLink = (token: string) => {
    const pathnameRoot =
      dashboardType === DashboardType.CHILDCARE
        ? REGISTRATION_ROUTES.childcareInviteLanding.replace(":token", "")
        : REGISTRATION_ROUTES.medicaidInviteLanding.replace(":token", "");
    return `${window.location.origin}${pathnameRoot}${token}`;
  };
  const copyInviteLink = async (token: string) => {
    await navigator.clipboard.writeText(generateInviteLink(token));
    showSnackbar("Invite Link Copied!", "success");
  };

  return (
    <>
      <Helmet>
        <title>Carina | Invite Details</title>
      </Helmet>
      <PageContainerDashboard title={getDashboardTitle(userSession?.role, dashboardType)} maxWidth="xl">
        <DashReturnLink
          to={
            dashboardType === DashboardType.CHILDCARE
              ? SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_invited_user
              : SUPPORT_ADMIN_DASHBOARD_ROUTES.invited_user
          }
        >
          Back
        </DashReturnLink>

        {isLoadingInvite && <LoadingCircle />}

        {!isLoadingInvite && !inviteData && (
          <Constraint columns={8} paddingTop={4}>
            <SectionTitle paragraph>Invite not found</SectionTitle>
          </Constraint>
        )}

        {!isLoadingInvite && inviteData && (
          <>
            <Helmet>
              <title>Carina | Invite Details</title>
            </Helmet>
            <Constraint columns={8} paddingTop={4}>
              <Grid container style={{ marginBottom: "36px" }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SectionTitle paragraph>
                    {inviteData?.firstName} {inviteData?.lastName}
                  </SectionTitle>
                  <Body>
                    Invite {inviteData.funderName} {_.startCase(_.camelCase(inviteData.role.toString()))}
                  </Body>
                </Grid>
              </Grid>
              {inviteData.email && (
                <DashSection bodyTitleLabel label="Account Info" topBorder>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <BodyEmphasis paragraph>Email Address</BodyEmphasis>
                    </Grid>
                  </Grid>
                  <Body>{inviteData.email}</Body>
                </DashSection>
              )}
              {inviteData.reason && inviteData.verifiersInfo && (
                <DashSection bodyTitleLabel label="Invite notes" topBorder>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <BodyEmphasis paragraph>{format(inviteData.createdAt, "PP")}</BodyEmphasis>
                    </Grid>
                    <Grid item>
                      {inviteData.issuerFirstName && (
                        <Body paragraph>
                          Submitted By {inviteData.issuerFirstName} {inviteData.issuerLastName.substring(0, 1)} &#46;
                        </Body>
                      )}
                    </Grid>
                  </Grid>
                  <Body>{inviteData.reason}</Body>
                  {JSON.parse(inviteData.verifiersInfo).verifiersFirstName && (
                    <Grid item>
                      <Grid item style={{ padding: "16px 0px" }}>
                        <Body>
                          <span style={{ color: "#595A5A" }}>
                            Verified By {JSON.parse(inviteData.verifiersInfo).verifiersFirstName} &nbsp;
                            {JSON.parse(inviteData.verifiersInfo).verifiersLastName.substring(0, 1)} &#46; &nbsp; (
                            {JSON.parse(inviteData.verifiersInfo).verifiersEmail})
                          </span>
                        </Body>
                      </Grid>
                    </Grid>
                  )}
                </DashSection>
              )}
              {inviteData.token && (
                <DashSection bodyTitleLabel label="Invite link" topBorder>
                  <Grid item xs={12}>
                    <TextField
                      name="inviteLink"
                      label="Invite Link"
                      type="text"
                      value={generateInviteLink(inviteData.token)}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button variant="text" type="button" onClick={() => copyInviteLink(inviteData.token)}>
                              Copy
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </DashSection>
              )}
            </Constraint>
          </>
        )}
      </PageContainerDashboard>
    </>
  );
};

export default InviteDetails;
