import { AppBar, Box, Menu, MenuItem, SelectChangeEvent, Toolbar } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MouseEvent, useEffect, useState } from "react";
import { Link, NavLink as NavRouterLink } from "react-router-dom";
import CARINA_LOGO from "src/assets/images/logo-carina-version-2-white-outline.svg";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { ROUTES } from "src/MainRouter";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { funderSelectionController } from "src/pages/supportAdminAccount/ControllerClients";
import { SUPPORT_ADMIN_PROGRAM_KEY } from "src/pages/supportAdminAccount/dashboard/components/SupportAdminDashboardContainer";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { SelectOption } from "src/reusable_view_elements/form_fields";
import Select from "src/reusable_view_elements/form_fields/Select";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const NavbarTitle = (props: { string: string }) => {
  const style = {
    marginLeft: 8,
  };
  return (
    <Body color="inherit" style={style}>
      | {props.string}
    </Body>
  );
};

export type HeaderProps = {
  title?: string;
  withProgramDropdown?: boolean;
  searchTriggerFn?: (programId: string) => void;
};

const DashboardNavbar: React.FC<HeaderProps> = ({ title, withProgramDropdown, searchTriggerFn }) => {
  const { userSession, isImpersonated, isUserType } = useUserSession();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();

  const styles = {
    appbar: {
      boxShadow: "none",
    },
    toolbar: {
      backgroundColor: CivColors.coreDarkNavy,
      color: CivColors.white,
      paddingLeft: "16px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    logo: {
      width: 139,
      height: 66,
    },
  };

  function handleOpen(event: MouseEvent<HTMLElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setMenuAnchorEl(undefined);
  }

  const UserInfo = () => {
    return (
      <Box textAlign="right">
        <BodyEmphasis color="inherit">{`${userSession?.firstName} ${userSession?.lastName}`}</BodyEmphasis>
        <Body color="inherit" data-testid="authed-user-email">
          {userSession?.email}
        </Body>
      </Box>
    );
  };

  const MenuDropdownArrow = () => {
    return (
      <Box display="flex" alignItems="center">
        <ArrowDropDownIcon sx={{ color: CivColors.white }} />
      </Box>
    );
  };

  const MenuForLoggedInUser = () => {
    return (
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        // TODO: PaperProps is deprecated
        // PaperProps={{
        //   style: {
        //     width: "25ch", // The CSS unit "ch" is the width of the "0" character
        //   },
        // }}
      >
        {isUserType(UserType.UserSupportManager) && (
          <MenuItem component={NavRouterLink} to="/" style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Body> FCCP Token Generator </Body>
          </MenuItem>
        )}

        {(isUserType(UserType.UserSupportManager) || isUserType(UserType.UserSupportAgent)) && (
          <MenuItem component={NavRouterLink} to="/" style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Body> Switch Tools / View </Body>
          </MenuItem>
        )}
        {isUserType(UserType.AgencyAdmin) && (
          <MenuItem
            target="_blank"
            component={NavRouterLink}
            to={DASHBOARD_ROUTES.faq}
            style={{ paddingTop: 16, paddingBottom: 16 }}
          >
            <Body>Visit FAQ Page</Body>
          </MenuItem>
        )}

        {isUserType(UserType.CarinaAdmin) && (
          <MenuItem component={NavRouterLink} to={CARINA_ADMIN_MGMT_ROUTES.root}>
            Management Tools
          </MenuItem>
        )}
        <MenuItem
          disabled={isImpersonated()}
          component={NavRouterLink}
          to={ROUTES.logout}
          data-testid="logout-button"
          style={{ paddingTop: 16, paddingBottom: 16 }}
        >
          <Body>Log out</Body>
        </MenuItem>
      </Menu>
    );
  };

  const MenuLoginButton = () => {
    return (
      <LinkStyledAsButton variant="outlined" href={ROUTES.login} data-testid="login-button">
        Login
      </LinkStyledAsButton>
    );
  };

  return (
    <AppBar position="static" style={styles.appbar}>
      <Toolbar style={styles.toolbar}>
        <Box display="flex" flexGrow={1} alignItems="center">
          <Link to="/" style={{ lineHeight: 0 }}>
            <img src={CARINA_LOGO} alt="logo" style={styles.logo} />
          </Link>
          {title && <NavbarTitle string={title} />}
          {withProgramDropdown && <Body style={{ marginRight: 10 }}>:</Body>}
          {withProgramDropdown ? <ProgramDropdown searchTriggerFn={searchTriggerFn} /> : <></>}
        </Box>
        {/* Funder Admins only have Log Out, so don't show the Menu for them */}
        {userSession && isUserType(UserType.FunderAdmin) && (
          <>
            <UserInfo />
            <LinkStyledAsButton
              variant="contained"
              disabled={isImpersonated()}
              href={ROUTES.logout}
              data-testid="logout-button"
              sx={{ marginLeft: "24px" }}
            >
              Log Out
            </LinkStyledAsButton>
          </>
        )}
        {userSession && !isUserType(UserType.FunderAdmin) && (
          <>
            <Box
              data-testid="header-user-info"
              onClick={handleOpen}
              display="flex"
              flexDirection="row"
              style={{ cursor: "pointer" }}
            >
              <UserInfo />
              <MenuDropdownArrow />
            </Box>
            <MenuForLoggedInUser />
          </>
        )}
        {!userSession && <MenuLoginButton />}
      </Toolbar>
    </AppBar>
  );
};

interface ProgramOptionsProps {
  searchTriggerFn?: (programId: string) => void;
}

const ProgramDropdown: React.FC<ProgramOptionsProps> = ({ searchTriggerFn }) => {
  const { program, setProgram, setProgramOptions, programOptions, isLoadingPrograms, setLoadingPrograms } =
    useSupportAdminContext();
  const { isUserType } = useUserSession();
  const { showSnackbar } = useNotification();

  useEffect(() => {
    (async () => {
      if (programOptions.length === 0 && isUserType(UserType.UserSupportManager)) {
        try {
          setLoadingPrograms(true);
          const res = await funderSelectionController.fetchSelectOptions({ offering: "HC Medicaid" });
          setProgramOptions(res.data);
        } catch (e) {
          showSnackbar("Unable to load funder segment options", "error");
        } finally {
          setLoadingPrograms(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    const programId = sessionStorage.getItem(SUPPORT_ADMIN_PROGRAM_KEY) || "none";
    setProgram(programId);
  }, []);

  function getProgramOptions(): SelectOption<string>[] {
    return programOptions.map((option) => {
      return {
        label: option.segment.substring(3).replace("-", " "),
        value: option.id,
      };
    });
  }

  return (
    <>
      {isLoadingPrograms ? (
        <LoadingCircle />
      ) : (
        <Select
          name="programSelect"
          value={program === "none" ? "" : program}
          onChange={(event: SelectChangeEvent<any>) => {
            const selectedProgram = event.target.value as string;
            setProgram(selectedProgram);
            sessionStorage.setItem(SUPPORT_ADMIN_PROGRAM_KEY, selectedProgram);
            if (searchTriggerFn) {
              searchTriggerFn(selectedProgram);
            }
          }}
          selectOptions={getProgramOptions()}
          placeholderText="Select Program"
        />
      )}
    </>
  );
};

export default DashboardNavbar;
