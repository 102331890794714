import { Box, BoxProps } from "@mui/material";
import CivColors from "src/themes/civilization/CivColors";

const PortalCard = (props: BoxProps) => (
  <Box bgcolor={CivColors.white} px="32px" py="24px" my="24px" {...props}>
    {props.children}
  </Box>
);

export default PortalCard;
