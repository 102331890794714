import { Box, IconButton } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { AgencyProviderListing_DTO_Response_ListView } from "src/generated/api_types";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { ExportMenuOption } from "src/reusable_view_elements/datatable/Datatable";
import Tooltip from "src/reusable_view_elements/Tooltip";
import { useCarinaAdminProvidersDatatable } from "./CarinaAdminProviderDashboard";

/*********************************************************
 ************* Start Column Helper Functions *************
 *********************************************************/
export function getUpdatedDate(data: AgencyProviderListing_DTO_Response_ListView): Date {
  return data.updatedAt ? new Date(data.updatedAt) : new Date();
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getHasMaleProviders(data: AgencyProviderListing_DTO_Response_ListView): string {
  return data.hasMaleProviders ? "Yes" : "No";
}

function getLanguages(data: AgencyProviderListing_DTO_Response_ListView): string[] {
  return data.languages.map((language) => _.startCase(_.lowerCase(`${language.toString().replace(/_/g, " ")}`))).sort();
}

export function getAdminNotesText(data: AgencyProviderListing_DTO_Response_ListView): string {
  if (data.notes) {
    return `Notes on zip code, ${data.geoData?.zipCode}: ${data.notes}`;
  }
  return "";
}

/*********************************************************
 ******************* Column Definitions ******************
 *********************************************************/

const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<AgencyProviderListing_DTO_Response_ListView> } = {
  funder_name: {
    title: "Funder",
    field: "agency.funder.segment",
  },
  agency_name: {
    title: "Agency",
    field: "agency.name",
  },
  county: {
    title: "County",
    field: "geoData.county",
  },
  city: {
    title: "City",
    field: "geoData.city",
  },
  zip: {
    title: "Zip code",
    field: "geoData.zipCode",
  },

  num_providers: {
    title: "Available providers",
    field: "numProviders",
  },
  male_providers: {
    title: "Male providers available?",
    field: "hasMaleProviders",
    customExport: getHasMaleProviders,
    render: (data) => getHasMaleProviders(data),
    customFilterAndSearch: (term, data) => {
      return getHasMaleProviders(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
    },
  },

  language: {
    title: "Languages",
    field: "languages",
    customExport: (data) => getLanguages(data).join(", "),
    render: (data) => {
      return (
        <>
          {getLanguages(data).map((lang, index) => (
            <div key={index}>{lang}</div>
          ))}
        </>
      );
    },
    customFilterAndSearch: (term, data) => {
      return getLanguages(data).toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
    },
  },
  notes: {
    title: "Notes",
    field: "notes",
    customExport: getAdminNotesText,
    render: (data) => {
      if (data.notes) {
        return (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title={`Notes on zip code, ${data.geoData?.zipCode}: ${data.notes}`} data-testid="notes-icon">
              <IconButton size="large">
                <DescriptionRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
      return "";
    },
  },
  updated: {
    title: "Last updated",
    field: "updatedAt",
    customExport: (data) => formatDate(getUpdatedDate(data)),
    render: (data) => formatDate(getUpdatedDate(data)),
    customFilterAndSearch: (term, data) => formatDate(getUpdatedDate(data)).indexOf(term) !== -1,
    customSort: (data1, data2) => getUpdatedDate(data1).getTime() - getUpdatedDate(data2).getTime(),
    defaultSort: "desc",
  },
};

/*********************************************************
 ******************* Page Definition *********************
 *********************************************************/

export const CarinaAdminProvidersList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useCarinaAdminProvidersDatatable();

  return (
    <>
      <Helmet>
        <title>Carina | Internal Tools | Providers</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="carina-providers-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Agency Providers"
        options={{
          exportMenu: [ExportMenuOption.csv({ filename: "Agency Providers" })],
        }}
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching providers..." : "No provider records to display",
          },
        }}
      />
    </>
  );
};
