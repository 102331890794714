import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LocizeBackend from "i18next-locize-backend";
import { initializeLanguage, SourceLanguage, TargetLanguages } from "src/i18n/Languages";

const DefaultNS = "common.navigation";

/**
 * i18next@v22 introduces typesafe functions
 *
 * Because our workflow takes a Translation-first approach rather than a Dev-first approach, we need to
 * follow this snippet from https://github.com/i18next/i18next/pull/1853#issuecomment-1291606531
 * in response to https://github.com/i18next/react-i18next/issues/1559#issuecomment-1291889522
 */
declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: typeof DefaultNS;
    load: "currentOnly";
    returnNull: false;
    returnEmptyString: true;
    saveMissing: false;
  }
}

const LocizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

i18n
  .use(LocizeBackend)
  .use(initReactI18next)
  .init({
    // Config options: https://www.i18next.com/overview/configuration-options
    fallbackLng: SourceLanguage,
    supportedLngs: [SourceLanguage, ...TargetLanguages],
    load: "currentOnly", // 'currentOnly' loads only the set language; 'all' will load multiple codes
    lng: initializeLanguage(),
    defaultNS: DefaultNS, // Default namespace if nothing is passed to the translation function
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
    saveMissing: false, // Don't use in prod; Requires API key
    debug: false,
    react: {
      bindI18n: "languageChanged editorSaved", // Bind these events to trigger a re-render
      useSuspense: false,
    },
    backend: LocizeOptions,
    returnNull: false, // t function can return null, this behaviour is true by default
    returnEmptyString: true,
  });
