import { Box, ButtonProps as MButtonProps, Grid, GridProps as MGridProps, Link as MuiLink } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { nsChildcareProviderProfile, nsChildcareProviderProfileView, nsCommonCTAbtns } from "src/i18n/Namespaces";
import IconList, { IconListItem } from "src/pages/childcare/searchPages/components/IconListCC";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import CivColors from "src/themes/civilization/CivColors";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import {
  CalendarIcon,
  ChatTranslateIcon,
  TaskChecklistCheckIcon,
  PinLocation1Icon,
} from "src/assets/icons/StreamlineIcons";
import { toSentenceCase, toTitleCase } from "src/utilities/GeneralUtilities";
import { Button } from "src/reusable_view_elements/Button";

interface ChildCareProviderCardProps extends MGridProps {
  businessName: string;
  hasOpenings: boolean;
  description: string;
  personName: string;
  location: string;
  milesAway: number | null;
  schedule: Array<string>;
  languages: Array<string>;
  expertises: Array<string>;
  onButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  ButtonProps?: Omit<MButtonProps, "type">;
  funderKey: string;
}

function getBlogLink(funderKey: string): string {
  switch (funderKey) {
    case "CC Parents-WA-SEIU Local 925":
      return "https://carina.org/blog?p=How-to-WA-Child-Care-Check";
    case "CC Parents-CT-SEIU CSEA Local 2001":
      return "https://carina.org/blog?p=How-to-CT-Child-Care-License-Lookup";
    case "CC Parents-IL-SEIU HCII":
      return "https://carina.org/blog?p=How-to-IL-Day-Care-Compliance-Check";
    case "CC Parents-CA-SEIU Local 99":
    case "CC Parents-CA-SEIU Local 521":
    case "CC Parents-CA-AFSCME UDW":
      return "https://carina.org/blog?p=How-to-CA-Facility-Search";
    default:
      return "https://carina.org/blog?p=How-to-CA-Facility-Search";
  }
}

const ChildCareProviderCard = ({
  businessName,
  hasOpenings,
  description,
  personName,
  location,
  milesAway,
  schedule,
  languages,
  expertises,
  onButtonClick,
  ButtonProps,
  funderKey,
  ...restGridProps
}: ChildCareProviderCardProps) => {
  const { t, ready } = useTranslation([nsChildcareProviderProfile, nsChildcareProviderProfileView, nsCommonCTAbtns]);

  return !ready ? (
    <LoadingCircle />
  ) : (
    <Grid container {...restGridProps}>
      <Grid item xs={12} sm={6}>
        <Box bgcolor={CivColors.paleBlue} padding={3} width="100%" height="100%">
          <Grid container direction="column" justifyContent="space-between" style={{ height: "100%" }}>
            <Grid item>
              <SectionTitle paragraph style={{ overflowWrap: "anywhere" }}>
                {businessName}
              </SectionTitle>
              {hasOpenings && (
                <BodyTitle paragraph>{t("openings available", { ns: nsChildcareProviderProfile })}</BodyTitle>
              )}
              <Body paragraph style={{ overflowWrap: "anywhere" }}>
                {description}
              </Body>
            </Grid>
            <Grid item>
              <Body style={{ fontSize: "0.7rem" }}>
                {t("verify license info", { ns: nsChildcareProviderProfileView })}{" "}
                <MuiLink href={getBlogLink(funderKey)} target="_blank">
                  {t("read more here", { ns: nsChildcareProviderProfileView })}
                </MuiLink>
              </Body>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box bgcolor={CivColors.white} padding={3} width="100%" height="100%">
          <BodyTitle paragraph>{t("information", { ns: nsChildcareProviderProfileView })}</BodyTitle>
          <Body gutterBottom style={{ overflowWrap: "anywhere" }}>
            <b>{t("business owner", { ns: nsChildcareProviderProfile })}:</b> {personName}
          </Body>
          <IconList>
            <IconListItem
              icon={PinLocation1Icon}
              secondary={
                <Body>
                  {location}{" "}
                  {milesAway &&
                    `(${t("miles away", { ns: nsChildcareProviderProfile, distance: _.round(milesAway, 1) })})`}
                </Body>
              }
            />
            <IconListItem
              icon={CalendarIcon}
              secondary={
                <Body>
                  {schedule
                    .map(toTitleCase)
                    .map((option) => t(`${option.toLowerCase()}`, { ns: nsChildcareProviderProfile }))
                    .join(", ")}
                </Body>
              }
            />
            <IconListItem
              icon={ChatTranslateIcon}
              secondary={
                <Body>
                  {languages
                    .map(toTitleCase)
                    .map((option) => t(`${option.toLowerCase()}`, { ns: nsChildcareProviderProfile }))
                    .join(", ")}
                </Body>
              }
            />
            <Body gutterBottom style={{ marginTop: ".35em" }}>
              <b>{t("expertise skills", { ns: nsChildcareProviderProfile })}:</b>
            </Body>
            <IconListItem
              icon={TaskChecklistCheckIcon}
              secondary={
                <Body paragraph>
                  {expertises
                    .map(toSentenceCase)
                    .map((option) => t(`${option.toLowerCase()}`, { ns: nsChildcareProviderProfile }))
                    .join(" • ")}
                </Body>
              }
            />
          </IconList>

          <Button
            variant="contained"
            type="button"
            sx={{ margin: "0px auto", display: "flex" }}
            onClick={onButtonClick}
            {...ButtonProps}
          >
            {t("message", { ns: nsCommonCTAbtns })}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChildCareProviderCard;
