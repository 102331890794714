import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import { ReactElement } from "react";
import { generatePath } from "react-router-dom";
import {
  ActiveUserListDTO,
  BehaviorCategoryEnum,
  BehaviorEnum,
  ChildcareInviteListDTO,
  MedicaidInviteListDTO,
  ReportStatusEnum,
  UserReportListDTO,
} from "src/generated/api_types";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ActiveUserListActionMenuButton from "src/pages/supportAdminAccount/dashboard/components/ActiveUserListActionMenuButton";
import InviteListActionMenuButton from "src/pages/supportAdminAccount/dashboard/components/InviteListActionMenuButton";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { ServersideColumn } from "src/reusable_view_elements/datatable/ServersideDatatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import Tooltip from "src/reusable_view_elements/Tooltip";
import { Body } from "src/reusable_view_elements/Typography";
import { applyMMddyyyyFormat, applyPhoneFormat, applyYyyyMMddFormat } from "src/utilities/DashboardUtilities";

export interface ActiveUserColumn extends ServersideColumn<ActiveUserListDTO> {}

export const ACTIVE_USERS_COLUMN_DEFINITIONS: { [k: string]: ActiveUserColumn } = {
  firstName: {
    title: "First Name",
    render: (data) => (
      <InternalLink
        id={data.id}
        to={
          data &&
          generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.user_details, {
            id: data.id,
          })
        }
      >
        <Body>{data.firstName}</Body>
      </InternalLink>
    ),
    sortingField: "firstName",
    filteringField: "firstName",
    field: "firstName",
  },
  lastName: {
    title: "Last Name",
    render: (data) => <Body>{data.lastName}</Body>,
    sortingField: "lastName",
    filteringField: "lastName",
    field: "lastName",
  },
  email: {
    title: "Email",
    sortingField: "email",
    render: (data) => <Body>{data.email}</Body>,
    filteringField: "email",
    field: "email",
  },
  smsPhone: {
    title: "Phone",
    render: (data) => <Body>{data.smsPhone && applyPhoneFormat(data.smsPhone)}</Body>,
    sortingField: "smsPhone",
    filteringField: "smsPhone",
    field: "smsPhone",
  },
  role: {
    title: "Role",
    render: (data) => <Body>{data.role}</Body>,
    sortingField: "dtype", // for ServerSideDatatable this name should correspond with the field's name in the BE
    filteringField: "dtype", // for ServerSideDatatable this name should correspond with the field's name in the BE
    field: "role",
  },
  unread: {
    title: "# of unread messages",
    render: (data) => <Body>{data.totalUnreadMessages}</Body>,
    sortingField: "totalUnreadMessages",
    filteringField: "totalUnreadMessages",
    field: "totalUnreadMessages",
  },
  status: {
    title: "Status",
    render: (data) => <Body>{data.status == "ACTIVE" ? "Active" : "Onboarding"}</Body>,
    sortingField: "status",
    filteringField: "status",
    field: "status",
  },
  lastLoginDate: {
    title: "Last Login",
    exportTransformer: (data) => applyMMddyyyyFormat(data.lastLoginDate),
    render: (data) => <Body>{applyMMddyyyyFormat(data.lastLoginDate)}</Body>,
    sortingField: "lastLoginDate",
    // Date parsing fails during API search across columns, so removing filteringField as an option as date is not parsing
    field: "lastLoginDate",
  },
  action: {
    title: "Action",
    render: (data) => {
      return (
        <ActiveUserListActionMenuButton
          data={data}
          callingPageUrl={SUPPORT_ADMIN_DASHBOARD_ROUTES.active_user}
          dashboardType={DashboardType.MEDICAID}
        />
      );
    },
  },
};

export const categoryLabels: { [c in BehaviorCategoryEnum]: string } = {
  UNPROFESSIONAL: "Unprofessional",
  OFFENSIVE_HOSTILE: "Offensive/Hostile",
  ABUSIVE_BEHAVIOR: "Abusive",
  SOMETHING_ELSE: "Something Else",
};

export const behaviorLabels: { [b in BehaviorEnum]: string } = {
  NON_RESPONSIVE: "Not responding",
  NO_SHOW: "Failure to show up",
  CURSING: "Use of curse words",
  NEGATIVE_EXPERIENCE: "Negative experience",
  OTHER_UNPROFESSIONAL: "Other",
  SEXUALLY_SUGGESTIVE: "Sexually suggestive",
  DISCRIMINATORY: "Discriminatory",
  OTHER_HOSTILE: "Other",
  ADULT_ABUSE_NEGLECT: "Abuse/neglect",
  CHILD_ABUSE_NEGLECT: "Abuse/neglect",
  VIOLENCE: "Violence physical",
  OTHER_ABUSIVE: "Other",
  SOMETHING_ELSE: "Something else",
};

const statusLabels: { [s in ReportStatusEnum]: string } = {
  RESOLVED: "Resolved",
  PENDING: "Pending",
  UNPROCESSED: "Unprocessed",
};

export interface ReportColumn extends ServersideColumn<UserReportListDTO> {}

export const REPORTED_CASES_COLUMN_DEFINITIONS: { [k: string]: ReportColumn } = {
  case_number: {
    title: "Case #",
    field: "caseNumber",
    exportTransformer: (data) => data.caseNumber,
    render: (data) => (
      <InternalLink
        id={data.id}
        to={
          data &&
          generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_details, {
            id: data.id,
          })
        }
      >
        <Body>{data.caseNumber}</Body>
      </InternalLink>
    ),
    sortingField: "caseNumber",
    // Date parsing fails during API search across columns, so removing filteringField as an option as date is not parsing
    defaultSort: "asc",
  },
  submitted: {
    title: "Submitted",
    field: "submittedDate",
    sortingField: "createdAt",
    filteringField: "createdAt",
    exportTransformer: (data) => applyYyyyMMddFormat(data.submittedDate),
    render: (data) => `${applyYyyyMMddFormat(data.submittedDate)}`,
  },
  category: {
    title: "Category",
    field: "category",
    sortingField: "behavior",
    filteringField: ["behaviorCategory", "behavior"],
    customExport: (data) => `${categoryLabels[data.category]} > ${behaviorLabels[data.behavior]}`,
    render: (data) => `${categoryLabels[data.category]} > ${behaviorLabels[data.behavior]}`,
  },
  offender: {
    title: "Offender",
    sortingField: "reportedUser.firstName",
    filteringField: ["reportedUser.firstName", "reportedUser.lastName"],
    field: "offender",
  },
  reporter: {
    title: "Reporter",
    sortingField: "reportingUser.firstName",
    filteringField: ["reportingUser.firstName", "reportingUser.lastName"],
    field: "reporter",
  },
  status: {
    title: "Status",
    field: "status",
    sortingField: "reportStatus",
    filteringField: "reportStatus",
    exportTransformer: (data) => statusLabels[data.status],
    render: (data) => `${statusLabels[data.status]}`,
  },
  updatedDate: {
    title: "Updated",
    field: "updatedDate",
    sortingField: "updatedAt",
    // Date parsing fails during API search across columns, so removing filteringField as an option as date is not parsing
    exportTransformer: (data) => applyYyyyMMddFormat(data.updatedDate),
    render: (data) => `${applyYyyyMMddFormat(data.updatedDate)}`,
  },
  notes: {
    title: "Notes",
    field: "notes",
    render: (data) => getNotesTooltip(data.notes),
  },
};

function getNotesTooltip(notes: string): ReactElement | string {
  if (notes) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Tooltip title={notes} data-testid="notes-icon">
          <IconButton size="large">
            <DescriptionRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
  return "";
}

function getInviteStatusLabelText(data: ChildcareInviteListDTO | MedicaidInviteListDTO) {
  if (data.expiresAt < new Date().getTime()) return "Expired";
  return _.startCase(_.camelCase(data.status.toString()));
}

function getInviteStatusLabel(data: ChildcareInviteListDTO | MedicaidInviteListDTO) {
  if (data.expiresAt < new Date().getTime()) return <Body>Expired</Body>;
  return <Body>{_.startCase(_.camelCase(data.status.toString()))}</Body>;
}

export interface InvitedUsersColumn extends ServersideColumn<MedicaidInviteListDTO> {}

export const INVITED_USERS_COLUMN_DEFINITIONS: {
  [k: string]: InvitedUsersColumn;
} = {
  firstName: {
    title: "First Name",
    render: (data) => (
      <InternalLink
        id={data.token}
        to={
          data &&
          generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.invite_details, {
            id: data.token,
          })
        }
      >
        <Body>{data.firstName}</Body>
      </InternalLink>
    ),
    sortingField: "firstName",
    filteringField: "firstName",
    field: "firstName",
  },
  lastName: {
    title: "Last Name",
    render: (data) => <Body>{data.lastName}</Body>,

    sortingField: "lastName",
    filteringField: "lastName",
    field: "lastName",
  },
  email: {
    title: "Email",
    sortingField: "email",
    render: (data) => <Body>{data.email}</Body>,
    filteringField: "email",
    field: "email",
  },
  role: {
    title: "Role",
    exportTransformer: (data) => _.startCase(_.camelCase(data.role.toString())),
    render: (data) => <Body>{_.startCase(_.camelCase(data.role.toString()))}</Body>,
    sortingField: "role",
    filteringField: "role",
    field: "role",
  },
  status: {
    title: "Status",
    field: "status",
    exportTransformer: getInviteStatusLabelText,
    render: (data) => getInviteStatusLabel(data),
  },
  action: {
    title: "Action",
    render: (data) => {
      return <InviteListActionMenuButton data={data} />;
    },
  },
};

/***********************************************************************************************************
 *************************** COLUMN DEFINITIONS FOR CHILDCARE ************************************************
 ***********************************************************************************************************/

export function getCityAndState(data: ActiveUserListDTO): string {
  return `${data.city}, ${data.state}`;
}

export const CC_ACTIVE_USERS_COLUMN_DEFINITIONS: { [k: string]: ActiveUserColumn } = {
  license: {
    title: "License",
    render: (data) => (
      <InternalLink
        id={data.id}
        to={
          data &&
          generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_user_details, {
            id: data.id,
          })
        }
      >
        <Body>{data.license}</Body>
      </InternalLink>
    ),
    field: "license",
  },
  firstName: {
    title: "First Name",
    sortingField: "firstName",
    render: (data) => <Body>{data.firstName}</Body>,
    filteringField: "firstName",
    field: "firstName",
  },
  lastName: {
    title: "Last Name",
    sortingField: "lastName",
    render: (data) => <Body>{data.lastName}</Body>,
    filteringField: "lastName",
    field: "lastName",
  },
  email: {
    title: "Email",
    sortingField: "email",
    render: (data) => <Body>{data.email}</Body>,
    filteringField: "email",
    field: "email",
  },
  smsPhone: {
    title: "Phone",
    sortingField: "smsPhone",
    exportTransformer: (data) => (data.smsPhone ? applyPhoneFormat(data.smsPhone) : ""),
    render: (data) => <Body>{data.smsPhone && applyPhoneFormat(data.smsPhone)}</Body>,
    filteringField: "smsPhone",
    field: "smsPhone",
  },
  cityState: {
    title: "City, State",
    customExport: (data) => getCityAndState(data),
    render: (data) => <Body>{getCityAndState(data)}</Body>,
    field: "cityState",
  },
  zipCode: {
    title: "ZIP code",
    render: (data) => <Body>{data.zipCode}</Body>,
    field: "zipCode",
  },
  contacts: {
    title: "Contacts",
    render: (data) => <Body>{data.contacts}</Body>,
    field: "contacts",
  },
  openSlots: {
    title: "Open Slots",
    exportTransformer: (data) => (data.openSlots ? "Yes" : "No"),
    render: (data) => <Body>{data.openSlots ? "Yes" : "No"}</Body>,
    field: "openSlots",
  },
  action: {
    title: "Action",
    render: (data) => {
      return (
        <ActiveUserListActionMenuButton
          data={data}
          callingPageUrl={SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_active_user}
          dashboardType={DashboardType.CHILDCARE}
        />
      );
    },
  },
};

export interface ChildcareInvitedUsersColumn extends ServersideColumn<ChildcareInviteListDTO> {}

export const CC_INVITED_USERS_COLUMN_DEFINITIONS: {
  [k: string]: ChildcareInvitedUsersColumn;
} = {
  license: {
    title: "License",
    render: (data) => (
      <InternalLink
        id={data.token}
        to={
          data &&
          generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_invite_details, {
            id: data.token,
          })
        }
      >
        <Body>{data.license}</Body>
      </InternalLink>
    ),

    sortingField: "license",
    filteringField: "license",
    field: "license",
  },
  firstName: {
    title: "First Name",
    render: (data) => <Body>{data.firstName}</Body>,

    sortingField: "firstName",
    filteringField: "firstName",
    field: "firstName",
  },
  lastName: {
    title: "Last Name",
    render: (data) => <Body>{data.lastName}</Body>,

    sortingField: "lastName",
    filteringField: "lastName",
    field: "lastName",
  },
  email: {
    title: "Email",
    sortingField: "email",
    render: (data) => <Body>{data.email}</Body>,
    filteringField: "email",
    field: "email",
  },
  status: {
    title: "Status",
    customExport: getInviteStatusLabelText,
    render: (data) => getInviteStatusLabel(data),
    field: "status",
  },
};
