import { Route, Switch } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO } from "src/generated/api_types";
import { requestPasswordReset } from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { CarinaAdminFunderAdminDetails } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/CarinaAdminFunderAdminDetails";
import { CarinaAdminFundersList } from "src/pages/carinaAdmin/dashboard/carina_admin_funders/CarinaAdminFundersList";
import {
  getAllFunderAdmins as getFunderAdminsType,
  getFunderAdmin,
} from "src/pages/carinaAdmin/dashboard/carina_admin_funders/Queries";
import {
  CarinaAdminDashboardContainer,
  CarinaAdminDashboardTabs,
} from "src/pages/carinaAdmin/dashboard/carina_admin_dashboard_components/CarinaAdminDashboardContainer";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useCarinaAdminFundersDatatable,
  ClientsideDatatableContextProvider: CarinaAdminFundersDatatableContextProvider,
} = GenerateClientsideDatatable<
  PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO,
  typeof getFunderAdminsType
>();

interface CarinaAdminFunderDashboardContainerProps {
  getAllFunderAdmins: typeof getFunderAdminsType;
}

export const CarinaAdminFunderDashboard: React.FC<CarinaAdminFunderDashboardContainerProps> = ({
  getAllFunderAdmins,
}) => {
  const { isUserType } = useUserSession();

  return (
    <CarinaAdminDashboardContainer tab={CarinaAdminDashboardTabs.FUNDERS}>
      <CarinaAdminFundersDatatableContextProvider getData={getAllFunderAdmins}>
        <Switch>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.funder_list
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.funder_list
            }
          >
            <CarinaAdminFundersList />
          </Route>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.funder_details
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.funder_details
            }
          >
            <CarinaAdminFunderAdminDetails
              getFunderAdmin={getFunderAdmin}
              requestPasswordReset={requestPasswordReset}
            />
          </Route>
        </Switch>
      </CarinaAdminFundersDatatableContextProvider>
    </CarinaAdminDashboardContainer>
  );
};
