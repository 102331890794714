import {
  AxiosChildcareControllerClient,
  AxiosChildcareMessagingControllerClient,
  AxiosChildcareProviderProfileControllerClient,
  AxiosChildcareProviderProfileSearchControllerClient,
} from "src/generated/api_types";

export const ChildcareControllerClient = new AxiosChildcareControllerClient();
export const ChildcareMessagingControllerClient = new AxiosChildcareMessagingControllerClient();
export const ChildcareSearchControllerClient = new AxiosChildcareProviderProfileSearchControllerClient();
export const ChildcareProviderProfileControllerClient = new AxiosChildcareProviderProfileControllerClient();
