import { Box, Grid, List, ListItem, ListItemTextProps, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { FunderState, UserType, useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsMedicaidJobSurveyConfirmation } from "src/i18n/Namespaces";
import { Circle1FilledIcon, Circle2FilledIcon, Circle3FilledIcon } from "src/assets/icons/CustomIcons";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import SimpleThreadSurveyConfirmation from "src/pages/inbox/matchingSurveyComponents/SimpleThreadSurveyConfirmation";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { ExternalLink, InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

export const IconList = styled(List)(() => ({
  padding: 0,
  margin: 0,
}));

interface IconListItemProps extends ListItemTextProps {
  icon: React.ComponentType<SvgIconProps>;
}

export const IconListItem = ({ icon: Component, primary, secondary }: IconListItemProps) => {
  const style = {
    item: {
      padding: "12px 0px",
    },
    icon: {
      width: 46,
      height: 46,
      marginRight: 25,
    },
  };
  return (
    <ListItem alignItems="flex-start" style={style.item}>
      <Component style={style.icon} />
      <div>
        {primary}
        {secondary}
      </div>
    </ListItem>
  );
};

interface ThreadSurveyMatchedConfirmationProps {
  userGotJob: boolean;
  threadId: string;
}

const ThreadSurveyMatchedConfirmation = (props: ThreadSurveyMatchedConfirmationProps) => {
  const { userSession, isUserType, isFunderState } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobSurveyConfirmation, nsCommonFormsBtns]);
  const showCongratsOnFindingJob = props.userGotJob;
  const showCongratsOnFindingProvider = !showCongratsOnFindingJob;

  if (isUserType(UserType.MedicaidCaseManager) || isUserType(UserType.MedicaidReferralCoordinator)) {
    return <SimpleThreadSurveyConfirmation threadId={props.threadId} />;
  }

  if (!ready) {
    return (
      <>
        <Section minimizeBottomGutter minHeight="100vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  return (
    <>
      <Section textAlign="center">
        <Constraint columns={8}>
          <SectionTitle>{t("received_your_response.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
          <Body paragraph>{t("received_your_response.description", { ns: nsMedicaidJobSurveyConfirmation })}</Body>
          <Grid container>
            <Grid item xs={12}>
              <LinkStyledAsButton
                variant="outlined"
                component={Link}
                to={generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: props.threadId })}
              >
                {t("button.return_to_thread", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
          </Grid>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.paleBlue} textAlign="center">
        <Constraint columns={8}>
          <SectionTitle sx={{ marginBottom: "48px" }}>
            {t("next_steps.label", { ns: nsMedicaidJobSurveyConfirmation })}
          </SectionTitle>
          <IconList>
            <IconListItem
              icon={Circle1FilledIcon}
              primary={
                <BodyTitle>
                  {showCongratsOnFindingProvider &&
                    isFunderState(FunderState.Washington) &&
                    t("check_with_cdwa.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingProvider &&
                    isFunderState(FunderState.Oregon) &&
                    t("check_with_case_manager.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingJob &&
                    isFunderState(FunderState.Washington) &&
                    t("wait_for_cdwa_ok.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingJob &&
                    isFunderState(FunderState.Oregon) &&
                    t("wait_for_case_manager_auth_ok.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Body style={{ margin: "20px 0px" }}>
                  {showCongratsOnFindingProvider &&
                    isFunderState(FunderState.Washington) &&
                    t("check_with_cdwa.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingProvider &&
                    isFunderState(FunderState.Oregon) &&
                    t("check_with_case_manager.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingJob &&
                    isFunderState(FunderState.Washington) &&
                    t("wait_for_cdwa_ok.description", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingJob &&
                    isFunderState(FunderState.Oregon) &&
                    t("wait_for_case_manager_auth_ok.description", { ns: nsMedicaidJobSurveyConfirmation })}
                </Body>
              }
            />
            <IconListItem
              icon={Circle2FilledIcon}
              primary={
                <BodyTitle>
                  {showCongratsOnFindingProvider &&
                    t("update_or_delete_job.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingJob &&
                    t("update_profile_as_needed.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Box style={{ margin: "20px 0px" }}>
                  {showCongratsOnFindingProvider && (
                    <>
                      <Body paragraph>
                        {t("update_or_delete_job.description", { ns: nsMedicaidJobSurveyConfirmation })}
                      </Body>
                      <InternalLink to={getMedicaidRouteByUserType("myJobList", isUserType)}>
                        <Body>{t("button.review_all_your_job_posts", { ns: nsCommonFormsBtns })}</Body>
                      </InternalLink>
                    </>
                  )}
                  {showCongratsOnFindingJob && (
                    <>
                      <Body paragraph>
                        {t("update_profile_as_needed.description", { ns: nsMedicaidJobSurveyConfirmation })}
                      </Body>
                      <InternalLink
                        to={generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
                          id: userSession?.id,
                        })}
                      >
                        <Body>{t("button.review_your_profile", { ns: nsCommonFormsBtns })}</Body>
                      </InternalLink>
                    </>
                  )}
                </Box>
              }
            />
            <IconListItem
              icon={Circle3FilledIcon}
              primary={
                <BodyTitle>
                  {showCongratsOnFindingProvider &&
                    isFunderState(FunderState.Washington) &&
                    t("review_consumer_guide.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingProvider &&
                    isFunderState(FunderState.Oregon) &&
                    t("reach_out_to_erc.label", { ns: nsMedicaidJobSurveyConfirmation })}
                  {showCongratsOnFindingJob &&
                    t("review_provider_guide.label", { ns: nsMedicaidJobSurveyConfirmation })}
                </BodyTitle>
              }
              secondary={
                <Box style={{ margin: "20px 0px" }}>
                  {showCongratsOnFindingProvider && isFunderState(FunderState.Washington) && (
                    <Body>
                      <Trans
                        t={t}
                        i18nKey="review_consumer_guide.description.more_cdwa_details"
                        ns={nsMedicaidJobSurveyConfirmation}
                        components={{
                          cdwacr: (
                            <ExternalLink to="https://www.consumerdirectwa.com/client-resources/" target="_blank" />
                          ),
                        }}
                      />
                    </Body>
                  )}
                  {showCongratsOnFindingProvider && isFunderState(FunderState.Oregon) && (
                    <>
                      <Body paragraph>
                        {t("reach_out_to_erc.description.erc_is_free", { ns: nsMedicaidJobSurveyConfirmation })}
                      </Body>
                      <ExternalLink
                        to="https://www.oregon.gov/odhs/home-care-consumers/pages/employer-resource-connection.aspx"
                        target="_blank"
                      >
                        <Body paragraph>
                          {t("reach_out_to_erc.description.read_about_erc", { ns: nsMedicaidJobSurveyConfirmation })}
                        </Body>
                      </ExternalLink>
                    </>
                  )}
                  {showCongratsOnFindingJob && isFunderState(FunderState.Washington) && (
                    <Body>
                      <Trans
                        t={t}
                        i18nKey="review_provider_guide.description.more_cdwa_details"
                        ns={nsMedicaidJobSurveyConfirmation}
                        components={{
                          cdwagr: <ExternalLink to="https://www.consumerdirectwa.com/ip-resources/" target="_blank" />,
                        }}
                      />
                    </Body>
                  )}
                  {showCongratsOnFindingJob && isFunderState(FunderState.Oregon) && (
                    <Body>
                      <Trans
                        t={t}
                        i18nKey="review_provider_guide.description.odhs_resource"
                        ns={nsMedicaidJobSurveyConfirmation}
                        components={{
                          odhs: (
                            <ExternalLink
                              to="https://www.oregon.gov/odhs/providers-partners/homecare-workforce/pages/default.aspx"
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    </Body>
                  )}
                </Box>
              }
            />
          </IconList>
        </Constraint>
      </Section>
    </>
  );
};

export default ThreadSurveyMatchedConfirmation;
