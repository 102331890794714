import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { RegistrationStepEnum } from "src/generated/api_types";
import { getLocalizedRegStep } from "src/i18n/Utilities";
import { nsMedicaidBEContent, nsMedicaidRegRoleSelection } from "src/i18n/Namespaces";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { BasicNavBar } from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import BasicStepper from "src/reusable_view_elements/steppers/BasicStepper";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import LanguageButton from "src/reusable_view_elements/navbars/navbar_elements/LanguageButton";

interface RegistrationContainerProps {
  hideStepper?: boolean;
  currentStep: RegistrationStepEnum;
}

export const RegistrationPageContainer: React.FC<RegistrationContainerProps> = ({
  hideStepper,
  currentStep,
  children,
}) => {
  const { regPath } = useRegistrationContext();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsMedicaidRegRoleSelection, nsMedicaidBEContent]);

  return (
    <>
      <Helmet>
        <title>Carina | Medicaid Register</title>
      </Helmet>

      <BasicNavBar right={<LanguageButton menuType={desktopSize ? "desktop" : "mobile"} />} />
      <main id="main-content">
        {!hideStepper && (
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
            <Constraint columns={6}>
              {ready && (
                <BasicStepper
                  mobileStyle={!desktopSize}
                  activeStep={regPath ? regPath!!.registrationSteps.findIndex((r) => r.step === currentStep) : 0}
                  steps={
                    regPath
                      ? regPath!!.registrationSteps.map((r) => getLocalizedRegStep(r.displayName, t))
                      : [
                          getLocalizedRegStep("Role Selection", t),
                          getLocalizedRegStep("Verification", t),
                          getLocalizedRegStep("Account Creation", t),
                        ]
                  }
                />
              )}
            </Constraint>
          </Section>
        )}
        {children!}
      </main>
      <Footer />
    </>
  );
};

RegistrationPageContainer.defaultProps = {
  hideStepper: false,
};
