import { CircularProgress, Link as MuiLink } from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Illustration from "src/assets/images/emans-about.svg";
import { useUserSession } from "src/context/UserSessionContext";
import { RoleType } from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidCompleteOnboarding } from "src/i18n/Namespaces";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import { onboardingClient } from "src/pages/onboarding/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

type CopyKeyType = keyof typeof RoleType | "ERROR";

type CopyType = {
  [k in CopyKeyType]?: any;
};

const CompleteOnboarding = () => {
  const { reloadAuthState } = useUserSession();
  const { onboardingState } = useOnboardingContext();
  const userType = onboardingState?.roleDTO.roleType;
  const [isLoading, setLoading] = useState(false);
  const { t, ready } = useTranslation([nsMedicaidCompleteOnboarding, nsCommonFormsBtns]);

  // Handle edge cases where user is a support role or is blank
  if (!userType) {
    return (
      <>
        <Section bgcolor={CivColors.white} textAlign="center" height="100vh">
          <Constraint columns={6}>
            <SectionTitle>{t("error.generic.something_went_wrong", { ns: nsMedicaidCompleteOnboarding })}</SectionTitle>
          </Constraint>
        </Section>
      </>
    );
  }

  const [copyKey, setCopyKey] = useState<CopyKeyType>(userType);
  const copy: CopyType = {
    [RoleType.PROVIDER]: {
      title: t("you_are_ready_for_jobs.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("you_are_ready_for_jobs.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.start_looking_jobs", { ns: nsCommonFormsBtns }),
    },
    [RoleType.CONSUMER]: {
      title: t("you_ready_for_home_care.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("you_ready_for_home_care.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.start_looking_home_care", { ns: nsCommonFormsBtns }),
    },
    [RoleType.CASE_WORKER]: {
      title: t("your_account_is_set_up.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("your_account_is_set_up_view_jobs_providers.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.access_account", { ns: nsCommonFormsBtns }),
    },
    [RoleType.MEDICAID_REFERRAL_COORDINATOR]: {
      title: t("your_account_is_set_up.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("your_account_is_set_up_view_jobs_providers.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.access_account", { ns: nsCommonFormsBtns }),
    },
    [RoleType.MEDICAID_CASE_MANAGER]: {
      title: t("your_account_is_set_up.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("your_account_is_set_up_view_jobs_providers.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.access_account", { ns: nsCommonFormsBtns }),
    },
    [RoleType.USER_SUPPORT_AGENT]: {
      title: t("your_account_is_set_up.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("now_that_youve_set_up.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.access_account", { ns: nsCommonFormsBtns }),
    },
    [RoleType.CHILDCARE_PROVIDER]: {
      title: t("your_account_is_set_up.label", { ns: nsMedicaidCompleteOnboarding }),
      body: t("now_that_youve_set_up.description", { ns: nsMedicaidCompleteOnboarding }),
      button: t("button.access_account", { ns: nsCommonFormsBtns }),
    },
    ERROR: {
      title: t("cannot_finalize_account.label", { ns: nsMedicaidCompleteOnboarding }),
      body: (
        <>
          <Trans
            t={t}
            i18nKey="cannot_finalize.description.email_us"
            ns={nsMedicaidCompleteOnboarding}
            components={{ cntctemail: <MuiLink href="mailto:contactus@carina.org" /> }}
          />
          <br />
          <br />
          <Body>{t("cannot_finalize.description.apologies", { ns: nsMedicaidCompleteOnboarding })}</Body>
        </>
      ),
    },
  };

  if (!ready) {
    return (
      <>
        <Section bgcolor={CivColors.white} minimizeBottomGutter minHeight="100vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await onboardingClient.completeProcess();
      setLoading(false);
      reloadAuthState();
    } catch (e) {
      setCopyKey("ERROR");
      setLoading(false);
    }
  };

  return (
    <>
      <Section bgcolor={CivColors.white} textAlign="center">
        <Constraint columns={6}>
          <SectionTitle>{copy[copyKey].title}</SectionTitle>
          <Body paragraph>{copy[copyKey].body}</Body>
          {isLoading && <CircularProgress color="secondary" />}
          {!isLoading && copyKey !== "ERROR" && (
            <Button variant="contained" type="button" onClick={handleSubmit}>
              {copy[userType].button}
            </Button>
          )}
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.white} textAlign="center">
        <Constraint columns={6}>
          <img src={Illustration} alt="" width="100%" />
        </Constraint>
      </Section>
    </>
  );
};

export default CompleteOnboarding;
