import { PropsWithChildren, useState } from "react";
import { Funder_DTO_Response_Listing } from "src/generated/api_types";
import { createContainer } from "unstated-next";

interface InitialConfig {
  program?: string;
}

function supportAdminContext(initialState?: InitialConfig) {
  // Querying dashboard data is dependent on the program the manager has selected or what the agent belongs to
  const [program, setProgram] = useState<string>(initialState?.program || "none");

  const [programOptions, setProgramOptions] = useState<Funder_DTO_Response_Listing[]>([]);

  const [isLoadingPrograms, setLoadingPrograms] = useState<boolean>(false);

  return {
    program,
    setProgram,
    programOptions,
    setProgramOptions,
    isLoadingPrograms,
    setLoadingPrograms,
  };
}

const SupportAdminContext = createContainer(supportAdminContext);

export const SupportAdminContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<{
  initialState?: InitialConfig;
}>) => <SupportAdminContext.Provider initialState={initialState}>{children}</SupportAdminContext.Provider>;

export const useSupportAdminContext = () => SupportAdminContext.useContainer();
