import { Box } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { AxiosCarinaAdminUtilityControllerClient } from "src/generated/api_types";
import {
  CarinaAdminDashboardContainer,
  CarinaAdminDashboardTabs,
} from "src/pages/carinaAdmin/dashboard/carina_admin_dashboard_components/CarinaAdminDashboardContainer";
import Constraint from "src/reusable_view_elements/Constraint";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormAddressField } from "src/reusable_view_elements/form_fields/address_autocomplete/FormAddressField";
import { Button } from "src/reusable_view_elements/Button";

const geocodingClient = new AxiosCarinaAdminUtilityControllerClient();

export const GeocodingPage: React.FC = () => {
  const [geoDataId, setGeoDataId] = useState("");

  return (
    <CarinaAdminDashboardContainer tab={CarinaAdminDashboardTabs.AGENCIES}>
      <Formik
        initialValues={{
          address: "",
        }}
        onSubmit={async (formikValues) => {
          setGeoDataId((await geocodingClient.geocode(formikValues.address)).data || "");
        }}
      >
        {({ isSubmitting }) => (
          <Form localizationReady={true}>
            <Box p={3} />
            <Constraint columns={6}>
              <div>{geoDataId}</div>
              <FormAddressField name="address" placeholder="Address to geocode" />
              <Button variant="outlined" type="submit" disabled={isSubmitting}>
                Geocode Address
              </Button>
            </Constraint>
          </Form>
        )}
      </Formik>
    </CarinaAdminDashboardContainer>
  );
};
