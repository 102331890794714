import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { nsCommonTooltips } from "src/i18n/Namespaces";
import Popover from "src/reusable_view_elements/Popover";
import { Body } from "../Typography";

const LgbtPopover = (props: PropsWithChildren<{}>) => {
  const { t } = useTranslation(nsCommonTooltips);

  return (
    <Popover
      contentTitle={t("popover.lgbti.label", { ns: nsCommonTooltips })}
      content={<Body>{t("popover.lgbti.description", { ns: nsCommonTooltips })}</Body>}
      contentId="lgbt-popover"
    >
      {props.children}
    </Popover>
  );
};

export default LgbtPopover;
