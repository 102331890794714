import { Box, Grid, GridProps, Hidden, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

// Helps center the content in grid
const FakeIcon = styled(Box)({
  width: 24,
  height: 24,
});

const iconStyle = {
  margin: -12, // Counteract padding
  padding: 3, // Make button and ripple size more dense
};

interface Props extends GridProps {
  onClose: () => void;
  backgroundColor?: keyof typeof CivColors;
}

const Banner = ({ onClose, backgroundColor, ...props }: Props) => {
  const Container = styled(Grid)({
    background: backgroundColor || CivColors.coreOrange,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 24,
    paddingRight: 24,
  });

  return (
    <Container container justifyContent="space-between" alignItems="center" wrap="nowrap" {...props}>
      <Hidden smDown>
        <FakeIcon />
      </Hidden>
      <Grid item>
        <Body component="span" style={{ color: CivColors.white }}>
          {props.children}
        </Body>
      </Grid>
      <Grid item>
        <IconButton aria-label="close banner" style={iconStyle} onClick={onClose} size="large">
          <CloseRoundedIcon htmlColor={CivColors.white} />
        </IconButton>
      </Grid>
    </Container>
  );
};

export default Banner;
