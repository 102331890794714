import { Box, Grid, Link as MuiLink, useMediaQuery } from "@mui/material";
import { Field, Formik } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { FunderState, UserType, useUserSession } from "src/context/UserSessionContext";
import {
  RegistrationController_VerifyCredentialsDTO,
  VerificationParameterType,
  VerificationStatusEnum,
} from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidJobPost,
  nsMedicaidRegVerification,
} from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { Medicaid_Case_Manager_Routes } from "src/pages/medicaidAccount/consumerAccount/CaseManagerAccount/CaseManagerRouter";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { Medicaid_Proxy_Provider_Routes } from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";
import { ORVerificationFormFields } from "src/pages/registration/verification/OR-Consumer-Components";
import { preVerifyConsumerCreds } from "src/pages/registration/verification/Queries";
import {
  useVerificationSchemaConsumerWA,
  WAConsumerVerificationFormFields,
} from "src/pages/registration/verification/WA-Consumer-Components";
import Alert from "src/reusable_view_elements/alert/Alert";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Constraint from "src/reusable_view_elements/Constraint";
import DashSection from "src/reusable_view_elements/DashSection";
import Footer from "src/reusable_view_elements/Footer";
import { FormRadioGroupField, FormTextField } from "src/reusable_view_elements/form_fields";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { scrollToId } from "src/utilities/ScrollToId";
import { object, string } from "yup";

export const PreVerifyJobPost = () => {
  const { showSnackbar } = useNotification();
  const [credentialsError, setCredentialsError] = useState<boolean>(false);
  const { setConsumerFirstNameFromCoordinator } = useMedicaidContext();
  const history = useHistory();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { id } = useParams<{ id: string | undefined }>();
  const { isUserType, isFunderState } = useUserSession();
  const { VerificationSchemaConsumerWA } = useVerificationSchemaConsumerWA();
  const { t, ready } = useTranslation([
    nsMedicaidJobPost,
    nsCommonFormsBtns,
    nsCommonTooltips,
    nsMedicaidRegVerification,
  ]);

  let routePrefix: { jobCreate: string; jobRepost: string; myJobList: string } = Medicaid_Coordinator_Dashboard_Routes;
  if (isUserType(UserType.MedicaidCaseManager)) {
    routePrefix = Medicaid_Case_Manager_Routes;
  } else if (isUserType(UserType.ProxyProvider)) {
    routePrefix = Medicaid_Proxy_Provider_Routes;
  }

  // the validation schema for WA consumer is the same as organic registration, but the OR one is different since
  // it's a different verification method field use for coordinator-type consumer verification
  const VerificationSchemaConsumerOR = object().shape({
    firstName: string()
      .label(t("field.first_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.first_name.error.provide", { ns: nsCommonFormsBtns })),
    lastName: string()
      .label(t("field.last_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.last_name.error.provide", { ns: nsCommonFormsBtns })),
    prid: string()
      .label(t("field.prime_identification_number.label", { ns: nsCommonFormsBtns }))
      .length(8, t("field.prime_identification_number.error.length", { ns: nsCommonFormsBtns }))
      .matches(/^[a-zA-Z0-9]{8}$/, t("field.prime_identification_number.error.invalid", { ns: nsCommonFormsBtns }))
      .required(t("field.prime_identification_number.error.required", { ns: nsCommonFormsBtns })),
  });

  const handleSubmit = async (values: Partial<WAConsumerVerificationFormFields & ORVerificationFormFields>) => {
    let verificationData: RegistrationController_VerifyCredentialsDTO;
    if (pridOptionOnly()) {
      verificationData = {
        params: {
          clientFirstName: {
            type: VerificationParameterType.FIRST_NAME,
            value: values.firstName || "",
          },
          clientLastName: {
            type: VerificationParameterType.LAST_NAME,
            value: values.lastName || "",
          },
          primeId: {
            type: VerificationParameterType.PRIME_ID,
            value: values.prid || "",
          },
        },
      };
    } else if (values.verificationMethod === "pon") {
      verificationData = {
        params: {
          clientFirstName: {
            type: VerificationParameterType.FIRST_NAME,
            value: values.firstName || "",
          },
          clientLastName: {
            type: VerificationParameterType.LAST_NAME,
            value: values.lastName || "",
          },
          clientP1Id: {
            type: VerificationParameterType.UNIQUE_ID,
            value: values.pon || "",
          },
        },
      };
    } else {
      verificationData = {
        params: {
          clientFirstName: {
            type: VerificationParameterType.FIRST_NAME,
            value: values.firstName || "",
          },
          clientLastName: {
            type: VerificationParameterType.LAST_NAME,
            value: values.lastName || "",
          },
          clientLast4: {
            type: VerificationParameterType.LAST_4,
            value: values.ssn || "",
          },
          clientDateOfBirth: {
            type: VerificationParameterType.DOB,
            value: values.dob || "",
          },
        },
      };
    }

    try {
      setConsumerFirstNameFromCoordinator(values.firstName);

      const res = await preVerifyConsumerCreds(verificationData);

      const { verificationStatus } = res.data;
      const { verificationResultId } = res.data;

      if (verificationStatus === VerificationStatusEnum.SUCCESS) {
        showSnackbar(
          {
            messageTitle: t("success.consumer_preverified_title", { ns: nsCommonToasts }),
            messageBody: t("success.consumer_preverified", { ns: nsCommonToasts }),
          },
          "success",
          false,
        );
        if (id) {
          history.push(generatePath(routePrefix.jobRepost, { id, verificationId: verificationResultId }));
        } else {
          history.push(generatePath(routePrefix.jobCreate, { verificationId: verificationResultId }));
        }
      }

      if (
        verificationStatus === VerificationStatusEnum.FAILURE ||
        verificationStatus === VerificationStatusEnum.CANCELLED
      ) {
        setCredentialsError(true);
        scrollToId("credentials-error");
      }
    } catch (err) {
      setCredentialsError(true);
      scrollToId("credentials-error");
    }
  };

  const pridOptionOnly = (): boolean => {
    return isUserType(UserType.MedicaidCaseManager) && isFunderState(FunderState.Oregon);
  };

  const initialValues: Partial<WAConsumerVerificationFormFields & ORVerificationFormFields> = pridOptionOnly()
    ? {
        firstName: "",
        lastName: "",
        verificationToken: "_",
        prid: "",
      }
    : {
        firstName: "",
        lastName: "",
        verificationMethod: "pon",
        pon: "",
        ssn: "",
        dob: "",
      };

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Pre Verify Consumer</title>
        </Helmet>

        <MedicaidNavbar />
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="80vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.pre_verify_consumer", { ns: nsMedicaidJobPost })}</title>
      </Helmet>

      <MedicaidNavbar />
      <main id="main-content">
        <Section bgcolor={CivColors.lightGray} textAlign="center">
          <Constraint columns={6}>
            <SectionTitle>{t("post_a_job.label", { ns: nsMedicaidJobPost })}</SectionTitle>
            <Body>{t("post_a_job.description.jobs_stay_posted", { ns: nsMedicaidJobPost })}</Body>
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray}>
          <Constraint columns={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={pridOptionOnly() ? VerificationSchemaConsumerOR : VerificationSchemaConsumerWA}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, isSubmitting }) => (
                <Form localizationReady={ready}>
                  <Body paragraph>{t("field_marked_asterisk.label", { ns: nsMedicaidJobPost })}</Body>

                  <DashSection
                    bodyTitleLabel
                    label={t("consumer_verification.label", { ns: nsMedicaidJobPost })}
                    container
                  >
                    <Body paragraph style={{ paddingLeft: 8 }}>
                      {t("consumer_verification.description", { ns: nsMedicaidJobPost })}
                    </Body>
                    <Grid item xs={12}>
                      <FormTextField
                        name="firstName"
                        label={t("field.first_name.label_consumer", { ns: nsCommonFormsBtns })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="lastName"
                        label={t("field.last_name.label_consumer", { ns: nsCommonFormsBtns })}
                        required
                      />
                    </Grid>

                    {pridOptionOnly() ? (
                      <Grid item xs={12}>
                        <Field
                          as={FormTextField}
                          name="prid"
                          label={t("field.prime_identification_number.label", { ns: nsCommonFormsBtns })}
                          required
                        />
                      </Grid>
                    ) : (
                      <>
                        <Box p={2} />
                        <BodyEmphasis gutterBottom style={{ paddingLeft: 8 }}>
                          {t("field.verification_method.label.consumer_info", { ns: nsCommonFormsBtns })}
                        </BodyEmphasis>
                        <FormRadioGroupField<string>
                          name="verificationMethod"
                          withTooltip
                          options={[
                            {
                              key: "pon",
                              optionLabel: t("field.verification_method.provider_one_option.label", {
                                ns: nsCommonFormsBtns,
                              }),
                              tooltipBody: t("providerone_number", { ns: nsCommonTooltips }),
                            },
                            {
                              key: "ssn",
                              optionLabel: t("field.verification_method.ssn_and_dob_option.label", {
                                ns: nsCommonFormsBtns,
                              }),
                            },
                          ]}
                        />

                        {values.verificationMethod === "pon" ? (
                          <Grid item xs={12}>
                            <Field
                              as={FormTextField}
                              name="pon"
                              label={t("field.providerone_number.label", { ns: nsCommonFormsBtns })}
                              required
                            />
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <Field
                                as={FormTextField}
                                name="ssn"
                                label={t("field.last_4_ssn.label", { ns: nsCommonFormsBtns })}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FastMaskTextField
                                name="dob"
                                label={t("field.dob.label", { ns: nsCommonFormsBtns })}
                                maskType="date"
                                helperText="MM/DD/YYYY"
                                required
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </DashSection>

                  <FormAlert
                    schema={pridOptionOnly() ? VerificationSchemaConsumerOR : VerificationSchemaConsumerWA}
                    reset={values.verificationMethod}
                    sx={{ mb: "80px" }}
                  />

                  {credentialsError && (
                    <Constraint columns={8}>
                      <Alert
                        severity="error"
                        title={t("we_couldnt_verify_error.label", { ns: nsMedicaidRegVerification })}
                        sx={{ mb: "80px" }}
                      >
                        <Body paragraph>
                          {t("we_couldnt_verify_error.description.please_check", { ns: nsMedicaidRegVerification })}
                        </Body>
                        <Body>
                          <Trans
                            t={t}
                            i18nKey="we_couldnt_verify_error.description.if_need_call_and_select"
                            ns={nsMedicaidRegVerification}
                            components={{
                              cntctus: <MuiLink href={ROUTES.contactUs} target="_blank" />,
                            }}
                            values={{
                              cntctus_key: t("we_couldnt_verify_error.contact_us.label", {
                                ns: nsMedicaidRegVerification,
                              }),
                            }}
                          />
                        </Body>
                      </Alert>
                    </Constraint>
                  )}

                  <Box pt={3} pb={3} textAlign="center">
                    <LinkStyledAsButton
                      href={routePrefix.myJobList}
                      variant="outlined"
                      sx={{ marginRight: desktopSize ? "24px" : "0px", marginBottom: desktopSize ? "0px" : "24px" }}
                    >
                      {t("button.cancel", { ns: nsCommonFormsBtns })}
                    </LinkStyledAsButton>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      {isSubmitting
                        ? `${t("button.verifying", { ns: nsCommonFormsBtns })}...`
                        : t("button.verify_and_proceed", { ns: nsCommonFormsBtns })}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Constraint>
        </Section>
      </main>

      <Footer />
    </>
  );
};
