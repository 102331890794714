import {
  AxiosSingleInvitesControllerClient,
  AxiosRegistrationTokenControllerClient,
  SingleInviteType,
} from "src/generated/api_types";

const singleInviteClient = new AxiosSingleInvitesControllerClient();
export const RegistrationTokenControllerClient = new AxiosRegistrationTokenControllerClient();

export function redeemSingleInvite(token: string, inviteType: SingleInviteType) {
  return singleInviteClient.redeemSingleInvite({ token, inviteType });
}
