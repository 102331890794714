import { useState } from "react";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  ActiveUserListDTO,
  ChildcareInviteListDTO,
  MedicaidInviteListDTO,
  ReportStatusEnum,
  SingleInvite_Status,
  User_AccountStatus,
  UserReportListDTO,
} from "src/generated/api_types";
import {
  getAllUserReportsList,
  getChildcareInvitedUsers,
  usersAdministrationController,
} from "src/pages/supportAdminAccount/ControllerClients";
import {
  ChildcareSupportAdminDashboardContainer,
  MedicaidSupportAdminDashboardContainer,
  SupportAdminDashboardTab,
} from "src/pages/supportAdminAccount/dashboard/components/SupportAdminDashboardContainer";
import UserInviteModal from "src/pages/supportAdminAccount/forms/UserInviteModal";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { Column, Datatable, ExportMenuOption } from "src/reusable_view_elements/datatable/Datatable";
import { ServersideDatatable } from "src/reusable_view_elements/datatable/ServersideDatatable";
import { Button } from "src/reusable_view_elements/Button";
import { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import { Body } from "src/reusable_view_elements/Typography";
import {
  ACTIVE_USERS_COLUMN_DEFINITIONS,
  ActiveUserColumn,
  CC_ACTIVE_USERS_COLUMN_DEFINITIONS,
  CC_INVITED_USERS_COLUMN_DEFINITIONS,
  ChildcareInvitedUsersColumn,
  INVITED_USERS_COLUMN_DEFINITIONS,
  InvitedUsersColumn,
  ReportColumn,
  REPORTED_CASES_COLUMN_DEFINITIONS,
} from "./DatatableDefinitions";

interface BlankDatatableProps<T extends Column<any>> {
  columnDefinitions: { [k: string]: T };
}

export const BlankDatatable: React.FC<BlankDatatableProps<any>> = (props) => {
  const { program } = useSupportAdminContext();
  const { columnDefinitions } = props;
  return (
    <>
      <Datatable columnDefinitions={columnDefinitions} persistKeyPrefix="" data={[]} options={{ filtering: false }} />
      {program === "none" && (
        <Body align="center" style={{ marginTop: 16 }}>
          Select a program to create your query.
        </Body>
      )}
    </>
  );
};

/********************************************************************************************
 ************************************** MEDICAID DASHBOARD ************************************
 ********************************************************************************************/

export const ActiveMedicaidUsers = () => {
  const { program } = useSupportAdminContext();
  const { isUserType } = useUserSession();

  return (
    <MedicaidSupportAdminDashboardContainer tab={SupportAdminDashboardTab.ACTIVE}>
      {program === "none" && <BlankDatatable columnDefinitions={ACTIVE_USERS_COLUMN_DEFINITIONS} />}
      {program !== "none" && (
        <ServersideDatatable<ActiveUserListDTO, ActiveUserColumn>
          dataFetchFn={(pageInfo) => usersAdministrationController.search(program, pageInfo)}
          columnDefinitions={ACTIVE_USERS_COLUMN_DEFINITIONS}
          persistKeyPrefix="user-support-manager-active-medicaid-users-paging"
          queryDependencies={[program]}
          options={{
            exportMenu: isUserType(UserType.UserSupportManager)
              ? [ExportMenuOption.csv({ filename: "Active Medicaid Users" })]
              : [],
          }}
        />
      )}
    </MedicaidSupportAdminDashboardContainer>
  );
};

export const InvitedMedicaidUsers = () => {
  const { userSession } = useUserSession();
  const { program } = useSupportAdminContext();
  const [inviteListRefreshTrigger, toggleTrigger] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);

  const handleModalClose = () => {
    toggleTrigger(!inviteListRefreshTrigger);
    setOpenInviteModal(false);
  };

  return (
    <>
      <MedicaidSupportAdminDashboardContainer tab={SupportAdminDashboardTab.INVITED}>
        {program === "none" ? (
          <BlankDatatable columnDefinitions={INVITED_USERS_COLUMN_DEFINITIONS} />
        ) : (
          <>
            <ServersideDatatable<MedicaidInviteListDTO, InvitedUsersColumn>
              dataFetchFn={(pageInfo) => usersAdministrationController.searchMedicaidUserInvites(program, pageInfo)}
              columnDefinitions={INVITED_USERS_COLUMN_DEFINITIONS}
              persistKeyPrefix="user-support-invited-users"
              runQueryInEffect
              queryDependencies={[program, inviteListRefreshTrigger]}
              highlightRowFn={(rowData) => rowData.status === SingleInvite_Status.INVALIDATED}
              toolbarExtras={
                userSession?.accountStatus === User_AccountStatus.ACTIVE &&
                (userSession?.role === "UserSupportManager" || userSession?.role === "CarinaAdmin") && (
                  <Button
                    variant="contained"
                    type="button"
                    data-testid="invite-user-button"
                    onClick={() => setOpenInviteModal(true)}
                  >
                    Invite User
                  </Button>
                )
              }
              options={{
                exportMenu: [ExportMenuOption.csv({ filename: "Medicaid User Invites" })],
              }}
            />
            <UserInviteModal
              showModal={openInviteModal}
              handleModalClose={handleModalClose}
              dashboardType={DashboardType.MEDICAID}
            />
          </>
        )}
      </MedicaidSupportAdminDashboardContainer>
    </>
  );
};

export const MedicaidReportedCases = () => {
  const { program } = useSupportAdminContext();

  return (
    <MedicaidSupportAdminDashboardContainer tab={SupportAdminDashboardTab.REPORTED}>
      {program === "none" ? (
        <BlankDatatable columnDefinitions={REPORTED_CASES_COLUMN_DEFINITIONS} />
      ) : (
        <ServersideDatatable<UserReportListDTO, ReportColumn>
          title="User Reports"
          columnDefinitions={REPORTED_CASES_COLUMN_DEFINITIONS}
          persistKeyPrefix="reports-list-paging"
          dataFetchFn={(pageInfo) => getAllUserReportsList(pageInfo, program)}
          queryDependencies={[program]}
          runQueryInEffect // we want all records on program change
          highlightRowFn={(rowData) => rowData.status === ReportStatusEnum.UNPROCESSED}
          options={{ exportMenu: [ExportMenuOption.csv({ filename: "Reported Cases" })] }}
        />
      )}
    </MedicaidSupportAdminDashboardContainer>
  );
};

/********************************************************************************************
 ************************************** CHILDCARE DASHBOARD ************************************
 ********************************************************************************************/

export const ActiveChildcareUsers = () => {
  const { program } = useSupportAdminContext();
  const { isUserType } = useUserSession();

  return (
    <ChildcareSupportAdminDashboardContainer tab={SupportAdminDashboardTab.ACTIVE}>
      {program === "none" && <BlankDatatable columnDefinitions={CC_ACTIVE_USERS_COLUMN_DEFINITIONS} />}
      {program !== "none" && (
        <ServersideDatatable<ActiveUserListDTO, ActiveUserColumn>
          dataFetchFn={(pageInfo) => usersAdministrationController.search(program, pageInfo)}
          columnDefinitions={CC_ACTIVE_USERS_COLUMN_DEFINITIONS}
          persistKeyPrefix="user-support-manager-active-childcare-users-paging"
          queryDependencies={[program]}
          options={{
            exportMenu: isUserType(UserType.UserSupportManager)
              ? [ExportMenuOption.csv({ filename: "Active Childcare Users" })]
              : [],
          }}
        />
      )}
    </ChildcareSupportAdminDashboardContainer>
  );
};

export const InvitedChildcareUsers = () => {
  const { userSession } = useUserSession();

  const { program } = useSupportAdminContext();
  const [inviteListRefreshTrigger, toggleTrigger] = useState(false);

  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);

  const handleModalClose = () => {
    toggleTrigger(!inviteListRefreshTrigger);
    setOpenInviteModal(false);
  };

  return (
    <>
      <ChildcareSupportAdminDashboardContainer tab={SupportAdminDashboardTab.INVITED}>
        {program === "none" ? (
          <BlankDatatable columnDefinitions={CC_INVITED_USERS_COLUMN_DEFINITIONS} />
        ) : (
          <>
            <ServersideDatatable<ChildcareInviteListDTO, ChildcareInvitedUsersColumn>
              dataFetchFn={(pageInfo) => getChildcareInvitedUsers(program, pageInfo)}
              columnDefinitions={CC_INVITED_USERS_COLUMN_DEFINITIONS}
              persistKeyPrefix="user-support-invited-childcare-users"
              runQueryInEffect
              queryDependencies={[program, inviteListRefreshTrigger]}
              highlightRowFn={(rowData) => rowData.status === SingleInvite_Status.INVALIDATED}
              toolbarExtras={
                userSession?.accountStatus === User_AccountStatus.ACTIVE &&
                (userSession?.role === "UserSupportManager" || userSession?.role === "CarinaAdmin") && (
                  <Button
                    variant="contained"
                    type="button"
                    data-testid="invite-user-button"
                    onClick={() => setOpenInviteModal(true)}
                  >
                    Invite User
                  </Button>
                )
              }
              options={{
                exportMenu: [ExportMenuOption.csv({ filename: "Invited Childcare Users" })],
              }}
            />
            <UserInviteModal
              showModal={openInviteModal}
              handleModalClose={handleModalClose}
              dashboardType={DashboardType.CHILDCARE}
            />
          </>
        )}
      </ChildcareSupportAdminDashboardContainer>
    </>
  );
};
