import { Dialog, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { nsMedicaidInbox, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import FirstMessageForm from "src/pages/medicaidAccount/sharedComponents/FirstMessageForm";
import DialogCloseIconButton from "src/reusable_view_elements/dialog/DialogCloseIconButton";
import DialogBody from "src/reusable_view_elements/dialog/DialogBody";
import DialogHeader from "src/reusable_view_elements/dialog/DialogHeader";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";

export enum FirstMessageContext {
  PROVIDER_PROFILE = "Provider Profile",
  JOB_POST = "Job Post",
  REFERRAL_COORDINATOR_TEAM = "Referral Coordinator Team",
}

// Component Props
export interface FirstMessageDialogProps {
  recipientId: string;
  recipientFirstName: string;
  recipientLastName?: string;
  recipientRole: UserType;
  context: FirstMessageContext;
  contextJobPostId?: string;
  isDialogOpen: boolean;
  onCloseDialog: (threadId?: string) => void;
  pronouns?: string[];
}

const FirstMessageDialog = ({
  recipientId,
  recipientFirstName,
  recipientLastName,
  recipientRole,
  context,
  contextJobPostId,
  isDialogOpen,
  onCloseDialog,
  pronouns,
}: FirstMessageDialogProps) => {
  const { isUserType, userSession } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidInbox, nsMedicaidTagsEnums]);

  const ariaDialogLabelKey = "send-first-message-dialog-title";
  const ariaDescribeKey = "send-first-message-dialog-content";
  const showPHIReminder = isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider);

  function handleClose(threadId?: string) {
    onCloseDialog(threadId);
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => handleClose()}
      aria-labelledby={ariaDialogLabelKey}
      aria-describedby={ariaDescribeKey}
      maxWidth="sm"
    >
      {ready && (
        <DialogHeader>
          <Grid item>
            <BodyTitle id={ariaDialogLabelKey}>
              {t("message_username.label", {
                ns: nsMedicaidInbox,
                username: getRecipientName(recipientRole, recipientFirstName, recipientLastName),
              })}
            </BodyTitle>
            {pronouns && pronouns?.length > 0 && <Body>{pronouns.join(", ")}</Body>}
          </Grid>
          <DialogCloseIconButton onClick={() => handleClose()} />
        </DialogHeader>
      )}
      {ready && (
        <DialogBody id={ariaDescribeKey}>
          <Body paragraph>
            {t(getInstructionsKey(context, userSession?.role), { ns: nsMedicaidInbox, username: recipientFirstName })}
          </Body>
          {showPHIReminder && (
            <Body paragraph>{t("message_username.description.consumer_phi_reminder", { ns: nsMedicaidInbox })}</Body>
          )}
          <FirstMessageForm
            context={context}
            recipientId={recipientId}
            recipientRole={recipientRole}
            closeDialog={handleClose}
            contextJobPostId={contextJobPostId}
          />
        </DialogBody>
      )}
    </Dialog>
  );
};

export default FirstMessageDialog;

export function getRecipientName(
  recipientRole: UserType,
  recipientFirstName: string,
  recipientLastName: string | undefined,
): string {
  // If the recipient is a consumer, show first name. Everyone else, show first name + last initial
  if (recipientRole === UserType.Consumer || !recipientLastName) {
    return recipientFirstName;
  }
  return `${recipientFirstName} ${recipientLastName[0]}.`;
}

export function getInstructionsKey(context: FirstMessageContext, senderUserType?: string): string {
  if (
    senderUserType === UserType.Provider ||
    (senderUserType === UserType.ProxyProvider && context === FirstMessageContext.JOB_POST)
  ) {
    return "message_username.description.start_conversation_provider";
  }

  if (
    senderUserType === UserType.Consumer ||
    senderUserType === UserType.MedicaidCaseManager ||
    (senderUserType === UserType.MedicaidReferralCoordinator &&
      context !== FirstMessageContext.REFERRAL_COORDINATOR_TEAM) ||
    (senderUserType === UserType.ProxyProvider && context === FirstMessageContext.PROVIDER_PROFILE)
  ) {
    return "message_username.description.start_conversation_job";
  }

  return "message_username.description.start_conversation_generic";
}
