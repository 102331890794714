import { Trans, useTranslation } from "react-i18next";
import { ThreadFilterEnum } from "src/generated/api_types";
import { nsMedicaidInbox } from "src/i18n/Namespaces";
import InventoryIcon from "@mui/icons-material/Inventory";
import { EmptyContainer } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListContainer from "src/pages/inbox/inboxComponents/ThreadListContainer";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";

const ArchivedThreadsTabView = () => {
  const { t, ready } = useTranslation(nsMedicaidInbox);

  return (
    <ThreadListContainer
      threadFilter={ThreadFilterEnum.ARCHIVED}
      loadingText={ready ? t("loading.archived_conversations.label", { ns: nsMedicaidInbox }) : ""}
    >
      <EmptyContainer>
        <SectionTitle>{t("you_have_zero_messages.label_archived", { ns: nsMedicaidInbox })}</SectionTitle>
        <Trans
          i18nKey="you_have_zero_messages.description_archived"
          ns={nsMedicaidInbox}
          parent={Body}
          components={{
            archvicon: <InventoryIcon />,
          }}
        />
      </EmptyContainer>
    </ThreadListContainer>
  );
};

export default ArchivedThreadsTabView;
