import { Route, Switch } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AgencyAdminInviteDTO } from "src/generated/api_types";
import { AgencyAdminDetails } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyAdminDetails";
import { AgencyAdminInviteDetails } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyAdminInviteDetails";
import { AgencyInviteList } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyInviteList";
import {
  callReinvite,
  generateInvite,
  getAgencyAdmin,
  getAgencyInvites as getAgencyInvitesType,
  getAllAgencies,
  getAllFunders,
  getInviteDetails,
  requestDeactivation,
  requestPasswordReset,
  updateInvite,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import {
  CarinaAdminDashboardContainer,
  CarinaAdminDashboardTabs,
} from "src/pages/carinaAdmin/dashboard/carina_admin_dashboard_components/CarinaAdminDashboardContainer";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { AgencyAdminCreateInvite } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyAdminCreateInvite";

export const {
  useClientsideDatatable: useAgencyInviteDatatable,
  ClientsideDatatableContextProvider: AgencyInvitesDatatableContextProvider,
} = GenerateClientsideDatatable<AgencyAdminInviteDTO, typeof getAgencyInvitesType>("registeredUserId");

interface AgencyInviteDashboardContainerProps {
  getAgencyInvites: typeof getAgencyInvitesType;
}

export const AgencyInviteDashboard: React.FC<AgencyInviteDashboardContainerProps> = ({ getAgencyInvites }) => {
  const { isUserType } = useUserSession();
  return (
    <CarinaAdminDashboardContainer tab={CarinaAdminDashboardTabs.AGENCIES}>
      <AgencyInvitesDatatableContextProvider getData={getAgencyInvites}>
        <Switch>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list
            }
            component={AgencyInviteList}
          />
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_invite
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_invite
            }
          >
            <AgencyAdminCreateInvite
              getAllFunders={getAllFunders}
              getAllAgencies={getAllAgencies}
              onSubmit={generateInvite}
            />
          </Route>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_details
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_details
            }
          >
            <AgencyAdminDetails
              getAgencyAdmin={getAgencyAdmin}
              getAllFunders={getAllFunders}
              getAllAgencies={getAllAgencies}
              requestDeactivation={requestDeactivation}
              requestPasswordReset={requestPasswordReset}
            />
          </Route>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_invite_details
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_invite_details
            }
          >
            <AgencyAdminInviteDetails
              getInviteDetails={getInviteDetails}
              getAllFunders={getAllFunders}
              getAllAgencies={getAllAgencies}
              callReinvite={callReinvite}
              updateInvite={updateInvite}
            />
          </Route>
        </Switch>
      </AgencyInvitesDatatableContextProvider>
    </CarinaAdminDashboardContainer>
  );
};
