import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidSettings } from "src/i18n/Namespaces";
import { getLocalizedEmailChangeResponse } from "src/i18n/Utilities";
import { medicaidClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import DashSection from "src/reusable_view_elements/DashSection";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import Form from "src/reusable_view_elements/form_fields/Form";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

interface EmailSectionProps {
  currentEmail: string;
  setCurrentEmail: (email: string) => void;
  // The settings page needs to be aware when we're in edit mode to disable all other buttons outside of this form
  editMode: boolean;
  toggleEditMode: (bool: boolean) => void;
}

const EmailSection = (props: EmailSectionProps) => {
  const { showSnackbar } = useNotification();
  const { isImpersonated } = useUserSession();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsCommonToasts, nsMedicaidSettings]);

  const formValidationSchema = object({
    email: string()
      .email(t("field.valid_email.error", { ns: nsCommonFormsBtns }))
      .notOneOf([props.currentEmail], t("field.current_email.error", { ns: nsCommonFormsBtns })),
  });

  if (!props.editMode) {
    return (
      <DashSection bodyTitleLabel label={t("email.label", { ns: nsMedicaidSettings })} topBorder>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Body>{t("current_email.label", { ns: nsMedicaidSettings })}</Body>
            <BodyEmphasis paragraph data-testid="current-email">
              {props.currentEmail}
            </BodyEmphasis>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              onClick={() => {
                if (isImpersonated()) {
                  showSnackbar(
                    t(
                      "info.cant_change_email_if_impersonating",
                      "You cannot change this user's email if you are in impersonation mode.",
                      { ns: nsCommonToasts },
                    ),
                    "info",
                  );
                } else {
                  props.toggleEditMode(true);
                }
              }}
            >
              {t("button.change_email", { ns: nsCommonFormsBtns })}
            </Button>
          </Grid>
        </Grid>
      </DashSection>
    );
  }

  return (
    <DashSection bodyTitleLabel label={t("email.label", { ns: nsMedicaidSettings })} topBorder>
      <Body>{t("current_email.label", { ns: nsMedicaidSettings })}</Body>
      <BodyEmphasis paragraph>{props.currentEmail}</BodyEmphasis>

      <Body paragraph>{t("email.description", { ns: nsMedicaidSettings })}</Body>

      <Formik
        initialValues={{ email: "" }}
        validationSchema={formValidationSchema}
        onSubmit={(values, helpers) => {
          medicaidClient
            .updateEmail({ newEmail: values.email })
            .then((res) => {
              showSnackbar(getLocalizedEmailChangeResponse(res.data, "success", t), "success");
              props.toggleEditMode(false);
              props.setCurrentEmail(values.email);
            })
            .catch((error) => {
              if (typeof error.response.data === "string") {
                helpers.setFieldError("email", getLocalizedEmailChangeResponse(error.response.data, "error", t));
              } else if (error.message) {
                helpers.setFieldError("email", getLocalizedEmailChangeResponse(error.message, "error", t));
              } else {
                helpers.setFieldError("email", t("field.email.error.generic", { ns: nsCommonFormsBtns }));
              }
            })
            .finally(() => {
              helpers.setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form localizationReady={ready}>
            <Box mb={6}>
              <FormTextField
                name="email"
                label={t("field.enter_new_email.label", { ns: nsCommonFormsBtns })}
                disabled={isSubmitting}
                required={isRequiredField("email", formValidationSchema)}
              />
            </Box>
            <Grid container justifyContent="flex-end">
              <Button variant="text" type="button" onClick={() => props.toggleEditMode(false)} disabled={isSubmitting}>
                {t("button.cancel", { ns: nsCommonFormsBtns })}
              </Button>
              <Button variant="contained" type="submit" disabled={isSubmitting || !dirty}>
                {isSubmitting
                  ? t("button.saving_changes", { ns: nsCommonFormsBtns })
                  : t("button.save_changes", { ns: nsCommonFormsBtns })}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DashSection>
  );
};

export default EmailSection;
