import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Prompt, useHistory } from "react-router-dom";
import { Button } from "src/reusable_view_elements/Button";

interface DialogProps {
  when: boolean;
  nextPath: string;
}

const dialogActionsStyle = { paddingBottom: 8, justifyContent: "center" };

export default function UnsavedChangesDialog(props: DialogProps) {
  const { when, nextPath } = props;
  const [open, setOpen] = useState(false);
  const [lastPath, setLastPath] = useState<string | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    setOpen(false);
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = (): boolean => {
    if (!confirmedNavigation) {
      setOpen(true);
      setLastPath(nextPath);
      return false;
    }
    return true;
  };

  const history = useHistory();
  useEffect(() => {
    if (confirmedNavigation && lastPath) {
      history.push(lastPath);
    }
  }, [confirmedNavigation, lastPath]);

  const DIALOG_TITLE = "Wait, you have unsaved changes!";
  const CONTENT = "You can either continue editing and save your changes or discard them to return to the dashboard";
  const DISCARD_BUTTON_TEXT = "Discard changes";
  const CONTINUE_BUTTON_TEXT = "Continue editing";

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="unsaved-changes-dialog"
        aria-describedby="unsaved-changes-dialog-content"
        data-testid="unsaved-changes-dialog"
      >
        <DialogTitle id="unsaved-changes-dialog">{DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-dialog-content">{CONTENT}</DialogContentText>
        </DialogContent>
        <DialogActions style={dialogActionsStyle}>
          <Button onClick={handleDiscard} variant="outlined" type="button" data-testid="unsaved-changes-dialog-button">
            {DISCARD_BUTTON_TEXT}
          </Button>
          <Button onClick={handleClose} variant="contained" type="button" data-testid="unsaved-changes-dialog-button">
            {CONTINUE_BUTTON_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
