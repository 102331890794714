import { Box, Grid, useMediaQuery } from "@mui/material";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import Illustration from "src/assets/images/emans-computer-plain.svg";
import DesktopAvailabilityStep1 from "src/assets/tutorial/provider/desktop-5-1.jpg";
import DesktopAvailabilityStep2 from "src/assets/tutorial/provider/desktop-5-2.jpg";
import DesktopAvailabilityStep3 from "src/assets/tutorial/provider/desktop-5-3.jpg";
import DesktopAvailabilityStep4 from "src/assets/tutorial/provider/desktop-5-4.jpg";
import MobileAvailabilityStep1a from "src/assets/tutorial/provider/mobile-5-1a.jpg";
import MobileAvailabilityStep1b from "src/assets/tutorial/provider/mobile-5-1b.jpg";
import MobileAvailabilityStep2 from "src/assets/tutorial/provider/mobile-5-2.jpg";
import MobileAvailabilityStep3 from "src/assets/tutorial/provider/mobile-5-3.jpg";
import MobileAvailabilityStep4 from "src/assets/tutorial/provider/mobile-5-4.jpg";

const UpdateAvailability = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Update Availability</SectionTitle>
            <Body paragraph>
              If you are done looking for care work, please remember to <b>update your work availability</b> on Carina
              to let consumers know that you are not available for work.
            </Body>
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Go to &#34;My Profile&#34;</BodyTitle>
        <img src={onDesktop ? DesktopAvailabilityStep1 : MobileAvailabilityStep1a} width="100%" alt="" />
        {onMobile && (
          <>
            <Box p={1} />
            <img src={MobileAvailabilityStep1b} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Click &#34;Edit Profile&#34;</BodyTitle>
        <img src={onDesktop ? DesktopAvailabilityStep2 : MobileAvailabilityStep2} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Click on &#34;No, I am not available&#34;</BodyTitle>
        <img src={onDesktop ? DesktopAvailabilityStep3 : MobileAvailabilityStep3} width="100%" alt="" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>4. Save your changes</BodyTitle>
        <Body paragraph>Scroll down to the bottom of the page and click &#34;Save&#34;. </Body>
        <img src={onDesktop ? DesktopAvailabilityStep4 : MobileAvailabilityStep4} width="100%" alt="" />
      </Segment>
    </>
  );
};

export default UpdateAvailability;
