type FPixelOptionsObject = { [k: string]: any };

//https://developers.facebook.com/docs/meta-pixel/reference
type FPixedEventAction = {
  [k in "SEARCH" | "CONTACT" | "PAGE_VIEW"]: (options?: FPixelOptionsObject) => void;
};

declare global {
  interface Window {
    fbq: (action: string, eventName: string, options?: FPixelOptionsObject) => void;
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const PixelEventPush: FPixedEventAction = {
  SEARCH: (options?: FPixelOptionsObject) => {
    window.fbq("track", "Search", options);
  },
  CONTACT: (options?: FPixelOptionsObject) => {
    window.fbq("track", "Contact", options);
  },
  PAGE_VIEW: () => {
    window.fbq("track", "PageView");
  },
};
