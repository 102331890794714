import { Grid } from "@mui/material";
import { FormikBag } from "formik";
import { useState } from "react";
import { Funder_DTO_Response_Listing } from "src/generated/api_types";
import { getFunder as getFunderType } from "src/pages/funderAdmin/dashboard/funder_admin_agencies/Queries";
import DashSection from "src/reusable_view_elements/DashSection";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { FormAddressField } from "src/reusable_view_elements/form_fields";
import { Body } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { useOnce } from "src/utilities/useOnce";
import { useStateIfMounted } from "use-state-if-mounted";
import { object, string } from "yup";

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const uuidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const validationSchema = {
  firstName: string().required("Error: Please enter a first name"),
  lastName: string().required("Error: Please enter a last name"),
  phone: string().matches(phoneRegExp, { message: "Please enter a valid phone number" }),
  agencyId: string().matches(uuidRegExp, { message: "Please select an agency" }),
  email: string()
    .required("Error: Please enter a valid email address")
    .email("Error: Please enter a valid email address"),
};

const formattedSchema = object(validationSchema);

export interface AgencyAdminFormFields {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  agencyId: string;
  agencyZip: string;
  agencyFunder: string;
  agencyName: string;
}

export type AgencyAdminFormProps<RemoteData = void, Fields extends AgencyAdminFormFields = AgencyAdminFormFields> = {
  data?: RemoteData;
  getFunder: typeof getFunderType;
  handleSubmit?: (values: Fields, formikBag: FormikBag<AgencyAdminFormProps<RemoteData, Fields>, Fields>) => void;
  editable?: boolean | (keyof AgencyAdminFormFields)[];
};

//TODO: Check if Carina Admin and Funder Admin can use the same underlying AgencyAdminForm
export function AgencyAdminForm<RemoteData, Fields extends AgencyAdminFormFields = AgencyAdminFormFields>({
  editable,
  getFunder,
}: AgencyAdminFormProps<RemoteData, Fields>) {
  const [_funder, setFunder] = useState<Funder_DTO_Response_Listing>();
  const [isFetching, setIsFetching] = useStateIfMounted(true);

  useOnce(() => {
    Promise.allSettled([
      getFunder()
        .then((response) => {
          if (response.status === 200) {
            setFunder(response.data);
          } else {
            // eslint-disable-next-line no-console
            console.error("Failed to query funder");
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${response.status}`);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("Failed to query funders");
          // eslint-disable-next-line no-console
          console.error(`Error: ${err}`);
        }),
    ]).finally(() => {
      setIsFetching(false);
    });
  });

  if (isFetching) {
    return <Body>Information is being retrieved</Body>;
  }

  // function getFunderOptions(data: Funder_DTO_Response_Listing | undefined): SelectOption<string>[] {
  //   if (!data) {
  //     return [{ label: "Not Available", value: "unassigned" }];
  //   }
  //   return [{ label: `${data.segment}`, value: `${data.id}` }];
  // }

  function isEditable(fieldName: keyof AgencyAdminFormFields) {
    if (Array.isArray(editable)) return editable.includes(fieldName);
    return editable === true;
  }

  return (
    <Form name="agency-admin-invite" localizationReady={true}>
      <DashSection label="Admin Information" container topBorder>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="firstName"
            label="First Name"
            disabled={!isEditable("firstName")}
            required={isRequiredField("firstName", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="lastName"
            label="Last Name"
            disabled={!isEditable("lastName")}
            required={isRequiredField("lastName", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FastMaskTextField
            name="phone"
            label="Work Phone"
            maskType="phone"
            required={isRequiredField("phone", formattedSchema)}
            disabled={!isEditable("phone")}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="email"
            label="Email Address"
            disabled={!isEditable("email")}
            type="email"
            required={isRequiredField("email", formattedSchema)}
          />
        </Grid>
      </DashSection>

      <DashSection label="Agency Information" container topBorder>
        <Grid item xs={12} md={12}>
          <FormTextField
            name="agencyFunder"
            label="Funder"
            disabled={!isEditable("agencyFunder")}
            required={isRequiredField("agencyFunder", formattedSchema)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormAddressField
            zipCodeOnly
            name="agencyZip"
            label="Zip Code"
            disabled={!isEditable("agencyZip")}
            required={isRequiredField("agencyZip", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="agencyName"
            label="Agency Name"
            disabled={!isEditable("agencyId")}
            required={isRequiredField("agencyName", formattedSchema)}
          />
        </Grid>
      </DashSection>
    </Form>
  );
}

AgencyAdminForm.defaultProps = {
  data: undefined,
  editable: true,
  handleSubmit: () => {},
};
