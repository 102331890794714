import { useUserSession } from "src/context/UserSessionContext";
import Form, { FormProps } from "src/reusable_view_elements/form_fields/Form";

// On long forms, we want to delay showing the auto-logout modal so that users can take their time filling it out.
// We want to keep accessibility in mind, so we are trying out onFocus instead of onClick. We want the timer reset when
// user activity has been done by things like mouse clicks, touch on mobile devices, and assistive technology.
const LongForm = ({ children, onKeyDown, ...formikFormProps }: FormProps) => {
  const { delayLogoutTimer } = useUserSession();

  return (
    <Form
      onFocus={() => {
        delayLogoutTimer();
      }}
      noValidate
      {...formikFormProps}
    >
      {children}
    </Form>
  );
};

export default LongForm;
