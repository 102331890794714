import { alpha, Radio as MRadio, RadioProps as MRadioProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CivColors from "src/themes/civilization/CivColors";

const StyledRadio = styled(MRadio)({
  color: CivColors.coreOrange,
  padding: 12,
  "& .MuiSvgIcon-root": {
    height: 24,
    width: 24,
    color: CivColors.coreDarkNavy,
  },
  "&.Mui-disabled": {
    "& .MuiSvgIcon-root": {
      color: CivColors.mediumGray,
    },
  },
  "&.Mui-checked": {
    color: CivColors.coreOrange,
    "& .MuiSvgIcon-root": {
      color: CivColors.coreOrange,
    },
  },
  "& .MuiTouchRipple-child": {
    //The TouchRipple component holds the animation for the 'focus circle' around the checkbox
    //Its final color already has opacity 0.3, so we divide by 0.3 to get the 0.08 opacity per Figma design
    backgroundColor: alpha(CivColors.coreOrange, 0.08 / 0.3),
  },
  "&:hover": {
    backgroundColor: alpha(CivColors.coreOrange, 0.08),
  },
});

const Radio = ({ ...restProps }: MRadioProps) => {
  return <StyledRadio size="small" {...restProps} />;
};

export default Radio;
