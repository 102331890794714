import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import NewRelicAgent from "src/analytics/NewRelicAgent";
import { ConfigContextProvider } from "src/context/ConfigContext";
import { NotificationContextProvider } from "src/context/NotificationContext";
import { UserSessionContextProvider } from "src/context/UserSessionContext";
import "src/i18n/i18n";
import { MainRouter } from "src/MainRouter";
import { InboxContextProvider } from "src/pages/inbox/InboxContext";
import { MedicaidContextProvider } from "src/pages/medicaidAccount/MedicaidContext";
import SkipNavigationButton from "src/reusable_view_elements/SkipNavigationButton";
import Theme from "src/reusable_view_elements/Theme";
import { PrivatePayContextProvider } from "src/pages/findHomeCare/findPrivatePay/PrivatePayContext";

const HOSTNAME = window.location.hostname;

function App() {
  return (
    <HelmetProvider>
      <NewRelicAgent hostname={HOSTNAME} />
      <BrowserRouter>
        <UserSessionContextProvider>
          <ConfigContextProvider>
            <NotificationContextProvider>
              <PrivatePayContextProvider>
                <MedicaidContextProvider>
                  <InboxContextProvider>
                    <Theme>
                      <SkipNavigationButton />
                      <MainRouter />
                    </Theme>
                  </InboxContextProvider>
                </MedicaidContextProvider>
              </PrivatePayContextProvider>
            </NotificationContextProvider>
          </ConfigContextProvider>
        </UserSessionContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
