import { Grid, useMediaQuery } from "@mui/material";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import Illustration from "src/assets/images/emans-home-care-process-3.svg";
import theme from "src/themes/civilization/CivTheme";
import DesktopStep1a_WA from "src/assets/tutorial/client/desktop-3-1a-WA.jpg";
import DesktopStep1a_OR from "src/assets/tutorial/client/desktop-3-1a-OR.jpg";
import DesktopStep1b from "src/assets/tutorial/client/desktop-3-1b.jpg";
import DesktopStep2a_WA from "src/assets/tutorial/client/desktop-3-2a-WA.jpg";
import DesktopStep2a_OR from "src/assets/tutorial/client/desktop-3-2a-OR.jpg";
import DesktopStep2b_WA from "src/assets/tutorial/client/desktop-3-2b-WA.jpg";
import DesktopStep2b_OR from "src/assets/tutorial/client/desktop-3-2b-OR.jpg";
import MobileStep1a from "src/assets/tutorial/client/mobile-3-1a.jpg";
import MobileStep1b from "src/assets/tutorial/client/mobile-3-1b.jpg";
import MobileStep2a_WA from "src/assets/tutorial/client/mobile-3-2a-WA.jpg";
import MobileStep2a_OR from "src/assets/tutorial/client/mobile-3-2a-OR.jpg";
import MobileStep2b from "src/assets/tutorial/client/mobile-3-2b.jpg";

const screenshotFilesMap = new Map<string, string>([
  ["DesktopStep1a_WA", DesktopStep1a_WA],
  ["DesktopStep1a_OR", DesktopStep1a_OR],
  ["DesktopStep2a_WA", DesktopStep2a_WA],
  ["DesktopStep2a_OR", DesktopStep2a_OR],
  ["DesktopStep2b_WA", DesktopStep2b_WA],
  ["DesktopStep2b_OR", DesktopStep2b_OR],
  ["MobileStep2a_WA", MobileStep2a_WA],
  ["MobileStep2a_OR", MobileStep2a_OR],
]);

const Message = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  const { isFunderState } = useUserSession();

  const getScreenshot = (filePrefix: string) => {
    let file = `${filePrefix}_WA`;
    if (isFunderState(FunderState.Oregon)) file = `${filePrefix}_OR`;
    return screenshotFilesMap.get(file);
  };

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} textAlign={onDesktop ? "left" : "center"} columns={8}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Message</SectionTitle>
            <Body paragraph>
              Once you find a provider you’re interested in working with,{" "}
              <b>message that provider to say “Hello!” and introduce yourself</b>.
            </Body>
            <Body paragraph>
              You can also exchange phone numbers or set up a time to meet in person for an interview.
            </Body>
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Send a message</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>Click &#34;Message&#34; on the provider&apos;s profile page.</Body>
            </Constraint>
            <img src={getScreenshot("DesktopStep1a")} alt="" width="100%" />
            <Constraint columns={6} pt={4}>
              <Body paragraph>
                Fill out the form to introduce yourself. You can choose to send your phone number and job post in the
                message. Once you&#39;re done, click the &#34;Send&#34; button.
              </Body>
            </Constraint>
            <img src={DesktopStep1b} alt="" width="100%" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>Click &#34;Message&#34; on the provider’s profile page.</Body>
            </Constraint>
            <img src={MobileStep1a} alt="" width="100%" />
            <Constraint columns={6} pt={4}>
              <Body paragraph>
                Fill out the form to introduce yourself. You can choose to send your phone number and job post in the
                message. Once you&#39;re done, click the &#34;Send&#34; button.
              </Body>
            </Constraint>
            <img src={MobileStep1b} alt="" width="100%" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. See your messages</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                To view the messages you have sent and received, click the &#34;Messages&#34; button at the top of the
                page.
              </Body>
            </Constraint>
            <img src={getScreenshot("DesktopStep2a")} alt="" width="100%" />
            <Constraint columns={6} pt={6} pb={4}>
              <Body>Click on the message you would like to read to open it.</Body>
            </Constraint>
            <img src={getScreenshot("DesktopStep2b")} alt="" width="100%" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                To view the messages you have sent and received, click &#34;Messages&#34; from the Menu.
              </Body>
            </Constraint>
            <img src={getScreenshot("MobileStep2a")} alt="" width="100%" />
            <Constraint columns={6} pt={6} pb={4}>
              <Body>Click on the message you would like to read to open it.</Body>
            </Constraint>

            <img src={MobileStep2b} alt="" width="100%" />
          </>
        )}
      </Segment>
    </>
  );
};

export default Message;
