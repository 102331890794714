import { getTime } from "date-fns";
import { withFormik } from "formik";
import { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AgencyInviteStatus, PrivatePayInvite_DTO_Response_Details } from "src/generated/api_types";
import {
  AgencyAdminForm,
  AgencyAdminFormFields,
  AgencyAdminFormProps,
  validationSchema,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyAdminForm";
import { useAgencyInviteDatatable } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyInviteDashboard";
import {
  callReinvite as callReinviteType,
  getAllAgencies as getAllAgenciesType,
  getAllFunders as getAllFundersType,
  getInviteDetails as getInviteDetailsType,
  updateInvite as updateInviteType,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import BottomActionBar from "src/reusable_view_elements/BottomActionBar";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import { Button } from "src/reusable_view_elements/Button";
import { SectionTitle } from "src/reusable_view_elements/Typography";
import { object, string } from "yup";

export const INVITE_UPDATE_SUCCESSFUL_BASE_MESSAGE = "New invite link";
export const INVITE_UPDATE_FAILED_MESSAGE = "Error updating invite. Try again later.";
export const REINVITE_FAILED_MESSAGE = "Error requesting reinvite. Try again later.";

type AgencyAdminInviteDetailsProps = {
  getInviteDetails: typeof getInviteDetailsType;
  getAllFunders: typeof getAllFundersType;
  getAllAgencies: typeof getAllAgenciesType;
  callReinvite: typeof callReinviteType;
  updateInvite: typeof updateInviteType;
};

export const AgencyAdminInviteDetails: React.FC<AgencyAdminInviteDetailsProps> = ({
  getInviteDetails,
  getAllFunders,
  getAllAgencies,
  callReinvite,
  updateInvite,
}) => {
  const { showSnackbar } = useNotification();
  const [invite, setInvite] = useState<PrivatePayInvite_DTO_Response_Details>();
  const [isFetching, setIsFetching] = useState(true);
  const history = useHistory();
  const { addOrUpdateData, removeData } = useAgencyInviteDatatable();
  const { isUserType } = useUserSession();

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    getInviteDetails(id)
      .then((res) => {
        if (res.status === 200) {
          setInvite(res.data);
        } else {
          showSnackbar("Error loading invite. Please try again.", "error");
        }
      })
      .catch(() => {
        showSnackbar("Error loading invite. Please try again.", "error");
      })
      .finally(() => setIsFetching(false));
  }, [id]);

  if (isFetching) {
    return <>Loading Agency Admin...</>;
  }

  if (!invite) {
    return <>Error loading Agency Admin!</>;
  }

  function handleNewInvite(data: PrivatePayInvite_DTO_Response_Details) {
    removeData(id);
    addOrUpdateData(data.token, () => ({
      id: data.token,
      agencyAdminName: `${data.firstName} ${data.lastName}`,
      agencyInviteCreatedAt: getTime(new Date()),
      agencyInviteStatus: AgencyInviteStatus.INVITED,
      agencyName: data.agency.name,
      funderName: data.agency.funder.segment,
    }));
    const newLink = `${window.location.origin}/homecare/agency/registration?token=${data.token}`;
    showSnackbar(`${INVITE_UPDATE_SUCCESSFUL_BASE_MESSAGE}: ${newLink}`, "success");
    history.push(
      generatePath(
        isUserType(UserType.CarinaAdmin)
          ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_invite_details
          : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_invite_details,
        { id: data.token },
      ),
    );
  }

  return (
    <>
      <DashReturnLink
        to={
          isUserType(UserType.CarinaAdmin)
            ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
            : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list
        }
      >
        Return to Agencies Dashboard
      </DashReturnLink>

      <Constraint columns={8} paddingTop={4}>
        <SectionTitle>Pending Agency Admin Invite</SectionTitle>
      </Constraint>

      <Constraint columns={8} paddingTop={2} paddingBottom={14}>
        <WrappedAgencyAdminInviteDetailsForm
          data={invite}
          getAllFunders={getAllFunders}
          getAllAgencies={getAllAgencies}
          handleSubmit={(values) => {
            updateInvite(id, { email: values.email })
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.invite) {
                    handleNewInvite(res.data.invite);
                  } else {
                    showSnackbar(res.data.message, "error");
                  }
                } else {
                  showSnackbar(INVITE_UPDATE_FAILED_MESSAGE, "error");
                }
              })
              .catch(() => {
                showSnackbar(INVITE_UPDATE_FAILED_MESSAGE, "error");
              });
          }}
          editable={["email"]}
        >
          {() => (
            <>
              <DashSection label="Re-invitation" container topBorder>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    callReinvite(id)
                      .then((res) => {
                        if (res.status === 200) {
                          if (res.data.invite) {
                            handleNewInvite(res.data.invite);
                          } else {
                            showSnackbar(res.data.message, "error");
                          }
                        } else {
                          showSnackbar(REINVITE_FAILED_MESSAGE, "error");
                        }
                      })
                      .catch(() => {
                        showSnackbar(REINVITE_FAILED_MESSAGE, "error");
                      });
                  }}
                >
                  Re-invite Admin
                </Button>
              </DashSection>

              <BottomActionBar
                bottomActionButtons={[
                  {
                    variant: "outlined",
                    buttonText: "Cancel",
                    buttonProps: {
                      onClick: () =>
                        history.push(
                          isUserType(UserType.CarinaAdmin)
                            ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_list
                            : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_list,
                        ),
                    },
                  },
                  {
                    variant: "contained",
                    buttonText: "Update",
                    buttonProps: {
                      type: "submit",
                      // @ts-ignore
                      "data-testid": "update-button",
                    },
                  },
                ]}
              />
            </>
          )}
        </WrappedAgencyAdminInviteDetailsForm>
      </Constraint>
    </>
  );
};

const WrappedAgencyAdminInviteDetailsForm = withFormik<
  AgencyAdminFormProps<PrivatePayInvite_DTO_Response_Details>,
  AgencyAdminFormFields
>({
  mapPropsToValues: ({ data: invite }) => ({
    firstName: invite?.firstName || "",
    lastName: invite?.lastName || "",
    phone: "",
    email: invite?.email || "",
    agencyId: invite?.agency.id || "",
    agencyZip: "",
    agencyFunder: "",
  }),
  validationSchema: object({ ...validationSchema, agencyId: string().required() }),
  handleSubmit: (values, formikBag) => {
    const {
      props: { handleSubmit },
    } = formikBag;
    if (handleSubmit) handleSubmit(values, formikBag);
  },
})(AgencyAdminForm);
