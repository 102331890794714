import {
  MedicaidProviderProfile_DTO_Response_ProfileDetails,
  ProviderProfileTagEnum,
  ProviderProfileTagEnum_Category,
  ProviderProfileTagEnum_DTO_ValueAndCategory,
} from "src/generated/api_types";

export interface TagsObjectType {
  languageTags: ProviderProfileTagEnum[];
  generalSkillTags: ProviderProfileTagEnum[];
  movementSkillTags: ProviderProfileTagEnum[];
  transportSkillTags: ProviderProfileTagEnum[];
  specializedSkillTags: ProviderProfileTagEnum[];
  generalWorkEnvironmentTags: ProviderProfileTagEnum[];
  workEnvironmentTags: ProviderProfileTagEnum[];
  petTags: ProviderProfileTagEnum[];
  consumerGenderTag: ProviderProfileTagEnum[];
  providerGenderTag: ProviderProfileTagEnum[];
  typeOfCareTag: ProviderProfileTagEnum[];
  typeOfSupportTags: ProviderProfileTagEnum[];
  ageRangeTags: ProviderProfileTagEnum[];
  pronounTags: ProviderProfileTagEnum[];
}

export const getTagsObject = (
  providerProfile?: MedicaidProviderProfile_DTO_Response_ProfileDetails,
): TagsObjectType => {
  if (!providerProfile) {
    return {
      languageTags: [],
      generalSkillTags: [],
      movementSkillTags: [],
      transportSkillTags: [],
      specializedSkillTags: [],
      generalWorkEnvironmentTags: [],
      workEnvironmentTags: [],
      petTags: [],
      consumerGenderTag: [],
      providerGenderTag: [],
      typeOfCareTag: [],
      typeOfSupportTags: [],
      ageRangeTags: [],
      pronounTags: [],
    };
  }
  return getTagsObjectFromTags(providerProfile.tags);
};

export const getTagsObjectFromTags = (
  providerProfileTags: ProviderProfileTagEnum_DTO_ValueAndCategory[],
): TagsObjectType => {
  return {
    languageTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.LANGUAGE)
      .map((tagObj) => tagObj.tag),
    generalSkillTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.GENERAL_SKILLS)
      .filter((tagObj) => tagObj.tag !== ProviderProfileTagEnum.LGBT_FRIENDLY) //filter out the old LGBT tag for now until we can remove from enum class
      .map((tagObj) => tagObj.tag),
    movementSkillTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.MOVEMENT_SKILLS)
      .map((tagObj) => tagObj.tag),
    transportSkillTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.TRANSPORTATION_SKILLS)
      .map((tagObj) => tagObj.tag),
    specializedSkillTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.SPECIALIZED_SKILL)
      .map((tagObj) => tagObj.tag),
    generalWorkEnvironmentTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.ENVIRONMENT_GENERAL)
      .map((tagObj) => tagObj.tag),
    workEnvironmentTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.ENVIRONMENT)
      .map((tagObj) => tagObj.tag),
    petTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.PETS)
      .map((tagObj) => tagObj.tag),
    consumerGenderTag: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.PREFERRED_CLIENT_GENDER)
      .sort((a, b) => a.order - b.order)
      .map((tagObj) => tagObj.tag),
    providerGenderTag: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.PROVIDER_GENDER)
      .sort((a, b) => a.order - b.order)
      .map((tagObj) => tagObj.tag),
    typeOfCareTag: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.TYPE_OF_CARE)
      .map((tagObj) => tagObj.tag),
    typeOfSupportTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.TYPE_OF_SUPPORT)
      .map((tagObj) => tagObj.tag),
    ageRangeTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.AGE_RANGE)
      .map((tagObj) => tagObj.tag),
    pronounTags: providerProfileTags
      .filter((tagObj) => tagObj.category === ProviderProfileTagEnum_Category.PRONOUN)
      .sort((a, b) => a.order - b.order)
      .map((tagObj) => tagObj.tag),
  };
};

export const getAvailability = (availability?: ProviderProfileTagEnum) => {
  switch (availability) {
    case ProviderProfileTagEnum.SEEKING:
      return "Available Now";
    case ProviderProfileTagEnum.SOON:
      return "Available Soon";
    case ProviderProfileTagEnum.NOTSEEKING:
      return "Not Available";
    default:
      return "";
  }
};
