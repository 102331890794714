import { Route, Switch } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { ConsumerApplication_DTO_Response_Listing } from "src/generated/api_types";
import { CarinaAdminConsumerDetails } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/CarinaAdminConsumerDetails";
import {
  getConsumer,
  getAllConsumers as getConsumersType,
} from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/Queries";
import { CarinaAdminConsumersList } from "src/pages/carinaAdmin/dashboard/carina_admin_consumers/CarinaAdminConsumersList";
import {
  CarinaAdminDashboardContainer,
  CarinaAdminDashboardTabs,
} from "src/pages/carinaAdmin/dashboard/carina_admin_dashboard_components/CarinaAdminDashboardContainer";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useCarinaAdminConsumersDatatable,
  ClientsideDatatableContextProvider: CarinaAdminConsumersDatatableContextProvider,
} = GenerateClientsideDatatable<ConsumerApplication_DTO_Response_Listing, typeof getConsumersType>();

interface CarinaAdminConsumerDashboardContainerProps {
  getAllConsumers: typeof getConsumersType;
}

export const CarinaAdminConsumerDashboard: React.FC<CarinaAdminConsumerDashboardContainerProps> = ({
  getAllConsumers,
}) => {
  const { isUserType } = useUserSession();

  return (
    <CarinaAdminDashboardContainer tab={CarinaAdminDashboardTabs.CONSUMERS}>
      <CarinaAdminConsumersDatatableContextProvider getData={getAllConsumers}>
        <Switch>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.consumer_list
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.consumer_list
            }
          >
            <CarinaAdminConsumersList />
          </Route>
          <Route
            exact
            path={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.consumer_details
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.consumer_details
            }
          >
            <CarinaAdminConsumerDetails getConsumer={getConsumer} />
          </Route>
        </Switch>
      </CarinaAdminConsumersDatatableContextProvider>
    </CarinaAdminDashboardContainer>
  );
};
