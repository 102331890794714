import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CivColors from "src/themes/civilization/CivColors";
import theme, { width } from "src/themes/civilization/CivTheme";

interface Props extends BoxProps {
  line?: boolean;
}

const defaultProps = {
  line: false,
};

const Section = styled(Box)({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(12),
  paddingRight: theme.spacing(12),
});

const LineStyles = {
  height: 0,
  borderBottom: `1px solid ${CivColors.coreDarkNavy}`,
  maxWidth: width(6),
  margin: "0px auto",
};

function Separator({ line, ...props }: Props) {
  return <Section {...props}>{line && <div style={LineStyles} />}</Section>;
}

Separator.defaultProps = defaultProps;
export default Separator;
