import { Box, BoxProps } from "@mui/material";

// Use to wrap around buttons inside a DialogBody or Di
const DialogButtons = ({ children, style, ...rest }: BoxProps) => {
  return (
    <Box paddingY={3} textAlign="right" style={{ display: "flex", justifyContent: "flex-end", ...style }} {...rest}>
      {children}
    </Box>
  );
};

export default DialogButtons;
