import { format } from "date-fns";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import { AgencyAdminInviteDTO } from "src/generated/api_types";
import { useFunderAdminAgencyInvitesDatatable } from "src/pages/funderAdmin/dashboard/funder_admin_agencies/FunderAdminAgencyAdminDashboard";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";

/*********************************************************
 ************* Start Column Helper Functions *************
 *********************************************************/

export function getCreatedDate(data: AgencyAdminInviteDTO): Date {
  return new Date(data.agencyInviteCreatedAt);
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getStatusString(data: AgencyAdminInviteDTO): any {
  return <Body>{_.startCase(_.lowerCase(data.agencyInviteStatus))}</Body>;
}

/*********************************************************
 ******************* Column Definitions ******************
 *********************************************************/

const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<AgencyAdminInviteDTO> } = {
  name: {
    title: "Admin Name",
    field: "agencyAdminName",
    render: (data) => {
      if (data.registeredUserId) {
        return (
          <InternalLink
            to={generatePath(FUNDER_ADMIN_DASHBOARD_ROUTES.agencyadmins_details, { id: data.registeredUserId })}
          >
            <Body>{data.agencyAdminName}</Body>
          </InternalLink>
        );
      }
      return <Body>{data.agencyAdminName}</Body>;
    },
    customSort: (data1, data2) =>
      data1.agencyAdminName.toLowerCase().localeCompare(data2.agencyAdminName.toLowerCase()),
  },
  agency: {
    title: "Agency",
    field: "agencyName",
  },
  status: {
    title: "Status",
    render: getStatusString,
    customFilterAndSearch: (term, data) =>
      data.agencyInviteStatus.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1,
    customSort: (data1, data2) =>
      _.lowerCase(data1.agencyInviteStatus)
        .toLowerCase()
        .localeCompare(_.lowerCase(data2.agencyInviteStatus).toLowerCase()),
  },
  created: {
    title: "Created",
    render: (data) => formatDate(getCreatedDate(data)),
    customFilterAndSearch: (term, data) => formatDate(getCreatedDate(data)).indexOf(term) !== -1,
    customSort: (data1, data2) => getCreatedDate(data1).getTime() - getCreatedDate(data2).getTime(),
    defaultSort: "desc",
  },
};

/*********************************************************
 ******************* Page Definition *********************
 *********************************************************/

export const FunderAdminAgencyAdminsList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useFunderAdminAgencyInvitesDatatable();

  return (
    <>
      <Helmet>
        <title>Carina | Internal Tools | Agencies</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="agencyInvites-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Agency Invites"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching agency invites..." : "No agency invite records to display",
          },
        }}
      />
    </>
  );
};
