import { ButtonProps } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Button } from "src/reusable_view_elements/Button";

export const MenuButton: React.FC<ButtonProps> = (props: Omit<ButtonProps, "type">) => {
  const style = { marginRight: "-6px", paddingRight: "8px" };
  return (
    <Button variant="text" type="button" sx={style} {...props}>
      {props.children}
      <MenuRoundedIcon style={{ marginLeft: 8 }} />
    </Button>
  );
};
