import { FastField, FieldAttributes } from "formik";
import { PropsWithChildren, useEffect, useState } from "react";

/**
 * `FastField` automatically ignores re-renders if the associated value in Formik is not changed.
 * The `controlledRerender` prop is used to trigger a re-render on the field if the value of
 * `controlledRerender` changes.
 */
export type ControllableFastFieldProps<T> = {
  name: string;
  // The 'languagePeek' prop is used to keep track of a change in the consuming component.
  // The text could be a label, placehlder, etc.
  languagePeek: string;
  controlledRerender?: T;
} & Omit<FieldAttributes<any>, "name">;

/**
 * `FastField` automatically ignores re-renders if the associated value in Formik is not changed.
 * `ControllableFastField` allows the component consumer to trigger a re-render on the field
 * on demand by changing the value of `controlledRerender`. Usually, you'll want to use a standard
 * React state variable here.
 */

// TODO ESLint: Resolve the rule being ignored here
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export function ControllableFastField<T extends any>(props: PropsWithChildren<ControllableFastFieldProps<T>>) {
  const { children, controlledRerender, ...restProps } = props;

  const [languagePeek, setLanguagePeek] = useState<string>(props.languagePeek);
  const [languageToggle, setLanguageToggle] = useState<object>();

  useEffect(() => {
    //If the text in the consuming component has changed (e.g. when language changes), trigger a re-render
    if (languagePeek !== props.languagePeek) {
      setLanguagePeek(props.languagePeek); // Set and keep track of the new text
      setLanguageToggle(languageToggle ? undefined : {}); // Toggle object between existent and non-existent to trigger re-render
    }
  }, [props.languagePeek]); //Every time the text changes in the consuming component, we want to re-run this check

  // FastField will re-render if props are added/removed.
  // This allows us to maintain performance of FastField + responsive controls
  const ffReset: { reset?: T; language?: any } = {};
  if (controlledRerender) ffReset.reset = controlledRerender;
  if (languageToggle) ffReset.language = languageToggle;

  return (
    <FastField {...restProps} {...ffReset}>
      {children}
    </FastField>
  );
}
