import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserSession } from "src/context/UserSessionContext";
import { OrganizationJobPostDTO } from "src/generated/api_types";
import { nsCoordinatorAndSupportTools, nsUniversalDatatables } from "src/i18n/Namespaces";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { useOrgJobsDatatable } from "src/pages/medicaidAccount/coordinatorAccount/MedicaidCoordJobsDashboard";
import Constraint from "src/reusable_view_elements/Constraint";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { StartImpersonationButton } from "src/reusable_view_elements/StartImpersonationButton";
import CivColors from "src/themes/civilization/CivColors";

export const ConsumerJobsList = () => {
  const { userSession, isImpersonated } = useUserSession();
  const { ClientsideDatatable, isFetching } = useOrgJobsDatatable();
  const { t, ready } = useTranslation([nsUniversalDatatables, nsCoordinatorAndSupportTools]);

  const [defaultJobCoordFilter, setDefaultJobCoordFilter] = useState("");

  useEffect(() => {
    setDefaultJobCoordFilter(localStorage.getItem("default-job-coord-filter") || "");
    localStorage.removeItem("default-job-coord-filter");
  }, []);

  const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<OrganizationJobPostDTO> } = {
    jobNumber: {
      title: t("job_id.label", { ns: nsUniversalDatatables }),
      field: "readableJobId",
    },
    firstName: {
      title: t("first_name.label", { ns: nsUniversalDatatables }),
      field: "firstName",
    },
    city: {
      title: t("city.label", { ns: nsUniversalDatatables }),
      field: "city",
    },
    coordinator: {
      title: t("coordinator.label", { ns: nsUniversalDatatables }),
      field: "coordinatorName",
      defaultFilter: defaultJobCoordFilter,
    },
    impersonate: {
      title: t("impersonate.label", { ns: nsUniversalDatatables }),
      render: (data) => {
        return (
          <>
            {data.coordinatorId !== userSession?.id && (
              <StartImpersonationButton
                userId={data.coordinatorId}
                userDisplayName={data.coordinatorName}
                label={t("actions.login.label", { ns: nsUniversalDatatables })}
                variant="text"
                fullWidth
                style={{
                  paddingLeft: 5,
                  fontFamily: "Europa-regular",
                  justifyContent: "left",
                }}
                returnRoute={Medicaid_Coordinator_Dashboard_Routes.jobs_list}
                disabled={isImpersonated()}
              />
            )}
          </>
        );
      },
    },
  };

  if (!ready) {
    return (
      <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
        <Constraint columns={6}>
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  return (
    <ClientsideDatatable
      columnDefinitions={COLUMN_DEFINITIONS}
      persistKeyPrefix="consumer-jobs-list"
      title={t("all_consumer_jobs.label", { ns: nsCoordinatorAndSupportTools })}
      localization={{
        body: {
          emptyDataSourceMessage: isFetching
            ? `${t("fetching_consumer_list.description", { ns: nsCoordinatorAndSupportTools })}...`
            : t("no_consumer_jobs.description", { ns: nsCoordinatorAndSupportTools }),
          filterRow: {
            filterTooltip: t("filter.label", { ns: nsUniversalDatatables }),
          },
        },
        toolbar: {
          addRemoveColumns: t("add_remove_columns.label", { ns: nsUniversalDatatables }),
          showColumnsTitle: t("show_hide_columns.label", { ns: nsUniversalDatatables }),
          showColumnsAriaLabel: t("show_hide_columns.label", { ns: nsUniversalDatatables }),
          searchPlaceholder: t("search.label", { ns: nsUniversalDatatables }),
        },
        pagination: {
          labelDisplayedRows: `{from}-{to} ${t("of.label", { ns: nsUniversalDatatables })} {count}`,
          // labelRowsSelect: t("rows.label", { ns: nsUniversalDatatables }), // TODO: Unsure which one to map to
          firstAriaLabel: t("first_page.label", { ns: nsUniversalDatatables }),
          firstTooltip: t("first_page.label", { ns: nsUniversalDatatables }),
          previousAriaLabel: t("previous_page.label", { ns: nsUniversalDatatables }),
          previousTooltip: t("previous_page.label", { ns: nsUniversalDatatables }),
          nextAriaLabel: t("next_page.label", { ns: nsUniversalDatatables }),
          nextTooltip: t("next_page.label", { ns: nsUniversalDatatables }),
          lastAriaLabel: t("last_page.label", { ns: nsUniversalDatatables }),
          lastTooltip: t("last_page.label", { ns: nsUniversalDatatables }),
        },
      }}
    />
  );
};
