import { Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { useNotification } from "src/context/NotificationContext";
import { ChildcareInvitesController_ChildcareInviteDTO } from "src/generated/api_types";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { createChildcareInvite } from "src/pages/supportAdminAccount/forms/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Button } from "src/reusable_view_elements/Button";
import Form from "src/reusable_view_elements/form_fields/Form";
import { DescriptionEmphasis, PanelTitle } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

const childcareValidationSchema = object().shape({
  firstName: string().required("Error: Please enter a first name"),
  lastName: string().required("Error: Please enter a last name"),
  email: string()
    .required("Error: Please enter a valid email address")
    .email("Error: Please enter a valid email address"),
  license: string().required("Please enter Verifier's license number"),
  verifiersFirstName: string().required("Please enter Verifier's First Name"),
  verifiersLastName: string().required("Please enter Verifier's Last Name"),
  verifiersEmail: string().required("Please enter Verifier's Email").email("Please enter a valid Verifier's Email"),
});

interface ChildcareInviteFieldValues {
  firstName: string;
  lastName: string;
  email: string;
  license: string;
  notes: string;
  verifiersFirstName: string;
  verifiersLastName: string;
  verifiersEmail: string;
}

const inviteInitialValues: ChildcareInviteFieldValues = {
  firstName: "",
  lastName: "",
  email: "",
  license: "",
  notes: "",
  verifiersFirstName: "",
  verifiersLastName: "",
  verifiersEmail: "",
};

export interface UsersInviteFormProps {
  closeCallback: () => void;
}

const ChildcareUsersInviteForm = ({ closeCallback }: UsersInviteFormProps) => {
  const { program } = useSupportAdminContext();
  const { showSnackbar } = useNotification();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (values: ChildcareInviteFieldValues) => {
    const dto: ChildcareInvitesController_ChildcareInviteDTO = {
      funderId: program,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      verificationInputsJson: JSON.stringify({
        verifiersFirstName: values.verifiersFirstName,
        verifiersLastName: values.verifiersLastName,
        verifiersEmail: values.verifiersEmail,
      }),
      providerLicenseNumber: values.license,
      notes: values.notes,
      verifierFirstName: values.verifiersFirstName,
      verifierLastName: values.verifiersLastName,
      verifierEmail: values.verifiersEmail,
    };
    setSubmitting(true);
    createChildcareInvite(dto)
      .then((res) => {
        if (res.data.error) {
          setSubmitting(false);
          showSnackbar(res.data.error.message, "error");
        } else if (res.data.result) {
          setSubmitting(false);
          showSnackbar(`Successfully created invite ${res.data.result}`, "success");
          closeCallback();
        }
      })
      .catch(() => {
        setSubmitting(false);
        showSnackbar("Error creating invite", "error");
      });
  };

  return (
    <>
      <Formik initialValues={inviteInitialValues} validationSchema={childcareValidationSchema} onSubmit={handleSubmit}>
        <Form localizationReady={true}>
          <Grid container spacing={2}>
            <Grid container item justifyContent="space-between">
              <Grid item xs={10}>
                <PanelTitle>Invite User</PanelTitle>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <IconButton onClick={closeCallback}>
                  <CloseRoundedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="firstName"
                label="First Name"
                type="text"
                required={isRequiredField("firstName", childcareValidationSchema)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="lastName"
                label="Last Name"
                type="text"
                required={isRequiredField("lastName", childcareValidationSchema)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="email"
                label="Email"
                type="email"
                required={isRequiredField("email", childcareValidationSchema)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="license"
                label="Provider license number"
                type="text"
                required={isRequiredField("license", childcareValidationSchema)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="notes"
                label="Notes"
                type="text"
                required={isRequiredField("notes", childcareValidationSchema)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: "1.0rem",
                marginTop: 20,
              }}
            >
              <DescriptionEmphasis>Verifier&#39;s Information</DescriptionEmphasis>
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="verifiersFirstName"
                label="Verifier's First Name"
                type="text"
                required={isRequiredField("verifiersFirstName", childcareValidationSchema)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="verifiersLastName"
                label="Verifier's Last Name"
                type="text"
                required={isRequiredField("verifiersLastName", childcareValidationSchema)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="verifiersEmail"
                label="Verifier's Email"
                type="email"
                required={isRequiredField("verifiersEmail", childcareValidationSchema)}
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button variant="text" type="button" onClick={closeCallback}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                Send Invite
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default ChildcareUsersInviteForm;
