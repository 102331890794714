import {
  alpha,
  Checkbox as MCheckbox,
  CheckboxProps,
  FormControl,
  FormControlLabel as MFormControlLabel,
  FormHelperText as MFormHelperText,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, ErrorMessageProps, FastFieldProps, FieldAttributes } from "formik";
import { ReactElement, useState } from "react";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ErrorGuidance from "src/reusable_view_elements/ErrorGuidance";
import {
  ControllableFastField,
  ControllableFastFieldProps,
} from "src/reusable_view_elements/form_fields/ControllableFastField";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

export const CheckboxControlLabel = styled(MFormControlLabel)({
  fontVariantLigatures: "no-contextual",
  marginLeft: "initial", // indents the control per Figma design; default is negative margin
  alignItems: "flex-start", // unique to checkboxes; aligns top of checkbox with label
  "& .MuiCheckbox-root": {
    padding: 12,
  },
  "& .MuiFormControlLabel-label": {
    margin: 0,
    padding: "12px 12px 12px 0",
    lineHeight: "24px",
    "&.Mui-disabled": {
      color: CivColors.mediumGray,
    },
  },
});

export const Checkbox = styled((props: CheckboxProps) => (
  <MCheckbox checkedIcon={<CheckBoxRoundedIcon />} icon={<CheckBoxOutlineBlankRoundedIcon />} {...props} />
))({
  "& .MuiSvgIcon-root": {
    height: 24,
    width: 24,
    color: CivColors.coreDarkNavy,
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      color: CivColors.coreOrange,
    },
  },
  "&.Mui-disabled": {
    "& .MuiSvgIcon-root": {
      color: CivColors.mediumGray,
    },
  },
  "& .MuiTouchRipple-child": {
    //The TouchRipple component holds the animation for the 'focus circle' around the checkbox
    //Its final color already has opacity 0.3, so we divide by 0.3 to get the 0.08 opacity per Figma design
    backgroundColor: alpha(CivColors.coreOrange, 0.08 / 0.3),
  },
  "&:hover": {
    backgroundColor: alpha(CivColors.coreOrange, 0.08),
  },
});

export const FormHelperText = styled(MFormHelperText)({
  margin: 0,
  paddingTop: 4,
  color: CivColors.coreGray,
  "&.Mui-disabled": {
    color: CivColors.mediumGray,
  },
  "&.Mui-error": {
    color: CivColors.deepRose,
  },
});

interface CheckboxErrorMessageProps extends ErrorMessageProps {
  BodyProps?: Partial<TypographyProps>;
}

export function CheckboxErrorMessage({ BodyProps, ...rest }: CheckboxErrorMessageProps) {
  return (
    <ErrorMessage
      render={(msg) => (
        <Body
          color="error"
          {...BodyProps}
          style={{
            ...BodyProps?.style,
            fontSize: "1.0rem",
            marginLeft: 14,
            marginTop: 8,
          }}
        >
          {msg}
        </Body>
      )}
      {...rest}
    />
  );
}

export interface CheckboxOption<Val> {
  label: string | ReactElement;
  value: Val;
  locizeKey?: string;
  namespace?: string;
}

type CheckboxFieldProps<_Val, FFControl> = {
  name: string;
  label: string | ReactElement;
  helperText?: string;
} & Omit<FieldAttributes<any>, "name" | "label"> &
  Omit<ControllableFastFieldProps<FFControl>, "name">;

export function CheckboxField<Val, FFControl>(props: CheckboxFieldProps<Val, FFControl>) {
  const { controlledRerender, ...restProps } = props;
  //'meta.touched' gets triggered when the user clicks the submit button
  //'touched' gets triggered when the user clicks on a checkbox
  const [touched, setTouched] = useState(false);

  return (
    <ControllableFastField
      name={restProps.name}
      controlledRerender={controlledRerender}
      languagePeek={props.label as string}
    >
      {({ field, meta, form }: FastFieldProps) => (
        <FormControl disabled={props.disabled} required={props.required}>
          <CheckboxControlLabel
            control={
              <Checkbox
                id={props.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase())}
                checked={!!field.value}
                onChange={(e) => {
                  field.onChange(e);
                  setTouched(true);
                  form.setFieldValue(field.name, !field.value);
                }}
              />
            }
            label={props.label}
          />
          {(props.helperText || Boolean(meta.error && (touched || meta.touched))) && (
            <FormHelperText error={Boolean(meta.error && (touched || meta.touched))}>
              {meta.error && (touched || meta.touched) ? (
                <ErrorGuidance helperText={meta.error} />
              ) : (
                <Body>{props.helperText}</Body>
              )}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </ControllableFastField>
  );
}
