import { Box, Grid, IconButton } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import {
  Action,
  BehaviorCategoryEnum,
  BehaviorEnum,
  ReportStatusEnum,
  UserReportActionDTO,
  UserReportDetailsDTO,
} from "src/generated/api_types";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { BehaviorCategoryOptions, BehaviorOptions } from "src/pages/medicaidAccount/sharedComponents/ReportUserModal";
import { getReportDetails, usersAdministrationController } from "src/pages/supportAdminAccount/ControllerClients";
import {
  behaviorLabels,
  categoryLabels,
} from "src/pages/supportAdminAccount/dashboard/components/DatatableDefinitions";
import { getDashboardTitle } from "src/pages/supportAdminAccount/dashboard/components/UserDetails";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import { Column, Datatable } from "src/reusable_view_elements/datatable/Datatable";
import { FormSelectField, FormTextField, SelectOption } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import PageContainerDashboard, { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import { impersonateUser } from "src/reusable_view_elements/StartImpersonationButton";
import Tooltip from "src/reusable_view_elements/Tooltip";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import { applyYyyyMMddFormat, applyYyyyMMddHHmmFormat } from "src/utilities/DashboardUtilities";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

interface UserReportDetailsProps {
  getDetails: typeof getReportDetails;
}

interface FormFields {
  status: ReportStatusEnum;
  category: BehaviorCategoryEnum;
  behavior: BehaviorEnum;
  action?: Action;
  actionNotes: string;
}

const ACTION_NOTES_MAX = 500;

const schema = object().shape({
  behavior: string().matches(/\b(?!DEFAULT\b)\w+/, "Please select one behavior"),
  action: string().required("Please select one action"),
  actionNotes: string()
    .required("Please provide comments")
    .max(ACTION_NOTES_MAX, `Maximum text length ${ACTION_NOTES_MAX} characters`),
});

const statusOptions: SelectOption<ReportStatusEnum>[] = [
  { value: ReportStatusEnum.RESOLVED, label: "Resolved" },
  { value: ReportStatusEnum.PENDING, label: "Pending" },
  { value: ReportStatusEnum.UNPROCESSED, label: "Unprocessed" },
];

const actionOptions: SelectOption<Action>[] = [
  { value: Action.STATUS_CHANGE, label: "Status Changed" },
  { value: Action.MESSAGE_REPORTER, label: "Messaged Reporter" },
  { value: Action.ADD_NOTES, label: "Added Notes" },
  { value: Action.MESSAGE_OFFENDER, label: "Messaged Offender" },
  { value: Action.SUSPEND_OFFENDER, label: "Suspended Offender" },
  { value: Action.UNSUSPEND_OFFENDER, label: "Un-suspended Offender" },
  { value: Action.DELETE_OFFENDER, label: "Deleted Offender's Account" },
  { value: Action.BLOCK_OFFENDER, label: "Blocked Offender" },
  { value: Action.REPORT_DSHS, label: "Reported to DSHS" },
  { value: Action.OTHER_ACTION, label: "Other Action" },
  { value: Action.BEHAVIOR_CHANGE, label: "Behavior Category Changed" },
];

const getActionLabel = (data: UserReportActionDTO) => {
  const filteredArr = actionOptions.filter((option) => option.value === data.action);
  return filteredArr.length > 0 ? filteredArr[0].label : "";
};

const ACTION_HISTORY_COLUMN_DEFINITIONS: { [k: string]: Column<UserReportActionDTO> } = {
  caseNumber: {
    title: "Case #",
    field: "caseNumber",
  },
  reportedDate: {
    title: "Date",
    render: (data) => applyYyyyMMddHHmmFormat(data.date),
    customSort: (data1, data2) => data1.date - data2.date,
    defaultSort: "desc",
  },
  action: {
    title: "Action",
    render: (data) => getActionLabel(data),
    customSort: (data1, data2) =>
      getActionLabel(data1).toLowerCase().localeCompare(getActionLabel(data2).toLowerCase()),
  },
  by: {
    title: "By",
    field: "by",
  },
  actionNotes: {
    title: "Notes",
    render: (data) => (
      <Box sx={{ textAlign: "center" }}>
        <Tooltip title={data.actionNotes} data-testid="notes-icon">
          <IconButton size="large">
            <DescriptionRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  },
};

const UserReportDetails: React.FC<UserReportDetailsProps> = ({ getDetails }) => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState<UserReportDetailsDTO>();
  const [helperToggle, setHelperToggle] = useState(false); //helper to show/hide/update behavior field
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const { userSession } = useUserSession();

  useEffect(() => {
    setIsLoading(true);
    getDetails(id)
      .then((resp) => {
        setReportDetails(resp.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const initialValues = (details: UserReportDetailsDTO): FormFields => ({
    status: details.status,
    category: details.category,
    behavior: details?.behavior,
    action: undefined,
    actionNotes: "",
  });

  const handleSubmit = (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    usersAdministrationController
      .updateUserReport({
        reportId: id,
        updatedStatus: values.status,
        updatedCategory: values.category,
        updatedBehavior: values.behavior,
        action: values.action || Action.OTHER_ACTION,
        actionNotes: values.actionNotes,
      })
      .then(() => history.push(SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_cases))
      .catch(() => {
        showSnackbar("Error saving changes to this user report. Please try again.", "error");
        helpers.setSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Carina | Medicaid: User Report Details</title>
      </Helmet>

      <PageContainerDashboard title={getDashboardTitle(userSession?.role, DashboardType.MEDICAID)} maxWidth="xl">
        <DashReturnLink to={SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_cases}>Back</DashReturnLink>

        {isLoading && <LoadingCircle />}

        {!isLoading && reportDetails && (
          <>
            <Constraint columns={8} paddingTop={4}>
              <SectionTitle style={{ marginBottom: 0 }}>{`Case #${reportDetails?.caseNumber}`}</SectionTitle>
              <Body>{reportDetails?.program}</Body>
            </Constraint>
            <Formik validationSchema={schema} initialValues={initialValues(reportDetails)} onSubmit={handleSubmit}>
              {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                <Form localizationReady={true}>
                  <Constraint columns={8} paddingTop={4} paddingBottom={6}>
                    <DashSection container label="Case info" topBorder bottomBorder>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <FormSelectField name="status" label="Status" selectOptions={statusOptions} />
                        </Grid>
                        <Grid item xs={6}>
                          <BodyEmphasis>Carina Case #</BodyEmphasis>
                          <Body>{reportDetails?.caseNumber}</Body>
                        </Grid>
                        <Grid item xs={6}>
                          <BodyEmphasis>Date Reported</BodyEmphasis>
                          <Body>{applyYyyyMMddFormat(reportDetails?.reportedDate)}</Body>
                        </Grid>
                        <Grid item xs={12}>
                          <BodyEmphasis>Offender</BodyEmphasis>
                          <UserAndMessageLinks
                            userId={reportDetails?.offenderId}
                            userName={reportDetails?.offenderName}
                            userLanguage={reportDetails?.offenderLanguage}
                            reportId={reportDetails?.reportId}
                          />
                          <Body
                            /* This allows React to process the line breaks correctly */
                            dangerouslySetInnerHTML={{ __html: reportDetails?.offenderCounts }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BodyEmphasis>Reporter</BodyEmphasis>
                          <UserAndMessageLinks
                            userId={reportDetails?.reporterId}
                            userName={reportDetails?.reporterName}
                            userLanguage={reportDetails?.reporterLanguage}
                            reportId={reportDetails?.reportId}
                          />
                          <Body
                            /* This allows React to process the line breaks correctly */
                            dangerouslySetInnerHTML={{ __html: reportDetails?.reporterCounts }}
                          />
                        </Grid>
                      </Grid>
                    </DashSection>
                    <DashSection container label="Details" bottomBorder>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Body>{reportDetails?.reportNotes}</Body>
                        </Grid>
                        <Grid item xs={12}>
                          <BodyEmphasis>Category</BodyEmphasis>
                          <Body>
                            {`${categoryLabels[reportDetails.category]} > ${behaviorLabels[reportDetails.behavior]}`}
                          </Body>
                        </Grid>
                        <Grid item xs={12}>
                          <FormSelectField
                            name="category"
                            label="Edit Category"
                            selectOptions={BehaviorCategoryOptions}
                            onChange={(e) => {
                              // TODO: https://github.com/CarinaWeb/CarinaCore/issues/1007
                              //  Revisit this implementation, speciallly the case: when the error message on this
                              //  field has been triggered, if SOMETHING_ELSE category is selected, the behavior field
                              //  is automatically selected, but the error message remains displayed, and only disappear
                              //  if the user clicks anywhere in the modal
                              if (e.target.value === BehaviorEnum.SOMETHING_ELSE) {
                                setFieldValue("behavior", BehaviorEnum.SOMETHING_ELSE, false);
                              } else {
                                setFieldValue("behavior", "DEFAULT", false);
                              }
                              setHelperToggle(!helperToggle);
                            }}
                          />
                        </Grid>
                        {values.category && (
                          <Grid item xs={12}>
                            <FormSelectField
                              name="behavior"
                              label="Edit Subcategory"
                              selectOptions={BehaviorOptions[values.category]}
                              controlledRerender={helperToggle}
                              disabled={values.behavior === BehaviorEnum.SOMETHING_ELSE}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </DashSection>
                    <DashSection container label="Action" bottomBorder>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <FormSelectField name="action" label="Select Action" selectOptions={actionOptions} />
                        </Grid>
                        <Grid item xs={12}>
                          <FormTextField
                            name="actionNotes"
                            label="Notes"
                            multiline
                            minRows={8}
                            maximumlength={500}
                            helperText={""}
                            required={isRequiredField("actionNotes", schema)}
                            error={Boolean(touched.actionNotes && errors.actionNotes)}
                          />
                        </Grid>
                      </Grid>
                    </DashSection>
                    <Grid container spacing={2} style={{ paddingTop: 32 }}>
                      <Grid item xs={12}>
                        <BodyEmphasis>Action History</BodyEmphasis>
                      </Grid>
                      <Grid item xs={12}>
                        <Datatable
                          columnDefinitions={ACTION_HISTORY_COLUMN_DEFINITIONS}
                          persistKeyPrefix="action-history"
                          data={reportDetails?.actions}
                          options={{
                            search: false,
                            filtering: false,
                            paging: false,
                            idSynonym: "date",
                          }} // <- since data doesn't have id field, row needs dif unique identifier
                        />
                      </Grid>
                    </Grid>
                  </Constraint>
                  <Grid container style={{ marginBottom: 40, borderTop: "1px solid #989898", paddingTop: 20 }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <LinkStyledAsButton
                        href={SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_cases}
                        variant="text"
                        disabled={isSubmitting}
                      >
                        Cancel
                      </LinkStyledAsButton>
                      <Button variant="contained" type="submit" sx={{ marginLeft: "30px" }} disabled={isSubmitting}>
                        Update Case
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        )}
      </PageContainerDashboard>
    </>
  );
};

interface UserAndMessageLinksProps {
  userId: string;
  userName: string;
  userLanguage: string;
  reportId: string;
}

const UserAndMessageLinks: React.FC<UserAndMessageLinksProps> = (props) => {
  const { reloadAuthState, loading } = useUserSession();
  const history = useHistory();
  return (
    <>
      <Body component="span">
        {props.userName} | Preferred Language: {props.userLanguage} | (
      </Body>
      <Body
        component="span"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={async () => {
          await impersonateUser(
            props.userId,
            reloadAuthState,
            loading,
            history,
            generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.reported_details, {
              id: props.reportId,
            }),
            MEDICAID_ACCOUNT_ROUTES.inbox,
          );
        }}
      >
        See Messages
      </Body>
      <Body component="span">)</Body>
    </>
  );
};

export default UserReportDetails;
