import { Route, Switch } from "react-router-dom";
import { CoordinatorsListDTO } from "src/generated/api_types";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { CoordinatorsList } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorsList";
import {
  MedicaidCoordDashboardContainer,
  MedicaidCoordDashboardTabs,
} from "src/pages/medicaidAccount/coordinatorAccount/MedicaidCoordDashboardContainer";
import { getCoordinatorsList as getCoordinatorsListType } from "src/pages/medicaidAccount/coordinatorAccount/Queries";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useCoordinatorsDatatable,
  ClientsideDatatableContextProvider: CoordinatorsDatatableContextProvider,
} = GenerateClientsideDatatable<CoordinatorsListDTO, typeof getCoordinatorsListType>();

interface CoordinatorDashboardProps {
  getCoordinatorsList: typeof getCoordinatorsListType;
}

export const MedicaidCoordTeamDashboard: React.FC<CoordinatorDashboardProps> = ({ getCoordinatorsList }) => {
  return (
    <MedicaidCoordDashboardContainer tab={MedicaidCoordDashboardTabs.COORDINATORS}>
      <CoordinatorsDatatableContextProvider getData={getCoordinatorsList}>
        <Switch>
          <Route exact path={Medicaid_Coordinator_Dashboard_Routes.team} component={CoordinatorsList} />
        </Switch>
      </CoordinatorsDatatableContextProvider>
    </MedicaidCoordDashboardContainer>
  );
};
