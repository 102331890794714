import { Box, Grid, IconButton } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AgencyProviderListing_DTO_Response_ListView } from "src/generated/api_types";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { deleteProviderListing as deleteProviderListingType } from "src/pages/agencyAdmin/dashboard/providers/AgencyAdmin_Provider_Requests";
import { useProvidersDatatable } from "src/pages/agencyAdmin/dashboard/providers/ProviderDashboard";
import { ProviderListingInteractionDialog } from "src/pages/agencyAdmin/dashboard/providers/ProviderListingInteractionDialog";
import { getAdminNotesText } from "src/pages/carinaAdmin/dashboard/carina_admin_providers/CarinaAdminProvidersList";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";
import { ExportMenuOption } from "src/reusable_view_elements/datatable/Datatable";
import { Body } from "src/reusable_view_elements/Typography";
import {
  AddOrEditProviderRowFormFields,
  DefaultFormValues,
} from "src/reusable_view_elements/forms/AddOrEditProviderForm";
import { Button } from "src/reusable_view_elements/Button";
import Tooltip from "src/reusable_view_elements/Tooltip";

/*********************************************************
 ************* Start Column Helper Functions *************
 *********************************************************/
export function getUpdatedDate(data: AgencyProviderListing_DTO_Response_ListView): Date {
  return data.updatedAt ? new Date(data.updatedAt) : new Date();
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getHasMaleProviders(data: AgencyProviderListing_DTO_Response_ListView): string {
  return data.hasMaleProviders ? "Yes" : "No";
}

function getLanguages(data: AgencyProviderListing_DTO_Response_ListView): string[] {
  return data.languages.map((language) => _.startCase(_.lowerCase(`${language.toString().replace(/_/g, " ")}`))).sort();
}

/*********************************************************
 ******************* Column Definitions ******************
 *********************************************************/
const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<AgencyProviderListing_DTO_Response_ListView> } = {
  county: {
    title: "County",
    field: "geoData.county",
  },
  city: {
    title: "City",
    field: "geoData.city",
  },
  zip: {
    title: "ZIP code",
    field: "geoData.zipCode",
  },

  num_providers: {
    title: "Available providers",
    field: "numProviders",
  },
  male_providers: {
    title: "Male providers available?",
    field: "hasMaleProviders",
    exportTransformer: (data) => getHasMaleProviders(data),
    render: (data) => getHasMaleProviders(data),
    customFilterAndSearch: (term, data) => {
      return getHasMaleProviders(data).toLowerCase().indexOf(term.toLowerCase()) !== -1;
    },
  },

  language: {
    title: "Language",
    field: "languages",
    exportTransformer: (data) => getLanguages(data).join(", "),
    render: (data) => {
      return (
        <>
          {getLanguages(data).map((lang, index) => (
            <div key={index}>{lang}</div>
          ))}
        </>
      );
    },
    customFilterAndSearch: (term, data) => {
      return getLanguages(data).toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
    },
  },
  notes: {
    title: "Notes",
    field: "notes",
    customExport: getAdminNotesText,
    render: (data) => {
      if (data.notes) {
        return (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title={`Notes on zip code, ${data.geoData?.zipCode}: ${data.notes}`} data-testid="notes-icon">
              <IconButton size="large">
                <DescriptionRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
      return "";
    },
  },
  updated: {
    title: "Last updated",
    field: "updatedAt",
    exportTransformer: (data) => formatDate(getUpdatedDate(data)),
    render: (data) => formatDate(getUpdatedDate(data)),
    customFilterAndSearch: (term, data) => formatDate(getUpdatedDate(data)).indexOf(term) !== -1,
    customSort: (data1, data2) => getUpdatedDate(data1).getTime() - getUpdatedDate(data2).getTime(),
    defaultSort: "desc",
  },
};

/*********************************************************
 ******************* Page Definition *********************
 *********************************************************/

type ProvidersListPageProps = {
  deleteProviderListing: typeof deleteProviderListingType;
};

export const ProvidersList: React.FC<ProvidersListPageProps> = ({ deleteProviderListing }) => {
  const { getReadOnlyData, ClientsideDatatable, isFetching, removeData } = useProvidersDatatable();
  const [isModalOpen, setModalOpen] = useState(false);
  const initialValues = useRef<AddOrEditProviderRowFormFields>(DefaultFormValues);

  const getExistingZipCodes = () => {
    const listOfZipCodes: Set<string> = new Set();
    getReadOnlyData().forEach((entry: AgencyProviderListing_DTO_Response_ListView) => {
      if (entry.geoData?.zipCode) {
        listOfZipCodes.add(entry.geoData?.zipCode);
      }
    });
    return listOfZipCodes;
  };

  const handleEditAction = (event: any, rowData: any) => {
    if (rowData) {
      setModalOpen(true);
      const existingRowData = rowData as AgencyProviderListing_DTO_Response_ListView;
      initialValues.current = {
        zip: existingRowData.geoData?.zipCode || initialValues.current.zip,
        numProviders: existingRowData.numProviders,
        hasMaleProviders: existingRowData.hasMaleProviders,
        languages: existingRowData.languages,
        notes: existingRowData.notes,
        existingRowId: rowData.id,
      };
    }
  };

  const handleAddRow = () => {
    initialValues.current = DefaultFormValues;
    setModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Carina | Agency Dashboard Providers Tab</title>
      </Helmet>
      <Box paddingTop={3}>
        <Grid container justifyContent="space-between">
          <Grid item sm={9}>
            <Body paragraph>
              Carina will only send you clients who are in the general area where you have available providers. Click
              <b> Add Row</b> on the right to provide info about where there are available providers. Please keep this
              table updated so we can send you the most geographically relevant home care clients your way.
            </Body>
          </Grid>
          <Grid item>
            <Button variant="contained" type="button" onClick={handleAddRow} data-testid="add-provider-button">
              Add Row
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ProviderListingInteractionDialog
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        existingZipCodes={getExistingZipCodes()}
        initialValues={initialValues.current}
      />
      <ClientsideDatatable
        persistKeyPrefix="providers-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Agency Providers"
        editRowTooltip="Edit Provider Row"
        editRowActionFunction={handleEditAction}
        editable={{
          onRowDelete: (oldData) =>
            deleteProviderListing(oldData.id)
              .then((resp) => {
                if (resp.status === 200) {
                  removeData(oldData.id);
                } else {
                  // eslint-disable-next-line no-console
                  console.error("Failed to delete provider");
                  // eslint-disable-next-line no-console
                  console.error(`Status Code: ${resp.status}`);
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error("Failed to delete provider");
                // eslint-disable-next-line no-console
                console.error(`Error: ${err}`);
              }),
        }}
        // options={{
        //   columnsButton: false, // Hide columns button
        //   search: false, // Hide search field
        // }}
        options={{
          exportMenu: [ExportMenuOption.csv({ filename: "Agency Providers" })],
        }}
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching providers..." : "No provider records to display",
          },
        }}
      />
    </>
  );
};
