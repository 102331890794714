import {
  AxiosCarinaAdminUserManagementControllerClient,
  AxiosInviteManagementControllerClient,
  AxiosPPHCCarinaAdminDashboardAgenciesControllerClient,
  AxiosPPHCCarinaAdminDashboardFundersControllerClient,
  AxiosUserControllerClient,
  PrivatePayInvite_DTO_Request_Update,
  UserController_RequestDeactivationDTO,
} from "src/generated/api_types";

const inviteClient = new AxiosInviteManagementControllerClient();
const agencyClient = new AxiosPPHCCarinaAdminDashboardAgenciesControllerClient();
const agencyAdminClient = new AxiosPPHCCarinaAdminDashboardAgenciesControllerClient();
const carinaAdminClient = new AxiosCarinaAdminUserManagementControllerClient();
const funderClient = new AxiosPPHCCarinaAdminDashboardFundersControllerClient();
const userClient = new AxiosUserControllerClient();

export async function callReinvite(id: string) {
  return inviteClient.reinvite(id);
}

export async function generateInvite(firstName: string, lastName: string, email: string, agencyId: string) {
  return inviteClient.create({ firstName, lastName, email, agencyId });
}

export async function getAllAgencies() {
  return agencyClient.listAllAgencies();
}

export async function getAgencyInvites() {
  return inviteClient.getAgencyAdminInvites();
}

export async function getInviteDetails(id: string) {
  return inviteClient.getDetails(id);
}

export async function updateInvite(id: string, updateDTO: PrivatePayInvite_DTO_Request_Update) {
  return inviteClient.update(id, updateDTO);
}

export async function requestPasswordReset(userId: string) {
  return carinaAdminClient.requestPasswordReset(userId);
}

export async function requestDeactivation(deactivateDTO: UserController_RequestDeactivationDTO) {
  return userClient.requestDeactivation(deactivateDTO);
}

export async function getAllFunders() {
  return funderClient.listAllFunders();
}

export async function getAgencyAdmin(id: string) {
  return agencyAdminClient.getAgencyAdmin(id);
}
