import { Container, ContainerProps } from "@mui/material";
import DashboardNavbar from "src/reusable_view_elements/navbars/DashboardNavbar";

const containerStyle = {
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 24,
  paddingBottom: 24,
};

const PageContainer = ({ children, ...props }: ContainerProps) => (
  <>
    <DashboardNavbar />
    <main id="main-content">
      <Container style={containerStyle} {...props}>
        {children}
      </Container>
    </main>
  </>
);

export default PageContainer;
