import { useMediaQuery } from "@mui/material";
import Constraint from "src/reusable_view_elements/Constraint";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import Footer from "src/reusable_view_elements/Footer";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

interface LoadingProps {
  showNav?: boolean;
}

const LoadingDefaults = {
  showNav: false,
};

const LoadingPage = ({ showNav }: LoadingProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  return (
    <>
      {showNav && <NavBar showLanguage />}

      <main id="main-content">
        <Section
          bgcolor={CivColors.lightGray}
          style={desktopSize ? { paddingTop: "200px", paddingBottom: "200px" } : {}}
        >
          <Constraint columns={6} textAlign="center">
            <LoadingCircle />
          </Constraint>
        </Section>
      </main>
      <Footer />
    </>
  );
};

LoadingPage.defaultProps = LoadingDefaults;
export default LoadingPage;
