import { Route, Switch } from "react-router-dom";
import { AgencyProviderListing_DTO_Response_ListView } from "src/generated/api_types";
import {
  DashboardContainer,
  DashboardTabs,
} from "src/pages/agencyAdmin/dashboard/dashboard_components/DashboardContainer";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import {
  deleteProviderListing,
  getAllAgencyProviderListings as getAllAgencyProviderListingsType,
} from "src/pages/agencyAdmin/dashboard/providers/AgencyAdmin_Provider_Requests";
import { ProvidersList } from "src/pages/agencyAdmin/dashboard/providers/ProvidersList";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useProvidersDatatable,
  ClientsideDatatableContextProvider: ProvidersDatatableContextProvider,
} = GenerateClientsideDatatable<
  AgencyProviderListing_DTO_Response_ListView,
  typeof getAllAgencyProviderListingsType,
  (agencyId: string) => void
>();

interface ProviderDashboardContainerProps {
  getProviderListings: typeof getAllAgencyProviderListingsType;
}

export const ProviderDashboard: React.FC<ProviderDashboardContainerProps> = ({ getProviderListings }) => {
  return (
    <DashboardContainer tab={DashboardTabs.PROVIDERS}>
      <ProvidersDatatableContextProvider getData={getProviderListings}>
        <Switch>
          <Route exact path={DASHBOARD_ROUTES.provider_list}>
            <ProvidersList deleteProviderListing={deleteProviderListing} />
          </Route>
        </Switch>
      </ProvidersDatatableContextProvider>
    </DashboardContainer>
  );
};
