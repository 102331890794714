import { Route, Switch } from "react-router-dom";
import { CarinaAdminFunderManagementController_FunderListingDTO } from "src/generated/api_types";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { FunderMgmtList } from "src/pages/carinaAdmin/management/funders/FunderMgmtList";
import { getFunders as getFundersType } from "src/pages/carinaAdmin/management/funders/Queries";
import { GenerateClientsideDatatable } from "src/reusable_view_elements/datatable/ClientsideDatatable";

export const {
  useClientsideDatatable: useFunderMgmtDatatable,
  ClientsideDatatableContextProvider: FunderMgmtDatatableContextProvider,
} = GenerateClientsideDatatable<CarinaAdminFunderManagementController_FunderListingDTO, typeof getFundersType>();

interface FunderMgmtRootProps {
  getFunders: typeof getFundersType;
}

export const FunderMgmtRoot: React.FC<FunderMgmtRootProps> = ({ getFunders }) => {
  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
      <FunderMgmtDatatableContextProvider getData={getFunders}>
        <Switch>
          <Route exact path={CARINA_ADMIN_MGMT_ROUTES.funder_mgmt}>
            <FunderMgmtList />
          </Route>
        </Switch>
      </FunderMgmtDatatableContextProvider>
    </CarinaAdminMgmtContainer>
  );
};
