import { Grid, useMediaQuery } from "@mui/material";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import HomeCareProcessMessageSVG from "src/assets/images/emans-homecare-process-message.svg";
import theme from "src/themes/civilization/CivTheme";
import DesktopSearchStep1A from "src/assets/tutorial/provider/desktop-2-1a.jpg";
import DesktopSearchStep1B from "src/assets/tutorial/provider/desktop-2-1b.jpg";
import DesktopSearchStep2a from "src/assets/tutorial/provider/desktop-2-2a.jpg";
import DesktopSearchStep2b from "src/assets/tutorial/provider/desktop-2-2b.jpg";
import MobileSearchStep1A from "src/assets/tutorial/provider/mobile-2-1a.jpg";
import MobileSearchStep1B from "src/assets/tutorial/provider/mobile-2-1b.jpg";
import MobileSearchStep2a from "src/assets/tutorial/provider/mobile-2-2a.jpg";
import MobileSearchStep2b from "src/assets/tutorial/provider/mobile-2-2b.jpg";

const Message = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={HomeCareProcessMessageSVG} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Message</SectionTitle>
            <Body paragraph>
              Once you find a consumer you&apos;re interested in working with,{" "}
              <b>message that consumer and say &#34;Hello!&#34; and introduce yourself</b>.
            </Body>
            <Body paragraph>
              You can also exchange phone numbers or set up a time to meet in person for an interview.
            </Body>
          </Grid>
          {!onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={HomeCareProcessMessageSVG} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Send a message</BodyTitle>

        <Constraint columns={6}>
          <Body paragraph>Click &#34;Message&#34; on the job post page.</Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep1A : MobileSearchStep1A} alt="" width="100%" />
        <Constraint columns={6} pt={4}>
          <Body paragraph>Fill out the form to introduce yourself. Once you&apos;re done, click &#34;Send&#34;.</Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep1B : MobileSearchStep1B} alt="" width="100%" />
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. See your messages</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>
            {onDesktop
              ? `To view the messages you have sent and received, click "Messages" at the top of the page.`
              : `To view the messages you have sent and received, click "Messages" from the Menu.`}
          </Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep2a : MobileSearchStep2a} alt="" width="100%" />
        <Constraint columns={6} pt={6} pb={4}>
          <Body>Click on the message you would like to read to open it.</Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep2b : MobileSearchStep2b} alt="" width="100%" />
      </Segment>
    </>
  );
};

export default Message;
