import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { ROUTES } from "src/MainRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const NotFound = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  return (
    <>
      <Helmet>
        <title>Carina | Page Not Found</title>
      </Helmet>

      <NavBar />

      <main id="main-content">
        <Section
          bgcolor={CivColors.lightGray}
          style={desktopSize ? { paddingTop: "200px", paddingBottom: "200px" } : {}}
        >
          <Constraint columns={6} textAlign="center">
            <SectionTitle>Hm... This page does not exist.</SectionTitle>
            <BodyEmphasis paragraph>Error code: 404</BodyEmphasis>
            <Body paragraph>No need to worry. You can return home to find what you were looking for.</Body>
            <LinkStyledAsButton variant="contained" href={ROUTES.root}>
              Return Home
            </LinkStyledAsButton>
          </Constraint>
        </Section>
      </main>

      <Footer englishOnly />
    </>
  );
};

export default NotFound;
