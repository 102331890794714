import { useState } from "react";
import { createContainer } from "unstated-next";

function consumerAccountContext() {
  //state variables for My Deleted Jobs page
  const [myDeletedJobIdList, setMyDeletedJobIdList] = useState<string[]>([]);
  const [deletedDisplayedPageNumber, setDeletedDisplayedPageNumber] = useState<number>(1);
  const [deletedTotalPages, setDeletedTotalPages] = useState<number>(0);

  return {
    myDeletedJobIdList,
    setMyDeletedJobIdList,
    deletedDisplayedPageNumber,
    setDeletedDisplayedPageNumber,
    deletedTotalPages,
    setDeletedTotalPages,
  };
}

const ConsumerAccountContext = createContainer(consumerAccountContext);

export const ConsumerAccountContextProvider: React.FC = ({ children }) => (
  <ConsumerAccountContext.Provider>{children}</ConsumerAccountContext.Provider>
);

export const useConsumerAccountContext = () => ConsumerAccountContext.useContainer();
