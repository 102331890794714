import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CarinaLogo from "src/assets/images/logo-carina-version-2-white-outline.svg";
import FBLogo from "src/assets/images/logo-facebook-white.svg";
import InstagramLogo from "src/assets/images/logo-instagram-white.svg";
import LinkedInLogo from "src/assets/images/logo-linkedin-white.svg";
import YouTubeLogo from "src/assets/images/logo-youtube-white.svg";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonAlt, nsCommonNavigation } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import { ExternalLink, InternalLink } from "src/reusable_view_elements/Link";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const FacebookLink = "https://www.facebook.com/CarinaCareMatch";
const LinkedInLink = "https://www.linkedin.com/company/carinacarematch";
const YouTubeLink = "https://www.youtube.com/channel/UCsqXPSf3x00y3Ocq5YQOVLw";
const InstagramLink = "https://www.instagram.com/carinacarematch";
const CareersLink =
  "https://recruiting2.ultipro.com/SEI1003SEIB/JobBoard/a67b6b36-d87c-4b5f-aef3-7a2f2b683702/?q=&o=postedDateDesc";

interface FooterProps {
  englishOnly?: boolean; // TODO: https://github.com/CarinaWeb/CarinaCore/issues/990: Remove this after localization
}

const FooterDefaults = {
  englishOnly: false,
};

const Footer = ({ englishOnly }: FooterProps) => {
  const { t, ready } = useTranslation([nsCommonAlt, nsCommonNavigation]);
  const location = useLocation();
  const { userSession, hasMedicaidAccess } = useUserSession();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const getCurrentYear = () => new Date().getFullYear();

  const LinkSetTwo = [
    {
      to: ROUTES.termsOfService,
      text: ready && !englishOnly ? t("link.terms_of_service", { ns: nsCommonNavigation }) : "Terms of Service",
    },
    {
      to: ROUTES.privacyPolicy,
      text: ready && !englishOnly ? t("link.privacy_policy", { ns: nsCommonNavigation }) : "Privacy Policy",
    },
  ];

  let findWorkOrCareLinkSet: { to: string; text: string }[] = [];
  if (!userSession) {
    findWorkOrCareLinkSet = findWorkOrCareLinkSet.concat([
      {
        to: ROUTES.homeCareOptions,
        text: ready && !englishOnly ? t("link.find_home_care", { ns: nsCommonNavigation }) : "Find Home Care",
      },
      {
        to: CHILD_CARE_ROUTES.landing,
        text: ready && !englishOnly ? t("link.find_child_care", { ns: nsCommonNavigation }) : "Find Child Care",
      },
      {
        to: ROUTES.providers,
        text: ready && !englishOnly ? t("link.provide_care", { ns: nsCommonNavigation }) : "Provide Care",
      },
    ]);
  } else if (hasMedicaidAccess()) {
    findWorkOrCareLinkSet = findWorkOrCareLinkSet.concat([
      {
        to: MEDICAID_ACCOUNT_ROUTES.browseJobs,
        text: ready && !englishOnly ? t("link.find_jobs", { ns: nsCommonNavigation }) : "Find Jobs",
      },
      {
        to: MEDICAID_ACCOUNT_ROUTES.browseProviders,
        text: ready && !englishOnly ? t("link.find_providers", { ns: nsCommonNavigation }) : "Find Providers",
      },
    ]);
  }

  const carinaLinkSet = [
    {
      to: ROUTES.aboutCarina,
      text: ready && !englishOnly ? t("link.about_carina", { ns: nsCommonNavigation }) : "About Carina",
    },
    { to: ROUTES.blog, text: ready && !englishOnly ? t("link.blog", { ns: nsCommonNavigation }) : "Carina Blog" },
  ];

  return (
    <Section bgcolor={CivColors.coreDarkNavy} component="footer" sx={{ padding: 0 }}>
      <Constraint maxWidth="1280px" columns={14}>
        <Grid
          container
          gap={desktopSize ? 3 : 6}
          sx={{ padding: desktopSize ? "64px 24px 48px" : "64px 16px 48px" }}
          wrap={desktopSize ? "nowrap" : "wrap"}
        >
          {findWorkOrCareLinkSet.length > 0 && (
            <Grid item xs={12} sx={{ width: "100%" }}>
              <BodyEmphasis color={CivColors.white}>
                {ready && !englishOnly
                  ? t("footer.find_work_or_care", { ns: nsCommonNavigation })
                  : "Find Work or Care"}
              </BodyEmphasis>
              {findWorkOrCareLinkSet.map((link) => (
                <Body sx={{ marginTop: 3 }} key={link.text}>
                  <InternalLink to={link.to} color={CivColors.white}>
                    {link.text}
                  </InternalLink>
                </Body>
              ))}
            </Grid>
          )}
          <Grid item xs={12} sx={{ width: "100%" }}>
            <BodyEmphasis color={CivColors.white}>Carina</BodyEmphasis>
            {carinaLinkSet.map((link) => (
              <Body sx={{ marginTop: 3 }} key={link.text}>
                <InternalLink to={link.to} color={CivColors.white}>
                  {link.text}
                </InternalLink>
              </Body>
            ))}
            <Body style={{ color: CivColors.white, marginTop: 24, marginBottom: 8 }}>
              <ExternalLink to={CareersLink}>
                {ready && !englishOnly ? t("link.careers", { ns: nsCommonNavigation }) : "Careers"}
              </ExternalLink>
            </Body>
          </Grid>

          <Grid item xs={12} sx={{ width: "100%" }}>
            <BodyEmphasis color={CivColors.white}>
              {ready && !englishOnly ? t("footer.support", { ns: nsCommonNavigation }) : "Support"}
            </BodyEmphasis>
            <Body sx={{ marginTop: 3, marginBottom: 1 }}>
              <InternalLink
                to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }}
                color={CivColors.white}
              >
                {ready && !englishOnly ? t("link.contact_us", { ns: nsCommonNavigation }) : "Contact Us"}
              </InternalLink>
            </Body>
            <BodyEmphasis color={CivColors.white} sx={{ marginTop: 3 }}>
              {t("footer.operating_hours", { ns: nsCommonNavigation })}
            </BodyEmphasis>
            <Body color={CivColors.white}>
              {ready && !englishOnly ? t("footer.monday_friday", { ns: nsCommonNavigation }) : "Monday through Friday"}
            </Body>
            <Body color={CivColors.white}>
              {ready && !englishOnly
                ? t("footer.eight_to_four", { ns: nsCommonNavigation })
                : "8 a.m—4:30 p.m. (Pacific Time)"}
            </Body>
            <Body color={CivColors.white}>1-855-796-0605</Body>
          </Grid>
        </Grid>
        <Grid item container sx={{ padding: "0px 24px 48px" }} gap={3} wrap={desktopSize ? "nowrap" : "wrap"}>
          <Grid item sx={{ textWrap: "nowrap", alignSelf: "center" }}>
            <BodyEmphasis color={CivColors.white}>
              {ready && !englishOnly ? t("footer.social_media", { ns: nsCommonNavigation }) : "Social Media"}
            </BodyEmphasis>
          </Grid>
          <Grid item gap={3} container>
            <Grid item>
              <ExternalLink to={FacebookLink} sx={{ display: "flex" }}>
                <img
                  src={FBLogo}
                  alt={
                    ready && !englishOnly
                      ? t("footer.view_facebook_page", { ns: nsCommonNavigation })
                      : "View Facebook Page"
                  }
                  height={48}
                />
              </ExternalLink>
            </Grid>
            <Grid item>
              <ExternalLink to={LinkedInLink} sx={{ display: "flex" }}>
                <img
                  src={LinkedInLogo}
                  alt={
                    ready && !englishOnly
                      ? t("footer.view_linkedin_page", { ns: nsCommonNavigation })
                      : "View LinkedIn Page"
                  }
                  height={48}
                />
              </ExternalLink>
            </Grid>
            <Grid item>
              <ExternalLink to={YouTubeLink} sx={{ display: "flex" }}>
                <img
                  src={YouTubeLogo}
                  alt={
                    ready && !englishOnly
                      ? t("footer.view_youtube_page", { ns: nsCommonNavigation })
                      : "View Youtube Page"
                  }
                  height={48}
                />
              </ExternalLink>
            </Grid>
            <Grid item>
              <ExternalLink to={InstagramLink} sx={{ display: "flex" }}>
                <img
                  src={InstagramLogo}
                  alt={
                    ready && !englishOnly
                      ? t("footer.view_instagram_page", { ns: nsCommonNavigation })
                      : "View Instagram Page"
                  }
                  height={48}
                />
              </ExternalLink>
            </Grid>
          </Grid>
        </Grid>
      </Constraint>
      <Box sx={{ borderTop: `1px solid ${CivColors.white}` }} />
      <Constraint maxWidth="1280px" columns={14} sx={{ padding: 3 }}>
        <Grid
          xs={12}
          container
          justifyContent="space-between"
          display="flex"
          rowSpacing={desktopSize ? 0 : 3}
          direction={desktopSize ? "row" : "column"}
        >
          <Grid item xs>
            <InternalLink to="/" sx={{ display: "flex" }}>
              <img src={CarinaLogo} alt={t("navigation.carina_logo", { ns: nsCommonAlt })} height={66} />
            </InternalLink>
          </Grid>
          <Grid
            item
            xs
            direction={desktopSize ? "row" : "column"}
            container
            display="contents"
            rowSpacing={desktopSize ? 0 : 3}
            columnSpacing={desktopSize ? 3 : 0}
          >
            <Grid item sx={{ alignContent: "center" }}>
              <Body color={CivColors.white}>
                &#169;{" "}
                {ready && !englishOnly
                  ? t("footer.copyright", { ns: nsCommonNavigation, year: getCurrentYear() })
                  : `${getCurrentYear()} Carina. All rights reserved.`}
              </Body>
            </Grid>
            {LinkSetTwo.map((link) => (
              <Grid item key={link.text} sx={{ alignContent: "center", padding: 0 }}>
                <Body>
                  <InternalLink to={link.to} color={CivColors.white}>
                    {link.text}
                  </InternalLink>
                </Body>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Constraint>
    </Section>
  );
};

Footer.defaultProps = FooterDefaults;
export default Footer;
