import { DialogTitle, DialogTitleProps, Grid, useMediaQuery } from "@mui/material";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

// Inherits the DialogTitle MUI component and adds mobile-specific styling
// Pairs with DialogBody
const DialogHeader = ({ children, style, ...rest }: DialogTitleProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  return (
    <DialogTitle
      style={
        desktopSize
          ? style
          : {
              backgroundColor: CivColors.coreDarkNavy,
              color: CivColors.paleWhite,
              ...style,
            }
      }
      {...rest}
    >
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        {children}
      </Grid>
    </DialogTitle>
  );
};

export default DialogHeader;
