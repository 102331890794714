import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ButtonProps, Drawer, Grid, IconButton, Menu, MenuList, MenuProps } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextOptionTranslateIcon } from "src/assets/icons/StreamlineIcons";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { Permission } from "src/generated/api_types";
import { changeLanguage, LanguageLabel } from "src/i18n/Languages";
import { nsCommonAria, nsCommonNavigation, nsCommonToasts } from "src/i18n/Namespaces";
import { Button } from "src/reusable_view_elements/Button";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { Snackbar } from "src/reusable_view_elements/Snackbar";
import { Body } from "src/reusable_view_elements/Typography";

interface DesktopLanguageMenuProps extends Pick<MenuProps, "anchorEl" | "open"> {
  onClose: () => void;
}

const DesktopLanguageMenu = (props: DesktopLanguageMenuProps) => {
  const { showSnackbar } = useNotification();
  const { hasPermission } = useUserSession();
  const { t } = useTranslation([nsCommonToasts]);

  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
    >
      {Object.entries(LanguageLabel).map((item) => (
        <MenuItem
          key={item[0]}
          lang={item[0]}
          onClick={() => {
            changeLanguage(item[0], () => {
              props.onClose();
              if (hasPermission(Permission.SETTINGS_ACCESS)) {
                // The copy in this snackbar is about going to Settings pg to change default
                // website lang, so it's only applicable for users with access to Settings.
                showSnackbar(
                  t("success.we_changed_the_websites_language", {
                    ns: nsCommonToasts,
                    lng: item[0],
                    newSiteLang: LanguageLabel[item[0]],
                  }),
                  "success",
                  true,
                  false,
                );
              }
            });
          }}
          selected={item[0] === i18next.language}
        >
          {item[1]}
        </MenuItem>
      ))}
    </Menu>
  );
};

// Omit the onClose prop so that closeMenu can be used by both the Drawer and IconButton components
interface MobileLanguageMenuProps {
  closeMenu: () => void;
  open?: boolean | undefined;
}

const MobileLanguageMenu = (props: MobileLanguageMenuProps) => {
  const { t, ready } = useTranslation([nsCommonNavigation, nsCommonToasts]);
  const { showSnackbar } = useNotification();
  const { hasPermission } = useUserSession();

  const MenuTitle = () => (
    <Grid style={{ padding: "16px" }} container alignItems="center" justifyContent="space-between">
      <Body>{ready ? t("website_language_menu.label", { ns: nsCommonNavigation }) : "Choose website language"}</Body>
      <IconButton aria-label="close" onClick={props.closeMenu} size="large">
        <CloseRoundedIcon />
      </IconButton>
    </Grid>
  );

  return (
    <Drawer anchor="bottom" onClose={props.closeMenu} open={props.open}>
      <MenuList>
        <MenuTitle />
        {Object.entries(LanguageLabel).map((item) => (
          <MenuItem
            key={item[0]}
            lang={item[0]}
            selected={item[0] === i18next.language}
            onClick={() => {
              changeLanguage(item[0], () => {
                props.closeMenu();
                if (hasPermission(Permission.SETTINGS_ACCESS))
                  //TODO: [Issue#1625] Verify if this check is needed
                  showSnackbar(
                    t("success.we_changed_the_websites_language", {
                      ns: nsCommonToasts,
                      lng: item[0],
                      newSiteLang: LanguageLabel[item[0]],
                    }),
                    "success",
                    true,
                    false,
                  );
              });
            }}
          >
            {item[1]}
          </MenuItem>
        ))}
      </MenuList>
    </Drawer>
  );
};

interface LanguageButtonProps {
  fullWidth?: boolean;
  menuType: "desktop" | "mobile";
}

const LanguageButton: React.FC<LanguageButtonProps & Omit<ButtonProps, "type">> = ({
  fullWidth = false,
  menuType,
  ...props
}) => {
  const { useMainSnackbar, closeSnackbar } = useNotification();
  const { t, ready } = useTranslation(nsCommonAria);

  // Button Style
  const fullWidthStyle = fullWidth && { width: "100%" };
  const buttonStyle = {
    paddingLeft: "12px",
    paddingRight: "12px",
    ...fullWidthStyle,
  };

  // Menu state for desktop and mobile
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [desktopMenuAnchor, setDesktopMenuAnchor] = useState<HTMLElement>();
  const [lngButtonWasClicked, setLngButtonWasClicked] = useState(false);

  const enableMessageSnackbar = () => {
    closeSnackbar();
    setLngButtonWasClicked(true);
  };

  function onClickButton(event: { currentTarget: React.SetStateAction<HTMLElement | undefined> }) {
    if (menuType === "desktop") {
      setDesktopMenuAnchor(event.currentTarget);
    } else {
      setShowMobileMenu(true);
    }
    enableMessageSnackbar();
  }

  return (
    <>
      <Button
        aria-label={
          ready
            ? t("language_select.website_language", {
                ns: nsCommonAria,
                language: LanguageLabel[i18next.language],
              })
            : `Website's language: ${LanguageLabel[i18next.language]}`
        }
        variant="text"
        type="button"
        sx={buttonStyle}
        startIcon={<TextOptionTranslateIcon />}
        endIcon={<ArrowDropDownRoundedIcon style={{ padding: 0, margin: 0 }} />}
        onClick={onClickButton}
        data-testid="language-button"
        {...props}
      >
        {LanguageLabel[i18next.language]}
      </Button>
      {menuType === "desktop" && (
        <DesktopLanguageMenu
          anchorEl={desktopMenuAnchor}
          open={Boolean(desktopMenuAnchor)}
          onClose={() => setDesktopMenuAnchor(undefined)}
        />
      )}
      {menuType === "mobile" && <MobileLanguageMenu open={showMobileMenu} closeMenu={() => setShowMobileMenu(false)} />}
      <Snackbar
        isEnabled={lngButtonWasClicked && !useMainSnackbar}
        onCloseCallback={() => setLngButtonWasClicked(false)}
      />
    </>
  );
};

export default LanguageButton;
