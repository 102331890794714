import { Box, Grid } from "@mui/material";
import axios from "axios";
import { Field, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import ChatMessage from "src/pages/carinaAdmin/dashboard/merlin/components/ChatMessage";
import { FormTextField } from "src/reusable_view_elements/form_fields";
import { Checkbox, CheckboxControlLabel } from "src/reusable_view_elements/form_fields/CheckboxField";
import { Button } from "src/reusable_view_elements/Button";
import Form from "src/reusable_view_elements/form_fields/Form";
import { Body } from "src/reusable_view_elements/Typography";
import { object, string, boolean } from "yup";

const dhakaEndpoint = "https://merlin.apps.dhaka.cf-app.com/qa";
// interface DhakaReqBody {
//   question: string;
// }

// interface DhakaResBody {
//   question: string;
//   answer: string;
// }

interface MessageItem {
  isMerlin: boolean;
  text: string;
  isError?: boolean;
}

const MerlinBaseComponent = () => {
  const [messages, setMessages] = useState<MessageItem[]>([]);
  const [isInitializing, _setInitializing] = useState<boolean>(false);

  const user_input_max_length = 1000;
  const validationSchema = object({
    userInput: string()
      .required("Ask a question")
      .max(user_input_max_length, "Please shorten your prompt to be less than 1000 characters long"),
    stuffit: boolean().notRequired(),
  });

  interface FormFieldValues {
    userInput: string;
    stuffit: boolean;
  }
  function handleSubmit(values: FormFieldValues, { setSubmitting, setFieldValue }: FormikHelpers<FormFieldValues>) {
    // Append user input to the chat and show Merlin thinking during submission
    setMessages((prevMessages) => [...prevMessages, { isMerlin: false, text: values.userInput }]);
    setFieldValue("userInput", "");

    axios
      .get(dhakaEndpoint, { params: { question: values.userInput, stuffit: values.stuffit } })
      .then((res) => {
        // Append Merlin's response to the chat
        setMessages((prevMessages) => [...prevMessages, { isMerlin: true, text: res.data.answer }]);
      })
      .catch((e) => {
        setMessages((prevMessages) => [...prevMessages, { isMerlin: true, text: e.toString(), isError: true }]);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <Box>
        {messages.map((message, i) => (
          <ChatMessage isMerlin={message.isMerlin} message={message.text} my={2} isError={message.isError} key={i} />
        ))}
      </Box>

      <Formik
        initialValues={{ userInput: "", stuffit: true }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <Form localizationReady={true}>
              {(isSubmitting || isInitializing) && (
                <ChatMessage isMerlin message="Merlin is thinking..." my={2} isLoading />
              )}
              <FormTextField
                name="userInput"
                multiline
                minRows={6}
                margin="normal"
                placeholder="Ask a question..."
                onKeyDown={(e: React.KeyboardEvent) => {
                  // re-enable submitting on enter since multiline disables this
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submitForm();
                  }
                }}
              />
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Field
                    as={CheckboxControlLabel}
                    name="stuffit"
                    type="checkbox"
                    control={<Checkbox />}
                    label={<Body>Stuff it!</Body>}
                  />
                </Grid>
                <Grid item>
                  <Button disabled={isSubmitting} variant="contained" type="submit">
                    {isSubmitting ? "Merlin is thinking..." : "Send question"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MerlinBaseComponent;
