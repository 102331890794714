import { PropsWithChildren, useState } from "react";
import { AxiosEnvironmentConfigControllerClient } from "src/generated/api_types";
import { useOnce } from "src/utilities/useOnce";
import { createContainer } from "unstated-next";

export interface InitialConfig {
  environment: string;
}

// INFO: EnvName here isn't strictly typed by the backend because the backend defines EnvName as a string.
// 1. application.yml sets EnvName, then
// 2. getEnvName() falls back to local if it's null, then
// 3. frontend loads the ConfigContext (see below) to obtain the environment string.
// Although PROD is the only enum used anywhere in the frontend, all environments are included for completeness
export enum EnvName {
  LOCAL = "local",
  DEV = "dev",
  HOTFIX = "hotfix",
  LOCALIZATION = "localization",
  PREVIEW = "preview",
  PROD = "production",
}

interface TestConfig {
  __skip_config_request?: boolean;
}

function configContext(initialState?: InitialConfig & TestConfig) {
  const [environmentName, setEnvironmentName] = useState(initialState?.environment || EnvName.PROD);
  const [loading, setLoading] = useState(true);

  const isInEnv = (envName: EnvName) => {
    return environmentName === envName;
  };

  return {
    environmentName,
    setEnvironmentName,
    loading,
    setLoading,
    isInEnv,
    __skip_config_request: initialState?.__skip_config_request,
  };
}

const ConfigContext = createContainer(configContext);
const configClient = new AxiosEnvironmentConfigControllerClient();

interface ConfigContextProviderProps {
  initialState?: InitialConfig & TestConfig;
}
const ConfigContextLoader: React.FC = ({ children }) => {
  const { __skip_config_request, ...container } = ConfigContext.useContainer();

  useOnce(() => {
    if (!__skip_config_request) {
      configClient
        .config()
        .then((res) => {
          const { environmentName } = res.data;
          container.setEnvironmentName(environmentName);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(`Unable to retrieve environment configuration. Error: ${err}`);
        })
        .finally(() => {
          container.setLoading(false);
        });
    } else {
      container.setLoading(false);
    }
  });

  return <>{children}</>;
};

export const ConfigContextProvider: React.FC<PropsWithChildren<ConfigContextProviderProps>> = ({
  initialState,
  children,
}) => {
  return (
    <ConfigContext.Provider initialState={initialState}>
      <ConfigContextLoader>{children}</ConfigContextLoader>
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const { __skip_config_request, setEnvironmentName, setLoading, ...container } = ConfigContext.useContainer();

  return {
    ...container,
  };
};
