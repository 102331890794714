import { FormControl, FormControlProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { ControllableFastField } from "src/reusable_view_elements/form_fields";

type InteractiveWrapperProps = { fieldName: string; languagePeek: string } & FormControlProps;
const InteractiveWrapper = ({
  fieldName,
  languagePeek,
  children,
  sx,
  ...formControlProps
}: PropsWithChildren<InteractiveWrapperProps>) => {
  /* Data from this component does not get fed into formik 'values'. It's a helper to make the
      popover interactive inside Trans, so that users can interact with it using keyboard */
  return (
    <ControllableFastField name={`${fieldName}_helper_field_`} languagePeek={languagePeek}>
      {() => (
        <FormControl sx={{ display: "grid", ...sx }} {...formControlProps}>
          {children}
        </FormControl>
      )}
    </ControllableFastField>
  );
};

export default InteractiveWrapper;
