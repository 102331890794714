import {
  AxiosPPHCAgencyDashboardConsumersControllerClient,
  ConsumerApplication_DTO_Request_Update,
  NewStatusDTO,
  Nullable,
  PageInfo,
} from "src/generated/api_types";

const agencyDashboardConsumerClient = new AxiosPPHCAgencyDashboardConsumersControllerClient();

export async function getConsumer(id: string) {
  return agencyDashboardConsumerClient.getConsumerApplicationDetails(id);
}

export async function getConsumers(agencyId: string) {
  return agencyDashboardConsumerClient.listApplications({
    agencyId,
  });
}

export async function updateConsumer(id: string, consumerRegistrationDTO: ConsumerApplication_DTO_Request_Update) {
  return agencyDashboardConsumerClient.postConsumerApplicationUpdate(id, consumerRegistrationDTO);
}

export async function updateConsumerStatusOnly(id: string, newStatusDTO: NewStatusDTO) {
  return agencyDashboardConsumerClient.postConsumerApplicationStatusUpdate(id, newStatusDTO);
}

export async function updateAssignedAdminOnly(id: string, newAdminID?: Nullable<string>) {
  return agencyDashboardConsumerClient.postAssignedAdminUpdate(id, { newAdminID });
}

export function getAllAgencyAdmins() {
  return agencyDashboardConsumerClient.listAgencyAdmins();
}

export function archiveClient(id: string) {
  return agencyDashboardConsumerClient.postClientArchive(id);
}

export function pageConsumerApps(pageInfo: PageInfo) {
  return agencyDashboardConsumerClient.pagingListApplications(pageInfo);
}
