/* eslint-disable max-len */
import QA_Image from "src/assets/images/blog-q-a.jpg";
import Stressed_Image from "src/assets/images/blog-stressed.jpg";
import Patrick_Image from "src/assets/images/home-care-laughing.jpg";
import Message_Image from "src/assets/images/blog-message.jpg";
import Target_Image from "src/assets/images/blog-target.png";
import Profile_Image from "src/assets/images/blog-profile.png";
import Child_Care_Teacher from "src/assets/images/child-care-teacher.jpg";
import Child_Care_Painting from "src/assets/images/child-care-fingerpainting.jpg";
import Child_Care_Caregiver from "src/assets/images/child-care-toddler-and-caregiver.jpg";
import Hello_Image from "src/assets/images/blog-hello.jpg";
import Conversation_Image from "src/assets/images/blog-conversation.jpg";
import Meet_Image from "src/assets/images/blog-meet.jpg";
import { nsMedicaidJobSurveyConfirmation, nsMedicaidLandingPage } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";

export const Provider_Blog_Posts = {
  "common-questions": {
    image: QA_Image,
    title: "Common caregiver questions about being an Individual Provider (IP)",
    translateKey: "blog.common_caregiver_questions.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=caregiving-jobs-for-individual-providers-ips",
  },
  "patrick's-story": {
    image: Patrick_Image,
    title: "Patrick's Story and his 3 tips for caregivers!",
    translateKey: "blog.patricks_story.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=patricks-carina-story-and-tips-for-new-caregivers",
  },
  "getting-clients": {
    image: Target_Image,
    title: "9 Steps to Getting the Job",
    translateKey: "blog.9_steps.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=9-steps-to-getting-a-new-client",
  },
  "stress-tips": {
    image: Stressed_Image,
    title: "Care professionals: Stressed out? Make time to care for yourself!",
    translateKey: "blog.care_professionals_stressed.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=stress-tips",
  },
  "improve-profile": {
    image: Profile_Image,
    title: "Looking for more work? Make the most of your Carina profile",
    translateKey: "blog.looking_more_work.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=make-the-most-of-your-profile",
  },
  "message-responses": {
    image: Message_Image,
    title: "The best ways to respond to messages",
    translateKey: "blog.best_ways_respond_messages.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=respond-to-messages",
  },
  "first-impression": {
    image: "", // TODO: Currently this blog link is only used in text. If it's used elsewhere in the future we'll need image from Marketing for this blog post
    title: "How to make a good first impression with a potential client",
    translateKey: "check_tips_for_good_impression.description",
    namespace: nsMedicaidJobSurveyConfirmation,
    url: "/blog?p=how-to-make-a-good-first-impression-with-a-potential-client",
  },
};

export const Consumer_Blog_Posts = {
  "message-responses": {
    image: Message_Image,
    title: "The best ways to respond to messages",
    translateKey: "blog.best_ways_respond_messages.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=respond-to-messages",
  },
  "interviewing-providers": {
    image: Hello_Image,
    title: "What to ask potential providers",
    translateKey: "blog.what_to_ask_providers.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=interviewing-caregivers",
  },
  "first-day": {
    image: Conversation_Image,
    title: "Tips for your first day of a new in-home care relationship",
    translateKey: "blog.tips_first_day.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=firstday",
  },
  "meet-caregivers": {
    image: Meet_Image,
    title: "Who's coming to my home? Meet your care professional",
    translateKey: "blog.whos_coming.label",
    namespace: nsMedicaidLandingPage,
    url: "/blog?p=meetcaregivers",
  },
  "tips-for-choosing-a-provider": {
    image: "", // TODO: Currently this blog link is only used in text. If it's used elsewhere in the future we'll need image from Marketing for this blog post
    title: "5 Smart Tips for Choosing a Home Care Provider",
    translateKey: "checkout_tips_for_finding_provider.description",
    namespace: nsMedicaidJobSurveyConfirmation,
    url: "/blog?p=5-smart-tips-for-choosing-a-home-care-provider",
  },
};

export const Child_Care_Blog_Posts = {
  "what-to-ask": {
    image: Child_Care_Teacher,
    title: "Cost, Safety, Location? What to Ask When Choosing Daycare",
    translateKey: "parent resources 1",
    url: "/blog?p=what_to_ask_when_choosing_daycare",
  },
  "what-is-childcare": {
    image: Child_Care_Painting,
    title: "Top 7 Reasons to Send Your Kids to Family Childcare",
    translateKey: "parent resources 2",
    url: "/blog?p=what-is-family-childcare",
  },
  "not-for-profit": {
    image: Child_Care_Caregiver,
    title: "Need Daycare Near You? Try a Not-for-Profit Matching Service",
    translateKey: "parent resources 3",
    url: "/blog?p=notforprofit_daycare_service",
  },
};

export function getGenderEquityBlogPost(languageTag: string): string {
  const base = `${ROUTES.blog}?p=`;
  const englishParam = "gender-equity";
  const spanishParam = "equidad-de-genero";
  return languageTag === "es-MX" ? base + spanishParam : base + englishParam;
}
