import { Box, BoxProps } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface ChatMessageProps extends BoxProps {
  isMerlin?: boolean;
  message: string;
  isLoading?: boolean;
  isError?: boolean;
}

const ChatMessage = ({
  isMerlin = false,
  message,
  isLoading = false,
  isError = false,
  ...restBoxProps
}: ChatMessageProps) => {
  function getColor() {
    const merlinColor = CivColors.greenSpring;
    const userColor = CivColors.paleBlue;
    const errorColor = CivColors.coreGold;

    if (isError) return errorColor;
    return isMerlin ? merlinColor : userColor;
  }

  return (
    <Box pt="24px" pb="10px" px="16px" bgcolor={getColor()} {...restBoxProps}>
      {!isMerlin && <BodyEmphasis gutterBottom>You</BodyEmphasis>}
      {isLoading ? (
        <>
          <Skeleton animation="wave" width="80%" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="70%" />
        </>
      ) : (
        message.split("\n").map((paragraph, i) => (
          <Body paragraph key={i}>
            {paragraph}
          </Body>
        ))
      )}
    </Box>
  );
};

export default ChatMessage;
