import { Box } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  BusinessDealHandshakeIcon,
  ConversationChat2Icon,
  Lock1Icon,
  LoveHeartHandsHold3Icon,
  TaskChecklistCheckIcon,
} from "src/assets/icons/StreamlineIcons";
import { useNotification } from "src/context/NotificationContext";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import { OnboardingStepType } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidCommunityGuidelines,
  nsMedicaidOnboardingMain,
} from "src/i18n/Namespaces";
import { useOnboardingContext } from "src/pages/onboarding/components/OnboardingContext";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import { onboardingSnackbarShouldBeShown } from "src/pages/onboarding/MedicaidOnboardingUtilities";
import { onboardingClient } from "src/pages/onboarding/Queries";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import { FeatureList, FeatureListItem } from "src/reusable_view_elements/FeatureList";
import Footer from "src/reusable_view_elements/Footer";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import UserNavbar from "src/reusable_view_elements/navbars/UserNavbar";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const CommunityGuidelines = () => {
  const { t, ready } = useTranslation([
    nsMedicaidOnboardingMain,
    nsCommonFormsBtns,
    nsMedicaidCommunityGuidelines,
    nsCommonToasts,
  ]);
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const { addCompletedStep, onboardingState } = useOnboardingContext();
  const { isFunderState } = useUserSession();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onboardingClient.completeOnboardingStep({
        json: "",
        stepType: OnboardingStepType.GUIDELINES,
      });
      addCompletedStep(OnboardingStepType.GUIDELINES);
      if (onboardingSnackbarShouldBeShown(onboardingState)) {
        showSnackbar(t("success.community_guidelines_agreed", { ns: nsCommonToasts }), "success");
      }
      history.push(ONBOARDING_ROUTES.root);
    } catch (e) {
      showSnackbar(
        ready
          ? t("error.something_wrong_saving_progress", { ns: nsCommonToasts })
          : "Something went wrong saving your progress",
        "error",
      );
      setIsSubmitting(false);
    }
  };

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Register</title>
        </Helmet>

        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
            <Constraint columns={6}>
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.medicaid", { ns: nsMedicaidOnboardingMain })}</title>
      </Helmet>

      <UserNavbar showLanguage />

      <main id="main-content">
        <Section bgcolor={CivColors.white} minimal>
          <Constraint columns={12}>
            <DashReturnLink to={ONBOARDING_ROUTES.root}>
              {t("button.back_to_previous_page", { ns: nsCommonFormsBtns })}
            </DashReturnLink>
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.white}>
          <Constraint columns={6}>
            <SectionTitle align="center">
              {t("community_guidelines.label", { ns: nsMedicaidCommunityGuidelines })}
            </SectionTitle>
            <Body paragraph align="center">
              {t("community_guidelines.description", { ns: nsMedicaidCommunityGuidelines })}
            </Body>

            <FeatureList>
              <FeatureListItem
                icon={Lock1Icon}
                primary={<BodyTitle paragraph>{t("be_safe.label", { ns: nsMedicaidCommunityGuidelines })}</BodyTitle>}
                secondary={<Body paragraph>{t("be_safe.description", { ns: nsMedicaidCommunityGuidelines })}</Body>}
              />
              <FeatureListItem
                icon={ConversationChat2Icon}
                primary={
                  <BodyTitle paragraph>{t("be_responsive.label", { ns: nsMedicaidCommunityGuidelines })}</BodyTitle>
                }
                secondary={
                  <Body paragraph>{t("be_responsive.description", { ns: nsMedicaidCommunityGuidelines })}</Body>
                }
              />
              <FeatureListItem
                icon={BusinessDealHandshakeIcon}
                primary={
                  <BodyTitle paragraph>{t("be_approved.label", { ns: nsMedicaidCommunityGuidelines })}</BodyTitle>
                }
                secondary={
                  <Body paragraph>
                    {isFunderState(FunderState.Washington)
                      ? t("be_approved_WA.description", { ns: nsMedicaidCommunityGuidelines })
                      : t("be_approved.description", { ns: nsMedicaidCommunityGuidelines })}
                  </Body>
                }
              />
              <FeatureListItem
                icon={TaskChecklistCheckIcon}
                primary={
                  <BodyTitle paragraph>{t("be_proactive.label", { ns: nsMedicaidCommunityGuidelines })}</BodyTitle>
                }
                secondary={
                  <Body paragraph>{t("be_proactive.description", { ns: nsMedicaidCommunityGuidelines })}</Body>
                }
              />
              <FeatureListItem
                icon={LoveHeartHandsHold3Icon}
                primary={
                  <BodyTitle paragraph>{t("be_informed.label", { ns: nsMedicaidCommunityGuidelines })}</BodyTitle>
                }
                secondary={<Body paragraph>{t("be_informed.description", { ns: nsMedicaidCommunityGuidelines })}</Body>}
              />
            </FeatureList>

            <Box textAlign="center">
              <Body paragraph>{t("community_guidelines.disclaimer", { ns: nsMedicaidCommunityGuidelines })}</Body>
              <Button variant="contained" type="button" onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting
                  ? t("button.submitting", { ns: nsCommonFormsBtns })
                  : t("button.i_agree", { ns: nsCommonFormsBtns })}
              </Button>
            </Box>
          </Constraint>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default CommunityGuidelines;
