import { Box, Grid, useMediaQuery } from "@mui/material";
import { useConfig } from "src/context/ConfigContext";
import colors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { Body } from "src/reusable_view_elements/Typography";

function DevBanner() {
  const { environmentName } = useConfig();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  return (
    <div style={{ zIndex: 10, position: "relative" }}>
      <Box bgcolor="#212121" color={colors.white} padding={1}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          direction={desktopSize ? "row" : "column"}
          spacing={2}
        >
          <Grid item>
            <Body>{`App is connected to a ${environmentName} backend.`}</Body>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DevBanner;
