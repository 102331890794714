import { Grid, Hidden, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import { nsUniversalHomepage } from "src/i18n/Namespaces";
import LeadGenerationForm from "src/reusable_view_elements/forms/LeadGenerationForm";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PanelImageChildCare from "src/assets/images/child-care-toddler-panel.jpg";
import HeroImageDesktop from "src/assets/images/emans-main.svg";
import EmanProvidersIllustration from "src/assets/images/emans-providers.svg";
import PanelImageHomeCare from "src/assets/images/home-care-elderly-and-caregiver-gardening-panel.jpg";
import PanelImageCareWork from "src/assets/images/home-care-provider-square.jpg";
import CAPLogo from "src/assets/images/logo-central-american-progress.svg";
import GeekWireLogo from "src/assets/images/logo-geekwire.svg";
import NewYorkLogo from "src/assets/images/logo-new-york.svg";
import NPQLogo from "src/assets/images/logo-nonprofit-quarterly.svg";
import PSBusinessJournalLogo from "src/assets/images/logo-puget-sound-business-journal.svg";
import StanfordReviewLogo from "src/assets/images/logo-stanford-social-innovation.svg";
import TestimonialImageTwo from "src/assets/images/round-child-care-toddler-playing.png";
import TestimonialImageThree from "src/assets/images/round-children-on-bench.png";
import TestimonialImageOne from "src/assets/images/round-holding-hands.png";
import { CheckBadgeIcon, FamilyHomeIcon, RoadSignSideRoadLeft1AlternateIcon } from "src/assets/icons/StreamlineIcons";
import { ROUTES } from "src/MainRouter";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import Banner from "src/reusable_view_elements/Banner";
import Blurb from "src/reusable_view_elements/Blurb";
import Constraint from "src/reusable_view_elements/Constraint";
import { FeatureList, FeatureListItem } from "src/reusable_view_elements/FeatureList";
import Footer from "src/reusable_view_elements/Footer";
import Hero from "src/reusable_view_elements/Hero";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Panel from "src/reusable_view_elements/Panel";
import PanelList from "src/reusable_view_elements/PanelList";
import Section from "src/reusable_view_elements/Section";
import Separator from "src/reusable_view_elements/Separator";
import {
  Body,
  BodyEmphasis,
  BodyTitle,
  ImpactTitle,
  PanelTitle,
  Quote,
  QuoteAttribution,
  QuoteHeader,
  SectionTitle,
} from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const Logos = [
  { src: NPQLogo, alt: "Non-Profit Quarterly" },
  { src: GeekWireLogo, alt: "Geek Wire" },
  { src: CAPLogo, alt: "Center for American Progress" },
  { src: StanfordReviewLogo, alt: "Stanford Social Innovation Review" },
  { src: NewYorkLogo, alt: "New York Journal" },
  { src: PSBusinessJournalLogo, alt: "Puget Sound Business Journal" },
];

export const COVID_BANNER_KEY = "covid-banner-closed";

export const Home: React.FC = () => {
  const { t, ready } = useTranslation([nsUniversalHomepage]);
  const [showBanner, setBannerVisibility] = useState(false);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  useEffect(() => {
    if (window.sessionStorage.getItem(COVID_BANNER_KEY) === "true") {
      setBannerVisibility(false);
    }
  }, []);

  const closeBanner = () => {
    setBannerVisibility(false);
    window.sessionStorage.setItem(COVID_BANNER_KEY, "true");
  };

  return (
    <>
      <Helmet>
        <title>Carina | Home</title>
      </Helmet>

      {showBanner && (
        <Banner onClose={closeBanner}>
          <BodyEmphasis>
            Carina will be unavailable June 23, 5PM - June 24, 9AM (Pacific Time) for scheduled maintenance
          </BodyEmphasis>
        </Banner>
      )}

      <NavBar showLanguage />
      <main id="main-content">
        {ready ? (
          <>
            <Hero>
              <Constraint columns={8} textAlign="center">
                <ImpactTitle>{t("banner")}</ImpactTitle>
                <img src={HeroImageDesktop} alt="" width="100%" />
              </Constraint>
            </Hero>

            <Section bgcolor={CivColors.lightGray}>
              <Constraint columns={6} textAlign="center">
                <SectionTitle>{t("routing options main header")}</SectionTitle>
                <Body>{t("routing options body")}</Body>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
              <Constraint columns={17}>
                <PanelList stretch>
                  <Panel img={PanelImageHomeCare}>
                    <PanelTitle paragraph>{t("routing options 1 body")}</PanelTitle>
                    <LinkStyledAsButton
                      variant="contained"
                      component={RouterLink}
                      to={ROUTES.homeCareOptions}
                      style={{ margin: "0px auto" }}
                    >
                      {t("routing options 1 button")}
                    </LinkStyledAsButton>
                  </Panel>
                  <Panel img={PanelImageChildCare}>
                    <PanelTitle paragraph>{t("routing options 2 body")}</PanelTitle>
                    <LinkStyledAsButton
                      variant="contained"
                      component={RouterLink}
                      to={CHILD_CARE_ROUTES.landing}
                      style={{ margin: "0px auto" }}
                    >
                      {t("routing options 2 button")}
                    </LinkStyledAsButton>
                  </Panel>
                  <Panel img={PanelImageCareWork}>
                    <PanelTitle paragraph>{t("routing options 3 body")}</PanelTitle>
                    <LinkStyledAsButton
                      variant="contained"
                      component={RouterLink}
                      to={ROUTES.providers}
                      style={{ margin: "0px auto" }}
                    >
                      {t("routing options 3 button")}
                    </LinkStyledAsButton>
                  </Panel>
                </PanelList>
              </Constraint>
            </Section>

            <Hidden smDown>
              <Separator bgcolor={CivColors.lightGray} />
            </Hidden>

            <Section bgcolor={CivColors.palePeach}>
              <Constraint columns={6}>
                <FeatureList>
                  <FeatureListItem
                    icon={FamilyHomeIcon}
                    primary={<BodyTitle paragraph>{t("value prop 1 header")}</BodyTitle>}
                    secondary={<Body paragraph>{t("value prop 1 body")}</Body>}
                  />
                  <FeatureListItem
                    icon={RoadSignSideRoadLeft1AlternateIcon}
                    primary={<BodyTitle paragraph>{t("value prop 2 header ")}</BodyTitle>}
                    secondary={<Body paragraph>{t("value prop 2 body")}</Body>}
                  />
                  <FeatureListItem
                    icon={CheckBadgeIcon}
                    primary={<BodyTitle paragraph>{t("value prop 3 header ")}</BodyTitle>}
                    secondary={<Body>{t("value prop 3 body ")}</Body>}
                  />
                </FeatureList>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
              <Constraint columns={8}>
                <Blurb imgProps={{ src: TestimonialImageOne, alt: "" }} reverse>
                  <QuoteHeader paragraph>{t("testimonial 2 header")}</QuoteHeader>
                  <Quote paragraph>{t("testimonial 1 quote")}</Quote>
                  <QuoteAttribution align="right">&ndash; Patricia Reed</QuoteAttribution>
                </Blurb>
              </Constraint>
            </Section>

            <Hidden smDown>
              <Separator bgcolor={CivColors.lightGray} line />
            </Hidden>

            <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
              <Constraint columns={8}>
                <Blurb imgProps={{ src: TestimonialImageTwo, alt: "" }}>
                  <QuoteHeader paragraph>{t("testimonial 1 header")}</QuoteHeader>
                  <Quote paragraph>{t("testimonial 2 quote")}</Quote>
                  <QuoteAttribution align="right">&ndash; Parker G.</QuoteAttribution>
                </Blurb>
              </Constraint>
            </Section>

            <Hidden smDown>
              <Separator bgcolor={CivColors.lightGray} line />
            </Hidden>

            <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
              <Constraint columns={8}>
                <Blurb reverse imgProps={{ src: TestimonialImageThree, alt: "" }}>
                  <QuoteHeader paragraph>{t("testimonial 3 header")}</QuoteHeader>
                  <Quote paragraph>{t("testimonial 3 quote")}</Quote>
                  <QuoteAttribution align="right">&ndash; Olga Ordaz de Pineda</QuoteAttribution>
                </Blurb>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.white}>
              <Constraint columns={8} textAlign="center">
                <SectionTitle style={{ marginBottom: 48 }}>{t("external validators header")}</SectionTitle>
                <Grid container justifyContent="center" spacing={6}>
                  {Logos.map((logo) => (
                    <Grid item xs={12} sm={4} md={3} key={logo.alt}>
                      <img src={logo.src} alt={logo.alt} style={{ maxWidth: "100%" }} />
                    </Grid>
                  ))}
                </Grid>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.paleBlue}>
              <Constraint columns={8}>
                <Grid container spacing={desktopSize ? 4 : 0} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Hidden smDown>
                      <img src={EmanProvidersIllustration} alt="" width="100%" style={{ maxWidth: 395 }} />
                    </Hidden>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SectionTitle paragraph align={desktopSize ? "left" : "center"}>
                      {t("bottom CTAs")}
                    </SectionTitle>
                    <Body paragraph align={desktopSize ? "left" : "center"}>
                      <LinkStyledAsButton variant="contained" component={RouterLink} to={ROUTES.homeCareOptions}>
                        {t("routing options 1 button")}
                      </LinkStyledAsButton>
                    </Body>
                    <Body paragraph align={desktopSize ? "left" : "center"}>
                      <LinkStyledAsButton variant="contained" component={RouterLink} to={CHILD_CARE_ROUTES.landing}>
                        {t("routing options 2 button")}
                      </LinkStyledAsButton>
                    </Body>
                    <Body align={desktopSize ? "left" : "center"}>
                      <LinkStyledAsButton variant="contained" component={RouterLink} to={ROUTES.providers}>
                        {t("routing options 3 button")}
                      </LinkStyledAsButton>
                    </Body>
                  </Grid>
                </Grid>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray}>
              <Constraint columns={6} textAlign="center">
                <SectionTitle paragraph id="join-our-community">
                  {t("newsletter signup header")}
                </SectionTitle>
                <Body paragraph>{t("newsletter signup body")}</Body>
                <LeadGenerationForm />
              </Constraint>
            </Section>
          </>
        ) : (
          <>
            <Section height="100vh" bgcolor={CivColors.white}>
              <LoadingCircle />
            </Section>
          </>
        )}
      </main>
      <Footer />
    </>
  );
};

export default Home;
