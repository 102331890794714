import { Container, ContainerProps } from "@mui/material";
import { useEffect, useState } from "react";
import { DeleteJobResponseMessage } from "src/generated/api_types";
import Banner from "src/reusable_view_elements/Banner";
import Navbar from "src/reusable_view_elements/navbars/NavBar";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const containerStyle = {
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 24,
  paddingBottom: 24,
};

export const AUTH_PAGE_CONTAINER_BANNER_KEY = "-";

interface BannerContent {
  bannerText: string;
  bannerColor: keyof typeof CivColors;
}

interface AuthPageContainerProps extends ContainerProps {
  persistBannerUntilClosed?: boolean;
}

export const AuthPageContainer = ({ children, persistBannerUntilClosed, ...props }: AuthPageContainerProps) => {
  const [showBanner, setBannerVisibility] = useState(false);
  const [bannerContent, setBannerContent] = useState<BannerContent | null>(null);

  useEffect(() => {
    const bannerKey = window.sessionStorage.getItem(AUTH_PAGE_CONTAINER_BANNER_KEY);
    if (window.sessionStorage.getItem(AUTH_PAGE_CONTAINER_BANNER_KEY) === null) {
      setBannerVisibility(false);
    } else {
      setBannerContent(getBannerContent(bannerKey));
      setBannerVisibility(true);
      if (!persistBannerUntilClosed) window.sessionStorage.removeItem(AUTH_PAGE_CONTAINER_BANNER_KEY);
    }
  }, []);

  const getBannerContent = (bannerKey: string | null): BannerContent | null => {
    const COMMON_TEXT = "Please login to modify your jobs or create new jobs";
    switch (bannerKey) {
      case DeleteJobResponseMessage.JOB_NOT_FOUND:
        return {
          bannerText: `This job post does not exist. ${COMMON_TEXT}`,
          bannerColor: CivColors.deepRose as keyof typeof CivColors,
        };
      case DeleteJobResponseMessage.JOB_ALREADY_DELETED:
        return {
          bannerText: `This job post has already been deleted. ${COMMON_TEXT}`,
          bannerColor: CivColors.deepRose as keyof typeof CivColors,
        };
      case DeleteJobResponseMessage.EMAIL_MISMATCH:
        return {
          bannerText: `We could not verify that you were the one who posted this job. ${COMMON_TEXT}`,
          bannerColor: CivColors.deepRose as keyof typeof CivColors,
        };
      case DeleteJobResponseMessage.GENERIC_JOB_DELETE_ERROR:
        return {
          bannerText: `We could not delete your job. Please log in to delete it.`,
          bannerColor: CivColors.deepRose as keyof typeof CivColors,
        };
      default:
        return null;
    }
  };

  const closeBanner = () => {
    if (persistBannerUntilClosed) window.sessionStorage.removeItem(AUTH_PAGE_CONTAINER_BANNER_KEY);
    setBannerVisibility(false);
  };

  return (
    <>
      {showBanner && (
        <Banner onClose={closeBanner} backgroundColor={bannerContent?.bannerColor}>
          <BodyEmphasis>{bannerContent?.bannerText || "Scheduled maintenance happening soon !"}</BodyEmphasis>
        </Banner>
      )}

      <Navbar hideMenu hideLogin showLanguage />
      <main id="main-content">
        <Container style={containerStyle} {...props}>
          {children}
        </Container>
      </main>
    </>
  );
};
