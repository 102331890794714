import { MapiResponse } from "@mapbox/mapbox-sdk/lib/classes/mapi-response";
import mbxGeocoding, { GeocodeService } from "@mapbox/mapbox-sdk/services/geocoding";

export const addressFieldSearchOptions: (zipCodeOnly?: boolean) => {
  getSuggestions: (input: string, resolve: (res: MapiResponse) => void) => void;
  formatOption: (rawOption: string) => string;
} = (zipcodeOnly) => {
  const geocodingClient: GeocodeService = mbxGeocoding({
    accessToken: process.env.REACT_APP_MAPBOX_API_KEY!,
  });

  //---- Default search options. The field shows the full address ---------
  const getSuggestionsForFullAddress = (input: string, resolve: (res: MapiResponse) => void) => {
    geocodingClient
      .forwardGeocode({
        query: input,
        mode: "mapbox.places",
        countries: ["us"],
        autocomplete: true,
      })
      .send()
      .then(resolve);
  };

  const formatOptionForFullAddress = (rawOption: string) => rawOption;

  //---- Search options if the field shows only the zip code ------------------
  const getSuggestionsForZipcodeOnly = (input: string, resolve: (res: MapiResponse) => void) => {
    geocodingClient
      .forwardGeocode({
        query: input,
        mode: "mapbox.places",
        types: ["postcode"],
        countries: ["us"],
        autocomplete: true,
      })
      .send()
      .then(resolve);
  };

  const formatOptionForZipcodeOnly = (str: string) => str.match(/\d+/g)?.join() || "";

  if (zipcodeOnly) {
    return { getSuggestions: getSuggestionsForZipcodeOnly, formatOption: formatOptionForZipcodeOnly };
  }

  return { getSuggestions: getSuggestionsForFullAddress, formatOption: formatOptionForFullAddress };
};
