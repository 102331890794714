import { Box, IconButton, useMediaQuery } from "@mui/material";
import { PropsWithChildren } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

interface BannerProps {
  color: "success" | "error";
  onClose: () => void;
}

export enum ViewAPIResBannerControlEnum {
  HIDDEN,
  VISIBLE_SUCCESS,
  VISIBLE_ERROR,
}

const ApiResponseBanner: React.FC<PropsWithChildren<BannerProps>> = ({ color, onClose, children }) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);

  // TODO: after MUI5 work is merged, dynamic backgroundColor should stay in style prop but static styles should be moves to sx prop
  return (
    <Box
      display="flex"
      style={{
        backgroundColor: color === "success" ? CivColors.greenSpring : CivColors.dustyRose,
        justifyContent: "right",
        alignItems: "center",
        padding: "10px 0px 10px 15px",
      }}
    >
      <Box flexGrow={1} textAlign={desktopSize ? "center" : "left"} style={{ paddingRight: 20 }}>
        {children}
      </Box>
      <Box>
        <IconButton onClick={onClose} size="large">
          <CancelRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ApiResponseBanner;
