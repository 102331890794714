import { GenericAxiosResponse } from "axios";
import { PropsWithChildren, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { Column, Datatable, DatatableProps } from "./Datatable";

interface ObjectWithId {
  id: string;
}

interface InitialConfig<T> {
  data: T[];
  isFetching: boolean;
}

interface Context<T> {
  data: T[];
  dataIndex: Map<string, number>;
  secondaryDataIndex: Map<string, number>;
  isFetching: boolean;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

function clientsideDatatableContext<T>(initialState?: InitialConfig<T>): Context<T> {
  // Stores raw data internally. Array members _can_ be null if items were dynamically removed.
  // These null values are kept because, otherwise, we would have to update all values in
  // `dataIndex` and `secondaryDataIndex`.
  const [data, setData] = useState<T[]>(initialState?.data || []);
  // This index keeps a map from record ID to index within our raw data array. This allows
  // us to quickly look up records within the data array in efficient time.
  const [dataIndex] = useState<Map<string, number>>(new Map());
  // This serves the same purpose as `dataIndex`, but it is only populated if the generated
  // `DatatableContext` is specified to have an alternate ID.
  const [secondaryDataIndex] = useState<Map<string, number>>(new Map());
  // State for consumers to watch while `DatatableContext` populates context from network
  // response.
  const [isFetching, setIsFetching] = useState(initialState?.isFetching || false);

  return {
    data,
    dataIndex,
    secondaryDataIndex,
    isFetching,
    setData,
    setIsFetching,
  };
}

export type ClientsideColumn<T extends object> = Column<T>;

export function GenerateClientsideDatatable<
  D extends ObjectWithId,
  GetData extends (...args: Parameters<GetDataArgsFn>) => Promise<GenericAxiosResponse<D[]>>,
  GetDataArgsFn extends (...args: any[]) => void = () => void,
>(alternateIdMapping?: string) {
  const ClientsideDatatableContext = createContainer<Context<D>, InitialConfig<D>>(clientsideDatatableContext);

  interface DatatableContextLoaderProps {
    getData: GetData;
    getDataArgs: Parameters<GetDataArgsFn>;
  }

  const ClientsideDatatableContextLoader = ({
    children,
    getData,
    getDataArgs,
  }: PropsWithChildren<DatatableContextLoaderProps>) => {
    const { setIsFetching, setData, dataIndex, secondaryDataIndex } = ClientsideDatatableContext.useContainer();

    useEffect(() => {
      setIsFetching(true);
      getData(...getDataArgs)
        .then((resp) => {
          if (resp.status === 200) {
            setData(resp.data);
            resp.data.forEach((pp, idx) => dataIndex.set(pp.id, idx));
            if (alternateIdMapping) {
              resp.data
                // @ts-ignore
                .filter((pp) => pp[alternateIdMapping])
                // @ts-ignore
                .forEach((pp, idx) => secondaryDataIndex.set(pp[alternateIdMapping], idx));
            }
          } else {
            // eslint-disable-next-line no-console
            console.error("Failed to query data");
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${resp.status}`);
          }
        })
        .catch((err) => {
          dataIndex.clear();
          secondaryDataIndex.clear();
          // eslint-disable-next-line no-console
          console.error("Failed to query data");
          // eslint-disable-next-line no-console
          console.error(`Error: ${err}`);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }, [...getDataArgs]);

    return <>{children}</>;
  };

  interface ClientsideDatatableContextProviderProps {
    initialState?: InitialConfig<D>;
    getData: GetData;
    getDataArgs?: Parameters<GetDataArgsFn>;
  }

  const ClientsideDatatableContextProvider: React.FC<PropsWithChildren<ClientsideDatatableContextProviderProps>> = ({
    initialState,
    getData,
    getDataArgs,
    children,
  }) => (
    <ClientsideDatatableContext.Provider initialState={initialState}>
      <ClientsideDatatableContextLoader
        getData={getData}
        getDataArgs={getDataArgs || ([] as unknown as Parameters<GetDataArgsFn>)}
      >
        {children}
      </ClientsideDatatableContextLoader>
    </ClientsideDatatableContext.Provider>
  );

  type ClientsideDatatableProps<T extends Column<D>> = Omit<DatatableProps<D, T>, "data">;

  function ClientsideDatatable(props: ClientsideDatatableProps<ClientsideColumn<D>>) {
    const { data } = ClientsideDatatableContext.useContainer();

    return (
      <Datatable<D, ClientsideColumn<D>>
        {...props}
        columnDefinitions={props.columnDefinitions}
        data={data.filter((v) => v !== null)}
      />
    );
  }

  const useClientsideDatatable = () => {
    const { data, isFetching, dataIndex, secondaryDataIndex, setData } = ClientsideDatatableContext.useContainer();

    // Get existing index from `secondaryDataIndex` if we have an `alternateIdMapping`.
    // Otherwise, get existing index from 'dataIndex`.
    // Returns 'undefined` if the ID cannot be found in either index.
    function getExistingIdx(id: string): number | undefined {
      let existingIdx;
      if (alternateIdMapping) {
        existingIdx = secondaryDataIndex.get(id);
      }
      if (existingIdx === undefined) {
        existingIdx = dataIndex.get(id);
      }
      return existingIdx;
    }

    function addOrUpdateData(id: string, addOrUpdateFn: (existing?: D) => D) {
      const existingIdx = getExistingIdx(id);
      const newData = [...data];
      if (existingIdx !== undefined) {
        const existing = data[existingIdx];
        newData[existingIdx] = addOrUpdateFn(existing);
      } else {
        const newRow = addOrUpdateFn();
        newData.push(newRow);
        dataIndex.set(newRow.id, newData.length - 1);
        if (alternateIdMapping) {
          // @ts-ignore
          secondaryDataIndex.set(newRow[alternateIdMapping], newData.length - 1);
        }
      }
      setData(newData);
    }

    function removeData(id: string) {
      const existingIdx = getExistingIdx(id);
      if (existingIdx !== undefined) {
        const newData = [...data];
        // @ts-ignore
        newData[existingIdx] = null;

        dataIndex.delete(id);
        if (alternateIdMapping) {
          // @ts-ignore
          secondaryDataIndex.delete(data[existingIdx][alternateIdMapping]);
        }
        setData(newData);
      }
    }

    return {
      getReadOnlyData: () => [...data.filter((v) => v !== null)],
      ClientsideDatatable,
      isFetching,
      addOrUpdateData,
      removeData,
    };
  };

  return {
    ClientsideDatatableContextProvider,
    useClientsideDatatable,
  };
}
