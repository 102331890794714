import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns } from "src/i18n/Namespaces";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface SurveyLinkBannerProps {
  threadId: string;
}

const SurveyLinkBanner = ({ threadId }: SurveyLinkBannerProps) => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation(nsCommonFormsBtns);

  return (
    <Box
      bgcolor={CivColors.palePeach}
      borderTop={`1px solid ${CivColors.mediumGray}`}
      borderBottom={`1px solid ${CivColors.mediumGray}`}
    >
      <Constraint columns={14}>
        <Grid container justifyContent="flex-end">
          {ready && (
            <LinkStyledAsButton
              variant="text"
              component={Link}
              to={generatePath(getMedicaidRouteByUserType("matchSurvey", isUserType), { id: threadId })}
              endIcon={<ChevronRightRoundedIcon style={{ width: 40, height: 40 }} />}
            >
              <BodyEmphasis>{t("button.working_together", { ns: nsCommonFormsBtns })}</BodyEmphasis>
            </LinkStyledAsButton>
          )}
        </Grid>
      </Constraint>
    </Box>
  );
};

export default SurveyLinkBanner;
