import { Box, BoxProps, useMediaQuery } from "@mui/material";
import theme from "src/themes/civilization/CivTheme";

interface PanelProps extends BoxProps {
  img: string;
}

const ContentStyle = {
  background: "rgba(255,255,255,0.85)",
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(5),
  width: "100%",
  height: "43%",
  display: "flex",
  // TODO ESLint: Fix the following so that rule doesn't have to be ignored
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  flexDirection: "column" as "column",
  alignItems: "flex-start", // default is "stretch"
  justifyContent: "space-between",
};

function Panel({ img, children, ...props }: PanelProps) {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);

  const mobileStyle = {
    backgroundColor: "white",
    width: "100%",
    marginTop: 4,
    marginBottom: 4,
  };

  const desktopStyle = {
    width: 330,
    height: 500,
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    display: "flex",
    alignItems: "flex-end",
    margin: 1,
    flexGrow: 1,
  };

  const style = desktopSize ? desktopStyle : mobileStyle;

  return (
    <Box style={style} {...props}>
      <Box style={ContentStyle}>{children}</Box>
    </Box>
  );
}

export default Panel;
