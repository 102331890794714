import { Redirect, Route, Switch } from "react-router-dom";
import { SingleInviteType } from "src/generated/api_types";
import {
  RegistrationContextProvider,
  useRegistrationContext,
} from "src/pages/registration/components/RegistrationContext";
import Password from "src/pages/registration/password_creation/Password";
import { redeemSingleInvite, RegistrationTokenControllerClient } from "src/pages/registration/Queries";
import ContactInfo from "src/pages/registration/role_selection/ContactInfo";
import { getFundersWithRoles } from "src/pages/registration/role_selection/Queries";
import SingleInviteLanding from "src/pages/registration/SingleInviteLanding";
import TokenRedemptionLanding from "src/pages/registration/TokenRedemptionLanding";
import VerificationBasePage from "src/pages/registration/verification/VerificationBasePage";
import VerificationConfirmation from "src/pages/registration/verification/VerificationConfirmation";

const REGISTRATION_PREFIX = "/register";

export const REGISTRATION_ROUTES = {
  medicaidInviteLanding: `${REGISTRATION_PREFIX}/homecare/medicaid/invite/:token`,
  tokenRedemptionLanding: `${REGISTRATION_PREFIX}/homecare/medicaid/token/:token`,
  childcareInviteLanding: `${REGISTRATION_PREFIX}/childcare/invite/:token`,
  contactInfo: `${REGISTRATION_PREFIX}/homecare/medicaid/contactinfo`,
  verification: `${REGISTRATION_PREFIX}/homecare/medicaid/verification/:funder/:role`,
  verificationConfirmation: `${REGISTRATION_PREFIX}/homecare/medicaid/verification/confirmation`,
  password: `${REGISTRATION_PREFIX}/homecare/medicaid/password/:funder/:role`,
  root: `${REGISTRATION_PREFIX}`,
};

const RegistrationRouter: React.FC = () => {
  return (
    <RegistrationContextProvider>
      <RegistrationRouterRoutes />
    </RegistrationContextProvider>
  );
};

const RegistrationRouterRoutes: React.FC = () => {
  const { regPath } = useRegistrationContext();

  return (
    <Switch>
      <Route exact path={REGISTRATION_ROUTES.medicaidInviteLanding}>
        <SingleInviteLanding redeemSingleInvite={redeemSingleInvite} inviteType={SingleInviteType.MEDICAID} />
      </Route>
      <Route exact path={REGISTRATION_ROUTES.tokenRedemptionLanding}>
        <TokenRedemptionLanding
          redeemRegistrationToken={(tokenDto) => RegistrationTokenControllerClient.redeemRegistrationToken(tokenDto)}
        />
      </Route>
      <Route exact path={REGISTRATION_ROUTES.childcareInviteLanding}>
        <SingleInviteLanding redeemSingleInvite={redeemSingleInvite} inviteType={SingleInviteType.CHILDCARE} />
      </Route>
      <Route exact path={REGISTRATION_ROUTES.contactInfo}>
        <ContactInfo getAllFundersWithRoles={getFundersWithRoles} />
      </Route>
      {!!regPath && (
        <Route exact path={REGISTRATION_ROUTES.verification}>
          <VerificationBasePage />
        </Route>
      )}
      {!!regPath && (
        <Route exact path={REGISTRATION_ROUTES.verificationConfirmation}>
          <VerificationConfirmation />
        </Route>
      )}
      {!!regPath && (
        <Route exact path={REGISTRATION_ROUTES.password}>
          <Password />
        </Route>
      )}
      <Redirect to={REGISTRATION_ROUTES.contactInfo} />
    </Switch>
  );
};

export default RegistrationRouter;
