import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { ActiveUserDetailsDTO } from "src/generated/api_types";
import { AuthPageContainer } from "src/pages/auth/AuthPageContainer";
import { requestPasswordResetOnBehalf } from "src/pages/auth/Queries";
import { usersAdministrationController } from "src/pages/supportAdminAccount/ControllerClients";
import Constraint from "src/reusable_view_elements/Constraint";
import { TextField } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { object, string } from "yup";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";

interface ResetPasswordProps {
  onSubmit: typeof requestPasswordResetOnBehalf;
}

interface ResetPasswordFields {
  email: string;
}

const validationSchema = object({
  email: string().email("Please enter a valid email address"),
});

const ResetEmail = ({ email }: ResetPasswordFields) => {
  const { setFieldValue } = useFormikContext<ResetPasswordFields>();

  useEffect(() => {
    if (email) {
      setFieldValue("email", email, true);
    }
  }, [email, setFieldValue]);

  return (
    <Constraint columns={4}>
      <FormTextField name="email" label="Email address" value={email} disabled />
    </Constraint>
  );
};

export const ResetPassword = ({ onSubmit }: ResetPasswordProps) => {
  const { showSnackbar } = useNotification();

  const location = useLocation();
  const [adminReset, setAdminReset] = useState<boolean>(false);
  const [isLoadingUser, setLoadingUser] = useState<boolean>(false);
  const [userData, setUserData] = useState<ActiveUserDetailsDTO>();
  const adminResetSlug = "support/tools/user/reset-password";
  const { id } = useParams<{ id: string }>();

  const copyPasswordResetLink = async () => {
    await navigator.clipboard.writeText(tokenString);
    showSnackbar("Password Reset Link Copied!", "success");
  };

  useEffect(() => {
    if (location.pathname.includes(adminResetSlug)) {
      setAdminReset(true);
      const loadUser = async () => {
        setLoadingUser(true);
        try {
          const res = await usersAdministrationController.getActiveUserDetails(id);
          setUserData(res.data);
        } catch (error) {
          showSnackbar("Sorry, there was an issue retrieving this user's data.", "error");
        } finally {
          setLoadingUser(false);
        }
      };
      loadUser();
    }
  }, [location.pathname, adminResetSlug, id, showSnackbar]);

  const initialValues: ResetPasswordFields = {
    email: "",
  };

  const [tokenString, setTokenString] = useState<string>("");

  const handleSubmit = async (value: ResetPasswordFields) => {
    try {
      const response = await onSubmit(value);
      setTokenString(response.data);
      console.log(response.data);
    } catch (error) {
      showSnackbar("Error submitting forgot password request. Please try again later.", "error");
    }
  };

  return (
    <AuthPageContainer>
      <Helmet>
        <title>{adminReset && userData ? `Carina Support | Reset Password` : "Carina Support | User Not Found"}</title>
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: "68vh",
        }}
      >
        <Grid
          item
          sm={8}
          style={{
            textAlign: "center",
          }}
        >
          {isLoadingUser && <LoadingCircle />}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting, submitCount }) => {
              if (submitCount > 0) {
                return (
                  <Constraint columns={8}>
                    <>
                      <SectionTitle>Password Reset Email Sent</SectionTitle>
                      <Body>
                        Email sent to {userData?.firstName} {userData?.lastName}
                      </Body>
                      <Box p={2} />
                      <Grid item xs={12}>
                        <TextField
                          name="passwordReset"
                          label="Password Reset Link"
                          type="text"
                          value={tokenString}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button variant="text" type="button" onClick={copyPasswordResetLink}>
                                  Copy
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Box p={2} />
                      <LinkStyledAsButton variant="contained" component={Link} to={SUPPORT_ADMIN_DASHBOARD_ROUTES.root}>
                        Back to Support Portal
                      </LinkStyledAsButton>
                    </>
                  </Constraint>
                );
              }
              return (
                <>
                  {!isLoadingUser && adminReset && !userData && (
                    <Constraint columns={8}>
                      <>
                        <SectionTitle>User Not Found</SectionTitle>
                        <Body>The specified user cannot be found. Please return home and try again.</Body>
                        <Box p={2} />
                        <LinkStyledAsButton
                          variant="contained"
                          component={Link}
                          to={SUPPORT_ADMIN_DASHBOARD_ROUTES.root}
                        >
                          Back to Home
                        </LinkStyledAsButton>
                      </>
                    </Constraint>
                  )}
                  {!isLoadingUser && adminReset && userData && (
                    <Form data-testid="passwordReset-form" localizationReady={true}>
                      <Constraint columns={8}>
                        <>
                          <SectionTitle> Reset Password</SectionTitle>
                          <Body>
                            Send a password reset to {userData?.firstName} {userData?.lastName}
                          </Body>
                          <Box p={2} />
                          <ResetEmail email={userData?.email} />
                          <Box p={2} />
                          <Button variant="contained" type="submit" disabled={isSubmitting}>
                            Send Link to Email
                          </Button>
                        </>
                      </Constraint>
                    </Form>
                  )}
                </>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </AuthPageContainer>
  );
};
