import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Redirect, useLocation, useParams } from "react-router-dom";
import HeroImage from "src/assets/images/emans-main.svg";
import { RegistrationController_RegistrationStepDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidRegVerification } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import LoadingPage from "src/pages/LoadingPage";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import Hero from "src/reusable_view_elements/Hero";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { RegistrationTokenControllerClient } from "./Queries";

interface TokenRedemptionLandingProps {
  redeemRegistrationToken: typeof RegistrationTokenControllerClient.redeemRegistrationToken;
}

const TokenRedemptionLanding: React.FC<TokenRedemptionLandingProps> = (props) => {
  const { token } = useParams<{ token: string }>();
  const { regPath, setRegPath, getNextStepRoute, sendUserInfoToContext } = useRegistrationContext();

  const [isLoadingResponse, setIsLoadingResponse] = useState(true);
  const [regStep, setRegStep] = useState<RegistrationController_RegistrationStepDTO | undefined>(undefined);
  const [apiErrorMsg, setApiErrorMsg] = useState("");

  useEffect(() => {
    props
      .redeemRegistrationToken({ token })
      .then((res) => {
        if (res.data.error) {
          setApiErrorMsg(res.data.error.message);
          setRegStep(undefined);
          setRegPath(undefined);
        } else if (res.data.result) {
          setRegStep(res.data!!.result.regStep);
          setRegPath(res.data!!.result.redeemedUser.registrationPath);
          sendUserInfoToContext({
            email: res.data!!.result.redeemedUser.email,
            firstName: res.data!!.result.redeemedUser.firstName,
            lastName: res.data!!.result.redeemedUser.lastName,
            medicaidState: res.data!!.result.redeemedUser.registrationPath.roleConfig.funder.segment,
          });
        }
      })
      .catch(() => {
        // Still need to catch unexpected errors - they are not guaranteed to be a ServiceResponse
        setApiErrorMsg("not specified");
      })
      .finally(() => {
        setIsLoadingResponse(false);
      });
  }, []);

  if (isLoadingResponse) {
    return <LoadingPage />;
  }

  if (!regStep || !regPath) {
    return <TokenRedemptionError apiErrorMessage={apiErrorMsg} />;
  }

  return (
    <Redirect
      to={getNextStepRoute(regStep.step, {
        funder: regPath.roleConfig.funder.segment.split("-")[1].toLowerCase(),
        role: regPath.roleConfig.roleType.toLowerCase(),
      })}
    />
  );
};

export default TokenRedemptionLanding;

const TokenRedemptionError = (props: { apiErrorMessage: string }) => {
  const { t, ready } = useTranslation([nsMedicaidRegVerification, nsCommonFormsBtns]);
  const location = useLocation();

  function message(): string {
    if (props.apiErrorMessage.toLowerCase().indexOf("token does not exist") !== -1) {
      return t("token_redemption.error.label.invalid", { ns: nsMedicaidRegVerification });
    }
    if (props.apiErrorMessage.toLowerCase().indexOf("expired") !== -1) {
      return t("token_redemption.error.label.expired", { ns: nsMedicaidRegVerification });
    }
    if (props.apiErrorMessage.toLowerCase().indexOf("invalidated") !== -1) {
      return t("token_redemption.error.label.invalidated", { ns: nsMedicaidRegVerification });
    }
    if (props.apiErrorMessage.toLowerCase().indexOf("redeemed") !== -1) {
      return t("token_redemption.error.label.redeemed", { ns: nsMedicaidRegVerification });
    }
    if (props.apiErrorMessage.toLowerCase().indexOf("not specified") !== -1) {
      return t("token_redemption.error.label.other", { ns: nsMedicaidRegVerification });
    }
    return "";
  }

  return (
    <>
      <Helmet>
        <title>Carina | Registration Error</title>
      </Helmet>

      <NavBar showLanguage />

      <main id="main-content">
        {ready ? (
          <Section bgcolor={CivColors.lightGray}>
            <Constraint columns={6} textAlign="center">
              <SectionTitle>{message()}</SectionTitle>
              <Trans
                t={t}
                i18nKey="token_redemption.error.description"
                ns={nsMedicaidRegVerification}
                parent={(parentProps) => <Body paragraph>{parentProps.children}</Body>}
                components={{
                  contact_link: (
                    <InternalLink
                      to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }}
                      target="_blank"
                    />
                  ),
                }}
                values={{
                  contact_us: t("button.contact_us", {
                    ns: nsCommonFormsBtns,
                  }),
                }}
              />
              <LinkStyledAsButton variant="contained" href={ROUTES.contactUs}>
                {t("button.contact_us", {
                  ns: nsCommonFormsBtns,
                })}
              </LinkStyledAsButton>
            </Constraint>
            <Hero bgcolor={CivColors.lightGray}>
              <Constraint columns={8} textAlign="center">
                <img src={HeroImage} alt="" width="100%" />
              </Constraint>
            </Hero>
          </Section>
        ) : (
          <Section bgcolor={CivColors.lightGray}>
            <LoadingCircle />
          </Section>
        )}
      </main>

      <Footer />
    </>
  );
};
