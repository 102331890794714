import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import FAQ_1_Image from "src/assets/images/AgencyAdminFAQ_1.jpg";
import FAQ_1_Image_LG from "src/assets/images/AgencyAdminFAQ_1_lg.png";
import FAQ_2_Image from "src/assets/images/AgencyAdminFAQ_2.jpg";
import FAQ_2_Image_LG from "src/assets/images/AgencyAdminFAQ_2_lg.png";
import FAQ_3a_Image from "src/assets/images/AgencyAdminFAQ_3a.jpg";
import FAQ_3_CardImage from "src/assets/images/AgencyAdminFAQ_3b.jpg";
import FAQ_3c_Image from "src/assets/images/AgencyAdminFAQ_3c.jpg";
import FAQ_4_Image from "src/assets/images/AgencyAdminFAQ_4.jpg";
import FAQ_4_Image_LG from "src/assets/images/AgencyAdminFAQ_4_lg.png";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { AccordionList } from "src/reusable_view_elements/Accordion";
import Constraint from "src/reusable_view_elements/Constraint";
import { ExternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import PageContainerDashboard from "src/reusable_view_elements/PageContainerDashboard";
import CivColors from "src/themes/civilization/CivColors";

const FAQ_COPY = [
  {
    title: "What is the Providers Tab? How do I use it?",
    body: (
      <>
        <Section bgcolor={CivColors.white} minimal>
          <Body paragraph>
            Use the Provider Dashboard to add and update the number of available providers in your agency. Keeping the
            number of available providers updated will allow us to send you the most geographically relevant home care
            clients your way. The Providers Tab contains multiple tools to help you filter and update provider
            availability. We will cover the main functions of the Providers Tab below.
          </Body>
          <BodyEmphasis>Add Provider Availability</BodyEmphasis>
          <Body paragraph>
            Adding provider availability in the Providers Tab is very important. You can do this by clicking the
            &quot;Add Row&quot; button located towards the top right side of the page when you’re in the Providers Tab.
            To learn more about how to do this, please refer to the &quot;How do I add Providers?&quot; FAQ in this
            page.
          </Body>
          <BodyEmphasis>Search by Keyword or Number</BodyEmphasis>
          <Body paragraph>
            Use the search bar at the top of the page to filter the entire dashboard by keyword or number. In addition
            to this, you may also filter keywords within certain columns if that is what you prefer. Below each column
            title is another search bar that you may use to narrow down your search.
          </Body>
          <BodyEmphasis>Sort Columns Alphabetically or Numerically</BodyEmphasis>
          <Body paragraph>
            In addition to filtering, you can also sort by each column. Just click on a column title to sort
            alphabetically or numerically.{" "}
          </Body>
          <BodyEmphasis>Add or Remove Columns you want on the Dashboard</BodyEmphasis>
          <Body paragraph>
            Click the button on the right of the main search bar. Doing so will open a menu with all the columns that
            can be displayed. From here, check/uncheck the columns you want to add/remove on the dashboard. We strongly
            recommend you to keep the First Name, Contact Method, ZIP code, Assigned to, Status, Notes, and Last Updated
            columns.{" "}
          </Body>
          <BodyEmphasis>Export Data</BodyEmphasis>
          <Body paragraph>
            You can easily export data from the Providers Dashboard as a spreadsheet file by clicking on the download
            button located right next to the search bar after you have selected the Providers Tab. After clicking on the
            download button, click on &quot;Export as CSV&quot;. This will start the download and you should be able to
            find the spreadsheet in your computer.{" "}
          </Body>
          <BodyEmphasis>Edit or Delete a Row</BodyEmphasis>
          <Body paragraph>
            Under the &quot;Actions&quot; column, you&apos;ll see a pencil icon and a trash bin icon. You can click the
            pencil icon to edit information in the row or click the trash bin icon to delete the row.{" "}
          </Body>
          <BodyEmphasis>Preview Notes on the Dashboard</BodyEmphasis>
          <Body paragraph>
            If there is a notes icon in the &quot;Notes&quot; column, hover over it to preview any notes that were
            inputted by any admin.
          </Body>
          <BodyEmphasis>County and City Column</BodyEmphasis>
          <Body paragraph>
            You may notice that once you add provider availability, the &quot;County&quot; and &quot;City&quot; columns
            appear. This information is automatically populated based on the ZIP code that was inputted and cannot be
            edited. In rare cases, the automatically populated city and county may be different from what you might
            expect. But don’t worry! This is normal. It happens when the inputted ZIP code is a part of two different
            counties/cities.
          </Body>
          <ExternalLink to={FAQ_1_Image_LG} target="_blank">
            <img src={FAQ_1_Image} alt="dashboard help" />
          </ExternalLink>
        </Section>
      </>
    ),
  },
  {
    title: "How can I add provider availability?",
    body: (
      <>
        <Section bgcolor={CivColors.white} minimal>
          <Body paragraph>
            Indicating the number of available providers in a given ZIP code is very important. This information will be
            available for clients to see on our website. Therefore clients are able to reach out to your agency only if
            you indicate provider availability through the dashboard.{" "}
          </Body>
          <Body>Below are steps you can follow to update provider availability.</Body>
          <ol
            style={{
              fontSize: "1.0rem",
            }}
          >
            <li>
              <Body paragraph>
                Make sure you are in the Providers Tab of the agency dashboard. If not, click on the Providers Tab
                located in the top left area of the page.
              </Body>
            </li>
            <li>
              <Body paragraph>
                Once you’re in the Providers Tab, click the “Add Row” button located on the right side of the page.{" "}
              </Body>
            </li>
            <li>
              <Body paragraph>
                Fill out the form to indicate how many providers are available in a given ZIP code. Refer to the image
                below for more detailed information about the form.
              </Body>
            </li>
            <li>
              <Body paragraph>
                Once you are done, click “Save” and you will see this new row of providers in your dashboard.
              </Body>
            </li>
          </ol>
          <ExternalLink to={FAQ_2_Image_LG} target="_blank">
            <img src={FAQ_2_Image} alt="add row help" />
          </ExternalLink>
        </Section>
      </>
    ),
  },
  {
    title: "How do the ZIP codes work?",
    body: (
      <>
        <Section bgcolor={CivColors.white} minimal>
          <Body paragraph>
            When a potential client searches a ZIP code on Carina.org, they not only will be able to see the number of
            providers in that specific ZIP code, but also the number of providers in neighboring ZIP codes that are
            within a 20 minute driving distance.{" "}
          </Body>
          <Body paragraph>
            For example, let’s imagine there are two ZIP codes A and B. ZIP code A has 10 providers and B has 5. Both
            the ZIP codes are physically located next to each other and are also within a 20 minute driving distance
            from one another.
          </Body>
          <Body paragraph>
            If a potential client searches for ZIP code A, they will see a provider count of 15 rather than 10.
          </Body>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
            <ExternalLink to={FAQ_3a_Image} target="_blank">
              <img src={FAQ_3a_Image} alt="map by two zip codes" />
            </ExternalLink>
            <ExternalLink to={FAQ_3_CardImage} target="_blank">
              <img src={FAQ_3_CardImage} alt="agency card" />
            </ExternalLink>
          </Box>
          <BodyEmphasis>Why is this the case?</BodyEmphasis>
          <Body paragraph>
            Because our system accounts for how many providers are within a 20 minute driving distance from that ZIP
            code, rather than just the number of providers from that one specific ZIP code.{" "}
          </Body>
          <Body paragraph>
            For example, let’s say there is another ZIP code, C with one provider. This ZIP code is further away from A
            and it takes more than 20 minutes to drive from A to C. In this case, our system will still only account for
            providers in ZIP code A and B when the consumer searches for ZIP A.{" "}
          </Body>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
            <ExternalLink to={FAQ_3c_Image} target="_blank">
              <img src={FAQ_3c_Image} alt="map by three zip codes" />
            </ExternalLink>
            <ExternalLink to={FAQ_3_CardImage} target="_blank">
              <img src={FAQ_3_CardImage} alt="agency card" />
            </ExternalLink>
          </Box>
        </Section>
      </>
    ),
  },
  {
    title: "What is the Clients Tab? How do I use it?",
    body: (
      <>
        <Section bgcolor={CivColors.white} minimal>
          <Body paragraph>
            In the Clients Tab, you will see potential clients who are seeking home care. Please follow up with these
            individuals within two business days so you can match them with a care provider. The Clients Tab contains
            multiple tools to help you filter and update client information on the dashboard. We will cover the main
            functions of the Clients Tab below.
          </Body>
          <BodyEmphasis>Search by Keyword or Number</BodyEmphasis>
          <Body paragraph>
            Use the search bar at the top of the page to filter the entire dashboard by keyword or number. In addition
            to this, you may also filter keywords within certain columns if that is what you prefer. Additionally, below
            each column title is another search bar that you may use to narrow down your search.
          </Body>
          <BodyEmphasis>Sort Columns Alphabetically or Numerically</BodyEmphasis>
          <Body paragraph>
            In addition to filtering, you can also sort by each column. Just click on a column title to sort
            alphabetically or numerically.{" "}
          </Body>
          <BodyEmphasis>Add or Remove Columns you want on the Dashboard</BodyEmphasis>
          <Body paragraph>
            Click the button on the right of the main search bar. Doing so will open a menu with all the columns that
            can be displayed. From here, check/uncheck the columns you want to add/remove on the dashboard. We strongly
            recommend you to keep the First Name, Contact Method, ZIP code, Assigned to, Status, Notes, and Last Updated
            columns.{" "}
          </Body>
          <BodyEmphasis>Access the Client Details Page</BodyEmphasis>
          <Body paragraph>
            Click on the client’s first name from under the First Name column to access more detailed information about
            the client. Please note that in certain cases you may see the name of the individual representing the client
            instead of the client’s name in the First Name column.{" "}
          </Body>
          <Body paragraph>
            The Client Details Page is where you can add or update notes, preferences, contact information and more
            about the client.{" "}
          </Body>
          <BodyEmphasis>Assign a Client to an Agency Admin</BodyEmphasis>
          <Body paragraph>
            When a new client enters the dashboard, please assign them to an admin using the drop down menu under the
            “Assigned to” column in the dashboard or in the client details page.{" "}
          </Body>
          <Body paragraph>
            We recommend that you only assign clients to yourself, but you may assign clients to different admins as
            well. Using this feature will help you keep track of the clients you’re responsible for.
          </Body>
          <BodyEmphasis>Update Client Status</BodyEmphasis>
          <Body paragraph>
            Whenever a client has been followed up with, please remember to update the status under the “Status” column
            or in the client details page.
          </Body>
          <ul style={{ fontSize: "1.0rem" }}>
            <li>
              <Body>New: Brand new client</Body>
            </li>
            <li>
              <Body>
                Contacted (x1): Attempted to reach out to client but no connection has been made. Left email/voicemail.
              </Body>
            </li>
            <li>
              <Body>
                Contacted (x2): Made second attempt to reach out to client but no connection has been made. Left
                email/voicemail.
              </Body>
            </li>
            <li>
              <Body>Phone Screened: Spoke with client and received more information about client care needs</Body>
            </li>
            <li>
              <Body>Not Now: Client is interested in home care, but is not needing home care right now</Body>
            </li>
            <li>
              <Body>Assignment Pending: Admin is looking for a provider</Body>
            </li>
            <li>
              <Body>Matched: Provider has been matched to client</Body>
            </li>
            <li>
              <Body>
                Declined: Client declined to match or 3 failed attempts have been made in reaching out to client.
              </Body>
            </li>
          </ul>
          <BodyEmphasis>Preview Notes on the Dashboard</BodyEmphasis>
          <Body paragraph>
            If there is a notes icon in the “Notes” column, hover over it to preview any notes that were inputted by any
            admin or by the client.
          </Body>
          <ExternalLink to={FAQ_4_Image_LG} target="_blank">
            <img src={FAQ_4_Image} alt="dashboard help" />
          </ExternalLink>
        </Section>
      </>
    ),
  },
];

const AgencyAdminFAQ = () => {
  return (
    <>
      <Helmet>
        <title>Carina | FAQ</title>
      </Helmet>
      <PageContainerDashboard title="Agency Dashboard" maxWidth="xl">
        <Section bgcolor={CivColors.white}>
          <Constraint columns={10}>
            <Constraint columns={6} textAlign="center">
              <SectionTitle>FAQ</SectionTitle>
              <Body paragraph>Below are some guides that can help you understand how to use the dashboard.</Body>
            </Constraint>
            <AccordionList data={FAQ_COPY} />
          </Constraint>

          <Box style={{ textAlign: "center", marginTop: 24 }}>
            <LinkStyledAsButton variant="contained" href={DASHBOARD_ROUTES.root}>
              Back to Dashboard
            </LinkStyledAsButton>
          </Box>
        </Section>
      </PageContainerDashboard>
    </>
  );
};

export default AgencyAdminFAQ;
