import { generatePath, Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  CarinaAdminFunderManagementController_RegPathDetailDTO,
  CarinaAdminFunderManagementController_RoleDetailDTO,
} from "src/generated/api_types";
import {
  CarinaAdminMgmtContainer,
  CarinaAdminMgmtTabs,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtContainer";
import { CARINA_ADMIN_MGMT_ROUTES } from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { getRole as getRoleType } from "src/pages/carinaAdmin/management/funders/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import { Column, Datatable } from "src/reusable_view_elements/datatable/Datatable";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import { useOnce } from "src/utilities/useOnce";
import { useStateIfMounted } from "use-state-if-mounted";

interface PathParams {
  id: string;
}

interface FunderMgmtDetailProps {
  getRole: typeof getRoleType;
}

export const FunderMgmtRoleDetail: React.FC<FunderMgmtDetailProps> = ({ getRole }) => {
  const { showSnackbar } = useNotification();
  const { id } = useParams<PathParams>();
  const [isFetching, setIsFetching] = useStateIfMounted<boolean>(true);
  const [roleConfig, setRoleConfig] = useStateIfMounted<CarinaAdminFunderManagementController_RoleDetailDTO>(
    undefined as unknown as CarinaAdminFunderManagementController_RoleDetailDTO,
  );

  const REG_STEP_COLUMN_DEFINITIONS: {
    [k: string]: Column<CarinaAdminFunderManagementController_RegPathDetailDTO>;
  } = {
    name: {
      title: "Registration Step",
      render: (data) => (
        <InternalLink
          to={generatePath(CARINA_ADMIN_MGMT_ROUTES.funder_reg_path_details, {
            id: data.id,
          })}
        >
          <Body>
            {data.dtype} ({data.id})
          </Body>
        </InternalLink>
      ),
    },
  };

  useOnce(() => {
    getRole(id)
      .then((res) => {
        setRoleConfig(res.data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  });

  if (isFetching) {
    return (
      <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
        <Body>Loading...</Body>
      </CarinaAdminMgmtContainer>
    );
  }

  if (!isFetching && !roleConfig) {
    showSnackbar(`Could not find Role Config ${id}`, "error");
    return <Redirect to={CARINA_ADMIN_MGMT_ROUTES.root} />;
  }

  return (
    <CarinaAdminMgmtContainer tab={CarinaAdminMgmtTabs.FUNDERS}>
      <Constraint columns={8} paddingTop={4}>
        <SectionTitle>{roleConfig.roleDisplayName}</SectionTitle>
      </Constraint>
      <Constraint columns={8}>
        <Datatable
          columnDefinitions={REG_STEP_COLUMN_DEFINITIONS}
          persistKeyPrefix="funder-mgmt-role-reg-paths"
          data={roleConfig.registrationPaths}
        />
      </Constraint>
    </CarinaAdminMgmtContainer>
  );
};
