import { Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsMedicaidJobSurveyConfirmation } from "src/i18n/Namespaces";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";

const FilledViaOtherMeans = () => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobSurveyConfirmation, nsCommonFormsBtns]);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  if (!ready) {
    return (
      <>
        <Section minimizeBottomGutter minHeight="100vh">
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </>
    );
  }

  return (
    <Section textAlign="center">
      <Constraint columns={8}>
        <SectionTitle>{t("job_post_deleted.label", { ns: nsMedicaidJobSurveyConfirmation })}</SectionTitle>
        <Body paragraph>
          {t("job_post_deleted.description.your_answers_will", { ns: nsMedicaidJobSurveyConfirmation })}
        </Body>
        <Body paragraph>
          {t("job_post_deleted.description.you_can_repost", { ns: nsMedicaidJobSurveyConfirmation })}
        </Body>
        <Grid container>
          <Grid item xs={12}>
            <LinkStyledAsButton
              variant="outlined"
              component={Link}
              to={getMedicaidRouteByUserType("myJobList", isUserType)}
              sx={desktopSize ? { marginRight: "24px" } : { marginBottom: "24px" }}
            >
              {t("button.return_my_jobs", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
            <LinkStyledAsButton
              variant="outlined"
              component={Link}
              to={getMedicaidRouteByUserType("myDeletedJobs", isUserType)}
            >
              {t("button.view_deleted_jobs", { ns: nsCommonFormsBtns })}
            </LinkStyledAsButton>
          </Grid>
        </Grid>
      </Constraint>
    </Section>
  );
};

export default FilledViaOtherMeans;
