import { Box, Divider, Hidden, ListItem, ListItemText, MenuList } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as NavRouterLink } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonNavigation } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { MenuButton } from "src/reusable_view_elements/MenuButton";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { NavLink } from "src/reusable_view_elements/navbars/NavBar";
import FullSizeMenu from "src/reusable_view_elements/navbars/navbar_elements/FullSizeMenu";
import LanguageButton from "src/reusable_view_elements/navbars/navbar_elements/LanguageButton";
import NavBarGrid from "src/reusable_view_elements/navbars/navbar_elements/NavBarGrid";
import CivColors from "src/themes/civilization/CivColors";

type NavBarLink = {
  label: string;
  route: string;
};

interface ChildcareNavBarProps {
  showLanguage?: boolean;
}

const ChildcareNavBarDefaults = {
  showLanguage: false,
};

const ChildcareNavbar = (showLanguage: ChildcareNavBarProps) => {
  const [mainMenuIsOpen, setMainMenuVisibility] = useState(false);
  const { isImpersonated } = useUserSession();
  const { t, ready } = useTranslation(nsCommonNavigation);

  const navbarLinks: Array<NavBarLink> = [
    {
      label: ready && showLanguage ? t("link.home") : "Home",
      route: CHILD_CARE_ROUTES.homepage,
    },
    {
      label: ready && showLanguage ? t("link.my_business_profile") : "My Business Profile",
      route: CHILD_CARE_ROUTES.editProfile,
    },
  ];

  return (
    <Constraint columns={14}>
      <NavBarGrid
        center={
          <Hidden lgDown>
            {/* DESKTOP SIZE */}
            <Box component="nav" aria-label="Childcare" display="flex" sx={{ height: "24px" }}>
              {navbarLinks.map((link, i) => (
                <Box
                  key={link.label}
                  sx={{
                    borderLeft: i != 0 ? `1px solid ${CivColors.coreDarkNavy}` : "none",
                    "& .MuiTypography-root": { display: "flex" },
                  }}
                >
                  <NavLink to={link.route}>{link.label}</NavLink>
                </Box>
              ))}
            </Box>
          </Hidden>
        }
        right={
          <>
            {/* DESKTOP SIZE */}
            <Hidden lgDown>
              {showLanguage && <LanguageButton menuType="desktop" />}
              <LinkStyledAsButton variant="outlined" href={ROUTES.logout} disabled={isImpersonated()}>
                {ready ? t("link.logout") : "Logout"}
              </LinkStyledAsButton>
            </Hidden>

            {/* MOBILE SIZE */}
            <Hidden lgUp>
              <MenuButton
                onClick={() => {
                  setMainMenuVisibility(true);
                }}
              >
                {ready ? t("link.menu") : "Menu"}
              </MenuButton>

              <FullSizeMenu open={mainMenuIsOpen} closeMenu={() => setMainMenuVisibility(false)}>
                <MenuList aria-label="Site Wide" sx={{ margin: "24px 0px", padding: 0 }}>
                  {navbarLinks.map((item) => (
                    <MenuItem
                      key={item.label}
                      component={NavRouterLink}
                      // @ts-ignore
                      to={item.route}
                      selected={window.location.pathname === item.route}
                      onClick={() => setMainMenuVisibility(false)}
                    >
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </MenuList>
                <Divider />
                <ListItem sx={{ padding: 0, marginTop: "24px" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <LinkStyledAsButton variant="outlined" href={ROUTES.logout} disabled={isImpersonated()}>
                      {ready ? t("link.logout") : "Logout"}
                    </LinkStyledAsButton>
                  </div>
                </ListItem>
                {showLanguage && (
                  <ListItem sx={{ marginTop: "16px", padding: 0, justifyContent: "center" }}>
                    <LanguageButton menuType="mobile" sx={{ paddingLeft: "16px", paddingRight: "8px" }} />
                  </ListItem>
                )}
              </FullSizeMenu>
            </Hidden>
          </>
        }
      />
    </Constraint>
  );
};

ChildcareNavbar.defaultProps = ChildcareNavBarDefaults;
export default ChildcareNavbar;
