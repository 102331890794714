import { Grid } from "@mui/material";
import { FormikBag, FormikContextType, isFunction, useFormikContext } from "formik";
import { Agency_DTO_Response_Listing, Funder_DTO_Response_Listing } from "src/generated/api_types";
import {
  getAllAgencies as getAllAgenciesType,
  getAllFunders as getAllFundersType,
} from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import DashSection from "src/reusable_view_elements/DashSection";
import FastMaskTextField from "src/reusable_view_elements/form_fields/FastMaskTextField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { FormSelectField, SelectOption } from "src/reusable_view_elements/form_fields/FormSelectField";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import { FormAddressField } from "src/reusable_view_elements/form_fields";
import { Body } from "src/reusable_view_elements/Typography";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { useOnce } from "src/utilities/useOnce";
import { useStateIfMounted } from "use-state-if-mounted";
import { object, string } from "yup";

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const uuidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const validationSchema = {
  firstName: string().required("Error: Please enter a first name"),
  lastName: string().required("Error: Please enter a last name"),
  phone: string().matches(phoneRegExp, { message: "Please enter a valid phone number" }),
  agencyId: string().matches(uuidRegExp, { message: "Please select an agency" }),
  email: string()
    .required("Error: Please enter a valid email address")
    .email("Error: Please enter a valid email address"),
};

const formattedSchema = object(validationSchema);

export interface AgencyAdminFormFields {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  agencyId: string;
  agencyZip: string;
  agencyFunder: string;
}

export type AgencyAdminFormProps<RemoteData = void, Fields extends AgencyAdminFormFields = AgencyAdminFormFields> = {
  data?: RemoteData;
  getAllFunders: typeof getAllFundersType;
  getAllAgencies: typeof getAllAgenciesType;
  handleSubmit?: (values: Fields, formikBag: FormikBag<AgencyAdminFormProps<RemoteData, Fields>, Fields>) => void;
  editable?: boolean | (keyof AgencyAdminFormFields)[];
  children: (formikContext: FormikContextType<Fields>) => React.ReactNode;
};

//TODO: Check if Carina Admin and Funder Admin can use the same underlying AgencyAdminForm
export function AgencyAdminForm<RemoteData, Fields extends AgencyAdminFormFields = AgencyAdminFormFields>({
  getAllAgencies,
  getAllFunders,
  editable,
  children,
}: AgencyAdminFormProps<RemoteData, Fields>) {
  if (!isFunction(children)) {
    throw new Error("AgencyAdminForm child must be a function");
  }

  const [funders, setFunders] = useStateIfMounted<Funder_DTO_Response_Listing[]>([]);
  const [agencies, setAgencies] = useStateIfMounted<Agency_DTO_Response_Listing[]>([]);
  const [isFetching, setIsFetching] = useStateIfMounted(true);
  const formikContext = useFormikContext<Fields>();

  useOnce(() => {
    Promise.allSettled([
      getAllFunders()
        .then((response) => {
          if (response.status === 200) {
            setFunders(response.data);
          } else {
            // eslint-disable-next-line no-console
            console.error("Failed to query funders");
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${response.status}`);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("Failed to query funders");
          // eslint-disable-next-line no-console
          console.error(`Error: ${err}`);
        }),
      getAllAgencies()
        .then((response) => {
          if (response.status === 200) {
            setAgencies(response.data);
          } else {
            // eslint-disable-next-line no-console
            console.error("Failed to query agencies");
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${response.status}`);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("Failed to query agencies");
          // eslint-disable-next-line no-console
          console.error(`Error: ${err}`);
        }),
    ]).finally(() => {
      setIsFetching(false);
    });
  });

  if (isFetching) {
    return <Body>Information is being retrieved</Body>;
  }

  function getFunderOptions(): SelectOption<string>[] {
    return funders.map((funder) => {
      return { label: funder.segment, value: funder.id };
    });
  }

  function getAgencyOptions(): SelectOption<string>[] {
    return agencies.map((agency) => {
      return { label: agency.name, value: agency.id };
    });
  }

  function isEditable(fieldName: keyof AgencyAdminFormFields) {
    if (Array.isArray(editable)) return editable.includes(fieldName);
    return editable === true;
  }

  return (
    <Form name="agency-admin-invite" localizationReady={true}>
      <DashSection label="Admin Information" container topBorder>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="firstName"
            label="First Name"
            disabled={!isEditable("firstName")}
            required={isRequiredField("firstName", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="lastName"
            label="Last Name"
            disabled={!isEditable("lastName")}
            required={isRequiredField("lastName", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FastMaskTextField
            name="phone"
            label="Work Phone"
            maskType="phone"
            required={isRequiredField("phone", formattedSchema)}
            disabled={!isEditable("phone")}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="email"
            label="Email Address"
            disabled={!isEditable("email")}
            type="email"
            required={isRequiredField("email", formattedSchema)}
          />
        </Grid>
      </DashSection>

      <DashSection label="Agency Information" container topBorder>
        <Grid item xs={12} md={12}>
          <FormSelectField
            name="agencyFunder"
            label="Funder"
            disabled={!isEditable("agencyFunder")}
            selectOptions={getFunderOptions()}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormAddressField
            zipCodeOnly
            name="agencyZip"
            label="Zip Code"
            disabled={!isEditable("agencyZip")}
            required={isRequiredField("agencyZip", formattedSchema)}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormSelectField
            name="agencyId"
            label="Agency Name"
            disabled={!isEditable("agencyId")}
            selectOptions={getAgencyOptions()}
          />
        </Grid>
      </DashSection>
      {children(formikContext)}
    </Form>
  );
}

AgencyAdminForm.defaultProps = {
  data: undefined,
  editable: true,
  handleSubmit: () => {},
};
