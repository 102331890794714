import { useTranslation } from "react-i18next";
import { nsCommonCTAbtns, nsCommonForms } from "src/i18n/Namespaces";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";

const LeadGenerationForm = () => {
  const { t } = useTranslation([nsCommonCTAbtns, nsCommonForms]);
  return (
    <LinkStyledAsButton
      variant="contained"
      sx={{ marginTop: "16px" }}
      href="mailto:contactus@carina.org?subject=Join Newsletter&body=Hey! Tell me more about Carina."
    >
      {t("sign up")}
    </LinkStyledAsButton>
  );
};

export default LeadGenerationForm;
