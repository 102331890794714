import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { RoleSelectionController_RoleSelectionDTO, ReferralMethodEnum } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidRegRoleSelection } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { selectRole } from "src/pages/registration/role_selection/Queries";
import { boolean, object, ref, string } from "yup";

interface ContactInfoFormFields {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  terms: boolean;
  medicaidState: string;
  medicaidRegPath: string;
  referralMethod: ReferralMethodEnum | undefined;
  referralMethodOther: string;
}

export const REFERRAL_MAX_LENGTH = 500;

function useContactInfoForm() {
  const { sendUserInfoToContext, getNextStepRoute, regPath } = useRegistrationContext();
  const history = useHistory();
  const { showSnackbar } = useNotification();
  const { t } = useTranslation([nsCommonFormsBtns, nsMedicaidRegRoleSelection, nsCommonToasts]);

  const validationSchema = object().shape({
    medicaidState: string()
      .label(t("field.state.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.select_one_dropdown", { ns: nsCommonFormsBtns })),
    medicaidRegPath: string()
      .label(t("field.im_a.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.select_one_dropdown", { ns: nsCommonFormsBtns })),
    firstName: string()
      .label(t("field.first_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.first_name.error.provide", { ns: nsCommonFormsBtns })),
    lastName: string()
      .label(t("field.last_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.last_name.error.provide", { ns: nsCommonFormsBtns })),
    email: string()
      .label(t("field.email.label.email", { ns: nsCommonFormsBtns }))
      .required(t("field.email.error.valid_email_sign_up", { ns: nsCommonFormsBtns }))
      .email(t("field.email.error.valid_email_sign_up", { ns: nsCommonFormsBtns })),
    confirmEmail: string()
      .label(t("field.re_enter_email.label.email", { ns: nsCommonFormsBtns }))
      .oneOf([ref("email"), undefined], t("field.re_enter_email.error.must_match", { ns: nsCommonFormsBtns }))
      .required(t("field.re_enter_email.error.must_match", { ns: nsCommonFormsBtns })),
    referralMethod: string()
      .label(t("field.referral.label", { ns: nsCommonFormsBtns }))
      .matches(/\w+[^EMPTY]/, t("field.referral_how.error", { ns: nsCommonFormsBtns }))
      .required(t("field.referral_how.error", { ns: nsCommonFormsBtns })),
    referralMethodOther: string()
      .label(t("field.referral_where", { ns: nsCommonFormsBtns }))
      .when("referralMethod", {
        is: ReferralMethodEnum.OTHER,
        then: string()
          .label(t("field.referral_where", { ns: nsCommonFormsBtns }))
          .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
          .max(REFERRAL_MAX_LENGTH, ({ max, value }) =>
            t("field.generic.count_char_over_limit.label", {
              ns: nsCommonFormsBtns,
              count: value.length - max,
            }),
          )
          .nullable(),
      }),
    terms: boolean()
      .label(t("field.tos_checkbox.carina_tos.label", { ns: nsCommonFormsBtns }))
      .oneOf([true], t("field.tos_checkbox_error", { ns: nsCommonFormsBtns })),
  });

  const handleSubmit = async (values: ContactInfoFormFields) => {
    sendUserInfoToContext(values);
    const relevantUserInfo: RoleSelectionController_RoleSelectionDTO = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      referralMethod: values.referralMethod!, // per validationSchema, user can't submit without selecting something
      referralMethodOther: values.referralMethodOther,
      regPathId: values.medicaidRegPath,
    };
    try {
      const resp = await selectRole(relevantUserInfo);
      if (!resp.data) {
        showSnackbar(
          t(
            "error.registering_you",
            "Oops! You either already have an account, or we had a problem registering you! " +
              "Please contact support@carina.org.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
        history.push(ROUTES.login);
      }

      if (resp.data) {
        history.push(
          getNextStepRoute(resp.data?.step, {
            funder: values.medicaidState.split("-")[1].toLowerCase(),
            role: regPath?.roleConfig.roleType.toLowerCase() || "",
          }),
        );
      }
    } catch (err) {
      showSnackbar(t("error.generic", "Something went wrong.", { ns: nsCommonToasts }), "error");
    }
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    terms: false,
    medicaidState: regPath?.roleConfig?.funder.segment || "",
    medicaidRegPath: regPath?.id || "",
    referralMethod: undefined,
    referralMethodOther: "",
  };

  return {
    validationSchema,
    initialValues,
    handleSubmit,
  };
}

export default useContactInfoForm;
