import {
  AddressChangeRequestPayload,
  AxiosShortLinkControllerClient,
  AxiosUserControllerClient,
  LicenseChangeRequestPayload,
  UserController_PasswordResetRequestDTO,
} from "src/generated/api_types";

const usersClient = new AxiosUserControllerClient();
const slClient = new AxiosShortLinkControllerClient();

export async function getTokenData(token: string) {
  return usersClient.getPasswordResetTokenData({
    token,
  });
}

export async function requestPasswordReset(dto: UserController_PasswordResetRequestDTO) {
  return usersClient.requestPasswordReset(dto);
}

export async function requestPasswordResetOnBehalf(dto: UserController_PasswordResetRequestDTO) {
  return usersClient.requestPasswordResetOnBehalf(dto);
}

export async function requestLicenseNumberChange(payload: LicenseChangeRequestPayload) {
  return usersClient.requestLicenseNumberChange(payload);
}

export async function requestAddressChange(payload: AddressChangeRequestPayload) {
  return usersClient.requestAddressChange(payload);
}

export async function expandShortlink(base62id: string) {
  return slClient.expandShortlink(base62id);
}
