import _ from "lodash";
import { MaterialTableProps } from "@material-table/core";
import { useEffect, useRef, useState } from "react";
import { Page, PageInfo, RestResponse, SortDirection } from "src/generated/api_types";
import { Column, CURRENT_PAGE_KEY, Datatable, DatatableProps } from "src/reusable_view_elements/datatable/Datatable";

export type ServersideDatatableProps<D extends object, T extends Column<D>> = {
  dataFetchFn: (pageInfo: PageInfo) => RestResponse<Page<D>>;
  columnDefinitions: { [k: string]: ServersideColumn<D> };
  queryDependencies?: Array<any>;
  runQueryInEffect?: boolean;
} & Omit<DatatableProps<D, T>, "data" | "columnDefinitions">;

export function getEnumKeyByEnumValue<TEnumKey extends string, TEnumVal extends string | number>(
  myEnum: { [key in TEnumKey]: TEnumVal },
  enumValue: TEnumVal,
): string {
  const keys = (Object.keys(myEnum) as TEnumKey[]).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : "";
}

export type ServersideColumn<T extends object> = {
  sortingField?: string; // for ServerSideDatatable this name should correspond with the field's name in the BE
  filteringField?: string | string[]; // Names should correspond with the field names in the BE
} & Omit<Column<T>, "customFilterAndSearch" | "customSort" | "sorting">;

export function ServersideDatatable<D extends object, T extends ServersideColumn<D>>({
  columnDefinitions,
  queryDependencies = [],
  runQueryInEffect = false,
  ...props
}: ServersideDatatableProps<D, T>) {
  const { dataFetchFn, options, persistKeyPrefix } = props;
  const tableRef = useRef<MaterialTableProps<any>>();
  const [isInitialView, setIsInitialView] = useState(false);
  const [extraPrefix, setExtraPrefix] = useState("");

  useEffect(() => {
    if (tableRef && tableRef.current && tableRef.current.onQueryChange) {
      setExtraPrefix(getExtraPrefix());
      setIsInitialView(true);
      if (runQueryInEffect) {
        // @ts-ignore
        tableRef.current.onQueryChange();
      }
    }
  }, queryDependencies);

  const getExtraPrefix = (): string => {
    if (queryDependencies !== null && queryDependencies?.length > 0) return `-${queryDependencies.toString()}`;
    return "";
  };

  function saveCurrentPage(currPage: number): void {
    sessionStorage.setItem(`${persistKeyPrefix}${extraPrefix}-${CURRENT_PAGE_KEY}`, JSON.stringify(currPage));
  }

  const getCurrentPage = (defaultPage: number): number => {
    const currentPageString = sessionStorage.getItem(`${persistKeyPrefix}${extraPrefix}-${CURRENT_PAGE_KEY}`);
    let currentPage = defaultPage;
    if (isInitialView) {
      currentPage = currentPageString ? JSON.parse(currentPageString) : 0;
    }
    return currentPage;
  };

  return (
    <Datatable
      {...props}
      tableRef={tableRef}
      columnDefinitions={Object.fromEntries(
        Object.entries(columnDefinitions).map((col) => {
          return [
            col[0],
            {
              ...col[1],
              sorting: !!(col[1] as ServersideColumn<D>).sortingField,
              customFilterAndSearch: (col[1] as ServersideColumn<D>).filteringField ? () => true : undefined,
            },
          ];
        }),
      )}
      onPageChange={(pageNum, _pageSize) => {
        saveCurrentPage(pageNum);
      }}
      options={{
        // search: false, // TODO: https://github.com/CarinaWeb/CarinaCore/issues/1012: 'show/hide columns' button hides
        columnsButton: true,
        debounceInterval: 500,
        ...options,
      }}
      data={async (query) => {
        if (!runQueryInEffect && query.search.length < 1) {
          return {
            data: [],
            page: 0,
            totalCount: 0,
          };
        }

        const currentPage = getCurrentPage(query.page);
        const pageInfo: PageInfo = {
          pageNum: currentPage,
          pageSize: query.pageSize,
          filters: {},
          search: query.search,
          searchMap: {},
        };
        const orderByInfo = query.orderBy as ServersideColumn<D>;

        if (orderByInfo && orderByInfo.sortingField)
          pageInfo.sort = {
            columnName: (query.orderBy as ServersideColumn<D>).sortingField!!,
            direction: getEnumKeyByEnumValue(SortDirection, query.orderDirection.toUpperCase()) as SortDirection,
          };

        // Map used in the BE to search each and every field for the text entered in the SEARCHBOX
        Object.values(columnDefinitions)
          .map((col) => col.filteringField)
          .filter((filter) => filter !== undefined)
          .forEach((filter) => {
            if (filter !== undefined) {
              const key = _.isArray(filter) ? filter.join(",") : filter;
              pageInfo.searchMap[key] = query.search;
            }
          });

        // Map used in the BE to search specific fields for the text entered in its respective column/FILTER
        query.filters
          .filter((filter) => (filter.column as ServersideColumn<D>).filteringField)
          .filter((filter) => filter.value.trim().length > 0)
          .forEach((filter) => {
            const column = filter.column as ServersideColumn<D>;
            const key = _.isArray(column.filteringField) ? column.filteringField!!.join(",") : column.filteringField!!;
            pageInfo.filters[key] = filter.value;
          });

        const serverRes = await dataFetchFn(pageInfo);
        setIsInitialView(false);
        return {
          data: serverRes.data.content,
          page: serverRes.data.pageable.pageNumber,
          totalCount: serverRes.data.totalElements,
        };
      }}
    />
  );
}
