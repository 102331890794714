import { Divider, Grid, useMediaQuery, Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import EmanChildCareProcess1 from "src/assets/images/emans-child-care-process-1.svg";
import EmanChildCareProcess2 from "src/assets/images/emans-child-care-process-2.svg";
import EmanChildCareProcess3 from "src/assets/images/emans-child-care-process-3.svg";
import EmanChildCareProcess4 from "src/assets/images/emans-child-care-process-4.svg";
import EmanChildCareProgramHero from "src/assets/images/emans-child-care-program-hero.png";
import Testimonial1Image from "src/assets/images/round-child-care-playpen.png";
import SEIU2001ColorLogo from "src/assets/images/SEIU2001-color-logo.png";
import SEIU521ColorLogo from "src/assets/images/SEIU521-color-logo.png";
import SEIU925ColorLogo from "src/assets/images/SEIU925-color-logo.png";
import SEIU99ColorLogo from "src/assets/images/SEIU99-color-logo.png";
import SEIUESFColorLogo from "src/assets/images/SEIUESF-color-logo.png";
import SEIUHCIIColorLogo from "src/assets/images/SEIUHCII-color-logo.png";
import UDW3930ColorLogo from "src/assets/images/UDW-color-logo.png";
import { nsChildcareProgramPage, nsCommonCTAbtns } from "src/i18n/Namespaces";
import {
  CheckBadgeIcon,
  TaskChecklistCheckIcon,
  TimeClockCircleAlternateIcon,
  PasswordDesktopApprovedIcon,
  FamilyHomeIcon,
  Baby1Icon,
  JobSearchProfileIcon,
  ToysTeddyBearIcon,
  MoneyWalletOpenIcon,
} from "src/assets/icons/StreamlineIcons";
import { Child_Care_Blog_Posts } from "src/pages/blog/BlogPosts";
import { AccordionList } from "src/reusable_view_elements/Accordion";
import BlogPost from "src/reusable_view_elements/BlogPost";
import Blurb from "src/reusable_view_elements/Blurb";
import Constraint from "src/reusable_view_elements/Constraint";
import { FeatureList, FeatureListItem } from "src/reusable_view_elements/FeatureList";
import Footer from "src/reusable_view_elements/Footer";
import ChildCareAddressForm from "src/reusable_view_elements/forms/ChildCareAddressForm";
import Hero from "src/reusable_view_elements/Hero";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import Separator from "src/reusable_view_elements/Separator";
import { Body, BodyTitle, Quote, QuoteAttribution, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

const PREFIX = "ChildCare";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")({
  [`& .${classes.root}`]: {
    height: 20,
    backgroundColor: "transparent",
  },
});

const PartnerLogos = [
  { src: UDW3930ColorLogo, alt: "UDW AFSCME Local 3930 / AFL-CIO The Home Care Providers Union" },
  { src: SEIU99ColorLogo, alt: "SEIU Local 99 Education Workers United" },
  { src: SEIU925ColorLogo, alt: "SEIU Local 925" },
  { src: SEIU521ColorLogo, alt: "SEIU Local 521" },
  { src: SEIUESFColorLogo, alt: "SEIU Education Support Fund" },
  { src: SEIUHCIIColorLogo, alt: "SEIU HCII Illinois Indiana Missouri Kansas" },
  { src: SEIU2001ColorLogo, alt: "CSEA-SEIU Local 2001 The Child Care Union" },
];

export const LineBreak = Divider;

const ChildCare = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsChildcareProgramPage, nsCommonCTAbtns]);

  const FAQ_COPY = [
    {
      title: `${t("faq 1 title")}`,
      body: `${t("faq 1 body")}`,
    },
    {
      title: `${t("faq 2 title")}`,
      body: `${t("faq 2 body")}`,
    },
    {
      title: `${t("faq 3 title")}`,
      body: (
        <Root>
          <Body paragraph>{t("faq 3 body1")}</Body>
          <Body paragraph>{t("faq 3 body2")}</Body>
          <Trans
            t={t}
            i18nKey="faq 3 body3"
            components={{
              faq3link1: <MuiLink href="https://www.youtube.com/watch?v=E34VCr1aRtU&pp=sAQA" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Trans
            t={t}
            i18nKey="faq 3 body4"
            components={{
              faq3link2: (
                <MuiLink
                  //eslint-disable-next-line
                  href="https://blog.carina.org/childcare/what-is-family-childcare?_ga=2.150286838.1992967595.1623185535-1822976612.1617727352#What-is-family-childcare"
                  target="_blank"
                />
              ),
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
        </Root>
      ),
    },
    {
      title: `${t("faq 4 title")}`,
      body: `${t("faq 4 body")}`,
    },
    {
      title: `${t("faq 5 title")}`,
      body: (
        <>
          <Body paragraph>{t("faq 5 body1")}</Body>
          <Trans
            t={t}
            i18nKey="faq 5 body2"
            components={{
              faq5li: <li />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Body paragraph>{t("faq 5 body5")}</Body>
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Trans
            t={t}
            i18nKey="faq 5 body3"
            components={{
              faq5link1: <MuiLink href="https://carina.org/childcare" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Trans
            t={t}
            i18nKey="faq 5 body4"
            components={{
              faq5link2: <MuiLink href="https://www.childcareaware.org/resources/ccrr-search-form/" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
        </>
      ),
    },
    {
      title: `${t("faq 6 title")}`,
      body: (
        <>
          <Body paragraph>{t("faq 6 body1")}</Body>
          <Body paragraph>{t("faq 6 body2")}</Body>
          <Trans
            t={t}
            i18nKey="faq 6 body3"
            components={{
              faq6link1: (
                <MuiLink
                  //eslint-disable-next-line
                  href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/guidance-for-childcare.html"
                  target="_blank"
                />
              ),
              faq6link2: <MuiLink href="https://covid19.ca.gov/safely-reopening/#school" target="_blank" />,
              faq6link3: <MuiLink href="https://coronavirus.wa.gov/information-for/workers" target="_blank" />,
              faq6link4: (
                <MuiLink
                  href="https://portal.ct.gov/Coronavirus/Information-For/Childcare-Provider-Resources"
                  target="_blank"
                />
              ),
              faq6link5: <MuiLink href="https://www.dhs.state.il.us/page.aspx?item=30355" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
        </>
      ),
    },
    {
      title: `${t("faq 7 title")}`,
      body: (
        <>
          <Body paragraph>{t("faq 7 body1")}</Body>
          <Trans
            t={t}
            i18nKey="faq 7 body2"
            components={{
              faq7link1: (
                <MuiLink href="https://www.cdss.ca.gov/inforesources/community-care-licensing" target="_blank" />
              ),
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Trans
            t={t}
            i18nKey="faq 7 body3"
            components={{
              faq7link2: <MuiLink href="https://coronavirus.wa.gov/information-for/workers" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Trans
            t={t}
            i18nKey="faq 7 body4"
            components={{
              faq7link3: (
                <MuiLink
                  href="https://portal.ct.gov/Coronavirus/Information-For/Childcare-Provider-Resources"
                  target="_blank"
                />
              ),
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
          <Trans
            t={t}
            i18nKey="faq 7 body5"
            components={{
              faq7link4: <MuiLink href="https://www.dhs.state.il.us/page.aspx?item=30355" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
        </>
      ),
    },
    {
      title: `${t("faq 8 title")}`,
      body: `${t("faq 8 body")}`,
    },
    {
      title: `${t("faq 9 title")}`,
      body: (
        <>
          <Body paragraph>{t("faq 9 body1")}</Body>
          <Body paragraph>{t("faq 9 body2")}</Body>
          <Trans
            t={t}
            i18nKey="faq 9 body3"
            components={{
              faq9link1: <MuiLink href="https://covid19.ca.gov/safely-reopening/#school" target="_blank" />,
              faq9link2: <MuiLink href="https://coronavirus.wa.gov/information-for/workers" target="_blank" />,
              faq9link3: (
                <MuiLink
                  href="https://portal.ct.gov/Coronavirus/Information-For/Childcare-Provider-Resources"
                  target="_blank"
                />
              ),
              faq9link4: <MuiLink href="https://www.dhs.state.il.us/page.aspx?item=30355" target="_blank" />,
            }}
            parent={Body}
          />
          <LineBreak
            classes={{
              root: classes.root,
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Carina | Find Child Care</title>
      </Helmet>

      <NavBar showLanguage />
      <main id="main-content">
        {ready ? (
          <>
            <Hero bgcolor={CivColors.gradient3}>
              <Constraint columns={8}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SectionTitle id="find-care">{t("hero headline")}</SectionTitle>
                    <ChildCareAddressForm />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <img src={EmanChildCareProgramHero} alt="" width="100%" />
                  </Grid>
                </Grid>
              </Constraint>
            </Hero>

            <Section bgcolor={CivColors.white}>
              <Constraint columns={8} textAlign="center">
                <SectionTitle>{t("partner header")}</SectionTitle>
                <Body paragraph>{t("partner body")}</Body>
                <Grid container justifyContent="center" spacing={6}>
                  {PartnerLogos.map((logo) => (
                    <Grid item xs={12} sm={3} key={logo.alt}>
                      <img src={logo.src} alt={logo.alt} style={{ maxWidth: "100%" }} />
                    </Grid>
                  ))}
                </Grid>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.palePeach}>
              <Constraint columns={6}>
                <SectionTitle align="center" style={{ marginBottom: 60 }}>
                  {t("value prop header")}
                </SectionTitle>
                <FeatureList>
                  <FeatureListItem
                    icon={ToysTeddyBearIcon}
                    primary={<BodyTitle paragraph>{t("value prop 1 header")}</BodyTitle>}
                    secondary={<Body paragraph>{t("value prop 1 body")}</Body>}
                  />
                  <FeatureListItem
                    icon={JobSearchProfileIcon}
                    primary={<BodyTitle paragraph>{t("value prop 2 header")}</BodyTitle>}
                    secondary={<Body paragraph>{t("value prop 2 body")}</Body>}
                  />
                  <FeatureListItem
                    icon={CheckBadgeIcon}
                    primary={<BodyTitle paragraph>{t("value prop 3 header")}</BodyTitle>}
                    secondary={<Body>{t("value prop 3 body")}</Body>}
                  />
                </FeatureList>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.paleBlue} textAlign="center">
              <Constraint columns={9}>
                <SectionTitle style={{ marginBottom: 60 }}>{t("value prop 1 header")}</SectionTitle>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TimeClockCircleAlternateIcon style={{ width: 60, height: 60 }} />
                    <BodyTitle>{t("icon panel header 1")}</BodyTitle>
                    <Body>{t("icon panel body 1")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MoneyWalletOpenIcon style={{ width: 60, height: 60 }} />
                    <BodyTitle>{t("icon panel header 2")}</BodyTitle>
                    <Body>{t("icon panel body 2")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FamilyHomeIcon style={{ width: 60, height: 60 }} />
                    <BodyTitle>{t("icon panel header 3")}</BodyTitle>
                    <Body>{t("icon panel body 3")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TaskChecklistCheckIcon style={{ width: 60, height: 60 }} />
                    <BodyTitle>{t("icon panel header 4")}</BodyTitle>
                    <Body>{t("icon panel body 4")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <PasswordDesktopApprovedIcon style={{ width: 60, height: 60 }} />
                    <BodyTitle>{t("icon panel header 5")}</BodyTitle>
                    <Body>{t("icon panel body 5")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Baby1Icon style={{ width: 60, height: 60 }} />
                    <BodyTitle>{t("icon panel header 6")}</BodyTitle>
                    <Body>{t("icon panel body 6")}</Body>
                  </Grid>
                </Grid>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.palePeach} textAlign="center">
              <SectionTitle gutterBottom>
                <Constraint columns={6}>{t("secondary search cta")}</Constraint>
              </SectionTitle>
              <LinkStyledAsButton variant="contained" href="#find-care">
                {t("hero search button")}
              </LinkStyledAsButton>
            </Section>

            <Section bgcolor={CivColors.paleBlue} textAlign="center">
              <SectionTitle>{t("how it works main header")}</SectionTitle>
              <Constraint columns={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <img src={EmanChildCareProcess1} alt="" width="100%" style={{ maxWidth: 200, marginBottom: 24 }} />
                    <BodyTitle paragraph>{t("how it works header 1")}</BodyTitle>
                    <Body>{t("how it works body 1")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <img src={EmanChildCareProcess2} alt="" width="100%" style={{ maxWidth: 200, marginBottom: 24 }} />
                    <BodyTitle paragraph>{t("how it works header 2")}</BodyTitle>
                    <Body>{t("how it works body 2")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <img src={EmanChildCareProcess3} alt="" width="100%" style={{ maxWidth: 200, marginBottom: 24 }} />
                    <BodyTitle paragraph>{t("how it works header 3")}</BodyTitle>
                    <Body>{t("how it works body 3")}</Body>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <img src={EmanChildCareProcess4} alt="" width="100%" style={{ maxWidth: 200, marginBottom: 24 }} />
                    <BodyTitle paragraph>{t("how it works header 4")}</BodyTitle>
                    <Body>{t("how it works body 4")}</Body>
                  </Grid>
                </Grid>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray} textAlign="center">
              <SectionTitle style={{ marginBottom: 60 }}>{t("testimonial header")}</SectionTitle>
              <Constraint columns={8}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Quote paragraph align="left">{`“${t("video testimonial 1")}“`}</Quote>
                    <QuoteAttribution align="right">&ndash; {t("video testimonial source 1")}</QuoteAttribution>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Blurb reverse imgProps={{ src: Testimonial1Image, alt: "" }} />
                  </Grid>
                </Grid>
                <Separator line bgcolor={CivColors.lightGray} style={{ marginBottom: 60, marginTop: 60 }} />
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <LiteYouTubeEmbed
                      id="gHAwWjQqvEc"
                      title="Parent describes experience with Carina"
                      params="rel=0"
                      announce="Watch"
                      poster={desktopSize ? "hqdefault" : "mqdefault"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Quote paragraph>{`“${t("video testimonial quote")}“`}</Quote>
                    <QuoteAttribution align="right">&ndash; {t("video testimonial source")}</QuoteAttribution>
                  </Grid>
                </Grid>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.paleBlue}>
              <SectionTitle align="center">{t("parent resources header")}</SectionTitle>
              <Grid container spacing={2} justifyContent="center">
                {[
                  Child_Care_Blog_Posts["what-to-ask"],
                  Child_Care_Blog_Posts["what-is-childcare"],
                  Child_Care_Blog_Posts["not-for-profit"],
                ].map((blogPost) => (
                  <Grid item key={blogPost.translateKey}>
                    <BlogPost img={blogPost.image} title={t(blogPost.translateKey)} link={blogPost.url} />
                  </Grid>
                ))}
              </Grid>
            </Section>

            <Section bgcolor={CivColors.palePeach} textAlign="center">
              <SectionTitle gutterBottom>
                <Constraint columns={6}>{t("secondary search cta")}</Constraint>
              </SectionTitle>
              <LinkStyledAsButton variant="contained" href="#find-care">
                {t("hero search button")}
              </LinkStyledAsButton>
            </Section>

            <Section bgcolor={CivColors.lightGray}>
              <Constraint columns={4} textAlign="center">
                <SectionTitle>{t("contact us header")}</SectionTitle>
                <Body>
                  <Trans
                    t={t}
                    i18nKey="contact us body"
                    components={{
                      ccemail: <MuiLink href="mailto:childcare@carina.org" />,
                    }}
                  />
                </Body>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.paleBlue}>
              <Constraint columns={6}>
                <SectionTitle paragraph align="center">
                  {t("faq title")}
                </SectionTitle>
                <AccordionList data={FAQ_COPY} />
              </Constraint>
            </Section>
          </>
        ) : (
          <>
            <Section height="100vh" bgcolor={CivColors.white}>
              <LoadingCircle />
            </Section>
          </>
        )}
      </main>

      <Footer />
    </>
  );
};

export default ChildCare;
