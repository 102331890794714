import { IconButton, IconButtonProps } from "@mui/material";
import { InterfaceBookmarkIcon, InterfaceBookmarkOutlineIcon } from "src/assets/icons/StreamlineIcons";

interface TogglingBookmarkProps extends IconButtonProps {
  isSelected: boolean;
  onClick: () => void;
}

const TogglingBookmark = ({ isSelected, onClick, ...iconButtonProps }: TogglingBookmarkProps) => {
  return (
    <IconButton onClick={onClick} {...iconButtonProps} size="large">
      {isSelected && <InterfaceBookmarkIcon />}
      {!isSelected && <InterfaceBookmarkOutlineIcon />}
    </IconButton>
  );
};

export default TogglingBookmark;
