import { Redirect, Route, Switch } from "react-router-dom";
import { UserType } from "src/context/UserSessionContext";
import AuthenticatedRoute from "src/pages/auth/AuthenticatedRoute";
import ChildCare from "src/pages/childcare/ChildCare";
import { ChildCareContextProvider } from "src/pages/childcare/ChildCareContext";
import COVID19Info from "src/pages/childcare/COVID19Info";
import AccountPortal from "src/pages/childcare/providerAccount/AccountPortal";
import ChildcareProviderProfile, { CCProfileForm } from "src/pages/childcare/providerProfile/ChildcareProviderProfile";
import SearchList from "src/pages/childcare/searchPages/SearchList";
import SearchListDetails from "src/pages/childcare/searchPages/SearchListDetails";

const CHILD_CARE_ROUTE_PREFIX = "/childcare";

export const CHILD_CARE_ROUTES = {
  root: CHILD_CARE_ROUTE_PREFIX,
  landing: CHILD_CARE_ROUTE_PREFIX,
  COVID19Info: `${CHILD_CARE_ROUTE_PREFIX}/covid19info`,
  // Search
  searchList: `${CHILD_CARE_ROUTE_PREFIX}/search`,
  searchListDetails: `${CHILD_CARE_ROUTE_PREFIX}/search/:id`,
  homepage: `${CHILD_CARE_ROUTE_PREFIX}/portal`,
  editProfile: `${CHILD_CARE_ROUTE_PREFIX}/providers/edit-profile`,
};

const ChildCareRouter = () => {
  return (
    <ChildCareContextProvider>
      <Switch>
        <Route exact path={CHILD_CARE_ROUTES.landing}>
          <ChildCare />
        </Route>
        <Route exact path={CHILD_CARE_ROUTES.COVID19Info}>
          <COVID19Info />
        </Route>

        {/* Search List */}
        <Route exact path={CHILD_CARE_ROUTES.searchList}>
          <SearchList />
        </Route>
        <Route exact path={CHILD_CARE_ROUTES.searchListDetails}>
          <SearchListDetails />
        </Route>

        {/* Provider Account Portal */}
        <AuthenticatedRoute path={CHILD_CARE_ROUTES.homepage} userType={UserType.ChildcareProvider}>
          <AccountPortal />
        </AuthenticatedRoute>

        {/* Edit Provider Profile */}
        <AuthenticatedRoute path={CHILD_CARE_ROUTES.editProfile} userType={UserType.ChildcareProvider}>
          <ChildcareProviderProfile formType={CCProfileForm.EDIT} />
        </AuthenticatedRoute>

        <Redirect to={CHILD_CARE_ROUTES.landing} />
      </Switch>
    </ChildCareContextProvider>
  );
};

export default ChildCareRouter;
