import { format } from "date-fns";

export function downloadCsv(dataArray: string, fileName: string): void {
  const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
  const a = document.createElement("a");
  const objUrl = URL.createObjectURL(new Blob([dataArray], { type: "text/csv" }));
  a.href = objUrl;
  a.setAttribute("download", finalFileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(objUrl);
}

/* See DashboardUtilities.test.tsx file for examples
    using these functions
 */

export function applyPhoneFormat(rawPhone: string, fallbackToRaw?: boolean): string {
  const cleaned = rawPhone.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  if (fallbackToRaw) {
    return rawPhone;
  }
  return "";
}

export function removeNonDigits(formattedPhone?: string): string {
  if (formattedPhone) {
    return formattedPhone.replace(/\D/g, "");
  }
  return "";
}

export function applyMMddyyyyFormat(rawDate?: number): string {
  if (rawDate) {
    return format(rawDate, "MM/dd/yyyy");
  }
  return "";
}

export function applyYyyyMMddFormat(rawDate?: number): string {
  if (rawDate) {
    return format(rawDate, "yyyy/MM/dd");
  }
  return "";
}

export function applyYyyyMMddHHmmFormat(rawDate?: number): string {
  if (rawDate) {
    return format(rawDate, "yyyy/MM/dd HH:mm");
  }
  return "";
}

export function hasOnlyDigits(text: string): boolean {
  return /^\d+$/.test(text);
}

export function convertAgeToDate(age: number | string): number {
  const ageAsNumber = typeof age === "string" ? parseInt(age, 10) : age;
  return new Date(new Date().getFullYear() - (ageAsNumber || 0), 0, 1).getTime();
}
