import { Redirect, Route, Switch } from "react-router-dom";
import ChildcareProviderProfile, { CCProfileForm } from "src/pages/childcare/providerProfile/ChildcareProviderProfile";
import { OnboardingContextProvider } from "src/pages/onboarding/components/OnboardingContext";
import OnboardingMain from "src/pages/onboarding/OnboardingMain";
import CommunityGuidelines from "src/pages/onboarding/step_pages/CommunityGuidelines";
import MedicaidProfile from "src/pages/onboarding/step_pages/MedicaidProfile";
import MedicaidSettings from "src/pages/onboarding/step_pages/MedicaidSettings";

const ONBOARDING_PREFIX = "/onboarding";
export const ONBOARDING_ROUTES = {
  root: ONBOARDING_PREFIX,
  medicaidProfile: `${ONBOARDING_PREFIX}/homecare/medicaid/profile`,
  medicaidSettings: `${ONBOARDING_PREFIX}/homecare/medicaid/settings`,
  communityGuidelines: `${ONBOARDING_PREFIX}/homecare/medicaid/guidelines/provider`,
  childcareProfile: `${ONBOARDING_PREFIX}/childcare/profile`,
};

const OnboardingRouter: React.FC = () => {
  return (
    <OnboardingContextProvider>
      <Switch>
        <Route exact path={ONBOARDING_ROUTES.root}>
          <OnboardingMain />
        </Route>
        <Route exact path={ONBOARDING_ROUTES.communityGuidelines}>
          <CommunityGuidelines />
        </Route>
        <Route exact path={ONBOARDING_ROUTES.medicaidProfile}>
          <MedicaidProfile />
        </Route>
        <Route exact path={ONBOARDING_ROUTES.medicaidSettings}>
          <MedicaidSettings />
        </Route>
        <Route exact path={ONBOARDING_ROUTES.childcareProfile}>
          <ChildcareProviderProfile formType={CCProfileForm.CREATE} />
        </Route>
        <Redirect to={ONBOARDING_ROUTES.root} />
      </Switch>
    </OnboardingContextProvider>
  );
};

export default OnboardingRouter;
