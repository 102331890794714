import { format } from "date-fns";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { generatePath, Link } from "react-router-dom";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AgencyAdminInviteDTO } from "src/generated/api_types";
import { useAgencyInviteDatatable } from "src/pages/carinaAdmin/dashboard/agencyInvite/AgencyInviteDashboard";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { ExportMenuOption } from "src/reusable_view_elements/datatable/Datatable";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import { ClientsideColumn } from "src/reusable_view_elements/datatable/ClientsideDatatable";

/*********************************************************
 ************* Start Column Helper Functions *************
 *********************************************************/

export function getCreatedDate(data: AgencyAdminInviteDTO): Date {
  return new Date(data.agencyInviteCreatedAt);
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getStatusText(data: AgencyAdminInviteDTO): any {
  return _.startCase(_.lowerCase(data.agencyInviteStatus));
}

export const AgencyInviteList: React.FC = () => {
  const { ClientsideDatatable, isFetching } = useAgencyInviteDatatable();
  const { isUserType } = useUserSession();

  /*********************************************************
   ******************* Column Definitions ******************
   *********************************************************/

  const COLUMN_DEFINITIONS: { [k: string]: ClientsideColumn<AgencyAdminInviteDTO> } = {
    name: {
      title: "Name",
      field: "agencyAdminName",
      render: (data) => {
        if (data.registeredUserId) {
          return (
            <InternalLink
              to={generatePath(
                isUserType(UserType.CarinaAdmin)
                  ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_details
                  : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_details,
                { id: data.registeredUserId },
              )}
            >
              <Body>{data.agencyAdminName}</Body>
            </InternalLink>
          );
        }

        return (
          <InternalLink
            to={generatePath(
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_invite_details
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_invite_details,
              { id: data.id },
            )}
          >
            <Body>{data.agencyAdminName}</Body>
          </InternalLink>
        );
      },
      customSort: (data1, data2) =>
        data1.agencyAdminName.toLowerCase().localeCompare(data2.agencyAdminName.toLowerCase()),
    },
    agency: {
      title: "Agency",
      field: "agencyName",
    },
    funder: {
      title: "Funder",
      field: "funderName",
    },
    status: {
      title: "Status",
      field: "agencyInviteStatus",
      exportTransformer: (data) => getStatusText(data),
      render: (data) => <Body>{getStatusText(data)}</Body>,
      customFilterAndSearch: (term, data) =>
        data.agencyInviteStatus.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1,
      customSort: (data1, data2) =>
        _.lowerCase(data1.agencyInviteStatus)
          .toLowerCase()
          .localeCompare(_.lowerCase(data2.agencyInviteStatus).toLowerCase()),
    },
    created: {
      title: "Created",
      field: "agencyInviteCreatedAt",
      exportTransformer: (data) => getStatusText(data) + " " + formatDate(getCreatedDate(data)),
      render: (data) => formatDate(getCreatedDate(data)),
      customFilterAndSearch: (term, data) => formatDate(getCreatedDate(data)).indexOf(term) !== -1,
      customSort: (data1, data2) => getCreatedDate(data1).getTime() - getCreatedDate(data2).getTime(),
      defaultSort: "desc",
    },
  };

  /*********************************************************
   ******************* Page Definition *********************
   *********************************************************/

  return (
    <>
      <Helmet>
        <title>Carina | Internal Tools | Agency Admins</title>
      </Helmet>
      <ClientsideDatatable
        persistKeyPrefix="agencyInvites-list"
        columnDefinitions={COLUMN_DEFINITIONS}
        title="All Agency Invites"
        localization={{
          body: {
            emptyDataSourceMessage: isFetching ? "Fetching agency invites..." : "No agency invite records to display",
          },
        }}
        toolbarExtras={
          <LinkStyledAsButton
            variant="contained"
            component={Link}
            to={
              isUserType(UserType.CarinaAdmin)
                ? CARINA_ADMIN_DASHBOARD_ROUTES.agency_invite
                : SUPPORT_ADMIN_DASHBOARD_ROUTES.agency_invite
            }
            data-testid="invite-agency-button"
          >
            Invite Agency Admin
          </LinkStyledAsButton>
        }
        options={{
          exportMenu: [ExportMenuOption.csv({ filename: "Agency Invites" })],
        }}
      />
    </>
  );
};
