import { Box, Hidden, ListItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonNavigation } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { MenuButton } from "src/reusable_view_elements/MenuButton";
import FullSizeMenu from "src/reusable_view_elements/navbars/navbar_elements/FullSizeMenu";
import { BasicNavBar } from "src/reusable_view_elements/navbars/NavBar";
import LanguageButton from "./navbar_elements/LanguageButton";

export type UserNavbarProps = {
  showLanguage?: boolean;
};

const UserNavbar: React.FC<UserNavbarProps> = ({ showLanguage }) => {
  const { userSession, isImpersonated } = useUserSession();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const { t, ready } = useTranslation([nsCommonNavigation]);

  const LogoutButton = () => (
    <LinkStyledAsButton variant="outlined" href={ROUTES.logout} disabled={isImpersonated()}>
      {ready ? t("link.logout") : "Logout"}
    </LinkStyledAsButton>
  );

  const RightSideDesktop = () => (
    <>
      {showLanguage && <LanguageButton menuType="desktop" />}
      {userSession && <LogoutButton />}
    </>
  );

  const RightSideMobile = () => {
    if (userSession) {
      return <MenuButton onClick={() => setMenuOpen(true)}>{ready ? t("link.menu") : "Menu"}</MenuButton>;
    }
    // If not logged in, just show the Language Button
    return showLanguage ? <LanguageButton menuType="mobile" /> : <></>;
  };

  return (
    <BasicNavBar
      right={
        <>
          <Hidden mdDown>
            <RightSideDesktop />
          </Hidden>{" "}
          <Hidden mdUp>
            <RightSideMobile />
            <FullSizeMenu closeMenu={() => setMenuOpen(false)} open={isMenuOpen}>
              <ListItem sx={{ padding: 0, marginTop: "24px" }}>
                <Box width="100%" textAlign="center">
                  <LogoutButton />
                </Box>
              </ListItem>
              {showLanguage && (
                <ListItem sx={{ marginTop: "16px", padding: 0, justifyContent: "center" }}>
                  <LanguageButton menuType="mobile" sx={{ paddingLeft: "16px", paddingRight: "8px" }} />
                </ListItem>
              )}
            </FullSizeMenu>
          </Hidden>
        </>
      }
    />
  );
};

UserNavbar.defaultProps = {
  showLanguage: false,
};
export default UserNavbar;
