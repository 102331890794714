import Constraint, { ConstraintProps } from "src/reusable_view_elements/Constraint";
import Section, { SectionProps } from "src/reusable_view_elements/Section";

/**
 * A convenient component that combines a Section and Constraint into one
 * @param children
 * @param columns
 * @param sectionProps
 * @constructor
 */
const Segment = ({ children, columns, ...sectionProps }: SectionProps & ConstraintProps) => {
  return (
    <Section {...sectionProps}>
      <Constraint columns={columns}>{children}</Constraint>
    </Section>
  );
};

export default Segment;
