import { Box, Grid, Link as MuiLink } from "@mui/material";
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import {
  ReferralMethodEnum,
  RegistrationStepEnum,
  RoleSelectionController_FunderWithRoleConfigDTO,
  RoleType,
} from "src/generated/api_types";
import { nsCommonFormsBtns, nsMedicaidRegRoleSelection, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getKeyForReferralType, getKeyForRoleDisplayName } from "src/i18n/Utilities";
import { ROUTES } from "src/MainRouter";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { RegistrationPageContainer } from "src/pages/registration/components/RegistrationPageContainer";
import {
  getFundersWithRoles as getAllFundersWithRolesType,
  getRegPath,
} from "src/pages/registration/role_selection/Queries";
import useContactInfoForm from "src/pages/registration/role_selection/useContactInfoForm";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import { FormSelectField, FormTextField, SelectOption, TextField } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import Form from "src/reusable_view_elements/form_fields/Form";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField } from "src/utilities/GeneralUtilities";

interface DropdownOption {
  value: string;
  label: string;
}

type ContactInfoPageProps = {
  getAllFundersWithRoles: typeof getAllFundersWithRolesType;
};

const referralRoleWashington: SelectOption<ReferralMethodEnum>[] = [
  {
    label: "SEIU 775 (Union)",
    value: ReferralMethodEnum.SEIU755UNION,
  },
  {
    label: "Case Manager",
    value: ReferralMethodEnum.CASEMANAGER,
  },
  {
    label: "CDWA",
    value: ReferralMethodEnum.CDWA,
  },
  {
    label: "SEIU 775 Benefits Group (vs. SEIU 775 Training Partnership)",
    value: ReferralMethodEnum.SEIU775BENEFITSGROUP,
  },
  {
    label: "Individual Provider",
    value: ReferralMethodEnum.INDIVIDUALPROVIDER,
  },
  {
    label: "Home care client / Consumer",
    value: ReferralMethodEnum.HOMECARECLIENT,
  },
  {
    label: "A friend or family member",
    value: ReferralMethodEnum.AFRIENDORFAMILY,
  },
  {
    label: "Carina Emails",
    value: ReferralMethodEnum.CARINAEMAILS,
  },
  {
    label: "Other",
    value: ReferralMethodEnum.OTHER,
  },
];

const referralRoleOregon: SelectOption<ReferralMethodEnum>[] = [
  {
    label: "Case Manager",
    value: ReferralMethodEnum.CASEMANAGER,
  },
  {
    label: "OHCC",
    value: ReferralMethodEnum.OHCC,
  },
  {
    label: "Home care client / Consumer",
    value: ReferralMethodEnum.HOMECARECLIENT,
  },
  {
    label: "A friend or fam",
    value: ReferralMethodEnum.AFRIENDORFAMILY,
  },
  {
    label: "SEIU Local 503 (Union)",
    value: ReferralMethodEnum.SEIULOCAL503,
  },
  {
    label: "Other",
    value: ReferralMethodEnum.OTHER,
  },
  {
    label: "RISE Partnership / Carewell",
    value: ReferralMethodEnum.RISECAREWELL,
  },
  {
    label: "In-home Care Provider",
    value: ReferralMethodEnum.INHOMEPROVIDER,
  },
];

const ContactInfo: React.FC<ContactInfoPageProps> = ({ getAllFundersWithRoles }) => {
  const { initialValues, handleSubmit, validationSchema } = useContactInfoForm();
  const [funderAndRoleData, setFunderAndRoleData] = useState<RoleSelectionController_FunderWithRoleConfigDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setRegPath, fetchingRole } = useRegistrationContext();
  const [isCaseManagerSelected, setIsCaseManagerSelected] = useState(false);
  const [referralMethod, setReferralMethod] = useState<null | string>(null);
  const { t, ready } = useTranslation([nsMedicaidRegRoleSelection, nsCommonFormsBtns, nsMedicaidTagsEnums]);
  const [helperToggle, setHelperToggle] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const offering = `HC Medicaid`;

    getAllFundersWithRoles(offering)
      .then((res) => {
        if (res.status === 200) {
          setFunderAndRoleData(res.data);
        } else {
          // eslint-disable-next-line no-console
          console.error(`Failed to load funder segment info for ${offering}`);
          // eslint-disable-next-line no-console
          console.error(`Status Code: ${res.status}`);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Failed to load funder segments and roles");
        // eslint-disable-next-line no-console
        console.error(`Error: ${err}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const STATE: { [key: string]: string } = {
    WA: "Washington",
    OR: "Oregon",
  };

  function getStateList(): DropdownOption[] {
    return funderAndRoleData.map((funder) => {
      return { value: funder.segment, label: STATE[funder.state] };
    });
  }

  const caseWorkerRoleTypeIsSelected = (selectedRegPathId: string): boolean => {
    const caseWorkerRegPathIds = funderAndRoleData
      .flatMap((fard) => fard.roleConfigs)
      .filter((rc) => rc.roleType === RoleType.CASE_WORKER)
      .flatMap((rc) => rc.registrationPaths.map((rp) => rp.id));
    return caseWorkerRegPathIds.includes(selectedRegPathId);
  };

  function getRolesByState(funderSegment: string): DropdownOption[] {
    if (funderSegment) {
      const relevantFunder = funderAndRoleData.filter((funder) => funder.segment === funderSegment);
      return relevantFunder[0].roleConfigs.map((roleConfig) => {
        return {
          value: roleConfig.registrationPaths[0].id,
          label: t(getKeyForRoleDisplayName(roleConfig.roleDisplayName), { ns: nsMedicaidTagsEnums }),
        };
      });
    }
    return [];
  }

  const getReferralOptionsByState = (medicaidState: string): DropdownOption[] => {
    return medicaidState === "HC Medicaid-OR"
      ? referralRoleOregon
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((option) => {
            return {
              label: t(getKeyForReferralType(option.value), { ns: nsMedicaidTagsEnums }),
              value: option.value,
            };
          })
      : referralRoleWashington
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((option) => {
            return {
              label: t(getKeyForReferralType(option.value), { ns: nsMedicaidTagsEnums }),
              value: option.value,
            };
          });
  };

  if (!ready) {
    return (
      <RegistrationPageContainer currentStep={RegistrationStepEnum.ROLE_SELECTION}>
        <Helmet>
          <title>Carina | Medicaid Register</title>
        </Helmet>

        <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
          <Constraint columns={6}>
            <LoadingCircle />
          </Constraint>
        </Section>
      </RegistrationPageContainer>
    );
  }

  return (
    <RegistrationPageContainer currentStep={RegistrationStepEnum.ROLE_SELECTION}>
      <Helmet>
        <title>Carina | Medicaid Register</title>
      </Helmet>

      <Section bgcolor={CivColors.lightGray} minimizeTopGutter sx={{ paddingTop: 8 }}>
        <Constraint columns={6}>
          <SectionTitle align="center">{t("lets_get_started.label", { ns: nsMedicaidRegRoleSelection })}</SectionTitle>
          <Body paragraph align="center">
            {t("lets_get_started.description", { ns: nsMedicaidRegRoleSelection })}{" "}
            <MuiLink href="https://www.medicaidplanningassistance.org/in-home-care/" target="_blank">
              {t("read_more_about_medicaid.label", { ns: nsMedicaidRegRoleSelection })}
            </MuiLink>
          </Body>
          <Body paragraph align="center">
            {t("already_have_an_account.label", { ns: nsMedicaidRegRoleSelection })}{" "}
            <MuiLink href={ROUTES.login}>{t("button.login", { ns: nsCommonFormsBtns })}</MuiLink>
          </Body>
        </Constraint>
      </Section>
      {isLoading && !fetchingRole ? (
        <Section bgcolor={CivColors.lightGray}>
          <LoadingCircle />
        </Section>
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, isSubmitting, handleChange, setFieldValue }) => (
            <Section bgcolor={CivColors.lightGray} minimizeTopGutter sx={{ paddingTop: 0 }}>
              <Form localizationReady={ready}>
                <Constraint columns={4}>
                  <Body sx={{ marginBottom: "24px" }}>
                    {t("field.marked_with_asterisk.label", { ns: nsCommonFormsBtns })}
                  </Body>
                  <BodyEmphasis gutterBottom>
                    {t("state_and_role.label", { ns: nsMedicaidRegRoleSelection })}
                  </BodyEmphasis>
                  <Body sx={{ marginBottom: "24px" }}>
                    {t("state_and_role.description", { ns: nsMedicaidRegRoleSelection })}
                  </Body>
                  <FormSelectField
                    selectOptions={getStateList()}
                    name="medicaidState"
                    id="medicaidState"
                    label={t("field.state.label", { ns: nsCommonFormsBtns })}
                    error={Boolean(touched.medicaidState && errors.medicaidState)}
                    helperText={touched.medicaidState && errors.medicaidState}
                    required={isRequiredField("medicaidState", validationSchema)}
                    onChange={async () => {
                      setRegPath(undefined);
                      setReferralMethod(null);
                      setFieldValue("medicaidRegPath", initialValues.medicaidRegPath);
                      setFieldValue("referralMethod", initialValues.referralMethod);
                      setHelperToggle(!helperToggle);
                    }}
                    sx={{ marginBottom: "24px" }}
                  />
                  <FormSelectField
                    selectOptions={getRolesByState(values.medicaidState)}
                    name="medicaidRegPath"
                    label={t("field.im_a.label", { ns: nsCommonFormsBtns })}
                    id="medicaidRegPath"
                    error={Boolean(touched.medicaidRegPath && errors.medicaidRegPath)}
                    helperText={touched.medicaidRegPath && errors.medicaidRegPath}
                    required={isRequiredField("medicaidRegPath", validationSchema)}
                    disabled={!values.medicaidState}
                    controlledRerender={helperToggle}
                    onChange={async (e: any) => {
                      handleChange(e);
                      const regPathRaw = await getRegPath(e.target.value);
                      setRegPath(regPathRaw.data);
                      setIsCaseManagerSelected(caseWorkerRoleTypeIsSelected(e.target.value));
                    }}
                    sx={{ marginBottom: "24px" }}
                  />
                  <Field
                    as={TextField}
                    label={t("field.first_name.label", { ns: nsCommonFormsBtns })}
                    id="firstName"
                    name="firstName"
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    required={isRequiredField("firstName", validationSchema)}
                    sx={{ marginBottom: "24px" }}
                  />
                  <Field
                    as={TextField}
                    label={t("field.last_name.label", { ns: nsCommonFormsBtns })}
                    id="lastName"
                    name="lastName"
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    required={isRequiredField("lastName", validationSchema)}
                    sx={{ marginBottom: "24px" }}
                  />
                  <BodyEmphasis>{t("registration_email.label", { ns: nsMedicaidRegRoleSelection })}</BodyEmphasis>
                  <Body sx={{ marginBottom: "24px" }}>
                    {t("registration_email.description", { ns: nsMedicaidRegRoleSelection })}
                  </Body>
                  <Field
                    as={TextField}
                    label={
                      isCaseManagerSelected
                        ? t("field.email.label.work_email", { ns: nsCommonFormsBtns })
                        : t("field.email.label.email", { ns: nsCommonFormsBtns })
                    }
                    id="email"
                    name="email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    required={isRequiredField("email", validationSchema)}
                    sx={{ marginBottom: "24px" }}
                  />
                  <Field
                    as={TextField}
                    label={
                      isCaseManagerSelected
                        ? t("field.re_enter_email.label.work_email", { ns: nsCommonFormsBtns })
                        : t("field.re_enter_email.label.email", { ns: nsCommonFormsBtns })
                    }
                    id="confirmEmail"
                    name="confirmEmail"
                    error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                    helperText={touched.confirmEmail && errors.confirmEmail}
                    required={isRequiredField("confirmEmail", validationSchema)}
                    sx={{ marginBottom: "24px" }}
                  />
                  <FormSelectField
                    selectOptions={getReferralOptionsByState(values.medicaidState)}
                    name="referralMethod"
                    label={t("field.referral.label", { ns: nsCommonFormsBtns })}
                    id="referralMethod"
                    error={Boolean(touched.referralMethod && errors.referralMethod)}
                    helperText={touched.referralMethod && errors.referralMethod}
                    required={isRequiredField("referralMethod", validationSchema)}
                    disabled={!values.medicaidState}
                    controlledRerender={helperToggle}
                    onChange={async (e: any) => {
                      handleChange(e);
                      setReferralMethod(e.target.value);
                      setFieldValue("referralMethodOther", "");
                    }}
                  />
                  {referralMethod === ReferralMethodEnum.OTHER ? (
                    <Box mt="24px">
                      <FormTextField
                        label={t("field.referral_where", { ns: nsCommonFormsBtns })}
                        id="referralMethodOther"
                        name="referralMethodOther"
                        required
                        helperText={""}
                        disabled={!values.referralMethod}
                      />
                    </Box>
                  ) : null}
                </Constraint>

                <Constraint columns={8} mt="64px">
                  <Grid item xs={12}>
                    <CheckboxField
                      name="terms"
                      style={{ padding: "18px 0px 0px" }}
                      label={
                        <Body>
                          <Trans
                            t={t}
                            i18nKey="field.tos_checkbox.description_required"
                            ns={nsCommonFormsBtns}
                            components={{
                              ctos: <MuiLink href={ROUTES.termsOfService} target="_blank" />,
                              prpo: <MuiLink href={ROUTES.privacyPolicy} target="_blank" />,
                              asterisk: <b />,
                            }}
                            values={{
                              ctos_key: t("field.tos_checkbox.carina_tos.label", { ns: nsCommonFormsBtns }),
                              prpo_key: t("field.tos_checkbox.privacy_policy.label", {
                                ns: nsCommonFormsBtns,
                              }),
                            }}
                          />
                        </Body>
                      }
                    />
                  </Grid>

                  <FormAlert schema={validationSchema} sx={{ mt: 3 }} />

                  <Box mt="24px" textAlign="center">
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      {isSubmitting
                        ? t("button.submitting", { ns: nsCommonFormsBtns })
                        : t("button.next", { ns: nsCommonFormsBtns })}
                    </Button>
                  </Box>
                </Constraint>
              </Form>
            </Section>
          )}
        </Formik>
      )}
    </RegistrationPageContainer>
  );
};

export default ContactInfo;
