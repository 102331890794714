import { Box, Grid, useMediaQuery } from "@mui/material";
import MobileStep1b_OR from "src/assets/tutorial/client/mobile-1-1b-OR.jpg";
import MobileStep1b_WA from "src/assets/tutorial/client/mobile-1-1b-WA.jpg";
import { FunderState, useUserSession } from "src/context/UserSessionContext";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import Illustration from "src/assets/images/emans-home-care-process-2.svg";
import theme from "src/themes/civilization/CivTheme";
import DesktopStep1_WA from "src/assets/tutorial/client/desktop-2-1-WA.jpg";
import DesktopStep1_OR from "src/assets/tutorial/client/desktop-2-1-OR.jpg";
import DesktopStep2 from "src/assets/tutorial/client/desktop-2-2.jpg";
import DesktopStep3a from "src/assets/tutorial/client/desktop-2-3a.jpg";
import DesktopStep3b_WA from "src/assets/tutorial/client/desktop-2-3b-WA.jpg";
import DesktopStep3b_OR from "src/assets/tutorial/client/desktop-2-3b-OR.jpg";
import MobileStep1A from "src/assets/tutorial/client/mobile-2-1a.jpg";
import MobileStep1B_WA from "src/assets/tutorial/client/mobile-2-1b-WA.jpg";
import MobileStep1B_OR from "src/assets/tutorial/client/mobile-2-1b-OR.jpg";
import MobileStep2A from "src/assets/tutorial/client/mobile-2-2a.jpg";
import MobileStep2B from "src/assets/tutorial/client/mobile-2-2b.jpg";
import MobileStep3A from "src/assets/tutorial/client/mobile-2-3a.jpg";
import MobileStep3B from "src/assets/tutorial/client/mobile-2-3b.jpg";

const screenshotFilesMap = new Map<string, string>([
  ["DesktopStep1_WA", DesktopStep1_WA],
  ["DesktopStep1_OR", DesktopStep1_OR],
  ["DesktopStep3b_WA", DesktopStep3b_WA],
  ["DesktopStep3b_OR", DesktopStep3b_OR],
  ["MobileStep1b_WA", MobileStep1B_WA],
  ["MobileStep1b_OR", MobileStep1B_OR],
]);

const Search = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;
  const { isFunderState } = useUserSession();

  const getScreenshot = (filePrefix: string) => {
    let file = `${filePrefix}_WA`;
    if (isFunderState(FunderState.Oregon)) file = `${filePrefix}_OR`;
    return screenshotFilesMap.get(file);
  };

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Search on Carina</SectionTitle>
            <Body>
              In addition to posting a job, you can also directly <b>search for and message providers</b> near you.
            </Body>
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={Illustration} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Go to &#34;Find Providers&#34;</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>Click &#34;Find Providers&#34; at the top of the page.</Body>
            </Constraint>
            <img src={getScreenshot("DesktopStep1")} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>Click &#34;Menu&#34; at the top of the page, then click &#34;Find Providers&#34;.</Body>
            </Constraint>
            <img src={MobileStep1A} width="100%" alt="" />
            <Box p={1} />
            <img src={getScreenshot("MobileStep1b")} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Refine your search</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Use the sort feature on the top and use the filters on the left to find a provider that meets your
                needs.
              </Body>
            </Constraint>
            <img src={DesktopStep2} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Click on &#34;Filter&#34; and/or &#34;Sort&#34; to see a list of what you can filter and sort by to best
                find a provider that meets your needs.
              </Body>
            </Constraint>
            <img src={MobileStep2A} width="100%" alt="" />
            <Constraint columns={6} pt={4}>
              <Body paragraph>Here is an example of what you&apos;ll see when you click &#34;Filter&#34;.</Body>
            </Constraint>
            <img src={MobileStep2B} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Learn more about a provider</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>
            On this page, you&apos;ll see a list of providers that match your filters. To learn more about each
            provider, click &#34;View Profile.&#34;
          </Body>
        </Constraint>
        <img src={onDesktop ? DesktopStep3a : MobileStep3A} width="100%" alt="" />
        <Constraint columns={6} pt={6} pb={4}>
          <Body>
            Clicking &#34;View Profile&#34; allows you to view a specific provider&apos;s skills, experience, interests
            and other information.
          </Body>
        </Constraint>
        <img src={onDesktop ? getScreenshot("DesktopStep3b") : MobileStep3B} width="100%" alt="" />
      </Segment>
    </>
  );
};

export default Search;
