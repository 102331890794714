import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { Permission, ProviderListDTO, ProviderProfileTagEnum_Category } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidAccountPortal,
  nsMedicaidProviderProfile,
  nsMedicaidSearch,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import FirstMessageDialog, { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import {
  bookmarkProfile,
  getBookmarkedProfiles,
  unBookmarkProfile,
} from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import Footer from "src/reusable_view_elements/Footer";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MessageFollowUpButton from "src/reusable_view_elements/MessageFollowUpButton";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import ProviderCard from "src/reusable_view_elements/provider_card/ProviderCard";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const SavedProviders = () => {
  const { showSnackbar } = useNotification();
  const { sendMsgDialogProps, setSendMsgDialogProps, setVisitedProviderIndex } = useMedicaidContext();
  const { program } = useSupportAdminContext();
  const { userSession, hasPermission, isUserType } = useUserSession();
  const [bookMarkedProfilesList, setBookMarkedProfilesList] = useState<ProviderListDTO[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string>();
  const { t, ready } = useTranslation([
    nsMedicaidSearch,
    nsMedicaidProviderProfile,
    nsCommonFormsBtns,
    nsCommonToasts,
    nsMedicaidAccountPortal,
  ]);

  useEffect(() => {
    if (sendMsgDialogProps) {
      setMsgModalOpen(true);
    }
  }, [sendMsgDialogProps]);

  /************************************************* *********************************************************
   The next two functions update the bookMarkedProfilesList object without having to
   make a call to the BE. This prevents rerendering the list, which would break the focus sequence of
   any open 'first message success snackbar' or move the user back to the top when using infinite scrolling
   ************************************************* *********************************************************/
  const updateProviderProfileAfterFirstMessage = (threadId?: string) => {
    if (!threadId) return;
    const updatedProviderProfileList = bookMarkedProfilesList?.map((profile) => {
      return profile.entity.provider.id === selectedProviderId
        ? { ...profile, entity: { ...profile.entity, threadIds: [threadId] } }
        : profile;
    });
    setBookMarkedProfilesList(updatedProviderProfileList);
  };
  /************************************************* *********************************************************/

  useEffect(() => {
    (async () => {
      if ((isUserType(UserType.UserSupportManager) && program !== "none") || !isUserType(UserType.UserSupportManager)) {
        const programId = isUserType(UserType.UserSupportManager) ? program : "";
        try {
          setLoading(true);
          const res = await getBookmarkedProfiles(programId);
          setBookMarkedProfilesList(res.data);
        } catch (e) {
          showSnackbar(t("saved_providers.error.issue_retrieving_bookmarked", { ns: nsCommonToasts }), "error");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [program]);

  const handleBookmarkClick = async (profileId: string, isBookmarked?: boolean) => {
    if (isBookmarked === undefined) {
      return;
    }
    try {
      if (isBookmarked) {
        await unBookmarkProfile(profileId);
      } else {
        await bookmarkProfile(profileId);
      }
      const updatedProfileList = bookMarkedProfilesList?.map((profile) => {
        return profile.entity.id === profileId
          ? { ...profile, entity: { ...profile.entity, bookmarked: !isBookmarked } }
          : profile;
      });
      setBookMarkedProfilesList(updatedProfileList);
    } catch (e) {
      showSnackbar(t("error.error.processing_bookmark", { ns: nsCommonToasts }), "error");
    }
  };

  return (
    <>
      <Helmet>
        <title>Carina | Saved Provider Profiles</title>
      </Helmet>

      <MedicaidNavbar />

      <main id="main-content">
        {!ready ? (
          <Section bgcolor={CivColors.lightGray}>
            <Constraint columns={12}>
              <LoadingCircle />
            </Constraint>
          </Section>
        ) : (
          <>
            <Section bgcolor={CivColors.lightGray} minimal>
              <Constraint columns={12}>
                <DashReturnLink to={MEDICAID_ACCOUNT_ROUTES.homepage}>
                  {t("button.back", { ns: nsCommonFormsBtns })}
                </DashReturnLink>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray}>
              <Constraint columns={8} textAlign="center">
                <SectionTitle>{t("saved_providers.label", { ns: nsMedicaidProviderProfile })}</SectionTitle>
                <Body paragraph>{t("saved_providers.description", { ns: nsMedicaidProviderProfile })}</Body>
                {!isLoading && (
                  <Body>
                    {t("saved_providers.description.provider_count", {
                      ns: nsMedicaidAccountPortal,
                      count: bookMarkedProfilesList.length,
                    })}
                  </Body>
                )}
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray} pt={0}>
              <Constraint columns={8}>
                {isLoading && <LoadingCircle />}

                {!isLoading && bookMarkedProfilesList.length > 0 && (
                  <>
                    <Grid item xs="auto">
                      <Grid container spacing={2}>
                        {bookMarkedProfilesList.map((profile, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ display: "flex" }}
                              key={profile.entity.id}
                              id={index.toString()}
                              onClick={() => setVisitedProviderIndex(index)}
                            >
                              <ProviderCard
                                profile={profile}
                                hideBookmark={userSession?.id === profile.entity.provider.id}
                                onBookmark={() => handleBookmarkClick(profile.entity.id, profile.entity.bookmarked)}
                                buttonSection={
                                  <Grid container spacing={2} direction="column" alignItems="center">
                                    <Grid item>
                                      <LinkStyledAsButton
                                        variant="contained"
                                        component={Link}
                                        to={generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, {
                                          id: profile.entity.provider.id,
                                        })}
                                      >
                                        {t("button.view_profile", { ns: nsCommonFormsBtns })}
                                      </LinkStyledAsButton>
                                    </Grid>
                                    {hasPermission(Permission.MESSAGING_ACCESS) &&
                                      userSession &&
                                      profile.entity.provider.id !== userSession.id &&
                                      profile.entity.threadIds && (
                                        <Grid item>
                                          <MessageFollowUpButton
                                            variant="outlined"
                                            onMessageClick={() => {
                                              setSelectedProviderId(profile.entity.provider.id);
                                              setSendMsgDialogProps({
                                                recipientId: profile.entity.provider.id,
                                                recipientFirstName: profile.entity.provider.firstName,
                                                recipientLastName: profile.entity.provider.lastName,
                                                recipientRole: profile.entity.provider.role as UserType,
                                                context: FirstMessageContext.PROVIDER_PROFILE,
                                                pronouns: profile.entity.tags
                                                  .filter(
                                                    (tagObj) =>
                                                      tagObj.category === ProviderProfileTagEnum_Category.PRONOUN,
                                                  )
                                                  .sort((a, b) => a.order - b.order)
                                                  .map((tagObj) =>
                                                    t(tagObj.tag.toLowerCase(), { ns: nsMedicaidTagsEnums }),
                                                  ),
                                              });
                                            }}
                                            threadIds={profile.entity.threadIds}
                                            otherUserId={profile.entity.provider.id}
                                          />
                                        </Grid>
                                      )}
                                  </Grid>
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </>
                )}
                {!isLoading && bookMarkedProfilesList.length === 0 && (
                  <Box bgcolor={CivColors.lightGray} pb={24}>
                    <Constraint columns={8} textAlign="center">
                      <LinkStyledAsButton
                        variant="contained"
                        component={Link}
                        to={MEDICAID_ACCOUNT_ROUTES.browseProviders}
                      >
                        {t("button.search_for_providers", { ns: nsCommonFormsBtns })}
                      </LinkStyledAsButton>
                    </Constraint>
                  </Box>
                )}
              </Constraint>
            </Section>
          </>
        )}

        {sendMsgDialogProps && (
          <FirstMessageDialog
            {...sendMsgDialogProps}
            isDialogOpen={isMsgModalOpen}
            onCloseDialog={(threadId) => {
              updateProviderProfileAfterFirstMessage(threadId);
              setMsgModalOpen(false);
              setSendMsgDialogProps(undefined);
            }}
          />
        )}
      </main>

      <Footer />
    </>
  );
};

export default SavedProviders;
