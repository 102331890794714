import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Field, FieldProps } from "formik";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { nsCommonForms } from "src/i18n/Namespaces";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Chip from "src/reusable_view_elements/Chip";
import TextField, { TextFieldProps } from "src/reusable_view_elements/form_fields/TextField";
import MenuItem from "src/reusable_view_elements/MenuItem";

export interface AutocompleteOption<T> {
  inputValue?: string;
  label: string;
  value: T;
}

type MultiAutocompleteAndChipsFieldProps<T> = {
  name: string;
  selectOptions: AutocompleteOption<T>[];
  closeOnSelect?: boolean;
  disableDefaultSort?: boolean;
  maxSelections?: number;
  noOptionsText?: string;
  reachedMaxText?: string;
} & Omit<TextFieldProps, "name">;

const Autocomplete = styled(MuiAutocomplete)({
  // Styling for the clear and popup icons area
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiAutocomplete-clearIndicator": {
      width: 24,
      height: 24,
      "& .MuiSvgIcon-root": {
        width: 24,
        height: 24,
        opacity: 0.54,
      },
      padding: 0,
      margin: 0,
    },
    "& .MuiAutocomplete-popupIndicator": {
      width: 24,
      height: 24,
      "& .MuiSvgIcon-root": {
        width: 24,
        height: 24,
        opacity: 0.54,
      },
      padding: 0,
      margin: 0,
    },
  },
  "& .MuiFormControl-root .MuiOutlinedInput-root": {
    paddingRight: 56, //Override 39px paddingRight set in Mui for presence of either clear icon or popup icon
    ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon&": {
      paddingRight: 56, //Override 65px paddingRight set in Mui for presence of both clear icon and popup icon
    },
    "& .MuiAutocomplete-endAdornment": {
      lineHeight: 1,
      right: 8, // Overriding default 9px right
    },
  },

  // Styling for alignment and spacing of chips and input text
  "& .MuiInputBase-root": {
    gap: "8px",
    "&.Mui-focused": {
      "& .MuiAutocomplete-inputFocused": {
        padding: "8px 12px", // Align text of the input with text of the chips
      },
    },
  },
});

export const MultiAutocompleteAndChipsField = <T extends string>(props: MultiAutocompleteAndChipsFieldProps<T>) => {
  const { selectOptions, closeOnSelect = false, helperText = "", maxSelections = 3 } = props;
  const [sortedOptions, setSortedOptions] = useState<AutocompleteOption<T>[]>(
    props.disableDefaultSort ? selectOptions : selectOptions.sort((a, b) => a.label.localeCompare(b.label)),
  );
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption<T>[]>();
  const { t } = useTranslation([nsCommonForms]);
  const getLabel = (option: AutocompleteOption<T>): string =>
    selectOptions.find((o) => o.value === option.value)?.label || "";

  const __reachedMaxText =
    props.reachedMaxText ||
    t("autocomplete.default_reached_max_text", {
      defaultValue: "Remove an existing selection to edit",
      ns: nsCommonForms,
    });
  const __noOptionsText =
    props.noOptionsText || t("autocomplete.default_no_options_text", { defaultValue: "No options", ns: nsCommonForms });

  useEffect(() => {
    setSortedOptions(selectOptions);
  }, [i18next.language]);

  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps<T[]>) => {
        return (
          <Autocomplete
            multiple
            freeSolo={false}
            data-testid={`button-${props.name}`}
            readOnly={props.InputProps?.readOnly} // Remove ability to interact with component's dropdown
            /*************
             * Styling
             *************/
            disabled={props.disabled}
            renderTags={(value, getTagProps) =>
              value.map((tagOption, index) => {
                const { key, disabled, onDelete } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    disabled={disabled}
                    readonly={props.InputProps?.readOnly}
                    label={getLabel(tagOption as AutocompleteOption<T>)}
                    onDelete={(e) => {
                      onDelete(e);
                      form.setFieldTouched(props.name);
                    }}
                  />
                );
              })
            }
            popupIcon={<ArrowDropDownRoundedIcon />}
            clearIcon={<CloseRoundedIcon />}
            /*****************
             * Value and input
             *****************/
            value={selectedOptions || selectOptions.filter((option) => field.value.includes(option.value))}
            renderInput={(params) => {
              const id = props.name.replace(/\.([a-z])/, (match, capture) => capture.toUpperCase());
              return (
                <TextField
                  id={id}
                  label={props.label}
                  InputProps={{ ...params.InputProps, ...props.InputProps }} // Apply TextField's readonly style
                  inputProps={{ ...params.inputProps, id: `${id}` }}
                  InputLabelProps={{ ...params.InputLabelProps, id: `label-${props.name}` }}
                  disabled={params.disabled}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error : helperText}
                  onBlur={() => form.setFieldTouched(props.name)}
                />
              );
            }}
            onChange={(_event, value) => {
              form.setFieldValue(
                field.name,
                (value as AutocompleteOption<T>[]).map((v) => v.value),
              );
              if (
                maxSelections === undefined ||
                (maxSelections && (value as AutocompleteOption<T>[]).length < maxSelections)
              ) {
                setSortedOptions(selectOptions);
              } else {
                setSortedOptions([]);
              }
              setSelectedOptions(value as AutocompleteOption<T>[]);
            }}
            /***********************
             * Dropdown List Options
             ***********************/
            disableCloseOnSelect={!closeOnSelect}
            options={!!maxSelections && field.value.length >= maxSelections ? [] : sortedOptions}
            isOptionEqualToValue={(option, value) =>
              (option as AutocompleteOption<T>).value === (value as AutocompleteOption<T>).value
            }
            renderOption={(optionProps, option, state) => {
              const { className, ...otherOptionProps } = optionProps;
              return (
                <MenuItem variant="checkbox" {...otherOptionProps} selected={state.selected}>
                  {getLabel(option as AutocompleteOption<T>)}
                </MenuItem>
              );
            }}
            /*************************************************************
             * Text when there are no options or reached maximum selection
             *************************************************************/
            noOptionsText={!!maxSelections && field.value.length >= maxSelections ? __reachedMaxText : __noOptionsText}
          />
        );
      }}
    </Field>
  );
};
