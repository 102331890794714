import { Stepper as MStepper, StepperProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledStepper = styled(MStepper, {
  shouldForwardProp: (prop) => prop !== "paddingBottom",
})<StepperProps>(({ style }) => ({
  backgroundColor: "inherit",
  paddingBottom: style?.paddingBottom || 24,
  "& .MuiStepLabel-label": {
    fontFamily: "Europa-Regular, sans-serif",
    fontSize: "1.0rem",
    fontVariantLigatures: "no-contextual",
  },
  "& .Mui-active": {
    fontFamily: "Europa-Bold, sans-serif",
  },
}));

export default StyledStepper;
