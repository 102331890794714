import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { ConsumerApplication_DTO_Response_Listing } from "src/generated/api_types";
import { useConsumersDatatable } from "src/pages/agencyAdmin/dashboard/consumers/ConsumerDashboard";
import { updateAssignedAdminOnly } from "src/pages/agencyAdmin/dashboard/consumers/Queries";
import { Button } from "src/reusable_view_elements/Button";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";

type AdminAssignmentDialogProps = {
  consumerApp?: ConsumerApplication_DTO_Response_Listing;
  newAdmin?: SelectedAdminAssignment;
  onSubmit: () => void;
};

export interface SelectedAdminAssignment {
  id: string;
  name: string;
}

export const AdminAssignmentDialog: React.FC<AdminAssignmentDialogProps> = ({
  consumerApp,
  newAdmin,
  onSubmit = () => {},
}) => {
  const { addOrUpdateData } = useConsumersDatatable();
  const { showSnackbar } = useNotification();
  const { userSession } = useUserSession();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (consumerApp?.id && newAdmin?.id === userSession?.id) {
      handleAssignedToChange();
    } else {
      setModalOpen(!!consumerApp);
    }
  }, [consumerApp, newAdmin]);

  async function handleAssignedToChange() {
    if (!newAdmin) return;

    try {
      if (!consumerApp) {
        throw Error("Consumer app is undefined");
      }
      if (newAdmin.id === "UNASSIGNED") {
        const response = await updateAssignedAdminOnly(consumerApp.id);

        const newId = response.data.id;
        const newEntry = response.data;
        await addOrUpdateData(newId, () => {
          return {
            ...newEntry,
            updatedAt: newEntry.updatedAt,
          };
        });
      } else {
        const response = await updateAssignedAdminOnly(consumerApp?.id, newAdmin.id);

        const newId = response.data.id;
        const newEntry = response.data;
        await addOrUpdateData(newId, () => {
          return {
            ...newEntry,
            updatedAt: newEntry.updatedAt,
          };
        });
      }
    } catch (e) {
      showSnackbar("Error saving assigned admin. Please try again or refresh browser.", "error");
    } finally {
      onSubmit();
    }
  }

  if (!newAdmin) {
    return <></>;
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick") {
          setModalOpen(false);
        }
      }}
      maxWidth="xs"
      PaperProps={{
        style: { padding: "8px 0px 16px 0px" },
      }}
    >
      <DialogTitle>
        <BodyTitle>
          {newAdmin.id === "UNASSIGNED"
            ? `Are you sure you want to unassign ${consumerApp?.assignedAgencyAdmin?.firstName} from 
          ${consumerApp?.contact.firstName}?`
            : `Are you sure you want to assign 
          ${consumerApp?.careRecipient.firstName ? consumerApp.careRecipient.firstName : consumerApp?.contact.firstName}
           to ${newAdmin.name}?`}
        </BodyTitle>
      </DialogTitle>
      <DialogContent>
        <Body>
          {newAdmin.name === "Unassigned"
            ? ""
            : `${newAdmin.name} will be responsible for reaching out to 
            ${consumerApp?.careRecipient.firstName ? `${consumerApp.careRecipient.firstName}'s point of contact` : ""}
            ${consumerApp?.contact.firstName}${
              consumerApp?.contact.lastName ? ` ${consumerApp.contact.lastName}.` : "."
            }`}
        </Body>

        <Grid style={{ textAlign: "right" }}>
          <Button
            data-testid="assign-admin-button"
            variant="text"
            type="submit"
            disabled={false}
            onClick={handleAssignedToChange}
          >
            Yes
          </Button>
          <Button variant="contained" type="button" onClick={() => onSubmit()}>
            No
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
