import { Box, SvgIconProps, Link as MuiLink } from "@mui/material";
import { useTranslation } from "react-i18next";
import { nsCommonFormsBtns, nsMedicaidOnboardingMain } from "src/i18n/Namespaces";
import { CheckCircle1Icon } from "src/assets/icons/StreamlineIcons";
import { IconAndBodyEmphasis } from "src/reusable_view_elements/IconAndBodyEmphasis";
import { Button } from "src/reusable_view_elements/Button";
import { Body, PanelTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

interface CardProps {
  name: string;
  title: string;
  icon: React.ComponentType<SvgIconProps>;
  subtitle: string;
  onClick: () => void;
  isComplete: boolean;
}

export const StepCard: React.FC<CardProps> = ({ name, title, icon: Component, subtitle, onClick, isComplete }) => {
  const { t } = useTranslation([nsCommonFormsBtns, nsMedicaidOnboardingMain]);

  const style = {
    icon: {
      width: 36,
      height: 36,
      marginRight: 10,
    },
    title: {
      margin: "8px 0px auto",
      minHeight: 100,
    },
    button: {
      margin: "0px auto 20px",
      display: "flex",
    },
  };
  return (
    <Box bgcolor={CivColors.white} padding={3} height="100%" textAlign="center" id={`${name.toLowerCase()}-card`}>
      <Component style={style.icon} />
      <PanelTitle style={style.title}>{title}</PanelTitle>
      {isComplete ? (
        <IconAndBodyEmphasis
          icon={CheckCircle1Icon}
          title={t("complete.label", { ns: nsMedicaidOnboardingMain })}
          iconColor={CivColors.coreOrange}
        />
      ) : (
        <Button variant="contained" type="button" onClick={onClick} style={style.button}>
          {t("button.start", { ns: nsCommonFormsBtns })}
        </Button>
      )}
      {isComplete ? (
        <Body>
          <MuiLink component="button" onClick={onClick}>
            {t("button.review_again", { ns: nsCommonFormsBtns })}
          </MuiLink>
        </Body>
      ) : (
        <Body>{subtitle}</Body>
      )}
    </Box>
  );
};
