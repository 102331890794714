import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Redirect, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { ProviderProfileTagEnum } from "src/generated/api_types";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { ChildcareControllerClient } from "src/pages/childcare/ControllerClients";
import Footer from "src/reusable_view_elements/Footer";

export const UpdateCCAvailability = () => {
  const { id: providerId, status: newAvailability } = useParams<{
    id: string;
    status: string;
  }>();
  const { showSnackbar } = useNotification();
  const [isRenewing, setIsRenewing] = useState(true);

  useEffect(() => {
    ChildcareControllerClient.updateAvailabilityPost(providerId, newAvailability as ProviderProfileTagEnum)
      .then(() => {
        // TODO: Localize the missed strings on this page: https://github.com/CarinaWeb/CarinaCore/issues/992
        showSnackbar(`Availability successfully updated!`);
      })
      .finally(() => {
        setIsRenewing(false);
      });
  });

  if (isRenewing) {
    return (
      <>
        <Helmet>
          <title>Carina | Childcare Availability Update</title>
        </Helmet>
        <main id="main-content">
          <div>Updating availability...</div>
        </main>
        <Footer englishOnly />
      </>
    );
  }

  return <Redirect to={CHILD_CARE_ROUTES.editProfile} />;
};
