import { Box, Grid, useMediaQuery } from "@mui/material";
import Constraint from "src/reusable_view_elements/Constraint";
import Segment from "src/reusable_view_elements/Segment";
import { Body, SectionTitle, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import HomeCareProcessExploreSVG from "src/assets/images/emans-homecare-process-explore.svg";
import theme from "src/themes/civilization/CivTheme";
import DesktopSearchStep1 from "src/assets/tutorial/provider/desktop-1-1.jpg";
import DesktopSearchStep2 from "src/assets/tutorial/provider/desktop-1-2.jpg";
import DesktopSearchStep3a from "src/assets/tutorial/provider/desktop-1-3a.jpg";
import DesktopSearchStep3b from "src/assets/tutorial/provider/desktop-1-3b.jpg";
import MobileSearchStep1A from "src/assets/tutorial/provider/mobile-1-1a.jpg";
import MobileSearchStep1B from "src/assets/tutorial/provider/mobile-1-1b.jpg";
import MobileSearchStep2A from "src/assets/tutorial/provider/mobile-1-2a.jpg";
import MobileSearchStep2B from "src/assets/tutorial/provider/mobile-1-2b.jpg";
import MobileSearchStep3a from "src/assets/tutorial/provider/mobile-1-3a.jpg";
import MobileSearchStep3b from "src/assets/tutorial/provider/mobile-1-3b.jpg";

const Search = () => {
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const onMobile = !onDesktop;

  return (
    <>
      <Segment bgcolor={CivColors.lightGray} columns={8} textAlign={onDesktop ? "left" : "center"}>
        <Grid container spacing={onDesktop ? 8 : 0} alignItems="center">
          {onDesktop && (
            <Grid item xs={12} sm={5}>
              <img src={HomeCareProcessExploreSVG} width="100%" alt="" />
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SectionTitle>Search on Carina</SectionTitle>
            <Body>
              Ready to search for jobs? <b>Find job postings</b> on Carina that match your experience and skills.
            </Body>
          </Grid>
          {onMobile && (
            <Grid item xs={12} sm={5}>
              <img src={HomeCareProcessExploreSVG} width="100%" alt="" />
            </Grid>
          )}
        </Grid>
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>1. Go to &#34;Find Jobs&#34;</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Once you complete the four steps to set up your account, you will be able to find home care jobs.
              </Body>
              <Body paragraph>
                To start, click &#34;Find Jobs&#34; at the top of the page or on &#34;Search for Jobs&#34; in the
                &#34;My Messages&#34; section.
              </Body>
            </Constraint>
            <img src={DesktopSearchStep1} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Once you complete the four steps to set up your account, you will be able to find home care jobs.
              </Body>
              <Body paragraph>
                To start, click &#34;Menu&#34; at the top of the page, then click &#34;Find Jobs&#34;.
              </Body>
            </Constraint>
            <img src={MobileSearchStep1A} width="100%" alt="" />
            <Box p={1} />
            <img src={MobileSearchStep1B} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>2. Refine your search</BodyTitle>
        {onDesktop && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Use the sort feature on the top and use the filters on the left to best find a consumer to work with.
              </Body>
            </Constraint>
            <img src={DesktopSearchStep2} width="100%" alt="" />
          </>
        )}
        {onMobile && (
          <>
            <Constraint columns={6}>
              <Body paragraph>
                Click on &#34;Filter&#34; and/or &#34;Sort&#34; to see a list of what you can filter and sort by to best
                find a consumer to work with.
              </Body>
            </Constraint>
            <img src={MobileSearchStep2A} width="100%" alt="" />
            <Constraint columns={6} pt={4}>
              <Body paragraph>Here is an example of what you’ll see when you click &#34;Filter&#34;.</Body>
            </Constraint>
            <img src={MobileSearchStep2B} width="100%" alt="" />
          </>
        )}
      </Segment>

      <Segment bgcolor={CivColors.white} textAlign="center" columns={8}>
        <BodyTitle paragraph>3. Learn more about a job</BodyTitle>
        <Constraint columns={6}>
          <Body paragraph>
            On this page, you&apos;ll see job posts that match your filters. To learn more about each job or consumer,
            click &#34;View Job.&#34;
          </Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep3a : MobileSearchStep3a} width="100%" alt="" />
        <Constraint columns={6} pt={6} pb={4}>
          <Body>
            Clicking &#34;View Job&#34; allows you to view a specific consumer&apos;s information and care needs.
          </Body>
        </Constraint>
        <img src={onDesktop ? DesktopSearchStep3b : MobileSearchStep3b} width="100%" alt="" />
      </Segment>
    </>
  );
};

export default Search;
