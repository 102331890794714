import { PropsWithChildren, useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import {
  RegistrationController_RegistrationStepDTO,
  RegistrationController_RegPathDTO,
  RegistrationStepEnum,
} from "src/generated/api_types";
import { getRegPathFromToken } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { REGISTRATION_ROUTES } from "src/pages/registration/components/RegistrationRouter";
import { createContainer } from "unstated-next";

interface UserInfo {
  firstName: string;
  lastName: string;
  funder: string;
  email: string;
}

interface UserInfoObject {
  [key: string]: any;
}

interface InitialConfig {
  userInfo: UserInfo;
}

function registrationContext(_initialState?: InitialConfig) {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: "",
    lastName: "",
    funder: "",
    email: "",
  });

  const [regPath, setRegPath] = useState<RegistrationController_RegPathDTO | undefined>(undefined);
  const [groupInviteToken, setGroupInviteToken] = useState<string>();
  const token = sessionStorage.getItem("group-invite-token");
  const [fetchingRole, isFetchingRole] = useState(!!token);
  const [isInvitedUser, setIsInvitedUser] = useState(false);

  useEffect(() => {
    (async () => {
      if (token) {
        setGroupInviteToken(token);
        try {
          const res = await getRegPathFromToken(token);
          setRegPath(res.data);
        } catch (e) {
          //eslint-disable-next-line
          console.error(`Something went wrong while fetching role config from token: ${e}`);
        } finally {
          isFetchingRole(false);
        }
      }
    })();
  }, []);

  function sendUserInfoToContext(userInfoObject: UserInfoObject) {
    setUserInfo({
      /* eslint-disable dot-notation */
      ...userInfo,
      firstName: userInfoObject["firstName"],
      lastName: userInfoObject["lastName"],
      email: userInfoObject["email"],
      funder: userInfoObject["medicaidState"],
      /* eslint-disable dot-notation */
    });
  }

  function getNextStep(currentStep: RegistrationStepEnum): RegistrationController_RegistrationStepDTO {
    const currentStepIdx = regPath!!.registrationSteps.findIndex((r) => r.step === currentStep);
    return regPath!!.registrationSteps[currentStepIdx + 1];
  }

  function getNextStepRoute(currentStep: RegistrationStepEnum, params: { [key: string]: string } = {}): string {
    const nextStep = getNextStep(currentStep);
    switch (nextStep.step) {
      case RegistrationStepEnum.VERIFICATION:
        return generatePath(REGISTRATION_ROUTES.verification, params);
      case RegistrationStepEnum.PASSWORD_CREATION:
        return generatePath(REGISTRATION_ROUTES.password, params);
      default:
        throw new Error(`Unsupported registration step ${nextStep.step}`);
    }
  }

  return {
    groupInviteToken,
    fetchingRole,
    sendUserInfoToContext,
    userInfo,
    regPath,
    setRegPath,
    getNextStepRoute,
    isInvitedUser,
    setIsInvitedUser,
  };
}

const RegistrationContext = createContainer(registrationContext);

interface RegistrationContextProviderProps {
  initialState?: InitialConfig;
}

export const RegistrationContextProvider: React.FC<PropsWithChildren<RegistrationContextProviderProps>> = ({
  initialState,
  children,
}) => <RegistrationContext.Provider initialState={initialState}>{children}</RegistrationContext.Provider>;

export const useRegistrationContext = () => {
  const { ...context } = RegistrationContext.useContainer();

  return {
    ...context,
  };
};
