import { Box, Grid, useMediaQuery } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { ProviderListDTO, ProviderProfileTagEnum } from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidAccountPortal,
  nsMedicaidProviderProfile,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedProviderProfileTag } from "src/i18n/TagHelpers";
import {
  CalendarIcon,
  TimeClockCircleAlternateIcon,
  PinLocation1Icon,
  SingleManActionsStarIcon,
} from "src/assets/icons/StreamlineIcons";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import { getBookmarkedProfiles } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardDivider from "src/reusable_view_elements/PortalCardDivider";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { getDistanceInMiles } from "src/utilities/GeneralUtilities";

/**
 * - For Consumers: Show both number of saved providers and list up to 3 on mobile, 6 on desktop
 * - For Providers: Show only number of saved providers
 */
const SavedProvidersPortalCard = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { showSnackbar } = useNotification();
  const { program } = useSupportAdminContext();
  const { isUserType } = useUserSession();
  const [bookMarkedProfilesList, setBookMarkedProfilesList] = useState<ProviderListDTO[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t, ready: translationReady } = useTranslation([nsMedicaidAccountPortal, nsCommonFormsBtns, nsCommonToasts]);

  useEffect(() => {
    (async () => {
      if ((isUserType(UserType.UserSupportManager) && program !== "none") || !isUserType(UserType.UserSupportManager)) {
        const programId = isUserType(UserType.UserSupportManager) ? program : "";
        try {
          setLoading(true);
          const res = await getBookmarkedProfiles(programId);
          setBookMarkedProfilesList(res.data);
        } catch (e) {
          showSnackbar(
            t(
              "error.retrieving_bookmarked_profiles",
              "Sorry, there was an issue retrieving your bookmarked profiles. Please try again later.",
              {
                ns: nsCommonToasts,
              },
            ),
            "error",
          );
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [program]);

  const slicedProfiles = bookMarkedProfilesList.slice(0, desktopSize ? 6 : 3);

  if (!translationReady) {
    return <LoadingPortalCardSkeleton />;
  }

  return (
    <PortalCard>
      <PortalCardTitle icon={SingleManActionsStarIcon} viewMorePath={MEDICAID_ACCOUNT_ROUTES.savedProviders}>
        <BodyTitle>{t("saved_providers.label", { ns: nsMedicaidAccountPortal })}</BodyTitle>
        {!isLoading && bookMarkedProfilesList.length >= 1 && (
          <Body>
            {t("saved_providers.description.provider_count", {
              ns: nsMedicaidAccountPortal,
              count: bookMarkedProfilesList.length,
            })}
          </Body>
        )}
      </PortalCardTitle>

      {!(isUserType(UserType.Provider) && bookMarkedProfilesList.length > 0) && <PortalCardDivider />}

      {isLoading && <LoadingCircle />}

      {!isLoading &&
        bookMarkedProfilesList.length > 0 &&
        (isUserType(UserType.Consumer) || isUserType(UserType.UserSupportManager)) &&
        slicedProfiles.map((profile, index) => (
          <ProviderCard key={profile.entity.id} showTopBorder={index !== 0} data={profile} />
        ))}

      {!isLoading && bookMarkedProfilesList.length === 0 && (
        <Box textAlign={desktopSize ? "left" : "center"}>
          <Body paragraph>
            {t("saved_providers.description.provider_count", { ns: nsMedicaidAccountPortal, count: 0 })}
          </Body>
          <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
            {t("button.search_for_providers", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Box>
      )}
    </PortalCard>
  );
};

export default SavedProvidersPortalCard;

interface ProviderCardProps {
  showTopBorder: boolean;
  data: ProviderListDTO;
}

const ProviderCard = ({ showTopBorder, data: profile }: ProviderCardProps) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready: translationReady } = useTranslation([nsMedicaidProviderProfile, nsMedicaidTagsEnums]);

  const topBorderStyle: string = `1px solid ${CivColors.mediumGray}`;

  if (!translationReady) {
    return (
      <Box key={profile.entity.id} py="16px">
        <Skeleton variant="rectangular" height={20} width="40%" style={{ marginBottom: 16 }} />
        <Skeleton variant="rectangular" height={80} />
      </Box>
    );
  }

  const location: string = `${profile.entity.geoDataCityState.city}, ${profile.entity.geoDataCityState.state}`;
  const milesAway: string = t("count_miles_away.label", {
    ns: nsMedicaidProviderProfile,
    count: getDistanceInMiles(profile.distance),
  });
  const locationAndMiles: string = `${location} (${milesAway})`;
  const localizedAvailability = getLocalizedProviderProfileTag(profile.entity.availability);

  return (
    <Box key={profile.entity.id} borderTop={showTopBorder ? topBorderStyle : undefined} py="16px">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          direction={desktopSize ? "row" : "column"}
          alignItems={desktopSize ? "center" : "flex-start"}
          spacing={2}
        >
          <Grid item>
            <InternalLink
              to={generatePath(MEDICAID_ACCOUNT_ROUTES.providerDetails, { id: profile.entity.provider.id })}
            >
              <BodyEmphasis>
                {profile.entity.provider.firstName} {profile.entity.provider.lastName.charAt(0)}.
              </BodyEmphasis>
            </InternalLink>
          </Grid>
          <Grid item>
            <Box
              px="3px"
              py="2px"
              bgcolor={
                profile.entity.availability === ProviderProfileTagEnum.SEEKING
                  ? CivColors.coreGold
                  : CivColors.lightGray
              }
              borderRadius="4px"
              display="inline-block"
            >
              <BodyEmphasis
                style={{
                  color:
                    profile.entity.availability === ProviderProfileTagEnum.NOTSEEKING
                      ? CivColors.mediumGray
                      : CivColors.coreDarkNavy,
                }}
              >
                {t(localizedAvailability.key, { ns: localizedAvailability.namespace })}
              </BodyEmphasis>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} sm={6} container>
            <Grid item>
              <PinLocation1Icon style={{ width: "24px", height: "24px", marginRight: "6px" }} />
            </Grid>
            <Grid item xs>
              <Body>{locationAndMiles}</Body>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container>
            <Grid item>
              <TimeClockCircleAlternateIcon style={{ width: "24px", height: "24px", marginRight: "6px" }} />
            </Grid>
            <Grid item xs>
              <Body>
                {t("count_hours_per_month.label", { ns: nsMedicaidProviderProfile, count: profile.entity.hours })}
              </Body>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container>
            <Grid item>
              <CalendarIcon style={{ width: "24px", height: "24px", marginRight: "6px" }} />
            </Grid>
            <Grid item xs>
              <Body style={{ overflowWrap: "anywhere" }}>{profile.entity.schedulingNotes}</Body>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
