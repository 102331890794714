import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidAccountPortal } from "src/i18n/Namespaces";
import { TaskChecklistCheckIcon } from "src/assets/icons/StreamlineIcons";
import { getMyJobPosts } from "src/pages/medicaidAccount/consumerAccount/Queries";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";

/**
 * - Consumers, Proxy Providers, Case Managers, Referral Coordinators see this portal card
 * - All of those user types see the same thing
 * - If they have active jobs, show number
 * - If no active jobs, show button to job post
 */
const ActiveJobPostsPortalCard = () => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const [numActiveJobPosts, setNumActiveJobPosts] = useState<number>(0);
  const [loadingActiveJobPosts, setLoadingActiveJobPosts] = useState<boolean>(false);
  const { showSnackbar } = useNotification();
  const { isUserType } = useUserSession();
  const { t, ready: translationReady } = useTranslation([nsMedicaidAccountPortal, nsCommonFormsBtns, nsCommonToasts]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingActiveJobPosts(true);
        //TODO: [issue#1112] This component only needs a count, but the api call brings way more info than needed,
        //  resulting in unnecessary overhead. Let's consider creating a separate endpoint for this.
        const res = await getMyJobPosts();
        setNumActiveJobPosts(res.data.length);
      } catch (e) {
        showSnackbar(
          t("error.loading_your_active_jobs", "There was a problem loading your active job posts", {
            ns: nsCommonToasts,
          }),
          "error",
        );
      } finally {
        setLoadingActiveJobPosts(false);
      }
    })();
  }, []);

  if (!translationReady) {
    return <LoadingPortalCardSkeleton />;
  }

  return (
    <PortalCard>
      <PortalCardTitle
        icon={TaskChecklistCheckIcon}
        hideViewMore={numActiveJobPosts === 0}
        viewMorePath={getMedicaidRouteByUserType("myJobList", isUserType)}
      >
        <BodyTitle>{t("active_job_posts.label", { ns: nsMedicaidAccountPortal })}</BodyTitle>
        {!loadingActiveJobPosts && numActiveJobPosts > 0 && (
          <Body>
            {t("active_job_posts.description.job_count", { ns: nsMedicaidAccountPortal, count: numActiveJobPosts })}
          </Body>
        )}
      </PortalCardTitle>

      {loadingActiveJobPosts && <LoadingCircle />}

      {!loadingActiveJobPosts && numActiveJobPosts === 0 && (
        <Box textAlign={desktopSize ? "left" : "center"}>
          <Body paragraph>
            {t("active_job_posts.description.job_count", { ns: nsMedicaidAccountPortal, count: 0 })}
          </Body>
          <LinkStyledAsButton
            variant="contained"
            component={Link}
            to={getMedicaidRouteByUserType("jobCreate", isUserType)}
          >
            {t("button.post_a_job", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Box>
      )}
    </PortalCard>
  );
};

export default ActiveJobPostsPortalCard;
