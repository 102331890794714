import { PropsWithChildren, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AgencyAdmin_DTO_Response_Session,
  AxiosUserControllerClient,
  Nullable,
  Permission,
  User_AccountStatus,
  User_DTO_Response_Session,
  UserController_SessionDTO,
} from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import { createContainer } from "unstated-next";

interface InitialConfig {
  userSession?: UserController_SessionDTO;
}

interface TestUserSession {
  __skip_user_session_request?: boolean;
}

function userSessionContext(
  initialState: InitialConfig & TestUserSession = {
    userSession: undefined,
  },
) {
  const { __skip_user_session_request } = initialState;
  const [userSession, setUserSession] = useState(initialState.userSession);
  const [shouldReloadAuthState, setShouldReloadAuthState] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout>();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  function delayLogoutTimer(): void {
    if (window.updateLogoutTimer) {
      window.updateLogoutTimer();
    }
  }

  function isUserType(type: UserType): boolean {
    return userSession?.user?.role === type;
  }

  function isFunderState(state: FunderState): boolean {
    switch (state) {
      case FunderState.Washington:
        return userSession?.user.funder.state === "WA";
      case FunderState.Oregon:
        return userSession?.user.funder.state === "OR";
      default:
        return false;
    }
  }

  function impersonatorIsUserType(type: UserType): boolean {
    if (userSession?.impersonatingUser) {
      return userSession?.impersonatingUser.role === type;
    }
    return false;
  }

  function hasAccountStatus(status: User_AccountStatus): boolean {
    return userSession?.user.accountStatus === status;
  }

  function hasPermission(permission: Permission): boolean {
    if (!userSession) return false;
    return userSession.user.permissions.indexOf(permission) >= 0;
  }

  function isImpersonated(): boolean {
    if (!userSession) return false;
    return !!userSession?.impersonatingUser;
  }

  function reloadAuthState(shouldRedirect = true) {
    clearTimeout(logoutTimer!);
    setIsLogin(shouldRedirect);
    setShouldReloadAuthState(!shouldReloadAuthState);
  }

  function hasMedicaidAccess() {
    switch (userSession?.user.role) {
      case UserType.AgencyAdmin:
        return false;
      case UserType.CarinaAdmin:
        return true;
      case UserType.FunderAdmin:
        return false;
      case UserType.LegacyUser:
        return false;
      case UserType.Consumer:
        return true;
      case UserType.Provider:
        return true;
      case UserType.ProxyProvider:
        return true;
      case UserType.MedicaidReferralCoordinator:
        return true;
      case UserType.MedicaidCaseManager:
        return true;
      case UserType.UserSupportManager:
        return true;
      case UserType.UserSupportAgent:
        return true;
      case UserType.CaseWorker:
        return true;
      default:
        return false;
    }
  }

  function isAssistiveRoleView(): boolean {
    switch (userSession?.user.role) {
      case UserType.MedicaidReferralCoordinator:
        return true;
      default:
        return false;
    }
  }

  return {
    userSession,
    setUserSession,
    shouldReloadAuthState,
    loading,
    setLoading,
    isLogin,
    setIsLogin,
    isUserType,
    isFunderState,
    hasAccountStatus,
    hasPermission,
    isImpersonated,
    impersonatorIsUserType,
    reloadAuthState,
    logoutTimer,
    setLogoutTimer,
    showLogoutModal,
    setShowLogoutModal,
    __skip_user_session_request,
    hasMedicaidAccess,
    delayLogoutTimer,
    isAssistiveRoleView,
  };
}

declare global {
  interface Window {
    updateLogoutTimer?: () => void;
  }
}

const UserSessionContext = createContainer(userSessionContext);
const userClient = new AxiosUserControllerClient();

interface UserSessionProviderProps {
  initialState?: InitialConfig & TestUserSession;
}

const UserSessionContextLoader: React.FC = ({ children }) => {
  const {
    __skip_user_session_request,
    isLogin,
    setIsLogin,
    setLoading,
    userSession,
    setUserSession,
    shouldReloadAuthState,
    logoutTimer,
    setLogoutTimer,
    setShowLogoutModal,
  } = UserSessionContext.useContainer();
  const history = useHistory();
  const location = useLocation();

  // Attach logoutEvent listener to detect logout events from other tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "logoutEvent") {
        // Call your logout function when the 'logoutEvent' is detected
        setUserSession(undefined);
        history.push(ROUTES.login);
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  function updateLogoutTimer() {
    if (!__skip_user_session_request && userSession) {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      setLogoutTimer(
        setTimeout(
          () => {
            setShowLogoutModal(true);
          },
          25 * 60 * 1000,
        ),
      );
    }
  }

  // Register globally accessible logout timer update so Axios instances can reach into context
  // Must re-register on state change because the function seems to keep state
  useEffect(() => {
    window.updateLogoutTimer = updateLogoutTimer;
  }, [__skip_user_session_request, userSession, logoutTimer]);

  useEffect(() => {
    if (!__skip_user_session_request) {
      setLoading(true);
      userClient
        .whoami({
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            setUserSession(res.data);
            if (isLogin) {
              history.push(ROUTES.loginSuccess);
            }
          } else {
            setUserSession(undefined);
            if (location.pathname.indexOf("logout") >= 0) {
              history.push(ROUTES.login);
            }
          }
          // how does this impact impersonation
        })
        .catch(() => {
          setUserSession(undefined);
          if (location.pathname.indexOf("logout") >= 0) {
            history.push(ROUTES.login);
          }
        })
        .finally(() => {
          setLoading(false);
          setIsLogin(false);
          setShowLogoutModal(false);
        });
    } else {
      setLoading(false);
      setIsLogin(false);
    }
  }, [shouldReloadAuthState]);

  return <>{children}</>;
};

export const UserSessionContextProvider: React.FC<PropsWithChildren<UserSessionProviderProps>> = ({
  initialState,
  children,
}) => {
  return (
    <UserSessionContext.Provider initialState={initialState}>
      <UserSessionContextLoader>{children}</UserSessionContextLoader>
    </UserSessionContext.Provider>
  );
};

export const useUserSession = () => {
  const {
    __skip_user_session_request,
    userSession,
    setUserSession,
    setLoading,
    shouldReloadAuthState,
    isLogin,
    setIsLogin,
    logoutTimer,
    setLogoutTimer,
    setShowLogoutModal,
    ...container
  } = UserSessionContext.useContainer();

  return {
    ...container,
    userSession: userSession?.user,
    impersonatingUser: userSession?.impersonatingUser,
  };
};

export function asAgencyAdminResponseDTO(dto: User_DTO_Response_Session): Nullable<AgencyAdmin_DTO_Response_Session> {
  const agencyAdmin = dto as AgencyAdmin_DTO_Response_Session;
  if (!agencyAdmin.agency || !agencyAdmin.agency.id) {
    // eslint-disable-next-line no-console
    console.error(`Expected field "agency.id" in User_DTO_Response_Session`, dto);
    return undefined;
  }
  return agencyAdmin;
}

export enum UserType {
  AgencyAdmin = "AgencyAdmin",
  CarinaAdmin = "CarinaAdmin",
  FunderAdmin = "FunderAdmin",
  LegacyUser = "LegacyUser",
  Consumer = "Consumer",
  Provider = "Provider",
  ProxyProvider = "ProxyProvider",
  MedicaidReferralCoordinator = "MedicaidReferralCoordinator",
  UserSupportManager = "UserSupportManager",
  UserSupportAgent = "UserSupportAgent",
  MedicaidCaseManager = "MedicaidCaseManager",
  CaseWorker = "CaseWorker",
  ChildcareProvider = "ChildcareProvider",
}

export enum FunderState {
  Washington = "Washington",
  Oregon = "Oregon",
}
