import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import qs from "qs";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { UserController_PasswordResetTokenDataDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonLogin, nsCommonToasts } from "src/i18n/Namespaces";
import { AuthPageContainer } from "src/pages/auth/AuthPageContainer";
import { postPasswordReset } from "src/pages/auth/PostPasswordReset";
import { getTokenData } from "src/pages/auth/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Form from "src/reusable_view_elements/form_fields/Form";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { useOnce } from "src/utilities/useOnce";
import { object, ref, string } from "yup";
import { FormPasswordField } from "src/reusable_view_elements/form_fields/FormPasswordField";
import { ROUTES } from "src/MainRouter";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import Constraint from "src/reusable_view_elements/Constraint";

interface PasswordResetProps {
  onSubmit: typeof postPasswordReset;
  getToken: typeof getTokenData;
}

interface PasswordResetFields {
  password: string;
  confirmPassword: string;
}

export const PasswordReset: React.FC<PasswordResetProps> = ({ onSubmit, getToken }) => {
  const { reloadAuthState } = useUserSession();
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const location = useLocation();
  const qParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [tokenData, setTokenData] = useState<UserController_PasswordResetTokenDataDTO>();
  const [tokenError, setTokenError] = useState<"invalid" | "expired" | undefined>();
  const { t, ready } = useTranslation([nsCommonLogin, nsCommonFormsBtns, nsCommonToasts]);

  if (!qParams.token) {
    return <Redirect to={ROUTES.login} />;
  }

  const validationSchema = object({
    password: string()
      .label(t("field.password.label", { ns: nsCommonFormsBtns }))
      .required(t("field.password.error_required", { ns: nsCommonFormsBtns }))
      .min(8, t("field.password.error_min", { ns: nsCommonFormsBtns })),
    confirmPassword: string()
      .label(t("field.reenter_password.label", { ns: nsCommonFormsBtns }))
      .oneOf([ref("password"), undefined], t("field.reenter_password.error_must_match", { ns: nsCommonFormsBtns }))
      .required(t("field.reenter_password.error_required", { ns: nsCommonFormsBtns })),
  });

  useOnce(() => {
    getToken(qParams.token as string)
      .then((res) => {
        if (res.status === 204) setTokenError("expired");
        else setTokenData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 404) setTokenError("invalid");
        else showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
      });
  });

  if (tokenError) {
    return (
      <AuthPageContainer>
        <Helmet>
          <title>Carina | Password Reset</title>
        </Helmet>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            minHeight: "70vh",
          }}
        >
          <Grid
            item
            sm={8}
            style={{
              textAlign: "center",
            }}
          >
            {ready && (
              <Constraint columns={9}>
                <SectionTitle>
                  {tokenError === "expired" && t("reset_password.expired_token.label", { ns: nsCommonLogin })}
                  {tokenError === "invalid" && t("reset_password.invalid_token.label", { ns: nsCommonLogin })}
                </SectionTitle>
                <Body>
                  {tokenError === "expired" &&
                    t("reset_password.expired_token.try_resetting_again", { ns: nsCommonLogin })}
                  {tokenError === "invalid" &&
                    t("reset_password.invalid_token.try_clicking_link_again", { ns: nsCommonLogin })}
                </Body>
                {tokenError === "invalid" && (
                  <>
                    <Box p={1} />
                    <Body>{t("reset_password.invalid_token.if_you_have_already", { ns: nsCommonLogin })}</Body>
                    <Box p={2} />
                    <LinkStyledAsButton variant="contained" component={Link} to={ROUTES.login}>
                      {t("button.login", { ns: nsCommonFormsBtns })}
                    </LinkStyledAsButton>
                  </>
                )}
                {tokenError === "expired" && (
                  <>
                    <Box p={2} />
                    <LinkStyledAsButton variant="contained" component={Link} to={ROUTES.forgotPassword}>
                      {t("button.reset_password", { ns: nsCommonFormsBtns })}
                    </LinkStyledAsButton>
                  </>
                )}
              </Constraint>
            )}
          </Grid>
        </Grid>
      </AuthPageContainer>
    );
  }

  if (!tokenData) {
    return (
      <AuthPageContainer>
        <Helmet>
          <title>Carina | Password Reset</title>
        </Helmet>
        <Box textAlign="center" margin="0px auto">
          <LoadingCircle />
        </Box>
      </AuthPageContainer>
    );
  }

  const initialValues: PasswordResetFields = {
    password: "",
    confirmPassword: "",
  };

  function handleSubmit(values: PasswordResetFields) {
    if (!tokenData) {
      throw Error("Token data is undefined");
    }
    onSubmit({
      token: tokenData.token,
      password: values.password,
    })
      .then((res) => {
        if (res.status === 204) {
          history.push(ROUTES.root);
        } else {
          reloadAuthState();
          showSnackbar(
            t("success.password_reset", "Your password has been reset successfully", { ns: nsCommonToasts }),
            "success",
            true,
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 404) history.push(ROUTES.root);
        else
          showSnackbar(
            t("error.password_reset", "Error submitting password reset request. Please try again later.", {
              ns: nsCommonToasts,
            }),
            "error",
          );
      });
  }

  return (
    <AuthPageContainer>
      <Helmet>
        <title>Carina | Password Reset</title>
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "70vh",
        }}
      >
        <Grid
          item
          sm={6}
          style={{
            textAlign: "center",
          }}
        >
          <Section>
            <Constraint columns={6}>
              {ready && (
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                  {({ isSubmitting }) => {
                    return (
                      <Form data-testid="login-form" localizationReady={ready}>
                        <SectionTitle>{t("create_new_password.label", { ns: nsCommonLogin })}</SectionTitle>
                        <Box maxWidth={400} textAlign="left" margin="0px auto">
                          <FormPasswordField
                            name="password"
                            label={t("field.password.label", { ns: nsCommonFormsBtns })}
                          />
                          <Box p={1} />
                          <FormPasswordField
                            name="confirmPassword"
                            label={t("field.reenter_password.label", { ns: nsCommonFormsBtns })}
                          />
                          <FormAlert schema={validationSchema} sx={{ mt: 2 }} />
                        </Box>
                        <Button variant="contained" type="submit" disabled={isSubmitting} sx={{ mt: 2 }}>
                          {isSubmitting
                            ? t("button.resetting", { ns: nsCommonFormsBtns })
                            : t("button.reset_password", { ns: nsCommonFormsBtns })}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Constraint>
          </Section>
        </Grid>
      </Grid>
    </AuthPageContainer>
  );
};
