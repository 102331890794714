import { useMediaQuery } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory, useParams } from "react-router-dom";
import { DeleteJobResponseMessage } from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import { AUTH_PAGE_CONTAINER_BANNER_KEY } from "src/pages/auth/AuthPageContainer";
import { publicMedicaidClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import Section from "src/reusable_view_elements/Section";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

export const DeleteJobFromNotification = () => {
  const { jobid: postId, userid: posterId } = useParams<{ jobid: string; userid: string }>();
  const history = useHistory();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);

  useEffect(() => {
    publicMedicaidClient
      .deleteJobPostFromNotification({ jobid: postId, userid: posterId })
      .then(() => history.push(ROUTES.deleteJobFromNotifConfirmation))
      .catch((e: AxiosError) => {
        switch (e.response?.data) {
          case DeleteJobResponseMessage.JOB_NOT_FOUND:
          case DeleteJobResponseMessage.JOB_ALREADY_DELETED:
          case DeleteJobResponseMessage.EMAIL_MISMATCH:
            window.sessionStorage.setItem(AUTH_PAGE_CONTAINER_BANNER_KEY, e.response?.data);
            break;
          default:
            window.sessionStorage.setItem(
              AUTH_PAGE_CONTAINER_BANNER_KEY,
              DeleteJobResponseMessage.GENERIC_JOB_DELETE_ERROR,
            );
        }
        history.push(ROUTES.login);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Carina | Medicaid Delete Job From Notification</title>
      </Helmet>

      <NavBar />
      <main id="main-content">
        <Section
          bgcolor={CivColors.lightGray}
          style={desktopSize ? { paddingTop: "200px", paddingBottom: "200px" } : {}}
        >
          <Constraint columns={6} textAlign="center">
            <Body paragraph>Processing request...</Body>
          </Constraint>
        </Section>
      </main>
      <Footer englishOnly />
    </>
  );
};
