import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { generatePath } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  AxiosPPHCAgencyDashboardConsumersControllerClient,
  ConsumerApplication_DTO_Response_Listing,
  ConsumerApplicationGender,
  ConsumerApplicationStatus,
  ContactMethod,
  HighlightReason,
  User_DTO_Response_NameAndIdOnly,
} from "src/generated/api_types";
import {
  AdminAssignmentDialog,
  SelectedAdminAssignment,
} from "src/pages/agencyAdmin/dashboard/consumers/AdminAssignmentDialog";
import {
  formatPhoneNumber,
  getAgencyNotes,
  getConsumerNotes,
  getCreatedDate,
  getDobDate,
  getUpdatedDate,
} from "src/pages/agencyAdmin/dashboard/consumers/ConsumersList";
import { getCareRecipientOrContactLastName } from "src/pages/agencyAdmin/dashboard/consumers/GetConsumers";
import { pageConsumerApps, updateConsumerStatusOnly } from "src/pages/agencyAdmin/dashboard/consumers/Queries";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { renderStatusString } from "src/pages/offerings/pphc/datatable_columns/consumer_applications/StatusColumn";
import { SelectOption } from "src/reusable_view_elements/form_fields";
import Select from "src/reusable_view_elements/form_fields/Select";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body } from "src/reusable_view_elements/Typography";
import { ServersideColumn, ServersideDatatable } from "src/reusable_view_elements/datatable/ServersideDatatable";
import CivColors from "src/themes/civilization/CivColors";

const PREFIX = "ConsumersListPaging";

const classes = {
  cellHighlight: `${PREFIX}-cellHighlight`,
  cellNotHighlight: `${PREFIX}-cellNotHighlight`,
};

const Root = styled("div")(() => ({
  [`& .${classes.cellHighlight}`]: {
    "& .MuiInput-input": {
      color: CivColors.deepRose,
      fontWeight: 700,
    },
  },

  [`& .${classes.cellNotHighlight}`]: {
    "& .MuiInput-input": {
      color: CivColors.coreDarkNavy,
    },
  },
}));

function getContactMethod(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.contact.preferredMethod) {
    switch (data.contact.preferredMethod) {
      case ContactMethod.EMAIL:
        return data.contact.email || "";
      case ContactMethod.PHONE:
        return formatPhoneNumber(data.contact.phone) || "";
      case ContactMethod.SMS:
        return formatPhoneNumber(data.contact.phone) || "";
      default:
        return "";
    }
  } else {
    return formatPhoneNumber(data.contact.phone) || data.contact.email || "";
  }
}

function getPetsResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (
    data.homeEnvironment.DOGS === true ||
    data.homeEnvironment.CATS === true ||
    data.homeEnvironment.BIRDS === true ||
    data.homeEnvironment.OTHER_PETS === true
  ) {
    return "Yes";
  }

  if (
    data.homeEnvironment.DOGS === false &&
    data.homeEnvironment.CATS === false &&
    data.homeEnvironment.BIRDS === false &&
    data.homeEnvironment.OTHER_PETS === false
  ) {
    return "No";
  }

  return "";
}

function getSmokingResponse(data: ConsumerApplication_DTO_Response_Listing): string {
  if (data.homeEnvironment.SMOKING_HOME === true) {
    return "Yes";
  }

  if (data.homeEnvironment.SMOKING_HOME === false) {
    return "No";
  }

  return "";
}

function formatDate(date: Date): string {
  return format(date, "MM/dd/yyyy");
}

function getGender(data: ConsumerApplication_DTO_Response_Listing): string {
  switch (data.careRecipient.gender) {
    case ConsumerApplicationGender.NOT_DISCLOSED:
      return "Not Disclosed";
    case ConsumerApplicationGender.DIFFERENT_IDENTITY:
      return "Different Identity";
    case ConsumerApplicationGender.FEMALE:
      return "Female";
    case ConsumerApplicationGender.MALE:
      return "Male";
    default:
      return "Not Disclosed";
  }
}

function renderAssignedAdmin(data?: User_DTO_Response_NameAndIdOnly): string {
  if (data) {
    return `${data.firstName} ${data.lastName}`;
  }

  return "Select";
}

function getZipCode(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.zipCode : "";
}

function getCity(data: ConsumerApplication_DTO_Response_Listing): string {
  return data.careRecipient.geoData ? data.careRecipient.geoData.city : "";
}

function getLanguages(data: ConsumerApplication_DTO_Response_Listing): string {
  const languagesArray = data.languages
    .map((language) => _.startCase(_.lowerCase(`${language.toString().replace(/_/g, " ")}`)))
    .sort();
  return languagesArray.toString().replace(/,/g, ", ");
}

/**
 * Agency Admin Assignment Dropdown Filter
 * Story: https://www.pivotaltracker.com/story/show/178506636
 */
const client = new AxiosPPHCAgencyDashboardConsumersControllerClient();
const useAssignmentFilter = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [options, setOptions] = useState([
    { label: "All", value: "ALL" },
    { label: "Unassigned", value: "UNASSIGNED" },
  ]);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setLoading(true);
        const res = await client.listAgencyAdmins();
        const adminOptions = res.data.map((admin) => ({
          label: `${admin.firstName} ${admin.lastName}`,
          value: admin.id,
        }));
        const combinedOptions = [...options, ...adminOptions];
        setOptions(combinedOptions);
      } catch (e) {
        setError("Server Error: Unable to retrieve agency admins");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    options,
    isLoading,
    error,
  };
};

export const ConsumersListPaging: React.FC = () => {
  const { options } = useAssignmentFilter();
  const { showSnackbar } = useNotification();
  const [currentEditingConsumerApp, setCurrentEditingConsumerApp] = useState<
    ConsumerApplication_DTO_Response_Listing | undefined
  >();
  const [selectedAdmin, setSelectedAdmin] = useState<SelectedAdminAssignment | undefined>();

  function isStatusHighlighted(highlightReasons?: HighlightReason[]): boolean {
    if (highlightReasons && highlightReasons.length > 0) {
      if (highlightReasons.includes(HighlightReason.NEW) || highlightReasons.includes(HighlightReason.STALLED)) {
        return true;
      }
    }
    return false;
  }

  function isAssignedToHighlighted(highlightReasons?: HighlightReason[]): boolean {
    if (highlightReasons && highlightReasons.length > 0) {
      if (highlightReasons.includes(HighlightReason.UNASSIGNED)) {
        return true;
      }
    }
    return false;
  }

  // function getHighlightReasonArray(data: ConsumerApplication_DTO_Response_Listing): HighlightReason[] {
  //   const highlightReasonArray = [];
  //   if (!data.assignedAgencyAdmin) {
  //     highlightReasonArray.push(HighlightReason.UNASSIGNED);
  //   }
  //   if (data.status === ConsumerApplicationStatus.NEW_LEAD) {
  //     highlightReasonArray.push(HighlightReason.NEW);
  //   }
  //   return highlightReasonArray;
  // }

  async function handleInlineStatusChange(
    updatedStatus: ConsumerApplicationStatus,
    data: ConsumerApplication_DTO_Response_Listing,
  ) {
    try {
      await updateConsumerStatusOnly(data.id, { status: updatedStatus });
    } catch (e) {
      showSnackbar("Error saving status update. Please try again or refresh browser.", "error");
    }
  }

  const getAssignedToOptions = (data: ConsumerApplication_DTO_Response_Listing): SelectOption<string>[] => {
    // start array with currently assigned admin
    const optionsArr = [
      {
        label: renderAssignedAdmin(data.assignedAgencyAdmin),
        value: renderAssignedAdmin(data.assignedAgencyAdmin),
        disabled: true,
      },
    ];

    const availableOptions = options.filter((option) =>
      data.assignedAgencyAdmin
        ? option.value !== "ALL" && option.value !== data.assignedAgencyAdmin?.id
        : option.value !== "ALL" && option.value !== "UNASSIGNED",
    );

    availableOptions.map((option) => {
      optionsArr.push({
        label: option.label,
        value: option.value,
        disabled: false,
      });
    });

    return optionsArr;
  };

  const getStatusOptions = (data: ConsumerApplication_DTO_Response_Listing): SelectOption<string>[] => {
    // start array with current status
    const optionsArr = [
      {
        label: renderStatusString(data.status),
        value: renderStatusString(data.status),
        disabled: true,
      },
    ];

    const availableStatuses = Object.values(ConsumerApplicationStatus).filter((status) => status !== data.status);

    availableStatuses.map((option) => {
      optionsArr.push({
        label: renderStatusString(option),
        value: option,
        disabled: false,
      });
    });

    return optionsArr;
  };

  interface ConsumerColumn extends ServersideColumn<ConsumerApplication_DTO_Response_Listing> {}

  const COLUMN_DEFINITIONS: { [k: string]: ConsumerColumn } = {
    readable_id: {
      title: "ID",
      sortingField: "readableId",
      filteringField: "readableId",
      field: "readableId",
      hidden: true,
    },
    first_name: {
      title: "First Name",
      sortingField: "contact.firstName",
      filteringField: ["contact.firstName", "careRecipient.firstName"],
      render: (data) => {
        return (
          <Root>
            <Body>
              <InternalLink
                to={
                  data.id! &&
                  generatePath(DASHBOARD_ROUTES.consumer_details, {
                    id: data.id,
                  })
                }
              >
                {data.careRecipient.firstName ? data.careRecipient.firstName : data.contact.firstName}
              </InternalLink>
            </Body>
            {!data.careRecipient.firstName && <Body>(representative)</Body>}
          </Root>
        );
      },
    },
    last_name: {
      title: "Last Name",
      sortingField: "contact.lastName",
      filteringField: ["contact.lastName", "careRecipient.lastName"],
      render: (data) => {
        return <Body>{getCareRecipientOrContactLastName(data)}</Body>;
      },
      hidden: true,
    },
    contact_method: {
      title: "Contact Method",
      filteringField: ["contact.email", "contact.phone"],
      render: getContactMethod,
    },
    email: {
      title: "Email",
      sortingField: "contact.email",
      filteringField: "contact.email",
      field: "contact.email",
      hidden: true,
    },
    phone: {
      title: "Phone",
      sortingField: "contact.phone",
      filteringField: "contact.phone",
      render: (data) => (formatPhoneNumber(data.contact.phone) ? formatPhoneNumber(data.contact.phone) : ""),
      hidden: true,
    },
    address: {
      title: "Address",
      sortingField: "careRecipient.address",
      filteringField: "careRecipient.address",
      field: "careRecipient.address",
      hidden: true,
    },
    county: {
      title: "County",
      sortingField: "careRecipient.geoData.county",
      filteringField: "careRecipient.geoData.county",
      field: "careRecipient.geoData.county",
      hidden: true,
    },
    city: {
      title: "City",
      sortingField: "careRecipient.geoData.city",
      filteringField: "careRecipient.geoData.city",
      render: getCity,
      hidden: true,
    },
    zip_code: {
      title: "ZIP code",
      sortingField: "careRecipient.geoData.zipCode",
      filteringField: "careRecipient.geoData.zipCode",
      render: getZipCode,
    },
    assignedTo: {
      title: "Assigned to",
      sortingField: "assignedAgencyAdmin.firstName",
      filteringField: ["assignedAgencyAdmin.firstName", "assignedAgencyAdmin.lastName"],
      render: (data) => {
        return (
          <Select
            variant="standard"
            value={renderAssignedAdmin(data.assignedAgencyAdmin)}
            className={
              isAssignedToHighlighted(data.highlightReasons!) ? classes.cellHighlight : classes.cellNotHighlight
            }
            selectOptions={getAssignedToOptions(data)}
            data-testid="assigned-to-select"
            onChange={(props) => {
              setCurrentEditingConsumerApp(data);
              setSelectedAdmin({
                id: props.target.value as string,
                name: options.filter((option) => option.value === props.target.value)[0].label,
              });
            }}
          />
        );
      },
    },
    status: {
      title: "Status",
      sortingField: "status",
      filteringField: "status",
      render: (data) => {
        return (
          <Select
            className={isStatusHighlighted(data.highlightReasons) ? classes.cellHighlight : classes.cellNotHighlight}
            variant="standard"
            value={renderStatusString(data.status)}
            onChange={(props) => {
              handleInlineStatusChange(props.target.value as ConsumerApplicationStatus, data);
            }}
            selectOptions={getStatusOptions(data)}
          />
        );
      },
    },
    hours: {
      title: "Req'd Hrs/Wk",
      sortingField: "hours",
      field: "hours",
      hidden: true,
    },
    consumerNotes: {
      title: "Care Needs Notes",
      render: getConsumerNotes,
      hiddenByColumnsButton: false,
    },
    agencyNotes: {
      title: "Agency Notes",
      render: getAgencyNotes,
      hiddenByColumnsButton: false,
    },
    pets: {
      title: "Pets",
      render: getPetsResponse,
      hidden: true,
    },
    smoking: {
      title: "Smoking",
      render: getSmokingResponse,
      hidden: true,
    },
    languages: {
      title: "Languages",
      render: getLanguages,
      hidden: true,
    },
    gender: {
      title: "Gender",
      sortingField: "gender",
      render: getGender,
      hidden: true,
    },
    dob: {
      title: "DOB",
      render: (data) => (getDobDate(data).getFullYear() === 1800 ? "" : formatDate(getDobDate(data))),
      hidden: true,
    },
    updated: {
      title: "Last Updated",
      sortingField: "updatedAt",
      render: (data) => formatDate(getUpdatedDate(data)),
      defaultSort: "desc",
    },
    created: {
      title: "Create Date",
      sortingField: "createdAt",
      render: (data) => formatDate(getCreatedDate(data)),
      defaultSort: "desc",
    },
  };

  return (
    <>
      <Helmet>
        <title>Carina | Agency Dashboard Clients Tab</title>
      </Helmet>
      <AdminAssignmentDialog
        consumerApp={currentEditingConsumerApp}
        newAdmin={selectedAdmin}
        onSubmit={() => {
          setCurrentEditingConsumerApp(undefined);
        }}
      />
      <ServersideDatatable<ConsumerApplication_DTO_Response_Listing, ConsumerColumn>
        columnDefinitions={COLUMN_DEFINITIONS}
        persistKeyPrefix="consumers-list-paging"
        dataFetchFn={pageConsumerApps}
      />
    </>
  );
};
