import { Alert as MuiAlert, AlertProps as MuiAlertProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const StyledAlert = styled(MuiAlert)({
  padding: "16px",
  width: "100%",
  gap: "12px",
  alignItems: "start",
  "&.MuiAlert-standard": {
    borderRadius: "4px",
    color: CivColors.coreDarkNavy,
    paddingLeft: "8px",
    "&.MuiAlert-colorSuccess": {
      backgroundColor: CivColors.newGreenSpring,
      borderLeft: `8px solid ${CivColors.forestSpring}`,
    },
    "&.MuiAlert-colorInfo": {
      backgroundColor: CivColors.pastelBlue,
      borderLeft: `8px solid ${CivColors.richBlue}`,
    },
    "&.MuiAlert-colorWarning": {
      backgroundColor: CivColors.newSandyDune,
      borderLeft: `8px solid ${CivColors.sunnyDune}`,
    },
    "&.MuiAlert-colorError": {
      backgroundColor: CivColors.dustyRose,
      borderLeft: `8px solid ${CivColors.deepRose}`,
    },
  },
  "&.MuiAlert-filled": {
    borderRadius: "4px",
    "&.MuiAlert-colorSuccess": {
      backgroundColor: CivColors.forestSpring,
    },
    "&.MuiAlert-colorInfo": {
      backgroundColor: CivColors.richBlue,
    },
    "&.MuiAlert-colorWarning": {
      color: CivColors.coreDarkNavy,
      backgroundColor: CivColors.sunnyDune,
    },
    "&.MuiAlert-colorError": {
      backgroundColor: CivColors.deepRose,
    },
  },
  "& .MuiAlert-icon": {
    margin: 0,
    padding: 0,
    width: "24px",
    height: "24px",
    "& .MuiSvgIcon-root": {
      margin: 0,
      width: "24px",
      height: "24px",
    },
  },
  "& .MuiAlert-message": {
    padding: 0,
    overflow: "hidden",
    lineHeight: 1.2,
    width: "100%",
  },
  "& .MuiAlert-action": {
    margin: 0,
    padding: 0,
    "& .MuiButtonBase-root": {
      padding: 0,
      margin: 0,
      width: "24px",
      height: "24px",
      "& .MuiSvgIcon-root": {
        margin: 0,
        width: "24px",
        height: "24px",
      },
    },
  },
  "& .MuiButtonBase-root": {
    marginTop: "-12px",
  },
});

export type AlertProps = {
  title?: string;
} & MuiAlertProps;

/**
 * Usage:
 * - To show the close icon, simply define the `onClose={() => {}}` prop. Omit it if you don't need it.
 * - View the MUI Alert API docs for more information: https://mui.com/material-ui/api/alert/
 */

export const ALERT_CLOSE_BUTTON_ID = "alert-close-button";

const Alert = ({ children, title, ...restAlertProps }: AlertProps) => {
  const iconColor = { color: restAlertProps.variant === "filled" ? CivColors.white : CivColors.coreDarkNavy };

  return (
    <StyledAlert
      iconMapping={{
        success: <CheckCircleRoundedIcon sx={iconColor} />,
        info: <InfoRoundedIcon sx={iconColor} />,
        warning: <WarningRoundedIcon sx={{ color: CivColors.coreDarkNavy }} />, // Stays the same color
        error: <ErrorRoundedIcon sx={iconColor} />,
      }}
      slots={{
        closeIcon: CloseRoundedIcon,
      }}
      slotProps={{ closeIcon: { sx: { color: iconColor } }, closeButton: { id: ALERT_CLOSE_BUTTON_ID } }}
      {...restAlertProps}
    >
      {title && <BodyEmphasis mb="12px">{title}</BodyEmphasis>}
      {children}
    </StyledAlert>
  );
};

export default Alert;
