import { Box, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  ActiveCaseManagersResponseDTO,
  GroupInviteResponseDTO,
  RoleConfigsWGroupInviteDTO,
} from "src/generated/api_types";
import { getActiveCaseManagers, generateInvite as generateInviteImpl } from "src/pages/carinaAdmin/management/Queries";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FormSelectField } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { InlineDivider } from "src/reusable_view_elements/InlineDivider";
import { Button } from "src/reusable_view_elements/Button";
import { Body, PanelTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { object, string } from "yup";

const validationSchema = object().shape({
  caseManagerId: string().required("Please choose a case manager"),
});

const initialValues: RoleSelectionFormFields = {
  caseManagerId: "",
};

interface RoleSelectionFormFields {
  caseManagerId: string;
}

interface GroupInviteFormProps {
  groupInviteRoleConfig: RoleConfigsWGroupInviteDTO;
  closeCallback: () => void;
  generateInvite: typeof generateInviteImpl;
}

export const GroupInviteForm = ({ groupInviteRoleConfig, closeCallback, generateInvite }: GroupInviteFormProps) => {
  const [inviteLinkRes, setInviteLinkRes] = useState<GroupInviteResponseDTO>();
  const [isLoadingCaseManagers, setIsLoadingCaseManagers] = useState(true);
  const [activeCaseManagers, setActiveCaseManagers] = useState<ActiveCaseManagersResponseDTO[]>();

  useEffect(() => {
    setIsLoadingCaseManagers(true);
    getActiveCaseManagers(groupInviteRoleConfig.funderId)
      .then((res) => {
        setActiveCaseManagers(res.data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch active case managers. Error: ${e}`);
      })
      .finally(() => {
        setIsLoadingCaseManagers(false);
      });
  }, []);

  if (isLoadingCaseManagers) {
    return <>Loading active case managers...</>;
  }

  const handleGenerateInvite = async ({ caseManagerId }: RoleSelectionFormFields) => {
    try {
      const res = await generateInvite({ sendingUserId: caseManagerId, regPathId: groupInviteRoleConfig.regPathId });
      setInviteLinkRes(res.data);
    } catch (e) {
      setInviteLinkRes({
        senderFirstName: caseManagerId,
        link: "Something went wrong generating an invite for the user id above. Please let product team know.",
        expiresAt: Date.now(),
      });
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleGenerateInvite}>
        {({ isSubmitting }) => (
          <Form localizationReady={true}>
            <Grid container spacing={2}>
              <Grid container item justifyContent="space-between">
                <Grid item xs={10}>
                  <PanelTitle paragraph>Generate Consumer Link</PanelTitle>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <IconButton onClick={closeCallback}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ color: CivColors.coreGray }}>
                <Body paragraph>
                  Choose the case manager or ERC representative who will be sending the invite to Consumer.
                </Body>
                <Body paragraph>Only active case managers without an existing valid invite link can be selected.</Body>
              </Grid>
              <Grid item xs={12}>
                {activeCaseManagers && (
                  <FormSelectField
                    fullWidth
                    name="caseManagerId"
                    label="Case Manager"
                    selectOptions={activeCaseManagers
                      .map((cm) => ({
                        label: `${cm.firstName} ${cm.lastName}`,
                        value: cm.id,
                        disabled: cm.hasValidInvite,
                      }))
                      .sort((a, b) => {
                        if (a.disabled === b.disabled) {
                          return a.label < b.label ? -1 : 1;
                        }
                        return a.disabled < b.disabled ? -1 : 1;
                      })}
                  />
                )}
              </Grid>
              {!inviteLinkRes && (
                <Grid container item justifyContent="flex-end">
                  <Button variant="text" type="button" onClick={closeCallback}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? `Generating...` : `Generate link`}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      {inviteLinkRes && (
        <>
          <Box p={2} />
          <InlineDivider />
          <Box p={2} />
          <PanelTitle paragraph>Copy Invite Link for {inviteLinkRes.senderFirstName}</PanelTitle>
          <Grid
            container
            style={{
              border: `1px solid ${CivColors.coreNavy}`,
              padding: 10,
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            <Body>{inviteLinkRes.link}</Body>
          </Grid>
          <Grid container justifyContent="flex-end" style={{ paddingTop: 24 }}>
            <Button
              variant="contained"
              type="button"
              onClick={() => navigator.clipboard.writeText(inviteLinkRes?.link)}
            >
              Copy Link
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};
