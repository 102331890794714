import { Box, Grid, Link as MuiLink, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  JobPostListDTO,
  JobPostTagCategoryEnum,
  JobPostTagEnum,
  MedicaidProviderProfile_DTO_Response_ProfileDetails,
  Permission,
  ReportSourceEnum,
  TravelDataDTO,
  TravelType,
} from "src/generated/api_types";
import {
  nsCommonFormsBtns,
  nsCommonToasts,
  nsCommonTooltips,
  nsMedicaidJobPost,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedJobPostTag } from "src/i18n/TagHelpers";
import { getKeyForRoleDisplayName } from "src/i18n/Utilities";
import {
  CalendarIcon,
  ChatTranslateIcon,
  CheckBadgeIcon,
  TimeClockCircleIcon,
  TimeClockCircleAlternateIcon,
  FireworksPeopleWatchIcon,
  Flag1Icon,
  JobChooseCandidate1Icon,
  PinLocation1Icon,
  SingleUserActionsChatIcon,
} from "src/assets/icons/StreamlineIcons";
import { getTagsObject } from "src/pages/medicaidAccount/consumerAccount/CreateEditRepostJobForm";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { getNextId, JobListOrigin, useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import FirstMessageDialog, { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import {
  bookmarkJob,
  getDirectionsData,
  getJobPostInfo,
  unBookmarkJob,
} from "src/pages/medicaidAccount/sharedComponents/Queries";
import { UserReportModal, UserReportResponseBanner } from "src/pages/medicaidAccount/sharedComponents/ReportUserModal";
import XOrCheckMarkListItem from "src/pages/medicaidAccount/sharedComponents/XOrCheckMarkListItem";
import {
  getMedicaidRouteByUserType,
  getRepostRoute,
  getRoutePrefix,
} from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import IconList from "src/reusable_view_elements/IconList";
import IconListItem from "src/reusable_view_elements/IconListItem";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MessageFollowUpButton from "src/reusable_view_elements/MessageFollowUpButton";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import { Button } from "src/reusable_view_elements/Button";
import NonbinaryPopover from "src/reusable_view_elements/popovers/NonbinaryPopover";
import Section from "src/reusable_view_elements/Section";
import Separator from "src/reusable_view_elements/Separator";
import { Snackbar } from "src/reusable_view_elements/Snackbar";
import TravelInformation from "src/reusable_view_elements/TravelInformation";
import { Body, BodyEmphasis, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { insertBetween } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";

interface JobDetailsProps {
  jobListView: JobListOrigin; // TODO: This is at odds with jobPostListOrigin, unsure which way to prefer at the moment
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const JobDetails = ({ jobListView }: JobDetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const { showSnackbar, useMainSnackbar, closeSnackbar } = useNotification();
  const { userSession, isUserType } = useUserSession();
  const { jobIdList, jobPostListOrigin, reportResponseView, setReportResponseView } = useMedicaidContext();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const [openReportModal, setOpenReportModal] = useState(false);

  const [carTravel, setCarTravel] = useState<TravelDataDTO>();
  const [busTravel, setBusTravel] = useState<TravelDataDTO>();
  const [isLoadingJobPost, setLoadingJobPost] = useState(false);
  const [isLoadingTravel, setLoadingTravel] = useState(false);
  const [jobPost, setJobPost] = useState<JobPostListDTO>();
  const [showMessageModal, setMessageModal] = useState<boolean>(false);
  const { t, ready } = useTranslation([
    nsMedicaidJobPost,
    nsCommonFormsBtns,
    nsCommonToasts,
    nsMedicaidTagsEnums,
    nsCommonTooltips,
  ]);

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/
  /************************************************* *************************************************
   The next function updates the jobPost object without having to
   make a call to the BE. This prevents rerendering the page, which would break the focus sequence of
   any open 'first message success snackbar'
   ************************************************* *************************************************/
  const updateJobPostAfterFirstMessage = (threadId?: string) => {
    if (!threadId || !jobPost) return;
    const updatedJobPost = { ...jobPost, entity: { ...jobPost.entity, threadIds: [threadId] } };
    setJobPost(updatedJobPost);
  };
  /************************************************* *********************************************************/

  useEffect(() => {
    setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!userSession) {
          throw Error("Missing user session");
        }

        setLoadingJobPost(true);

        const resJobPost = await getJobPostInfo(id);
        setJobPost(resJobPost.data);
        setLoadingTravel(true);
        const resCarDirections = await getDirectionsData(
          TravelType.CAR,
          userSession.geoData.id,
          resJobPost.data.entity.geoData.id,
        );
        setCarTravel(resCarDirections.data);

        const resBusDirections = await getDirectionsData(
          TravelType.BUS,
          userSession.geoData.id,
          resJobPost.data.entity.geoData.id,
        );
        setBusTravel(resBusDirections.data);
      } catch (e) {
        showSnackbar(
          t(
            "job_details.error.issue_fetching_job_info",
            "Sorry, there was an issue fetching job info. Please try again later.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoadingJobPost(false);
        setLoadingTravel(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (reportResponseView !== ViewAPIResBannerControlEnum.HIDDEN) scrollToId("nav-bar-carina-logo");
  }, [reportResponseView]);

  if (!ready) {
    return (
      <>
        <Helmet>
          <title>Carina | Medicaid Job Post Details</title>
        </Helmet>
        <MedicaidNavbar />
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minHeight="100vh">
            <Constraint columns={8} textAlign="center">
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
        <Footer />
      </>
    );
  }

  if (isLoadingJobPost) {
    return (
      <>
        <Helmet>
          <title>{t("browser_tab_title.job_details", { ns: nsMedicaidJobPost })}</title>
        </Helmet>
        <MedicaidNavbar />
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minHeight="100vh">
            <Constraint columns={8} textAlign="center">
              <LoadingCircle />
            </Constraint>
          </Section>
        </main>
        <Footer />
      </>
    );
  }

  if (!jobPost) {
    return (
      <>
        <Helmet>
          <title>{t("browser_tab_title.job_details", { ns: nsMedicaidJobPost })}</title>
        </Helmet>
        <MedicaidNavbar />
        <main id="main-content">
          <Section bgcolor={CivColors.lightGray} minHeight="100vh">
            <Constraint columns={8} textAlign="center">
              <SectionTitle>{t("job_post_not_found.label", { ns: nsMedicaidJobPost })}</SectionTitle>
              <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
                {t("button.go_to_find_jobs", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Constraint>
          </Section>
        </main>
        <Footer />
      </>
    );
  }

  const isJobPostPoster = userSession?.id && userSession?.id === jobPost.entity.postingUserInfo.id;
  const isDeleted = !!jobPost.entity.resolvedDate;
  const tagsObject = getTagsObject(jobPost.entity);

  function getAgeAndSupportDescription(): string {
    const ageLocizeKey: string = getLocalizedJobPostTag(tagsObject.ageRangeTag).key;
    const supportLocizeKeys: string[] = tagsObject.typeOfSupportTags.map((tag) => getLocalizedJobPostTag(tag).key);
    const keySet: string[] = [ageLocizeKey, ...supportLocizeKeys];
    return keySet.map((key) => t(key, { ns: nsMedicaidTagsEnums })).join(" • ");
  }

  function getTranslatedJobPostTag(tag: JobPostTagEnum): string {
    return t(getLocalizedJobPostTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  function getBackButtonLink(origin: JobListOrigin): string {
    if (isDeleted) {
      // Don't rely on JobListOrigin, since users can jump around the site and may skip past their deleted jobs list
      return getMedicaidRouteByUserType("myDeletedJobs", isUserType);
    }

    switch (origin) {
      case JobListOrigin.MY_JOBS:
        return getMedicaidRouteByUserType("myJobList", isUserType);
      case JobListOrigin.FIND_JOBS:
        return MEDICAID_ACCOUNT_ROUTES.browseJobs;
      case JobListOrigin.DELETED:
        return getMedicaidRouteByUserType("myDeletedJobs", isUserType);
      case JobListOrigin.INBOX:
        return MEDICAID_ACCOUNT_ROUTES.inbox;
      default:
        return MEDICAID_ACCOUNT_ROUTES.browseJobs;
    }
  }

  const handleSaveJobPost = async () => {
    try {
      if (jobPost.entity.bookmarked) {
        await unBookmarkJob(id);
      } else {
        await bookmarkJob(id);
      }
      setJobPost({ ...jobPost, entity: { ...jobPost.entity, bookmarked: !jobPost.entity.bookmarked } });
    } catch (e) {
      showSnackbar(
        t("error.processing_bookmark", "Error processing bookmark request. Please try again later.", {
          ns: nsCommonToasts,
        }),
        "error",
      );
    }
  };

  const ConsumerGenderTags = () => {
    return (
      <Body>
        {insertBetween(
          ", ",
          tagsObject.consumerGenderTags.map((tag) =>
            tag === JobPostTagEnum.NONBINARY_CLIENT ? (
              <NonbinaryPopover key={tag}>{getTranslatedJobPostTag(tag)}</NonbinaryPopover>
            ) : (
              getTranslatedJobPostTag(tag)
            ),
          ),
        )}
      </Body>
    );
  };

  const PreferredProviderGenderTags = () => {
    const containsAllGenders =
      tagsObject.providerGenderTags.includes(JobPostTagEnum.NO_GENDER_PREFERENCE) ||
      tagsObject.providerGenderTags.length >= 3;

    if (containsAllGenders) {
      return <Body>{getTranslatedJobPostTag(JobPostTagEnum.NO_GENDER_PREFERENCE)}</Body>;
    }

    return (
      <Body>
        {insertBetween(
          ", ",
          tagsObject.providerGenderTags.map((tag) =>
            tag === JobPostTagEnum.NONBINARY ? (
              <NonbinaryPopover key={tag}>{getTranslatedJobPostTag(tag)}</NonbinaryPopover>
            ) : (
              getTranslatedJobPostTag(tag)
            ),
          ),
        )}
      </Body>
    );
  };

  const displayedUserFirstName = () => jobPost.entity.firstName || jobPost.entity.postingUserInfo.firstName;
  const displayedPosterFirstName = () => jobPost.entity.postingUserInfo.firstName;
  const getConsumerPronouns = () => {
    if (jobPost.entity.postingUserInfo.role === UserType.Consumer) {
      return tagsObject.pronounTags.map((tag) => getTranslatedJobPostTag(tag)).join(", ");
    }
    if (displayedUserFirstName() !== displayedPosterFirstName()) {
      return tagsObject.pronounTags.map((tag) => getTranslatedJobPostTag(tag)).join(", ");
    }
    return "";
  };

  return (
    <>
      <Helmet>
        <title>{t("browser_tab_title.job_details", { ns: nsMedicaidJobPost })}</title>
      </Helmet>
      <MedicaidNavbar />
      <main id="main-content">
        <FirstMessageDialog
          recipientId={jobPost.entity.postingUserInfo.id}
          recipientFirstName={jobPost.entity.postingUserInfo.firstName}
          recipientRole={jobPost.entity.postingUserInfo.role as UserType}
          contextJobPostId={jobPost.entity.id}
          context={FirstMessageContext.JOB_POST}
          isDialogOpen={showMessageModal}
          onCloseDialog={(threadId) => {
            updateJobPostAfterFirstMessage(threadId);
            setMessageModal(false);
          }}
          pronouns={
            jobPost.entity.postingUserInfo.role === UserType.Consumer
              ? jobPost.entity.tags
                  .filter((tagObj) => tagObj.category === JobPostTagCategoryEnum.PRONOUN)
                  .sort((a, b) => a.order - b.order)
                  .map((tagObj) => t(tagObj.tag.toLowerCase(), { ns: nsMedicaidTagsEnums }))
              : undefined
          }
        />
        <UserReportResponseBanner view={reportResponseView} setView={setReportResponseView} />
        <Section bgcolor={CivColors.lightGray} textAlign="center">
          <Constraint columns={6}>
            <SectionTitle>
              {t("work_with_name.label", {
                ns: nsMedicaidJobPost,
                name: displayedUserFirstName(),
              })}
            </SectionTitle>
            <BodyEmphasis paragraph>{getConsumerPronouns()}</BodyEmphasis>
            <BodyEmphasis paragraph>{getAgeAndSupportDescription()}</BodyEmphasis>
            <Body paragraph>
              {t("posted_count_days_ago_by.label", {
                ns: nsMedicaidJobPost,
                count: jobPost.entity.daysPosted,
                name: displayedPosterFirstName(),
              })}
              {` | `}
              {jobPost.entity.postingUserInfo.role !== UserType.MedicaidReferralCoordinator &&
                `${t(getKeyForRoleDisplayName(jobPost.entity.postingUserInfo.role), { ns: nsMedicaidTagsEnums })} | `}
              {jobPost.entity.postingUserInfo.role === UserType.MedicaidReferralCoordinator &&
                jobPost.entity.postingUserOrganization &&
                `${jobPost.entity.postingUserOrganization} | `}
              {`${t("job.label", { ns: nsMedicaidJobPost })} #: ${jobPost.entity.jobNumber}`}
            </Body>
            <JobPostActionButtons
              setMessageModal={setMessageModal}
              isJobPostPoster={isJobPostPoster}
              handleSaveJobPost={handleSaveJobPost}
              jobPost={jobPost}
              isDeleted={isDeleted}
              desktopSize={desktopSize}
              id={id}
            />
          </Constraint>
        </Section>

        {isUserType(UserType.Provider) && (
          <Section bgcolor={CivColors.lightGray} textAlign="center" sidePadding>
            <Constraint columns={6}>
              {!isLoadingTravel ? (
                <TravelInformation
                  translationNamespace={nsMedicaidJobPost}
                  carTravelDTO={carTravel}
                  busTravelDTO={busTravel}
                />
              ) : (
                <Section bgcolor={CivColors.lightGray} height="100vh">
                  <LoadingCircle />
                </Section>
              )}
              <Body>{t("travel_time_calculated.description", { ns: nsMedicaidJobPost })}</Body>
              <Separator line style={{ padding: "48px 0px" }} />
            </Constraint>
          </Section>
        )}

        <Section bgcolor={CivColors.lightGray} sidePadding>
          <Constraint columns={6}>
            <BodyTitle>{t("summary.label", { ns: nsMedicaidJobPost })}</BodyTitle>
            <IconList>
              <IconListItem
                icon={PinLocation1Icon}
                primary={<BodyEmphasis>{t("summary.location.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>}
                secondary={
                  <Body>
                    {jobPost.entity.geoData.city}, {jobPost.entity.geoData.state}
                  </Body>
                }
              />
              <IconListItem
                icon={ChatTranslateIcon}
                primary={<BodyEmphasis>{t("summary.languages.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>}
                secondary={<Body>{tagsObject.languageTags.map(getTranslatedJobPostTag).join(", ")}</Body>}
              />
              <IconListItem
                icon={FireworksPeopleWatchIcon}
                primary={<BodyEmphasis>{t("summary.consumer_gender.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>}
                secondary={<ConsumerGenderTags />}
              />
              {getConsumerPronouns() && (
                <IconListItem
                  icon={SingleUserActionsChatIcon}
                  primary={
                    <BodyEmphasis>{t("summary.consumer_pronouns.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>
                  }
                  secondary={<Body>{getConsumerPronouns()}</Body>}
                />
              )}
              <IconListItem
                icon={JobChooseCandidate1Icon}
                primary={
                  <BodyEmphasis>{t("summary.pref_provider_gender.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>
                }
                secondary={<PreferredProviderGenderTags />}
              />
              <IconListItem
                icon={TimeClockCircleIcon}
                primary={<BodyEmphasis>{t("summary.frequency.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>}
                secondary={<Body>{getTranslatedJobPostTag(tagsObject.typeOfCareTag)}</Body>}
              />
              <IconListItem
                icon={TimeClockCircleAlternateIcon}
                primary={<BodyEmphasis>{t("summary.monthly_hours.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>}
                secondary={
                  <Body>
                    {jobPost.entity.hours}{" "}
                    {jobPost.entity.hoursFlexible ? t("flexible.description", { ns: nsMedicaidJobPost }) : ""}
                  </Body>
                }
              />
              <IconListItem
                icon={CalendarIcon}
                primary={<BodyEmphasis>{t("summary.schedule.label", { ns: nsMedicaidJobPost })}</BodyEmphasis>}
                secondary={
                  <Body style={{ overflowWrap: "anywhere" }}>
                    {jobPost.entity.scheduleNotes}{" "}
                    {jobPost.entity.scheduleFlexible ? t("flexible.description", { ns: nsMedicaidJobPost }) : ""}
                  </Body>
                }
              />
            </IconList>
            <Separator line style={{ padding: "48px 0px 28px" }} />
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} sidePadding>
          <Constraint columns={6}>
            <BodyTitle style={{ marginBottom: 30 }}>{t("care_needs.label", { ns: nsMedicaidJobPost })}</BodyTitle>

            <BodyEmphasis style={{ marginBottom: 16 }}>
              {t("care_needs.general_care.label", { ns: nsMedicaidJobPost })}
            </BodyEmphasis>
            {[
              JobPostTagEnum.HOUSEKEEPING,
              JobPostTagEnum.LAUNDRY,
              JobPostTagEnum.MEAL_PREP,
              JobPostTagEnum.SHOPPING,
              JobPostTagEnum.PERSONAL_CARE,
              JobPostTagEnum.MEDS_MGMT,
              JobPostTagEnum.BEHAVIOR_CHALLENGE,
              JobPostTagEnum.NURSE_DELEGATION,
            ].map((tag) => (
              <XOrCheckMarkListItem key={tag} tag={tag} tagsObject={tagsObject} />
            ))}

            <BodyEmphasis style={{ marginTop: 20, marginBottom: 16 }}>
              {t("care_needs.movement.label", { ns: nsMedicaidJobPost })}
            </BodyEmphasis>
            {[JobPostTagEnum.AMBULATION, JobPostTagEnum.POSITIONING, JobPostTagEnum.TRANSFER_ASSISTED].map((tag) => (
              <XOrCheckMarkListItem key={tag} tag={tag} tagsObject={tagsObject} />
            ))}

            <BodyEmphasis style={{ marginTop: 20, marginBottom: 16 }}>
              {t("care_needs.transportation.label", { ns: nsMedicaidJobPost })}
            </BodyEmphasis>
            {[JobPostTagEnum.TRANSPORT, JobPostTagEnum.TRANSPORT_PROVIDER].map((tag) => (
              <XOrCheckMarkListItem key={tag} tag={tag} tagsObject={tagsObject} />
            ))}

            <Separator line style={{ padding: "48px 0px 28px" }} />
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} sidePadding>
          <Constraint columns={6}>
            <BodyTitle style={{ marginBottom: 30 }}>{t("home_env.label", { ns: nsMedicaidJobPost })}</BodyTitle>

            <BodyEmphasis style={{ marginBottom: 16 }}>
              {t("home_env.general.label", { ns: nsMedicaidJobPost })}
            </BodyEmphasis>
            {[
              JobPostTagEnum.MULTIGENERATIONAL_HOUSEHOLD,
              JobPostTagEnum.MULTILINGUAL_HOUSEHOLD,
              JobPostTagEnum.LGBTQIA_HOUSEHOLD,
            ].map((tag) => (
              <XOrCheckMarkListItem key={tag} tag={tag} tagsObject={tagsObject} />
            ))}

            <BodyEmphasis style={{ marginTop: 20, marginBottom: 16 }}>
              {t("home_env.scents_smoking.label", { ns: nsMedicaidJobPost })}
            </BodyEmphasis>
            {[
              JobPostTagEnum.FRAG_FREE,
              JobPostTagEnum.NON_SMOKING,
              JobPostTagEnum.SMOKING,
              JobPostTagEnum.NON_SMOKING_PROVIDER,
            ].map((tag) => (
              <XOrCheckMarkListItem key={tag} tag={tag} tagsObject={tagsObject} />
            ))}

            <BodyEmphasis style={{ marginTop: 20, marginBottom: 16 }}>
              {t("home_env.my_pets.label", { ns: nsMedicaidJobPost })}
            </BodyEmphasis>
            {[JobPostTagEnum.DOG, JobPostTagEnum.CAT, JobPostTagEnum.BIRD, JobPostTagEnum.OTHER_PET].map((tag) => (
              <XOrCheckMarkListItem key={tag} tag={tag} tagsObject={tagsObject} />
            ))}

            <Separator line style={{ padding: "48px 0px 28px" }} />
          </Constraint>
        </Section>

        <Section bgcolor={CivColors.lightGray} sidePadding>
          <Constraint columns={6}>
            <BodyTitle>{t("hobbies_interests.label", { ns: nsMedicaidJobPost })}</BodyTitle>
            <IconListItem
              icon={CheckBadgeIcon}
              primary={<Body style={{ overflowWrap: "anywhere" }}>{jobPost.entity.furtherIntroduction}</Body>}
              secondary={<Body />}
            />
          </Constraint>
        </Section>

        <Box bgcolor={CivColors.lightGray} textAlign="center" pt={2} pb={2}>
          <Constraint columns={6}>
            <JobPostActionButtons
              setMessageModal={setMessageModal}
              isJobPostPoster={isJobPostPoster}
              handleSaveJobPost={handleSaveJobPost}
              jobPost={jobPost}
              isDeleted={isDeleted}
              desktopSize={desktopSize}
              id={id}
            />
          </Constraint>
        </Box>

        <Box bgcolor={CivColors.lightGray} pt={2} pb={2}>
          <Constraint columns={6}>
            <Grid container justifyContent="center" spacing={desktopSize ? 4 : 2}>
              {jobPostListOrigin !== JobListOrigin.INBOX && (
                <Grid item>
                  <LinkStyledAsButton variant="outlined" component={Link} to={getBackButtonLink(jobPostListOrigin)}>
                    {t("button.back_to_results", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              )}
              {jobPostListOrigin === JobListOrigin.INBOX && (
                <Grid item>
                  <LinkStyledAsButton variant="outlined" component={Link} to={MEDICAID_ACCOUNT_ROUTES.inbox}>
                    {t("button.back_to_inbox", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              )}
              {jobPostListOrigin !== JobListOrigin.INBOX && jobIdList.length > 1 && (
                <Grid item>
                  <LinkStyledAsButton
                    variant="outlined"
                    component={Link}
                    to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: getNextId(id, jobIdList) })}
                  >
                    {t("button.next", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              )}
            </Grid>
          </Constraint>
        </Box>

        {!isJobPostPoster && (
          <Section bgcolor={CivColors.lightGray}>
            <Constraint columns={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Flag1Icon width="24px" height="24px" color="primary" />
                <MuiLink component="button" onClick={() => setOpenReportModal(true)}>
                  <Body style={{ marginLeft: 9 }}>{t("button.report_user", { ns: nsCommonFormsBtns })}</Body>
                </MuiLink>
                <UserReportModal
                  subjectId={jobPost.entity.postingUserInfo.id}
                  subjectUserType={jobPost.entity.postingUserInfo.role as UserType}
                  subjectFirstName={jobPost.entity.postingUserInfo.firstName}
                  reportSource={ReportSourceEnum.JOB_POST}
                  open={openReportModal}
                  onClose={() => setOpenReportModal(false)}
                />
              </Box>
            </Constraint>
          </Section>
        )}
        {isJobPostPoster && <Box bgcolor={CivColors.lightGray} height={80} />}
      </main>
      <Footer />
    </>
  );
};

const JobPostActionButtons = (props: {
  setMessageModal: Dispatch<SetStateAction<boolean>>;
  isJobPostPoster?: boolean | "";
  handleSaveJobPost: () => Promise<void>;
  jobPost: JobPostListDTO;
  isDeleted: boolean;
  desktopSize: boolean;
  id: string;
}) => {
  const { setMessageModal, isJobPostPoster, handleSaveJobPost, jobPost, isDeleted, desktopSize, id } = props;
  const { isUserType, impersonatorIsUserType, hasPermission } = useUserSession();
  const { t } = useTranslation();
  const [msgBtnWasClicked, setMsgBtnWasClicked] = useState(false);
  const { useMainSnackbar, closeSnackbar } = useNotification();

  const enableMessageSnackbar = () => {
    closeSnackbar();
    setMsgBtnWasClicked(true);
  };

  return (
    <>
      {!isDeleted && (
        <Grid container justifyContent="center" spacing={desktopSize ? 4 : 2}>
          <Grid item>
            {isJobPostPoster && (
              <LinkStyledAsButton
                variant="contained"
                component={Link}
                to={generatePath(getRoutePrefix(isUserType).jobEdit, { id })}
              >
                {t("button.edit", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            )}
            {!isJobPostPoster && (
              <Button variant="contained" type="button" onClick={handleSaveJobPost}>
                {jobPost.entity.bookmarked
                  ? t("button.unsave", { ns: nsCommonFormsBtns })
                  : t("button.save", { ns: nsCommonFormsBtns })}
              </Button>
            )}
          </Grid>
          <Grid item>
            {isJobPostPoster && (
              <LinkStyledAsButton
                variant="contained"
                component={Link}
                to={generatePath(getRoutePrefix(isUserType).jobDelete, { id })}
              >
                {t("button.delete", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            )}
            {hasPermission(Permission.MESSAGING_ACCESS) &&
              !impersonatorIsUserType(UserType.UserSupportAgent) &&
              !isJobPostPoster &&
              jobPost.entity.threadIds && (
                <>
                  <MessageFollowUpButton
                    style={{ marginLeft: 12 }}
                    onMessageClick={() => {
                      enableMessageSnackbar();
                      setMessageModal(true);
                    }}
                    threadIds={jobPost.entity.threadIds}
                    otherUserId={jobPost.entity.postingUserInfo.id}
                  />
                  <Snackbar
                    isEnabled={msgBtnWasClicked && !useMainSnackbar}
                    onCloseCallback={() => setMsgBtnWasClicked(false)}
                  />
                </>
              )}
          </Grid>
        </Grid>
      )}
      {isDeleted && isJobPostPoster && (
        <Box textAlign="center">
          <LinkStyledAsButton variant="contained" component={Link} to={getRepostRoute(id)}>
            {t("button.edit_and_repost", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Box>
      )}
    </>
  );
};

export default JobDetails;
