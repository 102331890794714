import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { RoleConfigsWGroupInviteDTO } from "src/generated/api_types";
import { GroupInviteForm } from "src/pages/supportAdminAccount/forms/GroupInviteForm";
import { generateInvite as generateInviteImpl } from "src/pages/carinaAdmin/management/Queries";

interface UserInviteModalProps {
  groupInviteRoleConfig: RoleConfigsWGroupInviteDTO;
  showModal: boolean;
  handleModalClose: () => void;
}

const GroupInviteLinkModal = ({ groupInviteRoleConfig, showModal, handleModalClose }: UserInviteModalProps) => {
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Modal
        open={showModal}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick") {
            handleModalClose();
          }
        }}
        aria-labelledby="Medicaid-group-invite-modal"
        aria-describedby="Group invite medicaid user"
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "#fff",
            border: 0,
            borderRadius: 2,
            color: "#000",
            top: "50%",
            left: 0,
            right: 0,
            padding: "26px 24px",
            transform: "translateY(-50%)",
            maxWidth: "584px",
            margin: "auto",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <GroupInviteForm
              groupInviteRoleConfig={groupInviteRoleConfig}
              closeCallback={handleModalClose}
              generateInvite={generateInviteImpl}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default GroupInviteLinkModal;
